import React from 'react'
import OptionContext from '../editors/OptionContext'
import { BoxContent } from './components/BoxContent'
import { Label } from './components/Label'
import { FallbackLabel } from './components/FallbackLabel'
import { Type } from './components/Type'
import { Conditions } from './components/Conditions'
import { DefaultRadio } from './components/DefaultRadio'

/**
 * General option params
 */
const GeneralOptionParams: React.FC = () => {
  return (
    <OptionContext.Consumer>
      {(state) => {
        const { type, id } = state.option.meta
        return (
          <>
            {/* Rendering Option's id */}
            <div className="id">#{id}</div>

            {/* meta.type */}
            <Type state={state} />

            {/* meta.label */}
            {['radio', 'checkbox', 'static'].includes(type) && (
              <Label state={state} />
            )}

            {/* meta.fallbackLabel */}
            {['radio', 'checkbox', 'static'].includes(type) && (
              <FallbackLabel state={state} />
            )}

            {/* meta.box.content */}
            {type === 'box' && <BoxContent state={state} />}

            {/* meta.conditions */}
            <Conditions state={state} />

            {/* meta.defaultRadio (for parent) */}
            <DefaultRadio state={state} />
          </>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default GeneralOptionParams
