import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import '../assets/scss/main.scss'
import { Redirect, Route, Switch } from 'react-router'
import LoginPage from '../pages/LoginPage'
import Header from '../components/Header/Header'
import ForgotPage from '../pages/ForgotPage'
import ResetPage from '../pages/ResetPage'

interface IUnAuthorizedLayoutProps {}

@observer
class UnAuthorizedLayout extends Component<IUnAuthorizedLayoutProps> {
  public render(): ReactNode {
    return (
      <>
        <Header showMenu={false} />
        <div className="container">
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/forgot" component={ForgotPage} />
            <Route path="/reset/:token" component={ResetPage} />
            <Redirect to="/login" />
          </Switch>
        </div>
      </>
    )
  }
}

export default UnAuthorizedLayout
