import React from 'react'
import Portal from '../portal'
import createStore from '../../../../store/editor/createStore'
import UploadPdf from './uploadPdf'
import './style.scss'
import EditPdf from './editPdf'

const PdfEditor: React.FC = () => {
  const { currentDocument, document } = createStore

  const { pdf } = document.documents[currentDocument]

  const isPdf =
    typeof pdf === 'object' &&
    typeof pdf.id === 'string' &&
    typeof pdf.form === 'object' &&
    typeof pdf.thumbnails === 'object'

  const close = () => {
    createStore.toggleModal('pdfEditorIsOpen', false)
  }

  return (
    <Portal className="pdf-editor-root">
      {!isPdf && <UploadPdf close={close} />}
      {isPdf && pdf && <EditPdf close={close} pdf={pdf} />}
    </Portal>
  )
}

export default PdfEditor
