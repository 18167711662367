import Dialog from '@material-ui/core/Dialog'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { ICreateStore } from '../../store/editor/createStore'
import CheckboxField from '../Editor/CheckboxField'
import SelectField from '../SelectField/SelectField'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface IExtractionModalProps {
  createStore?: ICreateStore | any
  open: boolean
}

@inject('createStore')
@observer
class ExtractionModal extends Component<IExtractionModalProps> {
  @observable variables = [1]

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props.createStore
    toggleModal('extractionIsOpen', open)
  }

  @catchAction
  @action
  public addVariable = () => {
    this.variables.push(Math.random())
  }

  @catchAction
  @action
  public delVariable = (index: number) => {
    this.variables.splice(index, 1)
  }

  render() {
    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="extraction-modal"
      >
        {this.variables.map((variable: any, i: number) => (
          <div key={i}>
            <div className="variable-extract-holder">
              <span>Variable to extract:</span>
              <SelectField
                fieldClass="modal-select"
                setFieldValue={() => {}}
                name="Type"
                options={[]}
              />
              {this.variables.length > 1 && (
                <button
                  type="button"
                  className="btn-icon"
                  onClick={() => this.delVariable(i)}
                >
                  <span className="ico-box" />
                  <span className="ico-box" />
                </button>
              )}
            </div>
            <div className="modal-export-settings">
              <h3>Export to:</h3>
              <div className="checkbox-wrapper">
                <CheckboxField
                  name="empty"
                  setFieldValue={() => {}}
                  label="Sendinblue"
                  value="empty"
                />
              </div>
              <div className="checkbox-wrapper">
                <CheckboxField
                  name="empty"
                  setFieldValue={() => {}}
                  label="Mixpanel"
                  value="empty"
                />
              </div>
              <div className="checkbox-wrapper">
                <CheckboxField
                  name="empty"
                  setFieldValue={() => {}}
                  label="Slack"
                  value="empty"
                />
              </div>
            </div>
          </div>
        ))}
        <div className="variable-holder">
          <button
            type="button"
            onClick={() => this.addVariable()}
            className="add-variable"
          >
            <span>+</span>
            Add another variable
          </button>
        </div>
        <div className="buttons-holder">
          <button
            type="button"
            className="cancel"
            onClick={() => this.toggleModal(false)}
          >
            Cancel
          </button>
          <button type="button" className="save">
            Save
          </button>
        </div>
      </Dialog>
    )
  }
}

export default ExtractionModal
