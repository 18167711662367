import React, { useState, useEffect } from 'react'
import { FDFBoxesV3, OptionV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import createStore from '../../../../../../../../store/editor/createStore'
import Select from '../../../../../slate-editor/components/select/Select'
import Icon from '../../../../../../../../components/Icons/Icon'
import { DELICON } from '../../../../../../../../components/Icons/icons'

type ArrayElement<ArrayType extends unknown[]> = ArrayType[any]

const PdfVariableEditor: React.FC<{
  linkedOption: ArrayElement<FDFBoxesV3['options']>
  deleteOption: () => void
}> = ({ linkedOption, deleteOption }) => {
  // We'll store our option here
  const [option, setOption] = useState<OptionV3 | undefined>(undefined)

  const { id } = linkedOption
  const { document } = createStore

  // Getting our option from state
  useEffect(() => {
    if (option === undefined && document.options[id]) {
      setOption(document.options[id])
    }
  }, [option, id, document])

  const handleVariableSelection = (optionId: string) => {
    linkedOption.id = parseInt(optionId, 10)
    setOption(document.options[optionId])
  }

  const optionsList: Record<string, JSX.Element> = {}
  Object.values(document.options).forEach((currentOption) => {
    if (['radio', 'checkbox'].includes(currentOption.meta.type))
      optionsList[currentOption.meta.id] = (
        <>
          <b>#{currentOption.meta.id}</b> {currentOption.meta.label}
        </>
      )
  })

  return (
    <div className="linked-item-wrapper">
      <div className="lv-item">
        <label>{I18n.get('Option:')}</label>
        <Select
          options={optionsList}
          value={id.toString()}
          enableFilter
          onChange={handleVariableSelection}
        />
      </div>
      <div className="lv-footer">
        <button
          data-cypressId="del-btn"
          type="button"
          className="btn-icon"
          tabIndex={-1}
          onClick={() => deleteOption()}
        >
          <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
        </button>
      </div>
    </div>
  )
}

export default PdfVariableEditor
