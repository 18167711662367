import React from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import classNames from 'classnames'
import './style.scss'

export interface IOption {
  label: string
  value: any
}

class SimpleSelect extends React.Component<any, any> {
  public onChange = (e: any) => {
    const { name, setFieldValue } = this.props
    setFieldValue(name, e.target.value)
  }

  handleChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const {
      options = [],
      name,
      value = '',
      fieldClass = '',
      variant,
      title,
      disabled
    } = this.props
    const selected = options.find((e: IOption) => e.value === value)

    const fieldClassConst = classNames({
      'material-form-field': true,
      [fieldClass]: true
    })

    const variantType = !variant ? 'outlined' : 'standard'

    return (
      <FormControl variant={variantType} className={fieldClassConst}>
        {title ? <h5 className="text">{title}</h5> : ''}
        <Select
          value={selected ? selected.value : ''}
          onChange={this.onChange}
          variant={variantType}
          // className="material-custom-select"
          disabled={disabled}
          input={
            variantType === 'outlined' ? (
              <OutlinedInput
                className={
                  disabled ? 'outlined-select-disabled' : 'outlined-select'
                }
                labelWidth={0}
                name={name}
                id="outlined-age-simple"
              />
            ) : (
              <InputBase
                className={
                  disabled ? 'outlined-select-disabled' : 'outlined-select'
                }
                name={name}
                id="outlined-age-simple"
              />
            )
          }
        >
          {options.map((option: any, i: number) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

export default SimpleSelect
