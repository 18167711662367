import React from 'react'
import { action, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { catchAction } from '../../../store/editor/catchActionsDecorator'
import { ICreateStore } from '../../../store/editor/createStore'
import { IContractsStore } from '../../../store/editor/contractsStore'

const dictionary = {
  fr: {
    cancel: 'Annuler',
    from_another_model: 'Depuis un autre modèle',
    blank_model: 'Nouveau modèle',
    cta_or: 'OU'
  },
  en: {
    cancel: 'Cancel',
    from_another_model: 'From another model',
    blank_model: 'New model',
    cta_or: 'OR'
  }
}

I18n.putVocabularies(dictionary)

type IMainViewProps = {
  closeModal: () => void
  setView: (view: 'main' | 'copy') => void
  contractsStore?: IContractsStore
  createStore?: ICreateStore
}

@inject('contractsStore', 'createStore')
@observer
class MainView extends React.Component<IMainViewProps> {
  @catchAction
  @action
  newBlankModal = async () => {
    const { createStore, contractsStore, closeModal } = this.props
    if (createStore === undefined || contractsStore === undefined) return
    const { setMetaPermalink, setModelId, setDocument, saveModel } = createStore
    const { contractMeta } = contractsStore

    if (contractMeta.permalink === undefined) return

    // Getting permalink
    const { permalink } = contractMeta

    // Turning on loader
    createStore.isModelLoading = true

    // Closing modal
    closeModal()

    // Set Permalink in store
    await setMetaPermalink(permalink)
    // Reset Id in store
    await setModelId(-1)
    // Reset document in store
    await setDocument()
    // create new model
    await saveModel(createStore.document, true)
    const { meta_permalink, modelId, getTemplateData } = createStore
    // get model data
    await getTemplateData(meta_permalink, modelId)

    runInAction(() => {
      // Turning off loadesr
      createStore.isModelLoading = false
    })

    // redirect to editor
    window.location.href = `/contracts/create/${permalink}/${modelId}`
  }

  render() {
    const { closeModal, setView } = this.props
    return (
      <>
        <div className="nmm-main-view">
          <div>
            <button
              type="button"
              className="primary"
              onClick={this.newBlankModal}
            >
              {I18n.get('blank_model')}
            </button>
          </div>
          <div className="or">{I18n.get('cta_or')}</div>
          <div>
            <button
              type="button"
              className="primary"
              onClick={() => setView('copy')}
            >
              {I18n.get('from_another_model')}
            </button>
          </div>
        </div>
        <div className="nmm-footer">
          <div className="align-left">
            <button type="button" className="secondary" onClick={closeModal}>
              {I18n.get('cancel')}
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default MainView
