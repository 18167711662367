import React from 'react'
import Leaf from './Leaf'

const StrongElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <Leaf leaf={{ bold: true }} {...props.attributes}>
      {props.children}
    </Leaf>
  )
}

export default StrongElement
