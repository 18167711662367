import { I18n } from 'aws-amplify'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import Icon from '../../../../../components/Icons/Icon'
import {
  ARROWBACKLEFT,
  ARROWBACKRIGHT,
  SETTINGS
} from '../../../../../components/Icons/icons'
import MaterialSelect from '../../../../../components/MaterialSelect'
import {
  ICreateStore,
  IHistory,
  ISection
} from '../../../../../store/editor/createStore'
import { IUIStore } from '../../../../../store/editor/uiStore'
import './style.scss'

const dictionary = {
  fr: {
    'New document': 'Nouveau document'
  },
  en: {
    'New document': 'New document'
  }
}

I18n.putVocabularies(dictionary)

interface IFormToolbarProps {
  createStore: ICreateStore
  uiStore?: IUIStore | any
  addSection(): void
  setSection(val: number): void
  currentSectionId: number
  currentSection: any
  sections: ISection[]
  undo(): void
  redo(): void
  toggleDrawerHandler(open: boolean): void
  updateSelectedElement(el: any, ids: any[]): void
  currentState: number
  history: IHistory[]
}

@inject('uiStore')
@observer
class FormToolbar extends Component<IFormToolbarProps> {
  @observable isElementsListShown = false

  handleSetSection = (val: number) => {
    const { setSection, sections } = this.props

    const sectionsLength = sections.length
    if (!sectionsLength || val < 0 || val >= sectionsLength) return
    setSection(val)
  }

  handleSelectSection = (name: string, val: any) => {
    const { setSection } = this.props
    setSection(val)
  }

  handleSettingsClick = () => {
    const {
      updateSelectedElement,
      toggleDrawerHandler,
      currentSection
    } = this.props
    updateSelectedElement(currentSection, [])
    toggleDrawerHandler(true)
  }

  render() {
    const { isDrawerOpen } = this.props.uiStore
    const {
      addSection,
      currentSectionId,
      currentSection,
      sections,
      undo,
      redo,
      currentState,
      history
    } = this.props
    // options with section indexes and labels
    const options = sections.map((section: any, key: number) => ({
      value: key,
      label: `${key + 1}. ${section.label
        .replace(/<\s*\w+[^>]*>/gm, '')
        .replace(/<\s*\/\s*\w+>/gm, '')}`
    }))
    // getting current section index
    const sectionIndex = sections.indexOf(currentSection)

    const hasNextSection = sections.length - 1 > sectionIndex
    const hasPreviousSection = sectionIndex > 0

    return (
      <div className="create-form-toolbar">
        <CSSTransition
          in={isDrawerOpen}
          timeout={300}
          classNames="toolbar-drawer"
        >
          <div className="settings-group">
            <div className="undo-redo">
              <button
                type="button"
                disabled={currentState < 0}
                onClick={() => undo()}
                className="btn-back active"
              >
                <Icon
                  iconClass="arrow-back-left"
                  icon={ARROWBACKLEFT}
                  color="#fff"
                />
              </button>
              <button
                type="button"
                disabled={currentState === history.length - 1}
                onClick={() => redo()}
                className="btn-back"
              >
                <Icon
                  iconClass="arrow-back-right"
                  icon={ARROWBACKRIGHT}
                  color="#fff"
                />
              </button>
            </div>
          </div>
        </CSSTransition>
        <div className="content-group">
          <button
            type="button"
            className="btn-icon secondary"
            onClick={this.handleSettingsClick}
          >
            <Icon
              iconClass="setting-ico"
              icon={SETTINGS}
              color="rgb(173, 181, 189)"
            />
          </button>
          <MaterialSelect
            options={options}
            fieldClass="fieldContent"
            setFieldValue={this.handleSelectSection}
            name="content"
            value={currentSectionId}
          />

          {/* <SelectField */}
          {/* fieldClass="fieldContent" */}
          {/* label={''} */}
          {/* options={options} */}
          {/* setFieldValue={this.handleSelectSection} */}
          {/* name="content" */}
          {/* value={currentSectionId} */}
          {/* /> */}
          <button
            onClick={addSection}
            type="button"
            className="btn-icon-plus secondary"
          >
            +
          </button>
        </div>
        <div className="undo-redo btn-holder">
          <button
            onClick={() => this.handleSetSection(sectionIndex - 1)}
            type="button"
            className={`primary${hasPreviousSection ? '' : ' disabled'}`}
          >
            <span className="toolbar-left-icon">&#8592;</span>
          </button>
          <button
            onClick={() => this.handleSetSection(sectionIndex + 1)}
            type="button"
            className={`primary${hasNextSection ? '' : ' disabled'}`}
          >
            <span className="toolbar-right-icon">&#8594;</span>
          </button>
        </div>
      </div>
    )
  }
}

export default FormToolbar
