import React, { useState, useEffect } from 'react'
import { FDFBoxesV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import PdfOptionEditor from './PdfOptionEditor'

const PdfBoxEditor: React.FC<{
  box: FDFBoxesV3
}> = ({ box }) => {
  const [linkedOptions, setLinkedOptions] = useState<number[]>(
    box.options.map((v) => v.id)
  )

  useEffect(() => {
    setLinkedOptions(box.options.map((v) => v.id))
  }, [box.options])

  // Insert option
  const insertOption = () => {
    box.options.push({ id: 0 })
    setLinkedOptions(box.options.map((v) => v.id))
  }

  const deleteOption = (index: number) => {
    box.options.splice(index, 1)
    setLinkedOptions(box.options.map((v) => v.id))
  }
  return (
    <div>
      <div className="label">
        <b>{I18n.get('Name:')}</b>
        {` ${box.name}`}
      </div>
      <div className="items">
        {linkedOptions.map((id, index) => {
          const option = box.options[index]
          return (
            <PdfOptionEditor
              key={`linked-option-${index}-${id}`}
              linkedOption={option}
              deleteOption={() => deleteOption(index)}
            />
          )
        })}
        <div className="link-item-cta" onClick={insertOption}>
          {I18n.get('Link option')}
        </div>
      </div>
    </div>
  )
}

export default PdfBoxEditor
