import React from 'react'
import ReactQuill from 'react-quill'
import { OptionEditorStateType } from '../../editors/OptionEditor'

const QuillModules = {
  toolbar: [['bold', 'italic', 'underline', 'link']]
}
/**
 * Edits option helper content
 */
export const HelperContent: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const { helper } = state.option.meta

  if (helper === undefined) return null

  return (
    <div data-cypressId="helper-text">
      <ReactQuill
        className="editor"
        modules={QuillModules}
        value={helper}
        onChange={(val: string) => state.updateHelper(val)}
      />
    </div>
  )
}
