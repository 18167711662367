import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { NavLink } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import styles from './Navigation.module.scss'
import Icon from '../Icons/Icon'
import {
  ALLFILES,
  ALLTEMPLATES,
  SETTINGS,
  USERS,
  SIGNATURE
} from '../Icons/icons'

interface INavigationProps {}

const columns = {
  en: {
    nav_new_file: 'New File',
    nav_my_files: 'My Files',
    nav_all_files: 'All Files',
    contracts: 'Contracts',
    signatures: 'Signatures',
    templates: 'Templates',
    nav_users: 'Users',
    nav_organization: 'My organization',
    copyright: 'LegalPlace SAS'
  },
  fr: {
    nav_new_file: 'New File',
    nav_my_files: 'My Files',
    nav_all_files: 'All Files',
    contracts: 'Contrats',
    signatures: 'Signatures',
    templates: 'Templates',
    nav_users: 'Utilisateurs',
    nav_organization: 'Mon organisation',
    copyright: 'LegalPlace SAS'
  }
}

I18n.putVocabularies(columns)

@observer
class Navigation extends Component<INavigationProps> {
  public render(): ReactNode {
    return (
      <div className={styles.navContainer}>
        <nav className={styles.mainMenu}>
          {/* <NavLink className={styles.link} exact to='/dashboard/' activeClassName={styles.active}> */}
          {/* <Icon iconWrapperClass={styles.iconWrapper} iconClassDefault={styles.icon} iconClass={'iconPlus'} icon={PLUS}/> */}
          {/* <h4 className={styles.text}>{I18n.get('nav_new_file')}</h4> */}
          {/* </NavLink> */}
          {/* <NavLink className={styles.link} to='/dashboard/my-files' activeClassName={styles.active}> */}
          {/* <Icon iconWrapperClass={styles.iconWrapper} iconClassDefault={styles.icon} iconClass={'iconMyFiles'} icon={MYFILES}/> */}
          {/* <h4 className={styles.text}>{I18n.get('nav_my_files')}</h4> */}
          {/* </NavLink> */}
          <NavLink
            className={styles.link}
            to="/dashboard/all-files"
            activeClassName={styles.active}
          >
            <Icon
              iconWrapperClass={styles.iconWrapper}
              iconClassDefault={styles.icon}
              iconClass="iconAllFiles"
              icon={ALLFILES}
            />
            <h4 className={styles.text}>{I18n.get('contracts')}</h4>
          </NavLink>
          <NavLink
            className={styles.link}
            to="/dashboard/signatures"
            activeClassName={styles.active}
          >
            <Icon
              iconWrapperClass={styles.iconWrapper}
              iconClassDefault={styles.icon}
              iconClass="iconAllFiles"
              icon={SIGNATURE}
            />
            <h4 className={styles.text}>{I18n.get('signatures')}</h4>
          </NavLink>
          <NavLink
            className={styles.link}
            to="/dashboard/all-templates"
            activeClassName={styles.active}
          >
            <Icon
              iconWrapperClass={styles.iconWrapper}
              iconClassDefault={styles.icon}
              iconClass="iconAllTemplates"
              icon={ALLTEMPLATES}
            />
            <h4 data-cypressId="templates" className={styles.text}>
              {I18n.get('templates')}
            </h4>
          </NavLink>
          <NavLink
            className={styles.link}
            to="/dashboard/users"
            activeClassName={styles.active}
          >
            <Icon
              iconWrapperClass={styles.iconWrapper}
              iconClassDefault={styles.icon}
              iconClass="iconUsers"
              icon={USERS}
            />
            <h4 className={styles.text}>{I18n.get('nav_users')}</h4>
          </NavLink>
          <NavLink
            className={styles.link}
            to="/dashboard/organization"
            activeClassName={styles.active}
          >
            <Icon
              iconWrapperClass={styles.iconWrapper}
              iconClassDefault={styles.icon}
              iconClass="iconSettings"
              icon={SETTINGS}
            />
            <h4 className={styles.text}>{I18n.get('nav_organization')}</h4>
          </NavLink>
        </nav>
        <footer>
          <nav className={styles.footerMenu}>
            <NavLink
              className={styles.link}
              exact
              to="/dashboard/"
              activeClassName={styles.active}
            >
              <h4 className={styles.text}>{I18n.get('copyright')} © 2019</h4>
            </NavLink>
          </nav>
        </footer>
      </div>
    )
  }
}

export default Navigation
