import React from 'react'
import { cx, css } from 'emotion'

const Button = React.forwardRef(({ className, active, ...props }: any, ref) => {
  let style = `color: #555;`

  if (active) {
    style = `
        color: #2571DB;
        background: #D6F0FF;
      `
  }
  return (
    <span
      {...props}
      ref={ref}
      className={cx(
        className + (active ? ' active' : ''),
        css`
          & {
            cursor: pointer;
            text-align: center;
            line-height: 24px;
            min-width: 24px;
            border-radius: 4px;
            display: flex;
            text-align: center;
            padding: 3px;
            box-sizing: border-box;
            height: 24px;
            ${style}

            &.disabled {
              opacity: 0.5;
            }

            .icon-holder {
              float: left;
              width: 24px;
              height: 24px;
              svg {
                width: 18px;
                height: 18px;
              }
            }

            .text {
              line-height: 18px;
              padding: 0 4px;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
            }

            &.repeated-output-cta {
              self-align: flex-end;
              margin-left: auto;
              margin-right: 4px;

              .icon-holder {
                width: 18px;
                height: 18px;

                svg {
                  width: 14px;
                }
              }

              &.active {
                background: #dbe5fe;
                color: #4c6ef5;

                svg {
                  path {
                    fill: #4c6ef5;
                  }
                }
              }
            }

            &.conditional-output-cta {
              self-align: flex-end;
              margin-left: auto;
              margin-right: 4px;

              &.active {
                background: #fff2cc;
                color: #db7900;

                svg {
                  path {
                    fill: #db7900;
                  }
                }
              }
            }
          }
        `
      )}
    />
  )
})

Button.displayName = 'Button'

export default Button
