import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing.unit * 3
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#259bf4',
    fontSize: 16,
    width: 'auto',
    padding: '12px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {}
  }
}))(InputBase)

const styles = (theme: any) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing.unit
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
})

const CustomSelect: React.FC<any> = (props) => {
  const { classes, label, name, id, children } = props
  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={(e) => e.preventDefault()}
    >
      <FormControl style={{ width: '170px' }} className={classes.margin}>
        <div className="bootstrap-label">{label}</div>
        <Select
          onChange={() => {}}
          input={<BootstrapInput name={name} id={id} />}
        >
          {children}
        </Select>
      </FormControl>
    </form>
  )
}

// @ts-ignore
export default withStyles(styles)(CustomSelect)
