import React from 'react'
import { I18n } from 'aws-amplify'
import { ExtractV3 } from '@legalplace/models-v3-types'
import createStore from '../../../store/editor/createStore'
import Icon from '../../Icons/Icon'
import {
  DELICON,
  LISTICON,
  FIELDICON,
  RADIOICON,
  CHECKICON
} from '../../Icons/icons'
import Select from '../../../pages/Editor/CreateContractPage/slate-editor/components/select/Select'
import getElementsList from '../helpers/getElementsList'
import './extracts.scss'
import CheckboxField from '../../Editor/CheckboxField'
import Input from '../../Editor/InputField'
import deepObjectSetValue from '../helpers/deepObjectSetValue'

const dictionary = {
  fr: {
    extracts_list: 'Liste des éléments à extraire',
    'type-list': 'Liste déroulante',
    'type-text': 'Champ texte',
    'type-date': 'Champ date',
    'type-textarea': 'Champ multi-lignes',
    'type-number': 'Champ nombre',
    'type-eval': 'Champ calculatrice',
    'type-email': 'Champ email',
    'type-hour': 'Champ heur',
    'type-mask': 'Champ masque',
    extract_element_label: 'Element à extraire',
    slack: 'Slack',
    mixpanel: 'Mixpanel',
    sendinblue: 'Sendinblue',
    name: 'Nom',
    channel: 'Salon'
  },
  en: {}
}

I18n.putVocabularies(dictionary)

type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

type ExtractsParamsModalStateType = {
  extracts: ExtractV3[]
}

class ExtractsParamsModal extends React.Component<
  ParamsModalTabPropsType,
  ExtractsParamsModalStateType
> {
  constructor(props: ParamsModalTabPropsType) {
    super(props)

    const { getParams } = props

    this.state = {
      extracts: createStore.document.customization?.meta?.extracts || [],
      ...getParams()
    }
  }

  updateParams = (newParams: Record<string, any>) => {
    const { setParams } = this.props

    this.setState({ ...this.state, ...newParams })
    setParams(newParams)
  }

  renderVariableLabel = (id: number, level: number) => {
    const { document } = createStore

    const variable = document.variables[id]

    return {
      label: (
        <div className="extract-variable-select">
          <div className="extract-variable-level">
            {new Array(level).fill('\x10\x10\x10\x10').join('')}
          </div>
          <div className="extract-variable-label">
            {variable.type === 'list' ? (
              <Icon iconClass="list-ico" icon={LISTICON} color="#000" />
            ) : (
              <Icon iconClass="list-ico" icon={FIELDICON} color="#000" />
            )}
            {variable.label}
            <span className="variable-type">
              {I18n.get(`type-${variable.type}`)}
            </span>
            <span className="variable-id">#{variable.id}</span>
          </div>
        </div>
      ),
      query: [variable.label, variable.id].filter((v) =>
        ['number', 'string'].includes(typeof v)
      )
    }
  }

  renderOptionLabel = (id: number, level: number) => {
    const { document } = createStore

    const option = document.options[id]

    let icon = <span className="ico-holder-text">?</span>
    if (option.meta.type === 'radio')
      icon = <Icon icon={RADIOICON} color="#000" />
    if (option.meta.type === 'checkbox')
      icon = <Icon icon={CHECKICON} color="#000" />

    const query = [
      option.meta.label,
      option.meta.fallbackLabel,
      option.meta.id
    ].filter((v): v is string | number => v !== undefined)

    return {
      disabled: option.meta.type === 'static',
      label: (
        <div className="extract-option-select">
          <div className="extract-option-level">
            {new Array(level).fill('\x10\x10\x10\x10').join('')}
          </div>
          <div className="extract-option-label">
            {icon}
            {option.meta.label}
            <span className="option-id">#{option.meta.id}</span>
          </div>
        </div>
      ),
      query
    }
  }

  getElements = () => {
    const result: Record<string, any> = {}

    getElementsList().forEach((elem) => {
      let labelRender:
        | string
        | JSX.Element
        | {
            label: string | JSX.Element
            disabled?: boolean
            query?: (string | number) | (string | number)[]
          } = elem.label

      switch (elem.type) {
        case 'section':
          labelRender = {
            label: (
              <h3 className="extract-section">
                {elem.id}. {elem.label}
              </h3>
            ),
            disabled: true,
            query: elem.label
          }
          break
        case 'variable':
          labelRender = this.renderVariableLabel(elem.id, elem.level)
          break
        case 'option':
          labelRender = this.renderOptionLabel(elem.id, elem.level)
          break
        default:
          labelRender = elem.label
      }
      result[`${elem.type}-${elem.id}`] = labelRender
    })

    return result
  }

  renderExtract = (index: number) => {
    const extract = this.state.extracts[index]

    const deleteCurrentExtract = () => {
      const newExtracts = [
        ...this.state.extracts.slice(0, index),
        ...this.state.extracts.slice(index + 1)
      ]
      this.updateParams({ extracts: newExtracts })
    }

    const updateCurrentExtract = (e: ExtractV3) => {
      const newExtracts = [
        ...this.state.extracts.slice(0, index),
        e,
        ...this.state.extracts.slice(index + 1)
      ]
      this.updateParams({ extracts: newExtracts })
    }

    const setElement = (value: string) => {
      const [type, id] = value.split('-')

      if (
        ((t): t is 'option' | 'variable' => ['option', 'variable'].includes(t))(
          type
        )
      ) {
        updateCurrentExtract({
          ...extract,
          type,
          id: parseInt(id, 10)
        })
      }
    }

    const setDestination = (
      destination: 'slack' | 'mixpanel' | 'sendinblue',
      enabled: boolean
    ) => {
      let destinationParams
      if (enabled === false) {
        destinationParams = undefined
      } else {
        switch (destination) {
          case 'slack':
            destinationParams = {
              name: '',
              channel: ''
            }
            break
          default:
            destinationParams = {
              name: ''
            }
        }
      }
      updateCurrentExtract({
        ...extract,
        destination: {
          ...extract.destination,
          [destination]: destinationParams
        }
      })
    }

    const setDestinationValue = (
      name: string,
      value: string | number | boolean
    ) => {
      const newExtract = { ...extract }
      deepObjectSetValue(newExtract, name, value)
      updateCurrentExtract(newExtract)
    }

    return (
      <div className="extract-element">
        <div className="extract-header">
          {I18n.get('extract_element_label')}

          <div className="extract-delete" onClick={deleteCurrentExtract}>
            <svg viewBox="0 0 16px 16px" fill="#CCCCCC">
              {DELICON}
            </svg>
          </div>
        </div>
        <div className="extract-body">
          <div className="extract-var">
            <Select
              options={this.getElements()}
              enableFilter
              value={`${extract.type}-${extract.id}`}
              onChange={setElement}
            />
          </div>
          <div className="extract-providers">
            <div className="extract-row">
              <div className="checkbox">
                <CheckboxField
                  setFieldValue={(n: string, v: boolean) =>
                    setDestination('slack', v)
                  }
                  checked={extract.destination.slack !== undefined}
                  label={I18n.get('slack')}
                />
              </div>
              {extract.destination.slack !== undefined && (
                <div className="variables">
                  <div>
                    <Input
                      name="destination.slack.name"
                      setFieldValue={setDestinationValue}
                      value={extract.destination.slack.name}
                      placeholder={I18n.get('name')}
                    />
                  </div>
                  <div>
                    <Input
                      name="destination.slack.channel"
                      setFieldValue={setDestinationValue}
                      value={extract.destination.slack.channel}
                      placeholder={I18n.get('channel')}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="extract-row">
              <div className="checkbox">
                <CheckboxField
                  setFieldValue={(n: string, v: boolean) =>
                    setDestination('mixpanel', v)
                  }
                  checked={extract.destination.mixpanel !== undefined}
                  label={I18n.get('mixpanel')}
                />
              </div>
              {extract.destination.mixpanel !== undefined && (
                <div className="variables">
                  <div>
                    <Input
                      name="destination.mixpanel.name"
                      setFieldValue={setDestinationValue}
                      value={extract.destination.mixpanel.name}
                      placeholder={I18n.get('name')}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="extract-row">
              <div className="checkbox">
                <CheckboxField
                  setFieldValue={(n: string, v: boolean) =>
                    setDestination('sendinblue', v)
                  }
                  checked={extract.destination.sendinblue !== undefined}
                  label={I18n.get('sendinblue')}
                />
              </div>
              {extract.destination.sendinblue !== undefined && (
                <div className="variables">
                  <div>
                    <Input
                      name="destination.sendinblue.name"
                      setFieldValue={setDestinationValue}
                      value={extract.destination.sendinblue.name}
                      placeholder={I18n.get('name')}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  addExtract = () => {
    this.updateParams({
      extracts: [
        ...this.state.extracts,
        {
          type: 'option',
          id: 0,
          destination: {}
        }
      ]
    })
  }

  render() {
    return (
      <div>
        <div className="pm-block">
          <h3>{I18n.get('extracts_list')}</h3>
          <div className="extracts-list">
            {this.state.extracts.map((v, i) => this.renderExtract(i))}
          </div>
          <div className="add-extract">
            <button className="btn-add" type="button" onClick={this.addExtract}>
              Ajouter un élément à extraire
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ExtractsParamsModal
