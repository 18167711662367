import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { ICreateStore } from '../../store/editor/createStore'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'No output selected': 'Aucun output sélectionné',
    Close: 'Fermer'
  },
  en: {
    'No output selected': 'No output selected',
    Close: 'Close'
  }
}

I18n.putVocabularies(dictionary)

interface ISelectOutputModalProps {
  createStore?: ICreateStore | any
  open: boolean
}

@inject('createStore')
@observer
class SelectOutputModal extends Component<ISelectOutputModalProps> {
  @catchAction
  @action
  public toggleModal = () => {
    const { toggleSelectOutputModal } = this.props.createStore
    toggleSelectOutputModal(false, false)
  }

  render() {
    return (
      <Dialog
        onClose={() => this.toggleModal()}
        open={this.props.open}
        className="delete-condition-modal"
      >
        <div className="message">
          <h3>{I18n.get('No output selected')}</h3>
        </div>
        <div className="buttons-holder">
          <button type="button" onClick={this.toggleModal} className="primary">
            {I18n.get('Close')}
          </button>
        </div>
      </Dialog>
    )
  }
}

export default SelectOutputModal
