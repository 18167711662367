import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    'Number of lines': 'Nombre de lignes'
  },
  en: {
    'Number of lines': 'Number of lines'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits textarea line numbers
 */
export const TextareaLineNumbers: React.FC<{
  state: VariableEditorStateType
}> = ({ state }) => {
  const { textarea } = state.variable

  return (
    <div className="label-holder">
      <Input
        value={textarea?.lineNumbers || 0}
        name="eval_decimals"
        type="number"
        setFieldValue={(name: string, value: string | number) => {
          state.updateTextareaLineNumbers(
            typeof value === 'number' ? value : parseInt(value, 10)
          )
        }}
        label={I18n.get('Number of lines')}
      />
    </div>
  )
}
