import React from 'react'
import Leaf from './Leaf'
import VariableLeaf from './VariableLeaf'
import VariableHookLeaf from './VariableHookLeaf'

const renderLeaf = (props: any) => {
  if (props.leaf.type === 'variable') return <VariableLeaf {...props} />
  if (props.leaf.type === 'variable-hook')
    return <VariableHookLeaf {...props} />
  return <Leaf {...props} />
}

export default renderLeaf
