import React, { useState, useEffect } from 'react'
import { FDFInputsV3, VariableV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import createStore from '../../../../../../../../store/editor/createStore'
import Select from '../../../../../slate-editor/components/select/Select'
import Icon from '../../../../../../../../components/Icons/Icon'
import { DELICON } from '../../../../../../../../components/Icons/icons'
import Input from '../../../../../../../../components/Editor/InputField'

type ArrayElement<ArrayType extends unknown[]> = ArrayType[any]

const PdfVariableEditor: React.FC<{
  linkedVariable: ArrayElement<FDFInputsV3['variables']>
  deleteVariable: () => void
}> = ({ linkedVariable, deleteVariable }) => {
  // We'll store our variable here
  const [variable, setVariable] = useState<VariableV3 | undefined>(undefined)
  const [linkedState, setLinkedState] = useState({ ...linkedVariable })

  const { id } = linkedVariable
  const { document } = createStore

  // Getting our variable from state
  useEffect(() => {
    if (variable === undefined && document.variables[id]) {
      setVariable(document.variables[id])
    }
  }, [variable, id, document])

  const handleVariableSelection = (variableId: string) => {
    linkedVariable.id = parseInt(variableId, 10)
    setVariable(document.variables[variableId])
  }

  const variablesList: Record<string, JSX.Element> = {}
  Object.values(document.variables).forEach((currentVariable) => {
    variablesList[currentVariable.id] = (
      <>
        <b>#{currentVariable.id}</b> {currentVariable.label}
      </>
    )
  })

  const dateOptionsList = {
    FULL: I18n.get('Full'),
    DD: I18n.get('Jour'),
    MM: I18n.get('Mois'),
    YYYY: I18n.get('Année')
  }
  const updateDate = (date?: string) => {
    if (date === 'DD' || date === 'MM' || date === 'YYYY')
      linkedVariable.date = date
    else linkedVariable.date = undefined
    setLinkedState({ ...linkedVariable })
  }

  const hourOptionsList = {
    FULL: I18n.get('Full'),
    HH: I18n.get('Heure'),
    MM: I18n.get('Minutes')
  }
  const udpateHour = (hour?: string) => {
    if (hour === 'HH' || hour === 'MM') linkedVariable.hour = hour
    else linkedVariable.hour = undefined
    setLinkedState({ ...linkedVariable })
  }

  const emailOptionsList = {
    FULL: I18n.get('Full'),
    ADDRESS: I18n.get('Address'),
    PROVIDER: I18n.get('Provider')
  }
  const updateEmail = (email?: string) => {
    if (email === 'ADDRESS' || email === 'PROVIDER')
      linkedVariable.email = email
    else linkedVariable.email = undefined
    setLinkedState({ ...linkedVariable })
  }

  const updateFragmentFrom = (from?: number) => {
    if (
      (typeof from !== 'number' || Number.isNaN(from)) &&
      linkedVariable.fragment?.from !== undefined
    )
      linkedVariable.fragment = {
        ...linkedVariable.fragment,
        from: undefined
      }
    else if (from !== undefined)
      linkedVariable.fragment = {
        ...linkedVariable.fragment,
        from
      }
    setLinkedState({ ...linkedVariable })
  }

  const updateFragmentTo = (to?: number) => {
    if (
      (typeof to !== 'number' || Number.isNaN(to)) &&
      linkedVariable.fragment?.to !== undefined
    )
      linkedVariable.fragment = {
        ...linkedVariable.fragment,
        to: undefined
      }
    else if (to !== undefined)
      linkedVariable.fragment = {
        ...linkedVariable.fragment,
        to
      }
    setLinkedState({ ...linkedVariable })
  }

  return (
    <div className="linked-item-wrapper">
      <div className="lv-item">
        <label>{I18n.get('Variable:')}</label>
        <Select
          options={variablesList}
          value={id.toString()}
          enableFilter
          onChange={handleVariableSelection}
        />
      </div>
      {variable && variable.type === 'date' && (
        <div className="lv-item">
          <label>{I18n.get('Display:')}</label>
          <Select
            options={dateOptionsList}
            value={linkedState.date || 'FULL'}
            onChange={updateDate}
          />
        </div>
      )}
      {variable && variable.type === 'hour' && (
        <div className="lv-item">
          <label>{I18n.get('Display:')}</label>
          <Select
            options={hourOptionsList}
            value={linkedState.hour || 'FULL'}
            onChange={udpateHour}
          />
        </div>
      )}
      {variable && variable.type === 'email' && (
        <div className="lv-item">
          <label>{I18n.get('Display:')}</label>
          <Select
            options={emailOptionsList}
            value={linkedState.email || 'FULL'}
            onChange={updateEmail}
          />
        </div>
      )}
      {variable && !['date', 'hour', 'email'].includes(variable.type) && (
        <div className="lv-item">
          <label>{I18n.get('Display:')}</label>
          <div>
            <Input
              label={I18n.get('from')}
              type="number"
              value={linkedState.fragment?.from}
              placeholder={I18n.get('from')}
              setFieldValue={(name: undefined, value: string) =>
                updateFragmentFrom(parseInt(value, 10))
              }
            />
            <Input
              label={I18n.get('to')}
              type="number"
              value={linkedState.fragment?.to}
              placeholder={I18n.get('to')}
              setFieldValue={(name: undefined, value: string) =>
                updateFragmentTo(parseInt(value, 10))
              }
            />
          </div>
        </div>
      )}
      <div className="lv-footer">
        <button
          data-cypressId="del-btn"
          type="button"
          className="btn-icon"
          tabIndex={-1}
          onClick={() => deleteVariable()}
        >
          <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
        </button>
      </div>
    </div>
  )
}

export default PdfVariableEditor
