import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { match } from 'react-router'

import AbTesting from './AbTesting/AbTesting'
import Checkbox from '../../../components/Editor/Checkbox/Checkbox'
import dictionary from './dictionary'
import Divider from '../../../components/Editor/Divider/Divider'
import IconInput from '../../../components/Editor/IconInput/IconInput'
import { IContractsStore } from '../../../store/editor/contractsStore'
import { IModelsStore } from '../../../store/editor/modelsStore'
import Input from '../../../components/Editor/Input/Input'
import Title from '../../../components/Editor/Title/Title'
import Select from '../../../components/Editor/Select/Select'
import './style.scss'

const langs = [
  {
    value: 'fr_FR',
    text: 'Français'
  },
  {
    value: 'de_DE',
    text: 'Allemand'
  }
]

I18n.putVocabularies(dictionary)
// I18n.setLanguage('fr');

const categories = [
  {
    value: '1',
    text: 'Contrat de location'
  },
  {
    value: '2',
    text: 'Famille'
  },
  {
    value: '3',
    text: 'Création de sociétés'
  },
  {
    value: '6',
    text: 'Propriété Intellectuelle'
  },
  {
    value: '7',
    text: 'Contrat de travail'
  },
  {
    value: '9',
    text: 'Vie quotidienne'
  },
  {
    value: '10',
    text: 'Droit social'
  },
  {
    value: '11',
    text: 'Mietvertrag'
  },
  {
    value: '12',
    text: 'Locacao'
  },
  {
    value: '13',
    text: 'Kaufvertrag'
  },
  {
    value: '14',
    text: 'Carte grise en ligne'
  },
  {
    value: '15',
    text: 'Création entreprise'
  }
]
const types = [
  {
    value: 'demarche',
    text: 'Démarche'
  },
  {
    value: 'contrat',
    text: 'Contrat'
  }
]
// const version = [
//   {
//     value: '2',
//     text: '2'
//   },
//   {
//     value: '4',
//     text: '4'
//   },
//   {
//     value: '5',
//     text: '5'
//   },
//   {
//     value: '7',
//     text: '7'
//   }];
const payment = [
  {
    value: '0',
    text: 'PostPayment Standard'
  },
  {
    value: '1',
    text: 'Texte avec Facture Optionnelle'
  },
  {
    value: '2',
    text: 'Facture Obligatoire'
  }
]

interface IRouteParams {
  contractId: string
}

interface IEditContractPageProps {
  modelsStore?: IModelsStore | any
  contractsStore?: IContractsStore | any
  match: match<IRouteParams>
}

@inject('modelsStore', 'contractsStore')
@observer
class EditContractPage extends Component<IEditContractPageProps> {
  componentDidMount() {
    const {
      match: {
        params: { contractId }
      }
    } = this.props
    this.props.contractsStore
      .fetchContractMeta(contractId)
      .then(() => {
        this.props.modelsStore.fetchModels(contractId)
      })
      .catch((err: Error) => {
        console.log('Get contract meta failed: ', err)
      })
  }

  public render(): ReactNode {
    const {
      name,
      permalink,
      type,
      locale,
      version_in_production,
      do_not_send_welcome_email,
      do_not_send_docs,
      category_order,
      versions_ab_testing
    } = this.props.contractsStore.contractMeta
    const { models } = this.props.modelsStore
    let versionsList = null
    if (!versionsList && models.length) {
      versionsList = models.map((model: any) => {
        return {
          value: model.version,
          text: model.version
        }
      })
    }

    return (
      <div className="dashboard models">
        <div className="breadcrumbs">
          <Link to="/contracts">{I18n.get('Contracts')}</Link>
          <span> &gt; </span>
          <span>{name || 'loading...'}</span>
        </div>
        <Title
          title={`${name || 'loading...'} - ${I18n.get('Edition')}`}
          link="/"
          showButton={false}
        />
        <Divider />
        {this.props.contractsStore.fetching ? (
          'Loading...'
        ) : (
          <>
            <h3 className="subtitle">{I18n.get('General')}</h3>
            <div className="input-block">
              <Input
                type="text"
                value={name}
                label={I18n.get('Contract name')}
              />
              <Input
                type="text"
                value={permalink}
                label={I18n.get('Permalink')}
              />
              <Input
                type="text"
                label={I18n.get('Text above the title [Create your ...]')}
              />
              <Input
                type="text"
                label={I18n.get(
                  '"Create" button [Fill out this template (Word, PDF)]'
                )}
              />
              <Input type="text" label={I18n.get('Subtitle')} />
              <Input type="text" label={I18n.get('Checkout Title')} />
              <Select options={categories} label={I18n.get('Category')} />
              <Select value={type} options={types} label={I18n.get('Type')} />
              <Select
                value={locale}
                options={langs}
                label={I18n.get('Language')}
              />
              <Select
                value={version_in_production}
                options={versionsList}
                label={I18n.get('Version in production')}
              />
              <Input
                type="text"
                value={category_order}
                label={I18n.get('Order')}
              />
            </div>
            <div className="input-block">
              <Checkbox
                label={I18n.get('Do not show "- LegalPlace" in title')}
                id="disable_branding_title"
              />
              <Checkbox
                label={I18n.get('View subscription')}
                id="enable_subscription"
              />
              <Checkbox
                label={I18n.get(
                  'Do not display in the search bar of the home page'
                )}
                id="hide_on_homepage"
              />
              <Checkbox
                label={I18n.get('Do not show in the documents page')}
                id="remove_from_docs_list"
              />
              <Checkbox
                label={I18n.get('Do not show in category pages')}
                id="remove_from_cat_pages"
              />
              <Checkbox
                label={I18n.get('Ask search engines not to index this page')}
                id="noindex"
              />
            </div>
            <Divider />
            <h3 className="subtitle">{I18n.get('How it works ?')}</h3>
            <div className="input-block">
              <Input
                type="text"
                label={I18n.get(
                  'first step icon in (How does it work?) [create]'
                )}
              />
              <Input
                type="text"
                label={I18n.get(
                  'first step in (How does it work?) [Create your contract]'
                )}
              />
              <Input
                type="text"
                label={I18n.get(
                  'second step icon in (How does it work?) [save]'
                )}
              />
              <Input
                type="text"
                label={I18n.get(
                  'second step in (How does it work?) [Register and print]'
                )}
              />
              <Input
                type="text"
                label={I18n.get(
                  'third step icon in (How does it work?) [sign]'
                )}
              />
              <Input
                type="text"
                label={I18n.get(
                  'third step in (How does it work?) [Create your contract]'
                )}
              />
            </div>
            <Divider />
            <h3 className="subtitle">{I18n.get('SEO')}</h3>
            <div className="seo-container">
              <div>
                <Input type="text" label={I18n.get('Title of the HTML page')} />
                <Input type="text" label={I18n.get('Title about')} />
                <Input type="text" label={I18n.get('Keywords (META)')} />
              </div>
              <div>
                <Input
                  type="text"
                  textarea
                  label={I18n.get('Description (META)')}
                />
              </div>
            </div>
            <Divider />
            <h3 className="subtitle">{I18n.get('Upsell')}</h3>
            <Input
              type="text"
              className="long"
              label={I18n.get(
                'Documents to put in upsell in the download page (permalinks separated by a comma)'
              )}
            />
            <Divider />
            {/* a-b testing here */}
            <AbTesting
              versionsList={versionsList}
              versions={versions_ab_testing}
            />
            <Divider />
            {/* a-b testing here */}
            <Divider />
            <h3 className="subtitle">{I18n.get('Fixed costs per purchase')}</h3>
            <Input type="text" label={I18n.get('Cost')} />
            <Divider />
            <h3 className="subtitle">{I18n.get('Overview')}</h3>
            <Input type="text" label={I18n.get('Preview title')} />
            <Divider />
            <h3 className="subtitle">{I18n.get('Email section')}</h3>
            <div className="input-block">
              <Input type="text" label={I18n.get('Email section title')} />
              <Input type="text" label={I18n.get('Email input label')} />
              <Input type="text" label={I18n.get('Email section warning')} />
              <IconInput label={I18n.get('CTA section email')} />
            </div>
            <Divider />
            <h3 className="subtitle">{I18n.get('Backup section')}</h3>
            <div className="input-block">
              <Input type="text" label={I18n.get('Title section')} />
              <Input type="text" label={I18n.get('Label input')} />
              <Input type="text" label={I18n.get('Top Text')} />
              <Input
                type="text"
                label={I18n.get('Information label (below the field)')}
              />
              <Input type="text" label={I18n.get('Notification of success')} />
              <Input type="text" label={I18n.get('Message of success')} />
            </div>
            <Divider />
            <h3 className="subtitle">{I18n.get('Emails Sendgrid')}</h3>
            <div className="input-block">
              <Checkbox
                checked={do_not_send_welcome_email}
                label={I18n.get('Do not send the welcome email')}
                id="do_not_send_welcome_email"
              />
              <Checkbox
                label={I18n.get('Do not send reminders')}
                id="do_not_send_relances"
              />
              <Checkbox
                checked={do_not_send_docs}
                label={I18n.get('Do not send documents')}
                id="do_not_send_docs"
              />
            </div>
            <Divider />
            <h3 className="subtitle">{I18n.get('Checkout')}</h3>
            <div className="input-block">
              <Input type="text" label={I18n.get('First pack name')} />
              <Input type="text" label={I18n.get('Second pack name')} />
              <Input
                type="text"
                textarea
                label={I18n.get('First pack content')}
              />
              <Input
                type="text"
                textarea
                label={I18n.get('Second pack content')}
              />
              <Checkbox label={I18n.get('Single pack')} id="uniqpack" />
              <Checkbox
                label={I18n.get('Disable additional options')}
                id="disableoptions"
              />
              <Checkbox
                label={I18n.get('Disable billing block')}
                id="disableinvoice"
              />
              <Checkbox
                label={I18n.get(
                  'Disable billing blockDisable the content block of the command'
                )}
                id="disablepackcontent"
              />
              <Checkbox
                label={I18n.get('Disable the second payment CTA')}
                id="disablesecondpaymentcta"
              />
              <div className="input-block">
                <Input type="text" label={I18n.get('CTA Payment checkout')} />
                <Input type="text" label={I18n.get('CTA Payment popup')} />
              </div>
              {/* some field here */}
              <Divider />
              <h3 className="subtitle">{I18n.get('Post payment')}</h3>
              <div className="input-block">
                <Checkbox
                  label={I18n.get(
                    'Post different payment depending on the chosen pack'
                  )}
                  id="postpayment_differ"
                />
              </div>
              <div className="input-block">
                <Select options={payment} />
              </div>
              <Divider />
              <Divider />
              <Divider />
              <h3 className="subtitle">{I18n.get('Auto-recovery bar')}</h3>
              <div className="input-block">
                <Input
                  type="text"
                  className="long"
                  label={I18n.get('Title of the bar')}
                />
                <div className="input-block">
                  <Input
                    type="text"
                    title="EXAMPLE: Votre Acte de mariage a été enregistré il y a 7 minutes"
                    label={I18n.get('Text of the bar')}
                  />
                  <Input
                    type="text"
                    title="EXAMPLE: Reprendre mon accord de confidentialité"
                    label={I18n.get('Button text')}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default EditContractPage
