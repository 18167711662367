import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { IDataStore } from '../../store/dataStore'
import { IContractsStore } from '../../store/editor/contractsStore'
import { ICreateStore, IDocSection } from '../../store/editor/createStore'
import MaterialSelect from '../MaterialSelect'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'main-title': 'Copier une section',
    'From which template?': 'De quel template ?',
    'From which version?': 'De quelle version ?',
    'Which section do you want to copy?':
      'Quelle section souhaitez-vous copier ?\n',
    'After which section do you want to paste this section?':
      'Après quelle section souhaitez-vous insérer cette section ?',
    'cancel move': 'Annuler',
    'save move': 'Enregistrer'
  },
  en: {
    'main-title': 'Copy section',
    'From which template?': 'From which template?',
    'From which version?': 'From which version?',
    'Which section do you want to copy?': 'Which section do you want to copy?',
    'After which section do you want to paste this section?':
      'After which section do you want to paste this section?',
    'cancel move': 'Cancel',
    'save move': 'Save'
  }
}

I18n.putVocabularies(dictionary)

interface ICopySectionModalProps {
  createStore?: ICreateStore | any
  dataStore?: IDataStore | any
  contractsStore?: IContractsStore | any
  open: boolean
  toggleModal(name: string, value: boolean): void
}

@inject('contractsStore', 'dataStore', 'createStore')
@observer
class CopySectionModal extends Component<ICopySectionModalProps> {
  @observable template = ''

  @observable version = ''

  @observable section = ''

  @observable sectionToPaste = ''

  @catchAction
  @action
  public fetchProducts = () => {
    const params: any = new URLSearchParams()
    params.set('noLimit', true)
    this.props.dataStore.fetchProducts(params)
  }

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    toggleModal('copySectionIsOpen', open)
  }

  @catchAction
  @action
  handleSelectTemplate = (name: any, value: any) => {
    this.template = value
    this.props.contractsStore.fetchContractMeta(value)
  }

  @catchAction
  @action
  handleSelectVersion = (name: any, value: any) => {
    this.version = value
    this.props.createStore.getDocumentToCopySection(value)

    // getDocumentToCopySection
  }

  @catchAction
  @action
  handleSelectSection = (name: any, value: any) => {
    this.section = value
  }

  @catchAction
  @action
  handleSelectSectionToPaste = (name: any, value: any) => {
    this.sectionToPaste = value
  }

  @catchAction
  @action
  handleSave = () => {
    this.props.createStore.copySectionFromAnotherDoc(
      this.section,
      this.sectionToPaste
    )
    this.template = ''
    this.version = ''
    this.section = ''
    this.sectionToPaste = ''
    this.props.createStore.documentToCopySection = {}
    this.toggleModal(false)
  }

  render() {
    const templateOptions = this.props.dataStore.allProducts.data.map(
      (template: any) => ({
        value: template.id,
        label: template.name
      })
    )
    const versionOptions =
      this.props.contractsStore.contractMetaInfo.models &&
      this.props.contractsStore.contractMetaInfo.models.map((model: any) => ({
        value: model.id,
        label: model.version
      }))
    const sectionOptions =
      this.props.createStore.documentToCopySection.documents &&
      this.props.createStore.documentToCopySection.documents.main.sections.map(
        (section: any) => ({ value: section.id, label: section.label })
      )
    const sectionToPasteOptions = this.props.createStore.document.documents.main.sections.map(
      (section: IDocSection) => {
        return {
          value: section.id,
          label: section.label
        }
      }
    )

    return (
      <Dialog
        onEnter={() => this.fetchProducts()}
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="copy-modal-holder"
      >
        <div className="copy-modal">
          <h1>{I18n.get('main-title')}</h1>
          <div className="copy-select-holder">
            <h3>{I18n.get('From which template?')}</h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectTemplate(name, value)
              }
              name="template"
              value={this.template}
              options={templateOptions}
            />
          </div>
          <div className="copy-select-holder">
            <h3>{I18n.get('From which version?')}</h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectVersion(name, value)
              }
              name="version"
              disabled={!this.template}
              value={this.version}
              options={versionOptions}
            />
          </div>
          <div className="copy-select-holder">
            <h3>{I18n.get('Which section do you want to copy?')}</h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectSection(name, value)
              }
              name="section"
              disabled={!this.version}
              value={this.section}
              options={sectionOptions}
            />
          </div>
          <div className="copy-select-holder">
            <h3>
              {I18n.get(
                'After which section do you want to paste this section?'
              )}
            </h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectSectionToPaste(name, value)
              }
              name="sectionToPaste"
              disabled={!this.section}
              value={this.sectionToPaste}
              options={sectionToPasteOptions}
            />
          </div>
          <div className="btn-holder">
            <button
              type="button"
              className="secondary"
              onClick={() => this.toggleModal(false)}
            >
              {I18n.get('cancel move')}
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => this.handleSave()}
            >
              {I18n.get('save move')}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default CopySectionModal
