import React, { MouseEvent } from 'react'
import { useSlate } from '@legalplace/slate-react'
import { ReactComponent as ConditionalIcon } from './conditional-icon.svg'
import Button from './Button'
import EditorHelpers from '../../helpers/EditorHelpers'
import createStore from '../../../../../../store/editor/createStore'
import isConditionEmpty from '../../serializers/conditionEmptiness'

type ConditionalButtonProps = {
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ) => void
}
const ConditionalButton: React.FC<ConditionalButtonProps> = ({
  toggleConditionsDrawer
}) => {
  const editor = useSlate()
  const outputs = EditorHelpers.getCurrentOutputs(editor)
  const disabled = outputs.length !== 1
  const active = !disabled && EditorHelpers.isOutputConditional(editor)

  const updateHasConditions = (id: number) => {
    const option = createStore.document.options[id]
    if (option === undefined) return

    const hasConditions =
      typeof option.meta.conditions === 'object' &&
      !isConditionEmpty(option.meta.conditions)

    EditorHelpers.applySelection(editor)
    EditorHelpers.setOutput(editor, id, {
      hasConditions,
      originalOption: JSON.parse(JSON.stringify(option))
    })
  }

  const handleMouseDown = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (disabled === true) return
    const [currentOutput] = outputs
    EditorHelpers.storeSelection(editor)
    toggleConditionsDrawer(true, 'hidden', undefined, {
      id: currentOutput.id,
      onSave: () => updateHasConditions(currentOutput.id)
    })
  }

  return (
    <Button
      active={active}
      onMouseDown={handleMouseDown}
      className={`conditional-output-cta${disabled ? ' disabled' : ''}`}
    >
      <div className="icon-holder">
        <ConditionalIcon />
      </div>
      <div className="text">Conditions</div>
    </Button>
  )
}

export default ConditionalButton
