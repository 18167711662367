import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import createStore, { ICreateStore } from '../../store/editor/createStore'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'This element cannot be deleted:':
      'Vous ne pouvez pas supprimer cet élément car :',
    Close: 'Annuler',
    Unlink: 'Délier automatiquement tout'
  },
  en: {
    'This element cannot be deleted:': 'This element cannot be deleted:',
    Close: 'Close',
    Unlink: 'Unlink everything automatically'
  }
}

I18n.putVocabularies(dictionary)

interface IDeleteConditionModalProps {
  createStore?: ICreateStore | any
  open: boolean
}

@inject('createStore')
@observer
class DeleteConditionModal extends Component<IDeleteConditionModalProps> {
  @catchAction
  @action
  public toggleModal = () => {
    const {
      toggleDeleteConditionModal,
      clearConditionMessage
    } = this.props.createStore
    toggleDeleteConditionModal(false, false)
    clearConditionMessage()
  }

  unlink = () => {
    if (createStore.deletingElement === false) return
    const { id, type } = createStore.deletingElement
    createStore.unlinkDeleteElement(id, type)
    this.toggleModal()
  }

  render() {
    const { deleteConditionMessage, showHeading } = this.props.createStore

    if (deleteConditionMessage.length === 0) {
      return null
    }
    return (
      <Dialog
        onClose={() => this.toggleModal()}
        open={this.props.open}
        className="delete-condition-modal"
      >
        <div className="message">
          {showHeading ? (
            <h3>{I18n.get('This element cannot be deleted:')}</h3>
          ) : (
            ''
          )}
          <ul>
            {deleteConditionMessage.map((message: string, i: number) => {
              return <li key={i}>{message}</li>
            })}
          </ul>
        </div>
        <div className="buttons-holder">
          {createStore.canUnlink && (
            <button
              type="button"
              onClick={this.unlink}
              className="primary unlinkdelete"
            >
              {I18n.get('Unlink')}
            </button>
          )}
          <button type="button" onClick={this.toggleModal} className="primary">
            {I18n.get('Close')}
          </button>
        </div>
      </Dialog>
    )
  }
}

export default DeleteConditionModal
