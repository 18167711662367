import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import { CONDITIONTREEICON } from '../../../../../../../components/Icons/icons'
import createStore from '../../../../../../../store/editor/createStore'
import Icon from '../../../../../../../components/Icons/Icon'

const dictionary = {
  fr: {
    'Validators:': 'Validateurs:',
    'Add new': 'Ajouter un nouveau',
    'Add/Modify': 'Modifier'
  },
  en: {
    'Validators:': 'Validators:',
    'Add new': 'Add new',
    'Add/Modify': 'Add/Modify'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable validators conditions
 */
export const ValidatorConditions: React.FC<{
  state: VariableEditorStateType
}> = ({ state }) => {
  const { variable, toggleConditionsDrawer, toggleDrawer } = state
  const { validator } = variable
  const { conditionsCollector } = createStore

  // Getting conditions list
  let collectedValidators: any = []
  if (validator) {
    // collect conditions
    collectedValidators = conditionsCollector(Object.keys(validator), validator)
  }

  /**
   * Opens conditions editing drawer
   */
  const handleConditionClick = () => {
    // open condition modal
    const {
      toggleParamsWasShown,
      toggleEditingValidators,
      updateSelectedElement
    } = createStore
    const { id } = variable

    // set toggleParamsWasShown to open this drawer right after condition drawer will be closed
    toggleParamsWasShown(true)

    // Making sure editing validators is false
    toggleEditingValidators(true)

    // open condition drawer
    toggleConditionsDrawer(true, 'variable', id)

    // close parameters drawer
    updateSelectedElement(variable, [])
    toggleDrawer(false)
  }

  return (
    <div className="conditions-block">
      <div>
        <Icon iconClass="tree-ico" icon={CONDITIONTREEICON} color="#e32778" />
        {I18n.get('Validators:')}
        <span className="conditions-value">{collectedValidators.length}</span>
      </div>
      <button
        type="button"
        data-cypressId="conditions-btn"
        onClick={() => handleConditionClick()}
        className="secondary"
      >
        {validator && Object.keys(validator).length < 1
          ? I18n.get('Add new')
          : I18n.get('Add/Modify')}
      </button>
    </div>
  )
}
