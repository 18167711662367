import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'

const dictionary = {
  fr: {
    'Field height': 'Hauteur du champ',
    Large: 'Grand',
    Medium: 'Moyen',
    Small: 'Petit',
    Custom: 'Personnalisé'
  },
  en: {
    'Field height': 'Field height'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits textarea size
 */
export const TextareaSize: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { textarea } = state.variable

  const sizes = [
    {
      value: 'l',
      label: I18n.get('Large')
    },
    {
      value: 'm',
      label: I18n.get('Medium')
    },
    {
      value: 's',
      label: I18n.get('Small')
    },
    {
      value: 'p',
      label: I18n.get('Custom')
    }
  ]
  return (
    <div className="label-holder">
      <SelectField
        setFieldValue={(name: string, value: 'l' | 'm' | 's' | 'p') =>
          state.updateTextareaSize(value)
        }
        value={textarea?.size || ''}
        name="style"
        label={I18n.get('Field height')}
        options={sizes}
      />
    </div>
  )
}
