import generalStore from './generalStore'
import authStore from './authStore'
import dialogStore from './dialogStore'
import dataStore from './dataStore'
import userStore from './userStore'

import contractsStore from './editor/contractsStore'
import modelsStore from './editor/modelsStore'
import createStore from './editor/createStore'
import uiStore from './editor/uiStore'

export default {
  authStore,
  dialogStore,
  dataStore,
  userStore,
  generalStore,
  contractsStore,
  modelsStore,
  createStore,
  uiStore
}
