import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import Icon from '../Icons/Icon'
import { PLUS } from '../Icons/icons'
import './style.scss'
import DataListItem from './DataListItem'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface IDataListProps {
  title: string
  data: any[]
  addOption?: any
  type: string
  deleteOption?: any
  handleChange?: any
  newOpts?: any
  toggleModal(val: boolean, type: string): void
  updateOption(name: string, type: string, id: number): void
}

@observer
class DataList extends Component<IDataListProps> {
  @observable isEditing = false

  @catchAction
  @action
  toggleEdit = () => {
    this.isEditing = !this.isEditing
  }

  public render(): ReactNode {
    const {
      title = '',
      addOption,
      deleteOption,
      type,
      handleChange,
      newOpts,
      toggleModal,
      updateOption
    } = this.props

    return (
      <div className="data-list-container">
        <h4 className="list-ttl">{title}</h4>
        <ul className="data-list">
          {newOpts.map((el: any, i: number) => (
            <li key={i}>
              <DataListItem
                updateOption={updateOption}
                type={type}
                i={i}
                el={el}
                deleteOption={deleteOption.onClick}
                onChange={handleChange.onChange}
              />
            </li>
          ))}
        </ul>
        {addOption && (
          <button
            type="button"
            className="btn btn-list"
            onClick={() => toggleModal(true, type)}
          >
            <Icon iconClass="iconPlus" icon={PLUS} />
            <span className="text">{addOption.label}</span>
          </button>
        )}
      </div>
    )
  }
}

export default DataList
