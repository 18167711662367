import React from 'react'
import SectionContext from '../editors/SectionContext'
import { Label } from './components/Label'
import { Conditions } from './components/Conditions'

/**
 * General option params
 */
const GeneralSectionParams: React.FC = () => {
  return (
    <SectionContext.Consumer>
      {(state) => {
        const { id } = state.section
        return (
          <>
            {/* Rendering Section's id */}
            <div className="id">#{id}</div>

            {/* label */}
            <Label state={state} />

            {/* conditions */}
            <Conditions state={state} />
          </>
        )
      }}
    </SectionContext.Consumer>
  )
}

export default GeneralSectionParams
