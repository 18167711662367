import { useSlate } from '@legalplace/slate-react'
import React, { MouseEvent, useRef, useState } from 'react'
import TableHelpers from '../../helpers/TableHelpers'
import './borderwidth.scss'
import Button from './Button'
import Icon from './Icon'

const isDescendant = (parent: HTMLElement, element: HTMLElement) => {
  let node = element.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

let bodyEventListener:
  | ((bodyEvent: globalThis.MouseEvent) => void)
  | null = null

const TableBorderWidthButton = () => {
  const borderStyleCtaRef = useRef<HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const editor = useSlate()

  const close = () => {
    if (bodyEventListener !== null)
      document.body.removeEventListener('click', bodyEventListener)
    setOpen(false)
  }

  if (bodyEventListener === null)
    bodyEventListener = (bodyEvent: globalThis.MouseEvent) => {
      const target = bodyEvent.target as HTMLElement
      if (target === null || borderStyleCtaRef.current === null) {
        close()
      } else if (
        target === borderStyleCtaRef.current ||
        isDescendant(borderStyleCtaRef.current, target)
      ) {
        bodyEvent.preventDefault()
      } else {
        close()
      }
    }

  const handleMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()

    if (open === false && bodyEventListener !== null) {
      setOpen(true)
      document.body.addEventListener('click', bodyEventListener)
    } else {
      close()
    }
  }

  const widths = new Array(4).fill(0).map((a, index) => index + 1)

  const setBorderWidth = async (
    event: MouseEvent<HTMLDivElement>,
    width: string
  ) => {
    event.preventDefault()
    await TableHelpers.editCurrentCellBorder(editor, 'top', { width })
    await TableHelpers.editCurrentCellBorder(editor, 'bottom', { width })
    await TableHelpers.editCurrentCellBorder(editor, 'left', { width })
    await TableHelpers.editCurrentCellBorder(editor, 'right', { width })
    close()
  }

  return (
    <span
      className={`border-width-cta${open ? ' open' : ''}`}
      ref={borderStyleCtaRef}
    >
      <Button active={open} onMouseDown={handleMouseDown}>
        <Icon>border_horizontal</Icon>
      </Button>
      <div className="dropdown">
        <div className="styles">
          {widths.map((width) => {
            return (
              <div
                key={`border-width-${width}px`}
                className="style"
                onMouseDown={(event) => setBorderWidth(event, `${width}px`)}
              >
                <div
                  className="border"
                  style={{ borderBottomWidth: `${width}px` }}
                >
                  {width}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </span>
  )
}

export default TableBorderWidthButton
