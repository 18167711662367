import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { ICreateStore } from '../../store/editor/createStore'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const columns = {
  en: {
    'Are you sure you want to delete this element?':
      'Are you sure you want to delete this element?',
    Cancel: 'Cancel',
    Confirm: 'Confirm'
  },
  fr: {
    'Are you sure you want to delete this element?':
      'Etes-vous sûr de vouloir supprimer cet élément ?',
    Cancel: 'Annuler',
    Confirm: 'Confirmer'
  }
}

I18n.putVocabularies(columns)

interface IDeleteValidationModalProps {
  createStore?: ICreateStore | any
  open: boolean
}

@inject('createStore')
@observer
class DeleteValidationModal extends Component<IDeleteValidationModalProps> {
  @catchAction
  @action
  public closeModal = () => {
    const { toggleDeleteValidationModal } = this.props.createStore
    toggleDeleteValidationModal(false, {})
  }

  @catchAction
  @action
  handleDeleteElement = () => {
    const {
      deleteValidationData,
      deleteValidationCallback,
      deleteElement,
      deleteSection
    } = this.props.createStore
    if (deleteValidationData.section === true) {
      deleteSection()
      if (typeof deleteValidationCallback === 'function')
        deleteValidationCallback()
      this.closeModal()
      return
    }
    const { el, ids, variable } = deleteValidationData
    deleteElement(el, ids, variable)
    this.closeModal()
  }

  render() {
    return (
      <Dialog
        onClose={() => this.closeModal()}
        open={this.props.open}
        className="del-validation-modal"
      >
        <div className="variable-holder">
          <h3>{I18n.get('Are you sure you want to delete this element?')}</h3>
        </div>
        <div className="buttons-holder">
          <button
            type="button"
            className="secondary"
            onClick={() => this.closeModal()}
          >
            {I18n.get('Cancel')}
          </button>
          <button
            type="button"
            className="primary"
            onClick={this.handleDeleteElement}
          >
            {I18n.get('Confirm')}
          </button>
        </div>
      </Dialog>
    )
  }
}

export default DeleteValidationModal
