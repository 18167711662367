import React, { Component, ReactNode } from 'react'

import './style.scss'

interface ICheckboxProps {
  label: string
  id: string
  checked?: boolean
}

interface ICheckboxState {
  value: boolean
}

class Checkbox extends Component<ICheckboxProps, ICheckboxState> {
  constructor(props: ICheckboxProps) {
    super(props)
    this.state = {
      value: false
    }
  }

  onHandleChange = (e: any) => {
    e.persist()
    const { checked } = e.target

    this.setState({
      value: checked
    })
    // this.props.handleChange(value);
  }

  public render(): ReactNode {
    const { label, id } = this.props
    const { value } = this.state
    return (
      <div className="input checkbox">
        <input
          onChange={this.onHandleChange}
          checked={value}
          type="checkbox"
          id={id}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    )
  }
}

export default Checkbox
