import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { CSSTransition } from 'react-transition-group'
import './style.scss'
import { runInAction } from 'mobx'
import { IUIStore } from '../../../../store/editor/uiStore'
import { ICreateStore } from '../../../../store/editor/createStore'
import Icon from '../../../../components/Icons/Icon'
import { OPENICON, AROWDOWNICON } from '../../../../components/Icons/icons'

const plusIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 8V16"
      stroke="#14cf96"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 12H8"
      stroke="#14cf96"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="#14cf96"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const dictionary = {
  fr: {
    'New document': 'Nouveau document'
  },
  en: {
    'New document': 'New document'
  }
}

I18n.putVocabularies(dictionary)

interface IContractContentDrawerProps {
  uiStore?: IUIStore | any
  createStore: ICreateStore
}

@inject('uiStore', 'createStore')
@observer
class ContractContentDrawer extends Component<IContractContentDrawerProps> {
  renderSublist = (items: any) => {
    const {
      metaData,
      document: { options }
    } = this.props.createStore
    return items
      .map((item: any) => {
        const inner = Object.values(options[item].options)
        if (options[item].meta.type === 'box') return null
        const { id } = options[item].meta
        return (
          <div
            key={item.Id}
            className={`sublist ${!inner.length ? 'no-sublist' : ''}`}
          >
            <p onClick={this.toggleSublist(options[item])}>
              {inner.length ? (
                <span>
                  {metaData[id].openedInnerElements ? (
                    <Icon
                      iconClass="arrow-left-icon"
                      icon={AROWDOWNICON}
                      color="#45abff"
                    />
                  ) : (
                    <Icon
                      iconClass="arrow-down-icon"
                      icon={AROWDOWNICON}
                      color="#495057"
                    />
                  )}
                </span>
              ) : null}
              #{id}{' '}
              {options[item].meta.label ||
                `No label - ${options[item].meta.type}`}
            </p>
            {this.renderPlusIcon(options[item])}
            {options[item].variables.length
              ? options[item].variables.map((variable: any) => {
                  const currentVar = this.props.createStore.document.variables[
                    variable
                  ]
                  return (
                    <div key={item.Id} className="sublist no-sublist">
                      {`#${currentVar.id} ${currentVar.label}`}
                    </div>
                  )
                })
              : null}
            {metaData[id].openedInnerElements ? (
              <div className="sublist">
                {this.renderSublist(options[item].options)}
              </div>
            ) : null}
          </div>
        )
      })
      .filter((a: any) => a !== null)
  }

  renderList = () => {
    const {
      metaData,
      document,
      document: { options }
    } = this.props.createStore

    // collect all documents options
    const allDocumentOptions = document.documents.main.sections.reduce(
      (acc: any, curEl: any) => {
        return acc.concat({
          options: curEl.options,
          sectionLabel: curEl.label
        })
      },
      []
    )

    return allDocumentOptions.map((section: any) => {
      const filteredOptions = section.options.filter((item: any) => {
        const { id } = options[item].meta
        if (
          options[item].meta.type === 'hidden' ||
          options[item].meta.type === 'box'
        ) {
          return false
        }
        if (!metaData[id]) {
          return false
        }

        return true
      })
      return (
        <>
          {section.sectionLabel}
          {filteredOptions.length > 0 ? (
            filteredOptions.map((item: any) => {
              const { id } = options[item].meta
              const dontShowIcon =
                options[item].options.length === 1 &&
                options[options[item].options[0]].meta.type === 'box'
              return (
                <div key={id} className="group">
                  <p onClick={this.toggleSublist(options[item])}>
                    <span>
                      {options[item].variables.length ||
                      (options[item].options.length && !dontShowIcon) ? (
                        metaData[id] && metaData[id].openedInnerElements ? (
                          <Icon
                            iconClass="arrow-left-icon"
                            icon={AROWDOWNICON}
                            color="#45abff"
                          />
                        ) : (
                          <Icon
                            iconClass="arrow-down-icon"
                            icon={AROWDOWNICON}
                            color="#495057"
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                    {options[item].meta.label}
                  </p>
                  {this.renderPlusIcon(options[item])}
                  {metaData[id].openedInnerElements &&
                  options[item].variables.length
                    ? options[item].variables.map((variable: any) => {
                        const currentVar = this.props.createStore.document
                          .variables[variable]
                        return (
                          <div key={item.Id} className="sublist no-sublist">
                            {`#${currentVar.id} ${currentVar.label}`}
                          </div>
                        )
                      })
                    : null}
                  {metaData[id].openedInnerElements &&
                    this.renderSublist(options[item].options)}
                </div>
              )
            })
          ) : (
            <p />
          )}
        </>
      )
    })
  }

  renderPlusIcon = (item: any) => {
    // render + icon for conditions
    // check item type
    const type = item.meta ? item.meta.type : item.type
    // check is elem multiple
    const multiple = item.meta
      ? item.meta.multiple && item.meta.multiple.enabled
      : undefined

    const { showPlusIcon, addCondition } = this.props.createStore
    if (
      showPlusIcon &&
      (type === 'radio' ||
        type === 'checkbox' ||
        type === 'list' ||
        (type === 'static' && multiple))
    ) {
      return (
        <button
          type="button"
          className="btn-icon"
          onClick={() => addCondition(item)}
        >
          {plusIcon}
        </button>
      )
    }
    return null
  }

  toggleSublist = (item: any) => () => {
    // open element sublist
    const { metaData } = this.props.createStore
    const id = item.id ? item.id : item.meta.id

    runInAction(() => {
      if (metaData[id]) {
        metaData[id].openedInnerElements = !metaData[id].openedInnerElements
      }
    })
  }

  render() {
    // eslint-disable-next-line
    const { uiStore } = this.props
    const { isDrawerOpen, toggleDrawer } = uiStore
    return (
      <CSSTransition in={isDrawerOpen} timeout={300} classNames="drawer">
        <div className="drawer-content">
          <div className="head">
            {isDrawerOpen && <div>Sections</div>}
            <button
              type="button"
              className="toggle-button"
              onClick={toggleDrawer}
            >
              <Icon iconClass="open-icon" icon={OPENICON} color="#000" />
            </button>
          </div>
          {isDrawerOpen && this.renderList()}
        </div>
      </CSSTransition>
    )
  }
}

export default ContractContentDrawer
