import React from 'react'
import { I18n } from 'aws-amplify'
import moment from 'moment'

import Link from '../../../../components/Editor/Link/Link'
import LinkButton from '../../../../components/Editor/LinkButton/LinkButton'

import './style.scss'

const buttons = {
  fr: {
    'Modify the contract': 'Modifier le contrat',
    Draft: 'Broullion',
    Published: 'Publié',
    'Make obsolete': 'Rendre obsolète',
    'Make non-obsolete': 'Rendre non obsolète',
    'Non published': 'Non publié',
    'Modify template': 'Modifier le modèle',
    Proofreading: 'Relecture',
    Overview: 'Aperçu',
    at: 'à'
  },
  en: {
    'Modify the contract': 'Modify the contract',
    Draft: 'Draft',
    Published: 'Published',
    'Make obsolete': 'Make obsolete',
    'Make non-obsolete': 'Make non-obsolete',
    'Non published': 'Non published',
    'Modify template': 'Modify template',
    Proofreading: 'Proofreading',
    Overview: 'Overview',
    at: 'at'
  }
}

I18n.putVocabularies(buttons)

interface IModelProps {
  item: any
}

const Model: React.FC<IModelProps> = (props) => {
  const {
    last_update,
    publication_time,
    version,
    draft,
    obsolescence
  } = props.item
  const modified = last_update === 0 ? publication_time : last_update
  return (
    <li>
      <div
        className={`version ${draft === 0 && 'green'} ${
          obsolescence === 1 && 'red'
        }`}
      >
        <h4>
          {version}
          {draft === 0 && <div className="onair">on air</div>}
          {obsolescence === 1 && <div className="obsolete">obsolète</div>}
        </h4>
      </div>
      <div className="state">
        {draft === 1 ? I18n.get('Draft') : I18n.get('Published')}
      </div>
      <div className="last_edit">{`${moment(modified).format('L')} ${I18n.get(
        'at'
      )} ${moment(modified).format('HH:mm')}`}</div>
      <div className="publication_time">
        {publication_time !== 0
          ? `${moment(publication_time).format('L')} ${I18n.get('at')} ${moment(
              publication_time
            ).format('HH:mm')}`
          : I18n.get('Non published')}
      </div>
      <div className="buttons-block">
        <div className="left-block">
          <Link href="/contracts/edit/115">
            {obsolescence === 0
              ? I18n.get('Make obsolete')
              : I18n.get('Make non-obsolete')}
          </Link>
        </div>
        <div className="right-block">
          <LinkButton href="/contracts/edit/115">
            {I18n.get('Modify template')}
          </LinkButton>
          <LinkButton href="/contracts/edit/115">
            {I18n.get('Proofreading')}
          </LinkButton>
          <LinkButton href="/contracts/edit/115">
            {I18n.get('Overview')}
          </LinkButton>
        </div>
      </div>
    </li>
  )
}

export default Model
