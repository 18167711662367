import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { action, observable } from 'mobx'
import Icon from '../Icons/Icon'
import { EDITED } from '../Icons/icons'
import Input from '../Editor/InputField'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface IDataListProps {
  el: any
  i: number
  onChange: any
  deleteOption(i: number, type: string, el: any): void
  updateOption(name: string, type: string, id: number): void
  type: string
}

@observer
class DataListItem extends Component<IDataListProps> {
  @observable isEditing = false

  @observable name = ''

  @catchAction
  @action
  toggleEdit = () => {
    this.isEditing = !this.isEditing
  }

  @catchAction
  @action
  toggleSave = () => {
    const { el, type } = this.props
    this.toggleEdit()
    this.props.updateOption(this.name, type, el.id)
  }

  handleOptionChange = (name: string, value: string) => {
    const { el, i, type } = this.props
    this.name = value
    this.props.onChange(i, value, type, el)
  }

  public render(): ReactNode {
    const { el, i, type, deleteOption } = this.props

    return (
      <div className="data-list-item">
        {!this.isEditing ? (
          <div className="title-container">
            <span>{el.name}</span>
            <button
              type="button"
              onClick={this.toggleEdit}
              className="btn btn-gary btn-icon-text"
            >
              <Icon iconClass="iconDuplicate" icon={EDITED} />
            </button>
          </div>
        ) : (
          <>
            <Input
              el={el}
              name={i}
              value={el.name}
              setFieldValue={this.handleOptionChange}
            />
            <button
              type="button"
              onClick={this.toggleSave}
              className="btn save-button btn-dark btn-icon-text nomb"
            >
              Save
            </button>
          </>
        )}
        <button type="button" onClick={() => deleteOption(i, type, el)}>
          x
        </button>
      </div>
    )
  }
}

export default DataListItem
