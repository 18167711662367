import { Editor, Node } from '@legalplace/slate'
import { OptionV3 } from '@legalplace/models-v3-types'
import { deserializeOutputs } from './serializers/deserialize'

let nodesCache: Node[] = []

export const deserializeWithCache = (
  outputs: { option: OptionV3; children: OptionV3[] }[],
  clearCache?: boolean
) => {
  if (clearCache !== true && nodesCache.length > 0) return nodesCache
  const deserialized = deserializeOutputs(outputs)
  nodesCache = [...deserialized]
  return deserialized
}

export const withCache = <T extends Editor>(editor: T): T => {
  const { apply } = editor

  editor.apply = (operation) => {
    apply(operation)

    const { children } = editor

    // Getting additions
    const additions = children.filter((n) => !nodesCache.includes(n))

    // Getting deletions
    const deletions = nodesCache.filter((n) => !children.includes(n))

    // Performing deletions
    deletions.forEach((deletedNode) => {
      const deletedIndex = nodesCache.indexOf(deletedNode)
      nodesCache.splice(deletedIndex, 1)
    })

    // Performing additions
    additions.forEach((addedNode) => {
      const addedIndex = children.indexOf(addedNode)
      nodesCache.splice(addedIndex, 0, addedNode)
    })
  }

  return editor
}
