import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'

const dictionary = {
  fr: {
    Signatures: 'Signatures électroniques',
    message:
      'Le module de signature électronique ne fait pas partie de votre abonnement. Merci de nous contacter pour modifier votre abonnement si vous souhaitez utiliser le module de signature électronique'
  },
  en: {
    Signatures: 'Signatures',
    message:
      'The electronic signature module is not part of your plan. Please contact us to upgrade your plan if you want to use the electronic signature module.'
  }
}

I18n.putVocabularies(dictionary)

@observer
class SignaturesPage extends Component<any> {
  public render(): ReactNode {
    return (
      <div className="content">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('Signatures')}</h3>
          </div>
        </div>
        <div className="flex-row flex-center-sb signature">
          {I18n.get('message')}
        </div>
      </div>
    )
  }
}

export default SignaturesPage
