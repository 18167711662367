import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { ErrorMessage } from 'formik'
import Select from 'react-select'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Icon from '../Icons/Icon'
import './style.scss'

export interface IOption {
  label: string
  value: any
}

interface ISelectFieldProps {
  options: IOption[] | undefined
  setFieldValue(name: string, value: string, meta: any): void
  name: string
  value?: string | number
  placeholder?: string
  label?: string
  fieldClass?: string
  onMenuClose?: () => void
  [k: string]: any
}

@observer
class SelectField extends Component<ISelectFieldProps> {
  public onChange = ({ value }: any) => {
    const { name, setFieldValue, meta } = this.props
    setFieldValue(name, value, meta)
  }

  public render(): ReactNode {
    const {
      options = [],
      name,
      value = '',
      label = '',
      placeholder = '',
      fieldClass = '',
      isSearchable = true,
      disabled = false
    } = this.props
    const selected = options.find((e: IOption) => e.value === value)

    const fieldClassConst = classNames({
      'form-field': true,
      [fieldClass]: true
    })
    return (
      <div className={fieldClassConst}>
        <label className="label-wrapper">
          {label ? <h5 className="text">{label}</h5> : ''}
          <Select
            isSearchable={isSearchable}
            classNamePrefix="nice"
            isDisabled={disabled}
            className="nice-select-container"
            // isClearable={true}
            value={selected}
            onChange={this.onChange}
            options={options}
            placeholder={placeholder}
            onMenuClose={this.props.onMenuClose}
            // menuIsOpen={true}
            components={{
              Option: ({ data, children, innerProps }) => {
                return (
                  <div
                    {...innerProps}
                    className={`option ${data.className ? data.className : ''}`}
                  >
                    {data.icon ? (
                      <Icon
                        iconClass={data.icon.class}
                        icon={data.icon.path}
                        color={data.icon.color}
                      />
                    ) : null}
                    <span className="content">{children}</span>
                  </div>
                )
              },
              ValueContainer: ({ children, getValue }) => {
                const val: any = getValue()
                const icon = isEmpty(val) ? {} : val[0].icon
                return (
                  <div
                    className={`nice-select-value ${
                      icon && icon.path ? 'with-icon' : ''
                    } ${!isEmpty(val) ? val[0].className : ''}`}
                  >
                    {icon && icon.path ? (
                      <Icon
                        iconClass={icon.class}
                        icon={icon.path}
                        color={icon.color}
                      />
                    ) : null}
                    {children}
                  </div>
                )
              }
            }}
          />
        </label>
        <h5 className="text-error">
          <ErrorMessage name={name} />
        </h5>
      </div>
    )
  }
}

export default SelectField
