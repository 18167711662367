import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core/styles'
import { IDialogStore } from '../store/dialogStore'
import { PLUS } from '../components/Icons/icons'
import Icon from '../components/Icons/Icon'

interface IDialogContainerProps {
  classes?: any
  dialogStore?: IDialogStore | any
}

const styles = () => ({
  paper: {
    maxWidth: 'calc(100vw - 52px)',
    maxHeight: 'calc(100% - 28px)'
  }
})

@inject('dialogStore')
@observer
class DialogContainer extends Component<IDialogContainerProps> {
  public render(): ReactNode {
    const { classes, dialogStore } = this.props
    const { dialogContent, open, closeDialog, dialogSettings } = dialogStore
    const { closeBtn } = dialogSettings

    return (
      <Dialog
        open={open}
        onClose={closeDialog}
        classes={{ paper: classes.paper }}
      >
        <div className="dialog-inner">
          {closeBtn && (
            <button
              type="button"
              className="dialog-close"
              onClick={closeDialog}
            >
              <Icon iconClass="iconPlus" icon={PLUS} />
            </button>
          )}
          <div className="dialog-content">{dialogContent}</div>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(DialogContainer)
