import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik } from 'formik'
import { I18n } from 'aws-amplify'
import { IDataStore } from '../../../store/dataStore'
import FormField from '../../../components/FormField/FormField'
import TagsField, { ITag } from '../../../components/TagsField/TagsField'

interface INewFileFormProps {
  dataStore?: IDataStore | any
}

export interface INewFileFormValues {
  name?: string | any
  tags?: ITag[] | any
}

const columns = {
  en: {
    new_title: 'New',
    new_title_cont: 'Contrat de location',
    model: 'Model 7',
    file_name: 'File Name',
    file_tags: 'Tags',
    tags: 'Tags',
    submit_btn: 'Create New'
  },
  fr: {
    new_title: 'New “Contrat de location”',
    model: 'Model 7',
    file_name: 'File Name',
    file_tags: 'Tags',
    tags: 'Tags',
    submit_btn: 'Create New'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore')
@observer
class NewFileForm extends Component<INewFileFormProps> {
  private initialValues: INewFileFormValues = {
    name: '',
    tags: []
  }

  private validate = (values: INewFileFormValues) => {
    const errors: INewFileFormValues = {}
    if (!values.name) {
      errors.name = 'Required'
    }
    if (!values.tags) {
      errors.tags = 'Required'
    }
    return errors
  }

  public render(): ReactNode {
    const { newFile } = this.props.dataStore

    return (
      <div className="content">
        <div className="ttl-container">
          <h3 className="ttl">
            <span>{I18n.get('new_title')} </span>
            <span>
              <b>&quot;{I18n.get('new_title_cont')}&quot;</b>
            </span>
          </h3>
          <h4 className="sub-ttl">{I18n.get('model')}</h4>
        </div>
        <div className="modal-form-container">
          <Formik
            initialValues={this.initialValues}
            validate={this.validate}
            onSubmit={newFile}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormField
                  label={I18n.get('file_name')}
                  type="text"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <TagsField
                  tags={values.tags}
                  label={I18n.get('file_tags')}
                  name="tags"
                  fieldClass="fieldTags"
                  setFieldValue={setFieldValue}
                />
                <div className="footer">
                  <button
                    className="btn btn-active btn-send"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {I18n.get('submit_btn')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default NewFileForm
