const deepObjectSetValue = (
  obj: Record<string, any>,
  is: string | string[],
  value: any
): any => {
  if (typeof is === 'string')
    return deepObjectSetValue(obj, is.split('.'), value)
  if (is.length === 1 && value !== undefined) {
    obj[is[0]] = value
    return value
  }
  if (is.length === 0) return obj
  if (typeof obj[is[0]] !== 'object') obj[is[0]] = {}
  return deepObjectSetValue(obj[is[0]], is.slice(1), value)
}

export default deepObjectSetValue
