import React from 'react'
import { action, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { ICreateStore } from '../../../store/editor/createStore'
import { IContractsStore } from '../../../store/editor/contractsStore'
import { IDataStore } from '../../../store/dataStore'
import { catchAction } from '../../../store/editor/catchActionsDecorator'
import Select from '../../../pages/Editor/CreateContractPage/slate-editor/components/select/Select'

type ICopyViewProps = {
  closeModal: () => void
  setView: (view: 'main' | 'copy') => void
  contractsStore?: IContractsStore
  createStore?: ICreateStore
  dataStore?: IDataStore
}

type ICopyViewState = {
  metasLoading: boolean
  versionsLoading: boolean
  currentMeta: string | null
  currentVersion: string | null
  metaList: Record<string, { label: string; permalink: string }>
  versionList: Record<string, { label: string; model: Record<string, string> }>
}

const dictionary = {
  fr: {
    cancel: 'Annuler',
    back: 'Retour',
    confirm: 'Valider',
    duplicate_model: 'Dupliquer un modèle',
    which_template: 'De quel template ?',
    which_version: 'Quelle version ?'
  },
  en: {
    cancel: 'Cancel',
    back: 'Back',
    confirm: 'Confirm',
    duplicate_model: 'Copy a model',
    which_template: 'From which template?',
    which_version: 'Which model?'
  }
}

I18n.putVocabularies(dictionary)

@inject('dataStore', 'contractsStore', 'createStore')
@observer
class CopyView extends React.Component<ICopyViewProps, ICopyViewState> {
  constructor(props: ICopyViewProps) {
    super(props)

    this.state = {
      metasLoading: true,
      versionsLoading: true,
      currentMeta: null,
      currentVersion: null,
      metaList: {},
      versionList: {}
    }
  }

  componentDidMount() {
    this.fetchMetas()
  }

  // Fetching metas
  @catchAction
  @action
  fetchMetas = async () => {
    if (this.props.dataStore === undefined) return
    const params: any = new URLSearchParams()
    params.set('noLimit', true)
    // params.set('order', 'ORDER BY ASC')
    await this.props.dataStore.fetchProducts(params)

    // Setting meta options
    const metas = this.props.dataStore.allProductsData.data

    const metaList: Record<string, { label: string; permalink: string }> = {}
    metas.forEach((currentMeta) => {
      metaList[`meta-${currentMeta.id}`] = {
        label: currentMeta.name,
        permalink: currentMeta.permalink
      }
    })

    this.setState({
      metaList,
      metasLoading: false
    })
  }

  // Fetching versions
  @catchAction
  @action
  fetchVersions = async () => {
    if (
      this.props.contractsStore === undefined ||
      this.state.currentMeta === null
    )
      return

    const data = await this.props.contractsStore.getContractMeta(
      parseInt(this.state.currentMeta.replace('meta-', ''), 10)
    )

    if (data === false) return

    // Setting versions options
    const { models } = data

    const versionList: Record<
      string,
      { label: string; model: Record<string, string> }
    > = {}
    models.forEach((currentModel: Record<string, string>) => {
      versionList[currentModel.id] = {
        label: currentModel.version,
        model: currentModel
      }
    })

    this.setState({
      versionList,
      versionsLoading: false
    })
  }

  handleMetaChange = (v: string) => {
    this.setState(
      {
        currentMeta: v,
        currentVersion: null,
        versionsLoading: true
      },
      () => {
        // Fetching versions
        this.fetchVersions()
      }
    )
  }

  handleVersionChange = (v: string) => {
    this.setState({
      currentVersion: v
    })
  }

  @catchAction
  @action
  duplicateModel = async () => {
    const { createStore, contractsStore, closeModal } = this.props
    const { currentMeta, currentVersion, metaList, versionList } = this.state
    if (
      createStore === undefined ||
      contractsStore === undefined ||
      currentMeta === null ||
      currentVersion === null ||
      Object.keys(metaList).length === 0 ||
      Object.keys(versionList).length === 0
    )
      return

    // Getting permalink
    const { contractMeta } = contractsStore

    if (contractMeta.permalink === undefined) return

    // Getting permalink
    const { permalink, name } = contractMeta
    const { model } = versionList[currentVersion]

    // Turning on loader
    createStore.isModelLoading = true

    // Closing modal
    closeModal()

    const { getModelData, fillMetaData } = createStore

    // get model from API
    await getModelData(
      parseInt(currentVersion, 10),
      permalink,
      model,
      name,
      parseInt(currentMeta.replace('meta-', ''), 10),
      true,
      true
    )
    fillMetaData()
    runInAction(() => {
      // Turning off loadesr
      createStore.isModelLoading = false
    })
  }

  render() {
    const { closeModal, setView } = this.props
    return (
      <>
        <div className="nmm-header">
          <div className="align-left">
            <button
              type="button"
              className="secondary"
              onClick={() => setView('main')}
            >
              ← {I18n.get('back')}
            </button>
          </div>
          <div className="align-right">
            <h3>{I18n.get('duplicate_model')}</h3>
          </div>
        </div>
        <div className="nmm-copy-view">
          <div className="nmm-block">
            <h3>{I18n.get('which_template')}</h3>
            <Select
              options={this.state.metaList}
              value={`${this.state.currentMeta}` || ''}
              onChange={this.handleMetaChange}
              disabled={this.state.metasLoading}
              enableFilter
            />
          </div>
          <div className="nmm-block">
            <h3>{I18n.get('which_version')}</h3>
            <Select
              options={this.state.versionList}
              value={this.state.currentVersion?.toString() || ''}
              onChange={this.handleVersionChange}
              disabled={this.state.versionsLoading}
            />
          </div>
        </div>
        <div className="nmm-footer">
          <div className="align-left">
            <button type="button" className="secondary" onClick={closeModal}>
              {I18n.get('cancel')}
            </button>
          </div>
          <div className="align-right">
            <button
              type="button"
              className="primary"
              disabled={
                !(
                  typeof this.state.currentMeta === 'string' &&
                  typeof this.state.currentVersion === 'string'
                )
              }
              onClick={this.duplicateModel}
            >
              {I18n.get('confirm')}
            </button>
          </div>
        </div>
      </>
    )
  }
}

export default CopyView
