import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    'Contains a helper': 'Contient un helper'
  },
  en: {
    'Contains a helper': 'Contains a helper'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable helper hide
 */
export const HelperHide: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { helper } = state.variable

  const toggleHelper = (enable: boolean) => {
    if (enable === false && typeof helper === 'string')
      state.updateHelper(undefined)
    else if (enable === true && helper === undefined) state.updateHelper('')
  }

  return (
    <CheckboxField
      meta
      checked={typeof helper === 'string'}
      setFieldValue={(name: string, value: boolean) => toggleHelper(value)}
      cypressId="helper-checkbox"
      name="helper"
      label={I18n.get('Contains a helper')}
    />
  )
}
