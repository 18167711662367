import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    'CTA label': 'Bouton "Ajouter un..."'
  },
  en: {
    'CTA label': 'CTA label'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option second step
 */
export const MultipleCta: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  if (!state.option.meta.multiple || !state.option.meta.multiple?.enabled)
    return null
  const cta = state.option.meta.multiple.cta || ''

  return (
    <Input
      value={cta}
      meta
      name="cta"
      setFieldValue={(name: string, value: string) =>
        state.updateMultipleCta(value)
      }
      label={I18n.get('CTA label')}
    />
  )
}
