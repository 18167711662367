import { ConditionV3 } from '@legalplace/models-v3-types'

const isAndOr = (key: string): key is 'and' | 'or' => {
  return ['and', 'or'].includes(key)
}

const isConditionEmpty = (conditions: ConditionV3): boolean => {
  if (typeof conditions !== 'object') return true
  if (Object.keys(conditions).length === 0) return true

  if (Object.keys(conditions).length === 1) {
    const key = Object.keys(conditions)[0]
    if (isAndOr(key)) {
      return !!conditions[key]
        ?.map((subCondition) => isConditionEmpty(subCondition))
        .reduce((a, b) => a && b, true)
    }
    return false
  }
  return false
}

export default isConditionEmpty
