import { FormikActions } from 'formik'
import { action, computed, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { IAuthenticationParamsValues } from '../pages/Dashboard/SettingsPage/AuthenticationParamsForm'
import { IForgotValues } from '../pages/ForgotPage'
import { ILoginValues } from '../pages/LoginPage'
import { IResetValues } from '../pages/ResetPage'
import api from '../utils/api'
import { handleFormErrors } from '../utils/form'
import { go } from '../utils/history'
import { getLocale } from '../utils/localization'
import { catchAction } from './editor/catchActionsDecorator'

const cookies = new Cookies()

export interface IAuthStore {
  isSignedIn: boolean
  login(values: ILoginValues, actions: FormikActions<ILoginValues>): void
  forgot(values: IForgotValues, actions: FormikActions<IForgotValues>): void
  reset(values: IResetValues, actions: FormikActions<IResetValues>): void
  changePassword(
    values: IAuthenticationParamsValues,
    actions: FormikActions<IAuthenticationParamsValues>
  ): void
  logout(): void
  checkAuthorize(): void
}

class AuthStore implements IAuthStore {
  @observable private signedIn = false

  @computed get isSignedIn(): boolean {
    return this.signedIn && !!cookies.get('token')
  }

  @catchAction
  @action
  public login = async (
    values: ILoginValues,
    { setSubmitting, setFieldError }: FormikActions<ILoginValues>
  ) => {
    try {
      const data = {
        username: values.username,
        password: values.password,
        locale: getLocale()
      }

      const resp: any = await api.post('/auth/login', data)
      if (!cookies.get('token')) {
        cookies.set('token', resp.data, {
          domain: process.env.REACT_APP_COOKIE_SUBDOMAIN,
          path: '/'
        })
      }
      runInAction(() => {
        this.signedIn = true
      })
      go('/')
    } catch (err) {
      handleFormErrors(err, setFieldError)
    } finally {
      setSubmitting(false)
    }
  }

  @catchAction
  @action
  public forgot = async (
    { email }: IForgotValues,
    { setSubmitting, setFieldError }: FormikActions<IForgotValues>
  ): Promise<any> => {
    try {
      setSubmitting(false)
      await api.post('/auth/forgot', { email })
      setSubmitting(true)
    } catch (err) {
      handleFormErrors(err, setFieldError)
    }
  }

  @catchAction
  @action
  public reset = async (
    values: IResetValues,
    { setSubmitting, setFieldError }: FormikActions<IResetValues>
  ): Promise<any> => {
    const { newPassword, token } = values
    try {
      await api.post(`/auth/reset/${token}`, { newPassword })
      go('/login')
    } catch (err) {
      handleFormErrors(err, setFieldError)
    } finally {
      setSubmitting(false)
    }
  }

  @catchAction
  @action
  public changePassword = async (
    values: IAuthenticationParamsValues,
    { setSubmitting, setFieldError }: FormikActions<IAuthenticationParamsValues>
  ): Promise<any> => {
    const { oldPassword, newPassword } = values
    try {
      const data = {
        oldPassword,
        newPassword
      }
      await api.post('/auth/change', data)
      toast.success('Password has been changed successfully !', {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
      handleFormErrors(err, setFieldError)
    } finally {
      setSubmitting(false)
    }
  }

  @catchAction
  @action
  public logout = (): void => {
    cookies.remove('token', {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_SUBDOMAIN
    })
    this.signedIn = false
  }

  @catchAction
  @action
  public checkAuthorize = (): void => {
    this.signedIn = !!cookies.get('token')
  }

  public getUserProfile = async () => {
    if (cookies.get('token')) {
      try {
        const res = await api.get(`/user/profile`)
        const userResponse = res.data

        return {
          displayName: `${userResponse.first_name} ${userResponse.last_name}`,
          email: userResponse.email || '',
          level: userResponse.role || 'GUEST'
        }
      } catch (error) {
        return false
      }
    }
    return false
  }
}

const authStore = new AuthStore()
export default authStore
