import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import VariableContext from '../editors/VariableContext'
import { HelperHide } from './components/HelperHide'
import { HelperContent } from './components/HelperContent'
import { WarningHide } from './components/WarningHide'
import { WarningContent } from './components/WarningContent'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: { messages_category: 'Messages d’aides' },
  en: { messages_category: 'Help messages' }
}
I18n.putVocabularies(dictionary)

/**
 * Messages option params
 */
const MessagesVariableParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <VariableContext.Consumer>
      {(state) => {
        const toggleCollapsed = () => setCollapsed(!collapsed)

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('messages_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <>
                <HelperHide state={state} />
                <HelperContent state={state} />
                <WarningHide state={state} />
                <WarningContent state={state} />
              </>
            )}
          </div>
        )
      }}
    </VariableContext.Consumer>
  )
}

export default MessagesVariableParams
