import { cloneDeep } from 'lodash'
import createStore from './createStore'

/**
 * Saves model & executes action
 *
 * @param key Property key
 * @param fn Action function
 * @param boundThis Store instance
 */
function wrapAction(key: string | symbol, fn: Function, boundThis: any) {
  return function wrappedAction(...args: any) {
    let prevState: typeof createStore.history | null
    if (boundThis.history.length > 0) {
      prevState = boundThis.history[boundThis.currentState].state
    } else {
      prevState = cloneDeep(boundThis.document)
    }
    const prevSectionIndex = boundThis.currentSectionId
    const prevCurrentDocument = boundThis.currentDocument
    const next = fn.call(boundThis, ...args)

    // Saving history
    ;(async () => {
      const sectionIndex = boundThis.currentSectionId
      const { currentDocument } = boundThis
      const state = cloneDeep(boundThis.document)
      boundThis.pushHistory({
        prevState,
        state,
        prevSectionIndex,
        sectionIndex,
        currentDocument,
        prevCurrentDocument,
        action: key.toString()
      })
    })()
    return next
  }
}
/**
 * History tracing decorator
 */
export function traceHistory(
  target: any,
  key: string | symbol,
  baseDescriptor?: PropertyDescriptor & { initializer?: any }
): any {
  if (typeof baseDescriptor === 'object') {
    if (baseDescriptor.get !== undefined) {
      throw new Error(
        `@traceHistory cannot be used with getter "${key.toString()}"`
      )
    }

    if (typeof baseDescriptor.value === 'function') {
      const original = baseDescriptor.value as Function
      return {
        value(...args: any) {
          return wrapAction(key, original, this)(...args)
        },
        enumerable: false,
        configurable: true,
        writable: true
      }
    }
    if (
      typeof key === 'string' &&
      typeof target === 'object' &&
      typeof baseDescriptor.initializer === 'function'
    ) {
      const originalInitializer = baseDescriptor.initializer
      return {
        initializer() {
          const original = originalInitializer.call(this)
          return (...args: any) => {
            return wrapAction(key, original, this)(...args)
          }
        },
        enumerable: false,
        configurable: true,
        writable: true
      }
    }

    throw new Error(`@traceHistory works only with non arrow functions`)
  }
  return baseDescriptor
}
