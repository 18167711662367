/* eslint-disable react/no-unused-state */
import React from 'react'
import { VariableV3 } from '@legalplace/models-v3-types'
import { action } from 'mobx'
import createStore from '../../../../../../store/editor/createStore'
import { catchAction } from '../../../../../../store/editor/catchActionsDecorator'

class VariableEditor extends React.Component<IProps, VariableEditorStateType> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      id: props.variable.id,
      variable: props.variable,

      updateType: this.updateType,
      updateLabel: this.updateLabel,
      updateFallbackLabel: this.updateFallbackLabel,
      updateConditions: this.updateConditions,
      updateStep: this.updateStep,
      updateGrantLevel: this.updateGrantLevel,
      updateMandatory: this.updateMandatory,
      updateStyle: this.updateStyle,
      updateNumberIntOnly: this.updateNumberIntOnly,
      updateTextareaSize: this.updateTextareaSize,
      updateTextareaLineNumbers: this.updateTextareaLineNumbers,
      updateTermsheetHide: this.updateTermsheetHide,
      updateTermsheetLabel: this.updateTermsheetLabel,
      updateHelper: this.updateHelper,
      updateWarning: this.updateWarning,
      updateTags: this.updateTags,
      updateValidatorConditions: this.updateValidatorConditions,
      updateValidatorMessage: this.updateValidatorMessage,
      updateEvalFormula: this.updateEvalFormula,
      updateEvalDecimals: this.updateEvalDecimals,
      updateEvalRound: this.updateEvalRound,
      updateSelectValues: this.updateSelectValues,
      updateMaskFormula: this.updateMaskFormula,
      updateMaskPlaceholder: this.updateMaskPlaceholder,
      updatePrefillings: this.updatePrefillings,
      updatePlaceholder: this.updatePlaceholder,
      updateSuffix: this.updateSuffix,
      updateHidden: this.updateHidden,
      updateAutocomplete: this.updateAutocomplete,

      toggleConditionsDrawer: props.toggleConditionsDrawer,
      toggleDrawer: props.toggleDrawer
    }
  }

  /**
   * Saves current updates
   */
  @catchAction
  @action
  saveUpdates = () => {
    const originalVariable = createStore.document.variables[this.state.id]
    createStore.document.variables[this.state.id] = {
      ...originalVariable,
      ...this.state.variable
    }
    this.props.toggleDrawer(false)
  }

  /**
   * Deletes variable
   */
  deleteVariable = () => {
    // delete element
    const { toggleDrawer } = this.props
    const {
      checkForConditions,
      selectedElement,
      selectedElementIds
    } = createStore

    // delete element
    checkForConditions(
      selectedElement,
      selectedElementIds,
      false,
      true,
      true,
      () => {
        toggleDrawer(false)
      }
    )
  }

  /**
   * Moves variable
   */
  moveVariable = () => {
    const { id } = this.state.variable

    // Initiating move element
    createStore.initiateMoveElement('variable', id, this.props.toggleDrawer)
    this.props.toggleDrawer(false)
  }

  /**
   * Closes drawer
   */
  closeDrawer = () => {
    this.props.toggleDrawer(false)
  }

  /**
   * Updates variable type
   * @param type Variable's fallback type
   */
  updateType = (type: VariableV3['type']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        type
      }
    })
  }

  /**
   * Updates variable label
   * @param label Variable's label
   */
  updateLabel = (label: VariableV3['label']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        label
      }
    })
  }

  /**
   * Updates variable fallback label
   * @param fallbackLabel Variable's fallback label
   */
  updateFallbackLabel = (fallbackLabel: VariableV3['fallbackLabel']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        fallbackLabel
      }
    })
  }

  /**
   * Updates variable conditions
   * @param conditions Variable's conditions
   */
  updateConditions = (conditions: VariableV3['conditions']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        conditions
      }
    })
  }

  /**
   * Updates variable validator conditions
   * @param conditions Variable's validator conditions
   */
  updateValidatorConditions = (
    conditions: Exclude<VariableV3['validator'], undefined>['conditions']
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        validator: {
          ...this.state.variable.validator,
          conditions
        }
      }
    })
  }

  /**
   * Updates variable validator message
   * @param message Variable's validator message
   */
  updateValidatorMessage = (
    message: Exclude<VariableV3['validator'], undefined>['message']
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        validator: {
          ...this.state.variable.validator,
          message
        }
      }
    })
  }

  /**
   * Updates variable step
   * @param step Variable's step
   */
  updateStep = (step: VariableV3['step']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        step
      }
    })
  }

  /**
   * Updates variable grantLevel
   * @param grantLevel Variable's grantLevel
   */
  updateGrantLevel = (grantLevel: VariableV3['grantLevel']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        grantLevel
      }
    })
  }

  /**
   * Updates variable mandatory
   * @param mandatory Variable's mandatory
   */
  updateMandatory = (mandatory: VariableV3['mandatory']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        mandatory
      }
    })
  }

  /**
   * Updates variable style
   * @param style
   */
  updateStyle = (style: VariableV3['style']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        style
      }
    })
  }

  /**
   * Updates number intOnly
   * @param intOnly
   */
  updateNumberIntOnly = (intOnly: boolean) => {
    this.setState({
      variable: {
        ...this.state.variable,
        number: {
          ...this.state.variable.number,
          intOnly
        }
      }
    })
  }

  /**
   * Updates textarea size
   * @param size
   */
  updateTextareaSize = (
    size: Exclude<Exclude<VariableV3['textarea'], undefined>['size'], undefined>
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        textarea: {
          ...this.state.variable.textarea,
          size
        }
      }
    })
  }

  /**
   * Updates textarea line numbers
   * @param lineNumbers
   */
  updateTextareaLineNumbers = (
    lineNumbers: Exclude<
      Exclude<VariableV3['textarea'], undefined>['lineNumbers'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        textarea: {
          ...this.state.variable.textarea,
          lineNumbers
        }
      }
    })
  }

  /**
   * Updates variable termsheet hide
   * @param hide
   */
  updateTermsheetHide = (
    hide: Exclude<
      Exclude<VariableV3['termsheet'], undefined>['hide'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        termsheet: {
          ...this.state.variable.termsheet,
          hide
        }
      }
    })
  }

  /**
   * Updates variable termsheet label
   * @param label
   */
  updateTermsheetLabel = (
    label: Exclude<
      Exclude<VariableV3['termsheet'], undefined>['label'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        termsheet: {
          ...this.state.variable.termsheet,
          label
        }
      }
    })
  }

  /**
   * Updates variable helper
   * @param helper
   */
  updateHelper = (helper: VariableV3['helper']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        helper
      }
    })
  }

  /**
   * Updates variable warning
   * @param warning
   */
  updateWarning = (warning: VariableV3['warning']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        warning
      }
    })
  }

  /**
   * Updates variable tags
   * @param tags
   */
  updateTags = (tags: VariableV3['tags']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        tags
      }
    })
  }

  /**
   * Updates variable eval formula
   * @param formula
   */
  updateEvalFormula = (
    formula: Exclude<
      Exclude<VariableV3['eval'], undefined>['formula'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        eval: {
          round: this.state.variable.eval?.round
            ? this.state.variable.eval.round
            : 'round',
          decimals: this.state.variable.eval?.decimals
            ? this.state.variable.eval.decimals
            : 2,
          formula
        }
      }
    })
  }

  /**
   * Updates variable eval decimals numbers
   * @param decimals
   */
  updateEvalDecimals = (
    decimals: Exclude<
      Exclude<VariableV3['eval'], undefined>['decimals'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        eval: {
          round: this.state.variable.eval?.round
            ? this.state.variable.eval.round
            : 'round',
          formula: this.state.variable.eval?.formula
            ? this.state.variable.eval.formula
            : '',
          decimals
        }
      }
    })
  }

  /**
   * Updates variable eval round
   * @param round
   */
  updateEvalRound = (
    round: Exclude<Exclude<VariableV3['eval'], undefined>['round'], undefined>
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        eval: {
          formula: this.state.variable.eval?.formula
            ? this.state.variable.eval.formula
            : '',
          decimals: this.state.variable.eval?.decimals
            ? this.state.variable.eval.decimals
            : 2,
          round
        }
      }
    })
  }

  /**
   * Updates variable's dropdown values
   * @param selectValues
   */
  updateSelectValues = (
    selectValues: Exclude<VariableV3['selectValues'], undefined>
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        selectValues
      }
    })
  }

  /**
   * Updates variable's mask formula
   * @param formula
   */
  updateMaskFormula = (
    formula: Exclude<
      Exclude<VariableV3['mask'], undefined>['formula'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        mask: {
          ...this.state.variable.mask,
          formula
        }
      }
    })
  }

  /**
   * Updates variable's mask placeholder
   * @param placeholder
   */
  updateMaskPlaceholder = (
    placeholder: Exclude<
      Exclude<VariableV3['mask'], undefined>['placeholder'],
      undefined
    >
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        mask: {
          ...this.state.variable.mask,
          placeholder
        }
      }
    })
  }

  /**
   * Updates variable's prefillings
   * @param prefillings
   */
  updatePrefillings = (prefillings: VariableV3['prefillings']) => {
    this.setState({
      variable: {
        ...this.state.variable,
        prefillings
      }
    })
  }

  /**
   * Updates variable's placeholder
   * @param placeholder
   */
  updatePlaceholder = (
    placeholder: Exclude<VariableV3['placeholder'], undefined>
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        placeholder
      }
    })
  }

  /**
   * Updates variable's suffix
   * @param suffix
   */
  updateSuffix = (suffix: Exclude<VariableV3['suffix'], undefined>) => {
    this.setState({
      variable: {
        ...this.state.variable,
        suffix
      }
    })
  }

  /**
   * Updates variable's hidden
   * @param hidden
   */
  updateHidden = (hidden: Exclude<VariableV3['hidden'], undefined>) => {
    this.setState({
      variable: {
        ...this.state.variable,
        hidden
      }
    })
  }

  /**
   * Updates variable's autocomplete
   * @param autocomplete
   */
  updateAutocomplete = (
    autocomplete: Exclude<VariableV3['autocomplete'], undefined>
  ) => {
    this.setState({
      variable: {
        ...this.state.variable,
        autocomplete
      }
    })
  }

  render() {
    return <></>
  }
}

interface IProps {
  variable: VariableV3
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
  toggleDrawer(value: boolean): void
}

export interface VariableEditorStateType {
  id: number
  variable: VariableV3
  updateType: typeof VariableEditor.prototype.updateType
  updateLabel: typeof VariableEditor.prototype.updateLabel
  updateFallbackLabel: typeof VariableEditor.prototype.updateFallbackLabel
  updateConditions: typeof VariableEditor.prototype.updateConditions
  updateValidatorConditions: typeof VariableEditor.prototype.updateValidatorConditions
  updateValidatorMessage: typeof VariableEditor.prototype.updateValidatorMessage
  updateStep: typeof VariableEditor.prototype.updateStep
  updateGrantLevel: typeof VariableEditor.prototype.updateGrantLevel
  updateMandatory: typeof VariableEditor.prototype.updateMandatory
  updateStyle: typeof VariableEditor.prototype.updateStyle
  updateNumberIntOnly: typeof VariableEditor.prototype.updateNumberIntOnly
  updateTextareaSize: typeof VariableEditor.prototype.updateTextareaSize
  updateTextareaLineNumbers: typeof VariableEditor.prototype.updateTextareaLineNumbers
  updateTermsheetLabel: typeof VariableEditor.prototype.updateTermsheetLabel
  updateTermsheetHide: typeof VariableEditor.prototype.updateTermsheetHide
  updateHelper: typeof VariableEditor.prototype.updateHelper
  updateWarning: typeof VariableEditor.prototype.updateWarning
  updateTags: typeof VariableEditor.prototype.updateTags
  updateEvalFormula: typeof VariableEditor.prototype.updateEvalFormula
  updateEvalDecimals: typeof VariableEditor.prototype.updateEvalDecimals
  updateEvalRound: typeof VariableEditor.prototype.updateEvalRound
  updateSelectValues: typeof VariableEditor.prototype.updateSelectValues
  updateMaskFormula: typeof VariableEditor.prototype.updateMaskFormula
  updateMaskPlaceholder: typeof VariableEditor.prototype.updateMaskPlaceholder
  updatePrefillings: typeof VariableEditor.prototype.updatePrefillings
  updatePlaceholder: typeof VariableEditor.prototype.updatePlaceholder
  updateSuffix: typeof VariableEditor.prototype.updateSuffix
  updateHidden: typeof VariableEditor.prototype.updateHidden
  updateAutocomplete: typeof VariableEditor.prototype.updateAutocomplete

  toggleConditionsDrawer(
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ): void
  toggleDrawer(value: boolean): void
}

export default VariableEditor
