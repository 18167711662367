import { toast } from 'react-toastify'
import { I18n } from 'aws-amplify'

const dictionary = {
  fr: {
    'toast-state-error': `Il semble qu'une anomalie soit survenue. Nous vous recommandons de contacter votre account manager si besoin.`
  },
  en: {
    'toast-state-error':
      'A error just occured. Please contact your account manager if needed'
  }
}

I18n.putVocabularies(dictionary)

export function toastError(msg: string = I18n.get('toast-state-error')) {
  toast.error(msg)
}

/**
 * Wraps action into try/catch and executes needed actions
 * in case any error happens
 * @param key Property key
 * @param fn Action function
 * @param boundThis Store instance
 */
function wrapAction(key: string | symbol, fn: Function, boundThis: any) {
  return function wrappedAction(...args: any) {
    try {
      return fn.call(boundThis, ...args)
    } catch (error) {
      toastError()
      console.log(error)
    }
    return undefined
  }
}

/**
 * Actions errors catching decorator
 */
export function catchAction(
  target: any,
  key: string | symbol,
  baseDescriptor?: PropertyDescriptor & { initializer?: any }
): any {
  if (typeof baseDescriptor === 'object') {
    if (baseDescriptor.get !== undefined) {
      throw new Error(` cannot be used with getters "${key.toString()}"`)
    }

    /**
     * Decorating functions
     */
    if (typeof baseDescriptor.value === 'function') {
      const original = baseDescriptor.value as Function
      return {
        value(...args: any) {
          return wrapAction(key, original, this)(...args)
        },
        enumerable: false,
        configurable: true,
        writable: true
      }
    }

    /**
     * Decorating arrow functions
     */
    if (
      typeof key === 'string' &&
      typeof target === 'object' &&
      typeof baseDescriptor.initializer === 'function'
    ) {
      const originalInitializer = baseDescriptor.initializer
      return {
        initializer() {
          const original = originalInitializer.call(this)
          return (...args: any) => {
            return wrapAction(key, original, this)(...args)
          }
        },
        enumerable: false,
        configurable: true,
        writable: true
      }
    }

    throw new Error(`Works only with actions`)
  }
  return baseDescriptor
}
