import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    'Contains a warning': 'Contient un warning'
  },
  en: {
    'Contains a warning': 'Contains a warning'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable warning hide
 */
export const WarningHide: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { warning } = state.variable

  const togglewarning = (enable: boolean) => {
    if (enable === false && typeof warning === 'string')
      state.updateWarning(undefined)
    else if (enable === true && warning === undefined) state.updateWarning('')
  }

  return (
    <CheckboxField
      meta
      checked={typeof warning === 'string'}
      setFieldValue={(name: string, value: boolean) => togglewarning(value)}
      cypressId="warning-checkbox"
      name="warning"
      label={I18n.get('Contains a warning')}
    />
  )
}
