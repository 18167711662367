import React, { createRef } from 'react'
import { getVariableParentTree } from './readers/variable'

type ListType = {
  value: string | number
  render: JSX.Element
  selected?: boolean
}[]

type IProps = {
  list: ListType
  onChange: (value: string | number | false) => void
  setParentTree: React.Dispatch<
    React.SetStateAction<
      | false
      | {
          type: string
          id: number
          label: string
        }[]
    >
  >
}

type IState = {
  selectedIndex: number
}

class PopoverList extends React.Component<IProps, IState> {
  listElement = createRef<HTMLUListElement>()

  resultsElement = createRef<HTMLDivElement>()

  constructor(props: IProps) {
    super(props)

    /**
     * Setting up the state
     */
    const state = {
      selectedIndex: 0
    }

    props.list.forEach((item, index) => {
      if (item.selected === true) state.selectedIndex = index
    })

    this.state = state
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.keydownListener)
    if (this.props.list.length > 0) this.displayParentTree(0)
  }

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.list.map((i) => i.value).join('') !==
      this.props.list.map((i) => i.value).join('')
    )
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selectedIndex: 0 }, () => {
        if (this.props.list.length > 0) this.displayParentTree(0)
        else this.props.setParentTree(false)
        if (this.listElement.current !== null) {
          const activeElement = this.listElement.current?.querySelector(
            '.active'
          ) as HTMLElement
          if (activeElement && this.resultsElement.current) {
            this.resultsElement.current.scrollTop = activeElement.offsetTop - 31
          }
        }
      })
  }

  componentWillUnmount() {
    console.log('removing event listener')
    document.body.removeEventListener('keydown', this.keydownListener)
  }

  triggerChange = () => {
    if (this.props.list[this.state.selectedIndex]) {
      const { value } = this.props.list[this.state.selectedIndex]
      this.props.onChange(value)
    } else {
      this.props.onChange(false)
    }
  }

  keydownListener = (event: KeyboardEvent) => {
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      const diff = event.key === 'ArrowUp' ? -1 : 1
      const selectedIndex = this.state.selectedIndex + diff
      if (selectedIndex >= 0 && selectedIndex < this.props.list.length) {
        this.setState({
          selectedIndex
        })

        // Scrolling current item into view
        const activeElement = this.listElement.current?.querySelector(
          '.active'
        ) as HTMLElement
        if (activeElement && this.resultsElement.current)
          this.resultsElement.current.scrollTop = activeElement.offsetTop - 31

        this.displayParentTree(selectedIndex)
      }
      event.preventDefault()
      event.stopPropagation()
    } else if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      this.triggerChange()
    }
  }

  handleItemClick = (index: number) => {
    this.setState(
      {
        selectedIndex: index
      },
      () => this.triggerChange()
    )
  }

  displayParentTree = (index: number) => {
    const id = this.props.list[index].value
    try {
      const parentTree = getVariableParentTree(id)
      this.props.setParentTree(parentTree)
    } catch (e) {
      console.error(e)
      this.props.setParentTree(false)
    }
  }

  handleItemMouseEnter = (index: number) => {
    this.displayParentTree(index)
  }

  handleItemMouseLeave = () => {
    if (
      this.props.list.length > 0 &&
      this.state.selectedIndex >= 0 &&
      this.state.selectedIndex < this.props.list.length
    ) {
      this.displayParentTree(this.state.selectedIndex)
    } else this.props.setParentTree(false)
  }

  render() {
    return (
      <div className="results" ref={this.resultsElement}>
        <ul ref={this.listElement}>
          {this.props.list.map((item, index) => {
            return (
              <li
                key={`${item.value}-${index}`}
                onClick={() => this.handleItemClick(index)}
                onMouseEnter={() => this.handleItemMouseEnter(index)}
                onMouseLeave={() => this.handleItemMouseLeave()}
                className={index === this.state.selectedIndex ? 'active' : ''}
              >
                {item.render}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default PopoverList
