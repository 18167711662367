import { FDFBoxesV3, FDFInputsV3, ModelV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import React, { useState } from 'react'
import promptModal from '../../../../components/PromptPortal/PromptPortal'
import createStore from '../../../../store/editor/createStore'
import PdfElementsEditor from './components/PdfElementsEditor'

type PDFType = Exclude<ModelV3['documents']['']['pdf'], undefined>

type IProps = {
  close: () => void
  pdf: PDFType
}

const PdfInput: React.FC<{
  input: FDFInputsV3
  active: boolean
  editElement: () => void
}> = ({ input, editElement, active }) => {
  // Getting classNames
  const className: string = [
    'pdf-input',
    active && 'active',
    input.variables.length > 0 && 'linked'
  ]
    .filter((a): a is string => typeof a === 'string')
    .join(' ')

  // Positionning input
  const { x, y, width, height } = input.rect
  const style = {
    top: `${y}px`,
    left: `${x}px`,
    width: `${width}px`,
    height: `${height}px`
  }

  return (
    <div style={style} className={className} onClick={() => editElement()} />
  )
}

const PdfBox: React.FC<{
  box: FDFBoxesV3
  active: boolean
  editElement: () => void
}> = ({ box, editElement, active }) => {
  // Getting classNames
  const className: string = [
    'pdf-box',
    active && 'active',
    box.options.length > 0 && 'linked'
  ]
    .filter((a): a is string => typeof a === 'string')
    .join(' ')

  // Positionning box
  const { x, y, width, height } = box.rect
  const style = {
    top: `${y}px`,
    left: `${x}px`,
    width: `${width}px`,
    height: `${height}px`
  }

  return (
    <div style={style} className={className} onClick={() => editElement()} />
  )
}

const PageElements: React.FC<{
  pdf: PDFType
  page: number
  setEditingElement: (e: FDFInputsV3 | FDFBoxesV3) => void
  editingElement: FDFInputsV3 | FDFBoxesV3 | undefined
}> = ({ pdf, page, setEditingElement, editingElement }) => {
  const inputs = pdf.form.inputs.filter((input) => input.page === page)
  const boxes = pdf.form.boxes.filter((box) => box.page === page)

  // Rendering elements
  return (
    <>
      {inputs.map((input, index) => (
        <PdfInput
          input={input}
          key={`pdf-input-${index}-${page}-${input.name}`}
          editElement={() => setEditingElement(input)}
          active={editingElement === input}
        />
      ))}
      {boxes.map((box, index) => (
        <PdfBox
          box={box}
          key={`pdf-box-${index}-${page}-${box.name}`}
          editElement={() => setEditingElement(box)}
          active={editingElement === box}
        />
      ))}
    </>
  )
}

const PdfPage: React.FC<{
  pdf: PDFType
  index: number
  setEditingElement: (e: FDFInputsV3 | FDFBoxesV3) => void
  editingElement: FDFInputsV3 | FDFBoxesV3 | undefined
}> = ({ pdf, index, setEditingElement, editingElement }) => {
  const [loaded, setLoaded] = useState(false)
  const thumbnail = pdf.thumbnails[index]
  const page = index + 1
  return (
    <div className="pdf-page" style={{ position: 'relative' }}>
      <img
        src={thumbnail}
        alt={`Page ${page}`}
        width={800}
        onLoad={() => setLoaded(true)}
      />
      {loaded && (
        <PageElements
          setEditingElement={setEditingElement}
          editingElement={editingElement}
          pdf={pdf}
          page={page}
        />
      )}
    </div>
  )
}

const EditPdf: React.FC<IProps> = ({ close, pdf }) => {
  const [editingElement, setEditingElement] = useState<
    FDFBoxesV3 | FDFInputsV3 | undefined
  >(undefined)
  const handleDelete = () => {
    promptModal(() => <p>{I18n.get('Do you want to delete the pdf?')}</p>, {
      saveLabel: I18n.get('Yes'),
      closeLabel: I18n.get('No')
    }).then(() => {
      createStore.deleteCurrentDocumentPdf()
      close()
    })
  }
  return (
    <div className="pdf">
      <div className="pdf-content">
        <button type="button" className="close-cta" onClick={close}>
          {I18n.get('Close')}
        </button>
        {pdf.thumbnails.map((thumbnail, index) => {
          return (
            <PdfPage
              key={thumbnail}
              pdf={pdf}
              index={index}
              setEditingElement={setEditingElement}
              editingElement={editingElement}
            />
          )
        })}
        <button type="button" className="delete-cta" onClick={handleDelete}>
          {I18n.get('Delete')}
        </button>
      </div>
      {editingElement && (
        <PdfElementsEditor
          element={editingElement}
          close={() => setEditingElement(undefined)}
        />
      )}
    </div>
  )
}

export default EditPdf
