/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react'
import { SectionV3 } from '@legalplace/models-v3-types'
import SectionEditor, { SectionEditorStateType } from './SectionEditor'

const SectionContext = createContext<SectionEditorStateType>({
  id: 0,
  section: {
    id: 0,
    label: '',
    options: []
  },

  // TODO: Remove this legacy toggles asap
  toggleConditionsDrawer: (open: boolean, type: string, i?: number) => {},
  toggleDrawer: (value: boolean) => {},

  ...SectionEditor.prototype
})

export default SectionContext
