import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    'Can have multiple values': 'Est un multiple',
    warning:
      'Attention, cette question répète %1 output%2, nous avons bloqué la désactivation du multiple.'
  },
  en: {
    'Can have multiple values': 'Can have multiple values',
    warning:
      'Warning, this question repeats %1 output, we have restricted the disabling of multiple.'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option second step
 */
export const MultipleEnabled: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const enabled = state.option.meta.multiple?.enabled
  const repeateds = createStore.getMultipleRepeateds(state.option.meta.id)

  return (
    <div className="step-wrapper">
      {repeateds.length > 0 && (
        <span
          style={{
            color: 'red',
            fontSize: '12px',
            fontWeight: 'bold',
            marginBottom: '8px',
            display: 'block'
          }}
        >
          {I18n.get('warning')
            .replace('%1', repeateds.length)
            .replace('%2', repeateds.length > 1 ? 's' : '')}
        </span>
      )}
      <div className="step-holder">
        <CheckboxField
          meta
          checked={enabled}
          disabled={enabled && repeateds.length > 0}
          name="multiple_enabled"
          setFieldValue={(name: string, value: boolean) => {
            state.updateMultipleEnabled(value)
          }}
          label={I18n.get('Can have multiple values')}
          value="multiples"
        />
      </div>
    </div>
  )
}
