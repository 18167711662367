import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    Hidden: 'Cacher'
  },
  en: {
    Hidden: 'Hidden'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable hidden
 */
export const Hidden: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { hidden } = state.variable

  return (
    <div>
      <div className="step-holder">
        <CheckboxField
          meta
          checked={hidden}
          setFieldValue={(name: string, value: boolean) =>
            state.updateHidden(value)
          }
          cypressId="hidden-checkbox"
          name="hidden"
          label={I18n.get('Hidden')}
        />
      </div>
    </div>
  )
}
