import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import Drawer from '@material-ui/core/Drawer'
import { withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { OptionV3, VariableV3, SectionV3 } from '@legalplace/models-v3-types'
import Element from './element/Element'

const dictionary = {
  fr: {
    'New document': 'Nouveau document'
  },
  en: {
    'New document': 'New document'
  }
}

// TODO: CLEAN UP THIS FILE

I18n.putVocabularies(dictionary)

interface IElementStateDrawerProps {
  isDrawerOpen: boolean
  classes: any
  selectedElement: OptionV3 & VariableV3 & SectionV3
  toggleDrawer(state: boolean): void
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
}

const styles = {
  list: {
    width: 438
  },
  drawer: {},
  root: {
    left: 'unset'
  },
  MuiBackdrop: {
    left: 'unset'
  }
}

@observer
class ElementStateDrawer extends Component<IElementStateDrawerProps> {
  elementRef: React.RefObject<Element>

  constructor(props: IElementStateDrawerProps) {
    super(props)
    this.elementRef = React.createRef()
  }

  handleExit = () => {
    if (this.elementRef.current !== null) this.elementRef.current.saveChanges()
    else this.props.toggleDrawer(false)
  }

  render() {
    const {
      isDrawerOpen,
      toggleDrawer,
      classes,
      selectedElement,
      toggleConditionsDrawer
    } = this.props

    return (
      <Drawer
        open={isDrawerOpen}
        anchor="right"
        onClose={() => this.handleExit()}
      >
        <div className={classes.list} role="presentation">
          <Element
            toggleConditionsDrawer={toggleConditionsDrawer}
            toggleDrawer={toggleDrawer}
            selectedElement={selectedElement}
            ref={this.elementRef}
          />
          <Divider />
        </div>
      </Drawer>
    )
  }
}

export default withStyles(styles)(ElementStateDrawer)
