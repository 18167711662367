import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    IntOnly: 'Accepter les nombres entiers seulement'
  },
  en: {
    IntOnly: 'Force integers'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable number intOnly
 */
export const IntOnly: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { hidden } = state.variable

  return (
    <div>
      <div className="step-holder">
        <CheckboxField
          meta
          checked={hidden}
          setFieldValue={(name: string, value: boolean) =>
            state.updateNumberIntOnly(value)
          }
          cypressId="intonly-checkbox"
          name="intonly"
          label={I18n.get('IntOnly')}
        />
      </div>
    </div>
  )
}
