import React, { Component } from 'react'
import { observer } from 'mobx-react'
import './style.scss'
import Dialog from '@material-ui/core/Dialog'
import { action, observable } from 'mobx'
import { ErrorMessage, Formik } from 'formik'
import { I18n } from 'aws-amplify'
import MaterialSelect from '../MaterialSelect'
import FormField from '../FormField/FormField'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface ISelectMetaModalProps {
  open: boolean
  toggleModal(name: string, value: boolean): void
  createEmptyDoc(permalink: string): void
  options: any
}

const columns = {
  en: {
    'new template': 'New Template',
    name: 'Name',
    permalink: 'Permalink',
    category: 'Category',
    'cancel btn modal': 'Cancel',
    'create btn modal': 'Create new template'
  },
  fr: {
    'new template': 'Nouveau template',
    name: 'Nom',
    permalink: 'Permalink',
    category: 'Catégorie',
    'cancel btn modal': 'Annuler',
    'create btn modal': 'Valider'
  }
}

I18n.putVocabularies(columns)

@observer
class SelectMetaModal extends Component<ISelectMetaModalProps> {
  @observable permalink = ''

  @observable category = ''

  private initialValues = {
    name: '',
    permalink: '',
    server_error: ''
  }

  @catchAction
  @action
  componentDidUpdate(): void {
    if (this.props.options[0] && !this.category) {
      this.category = this.props.options[0].value
    }
  }

  private validate = (values: any) => {
    const errors: any = {}
    if (!values.name) {
      errors.name = 'Required'
    }
    if (!values.permalink) {
      errors.permalink = 'Required'
    }
    if (!/^[a-zA-Z0-9\-_]+$/g.test(values.permalink)) {
      errors.permalink =
        'This permalink should not contain any space or special caracter'
    }
    if (/\s/g.test(values.permalink)) {
      errors.permalink =
        'This permalink should not contain any space or special caracter'
    }
    return errors
  }

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    console.log('')

    toggleModal('selectMetaModalIsOpen', open)
  }

  @catchAction
  @action
  handleSelectBlock = (name: any, value: any) => {
    this.category = value
  }

  @catchAction
  @action
  handleSave = (values: any) => {
    const data = { ...values, category_id: this.category }
    this.props.createEmptyDoc(data)
  }

  render() {
    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="move-modal-holder"
      >
        <div className="move-modal">
          <h2>{I18n.get('new template')}</h2>
          <div className="move-select-holder">
            <Formik
              initialValues={this.initialValues}
              validate={this.validate}
              onSubmit={this.handleSave}
            >
              {({ handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormField
                    label={I18n.get('name')}
                    type="text"
                    name="name"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <FormField
                    label={I18n.get('permalink')}
                    type="text"
                    name="permalink"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <ErrorMessage name="server_error" />
                  <div className="btn-holder">
                    <button
                      type="button"
                      className="btn-cancel"
                      onClick={() => this.toggleModal(false)}
                    >
                      {I18n.get('cancel btn modal')}
                    </button>
                    <button
                      type="submit"
                      className="primary"
                      onClick={() => {}}
                    >
                      {I18n.get('create btn modal')}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <MaterialSelect
              title="Category"
              fieldClass="move-modal-select template-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectBlock(name, value)
              }
              name="Type"
              value={this.category}
              options={this.props.options}
            />
          </div>
        </div>
      </Dialog>
    )
  }
}

export default SelectMetaModal
