import {
  SectionV3,
  ModelV3,
  OptionV3,
  ConditionV3,
  VariableV3
} from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'

const dictionary = {
  fr: {
    'The conditions of': 'Les conditions concernant',
    'The conditions of prefillers of':
      'Les conditions des pré-remplissages concernant',
    'The validator conditions of': 'Les conditions du validateur concernant',
    'Unkown condition of': 'Les conditions inconnus concernant',
    'have not been entirely copied': "n'ont pas été copier intégralement",

    // Variables labels
    'the list "%1"': 'la liste déroulante "%1"',
    'the eval "%1"': 'la calculatrice "%1"',
    'the text "%1"': 'le champ texte "%1"',
    'the number "%1"': 'le champ nombre "%1"',
    'the date "%1"': 'le champ date "%1"',
    'the hour "%1"': 'le champ heure "%1"',
    'the email "%1"': 'le champ email "%1"',
    'the textarea "%1"': 'le champ multiligne "%1"',
    'the mask "%1"': 'le champ masque avancé "%1"',

    // Options labels
    'the static "%1"': 'la question "%1"',
    'the radio "%1"': 'la radio "%1"',
    'the checkbox "%1"': 'la case à cocher "%1"',
    'the hidden "%1"': 'l\'output "%1"',
    'the repeated "%1"': 'l\'output "%1"',
    'the box "%1"': 'la boite info "%1"'
  },
  en: {
    // Variables labels
    'the list "%1"': 'the dropdown "%1"',
    'the eval "%1"': 'the calculator field "%1"',
    'the text "%1"': 'the text field "%1"',
    'the number "%1"': 'the number field "%1"',
    'the date "%1"': 'the date field "%1"',
    'the hour "%1"': 'the hour field "%1"',
    'the email "%1"': 'the email field "%1"',
    'the textarea "%1"': 'the multi-line field "%1"',
    'the mask "%1"': 'the mask field "%1"',

    // Options labels
    'the static "%1"': 'the question "%1"',
    'the radio "%1"': 'the radio "%1"',
    'the checkbox "%1"': 'the checkbox "%1"',
    'the hidden "%1"': 'the output "%1"',
    'the repeated "%1"': 'the output "%1"',
    'the box "%1"': 'the box "%1"'
  }
}

I18n.putVocabularies(dictionary)

type CopyReportMessageType = {
  id: number
  optionOrVariable: 'option' | 'variable'
  message: string
}

type ReportDataType = {
  id: number
  label: string
  type: OptionV3['meta']['type'] | VariableV3['type']
  optionOrVariable: 'option' | 'variable'
  errorType: 'condition' | 'validator' | 'prefill'
}

class CopySection {
  private originalSection: SectionV3

  private originalModel: ModelV3

  private destinationModel: ModelV3

  private destinationIndex: number

  private optionsToCopy: number[]

  private variablesToCopy: number[]

  private newOptionsIds: Record<string, number> = {}

  private newVariablesIds: Record<string, number> = {}

  private copyReport: CopyReportMessageType[] = []

  constructor({
    id,
    originalModel,
    destinationModel,
    destinationIndex,
    getNewOptionId,
    getNewVariableId
  }: {
    id: number
    originalModel: ModelV3
    destinationModel: ModelV3
    destinationIndex: number
    getNewOptionId: () => number
    getNewVariableId: () => number
  }) {
    this.originalModel = originalModel
    this.destinationModel = destinationModel
    this.destinationIndex = destinationIndex

    // Getting original section from model
    const originalSection = originalModel.documents.main.sections.find(
      (section) => section.id === id
    )
    if (originalSection === undefined) {
      throw new Error(
        `Unable to find section ${id} on given model to copy from`
      )
    }
    this.originalSection = originalSection

    // Getting all options to copy
    this.optionsToCopy = this.getOptionsToCopy(originalSection)
    this.variablesToCopy = this.getVariablesToCopy(originalSection)

    // Getting new options ids
    this.optionsToCopy.forEach((optionId) => {
      this.newOptionsIds[optionId] = getNewOptionId()
    })

    // Getting new variables ids
    this.variablesToCopy.forEach((variableId) => {
      this.newVariablesIds[variableId] = getNewVariableId()
    })

    // Copying options & variables (Note that you can't do this before setting ids due to conditions copy)
    this.optionsToCopy.forEach((optionId) => this.copyOption(optionId))
    this.variablesToCopy.forEach((variableId) => this.copyVariable(variableId))

    // Copying section
    this.copySection()
  }

  get report() {
    return this.copyReport
  }

  private copySection() {
    // Getting new section's id
    const id = this.destinationIndex + 1

    // Setting new options array
    const options = this.originalSection.options.map(
      (childrenOriginalId) => this.newOptionsIds[childrenOriginalId]
    )

    // Setting newsection
    const newSection: SectionV3 = {
      ...this.originalSection,
      id,
      options,
      conditions: undefined // Removing sections conditions (no need to copy since none can be children)
    }

    // Copying section to model
    this.destinationModel.documents.main.sections.splice(
      this.destinationIndex,
      0,
      newSection
    )

    // Resetting sections ids
    this.destinationModel.documents.main.sections = this.destinationModel.documents.main.sections.map(
      (section: any, index: number) => {
        section.id = index + 1
        return section
      }
    )
  }

  private copyOption(originalId: number) {
    const originalOption = this.originalModel.options[originalId]

    // Cloning option
    const id = this.newOptionsIds[originalId]

    const { label, type } = originalOption.meta

    const reportData: ReportDataType = {
      id,
      label,
      type,
      optionOrVariable: 'variable',
      errorType: 'condition'
    }

    // Copying conditions
    const conditions = originalOption.meta.conditions
      ? this.copyConditions(originalOption.meta.conditions, reportData)
      : undefined

    // Copying validators
    const validator = originalOption.meta.validator
      ? {
          ...originalOption.meta.validator,
          conditions: originalOption.meta.validator.conditions
            ? this.copyConditions(originalOption.meta.validator.conditions, {
                ...reportData,
                errorType: 'validator'
              })
            : undefined
        }
      : undefined

    // Setting new options array
    const options = originalOption.options.map(
      (childrenOriginalId) => this.newOptionsIds[childrenOriginalId]
    )

    // Setting new variables array
    const variables = originalOption.variables.map(
      (childrenOriginalId) => this.newVariablesIds[childrenOriginalId]
    )

    // Creating new option's object
    const newOption: OptionV3 = {
      ...originalOption,
      options,
      variables,
      meta: {
        ...originalOption.meta,
        conditions,
        validator,
        id
      }
    }

    // Adding option to model
    this.destinationModel.options[id] = newOption
  }

  private copyVariable(originalId: number) {
    const originalVariable = this.originalModel.variables[originalId]

    // Cloning variable
    const id = this.newVariablesIds[originalId]

    const { label, type } = originalVariable

    const reportData: ReportDataType = {
      id,
      label,
      type,
      optionOrVariable: 'variable',
      errorType: 'condition'
    }

    // Copying conditions
    const conditions = originalVariable.conditions
      ? this.copyConditions(originalVariable.conditions, reportData)
      : undefined

    // Copying validators
    const validator = originalVariable.validator
      ? {
          ...originalVariable.validator,
          conditions: originalVariable.validator.conditions
            ? this.copyConditions(originalVariable.validator.conditions, {
                ...reportData,
                errorType: 'validator'
              })
            : undefined
        }
      : undefined

    // Copying prefillers conditions
    const prefillings = originalVariable.prefillings
      ? originalVariable.prefillings.map((currentPrefilling) => {
          return {
            ...currentPrefilling,
            conditions: currentPrefilling.conditions
              ? this.copyConditions(currentPrefilling.conditions, {
                  ...reportData,
                  errorType: 'validator'
                })
              : undefined
          }
        })
      : undefined

    // Creating new variable's object
    const newVariable: VariableV3 = {
      ...originalVariable,
      conditions,
      validator,
      prefillings,
      id
    }

    // Adding variable to model
    this.destinationModel.variables[id] = newVariable
  }

  private copyConditions(
    conditions: ConditionV3,
    reportData: ReportDataType
  ): ConditionV3 | undefined {
    if (Object.keys(conditions).length === 0) return undefined

    // Getting object's key
    const [key] = Object.keys(conditions)

    switch (key) {
      case 'and':
      case 'or': {
        const originalConditions = conditions[key]
        // Making sure original condition is defined
        if (originalConditions === undefined) return undefined
        const newConditions = originalConditions
          .map((conditionNode) =>
            this.copyConditions(conditionNode, reportData)
          )
          .filter(
            (a: ConditionV3 | undefined): a is ConditionV3 => a !== undefined
          )
        return newConditions.length > 0 ? { [key]: newConditions } : undefined
      }
      case 'selected':
      case 'not-selected':
      case 'not-selected-ip':
      case 'has-one':
      case 'at-least-one':
      case 'has-many':
      case 'contains':
      case 'does-not-contain':
      case '=':
      case '>':
      case '<':
      case '!=':
      case '>=':
      case '<=':
      case '<>':
      case '==':
      case 'empty':
      case 'not-empty':
      case 'is':
      case 'not':
      case 'like':
      case 'not-like': {
        const originalConditions = conditions[key]
        // Making sure original condition is defined
        if (originalConditions === undefined) return undefined
        if (Object.keys(originalConditions[0])[0] === 'var') {
          // Getting condition id
          const [elemType, elemId] = originalConditions[0].var.split('.')
          if (
            ['o', 'v'].includes(elemType) &&
            Number.isInteger(parseInt(elemId, 10))
          ) {
            // Replacing element id
            const newElementId = (elemType === 'o'
              ? this.newOptionsIds
              : this.newVariablesIds)[elemId]

            // If id isn't replaçable we return undefined
            if (newElementId === undefined) {
              const {
                id,
                label,
                type,
                optionOrVariable,
                errorType
              } = reportData

              if (
                this.copyReport.findIndex(
                  (report) =>
                    report.id === id &&
                    report.optionOrVariable === optionOrVariable
                ) === -1
              ) {
                let message = ''

                // Writing subtype
                switch (errorType) {
                  case 'condition':
                    message += I18n.get('The conditions of')
                    break
                  case 'prefill':
                    message += I18n.get('The conditions of prefillers of')
                    break
                  case 'validator':
                    message += I18n.get('The validator conditions of')
                    break
                  default:
                    message += I18n.get('Unkown condition of')
                }

                message += ` ${this.parseI18n(`the ${type} "%1"`, label)}`

                // Writing label
                message += ` ${I18n.get('have not been entirely copied')}`

                this.copyReport.push({
                  id,
                  optionOrVariable,
                  message
                })
              }

              // Returning undefiner
              return undefined
            }

            // Returning new condition with replaced condition
            return {
              [key]: [
                { var: `${elemType}.${newElementId}` },
                ...originalConditions.splice(1)
              ]
            }
          }

          throw new Error(
            `Unrecognized condition node ${JSON.stringify(conditions)}`
          )
        } else {
          throw new Error(
            `Unrecognized condition node ${JSON.stringify(conditions)}`
          )
        }
      }
      default:
        throw new Error(
          `Unrecognized condition node ${JSON.stringify(conditions)}`
        )
    }
  }

  private getOptionsToCopy<T extends SectionV3 | OptionV3>(elem: T) {
    let options = [...elem.options]
    elem.options.forEach((childId) => {
      options = [
        ...options,
        ...this.getOptionsToCopy(this.originalModel.options[childId])
      ]
    })
    return options
  }

  private getVariablesToCopy<T extends SectionV3 | OptionV3>(elem: T) {
    const isOption = (e: SectionV3 | OptionV3): e is OptionV3 =>
      Object.prototype.hasOwnProperty.call(e, 'variables')
    let variables = [...(isOption(elem) ? elem.variables : [])]
    elem.options.forEach((childId) => {
      variables = [
        ...variables,
        ...this.getVariablesToCopy(this.originalModel.options[childId])
      ]
    })
    return variables
  }

  /**
   * Parses a given message with its arguments
   * Example:
   * parseI18n('Hello %1, how are you %2? %1', 'John', 'today')
   * returns
   * "Hello John, how are you today? John"
   * @param str
   * @param args
   */
  private parseI18n(str: string, ...args: (string | number)[]) {
    let parsedMessage = I18n.get(str)

    args.forEach((argument, index) => {
      const tagRegex = new RegExp(`([^\\\\])?%${index + 1}`, 'g')

      parsedMessage = parsedMessage.replace(
        tagRegex,
        `$1${typeof argument === 'number' ? argument.toString() : argument}`
      )
    })

    return parsedMessage
  }
}

export default CopySection
