import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    'Box collapsable': 'Boite info rétractable'
  },
  en: {
    'Box collapsable': 'Box collapsable'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits box collapsable
 */
export const BoxCollapsable: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const collapsable = state.option.meta.box?.collapsable || false

  return (
    <div className="label-holder">
      <CheckboxField
        meta
        checked={collapsable}
        name="boxCollapsable"
        setFieldValue={(name: string, value: boolean) =>
          state.updateBoxCollapsable(value)
        }
        label={I18n.get('Box collapsable')}
        value="collapsable"
      />
    </div>
  )
}
