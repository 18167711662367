import React from 'react'

const VariableHookLeaf: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <span {...props.attributes} data-variable-hook-id="variable-hook">
      {props.children}
    </span>
  )
}

export default VariableHookLeaf
