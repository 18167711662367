import { createEditor, Node } from '@legalplace/slate'
import { withHistory } from '@legalplace/slate-history'
import { Editable, Slate, withReact } from '@legalplace/slate-react'
import { reaction } from 'mobx'
import React from 'react'
import createStore from '../../../../store/editor/createStore'
import { renderElement, renderLeaf } from './components/elements'
import { Toolbar } from './components/toolbar'
import { handleKeyDown } from './events/keyboardEvents'
import handleKeyUp from './events/keyupEvents'
import handleMouseUp from './events/mouseupEvents'
import EditorHelpers from './helpers/EditorHelpers'
import { serializeOutputs } from './serializers/serialize'
import './style.scss'
import Popover from './variableUtils/Popover'
import { deserializeWithCache, withCache } from './withCache'

let currentDocument = ''

type SlateEditorProps = {
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ) => void
}

type SlateEditorState = {
  value: Node[]
  popover: boolean
}

class SlateEditor extends React.PureComponent<
  SlateEditorProps,
  SlateEditorState
> {
  editor = withHistory(withReact(withCache(createEditor())))

  editableRef = React.createRef<HTMLDivElement>()

  constructor(props: SlateEditorProps) {
    super(props)

    this.state = {
      value: deserializeWithCache(createStore.currentDocumentOutputs, false),
      popover: createStore.slateEditorVariablePopover
    }

    // Reacting to document outputs updates
    reaction(
      () => createStore.currentDocumentOutputs,
      (outputs) => {
        if (currentDocument !== createStore.currentDocument) {
          // Setting focus on first output
          EditorHelpers.applySelection(this.editor, {
            anchor: { path: [0], offset: 0 },
            focus: { path: [0], offset: 0 }
          })
          currentDocument = createStore.currentDocument
          this.setState({ value: deserializeWithCache(outputs, true) })
        }
      }
    )

    // Reacting to popover toggle
    reaction(
      () => createStore.slateEditorVariablePopover,
      () => {
        if (this.state.popover !== createStore.slateEditorVariablePopover)
          this.setState({ popover: createStore.slateEditorVariablePopover })
      }
    )
  }

  componentDidMount() {
    createStore.setSlateEditor(this.editor)
  }

  handleOnChange = (newValue: Node[]) => {
    this.setState({ value: newValue })
    const changedValues = newValue.filter(
      (changedValue) => this.state.value.indexOf(changedValue) === -1
    )
    if (currentDocument !== '' && changedValues.length > 0) {
      createStore.pushHistory({
        slateHistory: this.editor.history.undos.length
      })
      serializeOutputs(newValue)
    }
  }

  updateOutputs() {
    this.setState({
      value: deserializeWithCache(createStore.currentDocumentOutputs, true)
    })
  }

  render() {
    return (
      <div className="slate-editor" ref={this.editableRef}>
        <Slate
          editor={this.editor}
          value={this.state.value}
          onChange={this.handleOnChange}
        >
          <Toolbar toggleConditionsDrawer={this.props.toggleConditionsDrawer} />
          <Editable
            className="slate-editor-inner"
            style={{
              whiteSpace: 'unset',
              WebkitUserModify: 'read-write',
              MozUserModify: 'read-write',
              wordBreak: 'break-word'
            }}
            spellCheck
            autoFocus
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onMouseUp={(e) => handleMouseUp(e, this.editor)}
            onKeyUp={(e) => handleKeyUp(e, this.editor)}
            onKeyDown={(e) => handleKeyDown(e, this.editor)}
          />
          {this.state.popover && <Popover />}
        </Slate>
      </div>
    )
  }
}

export default SlateEditor
