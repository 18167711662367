import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import '../assets/scss/main.scss'
import { Route, Switch } from 'react-router'
import { IUserStore } from '../store/userStore'
import DashboardContainer from '../pages/Dashboard/DashboardContainer'
import EditorContainer from '../pages/Editor/EditorContainer'
import EditorPage from '../pages/Editor/EditorPage/EditorPage'

interface IAuthorizedLayoutProps {
  userStore?: IUserStore | any
}

@inject('userStore')
@observer
class AuthorizedLayout extends Component<IAuthorizedLayoutProps> {
  UNSAFE_componentWillMount() {
    this.props.userStore.fetchUser()
  }

  public render(): ReactNode {
    return (
      <>
        <Switch>
          <Route path="/contracts" component={EditorContainer} />
          <Route path="/" component={DashboardContainer} />
          <Route exact path="/editor" component={EditorPage} />
        </Switch>
      </>
    )
  }
}

export default AuthorizedLayout
