import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'

import Button from '../../../components/Editor/Button/Button'
import Divider from '../../../components/Editor/Divider/Divider'
import { IContractsStore } from '../../../store/editor/contractsStore'
import Input from '../../../components/Editor/Input/Input'
import Select from '../../../components/Editor/Select/Select'
import Title from '../../../components/Editor/Title/Title'
import './style.scss'

const dictionary = {
  fr: {
    'New document': 'Nouveau document',
    'Contract name': 'Nom du contrat',
    Permalink: 'Permalink',
    Subtitle: 'Sous titre',
    Category: 'Catégorie',
    Type: 'Type',
    Language: 'Langue',
    'Title of the HTML page': 'Titre de la page HTML',
    'Title about': 'Titre à propos',
    'Keywords (META)': 'Mots clées (META)',
    'Description (META)': 'Description (META)',
    'Auto-recovery bar': 'Barre de reprise automatique',
    'Title of the bar': 'Titre de la barre',
    'Text of the bar': 'Texte de la barre',
    'Button text': 'Text du boutton',
    'EXAMPLE: Your Untitled Document was recorded 7 minutes ago':
      'EXAMPLE: Votre Document sans titre a été enregistré il y a 7 minutes',
    'EXAMPLE: Resume Untitled Document':
      'EXAMPLE: Reprendre Document sans titre',
    Save: 'Enregistrer'
  },
  en: {
    'New document': 'New document',
    'Contract name': 'Contract name',
    Permalink: 'Permalink',
    Subtitle: 'Subtitle',
    Category: 'Category',
    Type: 'Type',
    Language: 'Language',
    'Title of the HTML page': 'Title of the HTML page',
    'Title about': 'Title about',
    'Keywords (META)': 'Keywords (META)',
    'Description (META)': 'Description (META)',
    'Auto-recovery bar': 'Auto-recovery bar',
    'Title of the bar': 'Title of the bar',
    'Text of the bar': 'Text of the bar',
    'Button text': 'Button text',
    'EXAMPLE: Your Untitled Document was recorded 7 minutes ago':
      'EXAMPLE: Your Untitled Document was recorded 7 minutes ago',
    'EXAMPLE: Resume Untitled Document': 'EXAMPLE: Resume Untitled Document',
    Save: 'Save'
  }
}

I18n.putVocabularies(dictionary)
// I18n.setLanguage('fr');

const langs = [
  {
    value: 'fr_FR',
    text: 'Français'
  },
  {
    value: 'de_DE',
    text: 'Allemand'
  }
]
const categories = [
  {
    value: '1',
    text: 'Contrat de location'
  },
  {
    value: '2',
    text: 'Famille'
  },
  {
    value: '3',
    text: 'Création de sociétés'
  },
  {
    value: '6',
    text: 'Propriété Intellectuelle'
  },
  {
    value: '7',
    text: 'Contrat de travail'
  },
  {
    value: '9',
    text: 'Vie quotidienne'
  },
  {
    value: '10',
    text: 'Droit social'
  },
  {
    value: '11',
    text: 'Mietvertrag'
  },
  {
    value: '12',
    text: 'Locacao'
  },
  {
    value: '13',
    text: 'Kaufvertrag'
  },
  {
    value: '14',
    text: 'Carte grise en ligne'
  },
  {
    value: '15',
    text: 'Création entreprise'
  }
]
const types = [
  {
    value: 'contrat',
    text: 'Contrat'
  },
  {
    value: 'demarche',
    text: 'Démarche'
  }
]
// const version = [{
//   value: '2',
//   text: '2'
// },
//   {
//     value: '4',
//     text: '4'
//   },
//   {
//     value: '5',
//     text: '5'
//   },
//   {
//     value: '7',
//     text: '7'
//   }];
// const payment = [{
//   value: '0',
//   text: 'PostPayment Standard'
// },
//   {
//     value: '1',
//     text: 'Texte avec Facture Optionnelle'
//   },
//   {
//     value: '2',
//     text: 'Facture Obligatoire'
//   }];

interface INewContractPageProps {
  contractsStore?: IContractsStore | any
}

@inject('contractsStore')
@observer
class NewContractPage extends Component<INewContractPageProps> {
  render() {
    return (
      <div className="dashboard models">
        <div className="breadcrumbs">
          <Link to="/contracts">{I18n.get('Contracts')}</Link>
          <span> &gt; </span>
          <span>{I18n.get('New document')}</span>
        </div>
        <Title title={I18n.get('New document')} link="/" showButton={false} />
        <div className="input-block">
          <Input label={I18n.get('Contract name')} />
          <Input label={I18n.get('Permalink')} />
          <Input label={I18n.get('Subtitle')} />
          <Select options={categories} label={I18n.get('Category')} />
          <Select options={types} label={I18n.get('Type')} />
          <Select options={langs} label={I18n.get('Language')} />
          <div className="seo-container">
            <div>
              <Input label={I18n.get('Title of the HTML page')} />
              <Input label={I18n.get('Keywords (META)')} />
            </div>
            <div>
              <Input label={I18n.get('Title about')} />
              <Input textarea label={I18n.get('Description (META)')} />
            </div>
          </div>
        </div>
        <Divider />
        pricing
        <Divider />
        <h3 className="subtitle">{I18n.get('Auto-recovery bar')}</h3>
        <div className="input-block">
          <Input className="long" label={I18n.get('Title of the bar')} />
          <Input
            title="EXAMPLE: Your Untitled Document was recorded 7 minutes ago"
            label={I18n.get('Text of the bar')}
          />
          <Input
            title="EXAMPLE: Resume Untitled Document': 'EXAMPLE: Resume Untitled Document"
            label={I18n.get('Button text')}
          />
        </div>
        <Button className="right" type="big">
          {I18n.get('Save')}
        </Button>
      </div>
    )
  }
}

export default NewContractPage
