import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    'First number': 'Numéro de la première occurence'
  },
  en: {
    'First number': 'First number'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option second step
 */
export const MultipleIncrementationStart: React.FC<{
  state: OptionEditorStateType
}> = ({ state }) => {
  if (!state.option.meta.multiple || !state.option.meta.multiple?.enabled)
    return null
  const incrementationStart =
    typeof state.option.meta.multiple.incrementationStart === 'number'
      ? state.option.meta.multiple.incrementationStart
      : ''

  return (
    <Input
      value={incrementationStart}
      meta
      name="incrementationStart"
      type="number"
      setFieldValue={(name: string, value: string) =>
        state.updateMultipleIncrementationStart(parseInt(value, 10))
      }
      label={I18n.get('First number')}
    />
  )
}
