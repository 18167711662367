import { I18n } from 'aws-amplify'
import { ErrorMessage, Formik } from 'formik'
import { inject, observer } from 'mobx-react'
import React, { Component, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/main.scss'
import FormField from '../components/FormField/FormField'
import Icon from '../components/Icons/Icon'
import { CHECKMARK } from '../components/Icons/icons'
import { IAuthStore } from '../store/authStore'

interface IForgotPageProps {
  authStore: IAuthStore
}

export interface IForgotValues {
  email?: string
  server_error?: string
}

const columns = {
  en: {
    forgot_title: 'Forgot password',
    forgot_text:
      'Hi, to access the dashboard, please enter your login information',
    email: 'Email',
    forgot_btn: 'Reset my password',
    success:
      'Great! a password reset link has been sent to your inbox, make sure to check your “Spam” folder too',
    'forgot-login': 'Login',
    Required: 'Required'
  },
  fr: {
    forgot_title: 'Mot de passe oublié',
    forgot_text: 'Merci de rentrer vos identifiants ci-dessous',
    email: 'Email',
    forgot_btn: 'Valider',
    success:
      'Un email a été envoyé à votre adresse pour réinitialiser votre mot de passe. Si vous ne le recevez pas, pensez à vérifier vos spams !',
    'forgot-login': 'Connexion',
    Required: 'Obligatoire'
  }
}

I18n.putVocabularies(columns)

@inject('authStore')
@observer
class ForgotPage extends Component<IForgotPageProps> {
  private initialValues: IForgotValues = {
    email: '',
    server_error: ''
  }

  private validate = (values: IForgotValues) => {
    const errors: IForgotValues = {}
    if (!values.email) {
      errors.email = I18n.get('Required')
    }
    return errors
  }

  public render(): ReactNode {
    const { forgot } = this.props.authStore
    return (
      <div className="content forgotContainer">
        <div className="ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl text-center">{I18n.get('forgot_title')}</h3>
          </div>
        </div>
        <div className="forgotFormContainer">
          <div className="grid-container-form">
            <div className="grid-item">
              <Formik
                initialValues={this.initialValues}
                validate={this.validate}
                onSubmit={forgot}
              >
                {({ handleChange, handleBlur, handleSubmit, isSubmitting }) =>
                  !isSubmitting ? (
                    <>
                      <form onSubmit={handleSubmit} noValidate>
                        <h4 className="form-ttl text-center">
                          {I18n.get('forgot_text')}
                        </h4>
                        <FormField
                          label={I18n.get('email')}
                          type="email"
                          name="email"
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                        <ErrorMessage name="server_error" />
                        <div className="form-footer">
                          <button
                            className="btn btn-active btn-send btn-double-padding-side ma"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {I18n.get('forgot_btn')}
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className="success-massage">
                        <div className="icon-block">
                          <Icon iconClass="iconCheckmark" icon={CHECKMARK} />
                        </div>
                        <p className="text-center">{I18n.get('success')}</p>
                      </div>
                    </>
                  )
                }
              </Formik>
            </div>
          </div>
        </div>

        <div className="flex-center">
          <Link className="footer-link" to="/login">
            {I18n.get('forgot-login')}
          </Link>
        </div>
      </div>
    )
  }
}

export default ForgotPage
