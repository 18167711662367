import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import './style.scss'

const Link = (props: any) => {
  const { href, children } = props
  return (
    <RouterLink className="link" to={href}>
      {children}
    </RouterLink>
  )
}

export default Link
