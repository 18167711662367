import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    'Termsheet Label': 'Label dans le résumé'
  },
  en: {
    'Termsheet Label': 'Termsheet Label'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable termsheet label
 */
export const TermsheetLabel: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  if (state.variable.termsheet?.hide === true) return null
  const label = state.variable.termsheet?.label || ''

  return (
    <Input
      value={label}
      meta
      name="label"
      setFieldValue={(name: string, value: string) =>
        state.updateTermsheetLabel(value)
      }
      label={I18n.get('Termsheet Label')}
    />
  )
}
