import React from 'react'
import GeneralOptionParams from './option/general'
import OptionEditor from './editors/OptionEditor'
import OptionContext from './editors/OptionContext'
import DisplayOptionParams from './option/display'
import MultipleOptionParams from './option/multiple'
import StyleOptionParams from './option/style'
import TermsheetOptionParams from './option/termsheet'
import MessagesOptionParams from './option/messages'
import OtherOptionParams from './option/other'
import { ConditionOf } from './option/components/ConditionOf'
import ValidatorOptionParams from './option/validators'
import BoxOptionParams from './option/box'
import { DrawerHead } from './option/drawerHead'

class OptionElement extends OptionEditor {
  render() {
    return (
      <div className="element-drawer">
        <DrawerHead
          saveUpdates={this.saveUpdates}
          deleteOption={this.deleteOption}
          moveOption={this.moveOption}
          closeDrawer={this.closeDrawer}
        />
        <OptionContext.Provider value={this.state}>
          <GeneralOptionParams />
          <BoxOptionParams />
          <ValidatorOptionParams />
          <MultipleOptionParams />
          <DisplayOptionParams />
          <StyleOptionParams />
          <MessagesOptionParams />
          <TermsheetOptionParams />
          <OtherOptionParams />
          <ConditionOf state={this.state} />
        </OptionContext.Provider>
      </div>
    )
  }
}

export default OptionElement
