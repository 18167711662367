import React, { Component, ReactNode } from 'react'
import { I18n } from 'aws-amplify'
import { observer } from 'mobx-react'

import LinkButton from '../../../../components/Editor/LinkButton/LinkButton'
import './style.scss'

const buttons = {
  fr: {
    'Modify the contract': 'Modifier le contrat',
    Alias: 'Alias',
    Models: 'Modèles'
  },
  en: {
    'Modify the contract': 'Modify the contract',
    Alias: 'Alias',
    Models: 'Models',
    Overview: 'Overview'
  }
}

I18n.putVocabularies(buttons)

interface IListItemProps {
  item: any

  selectContract(id: number): void
}

@observer
class ListItem extends Component<IListItemProps> {
  public setSelectedContract = () => {
    const { item, selectContract } = this.props
    selectContract(item.contract_meta_id)
  }

  public render(): ReactNode {
    const {
      item: {
        contract_meta_id: { state, name, id: metaId },
        version,
        id
      }
    } = this.props
    const stateValue = state === 2 ? 'Production' : 'Broullion'
    return (
      <li>
        <div className="title">
          <h4>{name}</h4>
        </div>
        <div className="state">{stateValue}</div>
        <div className="nb_models">{version}</div>
        <div className="buttons">
          <LinkButton href={`/contracts/edit/${metaId}`}>
            {I18n.get('Modify the contract')}
          </LinkButton>
          <LinkButton href={`/duplicates/${id}`}>
            {I18n.get('Alias')}
          </LinkButton>
          <LinkButton
            onClick={this.setSelectedContract}
            href={`/contracts/models/${metaId}`}
          >
            {I18n.get('Models')}
          </LinkButton>
          <LinkButton href="http://www.legalplace.fr/contrats/accord-confidentialite">
            {I18n.get('Overview')}
          </LinkButton>
        </div>
      </li>
    )
  }
}

export default ListItem
