import { MouseEvent } from 'react'
import { Editor } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import createStore from '../../../../../store/editor/createStore'
import { deserializeOutputs } from '../serializers/deserialize'

export const addOutputEvent = (
  event: MouseEvent<HTMLDivElement>,
  editor: Editor & ReactEditor,
  id: number,
  position: 'after' | 'before',
  repeatedId?: number
) => {
  const { addOutput } = createStore
  const { selection } = editor
  if (selection === null) return

  const { anchor, focus } = selection
  const { path } = anchor

  const optionId = repeatedId || id

  const newOption = addOutput(optionId, position)

  const newNodeIndex = path[0] + (position === 'before' ? 0 : 1)

  // Inserting node
  editor.apply({
    type: 'insert_node',
    path: [newNodeIndex],
    node: deserializeOutputs([{ option: newOption, children: [] }])[0]
  })

  // Focusing on node
  editor.apply({
    type: 'set_selection',
    properties: {
      anchor,
      focus
    },
    newProperties: {
      anchor: {
        path: [newNodeIndex, 0],
        offset: 0
      },
      focus: {
        path: [newNodeIndex, 0],
        offset: 0
      }
    }
  })
}
