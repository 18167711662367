import { Node, Path, Text, Transforms } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import { CSSProperties } from 'react'
import EditorHelpers from './EditorHelpers'

type CellCoordinates = { row: number; cell: number }

const TableHelpers = {
  editCellStyleByCoordinates(
    editor: ReactEditor,
    style: CSSProperties,
    coordinates: CellCoordinates
  ) {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return

    Transforms.setNodes(
      editor,
      (node) => {
        return {
          style: {
            ...node.style,
            ...style
          }
        }
      },
      {
        at: path
      }
    )
  },

  editCellBorderByCoordinates(
    editor: ReactEditor,
    borderPosition: 'left' | 'top' | 'bottom' | 'right',
    params: {
      width?: string | null
      color?: string | null
      style?: string | null
    },
    coordinates: CellCoordinates
  ) {
    const surroundingCells = TableHelpers.getSurroundingCellsPaths(
      editor,
      coordinates
    )

    // If we're editing bottom border and this ain't last row, we must restyle top border of bottom cell
    if (
      borderPosition === 'bottom' &&
      !TableHelpers.isLastRow(editor, coordinates)
    ) {
      const bottomCoordinates = {
        ...coordinates,
        row: coordinates.row + 1
      }
      TableHelpers.editCellBorderByCoordinates(
        editor,
        'top',
        params,
        bottomCoordinates
      )
      return
    }

    // If we're editing right border and this ain't last cell, we must restyle left border of right cell
    if (
      borderPosition === 'right' &&
      !TableHelpers.isLastCell(editor, coordinates)
    ) {
      const rightCoordinates = {
        ...coordinates,
        cell: coordinates.cell + 1
      }
      TableHelpers.editCellBorderByCoordinates(
        editor,
        'left',
        params,
        rightCoordinates
      )
      return
    }

    // Removing top cell border for bottom cell if we're editing bottom border
    if (borderPosition === 'bottom' && surroundingCells.bottom !== null) {
      TableHelpers.removeCellBorder(editor, 'top', surroundingCells.bottom)
    }

    // Removing bottom cell border for top cell if we're editing top border
    if (borderPosition === 'top' && surroundingCells.top !== null) {
      TableHelpers.removeCellBorder(editor, 'bottom', surroundingCells.top)
    }

    // Removing left cell border for right cell if we're editing right border
    if (borderPosition === 'right' && surroundingCells.right !== null) {
      TableHelpers.removeCellBorder(editor, 'left', surroundingCells.right)
    }

    // Removing right cell border for left cell if we're editing left border
    if (borderPosition === 'left' && surroundingCells.left !== null) {
      TableHelpers.removeCellBorder(editor, 'right', surroundingCells.left)
    }

    /**
     * Editing border
     */
    const camelCasePosition = borderPosition.replace(
      /^[a-z]/i,
      (match) => `${match.toUpperCase()}`
    )
    const style: CSSProperties = {}

    const cssProps: Record<
      string,
      'borderWidth' | 'borderColor' | 'borderStyle'
    > = {
      borderWidth: `border${camelCasePosition}Width` as 'borderWidth',
      borderColor: `border${camelCasePosition}Color` as 'borderColor',
      borderStyle: `border${camelCasePosition}Style` as 'borderStyle'
    }

    // Width style
    if (typeof params.width === 'string') {
      style[cssProps.borderWidth] = params.width
    } else if (params.width === null) {
      style[cssProps.borderWidth] = undefined
    }

    // Color style
    if (typeof params.color === 'string') {
      style[cssProps.borderColor] = params.color
    } else if (params.color === null) {
      style[cssProps.borderColor] = undefined
    }

    // borderStyle style
    if (typeof params.style === 'string') {
      style[cssProps.borderStyle] = params.style
    } else if (params.style === null) {
      style[cssProps.border] = undefined
    }

    TableHelpers.editCellStyleByCoordinates(editor, style, coordinates)
  },

  editCurrentCellBorder(
    editor: ReactEditor,
    borderPosition: 'left' | 'top' | 'bottom' | 'right',
    style: { width?: string; color?: string; style?: string }
  ) {
    if (!EditorHelpers.isMultipleNodeSelection(editor)) {
      const coordinates = TableHelpers.getCurrentCellCoordinates(editor)
      if (coordinates === null) {
        return
      }
      TableHelpers.editCellBorderByCoordinates(
        editor,
        borderPosition,
        style,
        coordinates
      )
      return
    }
    const nodes = EditorHelpers.getAllNodesInSelection(editor, 'table-cell')
    for (let index = 0; index < nodes.length; index += 1) {
      const { path } = nodes[index]
      const coordinates = TableHelpers.getCellCoordinatesByPath(editor, path)
      if (coordinates === null) return
      TableHelpers.editCellBorderByCoordinates(
        editor,
        borderPosition,
        style,
        coordinates
      )
    }
  },

  getCellBorderStyle(
    editor: ReactEditor,
    borderPosition: 'left' | 'top' | 'bottom' | 'right',
    styleAttribute: 'color' | 'style' | 'width',
    coordinates: CellCoordinates
  ): string | undefined {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return undefined
    const node = EditorHelpers.getNodeByPath(editor, path)
    if (node === null) return undefined

    // If we're editing bottom border and this ain't last row, we must return top border of bottom cell
    if (
      borderPosition === 'bottom' &&
      !TableHelpers.isLastRow(editor, coordinates)
    ) {
      const bottomCoordinates = {
        ...coordinates,
        row: coordinates.row + 1
      }
      return TableHelpers.getCellBorderStyle(
        editor,
        'top',
        styleAttribute,
        bottomCoordinates
      )
    }

    // If we're editing right border and this ain't last cell, we must return left border of right cell
    if (
      borderPosition === 'right' &&
      !TableHelpers.isLastCell(editor, coordinates)
    ) {
      const rightCoordinates = {
        ...coordinates,
        cell: coordinates.cell + 1
      }
      return TableHelpers.getCellBorderStyle(
        editor,
        'left',
        styleAttribute,
        rightCoordinates
      )
    }

    const camelCaseBorder = borderPosition.replace(
      /^[a-z]/i,
      (match) => `${match.toUpperCase()}`
    )
    const camelCaseAttriute = styleAttribute.replace(
      /^[a-z]/i,
      (match) => `${match.toUpperCase()}`
    )
    if (typeof node.style === 'object')
      return node.style[`border${camelCaseBorder}${camelCaseAttriute}`]

    return undefined
  },

  getCurrentCellBorderStyle(
    editor: ReactEditor,
    borderPosition: 'left' | 'top' | 'bottom' | 'right',
    styleAttribute: 'color' | 'style' | 'width'
  ) {
    const coordinates = TableHelpers.getCurrentCellCoordinates(editor)
    if (coordinates === null) return null
    return TableHelpers.getCellBorderStyle(
      editor,
      borderPosition,
      styleAttribute,
      coordinates
    )
  },

  removeCellBorder(
    editor: ReactEditor,
    borderPosition: 'left' | 'top' | 'bottom' | 'right',
    path: Path
  ) {
    const node = EditorHelpers.getNodeByPath(editor, path)
    if (node === null) return
    const camelCasePosition = borderPosition.replace(
      /^[a-z]/i,
      (match) => `${match.toUpperCase()}`
    )
    const props = {
      style: {
        ...node.style,
        [`border${camelCasePosition}Width`]: undefined,
        [`border${camelCasePosition}Style`]: undefined,
        [`border${camelCasePosition}Color`]: undefined
      }
    }

    Transforms.setNodes(editor, props, {
      at: path
    })
  },

  isCell(editor: ReactEditor) {
    if (editor.selection === null) return false
    let isCell = false
    editor.selection.anchor.path.reduce((previousNode: Node, currentPath) => {
      if (Node.isNode(previousNode)) {
        const currentNode = previousNode.children[currentPath]
        if (Text.isText(currentNode)) return previousNode
        // eslint-disable-next-line no-debugger
        if (currentNode === undefined) debugger
        if (currentNode.type === 'table-cell') isCell = true
        return currentNode
      }
      return editor
    }, editor)

    return isCell
  },

  isFirstRow(editor: ReactEditor, coordinates: CellCoordinates) {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return false
    const [lastRowNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-row',
      path
    )
    const [lastTableBodyNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-body',
      path
    )
    const [lastTableNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table',
      path
    )
    if (lastRowNode === null) return false
    let parentChilds = []
    if (
      lastTableBodyNode !== null &&
      lastTableBodyNode.children.includes(lastRowNode)
    ) {
      parentChilds = lastTableBodyNode.children
    } else if (
      lastTableNode !== null &&
      lastTableNode.children.includes(lastRowNode)
    ) {
      parentChilds = lastTableNode.children
    }
    if (parentChilds.indexOf(lastRowNode) === 0) return true
    return false
  },

  isLastRow(editor: ReactEditor, coordinates: CellCoordinates) {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return false
    const [lastRowNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-row',
      path
    )
    const [lastTableBodyNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-body',
      path
    )
    const [lastTableNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table',
      path
    )
    if (lastRowNode === null) return false
    let parentChilds = []
    if (
      lastTableBodyNode !== null &&
      lastTableBodyNode.children.includes(lastRowNode)
    ) {
      parentChilds = lastTableBodyNode.children
    } else if (
      lastTableNode !== null &&
      lastTableNode.children.includes(lastRowNode)
    ) {
      parentChilds = lastTableNode.children
    }
    if (parentChilds.indexOf(lastRowNode) === parentChilds.length - 1)
      return true
    return false
  },

  isFirstCell(editor: ReactEditor, coordinates: CellCoordinates) {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return false
    const [lastCellNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-cell',
      path
    )
    const [lastRowNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-row',
      path
    )
    if (lastCellNode === null) return false
    if (lastRowNode !== null && lastRowNode.children.includes(lastCellNode)) {
      if (lastRowNode.children.indexOf(lastCellNode) === 0) return true
    }
    return false
  },

  isLastCell(editor: ReactEditor, coordinates: CellCoordinates) {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return false
    const [lastCellNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-cell',
      path
    )
    const [lastRowNode] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-row',
      path
    )
    if (lastCellNode === null) return false
    if (lastRowNode !== null && lastRowNode.children.includes(lastCellNode)) {
      if (
        lastRowNode.children.indexOf(lastCellNode) ===
        lastRowNode.children.length - 1
      )
        return true
    }
    return false
  },

  getSurroundingCellsNodes(editor: ReactEditor, coordinates: CellCoordinates) {
    const surroundingCells: {
      left: null | Node
      right: null | Node
      top: null | Node
      bottom: null | Node
    } = {
      left: null,
      right: null,
      top: null,
      bottom: null
    }
    if (coordinates === null) return surroundingCells
    const { row, cell } = coordinates

    // Left
    if (!TableHelpers.isFirstCell(editor, coordinates)) {
      surroundingCells.left = TableHelpers.getCellNodeByCoordinates(editor, {
        row,
        cell: cell - 1
      })
    }

    // Right
    if (!TableHelpers.isLastCell(editor, coordinates)) {
      surroundingCells.right = TableHelpers.getCellNodeByCoordinates(editor, {
        row,
        cell: cell + 1
      })
    }

    // Top
    if (!TableHelpers.isFirstRow(editor, coordinates)) {
      surroundingCells.top = TableHelpers.getCellNodeByCoordinates(editor, {
        row: row - 1,
        cell
      })
    }

    // Botton
    if (!TableHelpers.isLastRow(editor, coordinates)) {
      surroundingCells.bottom = TableHelpers.getCellNodeByCoordinates(editor, {
        row: row + 1,
        cell
      })
    }

    return surroundingCells
  },

  getSurroundingCellsPaths(editor: ReactEditor, coordinates: CellCoordinates) {
    const surroundingCells: {
      left: null | Path
      right: null | Path
      top: null | Path
      bottom: null | Path
    } = {
      left: null,
      right: null,
      top: null,
      bottom: null
    }
    if (coordinates === null) return surroundingCells
    const { row, cell } = coordinates

    // Left
    if (!TableHelpers.isFirstCell(editor, coordinates)) {
      surroundingCells.left = TableHelpers.getCurrentTableCellPathByCoordinates(
        editor,
        { row, cell: cell - 1 }
      )
    }

    // Right
    if (!TableHelpers.isLastCell(editor, coordinates)) {
      surroundingCells.right = TableHelpers.getCurrentTableCellPathByCoordinates(
        editor,
        { row, cell: cell + 1 }
      )
    }

    // Top
    if (!TableHelpers.isFirstRow(editor, coordinates)) {
      surroundingCells.top = TableHelpers.getCurrentTableCellPathByCoordinates(
        editor,
        { row: row - 1, cell }
      )
    }

    // Botton
    if (!TableHelpers.isLastRow(editor, coordinates)) {
      surroundingCells.bottom = TableHelpers.getCurrentTableCellPathByCoordinates(
        editor,
        { row: row + 1, cell }
      )
    }

    return surroundingCells
  },

  getCellCoordinatesByPath(editor: ReactEditor, path: Path) {
    const [lastTableBodyNode, tableBodyPath] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-body',
      path
    )
    const [lastTableNode, tablePath] = EditorHelpers.getLastNodeOfType(
      editor,
      'table',
      path
    )
    const parent = lastTableBodyNode || lastTableNode
    const parentPath = parent === lastTableBodyNode ? tableBodyPath : tablePath
    if (parent === null || parentPath === null) return null
    const [rowIndex, cellIndex] = path.slice(
      parentPath.length,
      parentPath.length + 2
    )
    return {
      row: rowIndex + 1,
      cell: cellIndex + 1
    }
  },

  getCurrentCellCoordinates(editor: ReactEditor) {
    const { selection } = editor
    if (selection === null) return null
    const { anchor } = selection
    const { path } = anchor

    const [lastTableBodyNode, tableBodyPath] = EditorHelpers.getLastNodeOfType(
      editor,
      'table-body',
      path
    )
    const [lastTableNode, tablePath] = EditorHelpers.getLastNodeOfType(
      editor,
      'table',
      path
    )
    const parent = lastTableBodyNode || lastTableNode
    const parentPath = parent === lastTableBodyNode ? tableBodyPath : tablePath
    if (parent === null || parentPath === null) return null
    const [rowIndex, cellIndex] = path.slice(
      parentPath.length,
      parentPath.length + 2
    )
    return {
      row: rowIndex + 1,
      cell: cellIndex + 1
    }
  },

  getCurrentTableCellPathByCoordinates(
    editor: ReactEditor,
    coordinates: { row: number; cell: number }
  ) {
    const { row, cell } = coordinates
    const [
      lastTableBodyNode,
      tableBodyPath
    ] = EditorHelpers.getCurrentLastNodeOfType(editor, 'table-body')
    const [lastTableNode, tablePath] = EditorHelpers.getCurrentLastNodeOfType(
      editor,
      'table'
    )
    const parent = lastTableBodyNode || lastTableNode
    const parentPath = parent === lastTableBodyNode ? tableBodyPath : tablePath
    if (parent === null || parentPath === null) return null
    return [...parentPath, row - 1, cell - 1]
  },

  getCellNodeByCoordinates(
    editor: ReactEditor,
    coordinates: { row: number; cell: number }
  ) {
    const path = TableHelpers.getCurrentTableCellPathByCoordinates(
      editor,
      coordinates
    )
    if (path === null) return null
    return EditorHelpers.getNodeByPath(editor, path)
  }
}

export default TableHelpers
