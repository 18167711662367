import React from 'react'
import { I18n } from 'aws-amplify'
import createStore from '../../../store/editor/createStore'
import CheckboxField from '../../Editor/CheckboxField'

const dictionary = {
  fr: {
    disable_auto_default:
      'Désactiver la sélection automatique des cases par défaut',
    is_two_steps: 'Le questionnaire est rempli en deux étapes',
    auto_default_head: 'Remplissage automatique',
    two_steps_head: 'Questionnaire en deux étapes'
  },
  en: {
    disable_auto_default: 'Disable automatic box checking by default',
    is_two_steps: 'The wizard is filled in two steps',
    auto_default_head: 'Auto reply',
    two_steps_head: 'Two steps wizard'
  }
}

I18n.putVocabularies(dictionary)

type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

type GeneralParamsModalStateType = {
  disableAutoDefault: boolean
  [key: string]: any
}

class GeneralParamsModal extends React.Component<
  ParamsModalTabPropsType,
  GeneralParamsModalStateType
> {
  constructor(props: ParamsModalTabPropsType) {
    super(props)

    const { getParams } = props

    this.state = {
      disableAutoDefault:
        createStore.document.customization?.disableAutoDefault === true,
      'meta.isTwoSteps':
        createStore.document.customization?.meta?.isTwoSteps === true,
      ...getParams()
    }
  }

  updateParams = (newParams: Record<string, any>) => {
    const { setParams } = this.props

    this.setState({ ...this.state, ...newParams })
    setParams(newParams)
  }

  setFieldValue = (name: string, value: string | number | boolean) => {
    const newParams = {
      ...this.state,
      [name]: value
    }
    this.updateParams({
      ...newParams
    })
  }

  render() {
    return (
      <div>
        <div className="pm-block">
          <h3>{I18n.get('auto_default_head')}</h3>
          <CheckboxField
            name="disableAutoDefault"
            setFieldValue={this.setFieldValue}
            checked={this.state.disableAutoDefault || false}
            label={I18n.get('disable_auto_default')}
          />
        </div>
        <div className="pm-block">
          <h3>{I18n.get('two_steps_head')}</h3>
          <CheckboxField
            name="meta.isTwoSteps"
            setFieldValue={this.setFieldValue}
            checked={this.state['meta.isTwoSteps'] || false}
            label={I18n.get('is_two_steps')}
          />
        </div>
      </div>
    )
  }
}

export default GeneralParamsModal
