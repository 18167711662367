const dictionary = {
  fr: {
    General: 'Générales',
    Edition: 'Edition',
    Contracts: 'Contrats',
    'Contract name': 'Nom du contrat',
    Permalink: 'Permalink',
    'Text above the title [Create your ...]':
      'Text au dessus du titre [Créez votre...]',
    '"Create" button [Fill out this template (Word, PDF)]':
      'Bouton "Créer" [Remplir ce modèle (Word, PDF)]',
    Subtitle: 'Sous titre',
    'Checkout Title': 'Titre Checkout',
    Category: 'Catégorie',
    Type: 'Type',
    Language: 'Langue',
    'Version in production': 'Version en production',
    Order: 'Order',
    'Do not show "- LegalPlace" in title':
      'Ne pas afficher "- LegalPlace" dans le titre',
    'View subscription': "Afficher l'abonnement",
    'Do not display in the search bar of the home page':
      "Ne pas afficher dans la barre de recherche de la page d'acceuil",
    'Do not show in the documents page':
      'Ne pas afficher dans la page des documents',
    'Do not show in category pages':
      'Ne pas afficher dans les pages catégories',
    'Ask search engines not to index this page':
      'Demander aux moteurs de recherche de ne pas indexer cette page',
    'How it works ?': 'Comment ça marche ?',
    'first step icon in (How does it work?) [create]':
      'icone première étape dans (Comment ça marche?) [create]',
    'first step in (How does it work?) [Create your contract]':
      'première étape dans (Comment ça marche?) [Créez votre contrat]',
    'second step icon in (How does it work?) [save]':
      'icône deuxième étape dans (Comment ça marche?) [save]',
    'second step in (How does it work?) [Register and print]':
      'deuxième étape dans (Comment ça marche?) [Enregistez et imprimez]',
    'third step icon in (How does it work?) [sign]':
      'icône troisième étape dans (Comment ça marche?) [sign]',
    'third step in (How does it work?) [Create your contract]':
      'troisième étape dans (Comment ça marche?) [Créez votre contrat]',
    SEO: 'SEO',
    'Title of the HTML page': 'Titre de la page HTML',
    'Description (META)': 'Description (META)',
    'Title about': 'Titre à propos',
    'Keywords (META)': 'Mots clées (META)',
    Upsell: 'Upsell',
    'Documents to put in upsell in the download page (permalinks separated by a comma)':
      'Documents à mettre en upsell dans la page de téléchargement (permaliens séparées par une virgule)',
    'A/B Testing Versions': 'A/B Testing des versions',
    Version: 'Version',
    'Percentage of visitors': 'Pourcentage des visiteurs',
    'A/B Testing CTAs': 'A/B Testing des CTA',
    'Fixed costs per purchase': 'Coûts fixes par achat',
    Cost: 'Coût',
    Overview: 'Aperçu',
    'Preview title': "Titre de l'aperçu",
    'Email section': 'Section email',
    'Email section title': 'Titre section email',
    'Email input label': 'Label input email',
    'Email section warning': 'Warning section email',
    'CTA section email': 'CTA section email',
    'Backup section': 'Section sauvegarde',
    'Title section': 'Titre section',
    'Label input': 'Label input',
    'Top Text': 'Text du haut',
    'Information label (below the field)':
      "Label d'information (en dessous du champ)",
    'Notification of success': 'Notification de succès',
    'Message of success': 'Message de succès',
    'Emails Sendgrid': 'Emails Sendgrid',
    'Do not send the welcome email': "Ne pas envoyer l'email de bienvenue",
    'Do not send reminders': 'Ne pas envoyer les relances',
    'Do not send documents': 'Ne pas envoyer les documents',
    Checkout: 'Checkout',
    'First pack name': 'Nom premier pack',
    'Second pack name': 'Nom deuxième pack',
    'First pack content': 'Contenu premier pack',
    'Second pack content': 'Contenu deuxième pack',
    'Single pack': 'Pack unique',
    'Disable additional options': 'Désactiver les options complémentaires',
    'Disable billing block': 'Désactiver le bloc facturation',
    'Disable the content block of the command':
      'Désactiver le bloc contenu de la commande',
    'Disable the second payment CTA': 'Désactiver le second CTA de paiement',
    'CTA Payment checkout': 'CTA Paiement checkout',
    'CTA Payment popup': 'CTA Paiement popup',
    'Custom block': 'Bloc personnalisé',
    'Enable customizable checkout block':
      'Activer le bloc checkout personnalisable',
    '+ Variation': '+ Variation',
    'Post payment': 'Post paiement',
    'Post different payment depending on the chosen pack':
      'Post paiement différent selon le pack choisi',
    'Auto-recovery bar': 'Barre de reprise automatique',
    'Title of the bar': 'Titre de la barre',
    'Text of the bar': 'Texte de la barre',
    'Button text': 'Text du boutton',
    'EXAMPLE: Your was recorded at the moment':
      "EXAMPLE: Votre a été enregistré à l'linstant",
    'EXAMPLE: Resume my confidentiality agreement':
      'EXAMPLE: Reprendre mon accord de confidentialité',
    'Presentation of the contract': 'Présentation du contrat',
    Save: 'Enregistrer'
  },
  en: {
    General: 'General',
    Edition: 'Edition',
    Contracts: 'Contracts ',
    'Contract name': 'NOM DU CONTRAT ',
    Permalink: 'Permalink',
    'Text above the title [Create your ...]':
      'Text above the title [Create your ...]',
    '"Create" button [Fill out this template (Word, PDF)]':
      '"Create" button [Fill out this template (Word, PDF)]',
    Subtitle: 'Subtitle',
    'Checkout Title': 'Checkout Title',
    Category: 'Category',
    Type: 'Type',
    Language: 'Language',
    'Version in production': 'Version in production',
    Order: 'Order',
    'Do not show "- LegalPlace" in title':
      'Do not show "- LegalPlace" in title',
    'View subscription': 'View subscription',
    'Do not display in the search bar of the home page':
      'Do not display in the search bar of the home page',
    'Do not show in the documents page': 'Do not show in the documents page',
    'Do not show in category pages': 'Do not show in category pages',
    'Ask search engines not to index this page':
      'Ask search engines not to index this page',
    'How it works ?': 'How it works ?',
    'first step icon in (How does it work?) [create]':
      'first step icon in (How does it work?) [create]',
    'first step in (How does it work?) [Create your contract]':
      'first step in (How does it work?) [Create your contract]',
    'second step icon in (How does it work?) [save]':
      'second step icon in (How does it work?) [save]',
    'second step in (How does it work?) [Register and print]':
      'second step in (How does it work?) [Register and print]',
    'third step icon in (How does it work?) [sign]':
      'third step icon in (How does it work?) [sign]',
    'third step in (How does it work?) [Create your contract]':
      'third step in (How does it work?) [Create your contract]',
    SEO: 'SEO',
    'Title of the HTML page': 'Title of the HTML page',
    'Description (META)': 'Description (META)',
    'Title about': 'Title about',
    'Keywords (META)': 'Keywords (META)',
    Upsell: 'Upsell',
    'Documents to put in upsell in the download page (permalinks separated by a comma)':
      'Documents to put in upsell in the download page (permalinks separated by a comma)',
    'A/B Testing Versions': 'A/B Testing Versions',
    Version: 'Version',
    'Percentage of visitors': 'Percentage of visitors',
    'A/B Testing CTAs': 'A/B Testing CTAs',
    'Fixed costs per purchase': 'Fixed costs per purchase',
    Cost: 'Cost',
    Overview: 'Overview',
    'Preview title': 'Preview title',
    'Email section': 'Email section',
    'Email section title': 'Email section title',
    'Email input label': 'Email input label',
    'Email section warning': 'Email section warning',
    'CTA section email': 'CTA section email',
    'Backup section': 'Backup section',
    'Title section': 'Title section',
    'Label input': 'Label input',
    'Top Text': 'Top Text',
    'Information label (below the field)':
      'Information label (below the field)',
    'Notification of success': 'Notification of success',
    'Message of success': 'Message of success',
    'Emails Sendgrid': 'Emails Sendgrid',
    'Do not send the welcome email': 'Do not send the welcome email',
    'Do not send reminders': 'Do not send reminders',
    'Do not send documents': 'Do not send documents',
    Checkout: 'Checkout',
    'First pack name': 'First pack name',
    'Second pack name': 'Second pack name',
    'First pack content': 'First pack content',
    'Second pack content': 'Second pack content',
    'Single pack': 'Single pack',
    'Disable additional options': 'Disable additional options',
    'Disable billing block': 'Disable billing block',
    'Disable the content block of the command':
      'Disable the content block of the command',
    'Disable the second payment CTA': 'Disable the second payment CTA',
    'CTA Payment checkout': 'CTA Payment checkout',
    'CTA Payment popup': 'CTA Payment popup',
    'Custom block': 'Custom block',
    'Enable customizable checkout block': 'Enable customizable checkout block',
    '+ Variation': '+ Variation',
    'Post payment': 'Post payment',
    'Post different payment depending on the chosen pack':
      'Post different payment depending on the chosen pack',
    'Auto-recovery bar': 'Auto-recovery bar',
    'Title of the bar': 'Title of the bar',
    'Text of the bar': 'Title of the bar',
    'Button text': 'Button text',
    'EXAMPLE: Your was recorded at the moment':
      'EXAMPLE: Your was recorded at the moment',
    'Presentation of the contract': 'Presentation of the contract',
    Save: 'Save'
  }
}

export default dictionary
