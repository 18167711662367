import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    formula: 'Formule'
  },
  en: {
    formula: 'Formula'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable's eval formula
 */
export const EvalFormula: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { formula } = state.variable.eval || { formula: '' }
  return (
    <div className="label-holder">
      <Input
        value={formula}
        name="eval_formula"
        setFieldValue={(name: string, value: string) => {
          state.updateEvalFormula(value)
        }}
        label={I18n.get('formula')}
      />
    </div>
  )
}
