import { I18n } from 'aws-amplify'
import { ErrorMessage, Formik } from 'formik'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component, ReactNode } from 'react'
import { Link, match, RouteComponentProps } from 'react-router-dom'
import '../assets/scss/main.scss'
import FormField from '../components/FormField/FormField'
import { IAuthStore } from '../store/authStore'
import { catchAction } from '../store/editor/catchActionsDecorator'

interface IRouteParams {
  token: string
}

interface IResetPageProps extends RouteComponentProps {
  authStore: IAuthStore
  match: match<IRouteParams>
}

export interface IResetValues {
  newPassword?: string
  token?: string
  server_error?: string
}

const columns = {
  en: {
    reset_title: 'Set new password',
    reset_text: 'Hi, please enter your new password',
    newPassword: 'New Password',
    reset_btn: 'Send',
    login: 'Login'
  },
  fr: {
    reset_title: 'Set new password',
    reset_text: 'Hi, please enter your new password',
    newPassword: 'New Password',
    reset_btn: 'Send',
    login: 'Login'
  }
}

I18n.putVocabularies(columns)

@inject('authStore')
@observer
class ResetPage extends Component<IResetPageProps> {
  private initialValues: IResetValues = {
    newPassword: '',
    token: '',
    server_error: ''
  }

  @catchAction
  @action
  UNSAFE_componentWillMount() {
    this.initialValues.token = this.props.match.params.token
  }

  private validate = (values: IResetValues) => {
    const errors: IResetValues = {}
    if (!values.newPassword) {
      errors.newPassword = 'Required'
    }
    return errors
  }

  public render(): ReactNode {
    const { reset } = this.props.authStore
    return (
      <div className="content resetContainer">
        <div className="ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl text-center">{I18n.get('reset_title')}</h3>
          </div>
        </div>
        <div className="resetFormContainer">
          <div className="grid-container-form">
            <div className="grid-item">
              <Formik
                initialValues={this.initialValues}
                validate={this.validate}
                onSubmit={reset}
              >
                {({ handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <h4 className="form-ttl text-center">
                      {I18n.get('reset_text')}
                    </h4>
                    <FormField
                      label={I18n.get('newPassword')}
                      type="password"
                      name="newPassword"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <ErrorMessage name="server_error" />
                    <div className="form-footer">
                      <button
                        className="btn btn-active btn-send btn-double-padding-side ma"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {I18n.get('reset_btn')}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <Link className="footer-link" to="/login">
            {I18n.get('login')}
          </Link>
        </div>
      </div>
    )
  }
}

export default ResetPage
