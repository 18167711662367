import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { match, RouteComponentProps } from 'react-router'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { action, observable } from 'mobx'
import { IDialogStore } from '../../../store/dialogStore'
import Confirm from '../../../components/Confirm/Confirm'
import { IDataStore } from '../../../store/dataStore'
import { go } from '../../../utils/history'
import { DELETE, DOWNLOAD, EDITED } from '../../../components/Icons/icons'
import Icon from '../../../components/Icons/Icon'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

interface IRouteParams {
  id: string
}

interface IFileDetailPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
  dialogStore?: IDialogStore | any
  match: match<IRouteParams>
}

const columns = {
  en: {
    info_back: 'My Files',
    info_information: 'Information',
    info_name: 'File name',
    info_tags: 'Tags',
    info_id: 'ID',
    info_type: 'Product type',
    info_model: 'Model',
    info_category: 'Category',
    info_created: 'Created',
    info_modified: 'Last modified',
    info_download_file: 'Download documents',
    info_edit: 'Edit questionnaire',
    info_remove: 'Remove file',
    info_delete_confirm: 'Are you sure?'
  },
  fr: {
    info_back: 'My Files',
    info_information: 'Information',
    info_name: 'File name',
    info_tags: 'Tags',
    info_id: 'ID',
    info_type: 'Product type',
    info_model: 'Model',
    info_category: 'Category',
    info_created: 'Created',
    info_modified: 'Last modified',
    info_download_file: 'Download documents',
    info_edit: 'Edit questionnaire',
    info_remove: 'Remove file',
    info_delete_confirm: 'Are you sure?'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'dialogStore')
@observer
class FileDetailPage extends Component<IFileDetailPageProps> {
  @observable public file: any = {
    id: '',
    name: '',
    type: '',
    model: '',
    created: '',
    modified: '',
    email: '',
    tags: [],
    status: ''
  }

  @catchAction
  @action
  public UNSAFE_componentWillMount() {
    const { dataStore, match: currentMatch } = this.props
    const file = dataStore.myFile(currentMatch.params.id)
    if (!file) go('/my-files')
    this.file = file
  }

  public handleDelete = () => {
    const { openDialog, closeDialog } = this.props.dialogStore
    openDialog(
      <Confirm
        question={I18n.get('info_delete_confirm')}
        handleCancel={closeDialog}
        handleDelete={this.deleteItem(this.file)}
      />
    )
  }

  public deleteItem = (item: any) => () => {
    this.props.dialogStore.closeDialog()
    console.log(item)
  }

  public render(): ReactNode {
    const { name, tags = [], id, type, model, created, modified } = this.file
    return (
      <div className="content fileDetailPage">
        <div className="bread-crumbs-container">
          <div className="padding-container bread-crumbs">
            <Link className="go-back-ttl secondary" to="/my-files">
              {I18n.get('info_back')}
            </Link>
          </div>
        </div>
        <div className="detail-container">
          <div className="ttl-padding-container">
            <div className="ttl-container">
              <h3 className="ttl">{name}</h3>
            </div>
          </div>

          <div className="grid-container">
            <div className="grid-item detail-table-container">
              <h4 className="table-ttl">{I18n.get('info_information')}</h4>
              <table className="detail-table">
                <tbody>
                  <tr>
                    <td>{I18n.get('info_name')}</td>
                    <td>
                      <div className="flex-wrapper">
                        <span className="text">{name}</span>
                        <Icon iconClass="iconEdited" icon={EDITED} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_tags')}</td>
                    <td>
                      <div className="flex-wrapper">
                        <span className="text">
                          {tags.map((i: any) => i.name).join(', ')}
                        </span>
                        <Icon iconClass="iconEdited" icon={EDITED} />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_id')}</td>
                    <td>{id}</td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_type')}</td>
                    <td>{type}</td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_model')}</td>
                    <td>{model}</td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_category')}</td>
                    <td>Hiring Management</td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_created')}</td>
                    <td>{created}</td>
                  </tr>
                  <tr>
                    <td>{I18n.get('info_modified')}</td>
                    <td>{modified}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="grid-item detail-links-container">
              <div className="links-list-holder">
                <h4 className="links-ttl">{I18n.get('info_download_file')}</h4>
                <ul className="links-list">
                  <li>
                    <a href="/" download>
                      <Icon iconClass="iconDownload" icon={DOWNLOAD} />
                      <span className="text">Document four</span>
                    </a>
                  </li>
                  <li>
                    <a href="/" download>
                      <Icon iconClass="iconDownload" icon={DOWNLOAD} />
                      <span className="text">Document four</span>
                    </a>
                  </li>
                  <li>
                    <a href="/" download>
                      <Icon iconClass="iconDownload" icon={DOWNLOAD} />
                      <span className="text">Document four</span>
                    </a>
                  </li>
                  <li>
                    <a href="/" download>
                      <Icon iconClass="iconDownload" icon={DOWNLOAD} />
                      <span className="text">Document four</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="btn-wrapper">
                <button type="button" className="btn btn-edit">
                  <Icon iconClass="iconEdited" icon={EDITED} />
                  <span className="text">{I18n.get('info_edit')}</span>
                </button>
                <button
                  type="button"
                  className="btn btn-delete"
                  onClick={this.handleDelete}
                >
                  <Icon iconClass="iconDelete" icon={DELETE} />
                  <span className="text">{I18n.get('info_remove')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FileDetailPage
