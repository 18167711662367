import React from 'react'

const ParentTree: React.FC<{
  currentIndex: number
  tree: { type: string; id: number; label: string }[]
}> = ({ currentIndex, tree }) => {
  const currentNode = tree[currentIndex]

  const line = (
    <li>
      <div className="level">
        <span className="id">#{currentNode.id}</span>
        <span className="label">{currentNode.label}</span>
      </div>
      {currentIndex < tree.length - 1 && (
        <ul>
          <ParentTree currentIndex={currentIndex + 1} tree={tree} />
        </ul>
      )}
    </li>
  )
  if (currentIndex === 0)
    return (
      <div className="parentTree">
        <ul>{line}</ul>
      </div>
    )
  return line
}

export default ParentTree
