import React from 'react'

const SpanElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <span style={props.element.style} {...props.attributes}>
      {props.children}
    </span>
  )
}

export default SpanElement
