import React, { Component } from 'react'
import { observer } from 'mobx-react'
import './style.scss'
import Dialog from '@material-ui/core/Dialog'
import { action } from 'mobx'
import { I18n } from 'aws-amplify'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'publish-title':
      'Êtes-vous sûr(e) de vouloir publier ce modèle ? Ce modèle ne sera ensuite plus modifiable',
    'cancel publish': 'Annuler',
    'save publish': 'Publier'
  },
  en: {
    'publish-title':
      'Are you sure you want to publish this model? The model will not be editable anymore.',
    'cancel publish': 'Cancel',
    'save publish': 'Publish'
  }
}

I18n.putVocabularies(dictionary)

interface IPublishModalProps {
  open: boolean
  toggleModal(name: string, value: boolean): void
  publishModelData(): void
}

@observer
class PublishModal extends Component<IPublishModalProps> {
  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    toggleModal('publishIsOpen', open)
  }

  @catchAction
  @action
  handlePublish = () => {
    this.props.publishModelData()
    this.toggleModal(false)
  }

  render() {
    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="publish-modal-holder"
      >
        <div className="publish-modal">
          <h1>{I18n.get('publish-title')}</h1>
          <div className="btn-holder">
            <button
              type="button"
              className="secondary"
              onClick={() => this.toggleModal(false)}
            >
              {I18n.get('cancel publish')}
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => this.handlePublish()}
            >
              {I18n.get('save publish')}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default PublishModal
