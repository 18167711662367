import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { ErrorMessage, Formik } from 'formik'
import { I18n } from 'aws-amplify'
import { action } from 'mobx'
import FormField from '../../../components/FormField/FormField'
import SelectField, {
  IOption
} from '../../../components/SelectField/SelectField'
import { IUserStore } from '../../../store/userStore'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

interface INewUserFormProps {
  form?: INewUserFormValues
  userStore?: IUserStore | any
  onSubmit(): void
  units?: any
}

export interface INewUserFormValues {
  id?: string | any
  email?: string | any
  first_name?: string | any
  last_name?: string | any
  role?: string | any
  unit_id?: string | any
  unit?: any
  organization?: any
  server_error?: string
}

const columns = {
  en: {
    new_user_title: 'Add New User',
    update_user_title: 'Update User',
    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    role: 'Role',
    sub_organization: 'Sub-organization',
    add_user_btn: 'Add user',
    update_user_btn: 'Update user',
    super: 'Super',
    admin: 'Admin',
    manager: 'Manager',
    user: 'User',
    guest: 'Guest',
    lead: 'Lead',
    developer: 'Developer',
    QA: 'QA',
    designer: 'Designer'
  },
  fr: {
    new_user_title: 'Ajouter un utilisateur',
    update_user_title: 'Modifier un utilisateur',
    first_name: 'Prénom',
    last_name: 'Nom',
    email: 'Email',
    role: 'Rôle',
    sub_organization: 'Equipe',
    add_user_btn: 'Ajouter',
    update_user_btn: 'Sauvegarder',
    super: 'Super',
    admin: 'Admin',
    manager: 'Manager',
    user: 'User',
    guest: 'Guest',
    lead: 'Lead',
    developer: 'Developer',
    QA: 'QA',
    designer: 'Designer'
  }
}

I18n.putVocabularies(columns)

@inject('userStore')
@observer
class NewUserForm extends Component<INewUserFormProps> {
  private initialValues: INewUserFormValues = {}

  private roles: IOption[] = [
    // {value: 'SUPER', label: I18n.get('super')},
    { value: 'ADMIN', label: I18n.get('admin') },
    { value: 'MANAGER', label: I18n.get('manager') },
    { value: 'USER', label: I18n.get('user') },
    { value: 'GUEST', label: I18n.get('guest') }
  ]

  private units: IOption[] = [
    { value: 'Lead', label: I18n.get('lead') },
    { value: 'Developer', label: I18n.get('developer') },
    { value: 'QA', label: I18n.get('QA') },
    { value: 'Designer', label: I18n.get('designer') }
  ]

  @catchAction
  @action
  UNSAFE_componentWillMount() {
    const { form = {} } = this.props
    const {
      id = '',
      email = '',
      first_name = '',
      last_name = '',
      role = '',
      // organization = {
      //   name: ''
      // },
      unit = {
        name: ''
      }
    } = form

    this.initialValues = {
      id,
      email,
      first_name,
      last_name,
      role,
      unit_id: unit ? unit.id : ''
    }

    // const roleIndex = this.roles.findIndex(r => r.value === userStore.user.role) || 0;
    // this.roles = this.roles.slice(roleIndex + 1);
  }

  private validate = (values: INewUserFormValues) => {
    const errors: INewUserFormValues = {}
    if (!values.email) {
      errors.email = 'Required'
    }
    if (!values.first_name) {
      errors.first_name = 'Required'
    }
    if (!values.last_name) {
      errors.last_name = 'Required'
    }
    if (!values.role) {
      errors.role = 'Required'
    }
    return errors
  }

  public render(): ReactNode {
    const { form, onSubmit, units } = this.props

    const unitOptions = units.map((unit: any) => {
      return {
        value: unit.id,
        label: unit.name
      }
    })
    return (
      <div className="content">
        <div className="ttl-container">
          <h3 className="ttl">
            {I18n.get(form ? 'update_user_title' : 'new_user_title')}
          </h3>
        </div>
        <div className="modal-form-container add-user-form">
          <Formik
            initialValues={this.initialValues}
            validate={this.validate}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="grid-container">
                  <div className="grid-item">
                    <FormField
                      label={I18n.get('first_name')}
                      type="text"
                      name="first_name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <FormField
                      label={I18n.get('last_name')}
                      type="text"
                      name="last_name"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                  <div className="grid-item grid-item-row">
                    <FormField
                      label={I18n.get('email')}
                      type="email"
                      name="email"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                  <div className="grid-item">
                    <SelectField
                      fieldClass="fieldRole"
                      label={I18n.get('role')}
                      options={this.roles}
                      setFieldValue={setFieldValue}
                      name="role"
                      value={values.role}
                    />
                    <SelectField
                      fieldClass="fieldUnit"
                      label={I18n.get('sub_organization')}
                      options={unitOptions}
                      setFieldValue={setFieldValue}
                      name="unit_id"
                      value={values.unit_id}
                    />
                  </div>
                  <ErrorMessage name="server_error" />
                </div>
                <div className="footer jc-end">
                  <button
                    className="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {I18n.get(form ? 'update_user_btn' : 'add_user_btn')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default NewUserForm
