import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { computed, observable, toJS, action } from 'mobx'
import { RouteComponentProps } from 'react-router'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import { I18n } from 'aws-amplify'
import api from '../../../utils/api'
import Input from '../../../components/Editor/InputField'
import { IDataStore } from '../../../store/dataStore'
import './style.scss'
import Icon from '../../../components/Icons/Icon'
import { EDITED } from '../../../components/Icons/icons'
import CustomSelect from '../../../components/bootstrapInput'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

interface IDocumentElement {
  id: number
  key: string
  href: string
  name: string
}

const columns = {
  en: {
    title: 'My organization',
    model: 'Informations de l’organisation',
    Save: 'Save',
    Category: 'Category',
    Contracts: 'Contracts',
    'Contract name': 'Contract name',
    Edit: 'Edit',
    Download: 'Download'
  },
  fr: {
    title: 'My organization',
    model: 'Informations de l’organisation',
    Save: 'Enregistrer',
    Category: 'Catégorie',
    Contracts: 'Contrats',
    'Contract name': 'Nom du contrat',
    Edit: 'Modifier',
    Download: 'Télécharger'
  }
}

I18n.putVocabularies(columns)

interface IDownloadPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
}

@inject('authStore', 'dataStore', 'contractsStore')
@observer
class DownloadPage extends Component<any> {
  @observable fileName: string = ''

  async UNSAFE_componentWillMount() {
    const { fetchDownloadInfo } = this.props.dataStore

    fetchDownloadInfo(this.props.match.params)
  }

  @computed get documents(): IDocumentElement[] {
    const { documents } = this.props.dataStore.downloadInformation

    if (!documents || !documents.length) return []

    return documents.map((document: Record<string, string>) => ({
      id: document.id,
      key: document.id,
      href: document.path,
      name: document.path.split('/')[document.path.split('/').length - 1]
    }))
  }

  @computed get instance(): any {
    const instance = toJS(this.props.dataStore.downloadInformation.instance)
    if (Object.keys(instance).length === 0) {
      return false
    }
    return {
      name: instance.filename || instance.model.meta.name,
      template: `${instance.model.meta.name}, model ${instance.model.version}`,
      id: instance.uniqid,
      permalink: instance.model.meta.permalink,
      categoryName: instance.model.meta.category.name
    }
  }

  handleSave = () => {
    const { updateDownloadInfo } = this.props.dataStore
    const params = {
      filename: this.instance.name
    }
    updateDownloadInfo(params)
  }

  downloadDoc = async (document: any) => {
    const { name } = document

    await api({
      url: `/editor/download/${this.instance.id}/document/${encodeURI(name)}`,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = window.document.createElement('a')
          link.href = url
          link.setAttribute('download', name) // or any other extension
          window.document.body.appendChild(link)

          link.click()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  @catchAction
  @action
  handleNameChange = (name: string, value: string) => {
    this.props.dataStore.updateInstanceName(value)
  }

  public render(): ReactNode {
    const { id, permalink } = this.instance
    return (
      <div className="content download-container">
        <nav className="download-nav">
          <div className="bread-crumbs-container">
            <div className="bread-crumbs">
              <Link className="go-back-ttl secondary" to="/dashboard/all-files">
                {`< ${I18n.get('Contracts')}`}
              </Link>
            </div>
          </div>
        </nav>
        <div className="download-header">
          <h3 className="ttl">
            {this.instance ? this.instance.name : 'Documents'}
          </h3>
          <div className="download-right">
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WIZARDX}contrats/${permalink}/continuer/${id}/1`}
            >
              <button type="button" className="btn primary" onClick={() => {}}>
                <Icon iconClass="iconPlus" icon={EDITED} color="#fff" />
                {I18n.get('Edit')}
              </button>
            </a>
            {/* <FormControl classes={{root: 'download-dropdown'}}> */}
            <CustomSelect
              className="primary"
              label={I18n.get('Download')}
              name="document"
              id="document-download"
            >
              {this.documents.map((document: IDocumentElement, i: number) => (
                <div key={i}>
                  <MenuItem
                    key={document.key}
                    onClick={() => this.downloadDoc(document)}
                  >
                    {document.name}
                  </MenuItem>
                </div>
              ))}
            </CustomSelect>
            {/* </FormControl> */}
          </div>
        </div>
        {this.instance && (
          <main className="main-container">
            <div className="download-row-container">
              <div className="detail-download-container">
                <span className="download-text-field first-child">
                  {I18n.get('Contract name')}
                </span>
                <Input
                  setFieldValue={(name: string, value: string) =>
                    this.handleNameChange(name, value)
                  }
                  name="fileName"
                  fieldClass="download-page-inputs"
                  value={this.instance.name}
                />
              </div>
              <div className="detail-download-container">
                <span className="download-text-field first-child">
                  Template
                </span>
                <span className="download-text-field">
                  {this.instance.template}
                </span>
              </div>
            </div>
            <div className="download-row-container">
              {/* <div className="detail-download-container"> */}
              {/* <span className="download-text-field">ID</span> */}
              {/* <span className="download-text-field id-field">{this.instance.id}</span> */}
              {/* </div> */}
              <div className="detail-download-container">
                {/* <span className="download-text-field first-child">Tags</span> */}
                {/* <Input fieldClass="download-page-inputs" /> */}
              </div>
              <div className="detail-download-container">
                <span className="download-text-field first-child">
                  {I18n.get('Category')}
                </span>
                <span className="download-text-field">
                  {this.instance.categoryName}
                </span>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-active"
              onClick={this.handleSave}
            >
              {I18n.get('Save')}
            </button>
          </main>
        )}
      </div>
    )
  }
}

export default DownloadPage
