import { OptionV3, VariableV3, SectionV3 } from '@legalplace/models-v3-types'

export const isOption = (option: any): option is OptionV3 => {
  if (typeof option === 'object')
    if (typeof option.meta === 'object') return true
  return false
}

export const isVariable = (variable: any): variable is VariableV3 => {
  if (
    typeof variable === 'object' &&
    variable.meta === undefined &&
    variable.options === undefined &&
    typeof variable.id === 'number' &&
    typeof variable.type === 'string'
  )
    return true
  return false
}

export const isSection = (section: any): section is SectionV3 => {
  if (
    typeof section === 'object' &&
    section.meta === undefined &&
    section.type === undefined &&
    typeof section.id === 'number'
  )
    return true
  return false
}
