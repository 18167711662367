import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import SectionContext from '../editors/SectionContext'
import { SecondStepOnly } from './components/SecondStepOnly'
import { AdminOnly } from './components/AdminOnly'

const dictionary = {
  fr: {
    display_category: 'Affichage'
  },
  en: {
    display_category: 'Display'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Display section params
 */
const DisplaySectionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  return (
    <div className="state-drawer-category">
      <div
        className={`category-title${collapsed ? ' collapsed' : ' open'}`}
        onClick={toggleCollapsed}
      >
        {I18n.get('display_category')}
      </div>

      {!collapsed && (
        <SectionContext.Consumer>
          {(state) => {
            return (
              <>
                {/* 2nd step only: step */}
                <SecondStepOnly state={state} />

                {/* Admin only: grantLevel */}
                <AdminOnly state={state} />
              </>
            )
          }}
        </SectionContext.Consumer>
      )}
    </div>
  )
}

export default DisplaySectionParams
