import { ConditionV3 } from '@legalplace/models-v3-types'
import { cloneDeep } from 'lodash'
import { action } from 'mobx'
import SafeDelete from './safeDelete'
import createStore from './createStore'
import { catchAction } from './catchActionsDecorator'

type DATA_MAP = {
  var: string
}

class UnlinkDelete extends SafeDelete {
  unlink() {
    // Deleting option conditions
    this.deleteOptionsConditions()
    this.deleteOptionsValidatorsConditions()

    // Deletion variables related conditions
    this.deleteVariablesConditions()
    this.deleteVariablesValidatorsConditions()
    this.deleteVariablesPrefillersConditions()

    this.deleteDocumentsConditions()

    this.deleteSectionsConditions()

    this.deleteClientTypeConditions()

    // Unlinking repeateds
    this.unLinkRepeats()
    createStore.updateCurrentDocumentOutputs()
    if (createStore.slateEditorRef?.current)
      createStore.slateEditorRef.current.updateOutputs()
  }

  /**
   * Delete from options conditions
   */
  @catchAction
  @action
  private deleteOptionsConditions() {
    this.elements.conditionnedOptions.forEach((id) => {
      const option = createStore.document.options[id]
      const { conditions } = option.meta
      if (conditions) {
        const newConditions = this.deleteCondition(conditions)
        if (
          typeof newConditions === 'object' &&
          Object.keys(newConditions).length === 0
        )
          delete option.meta.conditions
        else option.meta.conditions = newConditions
      }
    })
  }

  /**
   * Delete from options validators
   */
  @catchAction
  @action
  private deleteOptionsValidatorsConditions() {
    this.elements.validatedOptions.forEach((id) => {
      const option = createStore.document.options[id]
      const { meta } = option
      if (meta.validator) {
        const { conditions } = meta.validator
        if (conditions) {
          const newConditions = this.deleteCondition(conditions)
          if (
            typeof newConditions === 'object' &&
            Object.keys(newConditions).length === 0
          )
            delete meta.validator.conditions
          else meta.validator.conditions = newConditions
        }
      }
    })
  }

  /**
   * Delete from variable conditions
   */
  @catchAction
  @action
  private deleteVariablesConditions() {
    this.elements.conditionnedVariables.forEach((id) => {
      const variable = createStore.document.variables[id]
      const { conditions } = variable
      if (conditions) {
        const newConditions = this.deleteCondition(conditions)
        if (
          typeof newConditions === 'object' &&
          Object.keys(newConditions).length === 0
        )
          delete variable.conditions
        else variable.conditions = newConditions
      }
    })
  }

  /**
   * Delete from variable validators
   */
  @catchAction
  @action
  private deleteVariablesValidatorsConditions() {
    this.elements.validatedVariables.forEach((id) => {
      const variable = createStore.document.variables[id]
      if (variable.validator) {
        const { conditions } = variable.validator
        if (conditions) {
          const newConditions = this.deleteCondition(conditions)
          if (
            typeof newConditions === 'object' &&
            Object.keys(newConditions).length === 0
          )
            delete variable.validator.conditions
          else variable.validator.conditions = newConditions
        }
      }
    })
  }

  /**
   * Delete from variable's prefiller conditions
   */
  @catchAction
  @action
  private deleteVariablesPrefillersConditions() {
    this.elements.variablesConditionnedPrefills.forEach(
      ({ variableId, index }) => {
        const variable = createStore.document.variables[variableId]
        const { prefillings } = variable
        if (prefillings) {
          const prefilling = prefillings[index]
          if (prefilling) {
            const { conditions } = prefilling
            if (conditions) {
              const newConditions = this.deleteCondition(conditions)
              if (
                typeof newConditions === 'object' &&
                Object.keys(newConditions).length === 0
              )
                delete prefillings[index].conditions
              else prefillings[index].conditions = newConditions
            }
          }
        }
      }
    )
  }

  /**
   * Delete from document conditions
   */
  @catchAction
  @action
  private deleteDocumentsConditions() {
    this.elements.conditionnedDocuments.forEach((name) => {
      const document = createStore.document.documents[name]
      const { params } = document
      if (params) {
        const { conditions } = params
        if (conditions) {
          const newConditions = this.deleteCondition(conditions)
          if (
            typeof newConditions === 'object' &&
            Object.keys(newConditions).length === 0
          )
            delete params.conditions
          else params.conditions = newConditions
        }
      }
    })
  }

  /**
   * Delete from sections conditions
   */
  @catchAction
  @action
  private deleteSectionsConditions() {
    this.elements.conditionnedSections.forEach((id) => {
      const section = createStore.document.documents.main.sections.find(
        (s) => s.id === id
      )
      if (section) {
        const { conditions } = section
        if (conditions) {
          const newConditions = this.deleteCondition(conditions)
          if (
            typeof newConditions === 'object' &&
            Object.keys(newConditions).length === 0
          )
            delete section.conditions
          else section.conditions = newConditions
        }
      }
    })
  }

  /**
   * Delete from client type conditions
   */
  @catchAction
  @action
  private deleteClientTypeConditions() {
    if (this.elements.conditionnedClientType === true) {
      const clientType = createStore.document.customization.meta?.clientType

      if (clientType) {
        const { conditions } = clientType
        if (conditions) {
          const newConditions = this.deleteCondition(conditions)
          if (
            typeof newConditions === 'object' &&
            Object.keys(newConditions).length === 0
          )
            delete clientType.conditions
          else clientType.conditions = newConditions
        }
      }
    }
  }

  /**
   * Unlink repeated outputs
   */
  private unLinkRepeats() {
    this.elements.repeateds.forEach((id) => {
      const parentId = this.getOptionParent(id)
      createStore.unlinkOutputFromMultiple(id, parentId)
    })
  }

  /**
   * Deletes current element from condition object
   * @param conditions Condition object
   */
  private deleteCondition(conditions: ConditionV3) {
    const copy = cloneDeep(conditions)

    let changed = false

    const keys = Object.keys(copy) as (keyof ConditionV3)[]
    keys.forEach((key) => {
      const part = copy[key]
      if (
        Array.isArray(part) &&
        this.isDataMap(part[0]) &&
        part[0].var === `${this.type}.${this.id}`
      ) {
        delete copy[key]
        changed = true
      } else if (this.isConditionArray(part)) {
        for (let i = 0; i < part.length; i += 1) {
          const subPart = part[i]
          const newSubPart = this.deleteCondition(subPart)

          if (newSubPart !== subPart) {
            changed = true

            if (
              typeof newSubPart === 'object' &&
              Object.keys(newSubPart).length === 0
            ) {
              part.splice(i, 1)
              i -= 1
            } else part.splice(i, 1, newSubPart)

            if (part.length === 0) {
              delete copy[key]
            }
          }
        }
      }
    })

    if (changed) return copy
    return conditions
  }

  private isDataMap = (part: any): part is DATA_MAP => {
    return (
      typeof part === 'object' &&
      Object.prototype.hasOwnProperty.call(part, 'var') &&
      Object.keys(part).length === 1 &&
      typeof part.var === 'string'
    )
  }

  private isConditionArray = (part: any): part is ConditionV3[] => {
    return Array.isArray(part) && !this.isDataMap(part[0])
  }
}

export default UnlinkDelete
