import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import { SecondStepOnly } from './components/SecondStepOnly'
import { AdminOnly } from './components/AdminOnly'
import { Mandatory } from './components/Mandatory'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: {
    display_category: 'Affichage'
  },
  en: {
    display_category: 'Display'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Display option params
 */
const DisplayOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  return (
    <div
      className={`state-drawer-category${collapsed ? ' collapsed' : ' open'}`}
    >
      <div className="category-title" onClick={toggleCollapsed}>
        {I18n.get('display_category')}
        <div className="icon">
          <ChevronDown />
        </div>
      </div>

      {!collapsed && (
        <OptionContext.Consumer>
          {(state) => {
            return (
              <>
                {/* 2nd step only: meta.step */}
                <SecondStepOnly state={state} />

                {/* Admin only: meta.grantLevel */}
                <AdminOnly state={state} />

                {/* meta.mandatory */}
                <Mandatory state={state} />
              </>
            )
          }}
        </OptionContext.Consumer>
      )}
    </div>
  )
}

export default DisplayOptionParams
