import React from 'react'
import { I18n } from 'aws-amplify'
import { observer } from 'mobx-react'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import { CONDITIONTREEICON } from '../../../../../../../components/Icons/icons'
import createStore from '../../../../../../../store/editor/createStore'
import Icon from '../../../../../../../components/Icons/Icon'

const dictionary = {
  fr: {
    'Prefill:': 'Pré-remplissage:',
    'Add new': 'Ajouter un nouveau',
    'Add/Modify': 'Modifier'
  },
  en: {
    'Prefill:': 'Prefill:',
    'Add new': 'Add new',
    'Add/Modify': 'Add/Modify'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits label for question, radio & checkbox
 */
export const Prefillings: React.FC<{
  state: VariableEditorStateType
}> = observer(({ state }) => {
  const { variable } = state
  const { prefillings } = variable

  /**
   * Opens prefillings editing modal
   */
  const openEditingModal = () => {
    // open condition modal
    const { updateSelectedElementPrefillings, toggleModal } = createStore

    // update element prefillings
    updateSelectedElementPrefillings(prefillings || [])

    // open prefillings modal
    toggleModal('prefillIsOpen', true)
  }

  return (
    <div className="conditions-block">
      <div>
        <Icon iconClass="tree-ico" icon={CONDITIONTREEICON} color="#e32778" />
        {I18n.get('Prefill:')}
        <span className="conditions-value">{prefillings?.length || 0}</span>
      </div>
      <button
        type="button"
        data-cypressId="conditions-btn"
        onClick={openEditingModal}
        className="secondary"
      >
        {prefillings && prefillings.length < 1
          ? I18n.get('Add new')
          : I18n.get('Add/Modify')}
      </button>
    </div>
  )
})
