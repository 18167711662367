import React, { Component } from 'react'

import IconsModal from '../IconsModal/IconsModal'
import './style.scss'

interface IIconInputProps {
  label: string
  className?: string
}

interface IIconInputState {
  value: string
  icon: string
  modalIsOpen: boolean
}

class IconInput extends Component<IIconInputProps, IIconInputState> {
  constructor(props: IIconInputProps) {
    super(props)
    this.state = {
      value: '',
      icon: '',
      modalIsOpen: false
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false })
  }

  onHandleChange = (e: any) => {
    const { value } = e.target
    this.setState({
      value
    })
    // this.props.handleChange(value);
  }

  onHandleIconChange = (icon: any) => {
    this.setState({
      icon
    })
    // this.props.handleChange(value);
  }

  render() {
    const { label, className } = this.props
    const { value, modalIsOpen, icon } = this.state

    return (
      <div className={`input ${className}`}>
        <label>{label}</label>
        <div className="mi-icon-input" onClick={this.openModal}>
          <i className="material-icons ng-binding">{icon}</i>
        </div>
        <input
          className="iconInput"
          type="text"
          onChange={this.onHandleChange}
          value={value}
        />
        <IconsModal
          modalIsOpen={modalIsOpen}
          onHandleIconChange={this.onHandleIconChange}
          closeModal={this.closeModal}
        />
      </div>
    )
  }
}

export default IconInput
