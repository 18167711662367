import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import { action, observable } from 'mobx'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { IAuthStore } from '../../store/authStore'
import Icon from '../Icons/Icon'
import { ARROWBOTTOM, USER } from '../Icons/icons'
import styles from './Menu.module.scss'
import { IUserStore } from '../../store/userStore'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface IHeaderProps {
  authStore?: IAuthStore | any
  userStore?: IUserStore | any
}

const columns = {
  en: {
    settings: 'Settings',
    logout: 'Logout'
  },
  fr: {
    settings: 'Paramètres',
    logout: 'Déconnexion'
  }
}

I18n.putVocabularies(columns)

@inject('authStore', 'userStore')
@observer
class Menu extends Component<IHeaderProps> {
  @observable open = false

  private anchorEl: any

  @catchAction
  @action
  handleToggle = () => {
    this.open = !this.open
  }

  logout = () => {
    this.props.authStore.logout()
    this.handleToggle()
  }

  render(): ReactNode {
    const { first_name, last_name } = this.props.userStore.user
    const btnClassName = classNames({
      btn: true,
      'btn-profile': true,
      'is-open': this.open
    })
    return (
      <div>
        <button
          type="button"
          onClick={this.handleToggle}
          className={btnClassName}
          ref={(ref) => {
            this.anchorEl = ref
            return this.anchorEl
          }}
        >
          {first_name} {last_name}
          <div className={styles.iconsContainer}>
            <Icon iconClass="iconUser" icon={USER} />
            <Icon iconClass="iconArrows iconArrowBottom" icon={ARROWBOTTOM} />
          </div>
        </button>
        <Popover
          className="popover"
          classes={{ paper: 'popover-area' }}
          anchorEl={this.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={this.open}
          onClose={this.handleToggle}
        >
          <div className="menu">
            <Link to="/dashboard/settings">
              <MenuItem onClick={this.handleToggle}>
                {I18n.get('settings')}
              </MenuItem>
            </Link>
            <MenuItem onClick={this.logout}>{I18n.get('logout')}</MenuItem>
          </div>
        </Popover>
      </div>
    )
  }
}

export default Menu
