import React, { CSSProperties } from 'react'
import { I18n } from 'aws-amplify'
import HealthCheck from '@legalplace/model-healthcheck'
import { ModelV3 } from '@legalplace/models-v3-types'
import promptModal from '../../components/PromptPortal/PromptPortal'
import createStore from './createStore'

type ElementType<T extends Array<unknown>> = T extends Array<infer ElementType>
  ? ElementType
  : never

const wording = {
  en: {
    'healthcheckmodal-subtitle': 'Your model has %errorsCount errors',
    'healthcheckmodal-title': 'UNABLE TO SAVE: Your modal has errors',
    'To force saving please type "SAVE" under and click on "Save".':
      'To force saving please type "SAVE" under and click on "Save".',
    'healthcheckmodal-recommandation':
      'It is strongly recommended that you click "Close" and correct any errors. If you leave this model without saving it, first check the time of the last saved model on the dashboard',
    Save: 'Save',
    SAVE: 'SAVE'
  },
  fr: {
    'healthcheckmodal-title':
      'SAUVEGARDE IMPOSSIBLE : Le modèle comporte des erreurs',
    'healthcheckmodal-subtitle': 'Votre modèle comporte %errorsCount erreurs.',
    'To force saving please type "SAVE" under and click on "Save".':
      'Pour forcer l\'enregistrement veuillez saisir "ENREGISTRER" ci-dessous et cliquer sur "Enregistrer" .',
    'healthcheckmodal-recommandation':
      "Il est fortement recommandé de cliquer sur \"Fermer\" et de corriger les erreurs. Si vous quittez ce modèle sans l'enregistrer, vérifiez d'abord l'heure de la dernière sauvegarde du modèle sur le dashboard",
    Save: 'Enregistrer',
    SAVE: 'ENREGISTRER'
  }
}

I18n.putVocabularies(wording)

const HealthCheckReport: React.FC<{ report: string; errorsCount: number }> = ({
  report,
  errorsCount
}) => {
  const preStyle: CSSProperties = {
    fontFamily: 'sans-serif',
    background: '#F6F6F6',
    border: '1px solid #CCC',
    borderRadius: '4px',
    padding: '5px',
    fontSize: '12px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  }
  return (
    <div>
      <b>
        {I18n.get('healthcheckmodal-subtitle').replace(
          '%errorsCount',
          errorsCount
        )}
      </b>
      <pre style={preStyle}>{report}</pre>
      <i>
        {I18n.get(
          'To force saving please type "SAVE" under and click on "Save".'
        )}
      </i>
      <br />
      <b>{I18n.get('healthcheckmodal-recommandation')}</b>
    </div>
  )
}

export const runHealthCheck = (document: ModelV3) => {
  return new Promise((resolve, reject) => {
    try {
      const healthcheck = new HealthCheck(JSON.parse(JSON.stringify(document)))

      // Cleaning Orphelins
      const { orphelins } = healthcheck.getReport()
      if (orphelins.options.length > 0 || orphelins.variables.length > 0)
        createStore.cleanOrphelins(orphelins)
      if (healthcheck.getErrorsCount() > 0) {
        const report = healthcheck.getRawReport(true)
        promptModal(
          () => (
            <HealthCheckReport
              report={report}
              errorsCount={healthcheck.getErrorsCount()}
            />
          ),
          {
            title: I18n.get('healthcheckmodal-title'),
            expectedValue: I18n.get('SAVE')
          }
        )
          .then((value) => {
            // Running healthcheck again to check orphelins
            if (value === I18n.get('SAVE')) {
              resolve()
            }
          })
          .catch(() => {
            reject()
          })
      } else {
        resolve()
      }
    } catch (e) {
      reject(e)
    }
  })
}
