import React, { useLayoutEffect, useState } from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Icon from '../../../../../../../components/Icons/Icon'
import { CONDITIONTREEICON } from '../../../../../../../components/Icons/icons'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    See: 'Voir'
  },
  en: {
    See: 'See'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Checks whether variable conditions something
 */
export const ConditionOf: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const [hasCondition, setHasCondition] = useState<boolean>(false)

  useLayoutEffect(() => {
    createStore.clearHasCondition()
    createStore.checkForConditions(state.variable, [], true, false, false)

    if (hasCondition !== createStore.hasCondition)
      setHasCondition(createStore.hasCondition)
  }, [hasCondition, state.variable])

  if (hasCondition === false) return null

  return (
    <div className="condition-warning">
      <div className="condition-warning-left">
        <Icon iconClass="tree-ico" icon={CONDITIONTREEICON} color="#e32778" />
        {I18n.get('This element is conditionning other elements')}
      </div>
      <div className="condition-warning-right">
        <button
          type="button"
          onClick={() => createStore.toggleDeleteConditionModal(true, false)}
          className="secondary"
        >
          {I18n.get('See')}
        </button>
      </div>
    </div>
  )
}
