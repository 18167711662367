import { MouseEvent } from 'react'
import { Editor } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import watchSelectionChange from '../variableUtils/watchSelection'

const handleMouseUp = (event: MouseEvent, editor: Editor & ReactEditor) => {
  // Watch selection change for variables
  watchSelectionChange(event, editor)
}

export default handleMouseUp
