/* eslint-disable react/no-unused-state */
import React from 'react'
import { OptionV3 } from '@legalplace/models-v3-types'
import { cloneDeep } from 'lodash'
import { action } from 'mobx'
import createStore from '../../../../../../store/editor/createStore'
import { catchAction } from '../../../../../../store/editor/catchActionsDecorator'

class OptionEditor extends React.Component<IProps, OptionEditorStateType> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      id: props.option.meta.id,
      option: cloneDeep(props.option),

      updateType: this.updateType,
      updateLabel: this.updateLabel,
      updateFallbackLabel: this.updateFallbackLabel,
      updateConditions: this.updateConditions,
      updateBoxContent: this.updateBoxContent,
      updateBoxTitle: this.updateBoxTitle,
      updateBoxType: this.updateBoxType,
      updateBoxAggregatable: this.updateBoxAggregatable,
      updateBoxCollapsable: this.updateBoxCollapsable,
      updateStep: this.updateStep,
      updateGrantLevel: this.updateGrantLevel,
      updateMandatory: this.updateMandatory,
      updateMultipleEnabled: this.updateMultipleEnabled,
      updateMultipleLabel: this.updateMultipleLabel,
      updateMultipleCta: this.updateMultipleCta,
      updateMultipleIncrementationStart: this.updateMultipleIncrementationStart,
      updateStyle: this.updateStyle,
      updateTermsheetHide: this.updateTermsheetHide,
      updateTermsheetLabel: this.updateTermsheetLabel,
      updateHelper: this.updateHelper,
      updateWarning: this.updateWarning,
      updateTags: this.updateTags,
      updateValidatorConditions: this.updateValidatorConditions,
      updateValidatorMessage: this.updateValidatorMessage,

      toggleConditionsDrawer: props.toggleConditionsDrawer,
      toggleDrawer: props.toggleDrawer
    }
  }

  /**
   * Saves current updates
   */
  @catchAction
  @action
  saveUpdates = () => {
    const originalOption = createStore.document.options[this.state.id]
    createStore.document.options[this.state.id] = {
      ...originalOption,
      meta: {
        ...originalOption.meta,
        ...this.state.option.meta
      }
    }
    this.props.toggleDrawer(false)
  }

  /**
   * Deletes option
   */
  deleteOption = () => {
    // delete element
    const { toggleDrawer } = this.props
    const {
      checkForConditions,
      selectedElement,
      selectedElementIds
    } = createStore

    // delete element
    checkForConditions(
      selectedElement,
      selectedElementIds,
      false,
      true,
      true,
      () => {
        toggleDrawer(false)
      }
    )
  }

  /**
   * Moves option
   */
  moveOption = () => {
    const { id } = this.state.option.meta

    // Initiating move element
    createStore.initiateMoveElement('option', id, this.props.toggleDrawer)
    this.props.toggleDrawer(false)
  }

  /**
   * Closes drawer
   */
  closeDrawer = () => {
    this.props.toggleDrawer(false)
  }

  /**
   * Updates option type
   * @param type Option's fallback type
   */
  updateType = (type: OptionV3['meta']['type']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          type
        }
      }
    })
  }

  /**
   * Updates option label
   * @param label Option's label
   */
  updateLabel = (label: OptionV3['meta']['label']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          label
        }
      }
    })
  }

  /**
   * Updates option fallback label
   * @param fallbackLabel Option's fallback label
   */
  updateFallbackLabel = (fallbackLabel: OptionV3['meta']['fallbackLabel']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          fallbackLabel
        }
      }
    })
  }

  /**
   * Updates option conditions
   * @param conditions Option's conditions
   */
  updateConditions = (conditions: OptionV3['meta']['conditions']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          conditions
        }
      }
    })
  }

  /**
   * Updates option validator conditions
   * @param conditions Option's validator conditions
   */
  updateValidatorConditions = (
    conditions: Exclude<OptionV3['meta']['validator'], undefined>['conditions']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          validator: {
            ...this.state.option.meta.validator,
            conditions
          }
        }
      }
    })
  }

  /**
   * Updates option validator message
   * @param message Option's validator message
   */
  updateValidatorMessage = (
    message: Exclude<OptionV3['meta']['validator'], undefined>['message']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          validator: {
            ...this.state.option.meta.validator,
            message
          }
        }
      }
    })
  }

  /**
   * Updates option box content
   * @param content Option's box content
   */
  updateBoxContent = (
    content: Exclude<OptionV3['meta']['box'], undefined>['content']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          box: {
            ...this.state.option.meta.box,
            content
          }
        }
      }
    })
  }

  /**
   * Updates option box title
   * @param title Option's box title
   */
  updateBoxTitle = (
    title: Exclude<OptionV3['meta']['box'], undefined>['title']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          box: {
            ...this.state.option.meta.box,
            title
          }
        }
      }
    })
  }

  /**
   * Updates option box type
   * @param type Option's box type
   */
  updateBoxType = (
    type: Exclude<OptionV3['meta']['box'], undefined>['type']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          box: {
            ...this.state.option.meta.box,
            type
          }
        }
      }
    })
  }

  /**
   * Updates option box aggregatable
   * @param aggregatable Option's box aggregatable
   */
  updateBoxAggregatable = (
    aggregatable: Exclude<OptionV3['meta']['box'], undefined>['aggregatable']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          box: {
            ...this.state.option.meta.box,
            aggregatable
          }
        }
      }
    })
  }

  /**
   * Updates option box collapsable
   * @param collapsable Option's box collapsable
   */
  updateBoxCollapsable = (
    collapsable: Exclude<OptionV3['meta']['box'], undefined>['collapsable']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          box: {
            ...this.state.option.meta.box,
            collapsable
          }
        }
      }
    })
  }

  /**
   * Updates option step
   * @param step Option's step
   */
  updateStep = (step: OptionV3['meta']['step']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          step
        }
      }
    })
  }

  /**
   * Updates option grantLevel
   * @param grantLevel Option's grantLevel
   */
  updateGrantLevel = (grantLevel: OptionV3['meta']['grantLevel']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          grantLevel
        }
      }
    })
  }

  /**
   * Updates option mandatory
   * @param mandatory Option's mandatory
   */
  updateMandatory = (mandatory: OptionV3['meta']['mandatory']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          mandatory
        }
      }
    })
  }

  /**
   * Updates multiple enabled
   * @param eanbled
   */
  updateMultipleEnabled = (
    enabled: Exclude<OptionV3['meta']['multiple'], undefined>['enabled']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          multiple: {
            ...this.state.option.meta.multiple,
            enabled
          }
        }
      }
    })
  }

  /**
   * Updates multiple label
   * @param label
   */
  updateMultipleLabel = (
    label: Exclude<OptionV3['meta']['multiple'], undefined>['label']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          multiple: {
            ...(this.state.option.meta.multiple || { enabled: false }),
            label
          }
        }
      }
    })
  }

  /**
   * Updates multiple cta
   * @param cta
   */
  updateMultipleCta = (
    cta: Exclude<OptionV3['meta']['multiple'], undefined>['cta']
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          multiple: {
            ...(this.state.option.meta.multiple || { enabled: false }),
            cta
          }
        }
      }
    })
  }

  /**
   * Updates multiple incrementation start
   * @param incrementationStart
   */
  updateMultipleIncrementationStart = (
    incrementationStart: Exclude<
      Exclude<OptionV3['meta']['multiple'], undefined>['incrementationStart'],
      undefined
    >
  ) => {
    let incrementationStartNumber: number | undefined =
      typeof incrementationStart === 'number'
        ? incrementationStart
        : parseInt(incrementationStart, 10)
    if (Number.isNaN(incrementationStart)) incrementationStartNumber = undefined
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          multiple: {
            ...(this.state.option.meta.multiple || { enabled: false }),
            incrementationStart: incrementationStartNumber
          }
        }
      }
    })
  }

  /**
   * Updates option style
   * @param style
   */
  updateStyle = (style: Exclude<OptionV3['meta']['style'], undefined>) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          style
        }
      }
    })
  }

  /**
   * Updates option termsheet hide
   * @param hide
   */
  updateTermsheetHide = (
    hide: Exclude<
      Exclude<OptionV3['meta']['termsheet'], undefined>['hide'],
      undefined
    >
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          termsheet: {
            ...this.state.option.meta.termsheet,
            hide
          }
        }
      }
    })
  }

  /**
   * Updates option termsheet label
   * @param label
   */
  updateTermsheetLabel = (
    label: Exclude<
      Exclude<OptionV3['meta']['termsheet'], undefined>['label'],
      undefined
    >
  ) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          termsheet: {
            ...this.state.option.meta.termsheet,
            label
          }
        }
      }
    })
  }

  /**
   * Updates option helper
   * @param helper
   */
  updateHelper = (helper: OptionV3['meta']['helper']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          helper
        }
      }
    })
  }

  /**
   * Updates option warning
   * @param warning
   */
  updateWarning = (warning: OptionV3['meta']['warning']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          warning
        }
      }
    })
  }

  /**
   * Updates option tags
   * @param tags
   */
  updateTags = (tags: OptionV3['meta']['tags']) => {
    this.setState({
      option: {
        ...this.state.option,
        meta: {
          ...this.state.option.meta,
          tags
        }
      }
    })
  }

  render() {
    return <></>
  }
}

interface IProps {
  option: OptionV3
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
  toggleDrawer(value: boolean): void
}

export interface OptionEditorStateType {
  id: number
  option: OptionV3
  updateType: typeof OptionEditor.prototype.updateType
  updateLabel: typeof OptionEditor.prototype.updateLabel
  updateFallbackLabel: typeof OptionEditor.prototype.updateFallbackLabel
  updateConditions: typeof OptionEditor.prototype.updateConditions
  updateValidatorConditions: typeof OptionEditor.prototype.updateValidatorConditions
  updateValidatorMessage: typeof OptionEditor.prototype.updateValidatorMessage
  updateBoxContent: typeof OptionEditor.prototype.updateBoxContent
  updateBoxTitle: typeof OptionEditor.prototype.updateBoxTitle
  updateBoxType: typeof OptionEditor.prototype.updateBoxType
  updateBoxAggregatable: typeof OptionEditor.prototype.updateBoxAggregatable
  updateBoxCollapsable: typeof OptionEditor.prototype.updateBoxCollapsable
  updateStep: typeof OptionEditor.prototype.updateStep
  updateGrantLevel: typeof OptionEditor.prototype.updateGrantLevel
  updateMandatory: typeof OptionEditor.prototype.updateMandatory
  updateMultipleEnabled: typeof OptionEditor.prototype.updateMultipleEnabled
  updateMultipleLabel: typeof OptionEditor.prototype.updateMultipleLabel
  updateMultipleCta: typeof OptionEditor.prototype.updateMultipleCta
  updateMultipleIncrementationStart: typeof OptionEditor.prototype.updateMultipleIncrementationStart
  updateStyle: typeof OptionEditor.prototype.updateStyle
  updateTermsheetLabel: typeof OptionEditor.prototype.updateTermsheetLabel
  updateTermsheetHide: typeof OptionEditor.prototype.updateTermsheetHide
  updateHelper: typeof OptionEditor.prototype.updateHelper
  updateWarning: typeof OptionEditor.prototype.updateWarning
  updateTags: typeof OptionEditor.prototype.updateTags

  toggleConditionsDrawer(
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ): void
  toggleDrawer(value: boolean): void
}

export default OptionEditor
