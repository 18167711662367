import React from 'react'
import { Link } from 'react-router-dom'

import '../Button/style.scss'

const LinkButton = (props: any) => {
  const { href, children, type, onClick } = props
  return (
    <Link
      onClick={onClick}
      className={`button ${type === 'big' && 'big-button'}`}
      to={href}
    >
      {children}
    </Link>
  )
}

export default LinkButton
