import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'
import { action, observable } from 'mobx'
import debounce from 'lodash/debounce'
import { replace } from '../../utils/history'
import { SEARCH } from '../Icons/icons'
import Icon from '../Icons/Icon'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface IDataSearchProps extends RouteComponentProps {
  onChangeParams(): void
  placeholder?: string
  cypressId?: string
}

@observer
class DataSearch extends Component<IDataSearchProps> {
  @observable public search: string =
    new URLSearchParams(this.props.location.search).get('search') || ''

  private debounceSearch: any

  public componentDidMount() {
    this.debounceSearch = debounce(this.props.onChangeParams, 300)
  }

  public componentWillUnmount() {
    this.debounceSearch.cancel()
  }

  @catchAction
  @action
  public onSearch = ({ target }: any) => {
    this.search = target.value
    this.replaceHistory(this.search)
  }

  public replaceHistory = (s: string) => {
    const { search, pathname } = this.props.location
    const params: any = new URLSearchParams(search)
    params.set('search', s)
    replace(`${pathname}?${params.toString()}`)
    this.debounceSearch()
  }

  public render(): ReactNode {
    const { placeholder, cypressId } = this.props
    return (
      <div className="data-search">
        <div className="field-holder">
          <input
            data-cypressId={cypressId}
            type="text"
            className="input input-search"
            placeholder={placeholder}
            onChange={this.onSearch}
            value={this.search}
          />
          <label className="label label-search">
            <Icon iconClass="iconSearch" icon={SEARCH} />
          </label>
        </div>
      </div>
    )
  }
}

export default withRouter(DataSearch)
