import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import LabelEditor from '../../../../../../../components/Editor/LabelEditor'

const dictionary = {
  fr: {
    Label: 'Label'
  },
  en: {
    Label: 'Label'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits label for variables
 */
export const Label: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { label } = state.variable
  return (
    <div className="label-holder">
      <LabelEditor
        value={label}
        meta
        name="label"
        setFieldValue={(name, value) => {
          state.updateLabel(value)
        }}
        label={I18n.get('Label')}
      />
    </div>
  )
}
