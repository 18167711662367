import React from 'react'
import { I18n } from 'aws-amplify'
import createStore from '../../../store/editor/createStore'
import Select from '../../../pages/Editor/CreateContractPage/slate-editor/components/select/Select'
import { CONDITIONTREEICON } from '../../Icons/icons'
import Icon from '../../Icons/Icon'

const dictionary = {
  fr: {
    client_type: 'Type de client',
    strat: 'Le client doit être',
    individual: 'Toujours Particulier',
    professional: 'Toujours Professionnel',
    conditionnal: 'Selon conditions',
    pro_conditions:
      'Conditions pour que le client soit considéré comme professionnel'
  },
  en: {
    individual: 'Individual',
    professional: 'Professional',
    conditionnal: 'Conditionnal'
  }
}

I18n.putVocabularies(dictionary)

const clientTypeOptions = {
  ind: I18n.get('individual'),
  pro: I18n.get('professional'),
  conditionnal: I18n.get('conditionnal')
}
type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

type ClientTypeParamsModalStateType = {
  [key: string]: any
}

class ClientTypeParamsModal extends React.Component<
  ParamsModalTabPropsType,
  ClientTypeParamsModalStateType
> {
  constructor(props: ParamsModalTabPropsType) {
    super(props)

    const { getParams } = props

    this.state = {
      'meta.clientType.type':
        createStore.document.customization?.meta?.clientType?.type || 'ind',
      'meta.clientType.conditions':
        createStore.document.customization?.meta?.clientType?.conditions || {},
      ...getParams()
    }
  }

  updateParams = (newParams: Record<string, any>) => {
    const { setParams } = this.props

    this.setState({ ...this.state, ...newParams })
    setParams(newParams)
  }

  setFieldValue = (name: string, value: string | number | boolean) => {
    const newParams = {
      ...this.state,
      [name]: value
    }
    this.updateParams({
      ...newParams
    })
  }

  handleCondition = () => {
    const { toggleModelParamsWasShown } = createStore
    const { toggleConditionsDrawer, closeModal } = this.props
    closeModal(true)
    toggleModelParamsWasShown(true)
    toggleConditionsDrawer(true, 'client_type')
  }

  render() {
    const { conditionsCollector } = createStore
    let collectedConditions: any = []
    const conditions = this.state['meta.clientType.conditions']
    if (conditions) {
      collectedConditions = conditionsCollector(
        Object.keys(conditions),
        conditions
      )
    }

    return (
      <div>
        <div className="pm-block">
          <h3>{I18n.get('client_type')}</h3>
          <Select
            options={clientTypeOptions}
            value={this.state['meta.clientType.type']}
            onChange={(v) => this.setFieldValue('meta.clientType.type', v)}
          />
        </div>
        {this.state['meta.clientType.type'] === 'conditionnal' && (
          <div className="pm-block">
            <h3>{I18n.get('pro_conditions')}</h3>

            <div className="conditions-block">
              <div>
                <Icon
                  iconClass="tree-ico"
                  icon={CONDITIONTREEICON}
                  color="#e32778"
                />
                conditions:
                <span className="conditions-value">
                  {collectedConditions.length}
                </span>
              </div>
              <button
                type="button"
                onClick={this.handleCondition}
                className=" secondary"
              >
                {I18n.get('modify')}
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ClientTypeParamsModal
