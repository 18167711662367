import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    mandatory: 'Obligatoire'
  },
  en: {
    mandatory: 'Mandatory'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable second step
 */
export const Mandatory: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { mandatory } = state.variable

  return (
    <div className="step-wrapper">
      <div className="step-holder">
        <CheckboxField
          meta
          checked={mandatory && true}
          name="mandatory"
          setFieldValue={(name: string, value: boolean) =>
            state.updateMandatory(value)
          }
          label={I18n.get('mandatory')}
          value="mandatory"
        />
      </div>
    </div>
  )
}
