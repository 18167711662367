import { action, observable } from 'mobx'
import { catchAction } from './catchActionsDecorator'

export interface IUIStore {
  isDrawerOpen: boolean
  toggleDrawer(): void
}

class UIStore implements IUIStore {
  @observable isDrawerOpen = false

  @catchAction
  @action
  toggleDrawer = () => {
    this.isDrawerOpen = !this.isDrawerOpen
  }
}

const uiStore = new UIStore()
export default uiStore
