import React from 'react'
import ReactDOM from 'react-dom'

type IProps = {
  className?: string
  onClose?: () => void
  onSave?: () => void
}

class Portal extends React.PureComponent<IProps> {
  element: Element = document.createElement('div')

  componentDidMount() {
    const { className } = this.props
    if (typeof className === 'string') this.element.classList.add(className)
    document.body.appendChild(this.element)
  }

  componentWillUnmount() {
    document.body.removeChild(this.element)
  }

  handleOnSave() {
    if (typeof this.props.onSave === 'function') this.props.onSave()
  }

  handleOnClose() {
    if (typeof this.props.onClose === 'function') this.props.onClose()
  }

  childrenWithProps() {
    const { onSave, onClose, children } = this.props
    if (onSave === undefined && onClose === undefined) return children

    // Injecting onClose & onSave to children props
    return React.Children.map(children, (child) => {
      const props = {
        portalOnSave: onSave,
        portalOnClose: onClose
      }
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props)
      }
      return child
    })
  }

  render() {
    return ReactDOM.createPortal(this.childrenWithProps(), this.element)
  }
}

export default Portal
