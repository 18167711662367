import { Editor, Transforms } from '@legalplace/slate'
import { useSlate } from '@legalplace/slate-react'
import React, { useState } from 'react'
import createStore from '../../../../../../store/editor/createStore'
import EditorHelpers from '../../helpers/EditorHelpers'
import Modal from '../modal/Modal'
import Select from '../select/Select'
import Button from './Button'
import { ReactComponent as RepeatedIcon } from './repeated-icon.svg'

const RepeatedPopup: React.FC<{
  onCancel: (event: MouseEvent | React.MouseEvent) => void
  onSave: (event: MouseEvent | React.MouseEvent, value: string) => void
  currentValue: string
}> = ({ onCancel, onSave, currentValue }) => {
  const [value, setValue] = useState(currentValue)
  const { document } = createStore

  /**
   * Getting all repeated options
   */
  const options: Record<string, string> = {
    '0': '\xA0'
  }
  Object.keys(document.options)
    .filter((id) => {
      const { multiple } = document.options[id].meta
      return multiple && multiple.enabled === true
    })
    .forEach((id) => {
      options[id] = document.options[id].meta.label
    })

  /**
   * Handling save
   */
  const handleSave = (event: MouseEvent | React.MouseEvent) => {
    event.preventDefault()
    onSave(event, value)
  }

  return (
    <Modal
      onCancel={onCancel}
      onSave={handleSave}
      title="Sélectionnez un multiple dans la liste ci-dessous"
    >
      <Select options={options} value={value} onChange={setValue} />
    </Modal>
  )
}

const RepeatedButton = () => {
  const editor = useSlate()
  const [popup, togglePopup] = useState(false)
  const [repeated, setRepeated] = useState('')
  const active = EditorHelpers.isOutputRepeated(editor)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    const selectedRepeated = EditorHelpers.getCurrentOutputsRepeated(editor)
    if (selectedRepeated.length === 0) return

    setRepeated(
      selectedRepeated.reduce((p, c) => (p === c ? p : ''), selectedRepeated[0])
    )
    togglePopup(true)
  }

  const handleSave = (event: MouseEvent | React.MouseEvent, value: string) => {
    event.preventDefault()
    togglePopup(false)

    // Parsing repeat id
    const repeatId = parseInt(value, 10)

    // Getting outputs in selection
    const outputs = EditorHelpers.getCurrentOutputs(editor)
    if (outputs.length === 0) return
    const selectedOutputs = outputs.map((o) => o.id)

    // Repeat option
    const repeatOption = createStore.linkOutputsToMultiple(
      repeatId,
      selectedOutputs
    )

    Editor.withoutNormalizing(editor, () => {
      // Updating outputs nodes
      Transforms.setNodes(
        editor,
        {
          repeated: repeatOption === false ? undefined : repeatOption.meta.id,
          repeatedMeta:
            repeatOption === false
              ? undefined
              : JSON.parse(JSON.stringify(repeatOption.meta))
        },
        {
          match: (node) => selectedOutputs.includes(node.id)
        }
      )
    })
  }

  return (
    <>
      <Button
        active={active}
        onMouseDown={handleClick}
        className="repeated-output-cta"
      >
        <div className="icon-holder">
          <RepeatedIcon />
        </div>
        <div className="text">Multiple</div>
      </Button>
      {popup && (
        <RepeatedPopup
          onCancel={() => togglePopup(false)}
          onSave={handleSave}
          currentValue={repeated}
        />
      )}
    </>
  )
}

export default RepeatedButton
