import React, { useState, useEffect } from 'react'
import { reaction } from 'mobx'
import { I18n } from 'aws-amplify'
import createStore from '../../../../store/editor/createStore'

type IProps = {
  index: number
  destParent: number
  destParentType: 'option' | 'section' | 'variable'
  parents: number[]
}

const dictionary = {
  fr: {
    move_here: 'Déplacer ici'
  },
  en: {
    move_here: 'Move here'
  }
}

I18n.putVocabularies(dictionary)

/**
 * Determines whether current element moving CTA should display or not
 * @param index
 * @param destParent
 * @param destParentType
 * @param parents
 */
const shouldDisplay: (
  index: number,
  destParent: number,
  destParentType: 'option' | 'section' | 'variable',
  parents: number[]
) => boolean = (index, destParent, destParentType, parents) => {
  const { document } = createStore
  // Checking whether we should display here
  const {
    isMoving,
    type,
    id,
    currentParent,
    currentParentType
  } = createStore.moveElement

  let result = true

  // Getting option

  // If no element is being moved, we do not display the cta
  if (isMoving === false) result = false
  // If we're moving current variable/option or one of
  // its parents we should not display the cta
  else if (parents.includes(id)) result = false
  // If we're moving a "multiple" option and destinationType is
  // an option, we should not display the cta
  else if (
    destParentType === 'option' &&
    type === 'option' &&
    document.options[id].meta?.multiple?.enabled === true
  )
    result = false
  // If we're moving a radio option and current dest type is
  // section we should not display the cta
  else if (
    destParentType === 'section' &&
    type === 'option' &&
    document.options[id].meta.type === 'radio'
  )
    result = false
  /**
   * If current element is a variable and current destination is a section
   * or an option we do not display the cta
   */ else if (type === 'variable' && destParentType === 'section')
    result = false
  /**
   * If we're moving an option and current destination is variable
   * we should not display
   */ else if (type === 'option' && destParentType === 'variable')
    result = false
  /**
   * If we're moving a variable and current destination is option or section
   * we should not display
   */ else if (
    type === 'variable' &&
    ['option', 'section'].includes(destParentType)
  )
    result = false
  /**
   * If destination parent is an option of type other then radio,
   * checkbox, static, we don't display the cta
   */ else if (
    destParentType === 'option' &&
    !['static', 'radio', 'checkbox'].includes(
      document.options[destParent].meta.type
    )
  )
    result = false
  /**
   * If current destination is the same current element's position,
   * we don't display the cta's
   *
   * KEEP THIS ONE AT LAST
   */ else if (
    destParentType === currentParentType &&
    destParent === currentParent
  ) {
    if (destParentType === 'section') {
      const { sections } = document.documents.main

      // Filtring options according to ContractFrom (in ContractForm.renderList)
      // in order to have the same indexes
      const currentParentOptions = sections[destParent].options

      // Getting the index of current element
      const currentParentIndex = currentParentOptions.indexOf(id)
      if (currentParentIndex === index || currentParentIndex + 1 === index)
        result = false
    } else if (destParentType === 'option') {
      const { options } = document

      // Filtring options according to ContractFrom (in ContractForm.renderList)
      // in order to have the same indexes
      const currentParentOptions = options[destParent].options

      // Getting the index of current element
      const currentParentIndex = currentParentOptions.indexOf(id)
      if (currentParentIndex === index || currentParentIndex + 1 === index)
        result = false
    }
  }

  return result
}

const MoveElement: React.FC<IProps> = ({
  index,
  destParent,
  destParentType,
  parents
}) => {
  const [display, setDisplay] = useState<boolean>(
    shouldDisplay(index, destParent, destParentType, parents)
  )

  useEffect(
    () =>
      reaction(
        () => createStore.moveElement,
        () => {
          setDisplay(shouldDisplay(index, destParent, destParentType, parents))
        }
      ),
    [index, destParent, destParentType, parents]
  )

  if (display === false) return <></>

  const handleClick = () => {
    createStore.handleMoveElement(index, destParent, destParentType)
  }
  return (
    <div
      className={`move-element-placeholder${
        destParentType === 'variable' ? ' variable' : ''
      }`}
      onClick={handleClick}
    >
      <span>{I18n.get('move_here')}</span>
    </div>
  )
}

export default MoveElement
