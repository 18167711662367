/* eslint-disable react/no-unused-state */
import React from 'react'
import { SectionV3 } from '@legalplace/models-v3-types'
import { cloneDeep } from 'lodash'
import { action } from 'mobx'
import createStore from '../../../../../../store/editor/createStore'
import { catchAction } from '../../../../../../store/editor/catchActionsDecorator'

class SectionEditor extends React.Component<IProps, SectionEditorStateType> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      id: props.section.id,
      section: cloneDeep(props.section),

      updateLabel: this.updateLabel,
      updateConditions: this.updateConditions,
      updateStep: this.updateStep,
      updateGrantLevel: this.updateGrantLevel,

      toggleConditionsDrawer: props.toggleConditionsDrawer,
      toggleDrawer: props.toggleDrawer
    }
  }

  /**
   * Saves current updates
   */
  @catchAction
  @action
  saveUpdates = () => {
    const { sections } = createStore.document.documents.main
    const sectionIndex = sections.findIndex(
      (section) => section.id === this.state.id
    )
    const originalSection = sections[sectionIndex]
    sections[sectionIndex] = {
      ...originalSection,
      ...this.state.section
    }
    this.props.toggleDrawer(false)
  }

  /**
   * Deletes section
   */
  deleteSection = () => {
    const { toggleDrawer } = this.props
    const { toggleDeleteValidationModal } = createStore

    toggleDeleteValidationModal(true, { section: true }, () => {
      toggleDrawer(false)
    })
  }

  /**
   * Moves section
   */
  moveSection = () => {
    // open move modal
    const { toggleModal } = createStore
    toggleModal('moveIsOpen', true)
  }

  /**
   * Duplicates section
   */
  duplicateSection = () => {
    // open move modal
    const { duplicateSection } = createStore
    const { toggleDrawer } = this.props

    // duplicate section
    duplicateSection(this.state.id)

    // close drawer
    toggleDrawer(false)
  }

  /**
   * Closes drawer
   */
  closeDrawer = () => {
    this.props.toggleDrawer(false)
  }

  /**
   * Updates section label
   * @param label Section's label
   */
  updateLabel = (label: SectionV3['label']) => {
    this.setState({
      section: {
        ...this.state.section,
        label
      }
    })
  }

  /**
   * Updates section conditions
   * @param conditions Section's conditions
   */
  updateConditions = (conditions: SectionV3['conditions']) => {
    this.setState({
      section: {
        ...this.state.section,
        conditions
      }
    })
  }

  /**
   * Updates section step
   * @param step Section's step
   */
  updateStep = (step: SectionV3['step']) => {
    this.setState({
      section: {
        ...this.state.section,
        step
      }
    })
  }

  /**
   * Updates section grantLevel
   * @param grantLevel Section's grantLevel
   */
  updateGrantLevel = (grantLevel: SectionV3['grantLevel']) => {
    this.setState({
      section: {
        ...this.state.section,
        grantLevel
      }
    })
  }

  render() {
    return <></>
  }
}

interface IProps {
  section: SectionV3
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
  toggleDrawer(value: boolean): void
}

export interface SectionEditorStateType {
  id: number
  section: SectionV3
  updateLabel: typeof SectionEditor.prototype.updateLabel
  updateConditions: typeof SectionEditor.prototype.updateConditions
  updateStep: typeof SectionEditor.prototype.updateStep
  updateGrantLevel: typeof SectionEditor.prototype.updateGrantLevel

  toggleConditionsDrawer(
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ): void
  toggleDrawer(value: boolean): void
}

export default SectionEditor
