import { Transforms } from '@legalplace/slate'
import { useSlate } from '@legalplace/slate-react'
import React, { MouseEvent, useRef, useState } from 'react'
import TableHelpers from '../../helpers/TableHelpers'
import Button from './Button'
import Icon from './Icon'
import './tableblock.scss'

const isDescendant = (parent: HTMLElement, element: HTMLElement) => {
  let node = element.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

let bodyEventListener:
  | ((bodyEvent: globalThis.MouseEvent) => void)
  | null = null

const TableBlockButton = () => {
  const icon = 'border_all'
  const tableBlockRef = useRef<HTMLElement>(null)
  const [minRows, minCols] = [6, 6]
  const [maxRows, maxCols] = [20, 20]
  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState(minRows)
  const [cols, setCols] = useState(minCols)
  const [currentHover, setCurrentHover] = useState([0, 0])
  const editor = useSlate()

  const close = () => {
    if (bodyEventListener !== null)
      document.body.removeEventListener('click', bodyEventListener)
    setOpen(false)
  }

  if (bodyEventListener === null)
    bodyEventListener = (bodyEvent: globalThis.MouseEvent) => {
      const target = bodyEvent.target as HTMLElement
      if (target === null || tableBlockRef.current === null) {
        close()
      } else if (
        target === tableBlockRef.current ||
        isDescendant(tableBlockRef.current, target)
      ) {
        bodyEvent.preventDefault()
      } else {
        close()
      }
    }

  const handleMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()

    if (open === false && bodyEventListener !== null) {
      setOpen(true)
      document.body.addEventListener('click', bodyEventListener)
    } else {
      close()
    }
  }

  const handleColumnHover = (coordinates: [number, number]) => {
    const [rowIndex, colIndex] = coordinates
    setCurrentHover(coordinates)

    const currentRow = rowIndex + 1
    const currentCol = colIndex + 1

    if (currentRow >= minRows) {
      if (currentRow < maxRows) setRows(currentRow + 1)
    } else {
      setRows(minRows)
    }

    if (currentCol >= minCols) {
      if (currentCol < maxCols) setCols(currentCol + 1)
    } else {
      setCols(minCols)
    }
  }

  const insertTableNode = ([insertRown, insertColumns]: [number, number]) => {
    if (editor.selection === null) return

    const tableNode = {
      type: 'table',
      style: {
        tableLayout: 'fixed',
        width: '100%',
        borderSpacing: 0
      },
      children: [
        {
          type: 'table-body',
          children: new Array(insertRown).fill(0).map((a, rowIndex) => ({
            type: 'table-row',
            children: new Array(insertColumns).fill(0).map((b, colIndex) => {
              let borderTop = {
                borderTopWidth: 'inherit',
                borderTopStyle: 'inherit',
                borderTopColor: 'inherit'
              }
              let borderBottom = {
                borderBottomWidth: 'inherit',
                borderBottomStyle: 'inherit',
                borderBottomColor: 'inherit'
              }
              let borderLeft = {
                borderLeftWidth: 'inherit',
                borderLeftStyle: 'inherit',
                borderLeftColor: 'inherit'
              }
              let borderRight = {
                borderRightWidth: 'inherit',
                borderRightStyle: 'inherit',
                borderRightColor: 'inherit'
              }

              borderTop = {
                borderTopWidth: '1px',
                borderTopStyle: 'solid',
                borderTopColor: '#000'
              }

              if (rowIndex + 1 === insertRown)
                borderBottom = {
                  borderBottomWidth: '1px',
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#000'
                }

              borderLeft = {
                borderLeftWidth: '1px',
                borderLeftStyle: 'solid',
                borderLeftColor: '#000'
              }

              if (colIndex + 1 === insertColumns)
                borderRight = {
                  borderRightWidth: '1px',
                  borderRightStyle: 'solid',
                  borderRightColor: '#000'
                }

              return {
                type: 'table-cell',
                style: {
                  padding: '4px',
                  ...borderTop,
                  ...borderBottom,
                  ...borderLeft,
                  ...borderRight
                },
                children: [{ text: '' }]
              }
            })
          }))
        }
      ]
    }

    Transforms.insertNodes(
      editor,
      tableNode,
      TableHelpers.isCell(editor)
        ? {
            at: editor.selection.anchor.path
          }
        : {}
    )

    // Moving selection to node
    close()
  }

  const rowsArray = new Array(rows).fill(0)
  const colsArray = new Array(cols).fill(0)

  return (
    <span
      className={`table-block-cta${open ? ' open' : ''}`}
      ref={tableBlockRef}
    >
      <Button active={open} onMouseDown={handleMouseDown}>
        <Icon>{icon}</Icon>
      </Button>
      <div className="dropdown">
        <div className="table">
          {rowsArray.map((u, rowIndex) => {
            return (
              <div className="row" key={`table-row-${rowIndex}`}>
                {colsArray.map((v, colIndex) => {
                  const isActive =
                    rowIndex <= currentHover[0] && colIndex <= currentHover[1]
                  return (
                    <div
                      className={`column${isActive ? ' active' : ''}`}
                      key={`table-row-${rowIndex}-col-${colIndex}`}
                      onMouseDown={() =>
                        insertTableNode([rowIndex + 1, colIndex + 1])
                      }
                      onMouseOver={() =>
                        handleColumnHover([rowIndex, colIndex])
                      }
                    />
                  )
                })}
              </div>
            )
          })}
        </div>
        <div className="table-size">{`${currentHover[1] + 1}x${
          currentHover[0] + 1
        }`}</div>
      </div>
    </span>
  )
}

export default TableBlockButton
