import React from 'react'
import { I18n } from 'aws-amplify'

const dictionary = {
  fr: {
    "You are trying to edit a published model, this action is dangerous and can corrupt client's documents":
      'Vous tentez de modifier un modèle publié, cette action est dangereuse et peut corrompre les documents des clients',
    'If you still want to edit this model, please type "EDIT" here and click Save, otherwise just click Close':
      'Si vous souhaitez quand même modifier ce modèle, veuillez écrire "MODIFIER" ci-dessous et cliquer sur Enregistrer, sinon cliquez sur Fermer'
  },
  en: {}
}

I18n.putVocabularies(dictionary)

export const PromptPublishedContent = () => {
  return (
    <p>
      {I18n.get(
        "You are trying to edit a published model, this action is dangerous and can corrupt client's documents"
      )}
      <br />
      <b>
        {I18n.get(
          'If you still want to edit this model, please type "EDIT" here and click Save, otherwise just click Close'
        )}
      </b>
    </p>
  )
}
