import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    decimals: 'Décimales'
  },
  en: {
    decimals: 'Decimals'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable's eval decimals
 */
export const EvalDecimals: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { decimals } = state.variable.eval || { decimals: 2 }
  return (
    <div className="label-holder">
      <Input
        value={decimals}
        name="eval_decimals"
        type="number"
        setFieldValue={(name: string, value: string | number) => {
          state.updateEvalDecimals(
            typeof value === 'number' ? value : parseInt(value, 10)
          )
        }}
        label={I18n.get('decimals')}
      />
    </div>
  )
}
