import React, { CSSProperties, useState, useRef, useEffect } from 'react'
import { getVariableParentTree } from '../../variableUtils/readers/variable'
import ParentTree from '../../variableUtils/components/ParentTree'

const VariableLeaf: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [pullTop, setPullTop] = useState<boolean>(false)
  const rootParentTree = useRef<HTMLDivElement>(null)
  const style: CSSProperties = {
    fontWeight: props.leaf.bold ? 'bold' : undefined,
    fontStyle: props.leaf.italic ? 'italic' : undefined,
    textDecoration: props.leaf.underlined ? 'underline' : undefined
  }

  let parentTree
  try {
    parentTree = getVariableParentTree(props.leaf.id)
  } catch (e) {
    console.error(e)
    parentTree = undefined
  }

  const positionParentTree = () => {
    if (rootParentTree.current !== null) {
      const screenHeight = window.innerHeight
      const { top, height } = rootParentTree.current.getBoundingClientRect()
      const margin = 20

      if (top + height + margin > screenHeight && pullTop === false)
        setPullTop(true)
      else if (
        pullTop === true &&
        height !== 0 &&
        top + height + height + 50 + margin <= screenHeight
      )
        setPullTop(false)
    }
  }

  useEffect(() => {
    positionParentTree()
    window.addEventListener('scroll', () => positionParentTree())
  })

  return (
    <span
      {...props.attributes}
      spellCheck={false}
      className={`variable-leaf${isHovered ? ' controlled-hover' : ''}${
        pullTop ? ' pull-top' : ''
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseMove={(e) =>
        e.target !== rootParentTree.current
          ? setIsHovered(true)
          : setIsHovered(false)
      }
      onMouseLeave={() => setIsHovered(false)}
      style={style}
    >
      {props.children}
      <div
        className="variable-parent-tree"
        onMouseUp={(e) => e.preventDefault()}
        onClick={(e) => e.preventDefault()}
        onMouseDown={(e) => e.preventDefault()}
        ref={rootParentTree}
        contentEditable={false}
      >
        <div className="container">
          {parentTree && parentTree.length > 0 && (
            <ParentTree currentIndex={0} tree={parentTree} />
          )}
          {!parentTree && 'Parent tree undefined'}
        </div>
      </div>
    </span>
  )
}

export default VariableLeaf
