import { OptionV3, VariableV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'

const dictionary = {
  fr: {
    text: 'Boite info',
    question: 'Question',
    field: 'Champ',
    dropdown: 'Liste déroulante',
    checkbox: 'Case à cocher',
    radio: 'Radio'
  },
  en: {
    text: 'Text',
    question: 'Question',
    field: 'Field',
    dropdown: 'Dropdown',
    checkbox: 'Checkbox',
    radio: 'Radio'
  }
}

I18n.putVocabularies(dictionary)

const field: VariableV3 = {
  type: 'text',
  id: 0,
  label: I18n.get('field'),
  step: '*',
  mandatory: false,
  placeholder: ''
}
const text: OptionV3 = {
  meta: {
    type: 'box',
    id: 0,
    step: '*',
    box: {
      type: 'light',
      content: I18n.get('text')
    },
    label: ''
  },
  options: [],
  variables: []
}
const question: OptionV3 = {
  meta: {
    type: 'static',
    id: 0,
    label: I18n.get('question'),
    step: '*'
  },
  options: [],
  variables: []
}
const checkbox: OptionV3 = {
  meta: {
    type: 'checkbox',
    id: 0,
    step: '*',
    label: I18n.get('checkbox')
  },
  options: [],
  variables: []
}
const radioBox: OptionV3 = {
  meta: {
    type: 'radio',
    id: 0,
    step: '*',
    label: I18n.get('radio')
  },
  options: [],
  variables: []
}
const dropdownMenu: VariableV3 = {
  type: 'list',
  id: 0,
  label: I18n.get('dropdown'),
  step: '*',
  selectValues: ['']
}

export { field, text, question, checkbox, radioBox, dropdownMenu }
