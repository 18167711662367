import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import VariableContext from '../editors/VariableContext'
import { Prefillings } from './components/Prefillings'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: {
    prefillings_category: 'Pré-remplissage'
  },
  en: {
    prefillings_category: 'Préfilling'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Prefillings variable params
 */
const PrefillingsVariableParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <VariableContext.Consumer>
      {(state) => {
        const toggleCollapsed = () => setCollapsed(!collapsed)

        const { type } = state.variable

        if (['eval'].includes(type)) return null

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('prefillings_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <div>
                <Prefillings state={state} />
              </div>
            )}
          </div>
        )
      }}
    </VariableContext.Consumer>
  )
}

export default PrefillingsVariableParams
