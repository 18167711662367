import React from 'react'
import moment from 'moment'
import { INote } from '../../../store/editor/createStore'

interface NoteProps {
  note: INote
}

const Note: React.FC<NoteProps> = ({ note }) => {
  const date = moment(note.timestamp).format('DD/MM/YYYY - HH:mm')
  return (
    <div className="modelnotes-note">
      <div
        className="modelnotes-notecontent"
        dangerouslySetInnerHTML={{ __html: note.content }}
      />
      <div className="modelnotes-notehead">
        <div className="modelnotes-author">{note.author}</div>
        <div className="modelnotes-date">{date}</div>
      </div>
    </div>
  )
}

export default Note
