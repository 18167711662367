import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    'Box position': 'Position des boites info'
  },
  en: {
    'Box position': 'Box position'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits label for question, radio & checkbox
 */
export const Style: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const { style } = state.option.meta
  const { optionStyleOptions } = createStore
  return (
    <div className="label-holder">
      <SelectField
        setFieldValue={(name: string, value: '' | 'half' | 'full') =>
          state.updateStyle(value)
        }
        value={style || ''}
        name="style"
        label={I18n.get('Box position')}
        options={optionStyleOptions}
      />
    </div>
  )
}
