import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    autocomplete: 'Saisie automatique'
  },
  en: {
    autocomplete: 'Autocomplete'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable's autocomplete
 */
export const Autocomplete: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { style } = state.variable
  const { autocompleteOptions } = createStore
  return (
    <div className="label-holder">
      <SelectField
        setFieldValue={(name: string, value: string) =>
          state.updateAutocomplete(value)
        }
        value={style || ''}
        name="autocomplete"
        label={I18n.get('autocomplete')}
        options={autocompleteOptions}
      />
    </div>
  )
}
