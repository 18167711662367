import { KeyboardEvent as KeyboardEventType } from 'react'
import { Editor } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import EditorHelpers from '../helpers/EditorHelpers'
import handleVariableDelete from '../variableUtils/handleVariableDelete'
import { openVariablePopover } from '../variableUtils/handleInsertVariable'

type KeyEventsType = {
  [key: string]: (
    editor: Editor & ReactEditor,
    event: KeyboardEventType<HTMLDivElement>
  ) => void
}

const keyEvents: KeyEventsType = {
  b: (editor, event) => {
    event.preventDefault()
    EditorHelpers.toggleMark(editor, 'bold')
  },
  i: (editor, event) => {
    event.preventDefault()
    EditorHelpers.toggleMark(editor, 'italic')
  },
  u: (editor, event) => {
    event.preventDefault()
    EditorHelpers.toggleMark(editor, 'underlined')
  },
  h: (editor, event) => {
    event.preventDefault()
    EditorHelpers.toggleBlockStyle(editor, 'textAlign', 'center')
  },
  z: (editor, event) => {
    event.preventDefault()
  },
  y: (editor, event) => {
    event.preventDefault()
  }
}

/**
 * Handles keydown events
 *
 * @param event Keyboard event
 * @param editor Editor's instance
 */
export const handleKeyDown = (
  event: KeyboardEventType<HTMLDivElement>,
  editor: Editor & ReactEditor
) => {
  /** Adding variables */
  if (event.keyCode === 8) {
    handleVariableDelete(editor, event)
  } else if (event.key === '#') {
    openVariablePopover(editor, event)
  } else if (event.ctrlKey === true || event.metaKey === true) {
    /**
     * CTRL/CMD Shortcuts
     */
    if (Object.prototype.hasOwnProperty.call(keyEvents, event.key)) {
      keyEvents[event.key](editor, event)
    }
  } else if (event.keyCode === 32) {
    /**
     * Space
     */
    event.preventDefault()
    editor.insertText('\xA0')
  } else if (event.keyCode === 9) {
    /**
     * Tab
     */
    event.preventDefault()
    editor.insertText('\xA0\xA0\xA0\xA0')
  }
}
