import React, { Component } from 'react'
import './style.scss'
import Icon from '../../Icons/Icon'
import { EYEHIDEICON } from '../../Icons/icons'

type LabelEditorPropsType = {
  value: any
  label?: string
  meta?: boolean
  name?: string
  el?: any
  borderless?: boolean
  conditionTag?: boolean
  multipleTag?: boolean
  warningTag?: boolean
  helperTag?: boolean
  hiddenTag?: boolean
  secondStepOnlyTag?: boolean
  setFieldValue?: (name: string, value: any, meta?: boolean, el?: any) => void
  onChange?: (e: { target: { value: string } }) => void
}

type LabelEditorStateType = {
  value: string
}

class LabelEditor extends Component<
  LabelEditorPropsType,
  LabelEditorStateType
> {
  editableRef = React.createRef<HTMLSpanElement>()

  constructor(props: LabelEditorPropsType) {
    super(props)

    const value = props.value.toString()

    this.state = {
      value
    }
  }

  shouldComponentUpdate(newProps: LabelEditorPropsType) {
    return (
      newProps.value !== this.state.value ||
      this.props.conditionTag !== newProps.conditionTag ||
      this.props.helperTag !== newProps.helperTag ||
      this.props.warningTag !== newProps.warningTag ||
      this.props.hiddenTag !== newProps.hiddenTag ||
      this.props.secondStepOnlyTag !== newProps.secondStepOnlyTag
    )
  }

  onInput = (e: React.FormEvent<HTMLSpanElement>) => {
    const { name, setFieldValue, onChange, meta, el } = this.props
    const value = e.currentTarget.innerText

    this.setState(
      {
        value
      },
      () => {
        // Triggering setFieldValue callback
        if (typeof setFieldValue === 'function' && typeof name === 'string')
          setFieldValue(name, value, meta, el)

        // Triggering onChange callback
        if (typeof onChange === 'function') onChange({ target: { value } })
      }
    )
  }

  onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') e.preventDefault()
  }

  onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    // get text representation of clipboard
    const text = e.clipboardData.getData('text/plain')

    // insert text manually
    document.execCommand('insertHTML', false, text)
  }

  handleWrapperClick = () => {
    if (this.editableRef.current !== null) this.editableRef.current.focus()
  }

  render() {
    const className = ['label-editor', !this.props.borderless && 'inputBox']
      .filter((v): v is string => typeof v === 'string')
      .join(' ')

    return (
      <div className="custom-input">
        {this.props.label ? (
          <h5 style={{ margin: 0 }} className="text">
            {this.props.label}
          </h5>
        ) : (
          ''
        )}
        <div className={className} onClick={this.handleWrapperClick}>
          <span
            contentEditable
            ref={this.editableRef}
            suppressContentEditableWarning
            onPaste={this.onPaste}
            onInput={this.onInput}
            onKeyPress={this.onKeyPress}
          >
            {this.props.value}
          </span>
          {this.props.conditionTag && <span className="conditionTag">C</span>}
          {this.props.multipleTag && <span className="multipleTag">M</span>}
          {this.props.warningTag && <span className="warningTag">!</span>}
          {this.props.helperTag && <span className="helperTag">?</span>}
          {this.props.hiddenTag && (
            <span className="hiddenTag">
              &nbsp;
              <Icon iconClass="tree-ico" icon={EYEHIDEICON} color="#000" />
            </span>
          )}
          {this.props.secondStepOnlyTag && (
            <span className="secondStepOnlyTag">Q2</span>
          )}
        </div>
      </div>
    )
  }
}

export default LabelEditor
