import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import '../../assets/scss/main.scss'
import { Route } from 'react-router'
import 'react-toastify/dist/ReactToastify.css'
import HeaderEditor from '../../components/Editor/Header/Header'
import DashboardPage from './DashboardPage/DashboardPage'
import NewContractPage from './NewContractPage/NewContractPage'
import ModelsPage from './ModelsPage/ModelsPage'
import EditContractPage from './EditContractPage/EditContractPage'
import CreateContractPage from './CreateContractPage/CreateContractPage'

@observer
class EditorContainer extends Component<any, any> {
  public render(): ReactNode {
    return (
      <>
        <HeaderEditor />
        <>
          <Route exact path="/contracts" component={DashboardPage} />
          <Route
            exact
            path="/contracts/create/:permalink/:id"
            component={CreateContractPage}
          />
          <Route
            exact
            path="/contracts/create/:permalink/"
            component={CreateContractPage}
          />
          <Route exact path="/contracts/new" component={NewContractPage} />
          <Route
            exact
            path="/contracts/models/:contractId"
            component={ModelsPage}
          />
          <Route
            exact
            path="/contracts/edit/:contractId"
            component={EditContractPage}
          />
          {/* <Redirect to="/contracts"/> */}
        </>
      </>
    )
  }
}

export default EditorContainer
