import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    suffix: 'Suffix'
  },
  en: {
    suffix: 'Suffix'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable's suffix
 */
export const Suffix: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { suffix } = state.variable || { suffix: '' }

  return (
    <Input
      value={suffix || ''}
      name="suffix"
      setFieldValue={(name: string, value: string) => {
        state.updateSuffix(value)
      }}
      label={I18n.get('suffix')}
    />
  )
}
