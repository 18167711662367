import React from 'react'

export const TableElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <table style={props.element.style} {...props.attributes}>
      {props.children}
    </table>
  )
}

export const TableBodyElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <tbody style={props.element.style} {...props.attributes}>
      {props.children}
    </tbody>
  )
}

export const TableRowElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <tr style={props.element.style} {...props.attributes}>
      {props.children}
    </tr>
  )
}

export const TableCellElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <td style={props.element.style} {...props.attributes}>
      {props.children}
    </td>
  )
}
