import React, { Component, ReactNode } from 'react'
import { I18n } from 'aws-amplify'

import './style.scss'

interface ISearchProps {
  handleChange: Function
}

class Search extends Component<ISearchProps> {
  public changeSearchString = (e: any): void => {
    const { value } = e.target
    this.props.handleChange(value)
  }

  public render(): ReactNode {
    return (
      <div className="filter">
        <label>{`${I18n.get('Research')}:`}</label>
        <input
          type="text"
          id="filter"
          placeholder={I18n.get('Research')}
          name="filter"
          className="ng-pristine ng-untouched ng-valid"
          onChange={this.changeSearchString}
        />
      </div>
    )
  }
}

export default Search
