import React from 'react'
import ReactDOM from 'react-dom'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import { Router } from 'react-router-dom'
// import * as Sentry from '@sentry/browser'
import App from './App'
import * as serviceWorker from './serviceWorker'
import stores from './store'
import { history } from './utils/history'
import { setLanguage } from './utils/localization'
import './index.css'

// Sentry.init({
//   dsn:
//     'https://78879c928da64cc6b3fa52eea761b505@o309372.ingest.sentry.io/5237032',
//   release: process.env.REACT_APP_VERSION || 'unknown',
//   environment: process.env.REACT_APP_ENVIRONMENT || 'unknown'
// })

configure({
  enforceActions: 'observed'
})

setLanguage()

ReactDOM.render(
  <Provider {...stores}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
