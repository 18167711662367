import { observable } from 'mobx'

interface IGeneralStore {
  isSignedIn: boolean
}

class GeneralStore implements IGeneralStore {
  @observable public isSignedIn = true
}

const generalStore = new GeneralStore()
export default generalStore
