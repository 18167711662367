import React, { Component, ReactNode } from 'react'
import { FadeLoader } from 'react-spinners'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { observable, action } from 'mobx'
import Icon from '../../../components/Icons/Icon'
import { DOCUMENTS } from '../../../components/Icons/icons'
import styles from './NewFile.module.scss'
import { primary } from '../../../constants/scheme'
import NewContractCdiModal from './NewContractCdiModal'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

const columns = {
  en: {
    file_title: 'New Contract',
    file_search: 'Search ...',
    file_items_names: 'Categories',
    file_model: 'Model'
  },
  fr: {
    file_title: 'Nouveau contrat',
    file_search: 'Search ...',
    file_items_names: 'Categories',
    file_model: 'Modèle'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'userStore')
@observer
class NewFilePage extends Component<any> {
  @observable id: number = -1

  @observable meta_permalink: string = ''

  @observable name: string = ''

  UNSAFE_componentWillMount() {
    this.onChangeParams()
  }

  public onChangeParams = () => {
    const { location, dataStore } = this.props
    const params: any = new URLSearchParams(location.search)
    dataStore.fetchHomeCategories(params)
  }

  @catchAction
  @action
  openModal = (id: number, meta_permalink: string, name: string) => {
    const { toggleContractCDIModal } = this.props.dataStore
    this.id = id
    this.meta_permalink = meta_permalink
    this.name = name
    toggleContractCDIModal(true)
  }

  public render(): ReactNode {
    const {
      homeCategoriesList,
      fetching,
      error,
      toggleContractCDIModal,
      contractCDIModalIsOpen
    } = this.props.dataStore
    const { id: userId } = this.props.userStore.userDetail
    const categoriesOptions =
      homeCategoriesList &&
      homeCategoriesList
        .slice()
        .sort((a: any, b: any) =>
          a.category_name.localeCompare(b.category_name)
        )
    return (
      <div className="content newFilePageContainer">
        <div className="padding-container ttl-padding-container nfTtl">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('file_title')}</h3>
          </div>
          {/* <DataSearch onChangeParams={this.onChangeParams} placeholder={I18n.get('file_search')}/> */}
        </div>

        <div className="padding-container">
          <div className="category-container">
            <FadeLoader color={primary} loading={fetching} />
            {error && <div>Error - {error}</div>}

            {categoriesOptions.map(
              ({ category_id, category_name, list }: any) => {
                return (
                  <div className={styles.categoryHolder} key={category_id}>
                    <h2 className={styles.ttl}>{category_name}</h2>
                    <div className={styles.folders}>
                      {list
                        .slice()
                        .sort((a: any, b: any) =>
                          a.meta_name.localeCompare(b.meta_name)
                        )
                        .map(
                          ({
                            model_id,
                            meta_name,
                            model_version,
                            meta_permalink
                          }: any) => {
                            return (
                              <div
                                onClick={() =>
                                  this.openModal(
                                    model_version,
                                    meta_permalink,
                                    meta_name
                                  )
                                }
                                key={model_id}
                                className={styles.folder}
                              >
                                <Icon
                                  iconWrapperClass={styles.iconWrapper}
                                  iconClassDefault={styles.icon}
                                  iconClass="iconDocuments"
                                  icon={DOCUMENTS}
                                />
                                <div className={styles.textWrapper}>
                                  <h3 className={styles.ttl}>{meta_name}</h3>
                                  <h5 className={styles.descr}>
                                    {I18n.get('file_model')} {model_version}
                                  </h5>
                                </div>
                              </div>
                            )
                          }
                        )}
                    </div>
                  </div>
                )
              }
            )}
          </div>
        </div>
        <NewContractCdiModal
          name={this.name}
          userId={userId}
          permalink={this.meta_permalink}
          id={this.id}
          open={contractCDIModalIsOpen}
          toggleModal={toggleContractCDIModal}
        />
      </div>
    )
  }
}

export default NewFilePage
