import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'
import Menu from '../Menu/Menu'
import Logo from '../../assets/images/logo.svg'

interface IHeaderProps {
  showMenu: boolean
}

const columns = {
  fr: {
    logo: 'LOGO'
  },
  en: {
    logo: 'Logotype'
  }
}

I18n.putVocabularies(columns)

@observer
class Header extends Component<IHeaderProps> {
  public render(): ReactNode {
    const { showMenu } = this.props
    return (
      <header className="header">
        <div className="logo-wrapper">
          <Link to="/">
            {/* {I18n.get('logo')} */}
            <img src={Logo} alt={I18n.get('logo')} />
          </Link>
        </div>
        {showMenu && <Menu />}
      </header>
    )
  }
}

export default Header
