import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    'Input Style': 'Largeur du champ'
  },
  en: {
    'Input Style': 'Input Style'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits label for question, radio & checkbox
 */
export const Style: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { style } = state.variable
  const { inputStyleOptions } = createStore
  return (
    <div className="label-holder">
      <SelectField
        setFieldValue={(name: string, value: '' | 'half' | 'full') => {
          state.updateStyle(value === '' ? undefined : value)
        }}
        value={style || ''}
        name="style"
        label={I18n.get('Input Style')}
        options={inputStyleOptions}
      />
    </div>
  )
}
