import { Editor, Transforms, Path } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import { KeyboardEvent } from 'react'
import EditorHelpers from '../helpers/EditorHelpers'
import createStore from '../../../../../store/editor/createStore'
import { smallLabel } from './helpers'

const putSelection = (
  editor: Editor & ReactEditor,
  path: Path,
  offset: number
) => {
  const { selection } = editor
  if (selection) {
    ReactEditor.focus(editor)
    editor.apply({
      type: 'set_selection',
      properties: {
        ...selection
      },
      newProperties: {
        anchor: {
          path,
          offset
        },
        focus: {
          path,
          offset
        }
      }
    })
  }
}

const handleInsertVariable = (editor: Editor & ReactEditor) => (
  variableId: number | false
) => {
  createStore.toggleSlateEditorVariablePopover(false)
  EditorHelpers.applySelection(editor)
  const [hookNode, hookPath] = EditorHelpers.getCurrentLastNodeOfType(
    editor,
    'variable-hook'
  )
  Editor.withoutNormalizing(editor, () => {
    if (hookPath && hookNode) {
      // Deleting hook node
      Transforms.removeNodes(editor, {
        at: hookPath
      })

      // If no variable was inserted, we put
      // back the hashtag
      if (variableId === false) {
        Transforms.insertNodes(
          editor,
          [
            {
              text: '#'
            }
          ],
          {
            at: hookPath
          }
        )
        putSelection(editor, hookPath, 1)
      } else {
        const variable = createStore.document.variables[variableId]
        const label =
          variable && variable.label
            ? `#${variableId} ${smallLabel(variable.label)}`
            : `[var:${variableId}]`
        const variableNode = {
          type: 'variable',
          id: variableId,
          text: label
        }
        Transforms.insertNodes(
          editor,
          [
            variableNode,
            {
              text: '\uFEFF'
            }
          ],
          {
            at: hookPath
          }
        )
        const selectionPath = [
          ...hookPath.slice(0, -1),
          hookPath.slice(-1)[0] + 1
        ]
        putSelection(editor, selectionPath, 1)
      }
    }
  })
}

const openVariablePopover = (
  editor: Editor & ReactEditor,
  event: KeyboardEvent
) => {
  const { selection } = editor
  if (selection && selection.anchor) {
    event.preventDefault()
    // Inserting hook
    const node = {
      type: 'variable-hook',
      text: '#'
    }
    Editor.insertNode(editor, node)
    setTimeout(() => {
      EditorHelpers.storeSelection(editor)
      createStore.toggleSlateEditorVariablePopover(true)
    }, 100)
  }
}

export { openVariablePopover, handleInsertVariable }
