import React from 'react'
import GeneralVariableParams from './variable/general'
import VariableEditor from './editors/VariableEditor'
import VariableContext from './editors/VariableContext'
import DisplayVariableParams from './variable/display'
import StyleVariableParams from './variable/style'
import TermsheetVariableParams from './variable/termsheet'
import MessagesVariableParams from './variable/messages'
import OtherVariableParams from './variable/other'
import { ConditionOf } from './variable/components/ConditionOf'
import ValidatorVariableParams from './variable/validators'
import { DrawerHead } from './variable/drawerHead'
import EvalVariableParams from './variable/eval'
import SelectValuesVariableParams from './variable/selectValues'
import MaskVariableParams from './variable/mask'
import PrefillingsVariableParams from './variable/prefillings'
import NumberVariableParams from './variable/number'

class VariableElement extends VariableEditor {
  render() {
    return (
      <div className="element-drawer">
        <DrawerHead
          saveUpdates={this.saveUpdates}
          deleteVariable={this.deleteVariable}
          moveVariable={this.moveVariable}
          closeDrawer={this.closeDrawer}
        />
        <VariableContext.Provider value={this.state}>
          <GeneralVariableParams />
          <SelectValuesVariableParams />
          <NumberVariableParams />
          <EvalVariableParams />
          <MaskVariableParams />
          <ValidatorVariableParams />
          <PrefillingsVariableParams />
          <DisplayVariableParams />
          <StyleVariableParams />
          <MessagesVariableParams />
          <TermsheetVariableParams />
          <OtherVariableParams />
          <ConditionOf state={this.state} />
        </VariableContext.Provider>
      </div>
    )
  }
}

export default VariableElement
