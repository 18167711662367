export const RECOVERY_PAYMENT_METHOD = [
  'free',
  'payplug',
  'gocardless',
  'cheque',
  'paypal',
  'debit',
  'other'
]
export const PACK_CHOICE = [
  'first_pack',
  'second_pack',
  'third_pack',
  'fourth_pack'
]
