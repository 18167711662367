import React from 'react'
import { cx, css } from 'emotion'

const Icon = React.forwardRef(({ className, ...props }: any, ref) => (
  <span
    {...props}
    ref={ref}
    className={cx(
      'material-icons',
      className,
      css`
        font-size: 18px;
        vertical-align: text-bottom;
      `
    )}
  />
))

Icon.displayName = 'Icon'

export default Icon
