import { action, computed, observable, runInAction } from 'mobx'
import isEmpty from 'lodash/isEmpty'

import api from '../../utils/api'
import { catchAction } from './catchActionsDecorator'

export interface IModelsStore {
  fetching: boolean
  models: any

  fetchModels(id: number): void
}

class ModelsStore implements IModelsStore {
  @observable public fetching = false

  @observable private modelsInfo: any[] = []

  @computed get models() {
    return this.modelsInfo
  }

  @catchAction
  @action
  public fetchModels = async (id: number) => {
    if (!isEmpty(this.models) || this.fetching) return
    this.fetching = true
    try {
      const resp: any = await api.get(`contract/model/meta/${id}`)
      runInAction(() => {
        const sortedModels = resp.data.sort((a: any, b: any) =>
          a.version > b.version ? -1 : 1
        )
        this.modelsInfo = [...sortedModels]
      })
    } catch (err) {
      console.log('Get models failed: ', err)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }
}

const modelsStore = new ModelsStore()
export default modelsStore
