import React from 'react'

export const NumberedListElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return <ol {...props.attributes}>{props.children}</ol>
}

export const BulletedListElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return <ul {...props.attributes}>{props.children}</ul>
}
