import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import VariableContext from '../editors/VariableContext'
import { SecondStepOnly } from './components/SecondStepOnly'
import { AdminOnly } from './components/AdminOnly'
import { Mandatory } from './components/Mandatory'
import { Hidden } from './components/Hidden'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: {
    display_category: 'Affichage'
  },
  en: {
    display_category: 'Display'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Display variable params
 */
const DisplayVariableParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  return (
    <div
      className={`state-drawer-category${collapsed ? ' collapsed' : ' open'}`}
    >
      <div className="category-title" onClick={toggleCollapsed}>
        {I18n.get('display_category')}
        <div className="icon">
          <ChevronDown />
        </div>
      </div>

      {!collapsed && (
        <VariableContext.Consumer>
          {(state) => {
            return (
              <>
                {/* hidden */}
                <Hidden state={state} />

                {/* 2nd step only: step */}
                <SecondStepOnly state={state} />

                {/* Admin only: grantLevel */}
                <AdminOnly state={state} />

                {/* mandatory */}
                <Mandatory state={state} />
              </>
            )
          }}
        </VariableContext.Consumer>
      )}
    </div>
  )
}

export default DisplayVariableParams
