import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    mask: 'Masque (9=nombre, a=lettre, *=nombre ou lettre)'
  },
  en: {
    mask: 'Mask (9=number, a=letter, *=anything)'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable's mask formula
 */
export const MaskFormula: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { formula } = state.variable.mask || { formula: '' }

  return (
    <Input
      value={formula || ''}
      name="advanced_mask"
      setFieldValue={(name: string, value: string) => {
        state.updateMaskFormula(value)
      }}
      label={I18n.get('mask')}
    />
  )
}
