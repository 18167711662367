import React, { useState, useEffect } from 'react'
import { FDFInputsV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import PdfVariableEditor from './PdfVariableEditor'

const PdfInputEditor: React.FC<{
  input: FDFInputsV3
}> = ({ input }) => {
  const [linkedVariables, setLinkedVariables] = useState<number[]>(
    input.variables.map((v) => v.id)
  )

  useEffect(() => {
    setLinkedVariables(input.variables.map((v) => v.id))
  }, [input.variables])

  // Insert variable
  const insertVariable = () => {
    input.variables.push({ id: 0 })
    setLinkedVariables(input.variables.map((v) => v.id))
  }

  const deleteVariable = (index: number) => {
    input.variables.splice(index, 1)
    setLinkedVariables(input.variables.map((v) => v.id))
  }
  return (
    <div>
      <div className="label">
        <b>{I18n.get('Name:')}</b>
        {` ${input.name}`}
      </div>
      <div className="items">
        {linkedVariables.map((id, index) => {
          const variable = input.variables[index]
          return (
            <PdfVariableEditor
              key={`linked-variable-${index}-${id}`}
              linkedVariable={variable}
              deleteVariable={() => deleteVariable(index)}
            />
          )
        })}
        <div className="link-item-cta" onClick={insertVariable}>
          {I18n.get('Link variable')}
        </div>
      </div>
    </div>
  )
}

export default PdfInputEditor
