import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    'Box aggregatable': 'Boite info agrandissable'
  },
  en: {
    'Box aggregatable': 'Aggregatable box'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits box aggregatable
 */
export const BoxAggregatable: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const aggregatable = state.option.meta.box?.aggregatable || false

  return (
    <div className="label-holder">
      <CheckboxField
        meta
        checked={aggregatable}
        name="boxAggregatable"
        setFieldValue={(name: string, value: boolean) =>
          state.updateBoxAggregatable(value)
        }
        label={I18n.get('Box aggregatable')}
        value="aggregatable"
      />
    </div>
  )
}
