import { OptionV3 } from '@legalplace/models-v3-types'
import createStore from '../../../../../../store/editor/createStore'

export const getOption = (id: number): OptionV3 => {
  const option = createStore.document.options[id]
  if (option === undefined) throw new Error(`Undefined option`)
  return option
}

const getOptionParent = (id: number): number => {
  let parentId = 0
  Object.keys(createStore.document.options).forEach((currentId) => {
    if (createStore.document.options[currentId].options.includes(id))
      parentId = parseInt(currentId, 10)
  })

  return parentId
}

const getOptionParentsList = (id: number): number[] => {
  const parentsList = []
  let parentId = getOptionParent(id)
  while (parentId !== 0) {
    parentsList.push(parentId)
    parentId = getOptionParent(parentId)
  }
  return parentsList
}

const getOptionSection = (id: number): number => {
  const parentsList = getOptionParentsList(id)

  let rootParent
  if (parentsList.length > 0) rootParent = parentsList[parentsList.length - 1]
  else rootParent = id

  const { sections } = createStore.document.documents.main
  for (let i = 0; i < sections.length; i += 1) {
    if (sections[i].options.includes(rootParent)) return sections[i].id
  }

  throw new Error(`Cannot find section for option ${id}`)
}

export const getOptionParentTree = (
  id: number
): { type: string; id: number; label: string }[] => {
  const sectionId = getOptionSection(id)

  return [
    {
      type: 'section',
      id: sectionId,
      label: createStore.document.documents.main.sections[sectionId - 1].label
    },
    ...getOptionParentsList(id).map((_id) => ({
      type: 'option',
      id: _id,
      label: createStore.document.options[_id].meta.label
    })),
    { type: 'option', id, label: createStore.document.options[id].meta.label }
  ]
}
