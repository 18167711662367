import React, { MouseEvent } from 'react'
import { cx, css } from 'emotion'
import { useSlate } from '@legalplace/slate-react'
import Menu from './Menu'
import EditorHelpers, {
  EditorMarksTypes,
  EditorBlockTypes
} from '../../helpers/EditorHelpers'
import Button from './Button'
import Icon from './Icon'
import ToolbarStyleSelect from './SelectStyle'
import TableBlockButton from './TableBlockButton'
import TableStylingToolbar from './TableStylingToolbar'
import TableHelpers from '../../helpers/TableHelpers'
import RepeatedButton from './RepeatedButton'
import ConditionalButton from './ConditionalButton'

const ToolbarMarkButton: React.FC<{
  type: EditorMarksTypes
  value?: string | number
  icon: string
}> = ({ type, icon, value }) => {
  const editor = useSlate()
  const isActive = EditorHelpers.isMarkActive(editor, type)

  const handleClick: React.EventHandler<MouseEvent<HTMLDivElement>> = (
    event
  ) => {
    event.preventDefault()
    EditorHelpers.toggleMark(editor, type, value)
  }

  return (
    <Button active={isActive} onMouseDown={handleClick}>
      <Icon>{icon}</Icon>
    </Button>
  )
}

const ToolbarBlockStyleButton: React.FC<{
  attribute: string
  value: string | number
  icon: string
}> = ({ attribute, icon, value }) => {
  const editor = useSlate()
  const isActive = EditorHelpers.isBlockStyleActive(editor, attribute, value)

  const handleClick: React.EventHandler<MouseEvent<HTMLDivElement>> = (
    event
  ) => {
    event.preventDefault()
    EditorHelpers.toggleBlockStyle(editor, attribute, value)
  }

  return (
    <Button active={isActive} onMouseDown={handleClick}>
      <Icon>{icon}</Icon>
    </Button>
  )
}

const ToolbarBlockTypeButton: React.FC<{
  type: EditorBlockTypes
  icon: string
}> = ({ type, icon }) => {
  const editor = useSlate()
  const isActive = EditorHelpers.isBlockActive(editor, type)

  const handleClick: React.EventHandler<MouseEvent<HTMLDivElement>> = (
    event
  ) => {
    event.preventDefault()
    EditorHelpers.toggleBlock(editor, type)
  }

  return (
    <Button active={isActive} onMouseDown={handleClick}>
      <Icon>{icon}</Icon>
    </Button>
  )
}

export const Toolbar = ({
  className,
  toggleConditionsDrawer,
  ...props
}: any) => {
  const editor = useSlate()

  return (
    <Menu
      {...props}
      className={cx(
        className,
        css`
          position: sticky;
          top: 0;
          padding: 1px 18px 17px;
          margin: 0 -20px;
          border-bottom: 2px solid #eee;
          margin-bottom: 20px;
          z-index: 2;

          .align-right {
            margin-left: auto;
            display: flex;
          }
        `
      )}
    >
      <ToolbarStyleSelect />
      <ToolbarMarkButton type="bold" icon="format_bold" />
      <ToolbarMarkButton type="italic" icon="format_italic" />
      <ToolbarMarkButton type="underlined" icon="format_underlined" />
      <span className="toolbar-separator" />
      <ToolbarBlockStyleButton
        attribute="textAlign"
        value="left"
        icon="format_align_left"
      />
      <ToolbarBlockStyleButton
        attribute="textAlign"
        value="center"
        icon="format_align_center"
      />
      <ToolbarBlockStyleButton
        attribute="textAlign"
        value="right"
        icon="format_align_right"
      />
      <ToolbarBlockStyleButton
        attribute="textAlign"
        value="justify"
        icon="format_align_justify"
      />
      <span className="toolbar-separator" />
      <ToolbarBlockTypeButton
        type="numbered-list"
        icon="format_list_numbered"
      />
      <ToolbarBlockTypeButton
        type="bulleted-list"
        icon="format_list_bulleted"
      />
      <span className="toolbar-separator" />
      <TableBlockButton />
      {TableHelpers.isCell(editor) && (
        <>
          <span className="toolbar-separator" />
          <TableStylingToolbar />
        </>
      )}

      <div className="align-right">
        <RepeatedButton />
        <ConditionalButton toggleConditionsDrawer={toggleConditionsDrawer} />
      </div>
    </Menu>
  )
}

Toolbar.displayName = 'Toolbar'

export default Toolbar
