import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik } from 'formik'
import { I18n } from 'aws-amplify'
import { IAuthStore } from '../../../store/authStore'
import FormField from '../../../components/FormField/FormField'

interface IAuthenticationParamsFormProps {
  authStore?: IAuthStore | any
}

export interface IAuthenticationParamsValues {
  oldPassword?: string
  newPassword?: string
  confirm_newPassword?: string
}

const columns = {
  en: {
    authentication_title: 'Authentication parameters',
    password: 'Password',
    new_password: 'New password',
    confirm_password: 'Confirm new password',
    change_password: 'Change password'
  },
  fr: {
    authentication_title: "Paramètres d'authentification",
    passwordParams: 'Mot de passe',
    new_password: 'Nouveau mot de passe',
    confirm_password: 'Confirmer le nouveau mot de passe',
    change_password: 'Modifier mon mot de passe'
  }
}

I18n.putVocabularies(columns)

@inject('authStore')
@observer
class AuthenticationParamsForm extends Component<
  IAuthenticationParamsFormProps
> {
  private initialValues: IAuthenticationParamsValues = {
    oldPassword: '',
    newPassword: '',
    confirm_newPassword: ''
  }

  private validate = (values: IAuthenticationParamsValues) => {
    const errors: IAuthenticationParamsValues = {}
    if (!values.oldPassword) {
      errors.oldPassword = 'Required'
    }
    if (!values.newPassword) {
      errors.newPassword = 'Required'
    }
    if (values.confirm_newPassword !== values.newPassword) {
      errors.confirm_newPassword = 'Passwords do not match'
    }
    if (!values.confirm_newPassword) {
      errors.confirm_newPassword = 'Required'
    }
    return errors
  }

  public render(): ReactNode {
    const { changePassword } = this.props.authStore
    return (
      <div className="content">
        <Formik
          initialValues={this.initialValues}
          validate={this.validate}
          onSubmit={changePassword}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form className="flex-coll" onSubmit={handleSubmit} noValidate>
              <h4 className="form-ttl">{I18n.get('authentication_title')}</h4>
              <FormField
                label={I18n.get('password')}
                type="password"
                name="oldPassword"
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <FormField
                label={I18n.get('new_password')}
                type="password"
                name="newPassword"
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <FormField
                label={I18n.get('confirm_password')}
                type="password"
                name="confirm_newPassword"
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
              <button
                className="btn btn-send btn-active as-end btn-double-padding-side"
                type="submit"
                disabled={isSubmitting || !values.oldPassword}
              >
                {I18n.get('change_password')}
              </button>
            </form>
          )}
        </Formik>
      </div>
    )
  }
}

export default AuthenticationParamsForm
