import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    'Hide from termsheet': 'Ne pas afficher dans le résumé'
  },
  en: {
    'Hide from termsheet': 'Hide from termsheet'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable termsheet hide
 */
export const TermsheetHide: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const hide = state.variable.termsheet?.hide || false

  return (
    <CheckboxField
      meta
      checked={hide}
      name="termsheetHide"
      setFieldValue={(name: string, value: boolean) =>
        state.updateTermsheetHide(value)
      }
      label={I18n.get('Hide from termsheet')}
      value="hide"
    />
  )
}
