import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    placeholder: 'Placeholder'
  },
  en: {
    placeholder: 'Placeholder'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable's placeholder
 */
export const Placeholder: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { placeholder } = state.variable || { placeholder: '' }

  return (
    <Input
      value={placeholder || ''}
      name="placeholder"
      setFieldValue={(name: string, value: string) => {
        state.updatePlaceholder(value)
      }}
      label={I18n.get('placeholder')}
    />
  )
}
