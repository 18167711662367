import { observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component, createRef } from 'react'
import OptionElement from '../elements/Option'
import SectionElement from '../elements/Section'
import VariableElement from '../elements/Variable'
import './style.scss'

interface IElementProps {
  selectedElement: any
  toggleDrawer(state: boolean): void
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
}

@observer
class Element extends Component<IElementProps> {
  @observable elem = this.props.selectedElement

  optionRef = createRef<OptionElement>()

  variableRef = createRef<VariableElement>()

  sectionRef = createRef<SectionElement>()

  saveChanges = () => {
    if (this.optionRef.current !== null) {
      this.optionRef.current.saveUpdates()
      return
    }
    if (this.variableRef.current !== null) {
      this.variableRef.current.saveUpdates()
      return
    }
    if (this.sectionRef.current !== null) {
      this.sectionRef.current.saveUpdates()
    }
  }

  render() {
    if (this.elem.meta)
      return (
        <OptionElement
          ref={this.optionRef}
          option={this.elem}
          toggleConditionsDrawer={this.props.toggleConditionsDrawer}
          toggleDrawer={this.props.toggleDrawer}
        />
      )
    if (typeof this.elem.type === 'string')
      return (
        <VariableElement
          ref={this.variableRef}
          variable={this.elem}
          toggleConditionsDrawer={this.props.toggleConditionsDrawer}
          toggleDrawer={this.props.toggleDrawer}
        />
      )
    return (
      <SectionElement
        ref={this.sectionRef}
        section={this.elem}
        toggleConditionsDrawer={this.props.toggleConditionsDrawer}
        toggleDrawer={this.props.toggleDrawer}
      />
    )
  }
}

export default Element
