import React from 'react'

const DefaultElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <p style={props.element.style} {...props.attributes}>
      {props.children}
    </p>
  )
}

export default DefaultElement
