import { OptionV3 } from '@legalplace/models-v3-types'
import { Node, Element, Text } from '@legalplace/slate'
import { jsx } from '@legalplace/slate-hyperscript'
import { CSSProperties } from 'react'
import memoizeOne from 'memoize-one'
import isConditionEmpty from './conditionEmptiness'
import createStore from '../../../../../store/editor/createStore'
import { smallLabel } from '../variableUtils/helpers'

const allowedCssStyles: (keyof CSSProperties & keyof CSSStyleDeclaration)[] = [
  'textAlign',
  'fontWeight',
  'fontStyle',
  'textDecoration',
  'padding',
  'border',
  'borderTopColor',
  'borderTopWidth',
  'borderTopStyle',
  'borderBottomColor',
  'borderBottomWidth',
  'borderBottomStyle',
  'borderLeftColor',
  'borderLeftWidth',
  'borderLeftStyle',
  'borderRightColor',
  'borderRightWidth',
  'borderRightStyle',
  'width',
  'tableLayout',
  'borderSpacing'
]

const normalizeText = (text: string | null) => {
  if (text === null) return ''
  return text.replace(/\s\s/g, ' \xA0')
}

const normalizeListChildren = (children: Node[]): Node[] => {
  const normalized = children
    .map((child) => {
      if (child.text && child.text.trim().length === 0) return false
      if (child.text) return { type: 'list-item', children: [child] }
      return child
    })
    .filter((v): v is Node => v !== false)
  return normalized
}

const normalizeMarks = (mark: Node): Text => {
  if (Text.isText(mark)) return mark
  const children = mark.children as Node[]

  const normalizedChildren = children.map((child) => normalizeMarks(child))

  const normalizedMark = {
    bold:
      mark.type === 'strong' ||
      normalizedChildren.reduce((a: boolean, b: Text) => a || b.bold, false),
    underlined:
      mark.type === 'underlined' ||
      normalizedChildren.reduce(
        (a: boolean, b: Text) => a || b.underlined,
        false
      ),
    italic:
      mark.type === 'emphasis' ||
      normalizedChildren.reduce((a: boolean, b: Text) => a || b.italic, false),
    text: normalizedChildren.reduce((a: string, b: Text) => b.text, '')
  }

  return {
    ...normalizedMark
  }
}

const normalizeSpans = (span: Node) => {
  const isSpan = span.type === 'span'
  if (!isSpan) return span

  let children = span.children as Node[]
  children = children.map((child) => normalizeSpans(child))
  if (children.length === 1 && Text.isText(children[0])) {
    if (Object.keys(span.style).length === 0) {
      return children[0]
    }

    const markStyles = Object.keys(span.style)
      .map((name) => {
        if (
          ['fontWeight', 'fontStyle', 'textDecoration'].includes(name) &&
          ['bold', 'italic', 'underline'].includes(span.style[name])
        )
          return name
        return null
      })
      .filter((a: string | null): a is string => a !== null)

    if (
      markStyles.length > 0 &&
      markStyles.length === Object.keys(span.style).length
    ) {
      return {
        ...children[0],
        bold: span.style.fontWeight === 'bold',
        italic: span.style.fontStyle === 'italic',
        underlined: span.style.textDecoration === 'underline'
      }
    }
  }
  return span
}

const nodesParsers: {
  [key: string]: (
    children: Node[],
    style: CSSProperties,
    element: HTMLElement
  ) => Node | Node[]
} = {
  BODY: (children) => jsx('fragment', {}, children),
  BR: () => ({ text: '\n' }),
  BLOCKQUOTE: (children) => jsx('element', { type: 'quote' }, children),
  P: (children, style) =>
    jsx(
      'element',
      { type: 'paragraph', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  SPAN: (children, style) =>
    normalizeSpans(
      jsx(
        'element',
        { type: 'span', style },
        children.length > 0 ? children : [{ text: '' }]
      )
    ),
  H1: (children, style) =>
    jsx(
      'element',
      { type: 'heading-one', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  H2: (children, style) =>
    jsx(
      'element',
      { type: 'heading-two', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  H3: (children, style) =>
    jsx(
      'element',
      { type: 'heading-three', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  H4: (children, style) =>
    jsx(
      'element',
      { type: 'heading-four', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  H5: (children, style) =>
    jsx(
      'element',
      { type: 'heading-five', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  H6: (children, style) =>
    jsx(
      'element',
      { type: 'heading-six', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  TABLE: (children, style) =>
    jsx(
      'element',
      { type: 'table', style },
      children.length > 0
        ? children
        : [
            {
              type: 'table-body',
              children: [
                {
                  type: 'table-row',
                  children: [{ type: 'table-cell', children: [{ text: '' }] }]
                }
              ]
            }
          ]
    ),
  TBODY: (children, style) =>
    jsx(
      'element',
      { type: 'table-body', style },
      children.length > 0
        ? children
        : [
            {
              type: 'table-row',
              children: [{ type: 'table-cell', children: [{ text: '' }] }]
            }
          ]
    ),
  TR: (children, style) =>
    jsx(
      'element',
      { type: 'table-row', style },
      children.length > 0
        ? children
        : [{ type: 'table-cell', children: [{ text: '' }] }]
    ),
  TD: (children, style) =>
    jsx(
      'element',
      { type: 'table-cell', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  STRONG: (children, style) =>
    normalizeMarks(
      jsx(
        'element',
        { type: 'strong', style },
        children.length > 0 ? children : [{ text: '' }]
      )
    ),
  EM: (children, style) =>
    normalizeMarks(
      jsx(
        'element',
        { type: 'emphasis', style },
        children.length > 0 ? children : [{ text: '' }]
      )
    ),
  U: (children, style) =>
    normalizeMarks(
      jsx(
        'element',
        { type: 'underlined', style },
        children.length > 0 ? children : [{ text: '' }]
      )
    ),
  UL: (children) => {
    const normalizedChildren = normalizeListChildren(children)
    return jsx(
      'element',
      { type: 'bulleted-list' },
      normalizedChildren.length > 0
        ? normalizedChildren
        : [{ type: 'list-item', children: [{ text: '' }] }]
    )
  },
  OL: (children) => {
    const normalizedChildren = normalizeListChildren(children)
    return jsx(
      'element',
      { type: 'numbered-list' },
      normalizedChildren.length > 0
        ? normalizedChildren
        : [{ type: 'list-item', children: [{ text: '' }] }]
    )
  },
  LI: (children, style) =>
    jsx(
      'element',
      { type: 'list-item', style },
      children.length > 0 ? children : [{ text: '' }]
    ),
  A: (children, style, element) =>
    jsx(
      'element',
      { type: 'link', url: element.getAttribute('href'), style },
      children
    ),
  VARIABLE: (children, style, element) =>
    jsx(
      'text',
      { type: 'variable', id: element.getAttribute('data-id') },
      children
    )
}
const nodesParsersKeys = Object.keys(nodesParsers)

const deserializeDOMNodes: (el: HTMLElement) => Node | Node[] = (el) => {
  if (el.nodeType === 3) {
    return { text: normalizeText(el.textContent) }
  }
  if (el.nodeType === 8) {
    return []
  }
  if (el.nodeType !== 1) {
    throw new Error(`Unkown node type`)
  }

  if (nodesParsersKeys.includes(el.nodeName)) {
    const childNodes = Array.from(el.childNodes) as HTMLElement[]
    let children = childNodes.map((childEl) =>
      deserializeDOMNodes(childEl)
    ) as Node[]

    // Makes sure to spread extracted variable children results
    if (children.length === 1 && Array.isArray(children[0]))
      children = [...children[0]]

    const style: CSSProperties = {}
    if (el.style) {
      allowedCssStyles.forEach((attr) => {
        if (el.style[attr] && el.style[attr].length > 0) {
          // @ts-ignore
          style[attr] = el.style[attr]
        }
      })
    }

    /**
     * SPECIAL CASE:
     * EDITOR V1 puts variables under Mark tags (strong, b, u, em etc...)
     * We must bring them out of the text node in order to avoid loosing
     * them on normalization
     */
    if (
      children.length > 1 &&
      children.findIndex((c) => c.type === 'variable') > -1 &&
      ['STRONG', 'B', 'I', 'EM', 'U'].includes(el.nodeName)
    ) {
      // Extracting variables outside current node
      const variablesIndexes = children
        .map((c, i) => (c.type === 'variable' ? i : undefined))
        .filter((i): i is number => i !== undefined)

      let result: Node[] = []

      variablesIndexes.forEach((currentIndex, i) => {
        // Pushing previous childs
        let start = 0
        if (i > 0) {
          start = variablesIndexes[i - 1] + 1
        }
        const end = currentIndex
        if (end > start) {
          const part = nodesParsers[el.nodeName](
            children.slice(start, end),
            style,
            el
          )

          if (Array.isArray(part)) result = [...result, ...part]
          else result.push(part)
        }

        // Pushing current variable
        result.push({
          ...children[currentIndex],
          bold:
            ['STRONG', 'B'].includes(el.nodeName) ||
            children[currentIndex].bold,
          italic:
            ['EM', 'I'].includes(el.nodeName) || children[currentIndex].italic,
          underlined:
            ['U'].includes(el.nodeName) || children[currentIndex].underlined
        })

        // Adding last slice
        if (
          i === variablesIndexes.length - 1 &&
          currentIndex < children.length - 1
        ) {
          const part = nodesParsers[el.nodeName](
            children.slice(currentIndex + 1),
            style,
            el
          )

          if (Array.isArray(part)) result = [...result, ...part]
          else result.push(part)
        }
      })

      return result
    }
    return nodesParsers[el.nodeName](children, style, el)
  }
  return { text: normalizeText(el.textContent) }
}

const normalizeRootNodes = (nodes: Node[]): Node[] => {
  const normalized = nodes.map((node) => {
    if (Text.isText(node)) {
      // Ignoring node if it's a whitespace
      if (/^\s*$/.test(node.text)) return undefined

      return { type: 'paragraph', children: [node] }
    }
    return node
  })
  if (normalized.length === 0)
    return [{ type: 'paragraph', children: [{ text: '' }] }]
  return normalized.filter((e): e is Element => e !== undefined)
}

const parseHtml = memoizeOne((html: string) => {
  return new DOMParser().parseFromString(html, 'text/html')
})

const fillNode = (
  option: OptionV3,
  repeated?: number,
  repeatedMeta?: OptionV3['meta']
) => {
  const { id, conditions } = option.meta

  if (option.meta.output === undefined) return false

  // Parsing variables
  const output = option.meta.output.replace(
    /\[var:([0-9]+)\]/g,
    (variableTag, variableId) => {
      const variable =
        createStore.document.variables[variableId]?.label || variableTag
      return `<variable data-id="${variableId}">#${variableId} ${smallLabel(
        variable
      )}</variable>`
    }
  )

  const domNode = parseHtml(output)
  domNode.normalize()
  let elements = deserializeDOMNodes(domNode.body)
  elements = Array.isArray(elements) ? elements : [elements]

  const hasConditions =
    typeof conditions === 'object' && !isConditionEmpty(conditions)

  const children = normalizeRootNodes(elements)

  return {
    type: 'output',
    id,
    repeated,
    repeatedMeta: repeatedMeta
      ? JSON.parse(JSON.stringify(repeatedMeta))
      : undefined,
    hasConditions,
    originalOption: JSON.parse(JSON.stringify(option)),
    children
  }
}

export const deserializeOutputs: (
  o: { option: OptionV3; children: OptionV3[] }[]
) => Node[] = (outputs) => {
  const nodes: Element[] = []
  outputs.forEach((output) => {
    const { option, children } = output
    const { id, type } = option.meta
    if (type === 'hidden') {
      const currentNode = fillNode(JSON.parse(JSON.stringify(option)))
      if (currentNode) nodes.push(currentNode)
    } else if (type === 'repeated') {
      children.forEach((child) => {
        const currentNode = fillNode(
          JSON.parse(JSON.stringify(child)),
          id,
          JSON.parse(JSON.stringify(option.meta))
        )
        if (currentNode) nodes.push(currentNode)
      })
    }
  })
  return nodes
}
