import { I18n } from 'aws-amplify'
import { OptionV3, VariableV3 } from '@legalplace/models-v3-types'
import createStore from './createStore'

const dictionary = {
  fr: {
    '%1 conditions %2': '%1 conditionne %2',
    '%1 conditions %2 in %3': '%1 conditionne %2 dans %3',
    '%1 validates %2 in %3': '%1 valide %2 dans %3',
    '%1 repeats %2 in %3': '%1 répète %2 dans %3',
    '%1 conditions prefill of %2 in %3':
      '%1 conditionne une valeur pré-remplie dans %2 dans %3',
    "%1 conditions model's client type":
      '%1 conditionne le type de client du modèle',
    '%1 is used in label of %2 in %3':
      '%1 est utilisé dans le label de %2 dans %3',
    '%1 is used in prefill of %2 in %3':
      '%1 est utilisé dans le pré-remplissage de %2 dans %3',
    '%1 is used in content of %2 in %3':
      '%1 est utilisé dans le contenu de %2 dans %3',
    '%1 is used in formula of %2 in %3':
      '%1 est utilisé dans la formule de %2 dans %3',
    '%1 is used in %2 in %3': '%1 est utilisé dans %2 dans %3',
    '%1 is extracted': '%1 est extraite',

    // Variables labels
    'the list "%1"': 'la liste déroulante "%1"',
    'the eval "%1"': 'la calculatrice "%1"',
    'the text "%1"': 'le champ texte "%1"',
    'the number "%1"': 'le champ nombre "%1"',
    'the date "%1"': 'le champ date "%1"',
    'the hour "%1"': 'le champ heure "%1"',
    'the email "%1"': 'le champ email "%1"',
    'the textarea "%1"': 'le champ multiligne "%1"',
    'the mask "%1"': 'le champ masque avancé "%1"',

    // Options labels
    'the static "%1"': 'la question "%1"',
    'the radio "%1"': 'la radio "%1"',
    'the checkbox "%1"': 'la case à cocher "%1"',
    'the hidden "%1"': 'l\'output "%1"',
    'the repeated "%1"': 'l\'output "%1"',
    'the box "%1"': 'la boite info "%1"',

    // Section
    'the section "%1"': 'la section "%1"',

    // Document
    'the document "%1"': 'le document "%1"',

    // Vide
    empty: 'vide'
  },
  en: {
    '%1 conditions %2': '%1 conditions %2',
    '%1 conditions %2 in %3': '%1 conditions %2 in %3',
    '%1 validates %2 in %3': '%1 validates %2 in %3',
    '%1 conditions prefill of %2 in %3': '%1 conditions prefill of %2 in %3',
    "%1 conditions model's client type": "%1 conditions model's client type",

    // Variables labels
    'the list "%1"': 'the dropdown "%1"',
    'the eval "%1"': 'the calculator field "%1"',
    'the text "%1"': 'the text field "%1"',
    'the number "%1"': 'the number field "%1"',
    'the date "%1"': 'the date field "%1"',
    'the hour "%1"': 'the hour field "%1"',
    'the email "%1"': 'the email field "%1"',
    'the textarea "%1"': 'the multi-line field "%1"',
    'the mask "%1"': 'the mask field "%1"',

    // Options labels
    'the static "%1"': 'the question "%1"',
    'the radio "%1"': 'the radio "%1"',
    'the checkbox "%1"': 'the checkbox "%1"',
    'the hidden "%1"': 'the output "%1"',
    'the repeated "%1"': 'the output "%1"',
    'the box "%1"': 'the box "%1"',

    // Section
    'the section "%1"': 'the section "%1"',

    // Document
    'the document "%1"': 'the document "%1"'
  }
}

I18n.putVocabularies(dictionary)

type AllElementsTypes =
  | OptionV3['meta']['type']
  | VariableV3['type']
  | 'section'
  | 'document'
  | false

type DetailedErrorMessageType = {
  id: number | string
  type: 'option' | 'variable' | 'section' | 'document' | 'general'
  subType?: 'condition' | 'validator'
  document: string
  section: number
  parentsTree: number[]
  message: string
}

class SafeDelete {
  public id: number

  public type: 'o' | 'v'

  private conditionnedClientType: boolean = false

  private extractedElement: boolean = false

  private conditionnedOptions: number[] = []

  private validatedOptions: number[] = []

  private conditionnedVariables: number[] = []

  private outputUsedVariables: number[] = []

  private boxUsedVariables: number[] = []

  private prefillUsedVariables: {
    variableId: number
    index: number
  }[] = []

  private evalUsedVariables: number[] = []

  private labelUsedVariables: { id: number; type: 'o' | 'v' }[] = []

  private validatedVariables: number[] = []

  private variablesConditionnedPrefills: {
    variableId: number
    index: number
  }[] = []

  private conditionnedDocuments: string[] = []

  private conditionnedSections: number[] = []

  private repeateds: number[] = []

  private messages: DetailedErrorMessageType[] = []

  constructor(id: number | string, type: 'v' | 'o') {
    this.id = typeof id === 'number' ? id : parseInt(id, 10)
    this.type = type

    // Checking client type
    this.checkConditionnedClientType()
    this.checkExtractedElement()

    // Getting list of related options
    this.getConditionnedOptions()
    this.getValidatedOptions()

    // Getting list of related variables
    this.getConditionnedVariables()
    this.getValidatedVariables()

    // If it's a variable, we get the list
    // of elements where it's used
    if (type === 'v') {
      this.getOutputUsedVariables()
      this.getBoxesUsedVariables()
      this.getPrefillUsedVariables()
      this.getEvalUsedVariables()
      this.getLabelUsedVariables()
    }

    // Getting list of related prefills
    this.getVariablesConditionnedPrefills()

    // Getting list of conditionned documents
    this.getConditionnedDocuments()

    // Getting list of conditionned sections
    this.getConditionnedSections()

    // Getting list of repeated outputs
    if (type === 'o') this.getRepeateds()

    // Writing error messages
    this.writeClientTypeMessage()
    this.writeOptionsMessages()
    this.writeVariablesMessages()
    this.writeVariablesPrefillsMessages()
    this.writeSectionsMessages()
    this.writeDocumentsMessages()
    if (type === 'o') this.writeRepeatedsMessages()
    this.writeExtractedElementMessage()
    if (type === 'v') {
      this.writeEvalUsedVariables()
      this.writeOutputUsedVariables()
      this.writeBoxUsedVariables()
      this.writePrefillUsedVariables()
      this.writeLabelUsedVariables()
    }
  }

  get errorMessages() {
    return this.messages
  }

  get elements() {
    const {
      conditionnedClientType,
      conditionnedOptions,
      validatedOptions,
      conditionnedVariables,
      validatedVariables,
      variablesConditionnedPrefills,
      conditionnedDocuments,
      conditionnedSections,
      repeateds
    } = this

    return {
      conditionnedClientType,
      conditionnedOptions,
      validatedOptions,
      conditionnedVariables,
      validatedVariables,
      variablesConditionnedPrefills,
      conditionnedDocuments,
      conditionnedSections,
      repeateds
    }
  }

  get canUnlink() {
    const {
      conditionnedClientType,
      conditionnedOptions,
      validatedOptions,
      conditionnedVariables,
      validatedVariables,
      variablesConditionnedPrefills,
      conditionnedDocuments,
      conditionnedSections,
      repeateds
    } = this

    return !(
      [
        conditionnedClientType,
        conditionnedOptions.length > 0,
        validatedOptions.length > 0,
        conditionnedVariables.length > 0,
        validatedVariables.length > 0,
        variablesConditionnedPrefills.length > 0,
        conditionnedDocuments.length > 0,
        conditionnedSections.length > 0,
        repeateds.length > 0
      ].filter((a) => a === true).length === 0
    )
  }

  /**
   * Determines if current element conditions
   * the client type general param
   */
  private checkConditionnedClientType(): void {
    // Checking clientType conditions
    const { meta } = createStore.document.customization
    const clientType = meta?.clientType || undefined
    if (
      clientType &&
      clientType.conditions &&
      typeof clientType.conditions === 'object' &&
      JSON.stringify(clientType.conditions).indexOf(
        `"${this.type}.${this.id}"`
      ) > -1
    )
      this.conditionnedClientType = true
  }

  /**
   * Returns the list of options conditionned
   * by current element
   */
  private getConditionnedOptions(): void {
    // Looping through options
    Object.values(createStore.document.options).forEach((option) => {
      if (
        option.meta?.conditions &&
        typeof option.meta.conditions === 'object' &&
        JSON.stringify(option.meta.conditions).indexOf(
          `"${this.type}.${this.id}"`
        ) > -1
      )
        this.conditionnedOptions.push(option.meta.id)
    })
  }

  /**
   * Returns the list of options conditionned
   * by current element
   */
  private getValidatedOptions(): void {
    // Looping through options
    Object.values(createStore.document.options).forEach((option) => {
      if (
        option.meta?.validator?.conditions &&
        typeof option.meta.validator.conditions === 'object' &&
        JSON.stringify(option.meta.validator.conditions).indexOf(
          `"${this.type}.${this.id}"`
        ) > -1
      )
        this.validatedOptions.push(option.meta.id)
    })
  }

  /**
   * Returns the list of variables conditionned
   * by current element
   */
  private getConditionnedVariables(): void {
    // Looping through variables
    Object.values(createStore.document.variables).forEach((variable) => {
      if (
        variable.conditions &&
        typeof variable.conditions === 'object' &&
        JSON.stringify(variable.conditions).indexOf(
          `"${this.type}.${this.id}"`
        ) > -1
      )
        this.conditionnedVariables.push(variable.id)
    })
  }

  /**
   * Returns the list of variables conditionned
   * by current element
   */
  private getValidatedVariables(): void {
    // Looping through variables
    Object.values(createStore.document.variables).forEach((variable) => {
      if (
        variable.validator?.conditions &&
        typeof variable.validator.conditions === 'object' &&
        JSON.stringify(variable.validator.conditions).indexOf(
          `"${this.type}.${this.id}"`
        ) > -1
      )
        this.validatedVariables.push(variable.id)
    })
  }

  /**
   * Returns the list of outputs using
   * current variable
   */
  private getOutputUsedVariables(): void {
    // Looping through outputs
    Object.values(createStore.document.options).forEach((option) => {
      // Avoiding all non outputs & empty outputs (<=6 cannot contain a variable since [var:x] is minimum 7 chars)
      if (
        option.meta.type !== 'hidden' ||
        option.meta.output === undefined ||
        option.meta.output.trim().length <= 6
      )
        return

      if (new RegExp(`\\[var:${this.id}\\]`, 'g').test(option.meta.output)) {
        this.outputUsedVariables.push(option.meta.id)
      }
    })
  }

  /**
   * Returns the list of boxes using
   * current variable
   */
  private getBoxesUsedVariables(): void {
    // Looping through boxes
    Object.values(createStore.document.options).forEach((option) => {
      // Avoiding all non boxes & empty boxes (<=6 cannot contain a variable since [var:x] is minimum 7 chars)
      if (
        option.meta.type !== 'box' ||
        option.meta.box?.content === undefined ||
        option.meta.box?.content.length <= 6
      )
        return

      if (
        new RegExp(`\\[var:${this.id}\\]`, 'g').test(option.meta.box.content)
      ) {
        this.boxUsedVariables.push(option.meta.id)
      }
    })
  }

  /**
   * Returns the list of variables prefills
   * using current variable
   */
  private getPrefillUsedVariables(): void {
    // Looping through variables
    Object.values(createStore.document.variables).forEach((variable) => {
      if (variable.prefillings && Array.isArray(variable.prefillings)) {
        variable.prefillings.forEach((prefill, index) => {
          // Avoiding all empty prefill (<=6 cannot contain a variable since [var:x] is minimum 7 chars)
          if (prefill.value === undefined || prefill.value.trim().length <= 6)
            return

          if (new RegExp(`\\[var:${this.id}\\]`, 'g').test(prefill.value)) {
            this.prefillUsedVariables.push({
              variableId: variable.id,
              index
            })
          }
        })
      }
    })
  }

  /**
   * Returns the list of evals using
   * current variable
   */
  private getEvalUsedVariables(): void {
    // Looping through variables
    Object.values(createStore.document.variables).forEach((variable) => {
      // Avoiding all non boxes & empty boxes (<=6 cannot contain a variable since [var:x] is minimum 7 chars)
      if (
        variable.type !== 'eval' ||
        variable.eval?.formula === undefined ||
        variable.eval?.formula.trim().length <= 6
      )
        return

      const { formula } = variable.eval
      if (new RegExp(`\\[var:${this.id}\\]`, 'g').test(formula)) {
        this.evalUsedVariables.push(variable.id)
      }
    })
  }

  /**
   * Returns the list of labels using
   * current variable
   */
  private getLabelUsedVariables(): void {
    // Looping through variables
    Object.values(createStore.document.variables).forEach((variable) => {
      // Avoiding all empty label (<=6 cannot contain a variable since [var:x] is minimum 7 chars)
      if (variable.label.trim().length <= 6) return

      if (new RegExp(`\\[var:${this.id}\\]`, 'g').test(variable.label)) {
        this.labelUsedVariables.push({ id: variable.id, type: 'v' })
      }
    })
    // Looping through options
    Object.values(createStore.document.options).forEach((option) => {
      // Avoiding all empty label (<=6 cannot contain a option since [var:x] is minimum 7 chars)
      if (option.meta.label.trim().length <= 6) return

      if (new RegExp(`\\[var:${this.id}\\]`, 'g').test(option.meta.label)) {
        this.labelUsedVariables.push({ id: option.meta.id, type: 'o' })
      }
    })
  }

  /**
   * Checks whether current variable is extracted
   * or not
   */
  private checkExtractedElement(): void {
    const { customization } = createStore.document
    if (customization.meta?.extracts) {
      customization.meta.extracts.forEach((extract) => {
        if (
          ((extract.type === 'variable' && this.type === 'v') ||
            (extract.type === 'option' && this.type === 'o')) &&
          extract.id === this.id
        ) {
          this.extractedElement = true
        }
      })
    }
  }

  /**
   * Returns the list of variables prefills
   * conditionned by current element
   */
  private getVariablesConditionnedPrefills(): void {
    // Looping through variables
    Object.values(createStore.document.variables).forEach((variable) => {
      if (variable.prefillings && Array.isArray(variable.prefillings)) {
        variable.prefillings.forEach((prefill, index) => {
          if (
            prefill.conditions &&
            typeof prefill.conditions === 'object' &&
            JSON.stringify(prefill.conditions).indexOf(
              `"${this.type}.${this.id}"`
            ) > -1
          )
            this.variablesConditionnedPrefills.push({
              variableId: variable.id,
              index
            })
        })
      }
    })
  }

  /**
   * Returns the list of documents conditionned
   * by current element
   */
  private getConditionnedDocuments(): void {
    // Looping through documents
    Object.keys(createStore.document.documents).forEach((documentSlug) => {
      const document = createStore.document.documents[documentSlug]

      if (
        document.params?.conditions &&
        typeof document.params.conditions === 'object' &&
        JSON.stringify(document.params.conditions).indexOf(
          `"${this.type}.${this.id}"`
        ) > -1
      )
        this.conditionnedDocuments.push(documentSlug)
    })
  }

  /**
   * Returns the list of sections conditionned
   * by current element
   */
  private getConditionnedSections(): void {
    // Looping through main document sections
    Object.values(createStore.document.documents.main.sections).forEach(
      (section) => {
        if (
          section.conditions &&
          typeof section.conditions === 'object' &&
          JSON.stringify(section.conditions).indexOf(
            `"${this.type}.${this.id}"`
          ) > -1
        )
          this.conditionnedSections.push(section.id)
      }
    )
  }

  /**
   * Returns the list of outputs repeated
   * by current element
   */
  private getRepeateds(): void {
    const option = createStore.document.options[this.id]
    if (option.meta.multiple?.enabled === true) {
      // List of outputs
      const outputs = createStore.getMultipleRepeateds(this.id)

      outputs.forEach((output) => {
        this.repeateds.push(output.meta.id)
      })
    }
  }

  /**
   * Write error messages for repeated
   * outputs linked to current element
   */
  private writeRepeatedsMessages(): void {
    this.repeateds.forEach((id) => {
      const parents = this.getOptionParentsTree(id)

      const { document } = this.getOptionSectionDocument(id)

      this.writeMessage(
        id,
        'option',
        parents,
        document || I18n.get('Unkown'),
        0,
        '',
        'hidden',
        'repeated'
      )
    })
  }

  /**
   * Writes error message if client type is
   * conditionned by current elment
   */
  private writeClientTypeMessage() {
    if (this.conditionnedClientType === true) {
      this.writeMessage(0, 'general', [], '', 0, '', false, 'clientType')
    }
  }

  /**
   * Writes error message if current element
   * is extracted
   */
  private writeExtractedElementMessage() {
    if (this.extractedElement === true) {
      this.writeMessage(0, 'general', [], '', 0, '', false, 'extract')
    }
  }

  /**
   * Loops through option & writes messages
   */
  private writeOptionsMessages() {
    // Writing error messages for options conditions
    this.conditionnedOptions.forEach((optionId) => {
      // Getting parent tree
      const parentsTree = this.getOptionParentsTree(optionId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(optionId)
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for option ${optionId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${optionId} - ${createStore.document.options[optionId].meta.label}`
      const conditionnedType = createStore.document.options[optionId].meta.type

      this.writeMessage(
        optionId,
        'option',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'condition'
      )
    })
    // Writing error messages for options validations
    this.validatedOptions.forEach((optionId) => {
      // Getting parent tree
      const parentsTree = this.getOptionParentsTree(optionId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(optionId)
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for option ${optionId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${optionId} - ${createStore.document.options[optionId].meta.label}`
      const conditionnedType = createStore.document.options[optionId].meta.type

      this.writeMessage(
        optionId,
        'option',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'validator'
      )
    })
  }

  /**
   * Loops through variables & writes messages
   */
  private writeVariablesMessages() {
    // Writing error messages for variable conditions
    this.conditionnedVariables.forEach((variableId) => {
      // Getting parent tree
      const parentsTree = this.getVariableParentsTree(variableId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(
        parentsTree[0]
      )
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for variable ${variableId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${variableId} - ${createStore.document.variables[variableId].label}`
      const conditionnedType = createStore.document.variables[variableId].type

      this.writeMessage(
        variableId,
        'variable',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'condition'
      )
    })

    // Writing error messages for variable validations
    this.validatedVariables.forEach((variableId) => {
      // Getting parent tree
      const parentsTree = this.getVariableParentsTree(variableId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(
        parentsTree[0]
      )
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for variable ${variableId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${variableId} - ${createStore.document.variables[variableId].label}`
      const conditionnedType = createStore.document.variables[variableId].type

      this.writeMessage(
        variableId,
        'variable',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'validator'
      )
    })
  }

  /**
   * Loops through variables & writes messages
   */
  private writeEvalUsedVariables() {
    this.evalUsedVariables.forEach((variableId) => {
      // Getting parent tree
      const parentsTree = this.getVariableParentsTree(variableId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(
        parentsTree[0]
      )
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for variable ${variableId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${variableId} - ${createStore.document.variables[variableId].label}`
      const conditionnedType = createStore.document.variables[variableId].type

      this.writeMessage(
        variableId,
        'variable',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'evalUsed'
      )
    })
  }

  /**
   * Loops through option & writes messages
   */
  private writeBoxUsedVariables() {
    // Writing error messages for boxes usings variable
    this.boxUsedVariables.forEach((optionId) => {
      // Getting parent tree
      const parentsTree = this.getOptionParentsTree(optionId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(optionId)
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for option ${optionId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${optionId} - ${createStore.document.options[optionId].meta.label}`
      const conditionnedType = createStore.document.options[optionId].meta.type

      this.writeMessage(
        optionId,
        'option',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'boxUsed'
      )
    })
  }

  /**
   * Loops through option & writes messages
   */
  private writeOutputUsedVariables() {
    // Writing error messages for outputs usings variable
    this.outputUsedVariables.forEach((optionId) => {
      // Getting parent tree
      const parentsTree = this.getOptionParentsTree(optionId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(optionId)
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for option ${optionId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const conditionnedLabel = `#${optionId} - ${createStore.document.options[optionId].meta.label}`
      const conditionnedType = createStore.document.options[optionId].meta.type

      this.writeMessage(
        optionId,
        'option',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'outputUsed'
      )
    })
  }

  /**
   * Loops through variables prefills & writes messages
   */
  private writePrefillUsedVariables() {
    // Writing error messages for prefills using variable
    this.prefillUsedVariables.forEach(({ variableId, index }) => {
      // Getting parent tree
      const parentsTree = this.getVariableParentsTree(variableId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(
        parentsTree[0]
      )
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for variable ${variableId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const { prefillings } = createStore.document.variables[variableId]
      const prefillMessage =
        prefillings && prefillings[index]
          ? prefillings[index].value
          : I18n.get('empty')
      const conditionnedLabel = `#${variableId} - ${
        createStore.document.variables[variableId].label
      } (${this.parseOutputText(prefillMessage)})`
      const conditionnedType = createStore.document.variables[variableId].type

      this.writeMessage(
        variableId,
        'variable',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'prefillUsed'
      )
    })
  }

  /**
   * Loops through variables prefills & writes messages
   */
  private writeLabelUsedVariables() {
    // Writing error messages for prefills using variable
    this.labelUsedVariables.forEach(({ id, type }) => {
      let conditionnedLabel
      let conditionnedType: AllElementsTypes
      let parentsTree
      let section
      let document
      if (type === 'o') {
        // Getting parent tree
        parentsTree = this.getOptionParentsTree(id)

        // Getting section & document
        const {
          section: optionSection,
          document: optionDocument
        } = this.getOptionSectionDocument(id)
        section = optionSection
        document = optionDocument
        if (section === undefined || document === undefined)
          throw new Error(`Unable to find section & document for option ${id}`)

        /**
         * Writing the message
         */
        // Getting conditionned element's label & type
        conditionnedLabel = `#${id} - ${createStore.document.options[id].meta.label}`
        conditionnedType = createStore.document.options[id].meta.type
      } else if (type === 'v') {
        // Getting parent tree
        parentsTree = this.getVariableParentsTree(id)

        // Getting section & document
        const {
          section: variableSection,
          document: variableDocument
        } = this.getOptionSectionDocument(parentsTree[0])
        section = variableSection
        document = variableDocument
        if (section === undefined || document === undefined)
          throw new Error(
            `Unable to find section & document for variable ${id}`
          )

        /**
         * Writing the message
         */
        // Getting conditionned element's label & type
        conditionnedLabel = `#${id} - ${createStore.document.variables[id].label}`
        conditionnedType = createStore.document.variables[id].type
      } else {
        throw new Error(`Unrecognized type`)
      }

      this.writeMessage(
        id,
        type === 'v' ? 'variable' : 'option',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'labelUsed'
      )
    })
  }

  /**
   * Loops through variables prefills & writes messages
   */
  private writeVariablesPrefillsMessages() {
    // Writing error messages for variable conditions
    this.variablesConditionnedPrefills.forEach(({ variableId, index }) => {
      // Getting parent tree
      const parentsTree = this.getVariableParentsTree(variableId)

      // Getting section & document
      const { section, document } = this.getOptionSectionDocument(
        parentsTree[0]
      )
      if (section === undefined || document === undefined)
        throw new Error(
          `Unable to find section & document for variable ${variableId}`
        )

      /**
       * Writing the message
       */
      // Getting conditionned element's label & type
      const { prefillings } = createStore.document.variables[variableId]
      const prefillMessage =
        prefillings && prefillings[index]
          ? prefillings[index].value
          : I18n.get('empty')
      const conditionnedLabel = `#${variableId} - ${
        createStore.document.variables[variableId].label
      } (${this.parseOutputText(prefillMessage)})`
      const conditionnedType = createStore.document.variables[variableId].type

      this.writeMessage(
        variableId,
        'variable',
        parentsTree,
        document,
        section,
        conditionnedLabel,
        conditionnedType,
        'prefill'
      )
    })
  }

  /**
   * Loops through documents & writes messages
   */
  private writeDocumentsMessages() {
    // Writing error messages for documents conditions
    this.conditionnedDocuments.forEach((documentSlug) => {
      /**
       * Writing the message
       */
      const { name } = createStore.document.documents[documentSlug]

      this.writeMessage(
        documentSlug,
        'document',
        [],
        documentSlug,
        0,
        name,
        'document',
        'shortCondition'
      )
    })
  }

  /**
   * Loops through sections & writes messages
   */
  private writeSectionsMessages() {
    // Writing error messages for sections conditions
    this.conditionnedSections.forEach((sectionId) => {
      /**
       * Writing the message
       */
      let label = createStore.document.documents.main.sections.find(
        (currentSection) => currentSection.id === sectionId
      )?.label

      if (label === undefined) {
        throw new Error(`Unable to find section ${sectionId}`)
      }

      label = `${sectionId} - ${label}`

      this.writeMessage(
        sectionId,
        'section',
        [],
        'main',
        sectionId,
        label,
        'section',
        'shortCondition'
      )
    })
  }

  /**
   * Helper used to simplify writing messages
   * @param id Element's id
   * @param parentsTree  Element's parentsTree
   * @param document Parent document
   * @param section Parent section
   * @param conditionnedLabel Conditionned element label
   * @param conditionnedType Conditionned element type
   */
  private writeMessage(
    id: number | string,
    type: 'option' | 'variable' | 'section' | 'document' | 'general',
    parentsTree: number[],
    document: string,
    section: number,
    conditionnedLabel: string,
    conditionnedType: AllElementsTypes,
    errorType:
      | 'condition'
      | 'validator'
      | 'shortCondition'
      | 'prefill'
      | 'clientType'
      | 'repeated'
      | 'extract'
      | 'boxUsed'
      | 'outputUsed'
      | 'evalUsed'
      | 'prefillUsed'
      | 'labelUsed'
  ) {
    // Getting current element's label & type
    let currentLabel
    let currentType
    if (this.type === 'o') {
      currentLabel = createStore.document.options[this.id].meta.label
      currentType = createStore.document.options[this.id].meta.type
    } else {
      currentLabel = createStore.document.variables[this.id].label
      currentType = createStore.document.variables[this.id].type
    }

    // Current message part
    const currentMessage = this.parseMessage(
      I18n.get(`the ${currentType} "%1"`),
      currentLabel
    )

    // Updating conditionned label with
    // output's content in case it's an
    // output
    const documentOptions = createStore.document.options
    let _conditionnedLabel = conditionnedLabel
    if (conditionnedType === 'hidden') {
      const output = this.parseOutputText(documentOptions[id].meta.output)
      _conditionnedLabel = output.length > 0 ? output : 'Output vide'
    } else if (conditionnedType === 'repeated') {
      const repeatedContent = this.parseOutputText(
        documentOptions[id].options
          .map((childId) => documentOptions[childId].meta.output || '')
          .join(' ')
          .trim()
      )
      _conditionnedLabel =
        repeatedContent.length > 0 ? repeatedContent : 'Output vide'
    }

    // Conditionned message part
    const conditionnedMessage = this.parseMessage(
      I18n.get(`the ${conditionnedType} "%1"`),
      _conditionnedLabel
    )

    // Location message
    let locationMessage = ''
    if (
      document === 'main' &&
      conditionnedType !== false &&
      !['hidden', 'repeated'].includes(conditionnedType)
    ) {
      locationMessage = this.parseMessage(
        I18n.get('the section "%1"'),
        `${section} - ${
          createStore.document.documents.main.sections.find(
            (s) => s.id === section
          )?.label || I18n.get('unkown')
        }`
      )
    } else if (conditionnedType !== false) {
      locationMessage = this.parseMessage(
        I18n.get('the document "%1"'),
        createStore.document.documents[document].name
      )
    }

    let errorKey = '%1 conditions %2 in %3'
    if (errorType === 'shortCondition') errorKey = '%1 conditions %2'
    else if (errorType === 'validator') errorKey = '%1 validates %2 in %3'
    else if (errorType === 'prefill')
      errorKey = '%1 conditions prefill of %2 in %3'
    else if (errorType === 'clientType')
      errorKey = "%1 conditions model's client type"
    else if (errorType === 'repeated') errorKey = '%1 repeats %2 in %3'
    else if (errorType === 'extract') errorKey = '%1 is extracted'
    else if (errorType === 'outputUsed') errorKey = '%1 is used in %2 in %3'
    else if (errorType === 'evalUsed')
      errorKey = '%1 is used in formula of %2 in %3'
    else if (errorType === 'boxUsed')
      errorKey = '%1 is used in content of %2 in %3'
    else if (errorType === 'prefillUsed')
      errorKey = '%1 is used in prefill of %2 in %3'
    else if (errorType === 'labelUsed')
      errorKey = '%1 is used in label of %2 in %3'

    const message = this.parseMessage(
      I18n.get(errorKey),
      currentMessage,
      conditionnedMessage,
      locationMessage
    )

    /**
     * Pushing error message
     */
    this.messages.push({
      id,
      type,
      parentsTree,
      section,
      document,
      message
    })
  }

  /**
   * Parses a given message with its arguments
   * Example:
   * parseMessage('Hello %1, how are you %2? %1', 'John', 'today')
   * returns
   * "Hello John, how are you today? John"
   * @param str
   * @param args
   */
  private parseMessage(str: string, ...args: (string | number)[]) {
    let parsedMessage = str

    args.forEach((argument, index) => {
      const tagRegex = new RegExp(`([^\\\\])?%${index + 1}`, 'g')

      parsedMessage = parsedMessage.replace(
        tagRegex,
        `$1${typeof argument === 'number' ? argument.toString() : argument}`
      )
    })

    return parsedMessage
  }

  /**
   * Returns options direct parent
   * @param {number} id option id
   */
  public getOptionParent(id: number) {
    let parent = 0
    Object.keys(createStore.document.options).forEach((_id) => {
      const option = createStore.document.options[_id]
      if (option.options.includes(id)) {
        parent = parseInt(_id, 10)
      }
    })
    return parent
  }

  /**
   * Returns options parents tree
   * @param id Option id
   */
  public getOptionParentsTree(id: number) {
    const parentsTree: number[] = []

    // Options
    let parentId = this.getOptionParent(id)
    while (parentId !== 0) {
      parentsTree.push(parentId)
      parentId = this.getOptionParent(parentId)
    }

    return parentsTree
  }

  /**
   * Returns variable's parent
   * @param {number} id Returns the parent's id of the given variable's id
   */
  private getVariableParent(id: number) {
    let parent = 0
    Object.keys(createStore.document.options).forEach((_id) => {
      const option = createStore.document.options[_id]
      if (option.variables.includes(id)) {
        parent = parseInt(_id, 10)
      }
    })
    return parent
  }

  /**
   * Returns option's section & document
   * @param {number} id
   */
  private getOptionSectionDocument(
    id: number
  ): { section: number | undefined; document: string | undefined } {
    // Getting root parent
    const rootParent = this.getOptionParentsTree(id).pop()

    // If root parent is undefined then current option
    // is a root option
    const rootOption = rootParent === undefined ? id : rootParent

    // Looping through documents & sections to find
    // root option
    const documents = Object.keys(createStore.document.documents)
    for (
      let documentIndex = 0;
      documentIndex < documents.length;
      documentIndex += 1
    ) {
      const documentSlug = documents[documentIndex]
      const { sections } = createStore.document.documents[documentSlug]
      for (
        let sectionIndex = 0;
        sectionIndex < sections.length;
        sectionIndex += 1
      ) {
        const section = sections[sectionIndex]

        if (section.options.includes(rootOption))
          return { section: section.id, document: documentSlug }
      }
    }

    return { section: undefined, document: undefined }
  }

  /**
   * Returns variable parents tree
   * @param id Option id
   */
  private getVariableParentsTree(id: number) {
    const variableParent = this.getVariableParent(id)
    return [variableParent, ...this.getOptionParentsTree(variableParent)]
  }

  /**
   * Parses an output's html and returns
   * its text
   */
  private parseOutputText(output: string | undefined) {
    if (output === undefined) return ''
    const domNode = new DOMParser().parseFromString(output, 'text/html')
    domNode.normalize()
    const outputText = domNode.body.innerText.replace(/\xA0/g, ' ').trim()
    if (outputText.length < 40) return outputText
    return `${outputText.substr(0, outputText.lastIndexOf(' ', 40))}...`
  }
}

export default SafeDelete
