import React, { useContext } from 'react'
import { useSlate, ReactEditor } from '@legalplace/slate-react'
import { ReactComponent as AddPlus } from './add-plus.svg'
import { addOutputEvent } from '../../events/addOutputEvent'
import OutputContext from './OutputContext'

const OutputElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  const editor = useSlate()
  const outputContent = useContext(OutputContext)
  let active = false

  if (ReactEditor.isFocused(editor) && editor.selection) {
    if (
      ReactEditor.findPath(editor, props.element)[0] ===
      editor.selection.focus.path[0]
    ) {
      active = true
    }
  }

  const { hasConditions, repeated, id } = props.element

  /**
   * Class Names
   */
  const outputClassNames = [
    'slate-output-wrapper',
    active && 'active',
    hasConditions && 'conditional-block',
    typeof repeated === 'number' && 'multiple-block'
  ]
    .filter((a) => a !== false)
    .join(' ')

  const addOutputClassNames = ['slate-add-output-cta', active && 'active']
    .filter((a) => a !== false)
    .join(' ')

  // If current output already has parent output we return its children in a fragment
  if (outputContent === true) {
    return <>{props.children}</>
  }

  return (
    <>
      <div
        className={outputClassNames}
        data-output-id={props.element.optionId}
        {...props.attributes}
      >
        <div
          className={addOutputClassNames}
          onClick={(e) => addOutputEvent(e, editor, id, 'before', repeated)}
          contentEditable={false}
        >
          <button type="button">
            <AddPlus />
          </button>
          <span className="cta-text">Ajouter un bloc</span>
        </div>
        <OutputContext.Provider value>{props.children}</OutputContext.Provider>
        <div
          className={`${addOutputClassNames} bottom`}
          onClick={(e) => addOutputEvent(e, editor, id, 'after', repeated)}
          contentEditable={false}
        >
          <button type="button">
            <AddPlus />
          </button>
          <span className="cta-text">Ajouter un bloc</span>
        </div>
      </div>
    </>
  )
}

export default React.memo(OutputElement)
