import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action } from 'mobx'
import { I18n } from 'aws-amplify'
import Drawer from '@material-ui/core/Drawer'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/core/styles'
import './styles.scss'
import createStore, { ICreateStore } from '../../../../store/editor/createStore'
import SelectField from '../../../../components/SelectField/SelectField'
import Toggle from '../../../../components/Toggle'
import Icon from '../../../../components/Icons/Icon'
import MaterialSelect from '../../../../components/MaterialSelect'

import { DELICON } from '../../../../components/Icons/icons'
import PlusCondition from '../../../../assets/images/plus_condition.png'
import { isOption } from '../../../../utils/elementsHelpers'
import { catchAction } from '../../../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'New document': 'Nouveau document',
    'Is checked': 'Est sélectionné',
    'Is not checked': 'Non sélectionné mais affiché',
    'Is not checked (even hidden)': "N'est pas sélectionné",
    'Is checked at least once': 'Au moins une sélection',
    Is: 'Est',
    'Is not': "N'est pas",
    'Has one value': 'A une seule occurence',
    'Has more than one value': 'A plusieurs occurences',
    Equals: '=',
    'Does not equals': '<>',
    'Strictly inferior': '<',
    'Strictly superior': '>',
    'Superior or equal': '>=',
    'Inferior of equal': '=<',
    and: 'and',
    or: 'or',
    'and/or': 'ET / OU',
    'condition pp': 'CONDITION',
    cancel: 'Annuler',
    save: 'Enregistrer',
    'helper click': 'Cliquez sur le bouton',
    helper1: ' pour ajouter une condition ou une branche « Et/Ou »',
    helper2: ' sur l’écran de gauche pour ajouter une condition',
    copy: 'Copier',
    paste: 'Coller',
    'Not found': 'Introuvable'
  },
  en: {
    'New document': 'New document',
    'Is checked': 'Is checked',
    'Is not checked': 'Not checked but displayed',
    'Is not checked (even hidden)': 'Is not checked',
    'Is checked at least once': 'Is checked at least once',
    Is: 'Is',
    'Is not': 'Is not',
    'Has one value': 'Has one value',
    'Has more than one value': 'Has more than one value',
    Equals: 'Equals',
    'Does not equals': 'Does not equals',
    'Strictly inferior': 'Strictly inferior',
    'Strictly superior': 'Strictly superior',
    'Superior or equal': 'Superior or equal',
    'Inferior of equal': 'Inferior of equal',
    and: 'and',
    or: 'or',
    'and/or': 'and / or',
    'condition pp': 'CONDITION',
    cancel: 'Cancel',
    save: 'Save',
    'helper click': 'Click on the',
    helper1: ' button to add a Condition or an "AND / OR" branch',
    helper2: ' button on the left panel to add a condition',
    copy: 'Copy',
    paste: 'Paste'
  }
}

const FORK = (
  <svg
    fontSize="2rem"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2 4.18699L11.8133 2.80032L10.4267 4.18699"
      stroke="#6B757F"
      strokeLinecap="round"
    />
    <path
      d="M2.8 8H9.04555C10.5774 7.99723 11.8166 6.75293 11.8133 5.22112C11.8133 5.21465 11.8133 5.20864 11.8133 5.20217V2.8"
      stroke="#6B757F"
      strokeLinecap="round"
    />
    <path
      d="M13.2 11.8136L11.8133 13.2003L10.4267 11.8136"
      stroke="#6B757F"
      strokeLinecap="round"
    />
    <path
      d="M9.04555 8.00034C10.5774 8.00311 11.8166 9.24741 11.8133 10.7792C11.8133 10.7857 11.8133 10.7917 11.8133 10.7982V13.2017"
      stroke="#6B757F"
      strokeLinecap="round"
    />
  </svg>
)

const PLUS = (
  <svg
    fontSize="2rem"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 2.66667V13.3333" stroke="#6B757F" strokeLinecap="round" />
    <path d="M13.3333 8H2.66667" stroke="#6B757F" strokeLinecap="round" />
  </svg>
)

I18n.putVocabularies(dictionary)

interface IElementStateDrawerProps {
  createStore: ICreateStore
  isConditionsDrawerOpen: boolean
  toggleConditionsDrawer(
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ): void
  toggleDrawer(val: boolean): void
  classes: any
}

const styles = {
  list: {
    width: 438
  },
  drawer: {},
  root: {
    left: 'unset'
  },
  MuiBackdrop: {
    left: 'unset'
  }
}

@inject('createStore')
@observer
class ConditionsDrawer extends Component<IElementStateDrawerProps> {
  // TODO: This is temporary
  repositionConditionBlocks = () => {
    // Getting lowest client top
    let minimumTop: number | null = null
    document.querySelectorAll('.condition-block').forEach((conditionBlock) => {
      const { top } = conditionBlock.getBoundingClientRect()
      if (minimumTop === null || minimumTop > top) minimumTop = top
    })

    if (minimumTop === null) return

    // Getting first block top
    const firstTop =
      document.querySelector('.condition-block')?.getBoundingClientRect().top ||
      0

    const marginTop = firstTop - minimumTop

    const block = document.querySelector(
      '.condition-content-block'
    ) as HTMLElement | null
    if (block) block.style.marginTop = `${marginTop}px`
  }

  @catchAction
  @action
  onHandleChange = (name: string, value: any, indexes: any) => {
    const { conditionedEl, editingValidators } = this.props.createStore
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }

    const rootElId = indexes[0]
    // array of parents IDs
    const idxs = [...indexes]
    // find current index
    const index = this.getIndex(indexes)
    // @ts-ignore
    if (index) {
      idxs.splice(index - 1)
    }
    idxs.shift()
    const destinationEl = idxs.reduce(
      (parent, id) => parent[id],
      conditions[rootElId]
    )
    // @ts-ignore
    const data = destinationEl[indexes[index - 1]][indexes[index]]
    // set new data
    // @ts-ignore
    destinationEl[indexes[index - 1]] = {
      [value]: data
    }
  }

  @catchAction
  @action
  handleSetListOption = (name: string, value: any, indexes: any) => {
    const { conditionedEl, editingValidators } = this.props.createStore
    // get conditions
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    // get current index
    const index = this.getIndex(indexes)
    // @ts-ignore
    if (index && index > 0) {
      idxs.splice(index)
    }
    // get nearest parent value
    // @ts-ignore
    const arr = indexes[index]
    idxs.shift()
    const destinationEl = idxs.reduce(
      (parent, id) => parent[id],
      conditions[rootElId]
    )
    // set new data
    if (destinationEl[arr][1]) {
      destinationEl[arr][1] = value
    } else {
      destinationEl[arr].push(value)
    }
  }

  @catchAction
  @action
  handleVariableValue = (
    event: React.FormEvent<HTMLInputElement>,
    indexes: any
  ) => {
    const { conditionedEl, editingValidators } = this.props.createStore
    const { value } = event.currentTarget
    // get conditions
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    // get current index
    const index = this.getIndex(indexes)
    // @ts-ignore
    if (index && index > 0) {
      idxs.splice(index)
    }
    // get nearest parent value
    // @ts-ignore
    const arr = indexes[index]
    idxs.shift()
    const destinationEl = idxs.reduce(
      (parent, id) => parent[id],
      conditions[rootElId]
    )
    // set new data
    destinationEl[arr][1] = value
  }

  @catchAction
  @action
  toggleSwitch = (name: string, value: any, indexes: any) => {
    const { conditionedEl, editingValidators } = this.props.createStore
    // get conditions
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    // get current index
    const index = this.getIndex(indexes)
    // @ts-ignore
    if (index && index > 0) {
      idxs.splice(index - 1)
    }
    idxs.shift()
    const i = idxs.pop()
    if (!i) {
      // find root toggle node
      const destinationEl = idxs.reduce(
        (parent, id) => parent[id],
        conditions[rootElId]
      )
      const key = value ? 'or' : 'and'

      conditions[key] = destinationEl
      delete conditions[rootElId]
    } else {
      // find current toggle node
      const destinationEl = idxs.reduce(
        (parent, id) => parent[id],
        conditions[rootElId]
      )
      // set new value
      // @ts-ignore
      const data = destinationEl[i]
      const key = value ? 'or' : 'and'
      destinationEl[key] = data
      delete destinationEl[i]
    }
  }

  handleSave = () => {
    // saving
    const { toggleConditionsDrawer, toggleDrawer } = this.props
    const {
      setConditions,
      setValidators,
      paramsWasShown,
      toggleParamsWasShown,
      prefillWasShown,
      togglePrefillWasShown,
      toggleModal,
      toggleEditingValidators,
      editingValidators
    } = this.props.createStore

    this.checkConditionsEmptiness()
    if (editingValidators === true) {
      setValidators()
    } else {
      setConditions()
    }
    toggleConditionsDrawer(false, '', undefined, { didSave: true })
    toggleEditingValidators(false)

    if (paramsWasShown) {
      toggleDrawer(true)
      toggleParamsWasShown(false)
      if (prefillWasShown) {
        toggleModal('prefillIsOpen', true)
        togglePrefillWasShown(false)
      }
    }
  }

  checkConditionsEmptiness = () => {
    const { deleteConditionsObject } = this.props.createStore
    const { conditionedEl, editingValidators } = this.props.createStore
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // Checking for empty conditions
    if (
      typeof conditions === 'object' &&
      Object.keys(conditions).length === 1
    ) {
      const key = Object.keys(conditions)[0]
      if (Array.isArray(conditions[key]) && conditions[key].length === 0) {
        deleteConditionsObject(conditionedEl.destinationEl, editingValidators)
      }
    }
  }

  handleClose = () => {
    const { toggleConditionsDrawer, toggleDrawer } = this.props
    const {
      resetConditionedEl,
      paramsWasShown,
      toggleParamsWasShown,
      prefillWasShown,
      togglePrefillWasShown,
      toggleModal,
      docParamsWasShown,
      toggleShowPlusIcon,
      toggleEditingValidators
    } = this.props.createStore
    toggleEditingValidators(false)
    resetConditionedEl()
    // close condition drawer
    toggleConditionsDrawer(false, '')
    if (paramsWasShown) {
      // if params modal was open - open it again
      toggleDrawer(true)
      toggleParamsWasShown(false)
      if (prefillWasShown) {
        // if prefill modal was open - open it again
        toggleModal('prefillIsOpen', true)
        togglePrefillWasShown(false)
      }
    }
    if (docParamsWasShown) {
      // if document params modal was open - open it again
      toggleModal('documentParamsIsOpen', true)
      togglePrefillWasShown(false)
    }
    toggleShowPlusIcon(false)
  }

  deleteCondition = (indexes: any[]) => {
    const {
      conditionedEl,
      deleteCondition,
      editingValidators
    } = this.props.createStore
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    // get current index
    const index = this.getIndex(indexes)
    if (index) {
      idxs.splice(index - 1)
    }
    idxs.shift()
    // get current condition
    const destinationEl = idxs.reduce(
      (parent, id) => parent[id],
      conditions[rootElId]
    )
    // @ts-ignore
    deleteCondition(destinationEl, indexes[index - 1])
  }

  @catchAction
  @action
  deleteNode = (indexes: any) => {
    const { conditionedEl, editingValidators } = this.props.createStore
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    // get current index
    const index = this.getIndex(indexes)
    if (index) {
      idxs.splice(index - 1)
    }

    idxs.shift()
    const lastIndex = idxs.pop()
    // get current node
    const destinationEl = idxs.reduce(
      (parent, id) => parent[id],
      conditions[rootElId]
    )
    delete destinationEl[lastIndex]
  }

  getIndex = (indexes: any[]) => {
    let index
    if (indexes.indexOf('selected') !== -1) {
      index = indexes.lastIndexOf('selected')
    } else if (indexes.indexOf('not-selected') !== -1) {
      index = indexes.lastIndexOf('not-selected')
    } else if (indexes.indexOf('not-selected-ip') !== -1) {
      index = indexes.lastIndexOf('not-selected-ip')
    } else if (indexes.indexOf('at-least-one') !== -1) {
      index = indexes.lastIndexOf('at-least-one')
    } else if (indexes.indexOf('contains') !== -1) {
      index = indexes.lastIndexOf('contains')
    } else if (indexes.indexOf('does-not-contain') !== -1) {
      index = indexes.lastIndexOf('does-not-contain')
    } else if (indexes.indexOf('has-one') !== -1) {
      index = indexes.lastIndexOf('has-one')
    } else if (indexes.indexOf('has-many') !== -1) {
      index = indexes.lastIndexOf('has-many')
    } else if (indexes.indexOf('=') !== -1) {
      index = indexes.lastIndexOf('=')
    } else if (indexes.indexOf('<>') !== -1) {
      index = indexes.lastIndexOf('<>')
    } else if (indexes.indexOf('>') !== -1) {
      index = indexes.lastIndexOf('>')
    } else if (indexes.indexOf('<') !== -1) {
      index = indexes.lastIndexOf('<')
    } else if (indexes.indexOf('>=') !== -1) {
      index = indexes.lastIndexOf('>=')
    } else if (indexes.indexOf('<=') !== -1) {
      index = indexes.lastIndexOf('<=')
    } else if (indexes.indexOf('empty') !== -1) {
      index = indexes.lastIndexOf('empty')
    } else if (indexes.indexOf('not-empty') !== -1) {
      index = indexes.lastIndexOf('not-empty')
    } else if (indexes.indexOf('is') !== -1) {
      index = indexes.lastIndexOf('is')
    } else if (indexes.indexOf('not') !== -1) {
      index = indexes.lastIndexOf('not')
    } else if (indexes.indexOf('like') !== -1) {
      index = indexes.lastIndexOf('like')
    } else if (indexes.indexOf('not-like') !== -1) {
      index = indexes.lastIndexOf('not-like')
    }
    return index
  }

  @catchAction
  @action
  addSwitch = (indexes: any[]) => {
    const { conditionedEl, editingValidators } = this.props.createStore
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    idxs.shift()
    let destinationEl
    if (!idxs.length) {
      const firstEl = Object.keys(conditions)[0]
      // find current node
      destinationEl = conditions[firstEl]
      destinationEl.push({
        and: []
      })
    } else {
      // find current node
      destinationEl = idxs.reduce(
        (parent, id) => parent[id],
        conditions[rootElId]
      )
      const key = Object.keys(destinationEl)[0]
      destinationEl[key].push({
        and: []
      })
    }
  }

  handleAddCondition = (indexes: any[]) => {
    const {
      toggleShowPlusIcon,
      setConditionIndexes,
      showPlusIcon
    } = this.props.createStore
    toggleShowPlusIcon(!showPlusIcon)
    setConditionIndexes(indexes)
  }

  renderTypeIcon = (type: string) => {
    switch (type) {
      case 'radio':
        return (
          <Radio
            className="grey-icon"
            checked
            color="default"
            disabled
            name="radio-button-demo"
            aria-label="D"
          />
        )
      case 'checkbox':
        return (
          <Checkbox
            className="grey-icon"
            checked
            disabled
            // indeterminate
          />
        )
      case 'list':
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 9.6L12 15.6L6 9.6"
              stroke="#212529"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )
      // case 'question':
      case 'static':
        return (
          <div className="multiple-icon-wrapper">
            <div className="multiple-icon" />
          </div>
        )
      default:
        return null
    }
  }

  renderContent = (
    content: any,
    condition: string | null,
    optId: string,
    list: any,
    idxs: any[]
  ) => {
    const {
      multipleConditionOptions,
      variableConditionOptions,
      listConditionOptions,
      conditionOptionsExtended,
      conditionOptions
    } = this.props.createStore
    let option
    // get id of option
    const optIdPrepared = optId.split('.')[1]
    if (Number.isNaN(+optIdPrepared)) return null
    // get type of option
    if (optId.split('.')[0] === 'o') {
      option = this.props.createStore.document.options[optIdPrepared]
    } else {
      option = this.props.createStore.document.variables[optIdPrepared]
    }

    let sectionId = -1
    const index = this.getIndex(idxs)

    if (index === undefined) throw new Error(`Cannot find index for ${idxs}`)

    // @ts-ignore
    const isNotConverters: Record<string, string> = {
      contains: 'is',
      'does-not-contain': 'not'
    }
    const value = ['contains', 'does-not-contain'].includes(idxs[index])
      ? isNotConverters[idxs[index]]
      : idxs[index]

    // @ts-ignore
    const optionId = option ? (option.meta ? option.meta.id : option.id) : -1

    const optionType = option
      ? isOption(option)
        ? option.meta.type
        : option.type
      : 'undefined'

    // @ts-ignore
    const optionLabel = option
      ? isOption(option)
        ? option.meta.label
        : option.label
      : I18n.get('Not found')
    let variableValue
    if (
      [
        'list',
        'text',
        'date',
        'eval',
        'mask',
        'textarea',
        'number',
        'hour',
        'email'
      ].includes(optionType)
    ) {
      const { conditionedEl, editingValidators } = this.props.createStore
      let conditions
      if (editingValidators === true) {
        if (
          typeof conditionedEl.destinationEl.meta === 'object' &&
          conditionedEl.destinationEl.meta.validator === undefined
        ) {
          conditionedEl.destinationEl.meta.validator = {}
        } else if (
          conditionedEl.destinationEl.meta === undefined &&
          conditionedEl.destinationEl.validator === undefined
        ) {
          conditionedEl.destinationEl.validator = {}
        }
        conditions = conditionedEl.destinationEl.meta
          ? conditionedEl.destinationEl.meta.validator.conditions
          : conditionedEl.destinationEl.validator.conditions
      } else {
        conditions = conditionedEl.destinationEl.meta
          ? conditionedEl.destinationEl.meta.conditions
          : conditionedEl.destinationEl.conditions
      }
      // get root el
      const rootElId = idxs[0]
      // copy parent IDs array
      const indexes = [...idxs]
      // get current index
      const currentIndex = this.getIndex(indexes)
      // @ts-ignore
      if (currentIndex && currentIndex > 0) {
        indexes.splice(currentIndex)
      }
      // get nearest parent value
      // @ts-ignore
      const arr = idxs[currentIndex]
      indexes.shift()
      const destinationEl = indexes.reduce(
        (parent, id) => parent[id],
        conditions[rootElId]
      )
      if (destinationEl[arr] && destinationEl[arr][1]) {
        ;[, variableValue] = destinationEl[arr]
      }
    }

    // need to get the section index
    const isVariable = !Object.prototype.hasOwnProperty.call(option, 'meta')

    // Getting parent section
    if (isVariable) {
      // Getting variable's parent section
      sectionId = createStore.getVariableParentSection(optionId)
    } else {
      // Getting option's parent section
      sectionId = createStore.getOptionParentSection(optionId)
    }

    const isParentMultiple = (id: number): boolean => {
      const options = Object.values(this.props.createStore.document.options)
      for (let i = 0; i < options.length; i += 1) {
        const opt = options[i]

        const hasChild = opt.options.find((child: any) => child === id)
        if (hasChild && opt.meta.multiple && opt.meta.multiple.enabled) {
          return true
        }
        if (hasChild) {
          return isParentMultiple(opt.meta.id)
        }
      }
      return false
    }

    let listOptions
    let options
    if (optionType === 'radio' || optionType === 'checkbox') {
      options = conditionOptions
      // eslint-disable-next-line
      if(isParentMultiple(optionId))
          options = conditionOptionsExtended
    } else if (optionType === 'list') {
      options = listConditionOptions
      // @ts-ignore
      const selectValues = option.selectValues ? option.selectValues : ['']
      listOptions = selectValues.map((opt: any) => {
        return { value: opt, label: opt }
      })
    } else if (optionType === 'static' || optionType === 'box') {
      options = multipleConditionOptions
    } else if (
      ['list', 'text', 'date', 'mask', 'textarea', 'hour', 'email'].includes(
        optionType
      )
    ) {
      options = listConditionOptions
    } else if (['eval', 'number'].includes(optionType)) {
      options = variableConditionOptions
    }

    return (
      <div className="condition-wrapper inner-condition">
        <div className="condition-holder">
          <div className="condition-type">
            {this.renderTypeIcon(optionType)}
          </div>
          <div className="condition-meta">
            <div className="condition-section">
              Section {sectionId}
              {` - #${optionId}`}
            </div>
            <div className="condition-label">{optionLabel}</div>
          </div>
          {[
            'list',
            'text',
            'date',
            'eval',
            'mask',
            'textarea',
            'number',
            'hour',
            'email'
          ].includes(optionType) ? (
            <>
              <SelectField
                fieldClass="fieldContent"
                label=""
                value={value}
                options={options}
                setFieldValue={(name, newValue) =>
                  this.onHandleChange(name, newValue, idxs)
                }
                name="c"
              />
              {optionType === 'list' ? (
                <MaterialSelect
                  fieldClass="fieldContent condition-select"
                  label=""
                  variant="standard"
                  value={variableValue}
                  options={listOptions}
                  setFieldValue={(name: any, newValue: any) =>
                    this.handleSetListOption(name, newValue, idxs)
                  }
                  name="c"
                />
              ) : (
                <input
                  type="text"
                  onChange={(e) => this.handleVariableValue(e, idxs)}
                  className="fieldContent"
                  value={variableValue}
                />
              )}
            </>
          ) : (
            <>
              <SelectField
                fieldClass="fieldContent"
                label=""
                value={value}
                options={options}
                setFieldValue={(name, newValue) =>
                  this.onHandleChange(name, newValue, idxs)
                }
                name="c"
              />
            </>
          )}
          <button
            type="button"
            className="btn-icon delete-node-button delete-option"
            onClick={() => this.deleteCondition(idxs)}
          >
            <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
          </button>
        </div>
      </div>
    )
  }

  renderCondition = (
    list: any,
    source: any,
    indexes: any[],
    type?: string,
    isOp: boolean = true
  ) => {
    if (typeof source === 'string') {
      // eslint-disable-next-line no-debugger
      if (isOp === false) return null
      return this.renderContent(source, null, source, type, indexes)
    }
    return list.map((condition: any, index: number) => {
      const idxs: any[] = [...indexes, condition]
      return (
        // TODO: Remove index from key
        // eslint-disable-next-line react/no-array-index-key
        <div key={`condition-${index}`} className={`condition-block ${index}`}>
          {condition && (condition === 'or' || condition === 'and') && (
            <>
              <span className="add-item">
                <button className="plus" type="button">
                  +
                </button>
                <div className="condition-popup">
                  <button
                    type="button"
                    data-cypressId="andor-btn"
                    onClick={() => this.addSwitch(indexes)}
                  >
                    <Icon iconClass="multiple-ico" icon={FORK} />
                    {I18n.get('and/or')}
                  </button>
                  <button
                    type="button"
                    data-cypressId="cond-btn"
                    onClick={() => this.handleAddCondition(indexes)}
                  >
                    <Icon iconClass="multiple-ico" icon={PLUS} />
                    {I18n.get('condition pp')}
                  </button>
                </div>
              </span>
              <Toggle
                checked={condition !== 'and'}
                setFieldValue={(name, value) =>
                  this.toggleSwitch(name, value, idxs)
                }
                name={Math.random().toString(36).substr(2, 16)}
              />
              <button
                type="button"
                className="btn-icon delete-node-button"
                onClick={() => this.deleteNode(idxs)}
              >
                <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
              </button>
            </>
          )}
          <div
            className={
              condition && (condition === 'or' || condition === 'and')
                ? 'inner-wrapper'
                : ''
            }
          >
            {Array.isArray(source[condition])
              ? source[condition].map(
                  (item: any, currentIndex: number) =>
                    item &&
                    this.renderCondition(
                      Object.keys(item),
                      item,
                      [...idxs, currentIndex],
                      undefined,
                      ['and', 'or', 'var'].includes(condition)
                    )
                )
              : this.renderCondition(
                  typeof source[condition] === 'string'
                    ? source[condition]
                    : Object.keys(source[condition]),
                  source[condition],
                  idxs,
                  source,
                  ['and', 'or', 'var'].includes(condition)
                )}
          </div>
        </div>
      )
    })
  }

  render() {
    const { isConditionsDrawerOpen, classes } = this.props
    const {
      conditionedEl,
      showPlusIcon,
      editingValidators,
      copyConditions,
      pasteConditions,
      conditionsBuffer
    } = this.props.createStore
    let conditions
    if (editingValidators === true) {
      if (
        typeof conditionedEl.destinationEl.meta === 'object' &&
        conditionedEl.destinationEl.meta.validator === undefined
      ) {
        conditionedEl.destinationEl.meta.validator = {}
      } else if (
        conditionedEl.destinationEl.meta === undefined &&
        conditionedEl.destinationEl.validator === undefined
      ) {
        conditionedEl.destinationEl.validator = {}
      }
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.validator.conditions
        : conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = conditionedEl.destinationEl.meta
        ? conditionedEl.destinationEl.meta.conditions
        : conditionedEl.destinationEl.conditions
    }

    const showHelper =
      conditions.and &&
      !conditions.and.length &&
      Object.keys(conditions).length === 1
    const disableCopyButton = !Object.keys(conditionsBuffer).length

    // TODO: Remove timeout
    setTimeout(() => this.repositionConditionBlocks(), 100)

    return (
      <Drawer
        open={isConditionsDrawerOpen}
        anchor="right"
        ModalProps={{
          hideBackdrop: true
        }}
        classes={{
          paper: classes.drawerPaper,
          // TS Ignores here are mandatory since MUI v3 isn't well typed
          // @ts-ignore
          root: classes.root,
          // @ts-ignore
          MuiBackdrop: classes.MuiBackdrop
        }}
        onClose={this.handleClose}
      >
        <div className="conditions-drawer">
          <div className={classes.list} role="presentation">
            <div className="button-wrapper">
              <div className="buttons-holder-left">
                <button
                  className="secondary"
                  type="button"
                  onClick={copyConditions}
                >
                  {I18n.get('copy')}
                </button>
                <button
                  type="button"
                  disabled={disableCopyButton}
                  className="secondary"
                  onClick={pasteConditions}
                >
                  {I18n.get('paste')}
                </button>
              </div>
              <div className="buttons-holder">
                <button
                  type="button"
                  className="secondary"
                  onClick={this.handleClose}
                >
                  {I18n.get('Cancel')}
                </button>
                <button
                  type="button"
                  onClick={this.handleSave}
                  className="primary"
                >
                  {I18n.get('Save')}
                </button>
              </div>
            </div>
            <div className="condition-content-block">
              {this.renderCondition(Object.keys(conditions), conditions, [])}
            </div>
            {showHelper && !showPlusIcon ? (
              <div className="helper-one">
                <div>
                  {I18n.get('helper click')}{' '}
                  <button className="plus-icon" type="button">
                    +
                  </button>
                  {I18n.get('helper1')}
                </div>
              </div>
            ) : (
              ''
            )}
            {showHelper && showPlusIcon ? (
              <div className="helper-two">
                <div>
                  {I18n.get('helper click')}{' '}
                  <button type="button" className="btn-icon plus-icon">
                    <img src={PlusCondition} alt="" />
                  </button>
                  {I18n.get('helper2')}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Drawer>
    )
  }
}

export default withStyles(styles)(ConditionsDrawer)
