import { I18n } from 'aws-amplify'
import { observer } from 'mobx-react'
import React, { Component, ReactNode } from 'react'
// import PersonalInfoForm from './PersonalInfoForm';
import AuthenticationParamsForm from './AuthenticationParamsForm'
import './style.scss'

interface ISettingsPageProps {}

const columns = {
  en: {
    my_settings_title: 'Settings Page'
  },
  fr: {
    my_settings_title: 'Paramètres'
  }
}

I18n.putVocabularies(columns)

@observer
class SettingsPage extends Component<ISettingsPageProps> {
  public render(): ReactNode {
    return (
      <div className="content settingsPageContainer">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('my_settings_title')}</h3>
          </div>
        </div>

        <div className="padding-container settingsFormContainer">
          <div className="grid-container-form">
            {/* <div className="grid-item"> */}
            {/* <PersonalInfoForm/> */}
            {/* </div> */}
            <div className="grid-item">
              <AuthenticationParamsForm />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SettingsPage
