import React from 'react'
import GeneralSectionParams from './section/general'
import SectionEditor from './editors/SectionEditor'
import SectionContext from './editors/SectionContext'
import DisplaySectionParams from './section/display'
import { DrawerHead } from './section/drawerHead'

class SectionElement extends SectionEditor {
  render() {
    return (
      <div className="element-drawer">
        <DrawerHead
          saveUpdates={this.saveUpdates}
          deleteSection={this.deleteSection}
          duplicateSection={this.duplicateSection}
          moveSection={this.moveSection}
          closeDrawer={this.closeDrawer}
        />
        <SectionContext.Provider value={this.state}>
          <GeneralSectionParams />
          <DisplaySectionParams />
        </SectionContext.Provider>
      </div>
    )
  }
}

export default SectionElement
