import React from 'react'
import DefaultElement from './DefaultElement'

const HeadingElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  switch (props.element.type) {
    case 'heading-one':
      return (
        <h1 style={props.element.style} {...props.attributes}>
          {props.children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={props.element.style} {...props.attributes}>
          {props.children}
        </h2>
      )
    case 'heading-three':
      return (
        <h3 style={props.element.style} {...props.attributes}>
          {props.children}
        </h3>
      )
    case 'heading-four':
      return (
        <h4 style={props.element.style} {...props.attributes}>
          {props.children}
        </h4>
      )
    case 'heading-five':
      return (
        <h5 style={props.element.style} {...props.attributes}>
          {props.children}
        </h5>
      )
    case 'heading-six':
      return (
        <h6 style={props.element.style} {...props.attributes}>
          {props.children}
        </h6>
      )
    default:
      return <DefaultElement {...props} />
  }
}

export default HeadingElement
