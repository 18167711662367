import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import '../assets/scss/main.scss'
import { Link } from 'react-router-dom'
import { ErrorMessage, Formik } from 'formik'
import { I18n } from 'aws-amplify'
import FormField from '../components/FormField/FormField'
import { IAuthStore } from '../store/authStore'

interface ILoginPageProps {
  authStore: IAuthStore
}

export interface ILoginValues {
  username?: string
  password?: string
  server_error?: string
  loggedIn?: boolean
}

const columns = {
  en: {
    login_title: 'Login',
    login_text:
      'Hi, to access the dashboard, please enter your login information',
    username: 'Email',
    password: 'Password',
    keep: 'Keep me logged in',
    login_btn: 'Login',
    forgot: 'Forgot password?'
  },
  fr: {
    login_title: 'Connexion',
    login_text: 'Merci de rentrer vos identifiants ci-dessous',
    username: 'Email',
    password: 'Mot de passe',
    keep: 'Rester connecter',
    login_btn: 'Connexion',
    forgot: 'Mot de passe oublié ?'
  }
}

I18n.putVocabularies(columns)

@inject('authStore')
@observer
class LoginPage extends Component<ILoginPageProps> {
  private initialValues: ILoginValues = {
    username: '',
    password: '',
    server_error: '',
    loggedIn: false
  }

  private validate = (values: ILoginValues) => {
    const errors: ILoginValues = {}
    if (!values.username) {
      errors.username = 'Required'
    }
    if (!values.password) {
      errors.password = 'Required'
    }
    return errors
  }

  public render(): ReactNode {
    const { login } = this.props.authStore
    const isLegalplace = window.location.host === 'smart-docs.legalplace.fr' // TODO deal with that in env variable for client dev-1352
    return (
      <div className="content loginContainer">
        <div className="ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl text-center">{I18n.get('login_title')}</h3>
          </div>
        </div>

        <div className="logFormContainer">
          <div className="grid-container-form">
            <div className="grid-item">
              <Formik
                initialValues={this.initialValues}
                validate={this.validate}
                onSubmit={login}
              >
                {({ handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <h4 className="form-ttl text-center">
                      {I18n.get('login_text')}
                    </h4>
                    <FormField
                      label={I18n.get('username')}
                      type="email"
                      cypressId="login"
                      name="username"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <FormField
                      label={I18n.get('password')}
                      type="password"
                      name="password"
                      cypressId="password"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    <ErrorMessage name="server_error" />
                    <div className="form-footer">
                      <label className="checkbox-container">
                        <input
                          type="checkbox"
                          name="loggedIn"
                          onChange={handleChange}
                        />
                        <span className="checkbox-btn" />
                        <span className="checkbox-text">
                          {I18n.get('keep')}
                        </span>
                      </label>
                      <button
                        className="btn btn-active btn-send btn-double-padding-side"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {I18n.get('login_btn')}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="flex-center">
          <Link className="footer-link" to="/forgot">
            {I18n.get('forgot')}
          </Link>
        </div>
        {isLegalplace && (
          <button
            className="btn btn-active btn-send btn-double-padding-side"
            type="button"
          >
            <a
              className="sso-button"
              href="https://legalplace.auth.eu-west-1.amazoncognito.com/authorize?client_id=t71scbe7fcoas5u50ihcc24sa&response_type=code&scope=email+openid&redirect_uri=https://clear-api.legalplace.fr/api/v1/auth/openid/&identity_provider=serverless-prod-oic-idp"
            >
              Login avec Legalplace
            </a>
          </button>
        )}
      </div>
    )
  }
}

export default LoginPage
