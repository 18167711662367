import React, { Component, createRef } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import './style.scss'
import { action, observable } from 'mobx'
import { OptionV3, VariableV3 } from '@legalplace/models-v3-types'
import unescape from 'lodash/unescape'
import Mousetrap from 'mousetrap'
import createStore, { ICreateStore } from '../../../../store/editor/createStore'
import SelectField from '../../../../components/SelectField/SelectField'
import Icon from '../../../../components/Icons/Icon'
import {
  CHECKICON,
  RADIOICON,
  FIELDICON,
  LISTICON,
  SETTINGS,
  MULTIPLEQUILLICON,
  DELICON,
  EYEHIDEICON
} from '../../../../components/Icons/icons'

import PlusCondition from '../../../../assets/images/plus_condition.png'
import DeleteConditionModal from '../../../../components/DeleteConditionModal'
import DeleteValidationModal from '../../../../components/DeleteValidationModal'
import SelectOutputModal from '../../../../components/SelectOutputModal'
import AutoAdjustableTextarea from '../../../../components/AutoAdjustableTextarea/AutoAdjustableTextarea'
import { catchAction } from '../../../../store/editor/catchActionsDecorator'
import LabelEditor from '../../../../components/Editor/LabelEditor'
import MoveElement from './MoveElement'

const dictionary = {
  fr: {
    'text root': 'Boite info',
    question: 'Question',
    field: 'Champ',
    dropdown: 'Liste déroulante',
    checkbox: 'Case à cocher',
    radio: 'Radio',
    'Add an element': 'Ajouter'
  },
  en: {
    'text root': 'Text',
    question: 'Question',
    field: 'Field',
    dropdown: 'Dropdown',
    checkbox: 'Checkbox',
    radio: 'Radio',
    'Add an element': 'Add an element'
  }
}

I18n.putVocabularies(dictionary)

interface IContractFormProps {
  createStore: ICreateStore
  toggleDrawerHandler?: any
  isConditionsDrawerOpen: boolean
  showPlusIcon: boolean
}

@inject('createStore')
@observer
class ContractForm extends Component<IContractFormProps> {
  @observable isElementsListShown = false

  @observable isElementsListOverflown = false

  @observable sectionTitle = ''

  @observable groupTitle = ''

  @observable editMode = false

  @observable editGroupMode = false

  contentRef = createRef<HTMLDivElement>()

  addElementButtonRef = createRef<HTMLButtonElement>()

  componentDidMount() {
    const mousetrap = new Mousetrap(document.body)

    mousetrap.bind('mod+z', (e) => {
      e.preventDefault()
      this.props.createStore.undo()
    })

    mousetrap.bind(['mod+y', 'mod+shift+z'], (e) => {
      e.preventDefault()
      this.props.createStore.redo()
    })

    mousetrap.bind('mod+s', (e) => {
      e.preventDefault()
      this.props.createStore.saveModelData()
    })
  }

  startElementsListOverflowWatchers = () => {
    /**
     * Watching elements list overflow
     */
    // Getting Content Element
    const contentElement = this.contentRef.current
    if (contentElement !== null) {
      // Removing previous scroll listener in case it exists
      // to avoid duplicating
      window.removeEventListener('resize', this.watchElementsListOverflow)

      // Adding new event listener on scroll
      window.addEventListener('resize', this.watchElementsListOverflow)

      // Using mutation observer to watch children changes
      // (child changes affect scrollHeight of the wizard content)
      const mutationObserver = new MutationObserver(
        this.watchElementsListOverflow
      )
      mutationObserver.observe(contentElement, { childList: true })

      // Executing watcher first time on useEffect
      // since at this moment none of the event
      // listeners recieved anything
      this.watchElementsListOverflow()
    }
  }

  @action
  watchElementsListOverflow = () => {
    // Getting Content Element
    const contentElement = this.contentRef.current
      ? this.contentRef.current.parentElement
      : null

    // Gettting ElementsList element
    const buttonElement = this.addElementButtonRef.current

    // If anyone of them is null, let's stop right here
    if (contentElement === null || buttonElement === null) return

    // Getting button element position
    const { bottom: buttonBottom } = buttonElement.getBoundingClientRect()
    const { bottom: contentBottom } = contentElement.getBoundingClientRect()

    if (contentBottom - buttonBottom > 143) this.isElementsListOverflown = false
    else this.isElementsListOverflown = true
  }

  @catchAction
  @action
  public addNewGroup = (type: string) => {
    // adds new item on the top level
    const { addGroup } = this.props.createStore
    // close popup
    this.isElementsListShown = false
    // call createStore method
    addGroup(type)
  }

  @catchAction
  @action
  public updateElement = (el: any, ids: number[]) => {
    const { updateSelectedElement } = this.props.createStore
    // set element as selectedElement
    updateSelectedElement(el, ids)
  }

  @catchAction
  @action
  public showElementsList = () => {
    // toggle popup
    this.isElementsListShown = !this.isElementsListShown
    this.startElementsListOverflowWatchers()
  }

  @catchAction
  @action
  blurPopup = () => {
    this.isElementsListShown = false
  }

  onSettingsClick = (el: any, ids: any[]) => {
    // set selected element
    this.updateElement(el, ids)
    // open parameters drawer
    this.props.toggleDrawerHandler(true)
  }

  onCopyClick = (
    el: OptionV3 | VariableV3,
    ids: number[],
    variable = false
  ) => {
    // copy element
    const { copyElement } = this.props.createStore
    copyElement(el, ids, variable)
  }

  onDelClick = (el: OptionV3 | VariableV3, ids: number[]) => {
    // delete element
    const { checkForConditions } = this.props.createStore
    // check element type
    // @ts-ignore
    const variable = !!el.type
    // delete element
    checkForConditions(el, ids, variable, true, true)
    // deleteElement(el, ids, variable);
  }

  @catchAction
  @action
  handleAddCondition = (el: OptionV3 | VariableV3) => {
    const { addCondition } = this.props.createStore
    // adding condition
    addCondition(el)
  }

  @catchAction
  @action
  handleTitleEdit = (e: any) => {
    const { editSection, currentSection } = this.props.createStore

    // edit section title
    editSection(currentSection.id, 'label', e.target.value)
  }

  @catchAction
  @action
  toggleEditMode = (name: string) => {
    // toggles edit section title mode
    // @ts-ignore
    this[name] = !this[name]
  }

  @catchAction
  @action
  handleLabelChange = (e: any, el: OptionV3 | VariableV3) => {
    const isOptionV3 = (a: OptionV3 | VariableV3): a is OptionV3 => {
      return Object.prototype.hasOwnProperty.call(a, 'meta')
    }
    if (isOptionV3(el)) {
      if (el.meta && el.meta.type === 'box') {
        el.meta.box = {
          ...el.meta.box,
          content: e.target.value
        }
        return
      }
      el.meta.label = e.target.value
    } else {
      el.label = e.target.value
    }
  }

  renderField = (
    el: OptionV3,
    ids: any[],
    sectionIndex: number,
    counter: number
  ) => {
    // render option by type
    const { showPlusIcon, isConditionsDrawerOpen } = this.props
    // delete html tags from label
    const label = el.meta.label
      .replace(/<\s*\w+[^>]*>/gm, '')
      .replace(/<\s*\/\s*\w+>/gm, '')

    // Checking if element has conditions
    let hasConditions = false
    const { conditions } = el.meta
    if (conditions)
      hasConditions =
        createStore.conditionsCollector(Object.keys(conditions), conditions)
          .length > 0

    // Checking if element is multiple
    let isMultiple = false
    const { multiple } = el.meta
    if (multiple?.enabled === true) isMultiple = true

    // Checking if element has warning
    let hasWarning = false
    const { warning } = el.meta
    if (typeof warning === 'string' && warning.trim().length > 0)
      hasWarning = true

    // Checking if element has helper
    let hasHelper = false
    const { helper } = el.meta
    if (typeof helper === 'string' && helper.trim().length > 0) hasHelper = true

    // Checking if element is second step only
    let isSecondStepOnly = false
    if (Array.isArray(el.meta.step) && el.meta.step.join('') === '2')
      isSecondStepOnly = true

    switch (el.meta.type) {
      case 'box': {
        const boxLabel =
          el.meta.box && typeof el.meta.box.content === 'string'
            ? el.meta.box.content
                .replace(/<\s*\w+[^>]*>/gm, '')
                .replace(/<\s*\/\s*\w+>/gm, '')
            : label
        const str = unescape(boxLabel)
        return (
          <div data-cypressId={`field ${counter}`} className="option-container">
            <p>
              {hasConditions && <span className="has-conditions-tag">C</span>}
              {hasWarning && <span className="has-warning-tag">!</span>}
              {hasHelper && <span className="has-warning-tag">?</span>}
              {isSecondStepOnly && (
                <span className="is-hidden-tag">
                  <Icon iconClass="tree-ico" icon={EYEHIDEICON} color="#000" />
                </span>
              )}
              <AutoAdjustableTextarea
                style={{ resize: 'none' }}
                onChange={(e: any) => this.handleLabelChange(e, el)}
                className="optionLabelInput"
                value={str}
              />
            </p>
            {!isConditionsDrawerOpen && (
              <div className="field-settings-button-holder">
                <button
                  data-cypressId="settings-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onSettingsClick(el, ids)}
                >
                  <Icon
                    iconClass="setting-ico"
                    icon={SETTINGS}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="copy-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onCopyClick(el, ids)}
                >
                  <Icon
                    iconClass="multiple-ico"
                    icon={MULTIPLEQUILLICON}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="del-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onDelClick(el, ids)}
                >
                  <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
                </button>
              </div>
            )}
            {showPlusIcon && el.meta.multiple && el.meta.multiple.enabled && (
              <button
                type="button"
                className="btn-icon plus-icon"
                tabIndex={-1}
                onClick={() => this.handleAddCondition(el)}
              >
                <img src={PlusCondition} alt="" />
              </button>
            )}
          </div>
        )
      }
      case 'static':
        // case 'brut-text':
        return (
          <div
            data-cypressId={`field ${counter}`}
            className="option-container question-container"
          >
            <p>
              <LabelEditor
                value={label}
                onChange={(e: any) => this.handleLabelChange(e, el)}
                borderless
                conditionTag={hasConditions}
                multipleTag={isMultiple}
                warningTag={hasWarning}
                helperTag={hasHelper}
                secondStepOnlyTag={isSecondStepOnly}
              />
            </p>
            {!isConditionsDrawerOpen && (
              <div className="field-settings-button-holder">
                <button
                  data-cypressId="settings-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onSettingsClick(el, ids)}
                >
                  <Icon
                    iconClass="setting-ico"
                    icon={SETTINGS}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="copy-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onCopyClick(el, ids)}
                >
                  <Icon
                    iconClass="multiple-ico"
                    icon={MULTIPLEQUILLICON}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="del-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onDelClick(el, ids)}
                >
                  <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
                </button>
              </div>
            )}
            {showPlusIcon &&
              el.meta.type === 'static' &&
              el.meta.multiple &&
              el.meta.multiple.enabled && (
                <button
                  type="button"
                  className="btn-icon plus-icon"
                  tabIndex={-1}
                  onClick={() => this.handleAddCondition(el)}
                >
                  <img src={PlusCondition} alt="" />
                </button>
              )}
          </div>
        )
      case 'checkbox':
        return (
          <div data-cypressId={`field ${counter}`} className="option-container">
            <div className="check-wrap">
              <div className="checkbox-holder">
                <input disabled type="checkbox" name="checkbox" />
                <label htmlFor="checkbox" />
              </div>
              <LabelEditor
                value={label}
                onChange={(e: any) => this.handleLabelChange(e, el)}
                borderless
                conditionTag={hasConditions}
                multipleTag={isMultiple}
                warningTag={hasWarning}
                helperTag={hasHelper}
                secondStepOnlyTag={isSecondStepOnly}
              />
            </div>
            {!isConditionsDrawerOpen && (
              <div className="field-settings-button-holder">
                <button
                  data-cypressId="settings-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onSettingsClick(el, ids)}
                >
                  <Icon
                    iconClass="setting-ico"
                    icon={SETTINGS}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="copy-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onCopyClick(el, ids)}
                >
                  <Icon
                    iconClass="multiple-ico"
                    icon={MULTIPLEQUILLICON}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="del-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onDelClick(el, ids)}
                >
                  <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
                </button>
              </div>
            )}
            {showPlusIcon && (
              <button
                type="button"
                className="btn-icon plus-icon"
                tabIndex={-1}
                onClick={() => this.handleAddCondition(el)}
              >
                <img src={PlusCondition} alt="" />
              </button>
            )}
          </div>
        )
      case 'radio':
        return (
          <div data-cypressId={`field ${counter}`} className="option-container">
            <div className="check-wrap">
              <div className="radio-holder">
                <input disabled type="radio" name="radio" />
                <label htmlFor="radio" />
              </div>
              <LabelEditor
                value={label}
                onChange={(e: any) => this.handleLabelChange(e, el)}
                borderless
                conditionTag={hasConditions}
                multipleTag={isMultiple}
                warningTag={hasWarning}
                helperTag={hasHelper}
                secondStepOnlyTag={isSecondStepOnly}
              />
            </div>
            {!isConditionsDrawerOpen && (
              <div className="field-settings-button-holder">
                <button
                  data-cypressId="settings-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onSettingsClick(el, ids)}
                >
                  <Icon
                    iconClass="setting-ico"
                    icon={SETTINGS}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="copy-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onCopyClick(el, ids)}
                >
                  <Icon
                    iconClass="multiple-ico"
                    icon={MULTIPLEQUILLICON}
                    color="#adb5bd"
                  />
                </button>
                <button
                  data-cypressId="del-btn"
                  type="button"
                  className="btn-icon"
                  tabIndex={-1}
                  onClick={() => this.onDelClick(el, ids)}
                >
                  <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
                </button>
              </div>
            )}
            {showPlusIcon && (
              <button
                type="button"
                className="btn-icon plus-icon"
                tabIndex={-1}
                onClick={() => this.handleAddCondition(el)}
              >
                <img src={PlusCondition} alt="" />
              </button>
            )}
          </div>
        )
      case 'hidden':
        return <div>hidden</div>
      default:
        return null
    }
  }

  renderVariable = (
    el: VariableV3,
    ids: any[],
    counter: number,
    index: number,
    parentId: number
  ) => {
    // render variable by type
    // eslint-disable-next-line
    const { showPlusIcon, isConditionsDrawerOpen } = this.props
    const label = el.label
      .replace(/<\s*\w+[^>]*>/gm, '')
      .replace(/<\s*\/\s*\w+>/gm, '')

    // Checking if element has conditions
    let hasConditions = false
    const { conditions } = el
    if (conditions)
      hasConditions =
        createStore.conditionsCollector(Object.keys(conditions), conditions)
          .length > 0

    // Checking if element has warning
    let hasWarning = false
    const { warning } = el
    if (typeof warning === 'string' && warning.trim().length > 0)
      hasWarning = true

    // Checking if element has helper
    let hasHelper = false
    const { helper } = el
    if (typeof helper === 'string' && helper.trim().length > 0) hasHelper = true

    // Checking if element is hidden
    let isHidden = false
    if (el.hidden === true) isHidden = true

    // Checking if element is second step only
    let isSecondStepOnly = false
    if (Array.isArray(el.step) && el.step.join('') === '2')
      isSecondStepOnly = true

    if (!el) return null
    switch (el.type) {
      case 'text':
      case 'date':
      case 'textarea':
      case 'number':
      case 'eval':
      case 'hour':
      case 'email':
      case 'mask':
        return (
          <>
            <div
              data-cypressId={`field ${counter}`}
              key={el.id}
              className="select-wrapper"
            >
              <div className="variable-wrapper">
                <div className="conditions-tag-flex">
                  <LabelEditor
                    value={label}
                    onChange={(e: any) => this.handleLabelChange(e, el)}
                    borderless
                    conditionTag={hasConditions}
                    warningTag={hasWarning}
                    helperTag={hasHelper}
                    hiddenTag={isHidden}
                    secondStepOnlyTag={isSecondStepOnly}
                  />
                </div>
                <input
                  disabled
                  className="title-field"
                  type="text"
                  placeholder={el.id.toString()}
                />
              </div>
              {!isConditionsDrawerOpen && (
                <div className="field-settings-button-holder">
                  <button
                    data-cypressId="settings-btn"
                    type="button"
                    className="btn-icon"
                    tabIndex={-1}
                    onClick={() => this.onSettingsClick(el, ids)}
                  >
                    <Icon
                      iconClass="setting-ico"
                      icon={SETTINGS}
                      color="#adb5bd"
                    />
                  </button>
                  <button
                    data-cypressId="copy-btn"
                    type="button"
                    className="btn-icon"
                    tabIndex={-1}
                    onClick={() => this.onCopyClick(el, ids, true)}
                  >
                    <Icon
                      iconClass="multiple-ico"
                      icon={MULTIPLEQUILLICON}
                      color="#adb5bd"
                    />
                  </button>
                  <button
                    data-cypressId="del-btn"
                    type="button"
                    className="btn-icon"
                    tabIndex={-1}
                    onClick={() => this.onDelClick(el, ids)}
                  >
                    <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
                  </button>
                </div>
              )}

              {showPlusIcon && (
                <button
                  type="button"
                  className="btn-icon plus-icon"
                  tabIndex={-1}
                  onClick={() => this.handleAddCondition(el)}
                >
                  <img src={PlusCondition} alt="" />
                </button>
              )}
            </div>

            <MoveElement
              index={index + 1}
              destParent={parentId}
              destParentType="variable"
              parents={[]}
              key={Math.random()}
            />
          </>
        )
      case 'list':
        return (
          <>
            <div
              data-cypressId={`field ${counter}`}
              key={el.id}
              className="select-wrapper"
            >
              <div className="variable-wrapper">
                <div className="field-label">
                  <LabelEditor
                    value={label}
                    onChange={(e: any) => this.handleLabelChange(e, el)}
                    borderless
                    conditionTag={hasConditions}
                    warningTag={hasWarning}
                    helperTag={hasHelper}
                    hiddenTag={isHidden}
                    secondStepOnlyTag={isSecondStepOnly}
                  />
                </div>
                <SelectField
                  disabled
                  placeholder={el.id.toString()}
                  setFieldValue={() => {}}
                  name="Type"
                  options={[]}
                />
              </div>
              {!isConditionsDrawerOpen && (
                <div className="field-settings-button-holder">
                  <button
                    data-cypressId="settings-btn"
                    type="button"
                    className="btn-icon"
                    tabIndex={-1}
                    onClick={() => this.onSettingsClick(el, ids)}
                  >
                    <Icon
                      iconClass="setting-ico"
                      icon={SETTINGS}
                      color="#adb5bd"
                    />
                  </button>
                  <button
                    data-cypressId="copy-btn"
                    type="button"
                    className="btn-icon"
                    tabIndex={-1}
                    onClick={() => this.onCopyClick(el, ids, true)}
                  >
                    <Icon
                      iconClass="multiple-ico"
                      icon={MULTIPLEQUILLICON}
                      color="#adb5bd"
                    />
                  </button>
                  <button
                    data-cypressId="del-btn"
                    type="button"
                    className="btn-icon"
                    tabIndex={-1}
                    onClick={() => this.onDelClick(el, ids)}
                  >
                    <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
                  </button>
                </div>
              )}
              {showPlusIcon && (
                <button
                  type="button"
                  className="btn-icon plus-icon"
                  tabIndex={-1}
                  onClick={() => this.handleAddCondition(el)}
                >
                  <img src={PlusCondition} alt="" />
                </button>
              )}
            </div>

            <MoveElement
              index={index + 1}
              destParent={parentId}
              destParentType="variable"
              parents={[]}
              key={Math.random()}
            />
          </>
        )
      default:
        return null
    }
  }

  renderList = (
    elems: any,
    ids: any[],
    sectionIndex: number,
    counter: number,
    parents: number[] = []
  ) => {
    // recursively renders element, it's childs and variables
    // eslint-disable-next-line

    const { addFormElement, document, moveElement } = this.props.createStore
    const { isMoving } = moveElement
    const filteredElems = elems.filter((el: any) => {
      const option = typeof +el === 'number' ? document.options[el] : el
      if (
        !option ||
        (option.meta &&
          (option.meta.type === 'hidden' || option.meta.type === 'repeated'))
      ) {
        return false
      }
      return true
    })
    // eslint-disable-next-line
    return filteredElems.map((el: any, index: number) => {
      counter += 1
      const option = typeof +el === 'number' ? document.options[el] : el
      // eslint-disable-next-line
      // if (!option || (option.meta && (option.meta.type === 'hidden' || option.meta.type === 'repeated'))) return;
      return (
        <>
          <li
            data-cypressId={`block ${counter}`}
            key={option.meta.id}
            className="form-element-box"
          >
            {option.meta &&
              option.meta.multiple &&
              option.meta.multiple.enabled && (
                <span className="copy-icon">
                  <Icon
                    iconClass="multiple-ico"
                    icon={MULTIPLEQUILLICON}
                    color="#d6b8f2"
                  />
                </span>
              )}
            <div className="select-form-box">
              <div className="select-form-box">
                {this.renderField(
                  option,
                  [...ids, option.meta.id],
                  sectionIndex,
                  counter
                )}
              </div>
              {option.meta.type !== 'box' && !isMoving && (
                <div className="add-form-element-box">
                  <span className="add-item">
                    <span className="plus">+</span>
                    <span className="plus-title"> Ajouter</span>
                  </span>
                  <ul className="elements-list child-list">
                    <li
                      data-cypressId={`add-elem ${counter} 1`}
                      onClick={() => {
                        addFormElement('box', true, option.meta.id, [
                          ...ids,
                          option.meta.id
                        ])
                      }}
                    >
                      <span className="ico-holder text">Hh</span>{' '}
                      {I18n.get('text')}
                    </li>
                    <li
                      data-cypressId={`add-elem ${counter} 2`}
                      onClick={() => {
                        addFormElement('static', true, option.meta.id, [
                          ...ids,
                          option.meta.id
                        ])
                      }}
                    >
                      <span className="ico-holder dark-text white primary">
                        ?
                      </span>{' '}
                      {I18n.get('question')}
                    </li>
                    <li
                      data-cypressId={`add-elem ${counter} 3`}
                      onClick={() => {
                        addFormElement('text', true, option.meta.id, [
                          ...ids,
                          option.meta.id
                        ])
                      }}
                    >
                      <span className="ico-holder ">
                        <Icon
                          iconClass="field-ico"
                          icon={FIELDICON}
                          color="#fff"
                        />
                      </span>{' '}
                      {I18n.get('field')}
                    </li>
                    <li
                      data-cypressId={`add-elem ${counter} 4`}
                      onClick={() => {
                        addFormElement('list', true, option.meta.id, [
                          ...ids,
                          option.meta.id
                        ])
                      }}
                    >
                      <span className="ico-holder dark-text">
                        <Icon
                          iconClass="list-ico"
                          icon={LISTICON}
                          color="#fff"
                        />
                      </span>{' '}
                      {I18n.get('dropdown')}
                    </li>
                    <li
                      data-cypressId={`add-elem ${counter} 5`}
                      onClick={() => {
                        addFormElement('checkbox', true, option.meta.id, [
                          ...ids,
                          option.meta.id
                        ])
                      }}
                    >
                      <span className="ico-holder dark-text">
                        <Icon
                          iconClass="check-ico"
                          icon={CHECKICON}
                          color="#fff"
                        />
                      </span>{' '}
                      {I18n.get('checkbox')}
                    </li>
                    <li
                      data-cypressId={`add-elem ${counter} 6`}
                      onClick={() => {
                        addFormElement('radio', true, option.meta.id, [
                          ...ids,
                          option.meta.id
                        ])
                      }}
                    >
                      <span className="ico-holder dark-text">
                        <Icon
                          iconClass="radio-ico"
                          icon={RADIOICON}
                          color="#fff"
                        />
                      </span>{' '}
                      {I18n.get('radio')}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <ul>
              <MoveElement
                index={0}
                destParent={option.meta.id}
                destParentType="variable"
                parents={[...ids, option.meta.id]}
                key={Math.random()}
              />
              {option.variables.length
                ? option.variables.map((variable: any, varIndex: number) => {
                    const currentVar = document.variables[variable]
                    return this.renderVariable(
                      currentVar,
                      [...ids, option.meta.id],
                      counter + 1,
                      varIndex,
                      option.meta.id
                    )
                  })
                : null}
            </ul>
            <ul>
              <MoveElement
                index={elems.indexOf(el)}
                destParent={option.meta.id}
                destParentType="option"
                parents={[...ids, option.meta.id]}
                key={Math.random()}
              />
              {option.options.length
                ? this.renderList(
                    option.options,
                    [...ids, option.meta.id],
                    sectionIndex,
                    counter,
                    [...parents, option.meta.id]
                  )
                : null}
            </ul>
          </li>

          <MoveElement
            index={elems.indexOf(el) + 1}
            destParent={
              parents.length === 0 ? sectionIndex : parents[parents.length - 1]
            }
            destParentType={parents.length === 0 ? 'section' : 'option'}
            parents={parents}
            key={Math.random()}
          />
        </>
      )
    })
  }

  generateJSON = () => {
    // preparing model to save
    const { clearAndStringify, document } = this.props.createStore
    console.log('preparedJSON', clearAndStringify(document))
  }

  render() {
    // eslint-disable-next-line
    const { currentSection, document, deleteConditionIsOpen, deleteValidationIsOpen, selectOutputModalIsOpen, moveElement } = this.props.createStore
    const { sections } = document.documents.main
    const { isMoving } = moveElement
    const sectionIndex = sections.indexOf(currentSection)
    const counter = 0

    return (
      <div className="create-form" ref={this.contentRef}>
        <div className="title-holder">
          <div>
            <span>{currentSection && sectionIndex + 1}.</span>
            <input
              className="title-field section-input"
              value={currentSection && currentSection.label}
              onChange={(e) => this.handleTitleEdit(e)}
              type="text"
            />
          </div>
        </div>
        {currentSection && currentSection.id && (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form-group" key={1}>
              <ul>
                <MoveElement
                  index={0}
                  destParent={sectionIndex}
                  destParentType="section"
                  parents={[]}
                  key={Math.random()}
                />
                {this.renderList(
                  currentSection.options,
                  [],
                  sectionIndex,
                  counter
                )}
              </ul>
            </div>
            {!isMoving && (
              <div className="add-element-wrapper">
                <button
                  ref={this.addElementButtonRef}
                  data-cypressId="root-el-btn"
                  type="button"
                  tabIndex={-1}
                  className="btn-blue button-active add-element-btn primary"
                  onClick={this.showElementsList}
                >
                  <span>+</span>
                  {I18n.get('Add an element')}
                </button>
                {this.isElementsListShown ? (
                  <>
                    <div
                      onClick={this.blurPopup}
                      className="elements-list-overlay"
                    />
                    <ul
                      className={`elements-list ${
                        this.isElementsListOverflown ? 'alignOnTop' : ''
                      }`}
                    >
                      <li
                        data-cypressId="root-el-add question"
                        onClick={() => this.addNewGroup('static')}
                      >
                        <span className="ico-holder dark-text white primary">
                          ?
                        </span>
                        {I18n.get('question')}
                      </li>
                      <li
                        data-cypressId="root-el-add text"
                        onClick={() => this.addNewGroup('box')}
                      >
                        <span className="ico-holder text primary">Hh</span>
                        {I18n.get('text root')}
                      </li>
                      <li
                        data-cypressId="root-el-add checkbox"
                        onClick={() => this.addNewGroup('checkbox')}
                      >
                        <span className="ico-holder dark-text primary">
                          <Icon
                            iconClass="check-ico"
                            icon={CHECKICON}
                            color="#fff"
                          />
                        </span>
                        {I18n.get('checkbox')}
                      </li>
                      {/* <li onClick={() => this.addNewGroup('radio')}><span className="ico-holder dark-text"><Icon iconClass="radio-ico" icon={RADIOICON} color="#fff"/></span> Radio</li> */}
                    </ul>
                  </>
                ) : null}
              </div>
            )}
          </form>
        )}
        <SelectOutputModal open={selectOutputModalIsOpen} />
        <DeleteConditionModal open={deleteConditionIsOpen} />
        <DeleteValidationModal open={deleteValidationIsOpen} />
      </div>
    )
  }
}

export default ContractForm
