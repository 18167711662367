import React from 'react'
import ReactDOM from 'react-dom'
import './style.scss'
import { I18n } from 'aws-amplify'
import tabs, { ParamsModalTabConfigType } from './tabs'
import createStore from '../../store/editor/createStore'

const dictionary = {
  fr: {
    close: 'Fermer'
  },
  en: {
    close: 'Close'
  }
}

I18n.putVocabularies(dictionary)

type ParamsModalProps = {
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
}

type ParamsModalState = {
  currentTab: string
  tabs: ParamsModalTabConfigType[]
  params: Record<string, Record<string, any>>
}

class ParamsModal extends React.Component<ParamsModalProps, ParamsModalState> {
  elem = document.createElement('div')

  constructor(props: ParamsModalProps) {
    super(props)

    this.state = {
      currentTab: createStore.modelParamsTab || 'default',
      tabs,
      params: {}
    }

    this.elem.classList.add('params-modal-root')
  }

  componentDidMount() {
    document.body.appendChild(this.elem)
  }

  componentWillUnmount() {
    document.body.removeChild(this.elem)
  }

  /**
   * Return current tab config object
   */
  getCurrentTab() {
    return this.state.tabs.filter((tab) => {
      if (this.state.currentTab === 'default' && tab.isDefault === true)
        return true
      if (this.state.currentTab === tab.slug) return true
      return false
    })[0]
  }

  /**
   * Renders tabs list for sidebar
   */
  renderTabsList = () => {
    return this.state.tabs.map((tab, index) => {
      const isActive = this.getCurrentTab()?.slug === tab.slug
      const classNames = [isActive && 'active']
        .filter((v) => typeof v === 'string')
        .join(' ')

      const clickEventHandler = () => {
        if (isActive === true) return
        this.setState({ currentTab: tab.slug })
      }

      return (
        <li
          key={`pm-tab-${tab.slug}-${index}`}
          onClick={clickEventHandler}
          className={classNames}
        >
          {tab.title}
        </li>
      )
    })
  }

  /**
   * Returns tab params
   */
  getTabParams = () => {
    const currentTab = this.getCurrentTab()

    if (this.state.params[currentTab.slug] === undefined) {
      this.setState({
        params: {
          ...this.state.params,
          [currentTab.slug]: {}
        }
      })
      return {}
    }
    return this.state.params[currentTab.slug]
  }

  /**
   * Sets tab params
   */
  setTabParams = (params: Record<string, any>) => {
    const currentTab = this.getCurrentTab()

    this.setState({
      params: {
        ...this.state.params,
        [currentTab.slug]: params
      }
    })
  }

  /**
   * Closes Modal
   */
  closeModal = (keepCurrentTab: boolean = false) => {
    if (keepCurrentTab === true)
      createStore.toggleModelParamsTab(this.state.currentTab)
    else createStore.toggleModelParamsTab('default')
    createStore.toggleModal('modelOptionsIsOpen', false)
  }

  /**
   * Handle save
   */
  handleSave = (keepCurrentTab: boolean = false) => {
    this.state.tabs.forEach((tab) => {
      const tabParams = this.state.params[tab.slug]
      if (typeof tabParams === 'object' && Object.keys(tabParams).length > 0)
        tab.onSave(tabParams)
    })
    this.closeModal(keepCurrentTab)
  }

  /**
   * Renders portal content
   */
  portalContent() {
    const tab = this.getCurrentTab()

    const ContentComponent = tab.content
    return (
      <div className="pm-container">
        <div className="pm-sidebar">
          <ul className="pm-tabs">{this.renderTabsList()}</ul>
        </div>
        <div className="pm-content">
          <div className="pm-tab-container">
            <div className="pm-tab-header">
              <div className="pm-title">{tab.title}</div>
            </div>
            <ContentComponent
              closeModal={this.handleSave}
              setParams={this.setTabParams}
              getParams={this.getTabParams}
              toggleConditionsDrawer={this.props.toggleConditionsDrawer}
            />
          </div>
          <div className="pm-footer">
            <button
              type="button"
              className="secondary"
              onClick={() => this.handleSave()}
            >
              {I18n.get('close')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Creates & renders React Portal
   */
  render() {
    return ReactDOM.createPortal(this.portalContent(), this.elem)
  }
}

export default ParamsModal
