import { I18n } from 'aws-amplify'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import 'react-quill/dist/quill.snow.css'
import { match, RouteComponentProps, withRouter } from 'react-router'
import DocumentParamsModal from '../../../components/DocumentParams'
import ExtractionModal from '../../../components/ExtractionModal'
import MoveModal from '../../../components/MoveModal'
import MultiplesModal from '../../../components/MultiplesModal'
// import OptionsModal from '../../../components/OptionsModal'
import PrefillModal from '../../../components/PrefillModal'
import createStore, { ICreateStore } from '../../../store/editor/createStore'
import ConditionsDrawer from './conditionsDrawer/conditionsDrawer'
import FormToolbar from './contract-form/toolbar/FormToolbar'
import ContractContentDrawer from './contractContentDrawer/ContractContentDrawer'
import ElementStateDrawer from './elementStateDrawer/ElementStateDrawer'
import EditorToolbar from './quill-editor/toolbar/EditorToolbar'
import SectionsContainer from './sectionContainer/SectionContainer'
import SlateEditor from './slate-editor'
import './style.scss'
import { catchAction } from '../../../store/editor/catchActionsDecorator'
import ParamsModal from '../../../components/ParamsModal'
import PdfEditor from './pdf-editor'
import PdfSidePreview from './pdf-editor/sidePreview'

const dictionary = {
  fr: {
    'New document': 'Nouveau document'
  },
  en: {
    'New document': 'New document'
  }
}

interface IRouteParams {
  id: string
}

I18n.putVocabularies(dictionary)

interface IRouteParams {
  id: string
  permalink: string
}

interface ICreateContractPageProps extends RouteComponentProps {
  createStore?: ICreateStore | any
  match: match<IRouteParams>
}

@inject('createStore')
@observer
class CreateContractPage extends Component<ICreateContractPageProps> {
  @observable isDrawerOpen = false

  @observable isConditionsDrawerOpen = false

  conditionalDrawerSaveCallback: null | (() => void) = null

  firstColRef = React.createRef<HTMLDivElement>()

  secondColRef = React.createRef<HTMLDivElement>()

  setIntervalId: ReturnType<typeof setTimeout> | null = null

  slateEditorRef = React.createRef<SlateEditor>()

  async UNSAFE_componentWillMount() {
    const { permalink, id } = this.props.match.params
    const { getTemplateData, meta_permalink } = this.props.createStore
    // if there is no model data - then fetch
    if (!meta_permalink) {
      await getTemplateData(permalink, id)
    }
  }

  componentDidMount(): void {
    const { fillMetaData, setAutosaveModelData } = this.props.createStore
    setAutosaveModelData(true)
    setTimeout(() => {
      // filling metadata object
      fillMetaData()
    }, 0)

    createStore.setSlateEditorRef(this.slateEditorRef)
  }

  componentDidUpdate() {
    createStore.setSlateEditorRef(this.slateEditorRef)
  }

  componentWillUnmount() {
    const { setAutosaveModelData } = this.props.createStore
    setAutosaveModelData(false)
  }

  @catchAction
  @action
  public toggleDrawer = (open: boolean) => {
    const { updateSelectedElement, paramsWasShown } = this.props.createStore

    if (!open && !paramsWasShown) {
      updateSelectedElement({})
    }
    this.isDrawerOpen = open
  }

  @catchAction
  @action
  public toggleConditionsDrawer = (
    open: boolean,
    type: string,
    i?: number,
    params?: {
      id?: number
      didSave?: boolean
      onSave?: () => void
    }
  ) => {
    const {
      setConditionedEl,
      toggleModal,
      docParamsWasShown,
      modelParamsWasShown
    } = this.props.createStore

    // Making sure there is a selected output
    this.isConditionsDrawerOpen = open
    if (open) {
      setConditionedEl(type, i, params ? params.id : undefined)
    } else if (docParamsWasShown) {
      toggleModal('documentParamsIsOpen', true)
      this.props.createStore.toggleDocParamsWasShown(false)
    } else if (modelParamsWasShown) {
      toggleModal('modelOptionsIsOpen', true)
      this.props.createStore.toggleModelParamsWasShown(false)
    }

    // Setting onSave callback function
    if (params && typeof params.onSave === 'function') {
      this.conditionalDrawerSaveCallback = params.onSave
    }

    // Executing onSave callback if we have any and resetting it to null
    if (
      typeof this.conditionalDrawerSaveCallback === 'function' &&
      params &&
      params.didSave === true
    ) {
      this.conditionalDrawerSaveCallback()
      this.conditionalDrawerSaveCallback = null
    }
  }

  render() {
    const {
      // contractTemplate,
      selectedElement,
      addSection,
      setSection,
      currentSectionId,
      currentSection,
      // sections,
      undo,
      redo,
      currentState,
      history,
      extractionIsOpen,
      modelOptionsIsOpen,
      toggleModal,
      documentParamsIsOpen,
      multiplesIsOpen,
      document,
      currentDocument,
      addDocument,
      duplicateDocument,
      setCurrentDocument,
      updateSelectedElement,
      slate,
      showPlusIcon,
      toggleParamsWasShown,
      conditionsCollector,
      moveIsOpen,
      pdfEditorIsOpen,
      parentDropdownOptions,
      setNewOptionIndex,
      optionParent,
      setNewOptionInSectionIndex,
      prefillIsOpen,
      paramsWasShown,
      togglePrefillWasShown,
      selectedElementPrefillings,
      setPrefillings,
      setNewSectionIndex,
      moveElement
    } = this.props.createStore
    let childId = -1
    if (slate && slate.value.anchorBlock) {
      childId = slate.value.anchorBlock.data.get('dataId')
    }
    let question
    if (childId >= 0) {
      // eslint-disable-next-line
      question = Object.values(document.options).find((option: any) => {
        if (option.meta && option.meta.type === 'repeated')
          return option.options.find((children: any) => +children === +childId)
      })
    }

    // @ts-ignore
    const value = question ? question.meta.repeatOption : undefined
    const documents = Object.keys(document.documents).map((key: string) => ({
      value: key,
      label: document.documents[key].name
    }))
    const options = parentDropdownOptions()

    // Tests if document is PDF
    const { pdf } = document.documents[currentDocument]
    const isPdf =
      typeof pdf === 'object' &&
      typeof pdf.id === 'string' &&
      typeof pdf.form === 'object' &&
      typeof pdf.thumbnails === 'object'

    return (
      <div className="create-contract">
        <div className="row">
          <div className="col" ref={this.firstColRef}>
            {/* // TODO: REMOVE TS IGNORE AND CHECK INJECT INFERING
                // @ts-ignore */}
            <FormToolbar
              currentSection={currentSection}
              currentSectionId={currentSectionId}
              sections={document.documents.main.sections}
              setSection={setSection}
              addSection={addSection}
              history={history}
              currentState={currentState}
              undo={undo}
              redo={redo}
              toggleDrawerHandler={this.toggleDrawer}
              updateSelectedElement={updateSelectedElement}
            />
            <div className="content">
              {/* // TODO: REMOVE TS IGNORE AND CHECK INJECT INFERING
                // @ts-ignore */}
              <ContractContentDrawer />
              <SectionsContainer
                showPlusIcon={showPlusIcon}
                isConditionsDrawerOpen={this.isConditionsDrawerOpen}
                toggleDrawerHandler={this.toggleDrawer}
              />
              {/* <ContractForm toggleDrawerHandler={this.toggleDrawer}/> */}
            </div>
          </div>

          <div className="col editor-col" ref={this.secondColRef}>
            {moveElement.isMoving === true && (
              <div className="move-element-hide-column" />
            )}
            <EditorToolbar
              toggleModal={toggleModal}
              toggleConditionsDrawer={this.toggleConditionsDrawer}
              documents={documents}
              currentDocument={currentDocument}
              setCurrentDocument={setCurrentDocument}
              addDocument={addDocument}
              duplicateDocument={duplicateDocument}
            />
            {!isPdf && (
              <SlateEditor
                toggleConditionsDrawer={this.toggleConditionsDrawer}
                ref={this.slateEditorRef}
              />
            )}
            {isPdf && <PdfSidePreview />}
          </div>
        </div>
        {/* <div className="preview">
          <p className="preview-label">PREVIEW</p>
          <div className="preview-content" dangerouslySetInnerHTML={{__html: contractTemplate}}/>
        </div> */}
        <ElementStateDrawer
          toggleConditionsDrawer={this.toggleConditionsDrawer}
          selectedElement={selectedElement}
          isDrawerOpen={this.isDrawerOpen}
          toggleDrawer={this.toggleDrawer}
        />
        {/* // TODO: REMOVE TS IGNORE AND CHECK INJECT INFERING
          // @ts-ignore */}
        <ConditionsDrawer
          toggleConditionsDrawer={this.toggleConditionsDrawer}
          toggleDrawer={this.toggleDrawer}
          isConditionsDrawerOpen={this.isConditionsDrawerOpen}
        />
        <ExtractionModal open={extractionIsOpen} />
        {/* <OptionsModal open={modelOptionsIsOpen} /> */}
        {modelOptionsIsOpen && (
          <ParamsModal toggleConditionsDrawer={this.toggleConditionsDrawer} />
        )}
        {pdfEditorIsOpen && <PdfEditor />}
        {/* // TODO: REMOVE TS IGNORE AND CHECK INJECT INFERING
          // @ts-ignore */}
        <DocumentParamsModal
          open={documentParamsIsOpen}
          toggleConditionsDrawer={this.toggleConditionsDrawer}
        />
        {/* // TODO: REMOVE TS IGNORE AND CHECK INJECT INFERING
          // @ts-ignore */}
        <MultiplesModal multipleValue={value} open={multiplesIsOpen} />
        <MoveModal
          setNewOptionInSectionIndex={setNewOptionInSectionIndex}
          optionParent={optionParent}
          setNewOptionIndex={setNewOptionIndex}
          open={moveIsOpen}
          options={options}
          toggleModal={toggleModal}
          selectedElement={selectedElement}
          setNewSectionIndex={setNewSectionIndex}
          toggleDrawerHandler={this.toggleDrawer}
        />
        <PrefillModal
          toggleModal={toggleModal}
          open={prefillIsOpen}
          selectedElement={selectedElement}
          conditionsCollector={conditionsCollector}
          toggleConditionsDrawer={this.toggleConditionsDrawer}
          toggleDrawer={this.toggleDrawer}
          paramsWasShown={paramsWasShown}
          toggleParamsWasShown={toggleParamsWasShown}
          togglePrefillWasShown={togglePrefillWasShown}
          selectedElementPrefillings={selectedElementPrefillings}
          setPrefillings={setPrefillings}
        />
        {/* <Button onClick={() => this.toggleDrawer( true)}>Open Bottom</Button> */}
      </div>
    )
  }
}

export default withRouter(CreateContractPage)
