import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import LabelEditor from '../../../../../../../components/Editor/LabelEditor'

const dictionary = {
  fr: {
    Label: 'Label'
  },
  en: {
    Label: 'Label'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits label for question, radio & checkbox
 */
export const Label: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const { label } = state.option.meta
  return (
    <div className="label-holder">
      <LabelEditor
        value={label}
        meta
        name="label"
        setFieldValue={(name, value) => {
          state.updateLabel(value)
        }}
        label={I18n.get('Label')}
      />
    </div>
  )
}
