import React from 'react'
import { I18n } from 'aws-amplify'
import Icon from '../../../../../../components/Icons/Icon'
import { DELICON } from '../../../../../../components/Icons/icons'

const dictionary = {
  fr: {
    move: 'Déplacer',
    cancel: 'Annuler',
    save: 'Enregistrer'
  },
  en: {
    move: 'Move',
    cancel: 'Cancel',
    save: 'Save'
  }
}
I18n.putVocabularies(dictionary)

interface IProps {
  saveUpdates: () => void
  closeDrawer: () => void
  moveVariable: () => void
  deleteVariable: () => void
}

export const DrawerHead: React.FC<IProps> = ({
  deleteVariable,
  saveUpdates,
  closeDrawer,
  moveVariable
}) => {
  return (
    <div>
      <div className="btn-wrapper">
        <div className="btn-holder">
          <button type="button" className="btn-icon" onClick={deleteVariable}>
            <Icon icon={DELICON} color="#adb5bd" />
          </button>
        </div>
        <div className="btn-holder">
          <button type="button" className=" secondary" onClick={moveVariable}>
            {I18n.get('move')}
          </button>

          <button type="button" className=" secondary" onClick={closeDrawer}>
            {I18n.get('cancel')}
          </button>
          <button type="button" className=" primary" onClick={saveUpdates}>
            {I18n.get('save')}
          </button>
        </div>
      </div>
    </div>
  )
}
