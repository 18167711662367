import React from 'react'
import ReactDOM from 'react-dom'
import ModelNotesModal from './components/ModelNotesModal'
import './style.scss'

const openModelNotes = (disableAdding: boolean = false): void => {
  const el = document.createElement('div')
  const close = () => {
    document.body.removeChild(el)
  }
  ReactDOM.render(
    <div className="modelnotes-root" role="dialog">
      <ModelNotesModal close={close} disableAdding={disableAdding} />
    </div>,
    el
  )
  document.body.appendChild(el)
}

export default openModelNotes
