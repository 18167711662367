import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import { HelperHide } from './components/HelperHide'
import { HelperContent } from './components/HelperContent'
import { WarningHide } from './components/WarningHide'
import { WarningContent } from './components/WarningContent'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: { messages_category: 'Messages d’aides' },
  en: { messages_category: 'Help messages' }
}
I18n.putVocabularies(dictionary)

/**
 * Messages option params
 */
const MessagesOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <OptionContext.Consumer>
      {(state) => {
        const toggleCollapsed = () => setCollapsed(!collapsed)
        const { type } = state.option.meta

        if (!['radio', 'checkbox', 'static'].includes(type)) return <></>

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('messages_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <>
                <HelperHide state={state} />
                <HelperContent state={state} />
                <WarningHide state={state} />
                <WarningContent state={state} />
              </>
            )}
          </div>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default MessagesOptionParams
