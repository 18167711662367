import React from 'react'
import { VariableV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    'Sub-type': 'Sous-type'
  },
  en: {
    'Sub-type': 'Sub-type'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable subtype
 */
export const SubType: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { maskOptions } = createStore
  const { type } = state.variable

  if (type === 'list') return null

  return (
    <SelectField
      setFieldValue={(name, value: VariableV3['type']) => {
        state.updateType(value)
      }}
      value={type}
      name="mask_type"
      label={I18n.get('Sub-type')}
      options={maskOptions}
    />
  )
}
