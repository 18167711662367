import React from 'react'

import './style.scss'

const Button = (props: any) => {
  const { children, type, handler, className } = props
  return (
    <button
      type="button"
      onClick={handler}
      className={`button ${type === 'big' && 'big-button'} ${className}`}
    >
      {children}
    </button>
  )
}

export default Button
