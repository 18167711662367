import { useSlate } from '@legalplace/slate-react'
import { css, keyframes } from 'emotion'
import React, { MouseEvent } from 'react'
import EditorHelpers, { EditorBlockTypes } from '../../helpers/EditorHelpers'
import { ReactComponent as ChevronDown } from '../select/chevron-down.svg'

const ToolbarStyleSelect: React.FC = () => {
  const editor = useSlate()

  const options = [
    { label: 'Normal', value: 'none' },
    { label: 'Titre 1', value: 'heading-one' },
    { label: 'Titre 2', value: 'heading-two' },
    { label: 'Titre 3', value: 'heading-three' },
    { label: 'Titre 4', value: 'heading-four' },
    { label: 'Titre 5', value: 'heading-five' },
    { label: 'Titre 6', value: 'heading-six' }
  ]

  let selectedLabel = 'Normal'
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].value === 'none') continue
    const type = options[i].value as EditorBlockTypes
    if (EditorHelpers.isBlockActive(editor, type)) {
      selectedLabel = options[i].label
      break
    }
  }

  const handleClick = (
    event: MouseEvent<HTMLSpanElement>,
    type: EditorBlockTypes | 'none'
  ) => {
    event.preventDefault()
    if (type !== 'none' && !EditorHelpers.isBlockActive(editor, type)) {
      EditorHelpers.toggleBlock(editor, type)
    } else {
      for (let i = 0; i < options.length; i += 1) {
        if (options[i].value === 'none') continue
        const currentType = options[i].value as EditorBlockTypes
        if (EditorHelpers.isBlockActive(editor, currentType)) {
          EditorHelpers.toggleBlock(editor, currentType)
        }
      }
    }
  }

  const dropDownSlideUp = keyframes`
    from {
      margin-top: 20px;
    }
    to {
      margin-top: 10px;
    }
  `

  const style = css`
    & {
      .select {
        background: #fff;
        width: 100px;
        border-radius: 4px;
        border: 1px solid #dee2e6;
        box-sizing: border-box;
        line-height: 18px;
        height: 24px;
        font-size: 12px;
        padding: 2px 4px;
        cursor: pointer;
        position: relative;

        .icon {
          position: absolute;
          right: 3px;
          top: 3px;

          svg {
            width: 16px;
            height: 16px;

            * {
              stroke: #dee2e6;
            }
          }
        }

        .current-value {
          font-size: 10px;
        }

        .dropdown {
          position: absolute;
          background: #fff;
          z-index: 9999999;
          list-style: none;
          border-radius: 4px;
          max-height: 100px;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05),
            0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.05);
          margin-top: 10px;
          visibility: hidden;
          opacity: 0;

          :after {
            position: absolute;
            width: 100%;
            height: 20px;
            background: transparent;
            content: '';
            top: -20px;
            left: 0;
          }

          :before {
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            content: '';
            background: #fff;
            top: -5px;
            left: 10px;
            position: absolute;
            z-index: -1;
          }

          ul {
            overflow-y: auto;
            list-style: none;
            padding: 0;
            margin: 0;
            min-width: 100px;
            max-height: 100px;

            li {
              padding: 10px;
              font-size: 12px;
              cursor: pointer;
              padding-right: 20px;

              &:hover {
                background: #f6f6f6;
              }
            }
          }
        }

        &:hover {
          padding: 1px 3px;
          border: 2px solid #3392ff;
          transition: 0.2s;

          .dropdown {
            visibility: visible;
            opacity: 1;
            transition: 0.2s;
            animation: ease-in ${dropDownSlideUp} 0.2s;
          }

          .icon {
            right: 2px;
            top: 2px;
            transition: 0.2s;

            svg {
              transition: 0.2s;
              * {
                stroke: #3392ff;
              }
            }
          }
        }
      }
    }
  `

  return (
    <>
      <span className={style} onMouseDown={(e) => e.preventDefault()}>
        <div className="select" onMouseDown={(e) => e.preventDefault()}>
          <div
            className="current-value"
            onMouseDown={(e) => e.preventDefault()}
          >
            {selectedLabel}
          </div>
          <div className="icon" onMouseDown={(e) => e.preventDefault()}>
            <ChevronDown />
          </div>
          <div onMouseDown={(e) => e.preventDefault()} className="dropdown">
            <ul onMouseDown={(e) => e.preventDefault()}>
              {options.map((option) => (
                <li
                  key={`style-option-${option.value}`}
                  onClick={(e) => {
                    const value = option.value as EditorBlockTypes
                    handleClick(e, value)
                  }}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </span>
    </>
  )
}

export default ToolbarStyleSelect
