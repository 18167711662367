import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import 'react-quill/dist/quill.snow.css'
import ContractForm from '../contract-form/ContractForm'
import { ICreateStore } from '../../../../store/editor/createStore'

const dictionary = {
  fr: {
    'New document': 'Nouveau document'
  },
  en: {
    'New document': 'New document'
  }
}

I18n.putVocabularies(dictionary)

interface ICreateContractPageProps {
  createStore?: ICreateStore | any
  toggleDrawerHandler: any
  isConditionsDrawerOpen: boolean
  showPlusIcon: boolean
}

@inject('createStore')
@observer
class SectionsContainer extends Component<ICreateContractPageProps> {
  render() {
    const {
      toggleDrawerHandler,
      isConditionsDrawerOpen,
      showPlusIcon
    } = this.props
    return (
      <div className="sections-wrapper">
        {/* // TODO: REMOVE TS IGNORE AND CHECK INJECT INFERING
          // @ts-ignore */}
        <ContractForm
          showPlusIcon={showPlusIcon}
          isConditionsDrawerOpen={isConditionsDrawerOpen}
          toggleDrawerHandler={toggleDrawerHandler}
        />
      </div>
    )
  }
}

export default SectionsContainer
