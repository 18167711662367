import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    Label: 'Label'
  },
  en: {
    Label: 'Label'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option second step
 */
export const MultipleLabel: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  if (!state.option.meta.multiple || !state.option.meta.multiple?.enabled)
    return null
  const label = state.option.meta.multiple.label || ''

  return (
    <Input
      value={label}
      meta
      name="label"
      setFieldValue={(name: string, value: string) =>
        state.updateMultipleLabel(value)
      }
      label={I18n.get('Label')}
    />
  )
}
