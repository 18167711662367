import React, { Component } from 'react'
import { I18n } from 'aws-amplify'
import { WithContext as ReactTags, Tag } from 'react-tag-input'

const dictionary = {
  fr: {
    'Add new tag': 'Ajouter un tag'
  },
  en: {
    'Add new tag': 'Add new tag'
  }
}

I18n.putVocabularies(dictionary)

type TagFieldProps = {
  label?: string
  name: string
  meta?: boolean
  value: string[]
  setFieldValue: (name: string, value: string[], meta: boolean) => void
}
type TagFieldState = {
  tags: Tag[]
}

class TagField extends Component<TagFieldProps, TagFieldState> {
  constructor(props: TagFieldProps) {
    super(props)

    this.state = {
      tags: Array.isArray(props.value)
        ? props.value.map((v: string) => ({ id: v, text: v }))
        : []
    }
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)
    this.handleDrag = this.handleDrag.bind(this)
  }

  handleDelete(i: number) {
    const { tags } = this.state
    this.setState(
      {
        tags: tags.filter((tag, index) => index !== i)
      },
      () => {
        this.updateValue(this.state.tags)
      }
    )
  }

  handleAddition(tag: Tag) {
    this.setState(
      (state) => ({ tags: [...state.tags, tag] }),
      () => {
        this.updateValue(this.state.tags)
      }
    )
  }

  handleDrag(tag: Tag, currPos: number, newPos: number) {
    const tags = [...this.state.tags]
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)

    // re-render
    this.setState({ tags: newTags }, () => {
      this.updateValue(this.state.tags)
    })
  }

  updateValue(tags: Tag[]) {
    const { name, setFieldValue, meta } = this.props
    const value = tags.map((tag) => tag.id)
    setFieldValue(name, value, meta || false)
  }

  render() {
    const { tags } = this.state
    return (
      <div className="custom-input">
        {this.props.label ? (
          <h5 style={{ margin: 0 }} className="text">
            {this.props.label}
          </h5>
        ) : (
          ''
        )}
        <ReactTags
          tags={tags}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleDrag={this.handleDrag}
          placeholder={I18n.get('Add new tag')}
          allowDragDrop={false}
          autofocus={false}
          delimiters={[188, 13]}
          classNames={{
            tags: 'tagsFieldContainer',
            tagInput: 'tagsFieldInputContainer',
            tagInputField: 'tagsFieldInputField',
            selected: 'tagsFieldSelected',
            tag: 'tagsFieldTag',
            remove: 'tagsFieldsRemove',
            suggestions: 'tagsFieldSuggestions',
            activeSuggestion: 'tagsFieldActiveSuggestion'
          }}
        />
      </div>
    )
  }
}

export default TagField
