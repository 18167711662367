import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import '../../assets/scss/main.scss'
import { Route } from 'react-router'
import Header from '../../components/Header/Header'
import Navigation from '../../components/Navigation/Navigation'
import AllFilesPage from './AllFilesPage'
import AllTemplatesPage from './AllTemplatesPage/AllTemplatesPage'
import UsersPage from './UsersPage/UsersPage'
import NewFilePage from './NewFilePage/NewFilePage'
import MyFilesPage from './MyFilesPage/MyFilesPage'
import SettingsPage from './SettingsPage/SettingsPage'
import FileDetailPage from './FileDetailPage/FileDetailPage'
import ProductDetailPage from './TemplateDetailPage/TemplateDetailPage'
import OrganizationPage from './OrgamizationPage/OrganizationPage'
import SignaturesPage from './SignaturePage'
import DownloadPage from './DownloadPage'

@observer
class DashboardContainer extends Component<any, any> {
  public render(): ReactNode {
    return (
      <>
        <Header showMenu />
        <div className="container">
          <Navigation />
          <div className="container-inner">
            {/* <Switch> */}
            <Route exact path="/" component={AllFilesPage} />
            <Route exact path="/dashboard/" component={NewFilePage} />
            <Route path="/dashboard/my-files/:id" component={FileDetailPage} />
            <Route path="/dashboard/my-files" component={MyFilesPage} />
            <Route path="/dashboard/signatures" component={SignaturesPage} />
            <Route path="/dashboard/all-files" component={AllFilesPage} />
            <Route
              path="/dashboard/all-templates/:id"
              component={ProductDetailPage}
            />
            <Route
              path="/dashboard/all-templates"
              component={AllTemplatesPage}
            />
            <Route path="/dashboard/users" component={UsersPage} />
            <Route
              path="/dashboard/organization"
              component={OrganizationPage}
            />
            <Route path="/dashboard/settings" component={SettingsPage} />
            <Route
              path="/download/:permalink/:uniqid"
              component={DownloadPage}
            />

            {/* <Redirect to="/new"/> */}

            {/* </Switch> */}
          </div>
        </div>
      </>
    )
  }
}

export default DashboardContainer
