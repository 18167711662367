import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import { ValidatorConditions } from './components/ValidatorConditions'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'
import { ValidatorMessage } from './components/ValidatorMessage'

const dictionary = {
  fr: {
    validator_category: 'Validateur'
  },
  en: {
    validator_category: 'Validator'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Validator option params
 */
const ValidatorOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <OptionContext.Consumer>
      {(state) => {
        const { type } = state.option.meta

        if (!['radio', 'checkbox'].includes(type)) return null

        const toggleCollapsed = () => setCollapsed(!collapsed)

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('validator_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <div>
                <ValidatorConditions state={state} />
                <ValidatorMessage state={state} />
              </div>
            )}
          </div>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default ValidatorOptionParams
