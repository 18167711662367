import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import { Fab } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { I18n } from 'aws-amplify'

const dictionary = {
  fr: {
    'new item': 'Nouvelle valeur'
  },
  en: {
    'new item': 'NEW ITEM'
  }
}

I18n.putVocabularies(dictionary)

class DropdownValues extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      options: props.list
    }
  }

  addNewOption = () => {
    this.setState({
      options: [...this.state.options, '']
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
    // const {name, setFieldValue, setListValue} = this.props;
    const { name, setFieldValue } = this.props
    const opts = [...this.state.options]
    // @ts-ignore
    opts[i] = event.target.value

    this.setState({
      options: opts
    })
    setFieldValue(name, opts)
  }

  deleteOption = (i: number) => {
    const { name, setFieldValue } = this.props
    const opts = [...this.state.options]
    opts.splice(i, 1)

    this.setState({
      options: opts
    })
    setFieldValue(name, opts)
  }

  render() {
    const { options } = this.state
    return (
      <div className="dropdown-values">
        {options.map((opt: any, i: number) => (
          <div key={i} style={{ position: 'relative' }}>
            <TextField
              classes={{ root: 'custom-field' }}
              id="outlined-bare"
              margin="normal"
              variant="outlined"
              value={opt}
              onChange={(e: any) => this.handleChange(e, i)}
            />
            <div style={{ position: 'absolute', top: '14px', right: '-15px' }}>
              <Fab
                size="small"
                aria-label="Delete"
                onClick={() => this.deleteOption(i)}
              >
                <DeleteIcon />
              </Fab>
            </div>
          </div>
        ))}
        <button type="button" className="secondary" onClick={this.addNewOption}>
          {I18n.get('new item')}
        </button>
      </div>
    )
  }
}

export default DropdownValues
