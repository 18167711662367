import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'

// import Arrow from "../Images/Arrow";
import { I18n } from 'aws-amplify'
import { ToastContainer } from 'react-toastify'
import Button from '../Button/Button'
// import Home from "../Images/Home";
import { IAuthStore } from '../../../store/authStore'
// import Plus from "../Images/Plus";
// import Save from "../Images/Save";
// import Svg from "../Svg/Svg";
import './style.scss'
import Icon from '../../Icons/Icon'
import { HEADERSEPARATORICO, HEADERSAVEICON } from '../../Icons/icons'

import PublishModal from '../../PublishModal'
import { ICreateStore } from '../../../store/editor/createStore'
import { go } from '../../../utils/history'
import CopySectionModal from '../../CopySectionModal'
import CopySecondaryDocumentModal from '../../CopySecondaryDocumentModal'
import { ReactComponent as HomeIcon } from './home.svg'
import openModelNotes from '../../ModelNotes/ModelNotes'

const dictionary = {
  fr: {
    preview: 'Aperçu',
    publish: 'Publier',
    'save header': 'Sauvegarder',
    copy_section: 'Copier une section',
    copy_document: 'Copier un document',
    'Enable autosave': 'Sauvegarder automatiquement',
    model_option: 'Options du modèle',
    moving_header_wording:
      "Merci de sélectionner l'emplacement où vous souhaitez déplacer l'élément",
    cancel: 'Annuler',
    notes: 'Notes'
  },
  en: {
    preview: 'Preview',
    publish: 'Publish',
    'save header': 'save',
    copy_section: 'copy section',
    copy_document: 'copy document',
    model_option: "Model's options",
    moving_header_wording: 'Please indicate where you want to move the element',
    cancel: 'Cancel',
    notes: 'Notes'
  }
}

I18n.putVocabularies(dictionary)

interface IHeaderProps {
  authStore?: IAuthStore | any
  createStore?: ICreateStore | any
}

@inject('authStore', 'createStore')
@observer
class Header extends Component<IHeaderProps> {
  async componentDidMount() {
    // try {
    //     const apiData = await API.graphql(graphqlOperation(listCategorys));
    //     console.warn("apiData",apiData);
    //
    // } catch (err) {
    //     console.log("error: ", err);
    // }
  }

  public handleSignOut = () => {
    this.props.authStore.logout()
    // History.history.push("/login")
  }

  toggleModal = (name: string, open: boolean) => {
    const { toggleModal } = this.props.createStore
    toggleModal(name, open)
  }

  goToTemplates = () => {
    const { templateId } = this.props.createStore
    if (templateId >= 0) {
      go(`/dashboard/all-templates/${templateId}`)
    } else {
      go(`/dashboard/all-templates/`)
    }
  }

  public render(): ReactNode {
    const inEditor = true
    const {
      saveModelData,
      publishModelData,
      version,
      templateName,
      meta_permalink,
      publishIsOpen,
      toggleModal,
      copySectionIsOpen,
      copySecondaryDocumentIsOpen,
      enableAutosaveModelDataInterval,
      setAutosaveModelData,
      moveElement,
      cancelMoveElement,
      modelNotes
    } = this.props.createStore

    return (
      <>
        {inEditor ? (
          <div className="editor-header">
            {moveElement.isMoving === true && (
              <div className="move-element-header">
                <div className="wording">
                  {I18n.get('moving_header_wording')}
                </div>
                <div className="cancel-cta">
                  <button
                    className="white"
                    type="button"
                    onClick={() => cancelMoveElement()}
                  >
                    {I18n.get('cancel')}
                  </button>
                </div>
              </div>
            )}
            <div className="header-left">
              <div className="home-block">
                <div className="home-icon" onClick={this.goToTemplates}>
                  <HomeIcon />
                </div>
                <Icon
                  iconClass="separator-ico"
                  icon={HEADERSEPARATORICO}
                  color="#efefef"
                />
              </div>
              <div className="contract-name">{templateName}</div>
              <div className="current-model">{`Model ${version}`}</div>
            </div>
            <div className="header-center">
              {/* DO NOT DELETE. WILL BE NEED IN FUTURE */}
              <div className="button-block">
                <div
                  onClick={() => this.toggleModal('modelOptionsIsOpen', true)}
                  className="primary button"
                >
                  {I18n.get('model_option')}
                </div>
                {/* <div
              {/* onClick={() => this.toggleModal("extractionIsOpen", true)} */}
                {/* className="grey-button">Extraction</div> */}
                {/* <div className="grey-button"><Svg width="14" height="14" */}
                {/* viewBox="0 0 12 12">{Plus()}</Svg><span>Import</span></div> */}
              </div>
            </div>
            <div className="header-right">
              <ToastContainer
                containerId="header"
                className="modelSavedContainer"
                enableMultiContainer
              />
              <div className="header-menu">
                <Button className="secondary" handler={() => openModelNotes()}>
                  {I18n.get('notes')} ({modelNotes.length})
                </Button>
                <Button
                  className="secondary"
                  handler={() =>
                    this.toggleModal('copySecondaryDocumentIsOpen', true)
                  }
                >
                  {I18n.get('copy_document')}
                </Button>
                <Button
                  className="secondary"
                  handler={() => this.toggleModal('copySectionIsOpen', true)}
                >
                  {I18n.get('copy_section')}
                </Button>
                <div onClick={() => this.toggleModal('publishIsOpen', true)}>
                  <Button className="secondary">{I18n.get('publish')}</Button>
                </div>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${process.env.REACT_APP_WIZARDX}contrats/${meta_permalink}/creer/1?version=${version}`}
                >
                  <div>
                    <Button className="secondary">{I18n.get('preview')}</Button>
                  </div>
                </a>
                <div onClick={() => saveModelData()} className="save-button">
                  {/* <Icon iconClass="header-save-ico" icon={HEADERSAVEICON} color="#000"/> */}
                  <Button
                    onClick={() => saveModelData()}
                    className="btn-link-save primary"
                  >
                    <Icon
                      iconClass="save-ico"
                      icon={HEADERSAVEICON}
                      color="#fff"
                    />{' '}
                    {I18n.get('save header')}
                  </Button>
                </div>
                <div className="autosave-checkbox">
                  <input
                    id="autosave-checkbox-input"
                    type="checkbox"
                    checked={enableAutosaveModelDataInterval}
                    onChange={(e) => setAutosaveModelData(e.target.checked)}
                  />
                  <label htmlFor="autosave-checkbox-input">
                    {I18n.get('Enable autosave')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="header">
            <div className="header-holder">
              <div className="header-content">
                <span className="logo" />
                <span className="header-right">
                  <div className="header-username">Hello vasily</div>
                  <button
                    type="button"
                    onClick={this.handleSignOut}
                    className="header-button"
                  >
                    <span>Sign Out</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        )}
        <PublishModal
          toggleModal={toggleModal}
          publishModelData={publishModelData}
          open={publishIsOpen}
        />
        <CopySectionModal toggleModal={toggleModal} open={copySectionIsOpen} />
        <CopySecondaryDocumentModal
          toggleModal={toggleModal}
          open={copySecondaryDocumentIsOpen}
        />
      </>
    )
  }
}

export default Header
