import React, {
  DetailedHTMLProps,
  TextareaHTMLAttributes,
  useLayoutEffect,
  useRef
} from 'react'

type TextareaProps = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>
const AutoAdjustableTextarea: React.FC<TextareaProps> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null)

  const adjustTextarea = (element: HTMLTextAreaElement) => {
    element.style.height = '1px'
    element.style.height = `${element.scrollHeight}px`
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const element = e.currentTarget
    adjustTextarea(element)
    if (typeof props.onKeyUp === 'function') props.onKeyUp(e)
  }

  useLayoutEffect(() => {
    if (ref.current !== null) adjustTextarea(ref.current)
  })
  return <textarea {...props} onKeyUp={handleKeyUp} ref={ref} />
}

export default AutoAdjustableTextarea
