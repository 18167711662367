import React from 'react'
import classNames from 'classnames'
import './style.scss'

interface IIconProps {
  iconWrapperClass?: string
  vb?: string
  iconClass?: string
  iconClassDefault?: string
  color?: string
  icon: any
}

const Icon: React.FC<IIconProps> = (props) => {
  const {
    iconWrapperClass = 'iconWrapper',
    vb = '0 0 32 32',
    iconClassDefault = 'icon',
    iconClass = '',
    color = '#495057',
    icon
  } = props
  const iconClassConst = classNames({
    [iconClassDefault]: true,
    [iconClass]: true
  })
  const iconWrapperClassConst = classNames({
    [iconWrapperClass]: true
  })
  return (
    <div className={iconWrapperClassConst}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={vb}
        className={iconClassConst}
        fill={color}
      >
        {icon}
      </svg>
    </div>
  )
}

export default Icon
