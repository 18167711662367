import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'

const dictionary = {
  fr: {
    round: 'Arrondi',
    Default: 'Par default',
    Up: 'Haut',
    Down: 'Bas'
  },
  en: {
    round: 'Round',
    Default: 'Default',
    Up: 'Up',
    Down: 'Down'
  }
}
I18n.putVocabularies(dictionary)

const roundOptions = [
  {
    value: 'round',
    label: I18n.get('Default')
  },
  {
    value: 'ceil',
    label: I18n.get('Up')
  },
  {
    value: 'floor',
    label: I18n.get('Down')
  }
]

/**
 * Edits variable's eval decimals
 */
export const EvalRound: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { round } = state.variable.eval || { round: 'round' }
  return (
    <div className="label-holder">
      <SelectField
        setFieldValue={(name: string, value: 'round' | 'ceil' | 'floor') => {
          state.updateEvalRound(value)
        }}
        value={round}
        name="eval_round"
        label={I18n.get('round')}
        options={roundOptions}
      />
    </div>
  )
}
