import React from 'react'
import ReactDOM from 'react-dom'
import './style.scss'
import { I18n } from 'aws-amplify'
import createStore from '../../store/editor/createStore'
import MainView from './components/MainView'
import CopyView from './components/CopyView'

const dictionary = {
  fr: {
    cancel: 'Annuler',
    duplicate_model: 'Dupliquer un modèle'
  },
  en: {
    cancel: 'Cancel',
    duplicate_model: 'Copy a model'
  }
}

I18n.putVocabularies(dictionary)

type NewModelModalProps = {}

type NewModelModalState = {
  currentView: 'main' | 'copy'
}

class NewModelModal extends React.Component<
  NewModelModalProps,
  NewModelModalState
> {
  elem = document.createElement('div')

  constructor(props: NewModelModalProps) {
    super(props)

    this.state = {
      currentView: 'main'
    }

    this.elem.classList.add('new-model-modal-root')
  }

  componentDidMount() {
    document.body.appendChild(this.elem)
  }

  componentWillUnmount() {
    document.body.removeChild(this.elem)
  }

  /**
   * Changes current view
   */
  setView = (view: NewModelModalState['currentView']) => {
    this.setState({ currentView: view })
  }

  /**
   * Closes Modal
   */
  closeModal = () => {
    createStore.toggleModal('newModelIsOpen', false)
  }

  /**
   * Renders portal content
   */
  portalContent() {
    return (
      <div className="nmm-container">
        <div className="nmm-content">
          {this.state.currentView === 'main' && (
            <MainView closeModal={this.closeModal} setView={this.setView} />
          )}
          {this.state.currentView === 'copy' && (
            <CopyView closeModal={this.closeModal} setView={this.setView} />
          )}
        </div>
      </div>
    )
  }

  /**
   * Creates & renders React Portal
   */
  render() {
    return ReactDOM.createPortal(this.portalContent(), this.elem)
  }
}

export default NewModelModal
