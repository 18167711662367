import React from 'react'
import ReactDOM from 'react-dom'
import './modal.scss'

type ModalProps = {
  onCancel: (event: MouseEvent | React.MouseEvent) => void
  onSave: (event: React.MouseEvent) => void
  title?: string
}

const ModalComponent: React.FC<ModalProps> = ({
  onCancel,
  onSave,
  title,
  children
}) => {
  return (
    <div className="modal-container">
      {title && <div className="modal-title">{title}</div>}
      <div className="modal-content">{children}</div>
      <div className="modal-controls">
        <button type="button" className="secondary" onClick={onCancel}>
          Fermer
        </button>
        <button type="button" className="primary" onClick={onSave}>
          Enregister
        </button>
      </div>
    </div>
  )
}

class Modal extends React.Component<ModalProps> {
  el = document.createElement('div')

  modelRootRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.body.appendChild(this.el)
    document.body.addEventListener('click', this.bodyClickEvent)
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.bodyClickEvent)
    document.body.removeChild(this.el)
  }

  bodyClickEvent = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (
      target === null ||
      this.modelRootRef.current === null ||
      this.modelRootRef.current === event.target
    )
      this.props.onCancel(event)
  }

  render() {
    return ReactDOM.createPortal(
      <div
        className="modal-root"
        role="dialog"
        onMouseDown={(e) => e.preventDefault()}
        ref={this.modelRootRef}
      >
        <ModalComponent {...this.props} />
      </div>,
      this.el
    )
  }
}

export default Modal
