import { useSlate } from '@legalplace/slate-react'
import React, { MouseEvent } from 'react'
import TableHelpers from '../../helpers/TableHelpers'
import Button from './Button'
import Icon from './Icon'
import TableBorderColorButton from './TableBorderColorButton'
import TableBorderStyleButton from './TableBorderStyleButton'
import TableBorderWidthButton from './TableBorderWidthButton'

const ToolbarBlockStyleButton: React.FC<{
  border: 'left' | 'right' | 'top' | 'bottom'
}> = ({ border }) => {
  const editor = useSlate()

  const handleClick: React.EventHandler<MouseEvent<HTMLDivElement>> = (
    event
  ) => {
    event.preventDefault()
    if (
      TableHelpers.getCurrentCellBorderStyle(editor, border, 'color') ===
      'transparent'
    ) {
      TableHelpers.editCurrentCellBorder(editor, border, {
        color: '#000',
        width: '1px',
        style: 'solid'
      })
    } else {
      TableHelpers.editCurrentCellBorder(editor, border, {
        color: 'transparent',
        width: '1px',
        style: 'solid'
      })
    }
  }

  return (
    <Button onMouseDown={handleClick}>
      <Icon>{`border_${border}`}</Icon>
    </Button>
  )
}

const TableStylingToolbar: React.FC = () => {
  return (
    <>
      <ToolbarBlockStyleButton border="bottom" />
      <ToolbarBlockStyleButton border="top" />
      <ToolbarBlockStyleButton border="left" />
      <ToolbarBlockStyleButton border="right" />
      <TableBorderStyleButton />
      <TableBorderColorButton />
      <TableBorderWidthButton />
    </>
  )
}

export default TableStylingToolbar
