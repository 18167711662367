import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'

const dictionary = {
  fr: {
    'Visible only by admin': "Visible uniquement par l'admin"
  },
  en: {
    'Visible only by admin': 'Visible only by admin'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option second step
 */
export const AdminOnly: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const { grantLevel } = state.option.meta

  const isAdminOnly = grantLevel === 'ADMIN'

  const setIsAdminOnly = (adminOnly: boolean) => {
    if (adminOnly === true) state.updateGrantLevel('ADMIN')
    else state.updateGrantLevel('GUEST')
  }

  return (
    <div className="step-holder">
      <CheckboxField
        meta
        checked={isAdminOnly}
        name="visibleByAdmin"
        setFieldValue={(name: string, value: boolean) => setIsAdminOnly(value)}
        label={I18n.get('Visible only by admin')}
      />
    </div>
  )
}
