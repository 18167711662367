import React, { Component } from 'react'

class SectionsBoard extends Component {
  async componentDidMount() {
    // try {
    //     const apiData = await API.graphql(graphqlOperation(listCategorys));
    //     console.warn('apiData',apiData);
    //
    // } catch (err) {
    //     console.log('error: ', err);
    // }
  }

  render() {
    return <div>Preview</div>
  }
}

export default SectionsBoard
