import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import ReactQuill from 'react-quill'
import { ReactComponent as CloseIcon } from './close.svg'
import createStore from '../../../store/editor/createStore'
import Note from './Note'

const dictionary = {
  fr: {
    Notes: 'Notes'
  },
  en: {
    Notes: 'Notes'
  }
}

I18n.putVocabularies(dictionary)

interface ModelNotesModalProps {
  close: () => void
  disableAdding: boolean
}

const ModelNotesModal: React.FC<ModelNotesModalProps> = ({
  close,
  disableAdding
}) => {
  const [addNote, setAddNote] = useState(false)
  const [newNoteContent, setNewNoteContent] = useState('')
  const { modelNotes, addModelNote } = createStore

  const cancelNewNote = () => {
    setAddNote(false)
    setNewNoteContent('')
  }

  const addNewNote = () => {
    if (disableAdding) return
    addModelNote(newNoteContent)
    setAddNote(false)
    setNewNoteContent('')
  }

  return (
    <div className="modelnotes-container">
      <div className="modelnotes-header">
        <div className="modelnotes-title">
          {I18n.get('Notes')}
          {!addNote && !disableAdding && (
            <button
              type="button"
              className="modelnotes-add-cta secondary btn"
              onClick={() => setAddNote(true)}
            >
              Ajouter une note
            </button>
          )}
        </div>
        <button type="button" className="modelnotes-close" onClick={close}>
          <CloseIcon />
        </button>
      </div>

      <div className="modelnotes-content">
        {addNote && !disableAdding && (
          <div className="modelnotes-addnote">
            <div className="modelnotes-rte">
              <ReactQuill
                className="editor"
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ color: [] }, { background: [] }],
                    ['link']
                  ]
                }}
                value={newNoteContent}
                onChange={(val: string) => {
                  setNewNoteContent(val)
                }}
              />
            </div>
            <div className="modelnotes-ctas">
              <button
                className="btn secondary"
                type="button"
                onClick={cancelNewNote}
              >
                Annuler
              </button>
              <button
                className="btn primary"
                type="button"
                onClick={addNewNote}
              >
                Ajouter
              </button>
            </div>
          </div>
        )}
        {!addNote &&
          modelNotes.map((note) => {
            return <Note note={note} key={`${note.author}-${note.timestamp}`} />
          })}
      </div>
    </div>
  )
}

export default ModelNotesModal
