import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import { Tags } from './components/Tags'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: { other_category: 'Autre' },
  en: { other_category: 'Other' }
}
I18n.putVocabularies(dictionary)
/**
 * Other option params
 */
const OtherOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <OptionContext.Consumer>
      {(state) => {
        const { type } = state.option.meta

        if (!['static', 'radio', 'checkbox'].includes(type)) return null

        const toggleCollapsed = () => setCollapsed(!collapsed)

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('other_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <>
                <Tags state={state} />
              </>
            )}
          </div>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default OtherOptionParams
