import React, { Component, ReactNode } from 'react'
import { I18n } from 'aws-amplify'

import Input from '../../../../components/Editor/Input/Input'
import Select from '../../../../components/Editor/Select/Select'
import './style.scss'

const titles = {
  fr: {
    Version: 'Version',
    'Percentage of visitors': 'Pourcentage des visiteurs'
  },
  en: {
    Version: 'Version',
    'Percentage of visitors': 'Percentage of visitors'
  }
}

// TODO: get versions list

// const options = [
//   {
//     value: '1',
//     text: '1'
//   },
//   {
//     value: '2',
//     text: '2'
//   },
//   {
//     value: '3',
//     text: '3'
//   },
//   {
//     value: '4',
//     text: '4'
//   },
// ];

I18n.putVocabularies(titles)

interface IAbTestingProps {
  versionsList: any[]
  versions: any[]
}

interface IAbTestingState {
  versions: any[]
}

class AbTesting extends Component<IAbTestingProps, IAbTestingState> {
  constructor(props: IAbTestingProps) {
    super(props)
    this.state = {
      versions: []
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (props.versions && state.versions.length === 0) {
      return {
        versions: props.versions
      }
    }
    return {
      versions: []
    }
  }

  public addVersion = () => {
    const versionsCopy = [...this.state.versions]
    // @ts-ignore
    versionsCopy.push({ version: '', percent: '100' })
    this.setState({
      versions: versionsCopy
    })
  }

  public removeVersion = (i: number) => {
    const versions = [...this.state.versions]
    versions.splice(i, 1)
    this.setState({
      versions
    })
  }

  public render(): ReactNode {
    const { versions } = this.state
    const { versionsList } = this.props

    return (
      <div>
        {versions.map((version: any, i: number) => (
          <div key={i} className="params ab_versions">
            <div className="input-holder">
              <Select
                value={version.version}
                className="long"
                options={versionsList}
                label={I18n.get('Version')}
              />
            </div>
            <div className="input-holder">
              <Input
                className="long"
                name="percent"
                value={version.percent}
                label={I18n.get('Percentage of visitors')}
              />
            </div>
            <div className="delete">
              <button type="button" onClick={() => this.removeVersion(i)}>
                x
              </button>
            </div>
          </div>
        ))}
        <button type="button" className="addVersion" onClick={this.addVersion}>
          + Version
        </button>
      </div>
    )
  }
}

export default AbTesting
