import React, { Component, ReactNode } from 'react'
import { I18n } from 'aws-amplify'

import miIcons from '../miIcons'
import './style.scss'

const Modal = require('react-modal')

Modal.setAppElement('#root')

const title = {
  fr: {
    'Please select an icon': 'Veuillez sélectionner une icône'
  },
  en: {
    'Please select an icon': 'Please select an icon'
  }
}

I18n.putVocabularies(title)

const styles = {
  content: {
    width: '500px',
    height: '500px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    background: '#FFF',
    borderRadius: '12px',
    boxShadow: '3px 3px 20px rgba(0,0,0,0.3)',
    padding: '20px'
  }
}

interface IIconsModalProps {
  modalIsOpen: boolean

  onHandleIconChange(icon: any): void

  closeModal(): void
}

class IconsModal extends Component<IIconsModalProps> {
  selectIcon = (icon: any) => {
    const { closeModal, onHandleIconChange } = this.props
    onHandleIconChange(icon)
    closeModal()
  }

  public renderIcons = () => {
    return Object.keys(miIcons).map((category, i) => (
      <div key={category + i} className="mi-categories">
        <div className="mi-category-title">{category}</div>
        <div className="mi-icons-list">
          {Object.keys(category).map((icon, x) => (
            <div key={icon + x} className="mi-icon-container">
              <i
                className="material-icons"
                onClick={() => this.selectIcon(icon)}
              >
                {icon}
              </i>
            </div>
          ))}
        </div>
      </div>
    ))
  }

  public render(): ReactNode {
    const { modalIsOpen, closeModal } = this.props
    return (
      <div className="icon-modal">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={styles}
          // contentLabel="Example Modal"
        >
          <div className="mi-big-title">
            {I18n.get('Please select an icon')}
          </div>
          <div className="mi-popup-close" onClick={closeModal}>
            x
          </div>
          {this.renderIcons()}
        </Modal>
      </div>
    )
  }
}

export default IconsModal
