import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'
import { EYEHIDEICON } from '../../../../../../../components/Icons/icons'
import Icon from '../../../../../../../components/Icons/Icon'

const dictionary = {
  fr: {
    '2nd step only': '2e étape uniquement'
  },
  en: {
    '2nd step only': '2nd step only'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option second step
 */
export const SecondStepOnly: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { step } = state.variable

  const isSecondStepOnly =
    Array.isArray(step) && step.length === 1 && step.includes(2)

  const setSecondStep = (isSecondStep: boolean) => {
    if (isSecondStep === true) state.updateStep([2])
    else state.updateStep('*')
  }

  return (
    <div className="step-wrapper">
      <div className="step-holder">
        <CheckboxField
          meta
          checked={isSecondStepOnly}
          name="step"
          setFieldValue={(name: string, value: boolean) => setSecondStep(value)}
          label={I18n.get('2nd step only')}
          value="step_two_only"
        />
        <Icon iconClass="tree-ico" icon={EYEHIDEICON} color="#000" />
      </div>
    </div>
  )
}
