import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import VariableContext from '../editors/VariableContext'
import { EvalFormula } from './components/EvalFormula'
import { EvalDecimals } from './components/EvalDecimals'
import { EvalRound } from './components/EvalRound'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: {
    eval_category: 'Calculatrice'
  },
  en: {
    eval_category: 'Eval'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Evaluation variable params
 */
const EvalVariableParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <VariableContext.Consumer>
      {(state) => {
        const toggleCollapsed = () => setCollapsed(!collapsed)

        const { type } = state.variable

        if (type !== 'eval') return null

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('eval_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <div>
                <EvalFormula state={state} />
                <EvalDecimals state={state} />
                <EvalRound state={state} />
              </div>
            )}
          </div>
        )
      }}
    </VariableContext.Consumer>
  )
}

export default EvalVariableParams
