import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import { BoxType } from './components/BoxType'
import { BoxTitle } from './components/BoxTitle'
import { BoxCollapsable } from './components/BoxCollapsable'
import { BoxAggregatable } from './components/BoxAggregatable'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: {
    box_category: "Boîte d'info"
  },
  en: {
    box_category: 'Box'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Box option params
 */
const BoxOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <OptionContext.Consumer>
      {(state) => {
        const { type } = state.option.meta

        if (type !== 'box') return null

        const toggleCollapsed = () => setCollapsed(!collapsed)

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('box_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <div>
                <BoxType state={state} />
                <BoxTitle state={state} />
                <BoxCollapsable state={state} />
                <BoxAggregatable state={state} />
              </div>
            )}
          </div>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default BoxOptionParams
