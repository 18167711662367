import React from 'react'
import { I18n } from 'aws-amplify'
import createStore from '../../../../store/editor/createStore'
import './style.scss'

const PdfSidePreview: React.FC = () => {
  const { currentDocument, document } = createStore

  const { pdf } = document.documents[currentDocument]

  if (pdf === undefined) return null

  const openPdfEditor = () => {
    createStore.toggleModal('pdfEditorIsOpen', true)
  }

  return (
    <div className="pdf-sidepreview">
      <div className="pdf-content">
        <div className="thumbnail">
          <img src={pdf?.thumbnails[0]} alt="" />
        </div>
        <div className="cta">
          <button type="button" onClick={openPdfEditor} className="primary">
            {I18n.get('Edit PDF')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PdfSidePreview
