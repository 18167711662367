import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import Input from '../Editor/InputField'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'Create new': 'Créer une nouvelle',
    unit: 'équipe',
    category: 'catégorie',
    'cancel move': 'Annuler',
    'save move': 'Enregistrer'
  },
  en: {
    'Create new': 'Create new',
    unit: 'unit',
    category: 'category',
    'cancel move': 'Cancel',
    'save move': 'Save'
  }
}

I18n.putVocabularies(dictionary)
// Créer une nouvelle
interface ISelectMetaModalProps {
  open: boolean
  toggleModal(value: boolean): void
  type: string
  createCategory(name: string): void
  createUnit(name: string): void
}

@observer
class OrganizationModal extends Component<ISelectMetaModalProps> {
  @observable name = ''

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    console.log('')

    toggleModal(open)
  }

  @catchAction
  @action
  handleChange = (name: any, value: any) => {
    this.name = value
  }

  @catchAction
  @action
  handleSave = () => {
    const { type, createUnit, createCategory, toggleModal } = this.props
    if (type === 'units') {
      createUnit(this.name)
    } else {
      createCategory(this.name)
    }
    toggleModal(false)
  }

  render() {
    const { type } = this.props
    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="move-modal-holder"
      >
        <div className="organization-modal ">
          <h2>{`${I18n.get('Create new')} ${
            type === 'units' ? I18n.get('unit') : I18n.get('category')
          }`}</h2>
          <div className="move-select-holder">
            <Input setFieldValue={this.handleChange} />
          </div>
          <div className="btn-holder">
            <button
              type="button"
              className="secondary"
              onClick={() => this.toggleModal(false)}
            >
              {I18n.get('cancel move')}
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => this.handleSave()}
            >
              {I18n.get('save move')}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default OrganizationModal
