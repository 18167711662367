import { I18n } from 'aws-amplify'
import React, { Component } from 'react'
import './style.scss'
import { action, observable } from 'mobx'
import { Formik } from 'formik'
import { observer, inject } from 'mobx-react'
import MaterialSelect from '../MaterialSelect'
import FormField from '../FormField/FormField'
import { catchAction } from '../../store/editor/catchActionsDecorator'
import { RECOVERY_PAYMENT_METHOD, PACK_CHOICE } from '../../constants/recovery'
import { IDataStore } from '../../store/dataStore'

const dictionary = {
  fr: {
    'move-title': 'Déplacer après',
    'cancel move': 'Annuler',
    'save move': 'Enregistrer',
    payment_method: 'Méthode de paiement',
    pack_choice: 'Choix du pack',
    'recovery-title': 'Reprise',
    cost: 'Coût',
    price: 'Prix'
  },
  en: {
    'move-title': 'Move after:',
    'cancel move': 'Cancel',
    'save move': 'Save',
    payment_method: 'Payment method',
    pack_choice: 'Pack',
    'recovery-title': 'Recovery',
    cost: 'Cost',
    price: 'Price'
  }
}

export interface IRecoveryValues {
  pack: string
  payment_method: string
  cost: number
  price: number
}

interface IRecoveryProps {
  closeDialog(): void
  dataStore?: IDataStore | any
  uniqid: string
}

I18n.putVocabularies(dictionary)
@observer
@inject('dataStore')
class RecoveryModal extends Component<IRecoveryProps> {
  @observable paymentMethod = ''

  @observable pack = ''

  @observable price = 0

  @observable cost = 0

  @catchAction
  @action
  handlePaymentMethodSelect(name: string, value: string) {
    this.paymentMethod = value
  }

  @catchAction
  @action
  handlePackChoice(name: string, value: string) {
    this.paymentMethod = value
  }

  @catchAction
  @action
  async handleSave(values: IRecoveryValues) {
    const { recoveryContract } = this.props.dataStore
    await recoveryContract(values, this.props.uniqid)
  }

  render() {
    return (
      <div>
        <div className="recovery-modal">
          <h1>{I18n.get('recovery-title')}</h1>
          <Formik
            initialValues={{ pack: '', payment_method: '', price: 0, cost: 0 }}
            onSubmit={this.handleSave}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              values
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className="form-container">
                  <div className="recovery-form-column">
                    <h3 className="move-select-holder">
                      {I18n.get('payment_method')}
                    </h3>
                    <MaterialSelect
                      fieldClass="recovery-select"
                      setFieldValue={setFieldValue}
                      name="payment_method"
                      value={values.payment_method}
                      options={RECOVERY_PAYMENT_METHOD.map((paymentMethod) => ({
                        label: paymentMethod,
                        value: paymentMethod
                      }))}
                    />
                    <h3 className="move-select-holder">
                      {I18n.get('pack_choice')}
                    </h3>
                    <MaterialSelect
                      fieldClass="recovery-select"
                      setFieldValue={setFieldValue}
                      name="pack"
                      value={values.pack}
                      options={PACK_CHOICE.map((paymentMethod) => ({
                        label: paymentMethod,
                        value: paymentMethod
                      }))}
                    />
                  </div>
                  <div>
                    <div>
                      <h3 className="move-select-holder">{I18n.get('cost')}</h3>
                      <FormField
                        type="text"
                        name="price"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        placeholder=""
                      />
                    </div>
                    <div>
                      <h3 className="move-select-holder">
                        {I18n.get('price')}
                      </h3>
                      <FormField
                        type="text"
                        name="cost"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                  </div>
                </div>
                <div className="btn-holder">
                  <button
                    type="button"
                    className="secondary"
                    onClick={this.props.closeDialog}
                  >
                    {I18n.get('cancel move')}
                  </button>
                  <button
                    type="button"
                    className="primary"
                    onClick={() => this.handleSave(values)}
                  >
                    {I18n.get('save move')}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

export default RecoveryModal
