import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { I18n } from 'aws-amplify'

interface IConfirmProps {
  handleCancel(e: any): void
  handleDelete(e: any): void
  question: string
}

const columns = {
  en: {
    cancel: 'Cancel',
    delete: 'Delete'
  },
  fr: {
    cancel: 'Cancel',
    delete: 'Delete'
  }
}

I18n.putVocabularies(columns)

@observer
class Confirm extends Component<IConfirmProps> {
  public render(): ReactNode {
    const { question, handleCancel, handleDelete } = this.props
    return (
      <div className="content confirm">
        <div className="ttl-container">
          <h3 className="ttl">{question}</h3>
        </div>
        <div className="btn-wrapper">
          <button
            type="button"
            className="btn btn-gary btn-cancel"
            onClick={handleCancel}
          >
            {I18n.get('cancel')}
          </button>
          <button
            type="button"
            className="btn btn-dark btn-delete"
            onClick={handleDelete}
          >
            {I18n.get('delete')}
          </button>
        </div>
      </div>
    )
  }
}

export default Confirm
