import { action, computed, observable, runInAction } from 'mobx'
import isEmpty from 'lodash/isEmpty'

import { toast } from 'react-toastify'
import api from '../../utils/api'
import dataStore from '../dataStore'
import { catchAction } from './catchActionsDecorator'

export interface IContractsStore {
  fetching: boolean
  contract: any
  contractMeta: any
  contracts: any

  fetchContracts(): void
  fetchCategories(): void

  fetchContractMeta(id: number): void
  getContractMeta(id: number): Promise<Record<string, any> | false>

  filterContracts(searchStr: string): void

  selectContract(contract: Object): void
}

interface IContractMetaInfo {
  category?: any
  category_order?: number
  cost?: number
  created_at?: Date
  cta_ab_testing?: any
  do_not_send_docs?: number
  do_not_send_relances?: number
  do_not_send_welcome_email?: number
  duplicate_of?: number
  id?: number
  locale?: string
  models?: any[]
  name?: string
  noindex?: number
  permalink?: string
  pricing?: any
  signers?: any
  state?: number
  type?: string
  updated_at?: Date
  version?: number
  version_in_production?: number
  versions_ab_testing?: any[]
  model_published?: any
}

class ContractsStore implements IContractsStore {
  @observable public fetching = false

  @observable private contractInfo = {}

  @observable private contractMetaInfo: IContractMetaInfo = {}

  @observable private contractsInfo = []

  @observable private filterContractsInfo = []

  @observable categories = []

  @observable draftState: number | null = null

  @computed get contract() {
    return this.contractInfo
  }

  @computed get contractMeta() {
    return this.contractMetaInfo
  }

  @computed get contracts() {
    return this.filterContractsInfo
  }

  @catchAction
  @action
  public fetchContracts = async () => {
    if (!isEmpty(this.contracts) || this.fetching) return
    this.fetching = true
    try {
      const resp: any = await api.get('/contract/model/category/1')
      runInAction(() => {
        this.contractsInfo = resp.data
        this.filterContractsInfo = resp.data
      })
    } catch (err) {
      console.log('Get contracts failed: ', err)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  @catchAction
  @action
  public fetchContractMeta = async (id: number) => {
    this.fetching = true
    try {
      const resp: any = await api.get(`contract/meta/${id}`)
      runInAction(() => {
        this.contractMetaInfo = resp.data
        return this.contractMetaInfo
      })
    } catch (err) {
      console.log('Get contract meta failed: ', err)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  @catchAction
  @action
  public getContractMeta = async (id: number) => {
    try {
      const resp: any = await api.get(`contract/meta/${id}`)
      return resp.data as Record<string, any>
    } catch (err) {
      console.log('Get contract meta failed: ', err)
    }

    return false
  }

  @catchAction
  @action
  public fetchCategories = async () => {
    this.fetching = true
    try {
      const resp: any = await api.post(`/category/search`, {})
      runInAction(() => {
        this.categories = resp.data.data
        return this.categories
      })
    } catch (err) {
      console.log('Get categories failed: ', err)
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  @catchAction
  @action
  public updateContractMeta = async (id: number) => {
    this.fetching = true
    const {
      name,
      permalink,
      state,
      category,
      model_published,
      category_order,
      locale,
      type,
      do_not_send_docs,
      do_not_send_welcome_email,
      do_not_send_relances,
      duplicate_of,
      cost,
      noindex,
      version_in_production
    } = this.contractMetaInfo
    const data = {
      name,
      permalink,
      state,
      category,
      model_published,
      category_order,
      locale,
      type,
      do_not_send_docs,
      do_not_send_welcome_email,
      do_not_send_relances,
      duplicate_of,
      cost,
      noindex,
      version_in_production
    }
    try {
      await api.put(`contract/meta/${id}`, data)
      toast.success('Model has been saved successfully !', {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      console.log('Get contract meta failed: ', err)
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
    const params: any = new URLSearchParams()
    dataStore.fetchProducts(params)
  }

  @catchAction
  @action
  public updateContractMetaInfo = (name: string, value: string) => {
    if (name === 'category') {
      const newCategory = this.categories.find(
        (category: any) => category.id === value
      )
      this.contractMetaInfo.category = newCategory
      return
    }
    if (name === 'model_published') {
      this.contractMetaInfo.model_published = value
      this.contractMetaInfo.version_in_production = +value
      this.contractMetaInfo.state = 2
      return
    }
    // @ts-ignore
    if (typeof this.contractMetaInfo[name] !== 'undefined')
      // @ts-ignore
      this.contractMetaInfo[name] = value
  }

  @catchAction
  @action
  public filterContracts = (searchStr: string) => {
    this.filterContractsInfo = this.contractsInfo.filter((contract: any) => {
      return contract.contract_meta_id.name
        .toLowerCase()
        .includes(searchStr.toLowerCase())
    })
  }

  @catchAction
  @action
  public selectContract = (contract: Object) => {
    this.contractInfo = { ...contract }
  }

  @catchAction
  @action
  public setDraftState = (draft: number) => {
    this.draftState = draft
  }
}

const contractsStore = new ContractsStore()
export default contractsStore
