import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import VariableContext from '../editors/VariableContext'
import { Style } from './components/Style'
import { Placeholder } from './components/Placeholder'
import { Suffix } from './components/Suffix'
import { TextareaSize } from './components/TextareaSize'
import { TextareaLineNumbers } from './components/TextareaLineNumbers'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: { style_category: 'Style' },
  en: { style_category: 'Style' }
}
I18n.putVocabularies(dictionary)
/**
 * Style variable params
 */
const StyleVariableParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <VariableContext.Consumer>
      {(state) => {
        const toggleCollapsed = () => setCollapsed(!collapsed)

        const { type, textarea } = state.variable

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('style_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <>
                {type === 'textarea' && <TextareaSize state={state} />}
                {type === 'textarea' && textarea?.size === 'p' && (
                  <TextareaLineNumbers state={state} />
                )}
                <Style state={state} />
                <Placeholder state={state} />
                <Suffix state={state} />
              </>
            )}
          </div>
        )
      }}
    </VariableContext.Consumer>
  )
}

export default StyleVariableParams
