import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { ErrorMessage, Formik } from 'formik'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import FormField from '../../../components/FormField/FormField'
import './style.scss'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

const columns = {
  en: {
    name: 'Contract name',
    'button-create': 'Create new contract',
    file_model: 'Model'
  },
  fr: {
    name: 'Nom du contrat',
    'button-create': 'Créer un nouveau contrat',
    file_model: 'Modèle'
  }
}

I18n.putVocabularies(columns)

interface IExtractionModalProps {
  open: boolean
  toggleModal(value: boolean): void
  id: any
  userId: number
  permalink: string
  name: string
}

@observer
class NewContractCdiModal extends Component<IExtractionModalProps> {
  @observable elementId = -1

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    toggleModal(open)
  }

  @catchAction
  @action
  handleSave = (values: any) => {
    const { userId, permalink } = this.props
    window.open(
      `${process.env.REACT_APP_WIZARDX}contrats/${permalink}/creer/1?contractName=${values.name}&userId=${userId}`,
      '_blank'
    )
    this.toggleModal(false)
  }

  private validate = (values: any) => {
    const errors: any = {}
    if (!values.name) {
      errors.name = 'Required'
    }
    return errors
  }

  render() {
    const { id, name } = this.props

    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="contrat-holder"
      >
        <div className="contrat-cdi">
          <h1>{name}</h1>
          <h5>{`${I18n.get('file_model')} ${id}`}</h5>
          <div className="move-select-holder">
            <Formik
              initialValues={{ name: '', tags: '' }}
              validate={this.validate}
              onSubmit={this.handleSave}
            >
              {({ handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="grid-container">
                    <div className="grid-item">
                      <FormField
                        label={I18n.get('name')}
                        type="text"
                        name="name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    </div>
                    <div className="grid-item grid-item-row">
                      {/* <FormField */}
                      {/* label="Tags" */}
                      {/* type="text" */}
                      {/* name="tags" */}
                      {/* handleChange={handleChange} */}
                      {/* handleBlur={handleBlur} */}
                      {/* /> */}
                    </div>
                    <ErrorMessage name="server_error" />
                  </div>
                  <div className="footer">
                    <button
                      className="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {I18n.get('button-create')}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default NewContractCdiModal
