import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import DropdownValues from '../../../../../../../components/Editor/Options'

const dictionary = {
  fr: {
    'Dropdown values': 'Valeurs'
  },
  en: {
    'Dropdown values': 'Dropdown values'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable select values (dropdown values)
 */
export const SelectValues: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { selectValues } = state.variable

  return (
    <div>
      <h5 style={{ margin: 0 }} className="text">
        {I18n.get('Dropdown values')}
      </h5>
      <DropdownValues
        list={selectValues || ['']}
        name="selectValues"
        setFieldValue={(name: string, values: string[]) => {
          state.updateSelectValues(values)
        }}
      />
    </div>
  )
}
