import React from 'react'
import { I18n } from 'aws-amplify'
import createStore from '../../../store/editor/createStore'
import Input from '../../Editor/InputField'
import CheckboxField from '../../Editor/CheckboxField'

const dictionary = {
  fr: {
    phone_number: 'Support téléphonique',
    display_phone_number: 'Afficher un numéro de téléphone',
    phone_number_label: 'Numéro de téléphone à afficher sur le boutton',
    phone_number_international:
      'Numéro de téléphone au format international sans espaces (Exemple: +331XXXXXXXX)'
  },
  en: {}
}

I18n.putVocabularies(dictionary)

type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

type OtherParamsModalStateType = {
  [key: string]: any
}

class OtherParamsModal extends React.Component<
  ParamsModalTabPropsType,
  OtherParamsModalStateType
> {
  constructor(props: ParamsModalTabPropsType) {
    super(props)

    const { getParams } = props

    this.state = {
      'meta.header.phonenumber.enabled':
        createStore.document.customization?.meta?.header?.phonenumber
          ?.enabled || false,
      'meta.header.phonenumber.phoneNumber':
        createStore.document.customization?.meta?.header?.phonenumber
          ?.phoneNumber || '',
      'meta.header.phonenumber.displayText':
        createStore.document.customization?.meta?.header?.phonenumber
          ?.displayText || '',
      ...getParams()
    }
  }

  updateParams = (newParams: Record<string, any>) => {
    const { setParams } = this.props

    this.setState({ ...this.state, ...newParams })
    setParams(newParams)
  }

  setFieldValue = (name: string, value: string | number | boolean) => {
    const newParams = {
      ...this.state,
      [name]: value
    }
    this.updateParams({
      ...newParams
    })
  }

  render() {
    return (
      <div>
        <div className="pm-block">
          <h3>{I18n.get('phone_number')}</h3>
          <CheckboxField
            name="meta.header.phonenumber.enabled"
            setFieldValue={this.setFieldValue}
            checked={this.state['meta.header.phonenumber.enabled'] || false}
            label={I18n.get('display_phone_number')}
          />
          {this.state['meta.header.phonenumber.enabled'] && (
            <>
              <Input
                name="meta.header.phonenumber.displayText"
                setFieldValue={this.setFieldValue}
                value={this.state['meta.header.phonenumber.displayText']}
                label={I18n.get('phone_number_label')}
              />

              <Input
                name="meta.header.phonenumber.phoneNumber"
                setFieldValue={this.setFieldValue}
                value={this.state['meta.header.phonenumber.phoneNumber']}
                label={I18n.get('phone_number_international')}
              />
            </>
          )}
        </div>
      </div>
    )
  }
}

export default OtherParamsModal
