import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { Formik } from 'formik'
import { I18n } from 'aws-amplify'
import { action, computed, observable, reaction } from 'mobx'
import { RouteComponentProps } from 'react-router'
import FormField from '../../../components/FormField/FormField'
import DataList from '../../../components/DataList/DataList'
import { IAuthStore } from '../../../store/authStore'
import { IDataStore } from '../../../store/dataStore'
import { IContractsStore } from '../../../store/editor/contractsStore'
import OrganizationModal from '../../../components/OrgsnizationModal/OrganizationModal'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

export interface IOrganizationPageProps extends RouteComponentProps {
  authStore: IAuthStore
  dataStore: IDataStore
  contractsStore: IContractsStore
}

interface IPersonalData {
  total_users?: string
  organization?: string
}

const columns = {
  en: {
    my_organization_title: 'My organization',
    personal_info_title: 'My personal information',
    org_name: 'Name of organization',
    num_of_users: 'Number of user',
    organization_title: 'Sub-organization',
    organization_new: 'New sub-organization',
    category_title: 'Product categories',
    category_new: 'Add new category'
  },
  fr: {
    my_organization_title: 'Mon organisation',
    personal_info_title: 'Informations de l’organisation',
    org_name: 'Nom de l’organisation',
    num_of_users: 'Nombre d’utilisateurs',
    organization_title: 'Equipes',
    organization_new: '+ Nouvelle équipe',
    category_title: 'Catégories de templates',
    category_new: 'Nouvelle catégorie'
  }
}

I18n.putVocabularies(columns)

@inject('authStore', 'dataStore', 'contractsStore')
@observer
class OrganizationPage extends Component<IOrganizationPageProps> {
  @observable private initialValues: IPersonalData = {
    total_users: '',
    organization: ''
  }

  async UNSAFE_componentWillMount() {
    const { fetchOrganization, fetchCategories } = this.props.dataStore

    await fetchOrganization()
    await fetchCategories()
    reaction(
      () => this.props.dataStore.organizationData,
      (organization) => {
        this.setPersonalInfo(organization)
      }
    )
  }

  @computed get organizations() {
    const { units } = this.props.dataStore.organizationData
    const { addUnit, deleteOption } = this.props.dataStore

    return {
      title: I18n.get('organization_title'),
      type: 'units',
      addOption: {
        label: I18n.get('organization_new'),
        onClick: () => {
          addUnit(1)
        }
      },
      handleChange: {
        onChange: (i: number, value: string, meta: any, el: any) => {
          this.handleUnitChange(i, value, 'units', el)
        }
      },
      deleteOption: {
        label: 'delete unit',
        onClick: deleteOption
      },
      data: units
    }
  }

  categories = () => {
    const { categories } = this.props.dataStore.organizationData
    const { addCategory, deleteOption } = this.props.dataStore
    return {
      title: I18n.get('category_title'),
      type: 'categories',
      addOption: {
        label: I18n.get('category_new'),
        // onClick: this.props.dataStore.createCategory
        onClick: () => {
          addCategory(1)
        }
      },
      handleChange: {
        onChange: (i: number, value: string, meta: any, el: any) => {
          this.handleUnitChange(i, value, 'categories', el)
        }
      },
      deleteOption: {
        label: 'delete category',
        onClick: deleteOption
      },
      data: categories
    }
  }

  @catchAction
  @action
  private setPersonalInfo = ({ total_users, organization }: any) => {
    this.initialValues = {
      total_users,
      organization: organization ? organization.name : ''
    }
  }

  @catchAction
  @action
  handleUnitChange = (i: number, value: string, type: string, el: any) => {
    this.props.dataStore.editOption(i, value, type, el)
  }

  public render(): ReactNode {
    const {
      newUnits,
      newCategories,
      organizationModalIsOpen,
      organizationModalType,
      toggleOrganizationModal,
      createCategory,
      createUnit,
      updateOption
    } = this.props.dataStore
    const { categories, units } = this.props.dataStore.organizationData
    const unitsOptions = units.concat(newUnits)
    const categoriesOptions = categories.concat(newCategories)

    return (
      <div className="content accountPageContainer">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('my_organization_title')}</h3>
          </div>
        </div>

        <div className="padding-container acFormContainer">
          <div className="grid-container-form">
            <div className="grid-item">
              <div className="grid-container-form">
                <div className="grid-item">
                  <Formik
                    initialValues={this.initialValues}
                    enableReinitialize
                    onSubmit={() => {}}
                  >
                    {() => (
                      <form noValidate onSubmit={(e) => e.preventDefault()}>
                        <h4 className="form-ttl">
                          {I18n.get('personal_info_title')}
                        </h4>
                        <FormField
                          label={I18n.get('org_name')}
                          type="text"
                          name="organization"
                          value={this.initialValues.organization}
                          readOnly
                        />
                        <FormField
                          label={I18n.get('num_of_users')}
                          type="text"
                          name="total_users"
                          value={this.initialValues.total_users}
                          readOnly
                        />
                      </form>
                    )}
                  </Formik>
                </div>
                <div className="grid-item">
                  <DataList
                    updateOption={updateOption}
                    toggleModal={toggleOrganizationModal}
                    newOpts={unitsOptions}
                    {...this.organizations}
                  />
                </div>
              </div>
            </div>
            <div className="grid-item">
              <DataList
                updateOption={updateOption}
                toggleModal={toggleOrganizationModal}
                newOpts={categoriesOptions}
                {...this.categories()}
              />
            </div>
            <OrganizationModal
              createCategory={createCategory}
              createUnit={createUnit}
              type={organizationModalType}
              toggleModal={toggleOrganizationModal}
              open={organizationModalIsOpen}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default OrganizationPage
