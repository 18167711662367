import { VariableV3 } from '@legalplace/models-v3-types'
import { getOptionParentTree } from './option'
import createStore from '../../../../../../store/editor/createStore'

export const getVariable = (id: number): VariableV3 => {
  const variable = createStore.document.variables[id]
  if (variable === undefined) throw new Error(`Undefined variable`)
  return variable
}

const getParentOption = (id: number): number => {
  const options = Object.values(createStore.document.options)

  for (let i = 0; i < options.length; i += 1)
    if (options[i].variables.includes(id)) return options[i].meta.id

  throw new Error(`Cannot find parent option for variable ${id}`)
}

export const getVariableParentTree = (
  id: number | string
): { type: string; id: number; label: string }[] => {
  const _id = typeof id === 'number' ? id : parseInt(id, 10)
  const parentOption = getParentOption(_id)

  return [
    ...getOptionParentTree(parentOption),
    {
      type: 'variable',
      id: _id,
      label: createStore.document.variables[id].label
    }
  ]
}
