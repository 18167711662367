import { TableCell } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import { I18n } from 'aws-amplify'
import { action, observable, runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component, ReactNode } from 'react'
import { match, RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import '../../../assets/scss/main.scss'
import DataPagination from '../../../components/DataPagination/DataPagination'
import DataTable from '../../../components/DataTable/DataTable'
import Icon from '../../../components/Icons/Icon'
import { EDITED, PLUS } from '../../../components/Icons/icons'
import { primary } from '../../../constants/scheme'
import { IDataStore } from '../../../store/dataStore'
import { IDialogStore } from '../../../store/dialogStore'
import { IContractsStore } from '../../../store/editor/contractsStore'
import createStore, {
  ICreateStore,
  INote
} from '../../../store/editor/createStore'
import './filterStyle.scss'
import TemplateDetailSettings from './TemplateDetailSettings'
import { catchAction } from '../../../store/editor/catchActionsDecorator'
import NewModelDialog from '../../../components/NewModel'
import openModelNotes from '../../../components/ModelNotes/ModelNotes'

interface IRouteParams {
  id: string
}

interface IAllTemplatesPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
  createStore?: ICreateStore | any
  contractsStore?: IContractsStore | any
  dialogStore?: IDialogStore | any
  match: match<IRouteParams>
}

const columns = {
  en: {
    template_detail_back: '< Templates',
    template_detail_items: 'Models',
    template_detail_edit: 'Edit',
    template_detail_preview: 'Preview',
    template_detail_duplicate: 'Duplicate',
    template_detail_id: 'ID',
    template_detail_model: 'Model',
    template_detail_state: 'State',
    template_detail_modified: 'Modified',
    template_detail_published: 'Published',
    template_detail_draft: 'Draft',
    template_detail_action: 'Action',
    template_detail_new_model: 'New Model'
  },
  fr: {
    template_detail_back: '< Templates',
    template_detail_items: 'Modèles',
    template_detail_edit: 'Modifier',
    template_detail_preview: 'Aperçu',
    template_detail_duplicate: 'Dupliquer',
    template_detail_id: 'ID',
    template_detail_model: 'Model',
    template_detail_state: 'Etat',
    template_detail_modified: 'Modifié',
    template_detail_published: 'Publié',
    template_detail_draft: 'Brouillon',
    template_detail_action: 'Action',
    template_detail_new_model: 'Nouveau modèle'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'dialogStore', 'contractsStore', 'createStore')
@observer
class TemplateDetailPage extends Component<IAllTemplatesPageProps> {
  @observable public template: any = {
    id: '',
    name: '',
    category: '',
    status: '',
    model: ''
  }

  @observable loadingModel: boolean = false

  @observable modelToPublish: number = -1

  @observable publishing: boolean = false

  private rows: any[] = [
    {
      disableOrder: false,
      id: 'model',
      label: I18n.get('template_detail_model')
    },
    {
      disableOrder: false,
      id: 'state',
      label: I18n.get('template_detail_state')
    },
    {
      disableOrder: false,
      id: 'modified',
      label: I18n.get('template_detail_modified')
    },
    // {disableOrder: false, id: 'published', label: I18n.get('template_detail_published')},
    {
      disableOrder: true,
      id: 'action',
      label: I18n.get('template_detail_action')
    }
  ]

  @catchAction
  @action
  public UNSAFE_componentWillMount() {
    const { dataStore, match: currentMatch } = this.props
    const template = dataStore.getProduct(currentMatch.params.id)
    if (!template) window.location.href = '/dashboard/all-templates'
    this.template = template
  }

  componentDidMount(): void {
    const { fetchContractMeta, fetchCategories } = this.props.contractsStore
    fetchContractMeta(this.props.match.params.id)
    fetchCategories()
  }

  @catchAction
  @action
  public goToDoc = async (
    id: number,
    permalink: string,
    duplicate?: boolean,
    row?: any,
    templateId?: number
  ) => {
    const { getModelData, fillMetaData } = this.props.createStore
    const { contractMetaInfo } = this.props.contractsStore
    // turn on loader
    this.loadingModel = true
    // get model from API
    await getModelData(
      id,
      permalink,
      row,
      contractMetaInfo.name,
      templateId,
      duplicate,
      true
    )
    fillMetaData()
    runInAction(() => {
      // turn off loader
      this.loadingModel = false
    })
  }

  @catchAction
  @action
  public createDoc = () => {
    const { toggleModal } = createStore
    toggleModal('newModelIsOpen', true)

    console.log(toggleModal)
  }

  public onChangeParams = () => {
    const { search } = this.props.location
    const params: any = new URLSearchParams(search)
    console.log(params.toString())
  }

  @catchAction
  @action
  public publishDraft = async () => {
    if (this.modelToPublish >= 0) {
      const { contractMetaInfo } = this.props.contractsStore
      const { getModelData, publishModelData } = this.props.createStore
      try {
        runInAction(() => {
          this.publishing = true
        })
        await getModelData(this.modelToPublish, contractMetaInfo.permalink)
        await publishModelData()
        const publishedModel = contractMetaInfo.models.find(
          (model: any) => model.id === this.modelToPublish
        )
        runInAction(() => {
          this.publishing = false
          // set model published
          publishedModel.draft = 0
        })
      } catch (e) {
        toast.error(e.message, {
          position: toast.POSITION.TOP_LEFT
        })
        runInAction(() => {
          this.publishing = false
        })
      }
    }
  }

  @catchAction
  @action
  public handleModelChange = (id: number) => {
    this.modelToPublish = id
  }

  public render(): ReactNode {
    // const {id, name} = this.template;
    const { categories, draftState } = this.props.contractsStore
    const { newModelIsOpen, isModelLoading } = this.props.createStore

    console.log(newModelIsOpen)

    let categoriesOptions = categories.map((category: any) => {
      return {
        label: category.name,
        value: category.id
      }
    })

    categoriesOptions = categoriesOptions.sort((a: any, b: any) =>
      a.label.localeCompare(b.label)
    )

    const { contractMetaInfo, fetching } = this.props.contractsStore
    const publishedOptions: any[] = [{ value: null, label: 'none' }]
    if (Object.keys(contractMetaInfo).length)
      contractMetaInfo.models.map((model: any) => {
        if (model.draft === 0)
          publishedOptions.push({ value: model.version, label: model.version })
        return null
      })

    return (
      <>
        <div className="content productsDetailPage">
          <div className="flex-row">
            <div className="row-item bread-crumbs-container">
              <div className="padding-container bread-crumbs">
                <Link
                  className="go-back-ttl secondary"
                  to="/dashboard/all-templates"
                >
                  {I18n.get('template_detail_back')}
                </Link>
              </div>
            </div>

            <div className="row-item">
              <div className="ttl-padding-container">
                <div className="ttl-container">
                  {/* <h5 className="sub-ttl">{I18n.get('template_detail_id')} {id}</h5> */}
                </div>
              </div>
              <FadeLoader color={primary} loading={this.publishing} />
              {/* <TemplateDetailFilter selectedModel={selectedModel} publishDraft={this.publishDraft} onChangeParams={this.onChangeParams}/> */}
            </div>
          </div>
          <div className="model-meta">
            <h3 className="model-ttl">{contractMetaInfo.name}</h3>
            {draftState === 2 ? (
              <div className="draft-status">Status: Draft</div>
            ) : (
              ''
            )}
          </div>
          <div className="padding-container">
            <TemplateDetailSettings
              name={contractMetaInfo.name}
              permalink={contractMetaInfo.permalink}
              type={contractMetaInfo.type}
              do_not_send_docs={contractMetaInfo.do_not_send_docs}
              template={this.template}
              modelId={contractMetaInfo.id}
              publishedOptions={publishedOptions}
              handleModelChange={this.handleModelChange}
              categoriesOptions={categoriesOptions}
              category={contractMetaInfo.category || -1}
              version_in_production={
                contractMetaInfo.version_in_production || -1
              }
            />
          </div>
          <FadeLoader color={primary} loading={fetching} />
          <FadeLoader color={primary} loading={isModelLoading} />

          <DataTable rows={this.rows} onChangeParams={this.onChangeParams}>
            {Object.keys(contractMetaInfo).length &&
              contractMetaInfo.models
                .sort((a: any, b: any) => (a.version > b.version ? -1 : 1))
                .map((row: any, i: number) => {
                  let notes: INote[] = []

                  if (typeof row.metadata === 'string') {
                    try {
                      const metadata = JSON.parse(row.metadata)
                      notes = [...(metadata.notes || [])]
                    } catch (e) {
                      console.log(e)
                    }
                  } else if (
                    typeof row.metadata === 'object' &&
                    row.metadata !== null
                  ) {
                    const { metadata } = row
                    notes = [...(metadata.notes || [])]
                  }

                  return (
                    <TableRow key={i}>
                      <TableCell align="right">{row.version}</TableCell>
                      <TableCell align="right">
                        {row.draft === 0
                          ? I18n.get('template_detail_published')
                          : I18n.get('template_detail_draft')}
                      </TableCell>
                      <TableCell align="right">
                        {moment(row.last_update * 1000).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </TableCell>
                      {/* <TableCell align="right">{moment(row.publication_time).format('DD/MM/YYYY HH:mm')}</TableCell> */}
                      <TableCell className="action-cell" align="right">
                        <div className="btn-wrapper ff-nowrap">
                          <button
                            type="button"
                            className="btn secondary"
                            onClick={() => {
                              createStore.setModelNotes(notes)
                              openModelNotes(true)
                            }}
                          >
                            {I18n.get('notes')} ({notes.length})
                          </button>
                          <button
                            type="button"
                            data-cypressId="templates-details-edit"
                            onClick={() =>
                              this.goToDoc(
                                row.id,
                                contractMetaInfo.permalink,
                                false,
                                row,
                                contractMetaInfo.id
                              )
                            }
                            className={
                              row.draft === 0 ? 'btn gray' : 'btn primary'
                            }
                          >
                            <Icon
                              iconClass="iconEdited"
                              icon={EDITED}
                              color={row.draft === 0 ? '#333' : '#FFF'}
                            />
                            {I18n.get('template_detail_edit')}
                          </button>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`${process.env.REACT_APP_WIZARDX}contrats/${contractMetaInfo.permalink}/creer/1?version=${row.version}`}
                          >
                            <button type="button" className="btn secondary">
                              {I18n.get('template_detail_preview')}
                            </button>
                          </a>
                          <button
                            type="button"
                            onClick={() =>
                              this.goToDoc(
                                row.id,
                                contractMetaInfo.permalink,
                                true,
                                row,
                                contractMetaInfo.id
                              )
                            }
                            className="btn secondary"
                          >
                            {I18n.get('template_detail_duplicate')}
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
          </DataTable>

          <div className="padding-container pagination-padding-container">
            <div className="btn-wrapper">
              <button
                type="button"
                className="btn primary"
                onClick={this.createDoc}
                disabled={fetching}
              >
                <Icon iconClass="iconPlus" icon={PLUS} color="#fff" />
                {I18n.get('template_detail_new_model')}
              </button>
            </div>
            <DataPagination
              onChangeParams={this.onChangeParams}
              total={
                Object.keys(contractMetaInfo).length &&
                contractMetaInfo.models.length
              }
              itemsName={I18n.get('template_detail_items')}
            />
          </div>
        </div>
        {newModelIsOpen && <NewModelDialog />}
      </>
    )
  }
}

export default withRouter(TemplateDetailPage)
