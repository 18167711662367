import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import './style.scss'
import { observable } from 'mobx'
import { Node, Editor } from '@legalplace/slate'
import SelectField, {
  IOption
} from '../../../../../components/SelectField/SelectField'
import Icon from '../../../../../components/Icons/Icon'
import { SETTINGS } from '../../../../../components/Icons/icons'
// import {MULTIPLEQUILLICON} from '../../../../../components/Icons/icons';
// import {CONDITIONTREEICON} from '../../../../../components/Icons/icons';
import { ICreateStore } from '../../../../../store/editor/createStore'
import promptModal from '../../../../../components/PromptPortal/PromptPortal'

const dictionary = {
  fr: {
    multiple: 'Multiple',
    condition: 'Condition',
    duplicate: 'Dupliquer'
  },
  en: {
    multiple: 'Multiple',
    condition: 'Condition',
    duplicate: 'Duplicate'
  }
}

I18n.putVocabularies(dictionary)

interface IParams {
  title: string
  pdf: boolean | null
  word: boolean | null
  [k: string]: any
}

interface IEditorToolbarProps {
  createStore?: ICreateStore | any
  toggleModal(name: string, val: boolean): void
  toggleConditionsDrawer(val: boolean, type: string): void
  setCurrentDocument(val: string): void
  addDocument(params: IParams): void
  duplicateDocument(slug: string): void
  documents: IOption[]
  currentDocument: string
}

@inject('createStore')
@observer
class EditorToolbar extends Component<IEditorToolbarProps> {
  @observable filePdf = ''

  @observable fileDoc = ''

  @observable selectedBlocks: any = []

  toggleModal = (name: string, open: boolean) => {
    const { toggleModal } = this.props
    toggleModal(name, open)
  }

  onHandleDocChange = (name: string, value: any) => {
    const { setCurrentDocument } = this.props
    setCurrentDocument(value)
  }

  fileInputChange = (e: any, name: string) => {
    e.persist()
    const file = e.target.files[0]
    // @ts-ignore
    this[name] = file
  }

  handleAddDoc = () => {
    const { addDocument } = this.props
    addDocument({
      title: 'New document',
      pdf: null,
      word: null,
      conditions: {}
    })
  }

  handleDuplicateDoc = () => {
    const { currentDocument, duplicateDocument } = this.props
    duplicateDocument(currentDocument)
  }

  handlePdfDoc = () => {
    // @ts-ignore
    const { slateEditor } = window
    if (Editor.isEditor(slateEditor)) {
      const content = Node.string(slateEditor)

      if (content.trim().length === 0) {
        this.toggleModal('pdfEditorIsOpen', true)
      } else {
        promptModal(
          () => (
            <h3>
              {I18n.get(
                'Current document is not empty, are you sure you want to override it?'
              )}
            </h3>
          ),
          {
            closeLabel: I18n.get('No'),
            saveLabel: I18n.get('Yes')
          }
        ).then(() => {
          this.toggleModal('pdfEditorIsOpen', true)
        })
      }
    } else {
      this.toggleModal('pdfEditorIsOpen', true)
    }
  }

  render() {
    const { documents, currentDocument } = this.props
    return (
      <div className="editor-toolbar">
        <div className="left-group">
          <div className="settings-field">
            <button
              onClick={() => this.toggleModal('documentParamsIsOpen', true)}
              type="button"
              className="btn-icon"
            >
              <Icon
                iconClass="setting-ico"
                icon={SETTINGS}
                color="rgb(173, 181, 189)"
              />
            </button>
            <SelectField
              fieldClass="fieldContent"
              label=""
              value={currentDocument}
              options={documents}
              setFieldValue={this.onHandleDocChange}
              name="document"
            />
            <button
              data-cypressId="create-doc"
              onClick={this.handleAddDoc}
              type="button"
              className="btn-white secondary"
            >
              +
            </button>
          </div>
          <button
            data-cypressId="create-doc"
            onClick={this.handlePdfDoc}
            type="button"
            className="btn-white secondary"
          >
            {I18n.get('pdf')}
          </button>
          <button
            data-cypressId="create-doc"
            onClick={this.handleDuplicateDoc}
            type="button"
            className="btn-white secondary"
          >
            {I18n.get('duplicate')}
          </button>
        </div>
      </div>
    )
  }
}

export default EditorToolbar
