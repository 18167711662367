import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import { RouteComponentProps } from 'react-router'
import { I18n } from 'aws-amplify'
import DataSearch from '../../../components/DataSearch/DataSearch'
import DataTable from '../../../components/DataTable/DataTable'
import DataPagination from '../../../components/DataPagination/DataPagination'
import { IDataStore } from '../../../store/dataStore'
import { IDialogStore } from '../../../store/dialogStore'
import NewUserForm from './NewUserForm'
import Icon from '../../../components/Icons/Icon'
import { PLUS } from '../../../components/Icons/icons'

interface IUsersPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
  dialogStore?: IDialogStore | any
}

const columns = {
  en: {
    users_title: 'Users',
    users_new: 'Add new user',
    users_search: 'Search users ...',
    users_edit: 'Modify',
    users_items_name: 'Users',
    users_id: 'User ID',
    users_email: 'Email',
    users_first_name: 'First Name',
    users_last_name: 'Last Name',
    users_role: 'Role',
    users_sub_organization: 'Sub-organization',
    users_action: 'Action',
    users_refresh: 'Refresh'
  },
  fr: {
    users_title: 'Utilisateurs',
    users_new: 'Ajouter un utilisateur',
    users_search: 'Chercher par nom de template',
    users_edit: 'Modifier',
    users_items_name: 'utilisateurs',
    users_id: 'ID',
    users_email: 'Email',
    users_first_name: 'Prénom',
    users_last_name: 'Nom',
    users_role: 'Role',
    users_sub_organization: 'Equipe',
    users_action: 'Action',
    users_refresh: 'Actualiser'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'dialogStore')
@observer
class UsersPage extends Component<IUsersPageProps> {
  private rows: any[] = [
    { disableOrder: false, id: 'id', label: I18n.get('users_id') },
    { disableOrder: false, id: 'email', label: I18n.get('users_email') },
    {
      disableOrder: false,
      id: 'first_name',
      label: I18n.get('users_first_name')
    },
    {
      disableOrder: false,
      id: 'last_name',
      label: I18n.get('users_last_name')
    },
    { disableOrder: true, id: 'role', label: I18n.get('users_role') },
    {
      disableOrder: true,
      id: 'sub_organization',
      label: I18n.get('users_sub_organization')
    },
    {
      disableOrder: true,
      id: 'sub_organization',
      label: I18n.get('users_action')
    }
  ]

  async UNSAFE_componentWillMount() {
    this.onChangeParams()
    this.props.dataStore.fetchOrganization()
  }

  public newUser = () => {
    const { dialogStore, dataStore } = this.props
    const { organization } = this.props.dataStore
    dialogStore.openDialog(
      <NewUserForm units={organization.units} onSubmit={dataStore.newUser} />
    )
  }

  public updateUser = (user: any) => () => {
    const { dialogStore, dataStore } = this.props
    const { organization } = this.props.dataStore
    dialogStore.openDialog(
      <NewUserForm
        units={organization.units}
        form={user}
        onSubmit={dataStore.updateUser}
      />
    )
  }

  public onChangeParams = () => {
    const { location, dataStore } = this.props
    const params: any = new URLSearchParams(location.search)
    dataStore.fetchUsers(params)
  }

  public render(): ReactNode {
    const { dataStore } = this.props
    const { data, total } = dataStore.usersData
    return (
      <div className="content">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('users_title')}</h3>
            <button type="button" className="primary" onClick={this.newUser}>
              <Icon iconClass="iconPlus" icon={PLUS} color="#fff" />
              {I18n.get('users_new')}
            </button>
          </div>
          <div className="search-holder">
            <DataSearch
              onChangeParams={this.onChangeParams}
              placeholder={I18n.get('users_search')}
            />
            <button
              type="button"
              className="secondary"
              onClick={this.onChangeParams}
            >
              {I18n.get('users_refresh')}
            </button>
          </div>
        </div>

        <DataTable rows={this.rows} onChangeParams={this.onChangeParams}>
          {data.map((row: any, i: number) => (
            <TableRow key={i}>
              <TableCell className="big-text" align="right">
                {row.id}
              </TableCell>
              <TableCell className="big-text" align="right">
                {row.email}
              </TableCell>
              <TableCell className="big-text" align="right">
                {row.first_name}
              </TableCell>
              <TableCell className="big-text" align="right">
                {row.last_name}
              </TableCell>
              {/* <TableCell className="big-text" align="right">{row.unit}</TableCell> */}
              <TableCell className="big-text" align="right">
                {row.role}
              </TableCell>
              {/* <TableCell className="big-text" align="right">{row.organization.name}</TableCell> */}
              <TableCell className="big-text" align="right">
                {row.unit ? row.unit.name : 'N/A'}
              </TableCell>
              <TableCell align="right">
                <div className="btn-wrapper ff-nowrap">
                  <button
                    type="button"
                    className="btn secondary"
                    onClick={this.updateUser(row)}
                  >
                    {I18n.get('users_edit')}
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </DataTable>

        <div className="padding-container pagination-padding-container">
          <DataPagination
            onChangeParams={this.onChangeParams}
            total={total}
            itemsName={I18n.get('users_items_name')}
          />
        </div>
      </div>
    )
  }
}

export default UsersPage
