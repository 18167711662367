import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionV3 } from '@legalplace/models-v3-types'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    'Box type': 'Type de boite info'
  },
  en: {
    'Box type': 'Box type'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits box type
 */
export const BoxType: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const type = state.option.meta.box?.type || ''

  return (
    <div className="label-holder">
      <SelectField
        setFieldValue={(
          name: string,
          value: Exclude<
            Exclude<OptionV3['meta']['box'], undefined>['type'],
            undefined
          >
        ) => state.updateBoxType(value)}
        value={type}
        meta="box"
        name="boxType"
        label={I18n.get('Box type')}
        options={createStore.boxTypes}
      />
    </div>
  )
}
