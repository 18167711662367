import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import LabelEditor from '../../../../../../../components/Editor/LabelEditor'

const dictionary = {
  fr: {
    'Fallback Label': 'Label quand la variable est vide'
  },
  en: {
    'Fallback Label': 'Fallback Label'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits fallback label for question, radio & checkbox
 */
export const FallbackLabel: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const { label, fallbackLabel } = state.option.meta

  if (!/\[var:([0-9]+)\]/gi.test(label)) return <></>

  return (
    <div className="label-holder">
      <LabelEditor
        value={fallbackLabel || ''}
        meta
        name="fallback-label"
        setFieldValue={(name, value) => {
          state.updateFallbackLabel(value)
        }}
        label={I18n.get('Fallback Label')}
      />
    </div>
  )
}
