import { Transforms, Editor, Text, Node, Path, Range } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'

import { OptionV3 } from '@legalplace/models-v3-types'
import TableHelpers from './TableHelpers'

export type EditorMarksTypes =
  | 'bold'
  | 'italic'
  | 'underlined'
  | 'align'
  | 'isRepeated'
export type EditorBlockTypes =
  | 'numbered-list'
  | 'bulleted-list'
  | 'heading-one'
  | 'heading-two'
  | 'heading-three'
  | 'heading-four'
  | 'heading-five'
  | 'heading-six'

/**
 * Used by storeSelection & applySelection
 * to store selection
 */
let selectionCache: Range | null = null

const EditorHelpers = {
  setOutput(
    editor: Editor,
    id: number,
    output: {
      repeated?: boolean
      repeatedMeta?: OptionV3['meta']
      hasConditions?: boolean
      originalOption?: OptionV3
    }
  ) {
    Transforms.setNodes(editor, output, {
      match: (n) => {
        if (n.type === 'output') console.log(n.id)
        return n.type === 'output' && n.id === id
      }
    })
  },

  setOutputConditionalMark(editor: Editor, id: number, value: boolean) {
    Transforms.setNodes(
      editor,
      {
        hasConditions: value
      },
      {
        match: (n) => n.type === 'output' && n.id === id
      }
    )
  },

  isOutputRepeated(editor: Editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => typeof n.repeated === 'number'
    })
    return !!match
  },

  getCurrentOutputsRepeated(editor: Editor) {
    return Array.from(
      Editor.nodes(editor, {
        match: (n) => n.type === 'output',
        universal: true
      })
    ).map((n) => n[0].repeatedMeta?.repeatOption)
  },

  isOutputConditional(editor: Editor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.hasConditions
    })
    return !!match
  },

  getCurrentOutputs(editor: Editor) {
    return Array.from(
      Editor.nodes(editor, {
        match: (n) => n.type === 'output',
        universal: true
      })
    ).map((n) => n[0])
  },

  isMarkActive(
    editor: Editor,
    mark: EditorMarksTypes,
    value?: string | number
  ) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n[mark] === (value !== undefined ? value : true),
      universal: true
    })
    return !!match
  },

  toggleMark(editor: Editor, mark: EditorMarksTypes, value?: string | number) {
    const isActive = EditorHelpers.isMarkActive(editor, mark, value)
    Transforms.setNodes(
      editor,
      { [mark]: isActive ? null : value !== undefined ? value : true },
      { match: (n) => Text.isText(n) && n.type !== 'variable', split: true }
    )
    Transforms.setNodes(
      editor,
      { [mark]: isActive ? null : value !== undefined ? value : true },
      { match: (n) => Text.isText(n) && n.type === 'variable', split: false }
    )
  },

  isBlockStyleActive(
    editor: Editor,
    attribute: string,
    value: string | number
  ): boolean {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.style && n.style[attribute] === value,
      universal: true
    })
    return !!match
  },

  toggleBlockStyle(
    editor: Editor,
    attribute: string,
    value: string | number,
    defaultValue: string | number | null = null
  ) {
    const isActive = EditorHelpers.isBlockStyleActive(editor, attribute, value)

    Transforms.setNodes(
      editor,
      (node) => {
        const style = node.style || {}
        return {
          style: {
            ...style,
            [attribute]: isActive ? defaultValue : value
          }
        }
      },
      {
        match: (n) =>
          [
            'paragraph',
            'list-item',
            'table-cell',
            'heading-one',
            'heading-two',
            'heading-three',
            'heading-four',
            'heading-five',
            'heading-six'
          ].includes(n.type),
        mode: 'highest'
      }
    )
  },

  isMultipleNodeSelection(editor: Editor) {
    const { selection } = editor
    if (selection === null) return false
    const { anchor, focus } = selection
    const anchorPath = anchor.path
    const focusPath = focus.path

    const anchorNode = EditorHelpers.getNodeByPath(editor, anchorPath)
    const focusNode = EditorHelpers.getNodeByPath(editor, focusPath)

    return !(anchorNode === focusNode)
  },

  getAllNodesInSelection(editor: Editor, type?: string) {
    const { selection } = editor
    if (selection === null) return []
    const { anchor, focus } = selection
    const anchorPath = anchor.path
    const focusPath = focus.path
    return Array.from(
      Node.nodes(editor, {
        from: anchorPath,
        to: focusPath
      })
    )
      .map(([node, path]) => ({ node, path }))
      .filter((n) => {
        if (type === undefined) return true
        if (type === 'isText') {
          if (Text.isText(n.node)) return true
        } else if (n.node.type === type) return true
        return false
      })
  },

  isBlockActive(editor: Editor, format: EditorBlockTypes) {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === format
    })

    return !!match
  },

  toggleBlock(editor: ReactEditor, format: EditorBlockTypes) {
    const isActive = EditorHelpers.isBlockActive(editor, format)
    const isList = ['numbered-list', 'bulleted-list'].includes(format)
    const wrapDescendantsOf = ['table-cell']
    let wrap = false

    // Determining whether we need top wrap block or not
    const { selection } = editor
    if (selection === null) return
    const { anchor } = selection
    const { path } = anchor
    const isTableCell = TableHelpers.isCell(editor)

    // Getting last non-text node
    const lastNode = path.reduce((previousNode: Node, currentPath) => {
      if (Node.isNode(previousNode)) {
        const currentNode = previousNode.children[currentPath]
        if (Text.isText(currentNode)) return previousNode
        return currentNode
      }
      return editor
    }, editor)

    if (wrapDescendantsOf.includes(lastNode.type)) wrap = true

    Transforms.unwrapNodes(editor, {
      match: (n) => ['numbered-list', 'bulleted-list'].includes(n.type),
      split: true
    })

    if (wrap === false) {
      const type = isActive ? 'paragraph' : isList ? 'list-item' : format
      Transforms.setNodes(editor, {
        type
      })

      if (isTableCell) {
        Transforms.unwrapNodes(editor, {
          match: (n) => n.type === type,
          split: true
        })
      }
    } else {
      Transforms.wrapNodes(
        editor,
        {
          type: isActive ? 'paragraph' : isList ? 'list-item' : format,
          children: []
        },
        { match: (n) => Text.isText(n) }
      )
    }

    if (!isActive && isList) {
      const block = { type: format, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  },

  getNodeByPath(editor: Editor, path: Path) {
    return path.reduce((previousNode: Node, currentPath) => {
      if (Node.isNode(previousNode)) {
        const currentNode = previousNode.children[currentPath] as Node
        return currentNode
      }
      return editor
    }, editor)
  },

  getLastNodeOfType(
    editor: Editor,
    type: string,
    path: Path
  ): [Node | null, Path | null] {
    let lastPath: number[] = []
    const progressPath: number[] = []
    path.reduce((previousNode: Node, currentPath) => {
      progressPath.push(currentPath)
      if (Node.isNode(previousNode)) {
        const currentNode = previousNode.children[currentPath] as Node
        if (currentNode.type === type) lastPath = [...progressPath]
        return currentNode
      }
      return editor
    }, editor)
    if (lastPath.length === 0) return [null, null]
    const lastNode = Array.from(
      Editor.nodes(editor, {
        at: lastPath,
        match: (n) => n.type === type
      })
    )
      .map((n) => n[0])
      .pop()
    return [lastNode || null, lastPath]
  },

  getCurrentLastNodeOfType(
    editor: Editor,
    type: string
  ): [Node | null, Path | null] {
    if (editor.selection === null) return [null, null]
    return EditorHelpers.getLastNodeOfType(
      editor,
      type,
      editor.selection.anchor.path
    )
  },

  /**
   * Stores current selection
   * ------------------------
   * Used when you want to unfocus from the editor
   * for certains actions (like when opening popups)
   * with applySelection to move back focus to the
   * editor when needed
   * @param editor Editor
   */
  storeSelection(editor: Editor) {
    selectionCache = editor.selection
  },

  /**
   * Applies previously stored selection
   * using storeSelection(), used when
   * you want to bring back selection to
   * editor after unfocus
   *
   * Can also be used to apply custom selection
   * those by setting its second argument selection
   * @param editor Editor
   * @param selection Range
   */
  applySelection(editor: Editor, selection: Range | null = selectionCache) {
    if (selection === null) return

    const { anchor, focus } = selection
    // Focusing on node
    editor.apply({
      type: 'set_selection',
      properties: {
        anchor,
        focus
      },
      newProperties: {
        anchor,
        focus
      }
    })

    // selectionCache = null
  }
}

export default EditorHelpers
