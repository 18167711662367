import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import createStore from '../../../../../../store/editor/createStore'
import { MultipleEnabled } from './components/MultipleEnabled'
import { MultipleCta } from './components/MultipleCta'
import { MultipleLabel } from './components/MultipleLabel'
import { MultipleIncrementationStart } from './components/MultipleIncrementationStart'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: { multiple_category: 'Multiple' },
  en: { multiple_category: 'Multiple' }
}
I18n.putVocabularies(dictionary)
/**
 * Multiple option params
 */
const MultipleOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <OptionContext.Consumer>
      {(state) => {
        const { getOptionParent } = createStore
        const { id, type } = state.option.meta

        const parentId = getOptionParent(id)
        const isRoot = parentId === id

        if (!(isRoot && type === 'static')) return <></>

        const toggleCollapsed = () => setCollapsed(!collapsed)

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('multiple_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <div>
                <MultipleEnabled state={state} />
                <MultipleCta state={state} />
                <MultipleLabel state={state} />
                <MultipleIncrementationStart state={state} />
              </div>
            )}
          </div>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default MultipleOptionParams
