import React from 'react'

import LinkButton from '../LinkButton/LinkButton'
import './style.scss'

const Title = (props: any) => {
  const { title, link, buttonTitle, showButton = true } = props
  return (
    <div className="title">
      <h1>{title}</h1>
      {showButton && (
        <LinkButton href={link} type="big">
          {buttonTitle}
        </LinkButton>
      )}
    </div>
  )
}

export default Title
