import React from 'react'
import { cx, css } from 'emotion'

const Menu = React.forwardRef(({ className, ...props }: any, ref) => (
  <div
    contentEditable={false}
    {...props}
    ref={ref}
    className={cx(
      className,
      css`
        & {
          padding: 10px;
          margin: 0;
          display: flex;
        }
        & > * {
          display: inline-block;
        }
        & > * + * {
          margin-left: 5px;
        }

        & .toolbar-separator {
          height: 24px;
          width: 0;
          border-left: 1px solid #ccc;
        }
      `
    )}
  />
))

Menu.displayName = 'Menu'

export default Menu
