import {
  Range,
  Editor,
  Point,
  Text,
  Transforms,
  Path,
  Node
} from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import { MouseEvent, KeyboardEvent } from 'react'
import EditorHelpers from '../helpers/EditorHelpers'

let previousSelection: Range | null = null

/**
 * Wraps variables in selection if they're at the beginning
 * or the end of the range
 * @param editor
 * @param startPoint
 * @param endPoint
 */
const wrapVariableInSelection = (
  editor: Editor & ReactEditor,
  startPoint: Point,
  endPoint: Point
) => {
  const startNode = EditorHelpers.getNodeByPath(editor, startPoint.path)
  const endNode = EditorHelpers.getNodeByPath(editor, endPoint.path)

  // Checking whether any of starting point or ending
  // point is a variable (or both)

  const selection = {
    anchor: startPoint,
    focus: endPoint
  }

  // Start node
  if (Text.isText(startNode) && startNode.type === 'variable') {
    // Wrapping start node in selection
    selection.anchor = { ...startPoint, offset: 0 }
  }

  // End node
  if (Text.isText(endNode) && endNode.type === 'variable') {
    // Wrapping end node in selection
    selection.focus = { ...endPoint, offset: endNode.text.length }
  }

  if (selection.anchor !== startPoint || selection.focus !== endPoint) {
    editor.apply({
      type: 'set_selection',
      properties: {
        anchor: startPoint,
        focus: endPoint
      },
      newProperties: selection
    })
  }
}

/**
 * Checks if event is a keyboard event
 * @param event
 */
const isKeyboard = (event: React.SyntheticEvent): event is KeyboardEvent =>
  event.nativeEvent instanceof window.KeyboardEvent

const insertEmptyNode = (editor: Editor & ReactEditor, at: Path) =>
  Transforms.insertNodes(
    editor,
    {
      text: '\uFEFF'
    },
    {
      at
    }
  )
/**
 * Moves the cursor away for variables
 * @param editor
 */
const moveCursorOutsideVariable = (
  editor: Editor & ReactEditor,
  startPoint: Point,
  moveForward: boolean
) => {
  const { path } = startPoint
  const node = EditorHelpers.getNodeByPath(editor, path)
  const parentNode = EditorHelpers.getNodeByPath(editor, path.slice(0, -1))
  const currentIndex = parentNode.children.indexOf(node)
  const nextSibling = parentNode.children[currentIndex + 1]
  const previousSibling = parentNode.children[currentIndex - 1]

  if (Text.isText(node) && node.type === 'variable') {
    const anchor: Point = {
      offset: 0,
      path: []
    }
    if (moveForward) {
      anchor.path = [...path.slice(0, -1), currentIndex + 1]
      anchor.offset += 1
      if (
        nextSibling === undefined ||
        (nextSibling &&
          !/\s/.test(Node.leaf(editor, anchor.path).text.slice(0, 1)))
      ) {
        insertEmptyNode(editor, anchor.path)
      }
    } else {
      anchor.path = [...path.slice(0, -1), currentIndex - 1]
      if (previousSibling === undefined) {
        anchor.path = [...path.slice(0, -1), 0]
        insertEmptyNode(editor, anchor.path)
      } else {
        anchor.offset = previousSibling.text.length
      }
    }

    const newProperties = {
      anchor,
      focus: anchor
    }

    editor.apply({
      type: 'set_selection',
      properties: {
        ...editor.selection
      },
      newProperties
    })

    previousSelection = newProperties
  } else if (
    Text.isText(previousSibling) &&
    previousSibling.type === 'variable' &&
    startPoint.offset === 0
  ) {
    moveCursorOutsideVariable(
      editor,
      {
        path: [...path.slice(0, -1), currentIndex - 1],
        offset: previousSibling.text.length - 1
      },
      moveForward
    )
  }
}

/**
 * Watches selection changes and executes
 * related actions to handle variables
 * properly
 * @param event
 * @param editor
 */
function watchSelectionChange<EventType extends KeyboardEvent | MouseEvent>(
  event: EventType,
  editor: Editor & ReactEditor
) {
  // Checking whether selection changed
  const { selection } = editor

  // If both selections are null let's stop right here
  // there's no need to do anything
  if (selection === null && previousSelection === null) return false

  // If selection was lost let's update previousSelection & stop
  if (selection === null) {
    previousSelection = null
    return true
  }

  // // If selection didn't change let's stop here too
  // if (JSON.stringify(selection) === JSON.stringify(previousSelection)) {
  //   return false
  // }

  // Determining whether anchor moved forward or backward
  const startPoint = Range.start(selection)
  const endPoint = Range.end(selection)
  const isCollapsed = Range.isCollapsed(selection)

  // Wrapping variables in selection if needed
  if (!isCollapsed) wrapVariableInSelection(editor, startPoint, endPoint)

  // Initiating moveForward to true
  let moveForward = true

  if (
    previousSelection !== null &&
    isKeyboard(event) &&
    ['ArrowLeft', 'ArrowRight', 'ArrowTop', 'ArrowDown'].includes(event.key)
  ) {
    // Making moveForward false if use
    // presses ArrowLeft keyboard button
    moveForward = event.key !== 'ArrowLeft'
  }

  // Moving cursor away from variable
  if (isCollapsed) {
    moveCursorOutsideVariable(editor, startPoint, moveForward)
  }

  // Storing selection
  previousSelection = selection
  return true
}

export default watchSelectionChange
