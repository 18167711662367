import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'
import ReactPaginate from 'react-paginate'
import { replace } from '../../utils/history'
import { ARROWLEFT, ARROWRIGHT } from '../Icons/icons'
import Icon from '../Icons/Icon'

interface IDataPaginationProps extends RouteComponentProps {
  onChangeParams(): void

  placeholder?: string
  total: number
  itemsName?: string
}

@observer
class DataPagination extends Component<IDataPaginationProps> {
  public onPageChange = ({ selected }: { selected: number }) => {
    this.replaceHistory(selected)
  }

  public get params() {
    const { location, total } = this.props
    const params: any = new URLSearchParams(location.search)
    const limit = params.get('limit') || 10
    const offset = params.get('offset') || 0
    const pageCount = Math.ceil(total / limit)
    const initialPage = Math.floor(offset / limit)
    return {
      pageCount,
      initialPage
    }
  }

  public replaceHistory = (page: number) => {
    const { location, onChangeParams } = this.props
    const { search, pathname } = location
    const params: any = new URLSearchParams(search)
    const limit = params.get('limit') || 10
    params.set('limit', limit)
    params.set('offset', limit * page)
    replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      onChangeParams()
    })
  }

  public render(): ReactNode {
    const { total, itemsName = '' } = this.props
    const { pageCount, initialPage } = this.params
    return (
      <div className="data-pagination">
        <div className="total">
          {total} {itemsName}
        </div>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={4}
          marginPagesDisplayed={0}
          initialPage={initialPage}
          onPageChange={this.onPageChange}
          disableInitialCallback
          previousLabel={
            <Icon iconClass="iconArrows iconArrowLeft" icon={ARROWLEFT} />
          }
          nextLabel={
            <Icon iconClass="iconArrows iconArrowRight" icon={ARROWRIGHT} />
          }
        />
        <div />
      </div>
    )
  }
}

export default withRouter(DataPagination)
