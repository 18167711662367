import React, { useState, FormEvent } from 'react'
import ReactDOM from 'react-dom'
import { I18n } from 'aws-amplify'
import './prompt.scss'

const wording = {
  en: {
    Save: 'Save',
    Close: 'Close'
  },
  fr: {
    Save: 'Enregistrer',
    Close: 'Fermer'
  }
}

I18n.putVocabularies(wording)

type PromptPortalProps = {
  onCancel: () => void
  onSave: (value: string) => void
  saveLabel?: string
  closeLabel?: string
  title?: string
  expectedValue?: string
}

const PromptPortalComponent: React.FC<PromptPortalProps> = ({
  onCancel,
  onSave,
  saveLabel,
  closeLabel,
  expectedValue,
  title,
  children
}) => {
  const [value, setValue] = useState('')

  const handleValueChange = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }

  const handleSave = () => {
    onSave(value)
  }

  return (
    <div className="modal-container">
      {title && <div className="modal-title">{title}</div>}
      <div className="modal-content">{children}</div>
      {typeof expectedValue === 'string' && (
        <div className="modal-form">
          <input type="text" value={value} onInput={handleValueChange} />
        </div>
      )}
      <div className="modal-controls">
        <button type="button" className="secondary" onClick={onCancel}>
          {closeLabel || I18n.get('Close')}
        </button>
        <button
          type="button"
          disabled={
            typeof expectedValue === 'string' && expectedValue !== value
          }
          className="primary"
          onClick={handleSave}
        >
          {saveLabel || I18n.get('Save')}
        </button>
      </div>
    </div>
  )
}

const promptModal = (
  Children: React.FC,
  params?: {
    title?: string
    expectedValue?: string
    saveLabel?: string
    closeLabel?: string
  }
) => {
  return new Promise((resolve: (value: string) => void, reject) => {
    const el = document.createElement('div')
    const closePrompt = () => {
      document.body.removeChild(el)
    }
    const onSave = (value: string) => {
      closePrompt()
      resolve(value)
    }
    const onCancel = () => {
      closePrompt()
      reject()
    }
    ReactDOM.render(
      <div className="modal-root" role="dialog">
        <PromptPortalComponent
          onCancel={onCancel}
          title={params && params.title}
          expectedValue={params && params.expectedValue}
          saveLabel={params && params.saveLabel}
          closeLabel={params && params.closeLabel}
          onSave={onSave}
        >
          <Children />
        </PromptPortalComponent>
      </div>,
      el
    )
    document.body.appendChild(el)
  })
}

export default promptModal
