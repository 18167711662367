import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionV3 } from '@legalplace/models-v3-types'
import { action } from 'mobx'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import createStore from '../../../../../../../store/editor/createStore'
import CheckboxField from '../../../../../../../components/Editor/CheckboxField'
import { catchAction } from '../../../../../../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'Choice by default': 'Choix par défaut'
  },
  en: {
    'Choice by default': 'Choice by default'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option type
 */
export class DefaultRadio extends React.Component<{
  state: OptionEditorStateType
}> {
  parentOption: OptionV3 | undefined

  type: OptionV3['meta']['type']

  id: number

  isDefaultRadio: boolean = false

  constructor(props: { state: OptionEditorStateType }) {
    super(props)

    const { getOptionParent, document } = createStore
    const { id, type } = this.props.state.option.meta

    this.type = type
    this.id = id

    const parentId = getOptionParent(id)

    if (type === 'radio') {
      this.parentOption = document.options[parentId]
      this.isDefaultRadio = id === this.parentOption.meta.defaultRadio
    }
  }

  @catchAction
  @action
  updateDefaultRadio = (isDefault: boolean) => {
    if (!this.parentOption) return
    if (isDefault) this.parentOption.meta.defaultRadio = this.id
    else delete this.parentOption.meta.defaultRadio
  }

  render() {
    return (
      <>
        {this.type === 'radio' && (
          <div className="step-wrapper">
            <div className="step-holder">
              <CheckboxField
                meta
                checked={this.isDefaultRadio}
                name="defaultRadio"
                setFieldValue={(name: string, value: boolean) =>
                  this.updateDefaultRadio(value)
                }
                label={I18n.get('Choice by default')}
                value="default"
              />
            </div>
          </div>
        )}
      </>
    )
  }
}
