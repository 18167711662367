import React, { Component } from 'react'
import { observer } from 'mobx-react'
import './style.scss'
import Dialog from '@material-ui/core/Dialog'
import { action, observable } from 'mobx'
import { I18n } from 'aws-amplify'
import Input from '../Editor/InputField'
import Icon from '../Icons/Icon'
import { DELICON } from '../Icons/icons'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'prefill-title': 'Pré-remplissage',
    modify: 'Modifier',
    new: 'Ajouter',
    'prefill-cancel': 'Annuler',
    'prefill-save': 'Enregistrer'
  },
  en: {
    'prefill-title': 'Pre-filling fields',
    modify: 'Add/Modify',
    new: 'New pre-filling',
    'prefill-cancel': 'Cancel',
    'prefill-save': 'Save'
  }
}

I18n.putVocabularies(dictionary)

interface IPrefillModalProps {
  open: boolean
  toggleModal(name: string, value: boolean): void
  selectedElement: any
  paramsWasShown: boolean
  selectedElementPrefillings: any[]
  conditionsCollector(list: any, source: any): void
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
  toggleDrawer(open: boolean): void
  toggleParamsWasShown(val: boolean): void
  togglePrefillWasShown(val: boolean): void
  setPrefillings(): void
}

@observer
class PrefillModal extends Component<IPrefillModalProps> {
  @observable prefillings = this.props.selectedElementPrefillings

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    toggleModal('prefillIsOpen', open)
  }

  @catchAction
  @action
  public addVariable = () => {
    this.props.selectedElementPrefillings.push({
      conditions: {},
      text: ''
    })
  }

  @catchAction
  @action
  public delVariable = (index: number) => {
    this.props.selectedElementPrefillings.splice(index, 1)
  }

  handleSave = () => {
    this.props.setPrefillings()
    this.toggleModal(false)
  }

  @catchAction
  @action
  handleInputChange = (name: string, value: string, i: number) => {
    this.props.selectedElementPrefillings[i].value = value
  }

  onManageClick = (i: number) => {
    this.props.toggleParamsWasShown(true)
    this.props.togglePrefillWasShown(true)
    this.toggleModal(false)
    this.props.toggleDrawer(false)
    this.props.toggleConditionsDrawer(true, 'prefill', i)
  }

  render() {
    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="prefill-modal-holder"
      >
        <div className="prefill-modal">
          <h1>{I18n.get('prefill-title')}</h1>
          <div className="prefill-holder">
            {this.props.selectedElementPrefillings &&
              this.props.selectedElementPrefillings.map(
                (el: any, i: number) => {
                  const { conditions } = el
                  let collectedConditions: any = []
                  if (conditions) {
                    collectedConditions = this.props.conditionsCollector(
                      Object.keys(conditions),
                      conditions
                    )
                  }
                  collectedConditions = collectedConditions.length
                    ? collectedConditions.length
                    : 0
                  return (
                    <div key={i} className="prefill-container">
                      <div className="prefill-left">
                        <Input
                          value={el.value}
                          setFieldValue={(name: string, value: string) => {
                            this.handleInputChange(name, value, i)
                          }}
                          multiline
                        />
                      </div>
                      <div className="prefill-right">
                        <div>{`${collectedConditions} conditions`}</div>
                        <button
                          type="button"
                          onClick={() => this.onManageClick(i)}
                          className="secondary"
                        >
                          {I18n.get('modify')}
                        </button>

                        <button
                          type="button"
                          className="btn-icon del"
                          onClick={() => this.delVariable(i)}
                        >
                          <Icon icon={DELICON} color="#adb5bd" />
                        </button>
                      </div>
                    </div>
                  )
                }
              )}
            <button
              type="button"
              className="btn-prefill secondary"
              onClick={() => this.addVariable()}
            >
              {I18n.get('new')}
            </button>
          </div>
          <div className="btn-holder">
            <button
              type="button"
              className="secondary"
              onClick={() => this.toggleModal(false)}
            >
              {I18n.get('prefill-cancel')}
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => this.handleSave()}
            >
              {I18n.get('prefill-save')}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default PrefillModal
