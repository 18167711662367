import React from 'react'
import DefaultElement from './DefaultElement'
import HeadingElement from './HeadingElement'
import OutputElement from './OutputElement'
import SpanElement from './SpanElement'
import StrongElement from './StrongElement'
import EmphasisElement from './EmphasisElement'
import UnderlinedElement from './UnderlinedElement'
import ListItemElement from './ListItemElement'
import { NumberedListElement, BulletedListElement } from './ListElement'
import {
  TableElement,
  TableRowElement,
  TableCellElement,
  TableBodyElement
} from './TableElements'

const elementsRenderers: { [key: string]: React.FC } = {
  output: OutputElement,
  'heading-one': HeadingElement,
  'heading-two': HeadingElement,
  'heading-three': HeadingElement,
  'heading-four': HeadingElement,
  'heading-five': HeadingElement,
  'heading-six': HeadingElement,
  strong: StrongElement,
  emphasis: EmphasisElement,
  underlined: UnderlinedElement,
  span: SpanElement,
  paragraph: DefaultElement,
  'numbered-list': NumberedListElement,
  'bulleted-list': BulletedListElement,
  'list-item': ListItemElement,
  table: TableElement,
  'table-body': TableBodyElement,
  'table-row': TableRowElement,
  'table-cell': TableCellElement
}

const elementsList = Object.keys(elementsRenderers)
const renderElement = (props: any) => {
  if (elementsList.includes(props.element.type)) {
    const ElementComponent = elementsRenderers[props.element.type]
    return <ElementComponent {...props} />
  }

  return <DefaultElement {...props} />
}

export default renderElement
