import { action, computed, observable, runInAction } from 'mobx'
import { FormikActions } from 'formik'
import { IPersonalInfoValues } from '../pages/Dashboard/SettingsPage/PersonalInfoForm'
import api from '../utils/api'
import { handleFormErrors } from '../utils/form'
import { getLocale } from '../utils/localization'
import { catchAction } from './editor/catchActionsDecorator'

export interface IUserStore {
  fetching: boolean
  user: any
  fetchUser(): Promise<any>
  updatePersonalInfo(
    values: IPersonalInfoValues,
    actions: FormikActions<IPersonalInfoValues>
  ): Promise<any>
}

class UserStore implements IUserStore {
  @observable public fetching = false

  @observable private userDetail: IPersonalInfoValues = {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    telephone: '',
    role: '',
    organization: {
      name: ''
    }
  }

  @computed get user() {
    return this.userDetail
  }

  @catchAction
  @action
  public fetchUser = async () => {
    this.fetching = true
    try {
      const resp: any = await api.get('/user/profile')
      this.setUser(resp.data)
    } catch (err) {
      console.log('fetch error')
    } finally {
      runInAction(() => {
        this.fetching = false
      })
    }
  }

  @catchAction
  @action
  public updatePersonalInfo = async (
    values: IPersonalInfoValues,
    { setSubmitting, setFieldError }: FormikActions<IPersonalInfoValues>
  ): Promise<any> => {
    const { first_name, last_name, telephone, email } = values
    try {
      const data = {
        first_name,
        last_name,
        telephone,
        email,
        locale: getLocale()
      }
      const resp: any = await api.put('/user/profile', data)
      this.setUser(resp.data)
    } catch (err) {
      handleFormErrors(err, setFieldError)
    } finally {
      setSubmitting(false)
    }
  }

  @catchAction
  @action
  private setUser = (user: IPersonalInfoValues) => {
    this.userDetail = {
      ...user,
      id: user.id || '',
      email: user.email || '',
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      role: user.role || '',
      telephone: user.telephone || '',
      organization: user.organization || {
        name: ''
      }
    }
  }
}

const userStore = new UserStore()
export default userStore
