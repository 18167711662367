import { action, computed, observable, runInAction } from 'mobx'
import { FormikActions } from 'formik'
import { toast } from 'react-toastify'
import { I18n } from 'aws-amplify'
import { INewFileFormValues } from '../pages/Dashboard/MyFilesPage/NewFileForm'
import dialogStore from './dialogStore'
import { INewUserFormValues } from '../pages/Dashboard/UsersPage/NewUserForm'
import { ITemplateSettingsValues } from '../pages/Dashboard/TemplateDetailPage/TemplateDetailSettings'
import api from '../utils/api'
import { getLocale } from '../utils/localization'
import { handleFormErrors } from '../utils/form'
import { catchAction } from './editor/catchActionsDecorator'
import { IRecoveryValues } from '../components/RecoveryModal'

const dictionary = {
  fr: {
    failed_creation_user: "Echec de la création de l'utilisateur",
    success_creation_user: "L'utilisateur a été créé avec succès",
    success_recovery: "La création de l'order a été faite avec succès",
    failed_recovery: "La création de l'order a échoué",
    failed_update_user: "Echec de la mise à jour de l'utilisateur",
    success_update_user: "L'utilisateur a été mis à jour avec succès",
    success_created_category: 'La catégory a été créée avec succès',
    failed_created_category: 'La création de la catégorie a échoué',
    success_created_unit: "L'équipe a été créée avec succès",
    failed_created_unit: "La création de l'équipe a échoué",
    success_created_meta: 'Le nouveau template a été créée avec succès',
    failed_created_meta: 'La création du nouveau template a échoué',
    too_short_category_error:
      'Le nom de la catégorie doit contenir au moins 5 caractères',
    unit_success_deletion: "L'équipe a été supprimée avec succès",
    unit_failed_deletion: 'La suppression de l’équipe a échoué',
    category_success_deletion: 'La catégorie a été supprimée avec succès',
    category_faileds_deletion: 'La suppression de la catégorie a échoué'
  },
  en: {
    failed_creation_user: 'User creation failed',
    success_creation_user: 'User created successfully',
    success_recovery: 'Recovery successful',
    failed_recovery: 'Recovery failed',
    failed_update_user: 'User update failed',
    success_update_user: 'User updated successfully',
    success_created_category: 'Category created successfully',
    success_created_unit: 'Unit created successfully',
    failed_created_unit: 'Unit creation failed',
    success_created_metat: 'New template created successfully',
    failed_created_metat: 'New template creation failed',
    failed_created_category: 'Category creation failed',
    too_short_category_error: 'Category name needs at least 5 characters',
    unit_success_deletion: 'Unit deleted successfully',
    unit_failed_deletion: 'Unit deletion failed',
    category_success_deletion: 'Category deleted successfully',
    category_faileds_deletion: 'Category deletion failed'
  }
}

I18n.putVocabularies(dictionary)

const models = [
  {
    model: '1',
    state: 'Published',
    modified: '29/04/2019 15:47',
    published: '29/04/2019 15:47'
  },
  {
    model: '2',
    state: 'Draft',
    modified: '29/04/2019 15:47',
    published: '29/04/2019 15:47'
  },
  {
    model: '3',
    state: 'Published',
    modified: '29/04/2019 15:47',
    published: '29/04/2019 15:47'
  }
]

interface IData {
  data: any[]
  total: number
}

export interface IDataStore {
  myFilesData: IData
  allFilesData: IData
  allProductsData: IData
  downloadData: any
  modelsList: any[]
  homeCategoriesList: any[]
  usersData: IData
  categoriesData: IData
  organizationData: any
  fetching: any
  error: any
  newFile(values: INewFileFormValues, { setSubmitting }: any): void
  newUser(values: INewUserFormValues, { setSubmitting }: any): void
  updateUser(values: INewUserFormValues, { setSubmitting }: any): void
  recoveryContract(values: IRecoveryValues, uniqid: string): void
  saveProductSettings(
    values: ITemplateSettingsValues,
    { setSubmitting }: FormikActions<ITemplateSettingsValues>
  ): void
  myFile(id: string): any
  getProduct(id: string): any
  createCategory(name: string): void
  createUnit(name: string): void
  updateOption(name: string, type: string, id: number): void
  fetchCategories(): any
  fetchHomeCategories(params: any): any
  fetchAllFiles(params: any): any
  fetchMyFiles(params: any): any
  fetchOrganization(): any
  fetchProducts(params: any): any
  fetchDownloadInfo(params: any): any
  fetchUsers(params: any): any
  addUnit(unit: any): any
  addCategory(category: any): any
  deleteOption(i: number, type: string, el: any): any
  editOption(i: number, value: string, type: string, el: any): any
  toggleOrganizationModal(val: boolean, type?: string): any
  newCategories: any[]
  newUnits: any[]
  organizationModalIsOpen: boolean
  contractCDIModalIsOpen: boolean
  organizationModalType: string
}

class DataStore implements IDataStore {
  @observable private myFiles = { data: [], total: 0 }

  @observable private allFiles = { data: [], total: 0 }

  @observable private allProducts = { data: [], total: 0 }

  @observable private downloadInformation: any = {
    documents: [],
    instance: {}
  }

  @observable private users = { data: [], total: 0 }

  @observable private models = models

  @observable private fetchingStatus = false

  @observable private errorStatus = ''

  @observable private categories = { data: [], total: 0 }

  @observable private homeCategories: any[] = []

  @observable newCategories: any[] = []

  @observable newUnits: any[] = []

  @observable allUnits: any[] = []

  @observable organizationModalIsOpen: boolean = false

  @observable contractCDIModalIsOpen: boolean = false

  @observable organizationModalType: string = ''

  @observable private organization: any = {
    categories: [],
    organization: {},
    total_users: '',
    units: []
  }

  @computed get categoriesData(): IData {
    return this.categories
  }

  @computed get homeCategoriesList(): any[] {
    return this.homeCategories
  }

  @computed get fetching(): any {
    return this.fetchingStatus
  }

  @computed get error(): any {
    return this.errorStatus
  }

  @computed get myFilesData(): IData {
    return this.myFiles
  }

  @computed get allFilesData(): IData {
    return this.allFiles
  }

  @computed get allProductsData(): IData {
    return this.allProducts
  }

  @computed get downloadData(): any {
    return this.downloadInformation
  }

  @computed get usersData(): IData {
    return this.users
  }

  @computed get modelsList(): any[] {
    return this.models
  }

  @computed get organizationData(): any {
    return this.organization
  }

  public myFile() {
    return {}
  }

  public getProduct() {
    return {}
  }
  // public myFile(id: string) {
  //   return {};
  //   // return this.myFilesList.find(f => f.id === id);
  // }

  // public getProduct(id: string) {
  //   // return this.allProductsList.find(f => f.id === id);
  //   return {};
  // }

  @catchAction
  @action
  public newFile = (values: INewFileFormValues, { setSubmitting }: any) => {
    // const newItem = {
    //   id: '373H',
    //   name: values.name,
    //   type: 'CDI',
    //   model: '4',
    //   created: '29/04/2019 15:47',
    //   modified: '29/04/2019 15:47',
    //   email: 'prenom.nom@domain.fr',
    //   tags: values.tags.split(' ').map((item: string) => ({name: item})),
    //   status: 'Finished'
    // };
    // this.myFiles.push(newItem);
    // this.allFiles.push(newItem);
    console.log(values)
    setSubmitting(true)
    dialogStore.closeDialog()
  }

  @catchAction
  @action
  public toggleOrganizationModal = (value: boolean, type?: string) => {
    this.organizationModalIsOpen = value
    if (type) this.organizationModalType = type
  }

  @catchAction
  @action
  public toggleContractCDIModal = (value: boolean) => {
    this.contractCDIModalIsOpen = value
  }

  @catchAction
  @action
  public newUser = async (
    values: INewUserFormValues,
    { setSubmitting, setFieldError }: FormikActions<INewUserFormValues>
  ) => {
    try {
      const newUser = {
        email: values.email || '',
        first_name: values.first_name || '',
        last_name: values.last_name || '',
        role: values.role || '',
        telephone: '888888888',
        unit_id: +values.unit_id || 1,
        locale: getLocale()
      }
      await api.post('/user/profile', newUser)
      toast.success(I18n.get('success_creation_user'), {
        position: toast.POSITION.TOP_RIGHT
      })
      const params: any = new URLSearchParams()
      this.fetchUsers(params)
    } catch (err) {
      handleFormErrors(err, setFieldError)
      toast.error(I18n.get('failed_creation_user'), {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    setSubmitting(false)
    dialogStore.closeDialog()
  }

  @catchAction
  @action
  public recoveryContract = async (values: IRecoveryValues, uniqid: string) => {
    try {
      await api.post(`/wizard/order/${uniqid}/reprise`, values)
      toast.success(I18n.get('success_recovery'), {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      toast.error(I18n.get('failed_recovery'), {
        position: toast.POSITION.TOP_RIGHT
      })
    }
    dialogStore.closeDialog()
  }

  @catchAction
  @action
  public updateUser = async (
    values: INewUserFormValues,
    { setSubmitting }: any
  ) => {
    try {
      const { email, first_name, last_name, role, unit_id } = values
      const data = {
        email,
        first_name,
        last_name,
        role,
        unit_id
      }
      await api.put(`/user/profile/${values.id}`, data)
      setSubmitting(true)
      const params: any = new URLSearchParams()
      this.fetchUsers(params)
      toast.success(I18n.get('success_update_user'), {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
      toast.error(I18n.get('failed_update_user'), {
        position: toast.POSITION.TOP_RIGHT
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }

    dialogStore.closeDialog()
  }

  @catchAction
  @action
  addUnit = () => {
    this.newUnits.push({ name: '' })
  }

  @catchAction
  @action
  addCategory = () => {
    this.newCategories.push({ name: '' })
  }

  @catchAction
  @action
  deleteOption = async (i: number, type: string, el: any) => {
    this.organization[type].splice(i, 1)
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      if (type === 'units') {
        await api.delete(`/unit/${el.id}`)
        toast.success(I18n.get('unit_success_deletion'), {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        await api.delete(`/category/${el.id}`)
        toast.success(I18n.get('category_success_deletion'), {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (err) {
      if (type === 'units') {
        toast.error(I18n.get('unit_failed_deletion'), {
          position: toast.POSITION.TOP_LEFT
        })
      } else {
        toast.error(I18n.get('category_failed_deletion'), {
          position: toast.POSITION.TOP_LEFT
        })
      }
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  editOption = (i: number, value: string, type: string, el: any) => {
    // if el comes from server
    el.name = value
  }

  @catchAction
  @action
  getAllUnits = async () => {
    try {
      runInAction(() => {
        this.fetchingStatus = true
      })
      const resp = await api.post('/unit/search')
      runInAction(() => {
        this.allUnits = resp.data
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  public saveProductSettings() {}

  // @action public saveProductSettings = (
  //   values: ITemplateSettingsValues,
  //   { setSubmitting }: FormikActions<ITemplateSettingsValues>
  // ) => {
  //   // const oldProductIndex = this.allProducts.findIndex((product: any) => product.id === values.id);
  //   // const newItem = {
  //   //   ...this.allProducts[oldProductIndex],
  //   //   id: values.id || '',
  //   //   name: values.name || '',
  //   //   category: values.category || '',
  //   //   model: values.model || ''
  //   // };
  //   // this.allProducts.splice(oldProductIndex, 1, newItem);
  //   // console.log(newItem);
  //   // setSubmitting(true);
  // };

  @catchAction
  @action
  fetchCategories = async () => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data = {
        cache: false,
        select: ['id', 'name']
      }
      const categories = await api.post('/category/search', data)
      console.log(categories, 'CATACFAT')
      runInAction(() => {
        this.categories = categories.data
        this.organization.categories = categories.data.data
        console.log(this.categories)
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  createCategory = async (name: string) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data = {
        name,
        permalink: 'string',
        params: {},
        locale: 'fr_FR',
        cta_ab_testing: {}
      }
      const category = await api.post('/category', data)
      toast.success(I18n.get('success_created_category'), {
        position: toast.POSITION.TOP_RIGHT
      })
      runInAction(() => {
        this.organization.categories.push(category.data)
      })
    } catch (err) {
      const isMinErrorCode =
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.length > 0 &&
        err.response.data.errors[0].errorCode &&
        err.response.data.errors[0].errorCode === 'minLength.openapi.validation'

      toast.error(
        I18n.get(
          isMinErrorCode
            ? 'too_short_category_error'
            : 'failed_created_category'
        ),
        {
          position: toast.POSITION.TOP_LEFT
        }
      )
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  createUnit = async (name: string) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const unit = await api.post('/unit', { name })
      toast.success(I18n.get('success_created_unit'), {
        position: toast.POSITION.TOP_RIGHT
      })
      runInAction(() => {
        this.organization.units.push(unit.data)
      })
    } catch (err) {
      toast.error(I18n.get('failed_created_unit'), {
        position: toast.POSITION.TOP_LEFT
      })
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  updateOption = async (name: string, type: string, id: number) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      if (type === 'units') {
        await api.put(`/unit/${id}`, { name })
      } else {
        await api.put(`/category/${id}`, { name, params: {} })
      }
      // runInAction(() => {
      //   this.organization.units.push(unit.data);
      // });
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchUsers = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data: any = this.buildBaseParams(params)
      data.select = ['id', 'email', 'first_name', 'last_name', 'role']
      const s = params.get('search')
      if (s) {
        data.where = [
          { email: `%${s}%` },
          { first_name: `%${s}%` },
          { last_name: `%${s}%` }
        ]
        // No need to include ID in the search if NaN
        if (!Number.isNaN(parseInt(s, 10)))
          data.where.push({ id: parseInt(s, 10) })
      }
      const categories = await api.post('/user/search', data)
      runInAction(() => {
        this.users = categories.data
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchHomeCategories = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data: any = { cache: false }
      const s = params.get('search')
      if (s) {
        data.where = [{ category_name: `%${s}%` }, { meta_name: `%${s}%` }]
        if (!Number.isNaN(parseInt(s, 10)))
          data.where.push({ model_version: parseInt(s, 10) })
      }
      const categories = await api.post('/contract/model/active', data)
      runInAction(() => {
        this.homeCategories = this.normalizeHomeCategories(categories.data.data)
      })
    } catch (err) {
      // runInAction(() => {
      //   this.errorStatus = err.response.statusText;
      // });
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  createNewMeta = async (params: any) => {
    const { category_id, name, permalink } = params
    const payload = {
      category_id,
      name,
      permalink
    }
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      await api.post('/contract/meta', payload)
      toast.success(I18n.get('success_created_meta'), {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      toast.error(I18n.get('failed_created_meta'), {
        position: toast.POSITION.TOP_LEFT
      })
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchAllFiles = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data: any = this.buildBaseParams(params)
      const s = params.get('search')
      if (s) {
        data.where = [{ meta_name: `%${s}%` }, { user_email: `%${s}%` }]
        // No need to include ID in the search if NaN
        if (!Number.isNaN(parseInt(s, 10)))
          data.where.push({ id: parseInt(s, 10) })
      }
      const allFiles = await api.post('/contract/instance/active/all', data)
      runInAction(() => {
        this.allFiles = allFiles.data
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchMyFiles = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data: any = this.buildBaseParams(params)
      const s = params.get('search')
      if (s) {
        data.where = [{ filename: `%${s}%` }]
        if (!Number.isNaN(parseInt(s, 10)))
          data.where.push({ id: parseInt(s, 10) })
      }
      const myFiles = await api.post('/contract/instance/active/mine', data)
      runInAction(() => {
        this.myFiles = myFiles.data
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchOrganization = async () => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const organization = await api.get('/organization')
      runInAction(() => {
        this.organization = organization.data
      })
    } catch (err) {
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchProducts = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const data: any = this.buildBaseParams(params)
      const s = params.get('search')
      const filter = params.get('filter')
      data.order = { [filter ? 'meta_name' : 'name']: 'ASC' }
      if (s) {
        data.where = [
          { category_name: `%${s}%` },
          { id: `%${s}%` },
          { [filter ? 'meta_name' : 'name']: `%${s}%` }
        ]
        if (!Number.isNaN(parseInt(s, 10)))
          data.where.push({ id: parseInt(s, 10) })
      }
      const products = await api.post(
        filter ? `/contract/model/${filter}` : `/contract/meta/all`,
        data
      )
      runInAction(() => {
        this.allProducts = products.data
      })
    } catch (err) {
      console.error('err', err)

      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  fetchDownloadInfo = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      const { permalink, uniqid } = params
      const downloadInfo = await api.get(
        `/editor/download/${permalink}/${uniqid}`
      )
      runInAction(() => {
        this.downloadInformation = downloadInfo.data
        console.log('this.dowlonadinfo', this.downloadInformation)
      })
    } catch (err) {
      console.error('err', err)
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  updateDownloadInfo = async (params: any) => {
    this.fetchingStatus = true
    this.errorStatus = ''
    try {
      await api.put(
        `/contract/instance/${this.downloadInformation.instance.id}`,
        params
      )
      toast.success('Contract has been updated successfully !', {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      console.error('err', err)
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
      runInAction(() => {
        this.errorStatus = err.response.statusText
      })
    } finally {
      runInAction(() => {
        this.fetchingStatus = false
      })
    }
  }

  @catchAction
  @action
  updateInstanceName = async (val: string) => {
    this.downloadInformation.instance.filename = val
    // this.downloadInformation.instance.model.meta.name = val;
  }

  private normalizeHomeCategories = (categories: any[]): any[] => {
    const groups = categories.reduce(
      (res, { category_id, category_name, ...el }) => {
        if (!res[category_id])
          res[category_id] = { list: [], category_id, category_name }
        res[category_id].list.push(el)
        return res
      },
      {}
    )
    return Object.keys(groups).map((key) => groups[key])
  }

  private buildBaseParams = (params: any) => {
    const baseParams = {
      cache: false,
      skip: +params.get('offset') || 0,
      take: +params.get('limit') || 10,
      order: this.normalizeOrder(params.get('order'))
    }

    if (params.get('noLimit')) return this.removePagination(baseParams)

    return baseParams
  }

  private removePagination = (params: any) => {
    return {
      ...params,
      take: undefined
    }
  }

  private normalizeOrder = (order: string): any => {
    if (!order) return null
    const orderData = order.split(' ')
    const orderBy = ['asc', 'desc'].includes(orderData[1].toLowerCase())
      ? orderData[1].toUpperCase()
      : 'DESC'
    return {
      [orderData[0]]: orderBy
    }
  }
}

const dataStore = new DataStore()
export default dataStore
