import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import { ModelV3 } from '@legalplace/models-v3-types'
import api from '../../../../utils/api'
import { toastError } from '../../../../store/editor/catchActionsDecorator'
import createStore from '../../../../store/editor/createStore'

type PDFType = Exclude<ModelV3['documents']['']['pdf'], undefined>

type IProps = {
  close: () => void
}

const UploadPdf: React.FC<IProps> = ({ close }) => {
  const [file, setFile] = useState<File | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const handleOnChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    const { files } = e.currentTarget
    if (files && files.length > 0) {
      const [currentFile] = files

      // Setting state
      setFile(currentFile)
    }
  }

  const uploadFile = () => {
    if (file !== undefined) {
      setLoading(true)
      // Creating form data
      const data = new FormData()
      data.append('file', file, file.name)

      // Uploading file
      api
        .post<{ status: number; pdf: PDFType }>('/pdf/upload', data)
        .then((result) => {
          console.log(result)
          setLoading(false)
          if (result.status === 200) {
            const { id, filename, form, thumbnails } = result.data.pdf

            if (
              typeof id === 'string' &&
              typeof filename === 'string' &&
              typeof form === 'object' &&
              typeof thumbnails === 'object'
            ) {
              createStore.initCurrentDocumentPdf({
                id,
                filename,
                form,
                thumbnails
              })
            } else {
              console.error('Wrong pdf upload response')
              console.error(result.data)
              toastError('Unable to upload file')
              setLoading(false)
              close()
            }
          }
        })
        .catch((error) => {
          console.error(error)
          toastError('Unable to upload file')
          setLoading(false)
          close()
        })
    }
  }

  return (
    <div className="upload-pdf">
      {loading && <div className="loading">Chargement en cours...</div>}
      {!loading && (
        <>
          <div className="title">
            Veuillez sélectionner un fichier pdf à uploader
          </div>
          <div className="content">
            {file && <div className="current-file">{file.name}</div>}
            <div className="button primary upload-button">
              <input
                type="file"
                accept="application/pdf"
                title=""
                onChange={handleOnChange}
              />
              {file ? I18n.get('Choose another file') : I18n.get('Add file')}
            </div>
          </div>
          <div className="controls">
            <button type="button" onClick={close} className="secondary">
              Cancel
            </button>
            <button
              type="button"
              className="primary"
              disabled={file === undefined}
              onClick={uploadFile}
            >
              Uploader
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default UploadPdf
