import React, { Component, ReactNode } from 'react'
import { FadeLoader } from 'react-spinners'
import { inject, observer } from 'mobx-react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import { RouteComponentProps } from 'react-router'
import { I18n } from 'aws-amplify'
import Confirm from '../../components/Confirm/Confirm'
import DataSearch from '../../components/DataSearch/DataSearch'
import DataTable from '../../components/DataTable/DataTable'
import DataPagination from '../../components/DataPagination/DataPagination'
import { IDataStore } from '../../store/dataStore'
import { IDialogStore } from '../../store/dialogStore'
import { PLUS } from '../../components/Icons/icons'
import Icon from '../../components/Icons/Icon'
import { primary } from '../../constants/scheme'
import { go } from '../../utils/history'
import './style.scss'
import RecoveryModal from '../../components/RecoveryModal'

interface IAllFilesPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
  dialogStore?: IDialogStore | any
}

const columns = {
  en: {
    all_files_title: 'All contracts',
    all_new_file: 'New contract',
    all_search: 'Search by Contract Name and by Author ...',
    all_items_name: 'Files',
    all_delete_confirm: 'Are you sure?',
    all_id: 'ID',
    all_name: 'Contract Name',
    author: 'Author',
    all_type: 'Product Type',
    all_model: 'Model Number',
    all_created: 'Created',
    all_modified: 'Last Modified',
    all_user_email: 'User Email',
    all_tags: 'Tags',
    all_status: 'Status',
    all_action: 'Action',
    all_refresh: 'Refresh',
    'view-files': 'View',
    recovery: 'Recovery'
  },
  fr: {
    all_files_title: 'Contrats',
    all_new_file: 'Nouveau contrat',
    all_search: 'Chercher par nom du contrat',
    all_items_name: 'Contrats',
    all_delete_confirm: 'Are you sure?',
    all_id: 'ID',
    all_name: 'Nom du contrat',
    author: 'Auteur',
    all_type: 'Product Type',
    all_model: 'Model Number',
    all_created: 'Created',
    all_modified: 'Last Modified',
    all_user_email: 'User Email',
    all_tags: 'Tags',
    all_status: 'Status',
    all_action: 'Action',
    all_refresh: 'Actualiser',
    'view-files': 'Consulter',
    recovery: 'Reprise'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'dialogStore')
@observer
class AllFilesPage extends Component<IAllFilesPageProps> {
  private rows: any[] = [
    // {disableOrder: false, id: 'id', label: I18n.get('all_id')},
    { disableOrder: false, id: 'name', label: I18n.get('all_name') },
    { disableOrder: false, id: 'author', label: I18n.get('author') },
    // {disableOrder: false, id: 'type', label: I18n.get('all_type')},
    // {disableOrder: false, id: 'model_version', label: I18n.get('all_model')},
    // {disableOrder: false, id: 'created_at', label: I18n.get('all_created')},
    // {disableOrder: false, id: 'modified', label: I18n.get('all_modified')},
    // {disableOrder: false, id: 'email', label: I18n.get('all_user_email')},
    // {disableOrder: false, id: 'tags', label: I18n.get('all_tags')},
    // {disableOrder: false, id: 'status', label: I18n.get('all_status')},
    { disableOrder: true, id: 'action', label: I18n.get('all_action') }
  ]

  UNSAFE_componentWillMount() {
    this.onChangeParams()
  }

  public newFile = () => {
    go(`/dashboard`)
  }

  public handleDelete = (item: any) => () => {
    const { openDialog, closeDialog } = this.props.dialogStore
    openDialog(
      <Confirm
        question={I18n.get('all_delete_confirm')}
        handleCancel={closeDialog}
        handleDelete={this.deleteItem(item)}
      />
    )
  }

  public deleteItem = (item: any) => () => {
    this.props.dialogStore.closeDialog()
    console.log(item)
  }

  public onChangeParams = () => {
    const { location, dataStore } = this.props
    const params: any = new URLSearchParams(location.search)
    dataStore.fetchAllFiles(params)
  }

  public openRecoveryModal = (uniqid: string) => {
    const { openDialog, closeDialog } = this.props.dialogStore
    openDialog(<RecoveryModal closeDialog={closeDialog} uniqid={uniqid} />)
  }

  public recoveryOrderRequest = () => {}

  public render(): ReactNode {
    const { allFilesData, error, fetching } = this.props.dataStore
    const { data, total } = allFilesData
    return (
      <div className="content">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('all_files_title')}</h3>
            <button type="button" className="primary" onClick={this.newFile}>
              <Icon iconClass="iconPlus" icon={PLUS} color="#fff" />
              {I18n.get('all_new_file')}
            </button>
          </div>
          <div className="search-holder">
            <DataSearch
              onChangeParams={this.onChangeParams}
              placeholder={I18n.get('all_search')}
            />
            <button
              type="button"
              className="btn secondary"
              onClick={this.onChangeParams}
            >
              {I18n.get('all_refresh')}
            </button>
          </div>
        </div>

        <FadeLoader color={primary} loading={fetching} />
        {error && <div>Error - {error}</div>}

        <DataTable rows={this.rows} onChangeParams={this.onChangeParams}>
          {data &&
            data.map((row: any, i: number) => (
              <TableRow key={i}>
                <TableCell align="right">
                  {row.filename || row.meta_name}
                </TableCell>
                <TableCell align="right">{row.user_email}</TableCell>
                <TableCell align="right">
                  <a
                    className="btn-wrapper ff-nowrap"
                    href={`/download/${row.meta_permalink}/${row.uniqid}`}
                  >
                    <button type="button" className="btn secondary">
                      {I18n.get('view-files')}
                    </button>
                  </a>
                  {process.env.REACT_APP_IS_B2C && (
                    <button
                      type="button"
                      className="btn secondary"
                      onClick={() => this.openRecoveryModal(row.uniqid)}
                    >
                      {I18n.get('recovery')}
                    </button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </DataTable>

        <div className="padding-container pagination-padding-container">
          <DataPagination
            onChangeParams={this.onChangeParams}
            total={total}
            itemsName={I18n.get('all_items_name')}
          />
        </div>
      </div>
    )
  }
}

export default AllFilesPage
