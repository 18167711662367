import React from 'react'
import { VariableV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    Type: 'Type'
  },
  en: {
    Type: 'Type'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option type
 */
export const Type: React.FC<{ state: VariableEditorStateType }> = ({
  state
}) => {
  const { varTypes } = createStore
  const type = state.variable.type === 'list' ? 'list' : 'text'

  return (
    <SelectField
      isSearchable={false}
      fieldClass="type-element-select"
      setFieldValue={(name, value: VariableV3['type']) => {
        state.updateType(value)
      }}
      meta
      value={type}
      label={I18n.get('Type')}
      name="type"
      options={varTypes}
    />
  )
}
