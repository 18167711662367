import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { ErrorMessage } from 'formik'
import classNames from 'classnames'
import { WithContext as ReactTags } from 'react-tag-input'
import { KeyCodes } from '../../constants/general'
import './style.scss'

export interface ITag {
  id: string
  text: string
}

interface ITagsFieldProps {
  setFieldValue(name: string, value: ITag[]): void
  name: string
  tags: ITag[]
  placeholder?: string
  label?: string
  fieldClass?: string
}

const delimiters = [KeyCodes.comma, KeyCodes.tab, KeyCodes.enter]

@observer
class TagsField extends Component<ITagsFieldProps> {
  public handleAddition = (tag: ITag) => {
    const { name, tags, setFieldValue } = this.props
    setFieldValue(name, [...tags, tag])
  }

  public handleDelete = (i: number) => {
    const { name, tags, setFieldValue } = this.props
    tags.splice(i, 1)
    setFieldValue(name, tags)
  }

  public render(): ReactNode {
    const {
      name,
      tags,
      label = '',
      placeholder = '',
      fieldClass = ''
    } = this.props
    const fieldClassConst = classNames({
      'tags-field': true,
      [fieldClass]: true
    })
    return (
      <div className={fieldClassConst}>
        <label className="label-wrapper">
          <h5 className="text">{label}</h5>
          <ReactTags
            tags={tags}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            placeholder={placeholder}
            delimiters={delimiters}
          />
        </label>
        <h5 className="text-error">
          <ErrorMessage name={name} />
        </h5>
      </div>
    )
  }
}

export default TagsField
