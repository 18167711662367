import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import TagField from '../../../../../../../components/Editor/TagField'

const dictionary = {
  fr: {
    Tags: 'Tags'
  },
  en: {
    Tags: 'Tags'
  }
}
I18n.putVocabularies(dictionary)
/**
 * Edits option warning content
 */
export const Tags: React.FC<{ state: OptionEditorStateType }> = ({ state }) => {
  const { tags } = state.option.meta

  return (
    <TagField
      value={tags || []}
      meta
      name="tags"
      setFieldValue={(name, _tags) => state.updateTags(_tags)}
      label={I18n.get('Tags')}
    />
  )
}
