import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ReactEditor, useEditor } from '@legalplace/slate-react'
import { I18n } from 'aws-amplify'
import PopoverList from './List'
import createStore from '../../../../../store/editor/createStore'
import './popover.scss'
import { handleInsertVariable } from './handleInsertVariable'
import ParentTree from './components/ParentTree'

type IProps = {
  editor: ReactEditor
}
const columns = {
  en: {
    leave_msg: 'Use ESC to leave'
  },
  fr: {
    leave_msg: 'Appuyez sur ESC pour fermer'
  }
}

I18n.putVocabularies(columns)

const isDescendant = (parent: HTMLElement, element: HTMLElement) => {
  let node = element.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

export const VariablePicker = (props: {
  insert: (variableId: number | false) => void
}) => {
  const [filter, setFilter] = useState<string>('')
  const [popoverToLeft, setPopoverToLeft] = useState<boolean>(false)
  const [parentTree, setParentTree] = useState<
    { type: string; id: number; label: string }[] | false
  >(false)
  const filterRef = useRef<HTMLInputElement>(null)
  const pickerRef = useRef<HTMLDivElement>(null)

  const model = createStore.document

  const list = Object.keys(model.variables)
    .filter((id) => {
      const variable = model.variables[id]
      if (filter.trim().length === 0) return true

      const keywords = filter.split(' ').filter((v) => v.trim().length > 0)

      let hasKeyword = false
      let keyWordsCount = 0

      keywords.forEach((keyword) => {
        if (id.match(new RegExp(`^${keyword}`))) hasKeyword = true
        else if (variable.label.match(new RegExp(`${keyword}`, 'i')))
          keyWordsCount += 1
      })

      return keyWordsCount === keywords.length || hasKeyword
    })
    .map((id) => {
      const variable = model.variables[id]
      return {
        value: id,
        render: (
          <>
            <span className="id">{id}</span>
            <span className="label">{variable.label}</span>
          </>
        )
      }
    })

  const handleFilterChange = (e: React.FormEvent<HTMLInputElement>) => {
    setFilter(e.currentTarget.value)
  }

  useLayoutEffect(() => {
    if (filterRef.current !== null) {
      setTimeout(() => {
        if (filterRef.current !== null) filterRef.current.focus()
      }, 0)
    }
  }, [filterRef])

  useEffect(() => {
    if (pickerRef.current !== null) {
      const screenWidth = window.innerWidth
      const { left, width } = pickerRef.current.getBoundingClientRect()
      const popoverLeft = left + width
      const popoverWidth = 300
      const margin = 30

      if (
        popoverLeft + popoverWidth + margin > screenWidth &&
        popoverToLeft === false
      )
        setPopoverToLeft(true)
      else if (
        popoverToLeft === true &&
        popoverLeft + popoverWidth + margin <= screenWidth
      )
        setPopoverToLeft(false)
    }
  }, [setPopoverToLeft, popoverToLeft, pickerRef])

  const handleOnChange = (value: string | number | false) => {
    props.insert(typeof value === 'string' ? parseInt(value, 10) : value)
  }

  return (
    <div className="variable-picker" ref={pickerRef}>
      <div className="searchbar">
        <input type="text" ref={filterRef} onChange={handleFilterChange} />
      </div>
      <PopoverList
        list={list}
        onChange={handleOnChange}
        setParentTree={setParentTree}
      />
      <div className="footer">{I18n.get('leave_msg')}</div>
      {parentTree && (
        <div
          className={`variable-parent-tree${popoverToLeft ? ' pull-left' : ''}`}
        >
          {parentTree.length > 0 && (
            <ParentTree currentIndex={0} tree={parentTree} />
          )}
        </div>
      )}
    </div>
  )
}

class PopoverWithEditor extends React.Component<IProps, {}> {
  el = document.createElement('span')

  hook: HTMLElement | null

  editor: ReactEditor

  constructor(props: IProps) {
    super(props)
    this.el.style.position = 'relative'
    this.hook = document.querySelector(
      `span[data-variable-hook-id="variable-hook"]`
    )
    this.editor = props.editor
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.keydownEventListener)
    document.body.addEventListener('click', this.clickEventListener)
    if (this.hook) this.hook.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.keydownEventListener)
    document.body.removeEventListener('click', this.clickEventListener)
    if (this.hook) this.hook.removeChild(this.el)
  }

  clickEventListener = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target === null || this.hook === null) {
      this.close()
    } else if (target === this.hook || isDescendant(this.hook, target)) {
      event.preventDefault()
    } else {
      this.close()
    }
  }

  keydownEventListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      this.close()
    }
  }

  close = () => {
    this.insert(false)
  }

  insert = (variableId: number | false) => {
    handleInsertVariable(this.editor)(variableId)
  }

  render() {
    return ReactDOM.createPortal(
      <div className="editor-popover-x-axis">
        <div className="editor-popover-y-axis">
          <span
            className="editor-popover"
            contentEditable={false}
            suppressContentEditableWarning
          >
            <VariablePicker insert={this.insert} />
          </span>
        </div>
      </div>,
      this.el
    )
  }
}

const Popover: React.FC = () => {
  const editor = useEditor()
  return <PopoverWithEditor editor={editor} />
}

export default Popover
