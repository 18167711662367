import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { ErrorMessage, Field } from 'formik'
import classNames from 'classnames'

interface IFormFieldProps {
  handleChange?(e: any): void
  handleBlur?(e: any): void
  handleChangeMeta?(e: any, name: string): void
  label?: string
  type?: string
  name?: string
  value?: any
  placeholder?: string
  fieldClass?: string
  cypressId?: string
  readOnly?: boolean
}

@observer
class FormField extends Component<IFormFieldProps> {
  public render(): ReactNode {
    const {
      label = '',
      handleChange,
      handleBlur,
      type = 'text',
      name = '',
      placeholder = '',
      fieldClass = '',
      readOnly = false,
      cypressId
    } = this.props

    const fieldClassConst = classNames({
      'form-field': true,
      [fieldClass]: true
    })
    return (
      <div className={fieldClassConst}>
        <label className="label-wrapper">
          <h5 className="text">{label}</h5>
          <Field
            className="form-field-input"
            readOnly={readOnly}
            type={type}
            name={name}
            data-cypressId={cypressId}
            // value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </label>
        <h5 className="text-error">
          <ErrorMessage name={name} />
        </h5>
      </div>
    )
  }
}

export default FormField
