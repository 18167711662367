import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { cloneDeep } from 'lodash'
import { action, observable, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { ModelV3 } from '@legalplace/models-v3-types'
import { ICreateStore } from '../../store/editor/createStore'
import CheckboxField from '../Editor/CheckboxField'
import Icon from '../Icons/Icon'
import { CONDITIONTREEICON, DELICON } from '../Icons/icons'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'
import promptModal from '../PromptPortal/PromptPortal'

const dictionary = {
  fr: {
    'param-title': 'Paramètres du document',
    'doc title': 'Titre du document',
    'word&pdf': 'Word & PDF',
    word: 'Word uniquement',
    pdf: 'PDF uniquement',
    display_bo: 'Visible par le client sur le BO',
    hide_download_page: 'Non affiché sur la page téléchargement',
    modify: 'Modifier',
    'cancel-params': 'Annuler',
    'save-params': 'Enregistrer',
    'Are you sure you want to delete document "%1"':
      'Êtes-vous sûr de vouloir supprimer le document "%1"',
    delete: 'Supprimer',
    'Please type "Delete" in the box bellow to confirm':
      'Merci d\'écrire "Supprimer" sur le champ ci-dessous pour valider la suppression.',
    documentOrder: 'Emplacement'
  },
  en: {
    documentOrder: 'Document Order',
    'param-title': 'Document Parameters',
    'doc title': 'Document’s title',
    'word&pdf': 'Word & PDF',
    word: 'Word only',
    pdf: 'PDF only',
    display_bo: 'Visible on the BO',
    hide_download_page: 'Not shown on the download page',
    modify: 'Add/Modify',
    'cancel-params': 'Cancel',
    'save-params': 'Save'
  }
}

I18n.putVocabularies(dictionary)

interface IExtractionModalProps {
  createStore: ICreateStore
  open: boolean
  toggleConditionsDrawer(open: boolean, type: string, i?: number): void
}

@inject('createStore')
@observer
class DocumentParamsModal extends Component<IExtractionModalProps> {
  @observable doc = cloneDeep(
    this.props.createStore.document.documents[
      this.props.createStore.currentDocument
    ]
  )

  @observable title = this.props.createStore.currentDocument

  constructor(props: any) {
    super(props)
    // @ts-ignore
    this.reaction1 = reaction(
      () => this.props.createStore.currentDocument,
      () => {
        this.doc = cloneDeep(
          this.props.createStore.document.documents[
            this.props.createStore.currentDocument
          ]
        )
        this.title = this.doc.name
      }
    )

    // Reacting to document initiatlization
    reaction(
      () => this.props.createStore.document,
      () => {
        this.doc = cloneDeep(
          this.props.createStore.document.documents[
            this.props.createStore.currentDocument
          ]
        )
        this.title = this.doc.name
      }
    )
  }

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props.createStore
    toggleModal('documentParamsIsOpen', open)
  }

  handleAddDocument = () => {
    const { updateDocument, currentDocument, document } = this.props.createStore

    const conditions = document.documents[currentDocument].params
      ? document.documents[currentDocument].params?.conditions
      : {}

    const params: ModelV3['documents']['document']['params'] = {
      ...this.doc.params,
      conditions
    }

    updateDocument(currentDocument, this.title, params)
    this.toggleModal(false)
  }

  handleDelDocument = () => {
    const { deleteDocument } = this.props.createStore

    promptModal(
      () => (
        <div>
          {I18n.get('Please type "Delete" in the box bellow to confirm')}
        </div>
      ),
      {
        title: I18n.get(
          'Are you sure you want to delete document "%1"'
        ).replace('%1', this.title),
        expectedValue: I18n.get('delete')
      }
    ).then((value) => {
      // Running healthcheck again to check orphelins
      if (value === I18n.get('delete')) {
        deleteDocument()
      }
    })
    this.toggleModal(false)
  }

  @catchAction
  @action
  updateParams = (
    e: any,
    field:
      | keyof this
      | 'visibility.displayBo'
      | 'visibility.hideDownloadPage'
      | 'word&pdf'
      | 'word'
      | 'pdf'
  ) => {
    if (field === 'word&pdf') {
      this.doc.params = {
        ...(this.doc.params || {}),
        formats: {
          pdf: true,
          docx: true
        }
      }
    } else if (field === 'word') {
      this.doc.params = {
        ...(this.doc.params || {}),
        formats: {
          pdf: false,
          docx: true
        }
      }
    } else if (field === 'pdf') {
      this.doc.params = {
        ...(this.doc.params || {}),
        formats: {
          pdf: true,
          docx: false
        }
      }
    } else if (field === 'visibility.displayBo') {
      this.doc.params = {
        ...(this.doc.params || {}),
        visibility: {
          ...(this.doc.params?.visibility || {}),
          displayBo: e
        }
      }
    } else if (field === 'visibility.hideDownloadPage') {
      this.doc.params = {
        ...(this.doc.params || {}),
        visibility: {
          ...(this.doc.params?.visibility || {}),
          hideDownloadPage: e
        }
      }
    } else if (field === 'title') {
      this.title = e.target.value
    }
  }

  handleCondition = () => {
    this.toggleModal(false)
    this.props.createStore.toggleDocParamsWasShown(true)
    this.props.toggleConditionsDrawer(true, 'document')
  }

  render() {
    const {
      currentDocument,
      conditionsCollector,
      document,
      moveDocumentUpDown
    } = this.props.createStore
    const wordPdf =
      this.doc.params?.formats?.docx === true &&
      this.doc.params?.formats?.pdf === true
    const wordOnly = !wordPdf && this.doc.params?.formats?.docx === true
    const pdfOnly = !wordPdf && this.doc.params?.formats?.pdf === true

    const conditions = document.documents[currentDocument].params
      ? document.documents[currentDocument].params?.conditions
      : []
    let collectedConditions: any = []
    if (conditions) {
      collectedConditions = conditionsCollector(
        Object.keys(conditions),
        conditions
      )
    }

    const slugs = Object.keys(document.documents)
    const documentNumber = slugs.indexOf(currentDocument) + 1
    const totalDocuments = slugs.length

    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="params-modal"
      >
        <h1>{I18n.get('param-title')}</h1>
        <div className="modal-holder">
          <div className="title-input">
            <span className="label">{I18n.get('doc title')}</span>
            <input
              type="text"
              value={this.title}
              onChange={(e) => this.updateParams(e, 'title')}
            />
          </div>
          <div className="format-radio">
            <span>Format:</span>
            <form
              className="popup-radio-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="check-wrap">
                <div className="radio-holder">
                  <input
                    checked={wordPdf}
                    onChange={(e) => this.updateParams(e, 'word&pdf')}
                    name="format"
                    type="radio"
                    id="word&pdf"
                  />
                  <span className="radio-label" />
                </div>
                <label className="label" htmlFor="word&pdf">
                  {I18n.get('word&pdf')}
                </label>
              </div>
              <div className="check-wrap">
                <div className="radio-holder">
                  <input
                    checked={wordOnly}
                    onChange={(e) => this.updateParams(e, 'word')}
                    name="format"
                    type="radio"
                    id="word"
                  />
                  <span className="radio-label" />
                </div>
                <label className="label" htmlFor="word">
                  {I18n.get('word')}
                </label>
              </div>
              <div className="check-wrap">
                <div className="radio-holder">
                  <input
                    checked={pdfOnly}
                    onChange={(e) => this.updateParams(e, 'pdf')}
                    name="format"
                    type="radio"
                    id="pdfdoc"
                  />
                  <span className="radio-label" />
                </div>
                <label className="label" htmlFor="pdfdoc">
                  {I18n.get('pdf')}
                </label>
              </div>
            </form>
          </div>
          <div className="checkbox-wrapper">
            <CheckboxField
              name="visibility.displayBo"
              setFieldValue={(name: string, value: boolean) => {
                this.updateParams(value, 'visibility.displayBo')
              }}
              label={I18n.get('display_bo')}
              checked={this.doc.params?.visibility?.displayBo && true}
            />
          </div>
          <div className="checkbox-wrapper">
            <CheckboxField
              name="visibility.hideDownloadPage"
              setFieldValue={(name: string, value: boolean) => {
                this.updateParams(value, 'visibility.hideDownloadPage')
              }}
              label={I18n.get('hide_download_page')}
              checked={this.doc.params?.visibility?.hideDownloadPage && true}
            />
          </div>
          <div className="conditions-block">
            <div>
              <Icon
                iconClass="tree-ico"
                icon={CONDITIONTREEICON}
                color="#e32778"
              />
              conditions:
              <span className="conditions-value">
                {collectedConditions.length}
              </span>
            </div>
            <button
              type="button"
              onClick={this.handleCondition}
              className=" secondary"
            >
              {I18n.get('modify')}
            </button>
          </div>
          <div className="document-order-block">
            <div className="label">
              <h5>{I18n.get('documentOrder')}</h5>
            </div>
            <div className="order">
              <button
                type="button"
                className="secondary"
                disabled={documentNumber === 1}
                onClick={() => moveDocumentUpDown(currentDocument, 'up')}
              >
                ⇧
              </button>
              <span className="current-position">
                {documentNumber} / {totalDocuments}
              </span>
              <button
                type="button"
                className="secondary"
                disabled={documentNumber === totalDocuments}
                onClick={() => moveDocumentUpDown(currentDocument, 'down')}
              >
                ⇩
              </button>
            </div>
          </div>
          <div
            style={{
              justifyContent:
                currentDocument === 'main' ? 'flex-end' : 'space-between'
            }}
            className="params-button-holder"
          >
            {currentDocument !== 'main' && (
              <button
                type="button"
                className="btn-icon delete-node-button delete-option"
                onClick={this.handleDelDocument}
              >
                <Icon iconClass="del-ico" icon={DELICON} color="#adb5bd" />
              </button>
            )}
            <div className="buttons-holder">
              <button
                type="button"
                className="secondary"
                onClick={() => this.toggleModal(false)}
              >
                {I18n.get('cancel-params')}
              </button>
              <button
                type="button"
                className="primary"
                onClick={this.handleAddDocument}
              >
                {I18n.get('save-params')}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default DocumentParamsModal
