import React from 'react'
import { I18n } from 'aws-amplify'
import { VariableEditorStateType } from '../../editors/VariableEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    'Error message': 'Message d‘erreur'
  },
  en: {
    'Error message': 'Error message'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits variable validators message
 */
export const ValidatorMessage: React.FC<{
  state: VariableEditorStateType
}> = ({ state }) => {
  const { variable } = state
  const { message } = variable.validator || { message: '' }

  return (
    <Input
      value={message || ''}
      meta
      name="label"
      setFieldValue={(name: string, value: string) =>
        state.updateValidatorMessage(value)
      }
      multipline
      label={I18n.get('Error message')}
    />
  )
}
