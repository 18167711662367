import React, { Component } from 'react'

import SectionsBoard from './SectionsBoard/SectionBoard'
import SectionEditor from './SectionEditor/SectionEditor'
import Preview from './Preview/Preview'

class Editor extends Component {
  async componentDidMount() {
    // try {
    //     const apiData = await API.graphql(graphqlOperation(listCategorys));
    //     console.warn('apiData',apiData);
    //
    // } catch (err) {
    //     console.log('error: ', err);
    // }
  }

  render() {
    return (
      <div>
        Editor
        <SectionsBoard />
        <SectionEditor />
        <Preview />
      </div>
    )
  }
}

export default Editor
