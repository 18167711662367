import React, { Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

class CheckboxField extends Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      checked: props.checked
    }
  }

  handleChange = () => (event: any) => {
    const { name, setFieldValue, meta } = this.props
    this.setState({ checked: event.target.checked })
    setFieldValue(name, event.target.checked, meta)
  }

  render() {
    const { checked } = this.state
    const { cypressId } = this.props
    return (
      <div className="checkbox-wrapper" data-cypressId={cypressId}>
        <FormControlLabel
          control={
            <Checkbox
              classes={{ root: 'checkbox', checked: 'checked' }}
              checked={checked}
              onChange={this.handleChange()}
              value={this.props.value}
              disabled={this.props.disabled}
              color="primary"
            />
          }
          label={<div className="checkbox-label">{this.props.label}</div>}
        />
        {/* <div>{this.props.label}</div> */}
      </div>
    )
  }
}

export default CheckboxField
