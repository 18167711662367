import React, { Component, ReactNode } from 'react'

import './style.scss'

interface IInputProps {
  form?: any
  label: string
  type?: string
  name?: string
  textarea?: any
  className?: string
  title?: string
  field?: any
  value?: string

  onBlur?(): void
}

class Input extends Component<IInputProps, { value: IInputProps['value'] }> {
  constructor(props: IInputProps) {
    super(props)
    this.state = {
      value: this.props.value
    }
  }

  onHandleChange = (e: any) => {
    const { value } = e.target
    this.setState({
      value
    })
    // this.props.handleChange(value);
  }

  public render(): ReactNode {
    const {
      label,
      type,
      name,
      textarea,
      className,
      title,
      onBlur,
      field
    } = this.props
    const touched = (this.props.form && this.props.form.touched) || null
    const errors = (this.props.form && this.props.form.errors) || null
    const fieldName = (field && field.name) || null

    const { value } = this.state

    return (
      <div className={`input ${className}`}>
        <label>{label}</label>
        {textarea ? (
          <textarea name={name} {...field} onBlur={onBlur} value={value} />
        ) : (
          <input
            name={name}
            type={type}
            {...field}
            onBlur={onBlur}
            value={value}
          />
        )}
        {touched && touched[fieldName] && errors && errors[fieldName] && (
          <div className="error">{errors && errors[fieldName]}</div>
        )}
        {title && <span>{title}</span>}
      </div>
    )
  }
}

export default Input
