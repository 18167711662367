/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react'
import { VariableV3 } from '@legalplace/models-v3-types'
import VariableEditor, { VariableEditorStateType } from './VariableEditor'

const VariableContext = createContext<VariableEditorStateType>({
  id: 0,
  variable: {
    id: 0,
    type: 'text',
    label: '',
    step: '*'
  },

  // TODO: Remove this legacy toggles asap
  toggleConditionsDrawer: (open: boolean, type: string, i?: number) => {},
  toggleDrawer: (value: boolean) => {},

  ...VariableEditor.prototype
})

export default VariableContext
