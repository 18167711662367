import React from 'react'
import { I18n } from 'aws-amplify'
import { ReactComponent as ChevronDown } from './chevron-down.svg'
import './select.scss'

const columns = {
  en: {
    filter: 'Filter'
  },
  fr: {
    filter: 'Filtrer'
  }
}

I18n.putVocabularies(columns)

type Option =
  | string
  | JSX.Element
  | { label: string | JSX.Element; disabled?: boolean }

type SelectProps = {
  options: { [key: string]: Option }
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  enableFilter?: boolean
}

type SelectState = {
  open: boolean
  filter: string
  filterResults: number[]
  filterCurrentIndex: number
  filterTotal: number
}

const isDescendant = (parent: HTMLElement, element: HTMLElement) => {
  let node = element.parentNode
  if (parent === element) return true
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

class Select extends React.Component<SelectProps, SelectState> {
  selectRef = React.createRef<HTMLDivElement>()

  filterRef = React.createRef<HTMLInputElement>()

  constructor(props: SelectProps) {
    super(props)
    this.state = {
      open: false,
      filter: '',
      filterResults: [],
      filterCurrentIndex: 0,
      filterTotal: 0
    }
  }

  open = () => {
    if (this.state.open === false) {
      this.setState({ open: true })
      document.body.addEventListener('click', this.handleClick)
    }
  }

  close = () => {
    if (this.state.open === true) {
      this.setState({
        open: false,
        filter: '',
        filterResults: [],
        filterTotal: 0,
        filterCurrentIndex: 0
      })
      document.body.removeEventListener('click', this.handleClick)
    }
  }

  handleClick = (event: MouseEvent | React.MouseEvent) => {
    if (event.target !== this.filterRef.current) event.preventDefault()
    else if (this.filterRef.current) this.filterRef.current.focus()

    if (this.props.disabled === true) return

    const target = event.target as HTMLElement
    if (
      (target === null ||
        this.selectRef.current === null ||
        !isDescendant(this.selectRef.current, target)) &&
      this.state.open === true
    ) {
      this.close()
    } else {
      this.open()
    }
  }

  handleMouseDown = (event: MouseEvent | React.MouseEvent) => {
    event.preventDefault()
  }

  handleOptionClick = (
    event: MouseEvent | React.MouseEvent,
    value: string,
    disabled: boolean = false
  ) => {
    event.preventDefault()
    if (value !== this.props.value && disabled === false)
      this.props.onChange(value)
    this.close()
  }

  isOptionObject = (
    option: Option
  ): option is {
    label: string | JSX.Element
    disabled?: boolean
    query?: (string | number) | (string | number)[]
  } => {
    if (
      typeof option === 'object' &&
      Object.prototype.hasOwnProperty.call(option, 'label')
    )
      return true
    return false
  }

  findOption = (): void => {
    const optionsValues = Object.keys(this.props.options)

    const keywords = this.state.filter
      .split(' ')
      .filter((v) => v.trim().length > 0)

    // Filter results
    const filterResults = []

    for (let index = 0; index < optionsValues.length; index += 1) {
      if (this.state.filter.trim().length === 0) break
      const value = optionsValues[index]

      const option = this.props.options[value]
      const query = [
        this.isOptionObject(option) ? option.query : undefined,
        typeof option === 'string' ? option : undefined,
        this.isOptionObject(option) && typeof option.label === 'string'
          ? option.label
          : undefined,
        value
      ]
        .filter((v): v is number | string => v !== undefined)
        .join(' ')

      let hasKeyword = false
      let keyWordsCount = 0

      keywords.forEach((keyword) => {
        if (value.match(new RegExp(`${keyword}`))) hasKeyword = true
        else if (
          query.match(new RegExp(`${keyword}`, 'i')) &&
          this.state.filter.trim().length >= 1
        )
          keyWordsCount += 1
      })

      if (keyWordsCount === keywords.length || hasKeyword) {
        filterResults.push(index)
      }
    }

    // Setting filterTotal & filterCurrentIndex & filterResults in state
    this.setState({
      filterResults,
      filterTotal: filterResults.length,
      filterCurrentIndex: 0
    })

    // Scrolling to first index
    if (filterResults.length > 0) {
      const firstIndex = filterResults[0]
      const valueElement = document.querySelector(
        `li[query-index="${firstIndex}"]`
      )
      if (valueElement) {
        valueElement.scrollIntoView()
        valueElement.classList.add('highlight')
        setTimeout(() => valueElement.classList.remove('highlight'), 3000)
      }
    }
  }

  goPreviousFilter = () => {
    const { filterResults, filterCurrentIndex } = this.state

    if (filterResults.length > 0 && filterCurrentIndex > 0) {
      this.setState(
        {
          filterCurrentIndex: filterCurrentIndex - 1
        },
        () => {
          const firstIndex = filterResults[this.state.filterCurrentIndex]
          const valueElement = document.querySelector(
            `li[query-index="${firstIndex}"]`
          )
          if (valueElement) {
            valueElement.scrollIntoView()
            valueElement.classList.add('highlight')
            setTimeout(() => valueElement.classList.remove('highlight'), 3000)
          }
        }
      )
    }
  }

  goNextFilter = () => {
    const { filterResults, filterCurrentIndex } = this.state

    if (
      filterResults.length > 0 &&
      filterCurrentIndex < filterResults.length - 1
    ) {
      this.setState(
        {
          filterCurrentIndex: filterCurrentIndex + 1
        },
        () => {
          const firstIndex = filterResults[this.state.filterCurrentIndex]
          const valueElement = document.querySelector(
            `li[query-index="${firstIndex}"]`
          )
          if (valueElement) {
            valueElement.scrollIntoView()
            valueElement.classList.add('highlight')
            setTimeout(() => valueElement.classList.remove('highlight'), 3000)
          }
        }
      )
    }
  }

  watchFilter: React.EventHandler<React.KeyboardEvent<HTMLInputElement>> = (
    event
  ) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault()
      event.stopPropagation()
      this.goPreviousFilter()
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      event.stopPropagation()
      this.goNextFilter()
    }
  }

  renderOptions = (): JSX.Element => {
    const optionsValues = Object.keys(this.props.options)
    return (
      <>
        {optionsValues.map((value, index) => {
          const option = this.props.options[value]
          const label = this.isOptionObject(option) ? option.label : option
          const disabled =
            this.isOptionObject(option) && option.disabled === true
          const className = [
            value === this.props.value ? 'active' : undefined,
            disabled && 'disabled'
          ]
            .filter((v) => typeof v === 'string')
            .join(' ')
          return (
            <li
              key={`select-option-${value}-${index}`}
              query-index={index}
              className={className}
              onClick={(e) => this.handleOptionClick(e, value, disabled)}
            >
              {label}
            </li>
          )
        })}
      </>
    )
  }

  handleFilterChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    this.setState({ filter: value.trim() }, () => {
      this.findOption()
    })
  }

  render() {
    const currentValue = Object.prototype.hasOwnProperty.call(
      this.props.options,
      this.props.value
    )
      ? this.props.options[this.props.value]
      : ''
    const label = this.isOptionObject(currentValue)
      ? currentValue.label
      : currentValue

    const {
      filterTotal,
      filterCurrentIndex,
      filterResults,
      filter
    } = this.state

    return (
      <div
        className={`lp-select${this.state.open ? ' active' : ''}${
          this.props.disabled ? ' disabled' : ''
        }`}
        onMouseDown={this.handleMouseDown}
        onClick={this.handleClick}
        ref={this.selectRef}
      >
        <div className="current-value">{label}</div>
        <div className="icon">
          <ChevronDown />
        </div>
        {this.state.open === true && (
          <div className="dropdown">
            {this.props.enableFilter && (
              <div className="filter">
                <input
                  type="text"
                  ref={this.filterRef}
                  placeholder={I18n.get('filter')}
                  onInput={this.handleFilterChange}
                  onKeyDown={this.watchFilter}
                />
                {filterResults.length > 0 && filter.trim().length > 0 && (
                  <div className="pagination">
                    <div className="current">
                      {filterCurrentIndex + 1} / {filterTotal}
                    </div>
                    <div className="buttons">
                      <button
                        className="filter-up"
                        disabled={filterCurrentIndex === 0}
                        type="button"
                        onClick={this.goPreviousFilter}
                      >
                        ⇧
                      </button>
                      <button
                        className="filter-down"
                        disabled={filterCurrentIndex === filterTotal - 1}
                        type="button"
                        onClick={this.goNextFilter}
                      >
                        ⇩
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            <ul>{this.renderOptions()}</ul>
          </div>
        )}
      </div>
    )
  }
}

export default Select
