import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'

import { IContractsStore } from '../../../store/editor/contractsStore'
import ListItem from './ListItem/ListItem'
import Title from '../../../components/Editor/Title/Title'
import Search from '../../../components/Editor/Search/Search'
import './style.scss'

const columns = {
  fr: {
    'Title of the contract': 'Titre du contrat',
    State: 'Etat',
    'No. of models': 'Nb° modèles',
    Management: 'Gestion',
    'List of contracts': 'Liste des contrats',
    'New contract': 'Nouveau contrat',
    Research: 'Recherche'
  },
  en: {
    'Title of the contract': 'Title of the contract',
    State: 'State',
    'No. of models': 'No. of models',
    Management: 'Management',
    'List of contracts': 'List of contracts',
    'New contract': 'New contract',
    Research: 'Research'
  }
}

I18n.putVocabularies(columns)

interface IDashboardPageProps {
  contractsStore?: IContractsStore | any
}

@inject('contractsStore')
@observer
class DashboardPage extends Component<IDashboardPageProps> {
  UNSAFE_componentWillMount() {
    this.props.contractsStore.fetchContracts()
  }

  public render(): ReactNode {
    const {
      contracts,
      filterContracts,
      selectContract
    } = this.props.contractsStore

    return (
      <div className="dashboard">
        <Title
          title={I18n.get('List of contracts')}
          link="contracts/new"
          buttonTitle={I18n.get('New contract')}
        />
        <Search handleChange={filterContracts} />
        <ul className="list">
          <li className="head">
            <div className="title">{I18n.get('Title of the contract')}</div>
            <div className="state">{I18n.get('State')}</div>
            <div className="nb_models">{I18n.get('No. of models')}</div>
            <div className="buttons">{I18n.get('Management')}</div>
          </li>
          {contracts &&
            contracts.map((el: any) => {
              return (
                <ListItem
                  selectContract={selectContract}
                  key={el.id}
                  item={el}
                />
              )
            })}
        </ul>
      </div>
    )
  }
}

export default DashboardPage
