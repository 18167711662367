import React, { Component, ReactNode } from 'react'
import { FadeLoader } from 'react-spinners'
import { inject, observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { IDataStore } from '../../../store/dataStore'
import NewFileForm from './NewFileForm'
import DataTable from '../../../components/DataTable/DataTable'
import DataSearch from '../../../components/DataSearch/DataSearch'
import DataPagination from '../../../components/DataPagination/DataPagination'
import Confirm from '../../../components/Confirm/Confirm'
import { IDialogStore } from '../../../store/dialogStore'
import Icon from '../../../components/Icons/Icon'
import { PLUS } from '../../../components/Icons/icons'
import { primary } from '../../../constants/scheme'

interface IMyFilesPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
  dialogStore?: IDialogStore | any
}

// TODO: CHECK HANDLE DELETE HERE

const columns = {
  en: {
    my_files_title: 'My Files',
    my_new_file: 'New file',
    my_search: 'Search ...',
    my_items_name: 'Files',
    my_delete_confirm: 'Are you sure?',
    my_id: 'ID',
    my_name: 'File Name',
    my_type: 'Product Type',
    my_model: 'Model Number',
    my_created: 'Created',
    my_modified: 'Last Modified',
    my_tags: 'Tags',
    my_status: 'Status',
    my_action: 'Action',
    my_refresh: 'Refresh'
  },
  fr: {
    my_files_title: 'My Files',
    my_new_file: 'New file',
    my_search: 'Search ...',
    my_items_name: 'Files',
    my_delete_confirm: 'Are you sure?',
    my_id: 'ID',
    my_name: 'File Name',
    my_type: 'Product Type',
    my_model: 'Model Number',
    my_created: 'Created',
    my_modified: 'Last Modified',
    my_tags: 'Tags',
    my_status: 'Status',
    my_action: 'Action',
    my_refresh: 'Refresh'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'dialogStore')
@observer
class MyFilesPage extends Component<IMyFilesPageProps> {
  private rows: any[] = [
    { disableOrder: false, id: 'id', label: I18n.get('my_id') },
    { disableOrder: false, id: 'name', label: I18n.get('my_name') },
    { disableOrder: false, id: 'type', label: I18n.get('my_type') },
    { disableOrder: false, id: 'model_version', label: I18n.get('my_model') },
    { disableOrder: false, id: 'created_at', label: I18n.get('my_created') },
    { disableOrder: false, id: 'modified', label: I18n.get('my_modified') },
    { disableOrder: false, id: 'tags', label: I18n.get('my_tags') },
    { disableOrder: false, id: 'status', label: I18n.get('my_status') },
    { disableOrder: true, id: 'action', label: I18n.get('my_action') }
  ]

  UNSAFE_componentWillMount() {
    this.onChangeParams()
  }

  public newFile = () => {
    this.props.dialogStore.openDialog(<NewFileForm />)
  }

  public handleDelete = () => () => {
    const { openDialog, closeDialog } = this.props.dialogStore
    openDialog(
      <Confirm
        question={I18n.get('my_delete_confirm')}
        handleCancel={closeDialog}
        handleDelete={this.deleteItem()}
      />
    )
  }

  public deleteItem = () => () => {
    this.props.dialogStore.closeDialog()
  }

  public onChangeParams = () => {
    const { location, dataStore } = this.props
    const params: any = new URLSearchParams(location.search)
    dataStore.fetchMyFiles(params)
  }

  public render(): ReactNode {
    const { myFilesData, fetching, error } = this.props.dataStore
    const { data, total } = myFilesData
    return (
      <div className="content">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('my_files_title')}</h3>
            <button
              type="button"
              className="btn btn-active"
              onClick={this.newFile}
            >
              <Icon iconClass="iconPlus" icon={PLUS} />
              {I18n.get('my_new_file')}
            </button>
          </div>
          <div>
            <DataSearch
              onChangeParams={this.onChangeParams}
              placeholder={I18n.get('my_search')}
            />
            <button
              type="button"
              className="btn btn-active"
              onClick={this.onChangeParams}
            >
              {I18n.get('my_refresh')}
            </button>
          </div>
        </div>

        <FadeLoader color={primary} loading={fetching} />
        {error && <div>Error - {error}</div>}

        <DataTable rows={this.rows} onChangeParams={this.onChangeParams}>
          {data.map((row: any, i: number) => (
            <TableRow key={i}>
              <TableCell align="right">
                <Link to={`/my-files/${row.id}`}>{row.id}</Link>
              </TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.type}</TableCell>
              <TableCell align="right">{row.model_version}</TableCell>
              <TableCell align="right">
                {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
              </TableCell>
              <TableCell align="right">{row.modified}</TableCell>
              <TableCell className="tag-row" align="right">
                {/* <div className="btn-wrapper">{row.tags.map((tag: any, i: number) => ( */}
                {/* <button type="button" className="btn btn-tag small-text has-text-weight-medium" key={i}>{tag.name}</button> */}
                {/* ))} */}
                {/* </div> */}
              </TableCell>
              <TableCell className="small-text" align="right">
                {row.status}
              </TableCell>
              <TableCell align="right">
                <div className="btn-wrapper ff-nowrap">
                  <button type="button" className="btn btn-icon btn-download">
                    DownLoad
                  </button>
                  <button type="button" className="btn btn-icon btn-edit">
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-icon btn-delete"
                    onClick={this.handleDelete()}
                  >
                    Delete
                  </button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </DataTable>

        <div className="padding-container pagination-padding-container">
          <DataPagination
            onChangeParams={this.onChangeParams}
            total={total}
            itemsName={I18n.get('my_items_name')}
          />
        </div>
      </div>
    )
  }
}

export default MyFilesPage
