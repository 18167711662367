import React, { Component, ReactNode } from 'react'
import { Redirect, Switch } from 'react-router'
import { inject, observer } from 'mobx-react'
import './App.css'
import { ToastContainer } from 'react-toastify'
import AuthorizedLayout from './layouts/AuthorizedLayout'
import UnAuthorizedLayout from './layouts/UnAuthorizedLayout'
import DialogContainer from './containers/DialogContainer'

@inject('authStore')
@observer
class App extends Component<any> {
  UNSAFE_componentWillMount() {
    this.props.authStore.checkAuthorize()
  }

  public render(): ReactNode {
    const { isSignedIn } = this.props.authStore
    return (
      <>
        <Switch>
          {isSignedIn ? <AuthorizedLayout /> : <UnAuthorizedLayout />}
          <Redirect to="/" />
        </Switch>
        <DialogContainer />
        <ToastContainer enableMultiContainer style={{ top: '86px' }} />
      </>
    )
  }
}

export default App
