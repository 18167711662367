import React from 'react'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import Input from '../../../../../../../components/Editor/InputField'

const dictionary = {
  fr: {
    'Box title': 'Titre de la boite info'
  },
  en: {
    'Box title': 'Box title'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits box title
 */
export const BoxTitle: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const title = state.option.meta.box?.title || ''

  return (
    <div className="label-holder">
      <Input
        value={title}
        name="boxTitle"
        meta="box"
        setFieldValue={(name: string, value: string) =>
          state.updateBoxTitle(value)
        }
        label={I18n.get('Box title')}
      />
    </div>
  )
}
