import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'
import { action, observable } from 'mobx'
import { I18n } from 'aws-amplify'
import { replace } from '../../../utils/history'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

interface ITemplatesFilterProps extends RouteComponentProps {
  onChangeParams(): void
}

const columns = {
  en: {
    filter_all: 'All',
    filter_production: 'Production',
    filter_draft: 'Draft'
  },
  fr: {
    filter_all: 'Tous',
    filter_production: 'Publié',
    filter_draft: 'Brouillon'
  }
}

I18n.putVocabularies(columns)

@observer
class TemplatesFilter extends Component<ITemplatesFilterProps> {
  @observable public filter: string =
    new URLSearchParams(this.props.location.search).get('filter') || 'all'

  @catchAction
  @action
  public onChangeFilter = ({ target }: any) => {
    this.filter = target.value
    this.replaceHistory(this.filter)
  }

  public replaceHistory = (f: string) => {
    const { search, pathname } = this.props.location
    const params: any = new URLSearchParams(search)
    params.set('filter', f)
    replace(`${pathname}?${params.toString()}`)
    setTimeout(() => {
      this.props.onChangeParams()
    })
  }

  public render(): ReactNode {
    return (
      <div className="products-filter">
        <label className="radio-container">
          <input
            type="radio"
            name="filter"
            onChange={this.onChangeFilter}
            value="all"
            checked={this.filter === 'all'}
          />
          <span className="radio-btn" />
          <span className="radio-text">{I18n.get('filter_all')}</span>
        </label>
        <label className="radio-container">
          <input
            type="radio"
            name="filter"
            onChange={this.onChangeFilter}
            value="production"
            checked={this.filter === 'production'}
          />
          <span className="radio-btn" />
          <span className="radio-text">{I18n.get('filter_production')}</span>
        </label>
        <label className="radio-container">
          <input
            type="radio"
            name="filter"
            onChange={this.onChangeFilter}
            value="draft"
            checked={this.filter === 'draft'}
          />
          <span className="radio-btn" />
          <span className="radio-text">{I18n.get('filter_draft')}</span>
        </label>
      </div>
    )
  }
}

export default withRouter(TemplatesFilter)
