import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { IDataStore } from '../../store/dataStore'
import { IContractsStore } from '../../store/editor/contractsStore'
import { ICreateStore } from '../../store/editor/createStore'
import MaterialSelect from '../MaterialSelect'
import { catchAction } from '../../store/editor/catchActionsDecorator'
import './style.scss'

const dictionary = {
  fr: {
    'main-title': 'Copier un document',
    'From which template?': 'De quel template ?',
    'From which version?': 'De quelle version ?',
    'Which document do you want to copy?':
      'Quel document souhaitez-vous copier ?\n',
    'cancel move': 'Annuler',
    'save move': 'Enregistrer'
  },
  en: {
    'main-title': 'Copy document',
    'From which template?': 'From which template?',
    'From which version?': 'From which version?',
    'Which document do you want to copy?':
      'Which document do you want to copy?',
    'cancel move': 'Cancel',
    'save move': 'Save'
  }
}

I18n.putVocabularies(dictionary)

interface ICopySecondaryDocumentModalProps {
  createStore?: ICreateStore | any
  dataStore?: IDataStore | any
  contractsStore?: IContractsStore | any
  open: boolean
  toggleModal(name: string, value: boolean): void
}

@inject('contractsStore', 'dataStore', 'createStore')
@observer
class CopySecondaryDocumentModal extends Component<
  ICopySecondaryDocumentModalProps
> {
  @observable template = ''

  @observable version = ''

  @observable documentToPaste = ''

  @catchAction
  @action
  public fetchProducts = () => {
    const params: any = new URLSearchParams()
    params.set('noLimit', true)
    this.props.dataStore.fetchProducts(params)
  }

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    toggleModal('copySecondaryDocumentIsOpen', open)
  }

  @catchAction
  @action
  handleSelectTemplate = (name: any, value: any) => {
    this.template = value
    this.props.contractsStore.fetchContractMeta(value)
  }

  @catchAction
  @action
  handleSelectVersion = (name: any, value: any) => {
    this.version = value
    this.props.createStore.getDocumentToCopySection(value)
  }

  @catchAction
  @action
  handleSelectDocumenToPaste = (name: any, value: any) => {
    this.documentToPaste = value
  }

  @catchAction
  @action
  handleSave = () => {
    this.props.createStore.copySecondaryDocumentFromAnotherDoc(
      this.documentToPaste
    )
    this.template = ''
    this.version = ''
    this.documentToPaste = ''
    this.props.createStore.documentToCopySection = {}
    this.toggleModal(false)
  }

  render() {
    const templateOptions = this.props.dataStore.allProducts.data.map(
      (template: any) => ({
        value: template.id,
        label: template.name
      })
    )
    const versionOptions =
      this.props.contractsStore.contractMetaInfo.models &&
      this.props.contractsStore.contractMetaInfo.models.map((model: any) => ({
        value: model.id,
        label: model.version
      }))
    const secondaryDocuments =
      this.props.createStore.documentToCopySection.documents &&
      Object.keys(this.props.createStore.documentToCopySection.documents).map(
        (document) => ({
          label: this.props.createStore.documentToCopySection.documents[
            document
          ].name,
          value: document
        })
      )

    return (
      <Dialog
        onEnter={() => this.fetchProducts()}
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="copy-modal-holder"
      >
        <div className="copy-modal">
          <h1>{I18n.get('main-title')}</h1>
          <div className="copy-select-holder">
            <h3>{I18n.get('From which template?')}</h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectTemplate(name, value)
              }
              name="template"
              value={this.template}
              options={templateOptions}
            />
          </div>
          <div className="copy-select-holder">
            <h3>{I18n.get('From which version?')}</h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectVersion(name, value)
              }
              name="version"
              disabled={!this.template}
              value={this.version}
              options={versionOptions}
            />
          </div>
          <div className="copy-select-holder">
            <h3>{I18n.get('Which document do you want to copy?')}</h3>
            <MaterialSelect
              fieldClass="copy-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectDocumenToPaste(name, value)
              }
              name="section"
              disabled={!this.version}
              value={this.documentToPaste}
              options={secondaryDocuments}
            />
          </div>
          <div className="btn-holder">
            <button
              type="button"
              className="secondary"
              onClick={() => this.toggleModal(false)}
            >
              {I18n.get('cancel move')}
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => this.handleSave()}
            >
              {I18n.get('save move')}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default CopySecondaryDocumentModal
