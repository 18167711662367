import React from 'react'
import { FDFInputsV3, FDFBoxesV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import PdfInputEditor from './PdfInputEditor'
import PdfBoxEditor from './PdfBoxEditor'
import './style.scss'

const PdfElementEditor: React.FC<{
  element: FDFInputsV3 | FDFBoxesV3
  close: () => void
}> = ({ element, close }) => {
  const isInput = (e: FDFInputsV3 | FDFBoxesV3): e is FDFInputsV3 =>
    Object.prototype.hasOwnProperty.call(e, 'variables')
  const isBox = (e: FDFInputsV3 | FDFBoxesV3): e is FDFBoxesV3 =>
    Object.prototype.hasOwnProperty.call(e, 'options')

  return (
    <div className="pdf-element-editor">
      <div className="pdf-element-sidebar">
        <div className="pdf-element-title">
          {isInput(element) && I18n.get('Editing input')}
          {isBox(element) && I18n.get('Editing box')}
        </div>
        <div className="pdf-element-content">
          {isInput(element) && (
            <PdfInputEditor key={element.name} input={element} />
          )}
          {isBox(element) && (
            <PdfBoxEditor
              key={`${element.name}-${element.value}`}
              box={element}
            />
          )}
        </div>
        <div className="pdf-element-controls">
          <button type="button" className="secondary" onClick={() => close()}>
            {I18n.get('close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PdfElementEditor
