import { Node, Text } from '@legalplace/slate'
import escapeHtml from 'escape-html'
import createStore from '../../../../../store/editor/createStore'

const serializeNode = (node: Node): string => {
  let style = node.style || {}
  if (Text.isText(node)) style = {}
  if (node.bold === true) style.fontWeight = 'bold'
  if (node.italic === true) style.fontStyle = 'italic'
  if (node.underlined === true) style.textDecoration = 'underline'

  let styleString =
    typeof style === 'object'
      ? Object.entries(style).reduce(
          (currentStyleString, [propName, propValue]) => {
            propName = propName.replace(
              /[A-Z]/g,
              (match) => `-${match.toLowerCase()}`
            )
            return `${currentStyleString}${propName}:${propValue};`
          },
          ''
        )
      : ''

  styleString = styleString.trim().length > 0 ? ` style="${styleString}"` : ''
  if (Text.isText(node)) {
    let text = escapeHtml(node.text).replace(/&nbsp;&nbsp;/g, ' &nbsp;')
    if (node.type === 'variable' && styleString.length === 0)
      return `[var:${node.id}]`
    if (node.type === 'variable') text = `[var:${node.id}]`
    return styleString.length > 0 ? `<span${styleString}>${text}</span>` : text
  }

  const children = node.children.map((n) => serializeNode(n)).join('')
  switch (node.type) {
    case 'paragraph':
      return `<p${styleString}>${children}</p>`
    case 'span':
      return `<span${styleString}>${children}</span>`
    case 'heading-one':
      return `<h1${styleString}>${children}</h1>`
    case 'heading-two':
      return `<h2${styleString}>${children}</h2>`
    case 'heading-three':
      return `<h3${styleString}>${children}</h3>`
    case 'heading-four':
      return `<h4${styleString}>${children}</h4>`
    case 'heading-five':
      return `<h5${styleString}>${children}</h5>`
    case 'heading-siv':
      return `<h6${styleString}>${children}</h6>`
    case 'strong':
      return `<strong${styleString}>${children}</strong>`
    case 'emphasis':
      return `<em${styleString}>${children}</em>`
    case 'underlined':
      return `<u${styleString}>${children}</u>`
    case 'numbered-list':
      return `<ol${styleString}>${children}</ol>`
    case 'bulleted-list':
      return `<ul${styleString}>${children}</ul>`
    case 'list-item':
      return `<li${styleString}>${children}</li>`
    case 'table':
      return `<table${styleString}>${children}</table>`
    case 'table-body':
      return `<tbody${styleString}>${children}</tbody>`
    case 'table-row':
      return `<tr${styleString}>${children}</tr>`
    case 'table-cell':
      return `<td${styleString}>${children}</td>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}"${styleString}>${children}</a>`
    default:
      return children
  }
}

export const serializeOutputs = async (nodes: Node[]) => {
  const outputs = createStore.currentDocumentOutputs
  const outputsIds: number[] = []
  outputs.forEach((output) => {
    const { option, children } = output
    const { id, type } = option.meta
    if (type === 'hidden') {
      outputsIds.push(id)
    } else if (type === 'repeated') {
      children.forEach((child) => {
        outputsIds.push(child.meta.id)
      })
    }
  })

  const changeCommands: { [key: string]: any[] } = {}

  const pushCommand = (id: number, command: [string, ...any[]]) => {
    if (changeCommands[id] === undefined) changeCommands[id] = []
    changeCommands[id].push(command)
  }

  const foundOutputs: number[] = []

  // Getting parent direct ids
  const directParents: Record<string, number> = {}
  Object.keys(createStore.document.options).forEach((parentId) => {
    createStore.document.options[parentId].options.forEach((childId) => {
      directParents[childId] = parseInt(parentId, 10)
    })
  })

  // Checking outputs changes
  nodes.forEach((node, index) => {
    if (Text.isText(node)) throw new Error('Recieved text as root node')
    if (node.type !== 'output')
      throw new Error('Recieved a non output as root node')
    const { id, repeated, repeatedMeta, originalOption, hasConditions } = node

    foundOutputs.push(id)

    // Getting option
    const option = createStore.document.options[id]

    if (option === undefined) {
      const precedentOption = index > 0 ? nodes[index - 1].id : 0
      pushCommand(id, [
        'recreate',
        {
          parentId: repeated,
          parentMeta: repeatedMeta,
          precedentOption,
          originalOption
        }
      ])
      return
    }

    // Serializing output
    const newOutput = node.children.map((n) => serializeNode(n)).join('')

    // Checking if output changed
    const originalOutput = option.meta.output
      ? option.meta.output.toString()
      : ''

    if (originalOutput !== newOutput)
      pushCommand(id, ['updateOutput', newOutput])

    // Checking if conditions status changed
    const { conditions } = option.meta
    const originalHasConditions =
      typeof conditions === 'object' && Object.keys(conditions).length > 0
    if (hasConditions !== originalHasConditions) {
      pushCommand(id, ['updateConditions', originalOption])
    }

    // Checking if repeated status changed
    const parentId = directParents[id]
    if (parentId !== repeated) {
      const multipleId =
        repeatedMeta === undefined ? 0 : repeatedMeta.repeatOption
      pushCommand(id, ['updateRepeated', multipleId])
    }
  })

  // Checking deleted outputs
  outputsIds.forEach((id) => {
    if (!foundOutputs.includes(id)) pushCommand(id, ['delete'])
  })

  // Executing commands
  if (Object.keys(changeCommands).length > 0) {
    Object.keys(changeCommands).forEach((changeId) => {
      const id = parseInt(changeId, 10)
      changeCommands[id].forEach((command) => {
        const [type, ...args] = command

        switch (type) {
          case 'delete':
            createStore.deleteOutputOption(id)
            break
          case 'updateOutput':
            createStore.updateOutput(id, args[0])
            break
          case 'updateConditions':
            createStore.updateConditions(id, args[0])
            break
          case 'updateRepeated':
            createStore.linkOutputsToMultiple(args[0], [id])
            break
          case 'recreate':
            createStore.recreateOutput(args[0])
            break
          default:
            throw new Error(`Unknown command recieved`)
        }
      })
    })

    createStore.updateCurrentDocumentOutputs()
  }
}
