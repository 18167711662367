import React from 'react'
import VariableContext from '../editors/VariableContext'
import { Label } from './components/Label'
import { Type } from './components/Type'
import { Conditions } from './components/Conditions'
import { SubType } from './components/SubType'
import { FallbackLabel } from './components/FallbackLabel'

/**
 * General option params
 */
const GeneralVariableParams: React.FC = () => {
  return (
    <VariableContext.Consumer>
      {(state) => {
        const { id } = state.variable
        return (
          <>
            {/* Rendering Variable's id */}
            <div className="id">#{id}</div>

            {/* type */}
            <Type state={state} />

            {/* Sub-type */}
            <SubType state={state} />

            {/* label */}
            <Label state={state} />

            {/* fallbackLabel */}
            <FallbackLabel state={state} />

            {/* conditions */}
            <Conditions state={state} />
          </>
        )
      }}
    </VariableContext.Consumer>
  )
}

export default GeneralVariableParams
