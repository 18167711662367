import React, { Component } from 'react'

import './style.scss'

interface ISelectProps {
  label?: string
  options: any[]
  className?: string
  value?: string
}

class Select extends Component<ISelectProps, { value: ISelectProps['value'] }> {
  constructor(props: ISelectProps) {
    super(props)
    this.state = {
      value: this.props.value
    }
  }

  onHandleChange = (e: any) => {
    const { value } = e.target
    this.setState({
      value
    })
    // this.props.handleChange(value);
  }

  render() {
    const { label, options, className } = this.props
    const { value } = this.state
    return (
      <div className={`input ${className}`}>
        {label && <label>{label}</label>}
        {/* <input type="text" onChange={this.onHandleChange} value={value} /> */}
        <select value={value} onChange={this.onHandleChange}>
          {options &&
            options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
        </select>
      </div>
    )
  }
}

export default Select
