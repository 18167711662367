import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { ICreateStore } from '../../store/editor/createStore'
import MaterialSelect from '../MaterialSelect'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'move-title': 'Déplacer après',
    'cancel move': 'Annuler',
    'save move': 'Enregistrer'
  },
  en: {
    'move-title': 'Move after:',
    'cancel move': 'Cancel',
    'save move': 'Save'
  }
}

I18n.putVocabularies(dictionary)

interface IExtractionModalProps {
  createStore?: ICreateStore | any
  open: boolean
  toggleModal(name: string, value: boolean): void
  optionParent: any
  setNewOptionIndex(idx: number): void
  setNewOptionInSectionIndex(idx: number): void
  setNewSectionIndex(idx: number): void
  toggleDrawerHandler(val: boolean): void
  selectedElement: any
  options: any
}

@observer
class MoveModal extends Component<IExtractionModalProps> {
  @observable elementId = -1

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal } = this.props
    toggleModal('moveIsOpen', open)
  }

  @catchAction
  @action
  handleSelectBlock = (name: any, value: any) => {
    this.elementId = value
  }

  @catchAction
  @action
  handleSave = () => {
    const {
      selectedElement,
      setNewSectionIndex,
      setNewOptionIndex,
      setNewOptionInSectionIndex,
      optionParent
    } = this.props
    if (!selectedElement.meta && !selectedElement.type) {
      setNewSectionIndex(this.elementId)
    } else if (optionParent()) {
      setNewOptionIndex(this.elementId)
    } else {
      setNewOptionInSectionIndex(this.elementId)
    }
    this.toggleModal(false)
    this.props.toggleDrawerHandler(false)
    this.elementId = -1
  }

  render() {
    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="move-modal-holder"
      >
        <div className="move-modal">
          <h1>{I18n.get('move-title')}</h1>
          <div className="move-select-holder">
            <MaterialSelect
              fieldClass="move-modal-select"
              setFieldValue={(name: string, value: any) =>
                this.handleSelectBlock(name, value)
              }
              name="Type"
              value={this.elementId}
              options={this.props.options}
            />
          </div>
          <div className="btn-holder">
            <button
              type="button"
              className="secondary"
              onClick={() => this.toggleModal(false)}
            >
              {I18n.get('cancel move')}
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => this.handleSave()}
            >
              {I18n.get('save move')}
            </button>
          </div>
        </div>
      </Dialog>
    )
  }
}

export default MoveModal
