import { Editor, Text, Path, Element, Node } from '@legalplace/slate'
import { ReactEditor } from '@legalplace/slate-react'
import { KeyboardEvent } from 'react'
import EditorHelpers from '../helpers/EditorHelpers'

const deleteNode = (
  editor: Editor & ReactEditor,
  node: Element | Text,
  path: Path
) => {
  editor.apply({
    type: 'remove_node',
    path,
    node
  })
}

const handleVariableDelete = (
  editor: Editor & ReactEditor,
  event: KeyboardEvent
) => {
  const { selection } = editor

  // Making sure selection ain't null
  if (selection) {
    const { anchor } = selection
    const { path } = anchor
    const node = EditorHelpers.getNodeByPath(editor, path)
    const parentNode = EditorHelpers.getNodeByPath(editor, path.slice(0, -1))
    const currentIndex = parentNode.children.indexOf(node)
    const previousSibling = parentNode.children[currentIndex - 1]

    const offsetToDeleteSibling = /\s/.test(
      Node.leaf(editor, anchor.path).text.slice(0, 1)
    )
      ? 1
      : 0

    if (Text.isText(node) && node.type === 'variable') {
      event.preventDefault()
      Editor.deleteFragment(editor)
    } else if (
      Text.isText(previousSibling) &&
      previousSibling.type === 'variable' &&
      anchor.offset <= offsetToDeleteSibling
    ) {
      event.preventDefault()
      deleteNode(editor, previousSibling, [
        ...path.slice(0, -1),
        currentIndex - 1
      ])
      Editor.deleteFragment(editor)
    }
  }
}

export default handleVariableDelete
