import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import './style.scss'
import { action, observable } from 'mobx'
import { I18n } from 'aws-amplify'
import { setLanguage } from '../../utils/localization'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface ISelectFieldProps {
  setFieldValue(name: string, value: string): void
  name: string
  checked: boolean
  [k: string]: any
}

const dictionary = {
  fr: {
    multiple: 'Multiple',
    condition: 'Condition',
    'and 2': 'ET',
    'or 1': 'OU'
  },
  en: {
    multiple: 'Multiple',
    condition: 'Condition',
    'and 2': 'and',
    'or 1': 'or'
  }
}

I18n.putVocabularies(dictionary)
setLanguage()

@observer
class Toggle extends Component<ISelectFieldProps> {
  @observable checked = this.props.checked

  @catchAction
  @action
  public onChange = (e: any) => {
    const { name, setFieldValue } = this.props
    console.dir(e.target)

    this.checked = e.target.checked
    setFieldValue(name, e.target.checked)
  }

  public render(): ReactNode {
    const { name } = this.props
    const or = I18n.get('or 1')
    const and = I18n.get('and 2')

    return (
      <div className="can-toggle can-toggle--size-small">
        <div className="border-top" />
        <input
          onChange={this.onChange}
          checked={this.checked}
          id={name}
          type="checkbox"
        />
        <label htmlFor={name}>
          <div
            className="can-toggle__switch"
            data-checked={or}
            data-unchecked={and}
          />
        </label>
        <div className="border-bottom" />
      </div>
    )
  }
}

export default Toggle
