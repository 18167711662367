// @ts-ignore
import Cookies from 'universal-cookie'
import axios from 'axios'
import authStore from '../store/authStore'

const cookies = new Cookies()
const url = process.env.REACT_APP_API_URL
const api = axios.create({
  baseURL: url
})

api.interceptors.request.use((request) => {
  const UUID = cookies.get('token')
  request.headers.Authorization = UUID ? `Bearer ${UUID}` : ''
  return request
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) authStore.logout()
    return Promise.reject(error)
  }
)

export default api
