import { useSlate } from '@legalplace/slate-react'
import React, { MouseEvent, useRef, useState } from 'react'
import TableHelpers from '../../helpers/TableHelpers'
import './borderstyle.scss'
import Button from './Button'
import Icon from './Icon'

const isDescendant = (parent: HTMLElement, element: HTMLElement) => {
  let node = element.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

let bodyEventListener:
  | ((bodyEvent: globalThis.MouseEvent) => void)
  | null = null

const TableBorderColorButton = () => {
  const borderStyleCtaRef = useRef<HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const editor = useSlate()

  const close = () => {
    if (bodyEventListener !== null)
      document.body.removeEventListener('click', bodyEventListener)
    setOpen(false)
  }

  if (bodyEventListener === null)
    bodyEventListener = (bodyEvent: globalThis.MouseEvent) => {
      const target = bodyEvent.target as HTMLElement
      if (target === null || borderStyleCtaRef.current === null) {
        close()
      } else if (
        target === borderStyleCtaRef.current ||
        isDescendant(borderStyleCtaRef.current, target)
      ) {
        bodyEvent.preventDefault()
      } else {
        close()
      }
    }

  const handleMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()

    if (open === false && bodyEventListener !== null) {
      setOpen(true)
      document.body.addEventListener('click', bodyEventListener)
    } else {
      close()
    }
  }

  const colors = [
    '#FFFFF',
    '#000000',
    '#FF6633',
    '#FFB399',
    '#FF33FF',
    '#E6B333',
    '#3366E6',
    '#999966',
    '#99FF99',
    '#B34D4D',
    '#80B300',
    '#809900',
    '#E6B3B3',
    '#6680B3',
    '#66991A',
    '#FF99E6',
    '#CCFF1A',
    '#FF1A66',
    '#E6331A',
    '#33FFCC',
    '#66994D',
    '#B366CC',
    '#4D8000',
    '#B33300',
    '#CC80CC',
    '#66664D',
    '#991AFF',
    '#E666FF',
    '#4DB3FF',
    '#1AB399',
    '#E666B3',
    '#33991A',
    '#CC9999',
    '#B3B31A',
    '#00E680',
    '#4D8066',
    '#809980',
    '#E6FF80',
    '#1AFF33',
    '#999933',
    '#FF3380',
    '#CCCC00',
    '#66E64D',
    '#4D80CC',
    '#9900B3',
    '#E64D66',
    '#4DB380',
    '#FF4D4D',
    '#99E6E6',
    '#6666FF'
  ]

  const setBorderColor = async (
    event: MouseEvent<HTMLDivElement>,
    color: string
  ) => {
    event.preventDefault()
    await TableHelpers.editCurrentCellBorder(editor, 'top', { color })
    await TableHelpers.editCurrentCellBorder(editor, 'bottom', { color })
    await TableHelpers.editCurrentCellBorder(editor, 'left', { color })
    await TableHelpers.editCurrentCellBorder(editor, 'right', { color })
    close()
  }

  return (
    <span
      className={`border-color-cta${open ? ' open' : ''}`}
      ref={borderStyleCtaRef}
    >
      <Button active={open} onMouseDown={handleMouseDown}>
        <Icon>border_color</Icon>
      </Button>
      <div className="dropdown">
        <div className="colors">
          {colors.map((color) => {
            return (
              <div
                key={`border-color-${color}`}
                className="color"
                onMouseDown={(event) => setBorderColor(event, color)}
              >
                <div className="dot" style={{ backgroundColor: color }} />
              </div>
            )
          })}
        </div>
      </div>
    </span>
  )
}

export default TableBorderColorButton
