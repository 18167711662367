import { OptionV3 } from '@legalplace/models-v3-types'
import Dialog from '@material-ui/core/Dialog'
import { I18n } from 'aws-amplify'
import { action, observable, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { ICreateStore } from '../../store/editor/createStore'
import SelectField from '../SelectField/SelectField'
import './style.scss'
import { catchAction } from '../../store/editor/catchActionsDecorator'

const dictionary = {
  fr: {
    'multiple-title': 'Sélectionnez un multiple dans la liste ci-dessous',
    'multiple-cancel': 'Annuler',
    Multiply: 'Enregistrer'
  },
  en: {
    'multiple-title': 'Select a multiple in the list below:',
    'multiple-cancel': 'Cancel',
    Multiply: 'Multiply'
  }
}

I18n.putVocabularies(dictionary)

interface IMultiplesModalProps {
  createStore: ICreateStore
  open: boolean
  multipleValue: any
}

@inject('createStore')
@observer
class MultiplesModal extends Component<IMultiplesModalProps> {
  @observable selectedOption = this.props.multipleValue

  @observable prevSelectedOption = -1

  constructor(props: any) {
    super(props)
    // @ts-ignore
    this.reaction1 = reaction(
      () => this.props.multipleValue,
      () => {
        this.selectedOption = this.props.multipleValue
      }
    )
  }

  @catchAction
  @action
  public toggleModal = (open: boolean) => {
    const { toggleModal, setSelectedBlocks } = this.props.createStore
    toggleModal('multiplesIsOpen', open)
    setSelectedBlocks([])
  }

  @catchAction
  @action
  onHandleChange = (name: string, value: any) => {
    this.prevSelectedOption = this.selectedOption
    this.selectedOption = value
  }

  handleManageClass = (id: any, flag: string) => {
    if (Array.isArray(id)) {
      id.map((currId: number) => {
        const node = document.querySelector(`[data-id="${currId}"]`)
        if (flag === 'add') {
          if (node) node.classList.add('multiple-block')
        } else if (node) node.classList.remove('multiple-block')
        return null
      })
    } else {
      const node = document.querySelector(`[data-id="${id}"]`)
      if (flag === 'add') {
        if (node) node.classList.add('multiple-block')
      } else if (node) node.classList.remove('multiple-block')
    }
  }

  clearRepeatedOptsChilds = (selectedBlocks: number[]) => {
    const { document, currentDocument } = this.props.createStore

    const repeatedToClear: any[] = []
    // check if selected options are in different repeated opts
    Object.values(document.options).map((option: any) => {
      if (option.meta && option.meta.type === 'repeated') {
        const filteredChilds = option.options.filter((childrenId: any) => {
          return selectedBlocks.includes(childrenId)
        })
        if (filteredChilds.length) {
          repeatedToClear.push(option)
        }
      }
      return null
    })
    if (repeatedToClear.length) {
      // clear another repeated from selected options
      repeatedToClear.map((opt: any) => {
        opt.options = opt.options.filter(
          (child: any) => !selectedBlocks.includes(child)
        )
        return null
      })
      repeatedToClear.map((opt: any) => {
        if (!opt.options.length) {
          document.documents[
            currentDocument
          ].sections[0].options = document.documents[
            currentDocument
          ].sections[0].options.filter((optId: number) => optId !== opt.meta.id)
          delete document.options[opt.meta.id]
        }
        return null
      })
    }
  }

  clearNonRepeatedOptionsFromSection = (selectedBlocks: number[]) => {
    const { document, currentDocument } = this.props.createStore
    const indexes = document.documents[currentDocument].sections[0].options
      .map((id, index) => (selectedBlocks.includes(id) ? index : undefined))
      .filter((v: number | undefined): v is number => v !== undefined)
      .sort((a, b) => {
        return b - a
      })

    indexes.forEach((index) =>
      document.documents[currentDocument].sections[0].options.splice(index, 1)
    )
  }

  optionCreator = (optionsArr: number[]) => {
    const { idCounter } = this.props.createStore
    const option: OptionV3 = {
      meta: {
        type: 'repeated',
        label: 'Text de la question',
        output: '',
        step: '*',
        repeatOption: this.selectedOption,
        id: idCounter + 1
      },
      variables: [],
      options: optionsArr
    }
    return option
  }

  oneRepeatedSetter = (childId: number) => {
    const { idCounter, document, currentDocument } = this.props.createStore
    const firstIndex = document.documents[
      currentDocument
    ].sections[0].options.findIndex((optId: number) => optId === +childId)

    // replace hidden option in section.options with repeated id
    document.documents[currentDocument].sections[0].options.splice(
      firstIndex,
      1,
      idCounter + 1
    )
  }

  manyRepeatedSetter = () => {
    const {
      idCounter,
      document,
      selectedBlocks,
      currentDocument
    } = this.props.createStore
    const firstOpt = selectedBlocks[0]
    const firstIndex = document.documents[
      currentDocument
    ].sections[0].options.findIndex((optId: number) => +optId === +firstOpt)
    // add new repeated id to the section.options to the first output position
    document.documents[currentDocument].sections[0].options.splice(
      firstIndex,
      1,
      idCounter + 1
    )
    // remove all of the selected outputs from section.options
    document.documents[
      currentDocument
    ].sections[0].options = document.documents[
      currentDocument
    ].sections[0].options.filter(
      (optId: number) => !selectedBlocks.includes(optId)
    )
  }

  getRepeatedIndexes = () => {
    const { document, selectedBlocks, currentDocument } = this.props.createStore
    const repeatedIndexes: number[] = []
    const repeatedToClear: any[] = []

    Object.values(document.options).map((option: any) => {
      if (option.meta && option.meta.type === 'repeated') {
        const filteredChilds = option.options.filter((childrenId: any) => {
          return selectedBlocks.includes(childrenId)
        })
        if (filteredChilds.length) {
          repeatedToClear.push(option)
        }
      }
      return null
    })

    repeatedToClear.map((repeatedOpt: OptionV3) => {
      const repeatedIndex = document.documents[
        currentDocument
      ].sections[0].options.indexOf(repeatedOpt.meta.id)
      if (repeatedIndex >= 0) {
        repeatedIndexes.push(repeatedIndex)
      }
      return null
    })
    return repeatedIndexes
  }

  @catchAction
  @action
  handleMultiply = () => {
    const {
      addOption,
      document,
      selectedBlocks,
      currentDocument,
      updateCurrentDocumentOutputs,
      getNewOptionId
    } = this.props.createStore
    const childId = selectedBlocks[0]

    if (this.selectedOption === 'none') {
      if (selectedBlocks.length) {
        // replace all repeated from section.opts with their options
        const repeatedToClear: any[] = []
        // check if selected options are in different repeated opts
        Object.values(document.options).map((option: any) => {
          if (option.meta && option.meta.type === 'repeated') {
            const filteredChilds = option.options.filter((childrenId: any) => {
              return selectedBlocks.includes(childrenId)
            })
            if (filteredChilds.length) {
              repeatedToClear.push(option)
            }
          }
          return null
        })
        if (repeatedToClear.length) {
          repeatedToClear.map((repeated: OptionV3) => {
            // get repeated index
            const repeatedIndex = document.documents[
              currentDocument
            ].sections[0].options.findIndex(
              (optId: number) => optId === repeated.meta.id
            )
            // get repeated options
            const repeatedOptions = repeated.options
            // split section.options by repeatedIndex
            const p1 = document.documents[
              currentDocument
            ].sections[0].options.slice(0, repeatedIndex)
            const p2 = document.documents[
              currentDocument
            ].sections[0].options.slice(repeatedIndex)
            // join new section.options
            let newOpts = [...p1, ...repeatedOptions, ...p2]
            // filter repeated id from section.options
            newOpts = newOpts.filter((opt: number) => opt !== repeated.meta.id)
            document.documents[currentDocument].sections[0].options = newOpts
            delete document.options[repeated.meta.id]
            return null
          })
        }
        this.handleManageClass(selectedBlocks, 'remove')
      } else {
        // replace this repeated id in section.options with its options
        const repeated = Object.values(document.options).find(
          (option: OptionV3) => {
            if (option.meta) return option.options.includes(+childId)
            return false
          }
        )
        if (repeated) {
          const repeatedIndex = document.documents[
            currentDocument
          ].sections[0].options.findIndex(
            (optId: number) => optId === repeated.meta.id
          )
          const p1 = document.documents[
            currentDocument
          ].sections[0].options.slice(0, repeatedIndex)
          const p2 = document.documents[
            currentDocument
          ].sections[0].options.slice(repeatedIndex)
          let newOpts = [...p1, ...repeated.options, ...p2]
          newOpts = newOpts.filter((opt: number) => opt !== repeated.meta.id)
          document.documents[currentDocument].sections[0].options = newOpts
          delete document.options[repeated.meta.id]
          this.handleManageClass(childId, 'remove')
        }
      }
      this.selectedOption = undefined
      this.toggleModal(false)
      updateCurrentDocumentOutputs()
      return
    }

    if (selectedBlocks.length && selectedBlocks.length > 1) {
      const repeated: any[] = []
      // check if selected options are in different repeated opts
      Object.values(document.options).map((option: any) => {
        if (option.meta && option.meta.type === 'repeated') {
          const filteredChilds = option.options.filter((childrenId: any) => {
            return selectedBlocks.includes(childrenId)
          })
          if (filteredChilds.length) {
            repeated.push(option)
          }
        }
        return null
      })
      // in case of many options
      if (repeated.length) {
        // if options were used in another repeated
        const indexes = this.getRepeatedIndexes()
        const firstIndex = indexes[0]
        const option = this.optionCreator(selectedBlocks)
        const newRepeatedId = getNewOptionId()
        // add new repeated id to the section.options to the first output position
        document.documents[currentDocument].sections[0].options.splice(
          firstIndex,
          1,
          newRepeatedId
        )
        // remove all of the selected outputs from section.options
        indexes.shift()
        indexes.map((index: number) => {
          document.documents[currentDocument].sections[0].options.splice(
            index,
            0
          )
          return null
        })
        this.clearNonRepeatedOptionsFromSection(selectedBlocks)
        this.clearRepeatedOptsChilds(selectedBlocks)

        addOption(newRepeatedId, option, false, true)
        this.handleManageClass(selectedBlocks, 'add')
      } else {
        // if options are new
        const option = this.optionCreator(selectedBlocks)
        const newOptionId = getNewOptionId()
        this.manyRepeatedSetter()
        addOption(newOptionId, option, false, true)
        this.handleManageClass(selectedBlocks, 'add')
      }
      // in case of one option
    } else {
      const repeated = Object.values(document.options).find(
        (option: OptionV3) => {
          if (option.meta) {
            return option.options.includes(+childId)
          }
          return false
        }
      )
      if (repeated) {
        // if option was used in another repeated
        this.oneRepeatedSetter(repeated.meta.id)
        this.clearNonRepeatedOptionsFromSection(selectedBlocks)
        this.clearRepeatedOptsChilds([+childId])
        const option = this.optionCreator([+childId])
        addOption(getNewOptionId(), option, false, true)
        this.handleManageClass(selectedBlocks, 'add')
      } else {
        // if option is new
        const option = this.optionCreator([+childId])
        addOption(getNewOptionId(), option, false, true)
        this.oneRepeatedSetter(+childId)
        this.handleManageClass(selectedBlocks, 'add')
      }
    }
    this.selectedOption = undefined
    this.toggleModal(false)
    updateCurrentDocumentOutputs()
  }

  render() {
    const { multiplesOptions } = this.props.createStore

    return (
      <Dialog
        onClose={() => this.toggleModal(false)}
        open={this.props.open}
        className="extraction-modal multiple-modal"
      >
        <h1>{I18n.get('multiple-title')}</h1>
        <SelectField
          fieldClass="modal-select"
          value={this.selectedOption}
          setFieldValue={this.onHandleChange}
          name="selectedOption"
          options={multiplesOptions}
        />
        <div className="buttons-holder">
          <button
            type="button"
            className="secondary"
            onClick={() => this.toggleModal(false)}
          >
            {I18n.get('multiple-cancel')}
          </button>
          <button
            type="button"
            className="primary"
            onClick={this.handleMultiply}
          >
            {I18n.get('Multiply')}
          </button>
        </div>
      </Dialog>
    )
  }
}

export default MultiplesModal
