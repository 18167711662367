import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'

class Input extends Component<any> {
  onChange = (e: any) => {
    const { name, setFieldValue, meta, el } = this.props
    setFieldValue(name, e.target.value, meta, el)
  }

  render() {
    return (
      <div className="custom-input">
        {this.props.label ? (
          <h5 style={{ margin: 0 }} className="text">
            {this.props.label}
          </h5>
        ) : (
          ''
        )}
        <TextField
          classes={{ root: 'custom-field' }}
          id="outlined-bare"
          margin="normal"
          variant="outlined"
          type={this.props.type ? this.props.type : 'text'}
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.onChange}
          multiline={this.props.multiline}
          rowsMax="4"
        />
      </div>
    )
  }
}

export default Input
