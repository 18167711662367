import React from 'react'
import { I18n } from 'aws-amplify'
import GeneralParamsModal from './General'
import EmailParamsModal from './Email'
import createStore from '../../../store/editor/createStore'
import PreviewParamsModal from './Preview'
import ClientTypeParamsModal from './ClientType'
import ExtractsParamsModal from './Extracts'
import OtherParamsModal from './Other'

const dictionary = {
  fr: {
    general: 'Général',
    email: 'Section email',
    preview: 'Aperçu du document',
    client_type: 'Type de client',
    extracts: 'Extractions',
    other: 'Autre'
  },
  en: {
    general: 'General',
    email: 'Email section',
    preview: 'Preview',
    client_type: 'Type de client',
    extracts: 'Extracts',
    other: 'Other'
  }
}

I18n.putVocabularies(dictionary)

export type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

// Tab config type
export type ParamsModalTabConfigType = {
  slug: string
  title: string
  content: React.ComponentType<ParamsModalTabPropsType>
  isDefault?: boolean
  onSave: (params: Record<string, any>) => void
}

const tabs: ParamsModalTabConfigType[] = []

/**
 * General
 */
tabs.push({
  title: I18n.get('general'),
  slug: 'general',
  isDefault: true,
  content: GeneralParamsModal,
  onSave: (params) => {
    createStore.updateCustomizationParams(params)
  }
})

/**
 * Email
 */
tabs.push({
  title: I18n.get('email'),
  slug: 'email',
  content: EmailParamsModal,
  onSave: (params) => {
    createStore.updateCustomizationParams(params)
  }
})

/**
 * Preview
 */
tabs.push({
  title: I18n.get('preview'),
  slug: 'preview',
  content: PreviewParamsModal,
  onSave: (params) => {
    createStore.updateCustomizationParams(params)
  }
})

/**
 * Client type
 */
tabs.push({
  title: I18n.get('client_type'),
  slug: 'client_type',
  content: ClientTypeParamsModal,
  onSave: (params) => {
    createStore.updateCustomizationParams(params)
  }
})

/**
 * Extracts
 */
tabs.push({
  title: I18n.get('extracts'),
  slug: 'extracts',
  content: ExtractsParamsModal,
  onSave: (params) => {
    createStore.updateCustomizationParams({
      'meta.extracts': params.extracts
    })
  }
})

/**
 * Other
 */
tabs.push({
  title: I18n.get('other'),
  slug: 'other',
  content: OtherParamsModal,
  onSave: (params) => {
    createStore.updateCustomizationParams(params)
  }
})

export default tabs
