import createStore from '../../../store/editor/createStore'

type ElementType = {
  type: 'section' | 'option' | 'variable'
  level: number
  id: number
  label: string
}
const getVariablesList = (variables: number[], level: number = 0) => {
  const { document } = createStore
  const result: ElementType[] = []

  variables.forEach((id) => {
    const variable = document.variables[id]

    result.push({
      type: 'variable',
      level: level + 1,
      label: variable.label,
      id
    })
  })

  return result
}

const getOptionsList = (options: number[], level: number = 0) => {
  const { document } = createStore
  let result: ElementType[] = []

  options.forEach((id) => {
    const option = document.options[id]

    if (!['radio', 'checkbox', 'static'].includes(option.meta.type)) return

    result.push({
      type: 'option',
      level: level + 1,
      label: option.meta.label,
      id
    })

    // Reading variables
    result = [...result, ...getVariablesList(option.variables, level + 1)]

    // Reading options
    result = [...result, ...getOptionsList(option.options, level + 1)]
  })

  return result
}

const getElementsList = () => {
  const { document } = createStore

  let result: ElementType[] = []

  document.documents.main.sections.forEach((section) => {
    result.push({
      type: 'section',
      level: 0,
      label: section.label,
      id: section.id
    })

    // Reading options
    result = [...result, ...getOptionsList(section.options)]
  })

  return result
}

export default getElementsList
