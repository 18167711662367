import React, { Component, ReactNode } from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps, withRouter } from 'react-router'
import { action, observable } from 'mobx'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel/TableSortLabel'
import TableBody from '@material-ui/core/TableBody/TableBody'
import { replace } from '../../utils/history'
import { catchAction } from '../../store/editor/catchActionsDecorator'

interface IDataTableProps extends RouteComponentProps {
  children: any
  rows: any[]
  onChangeParams(): void
}

@observer
class DataTable extends Component<IDataTableProps> {
  @observable public order: 'asc' | 'desc' = 'asc'

  @observable public orderBy: string = ''

  @catchAction
  @action
  componentDidMount() {
    const { location, rows } = this.props
    const params: any = new URLSearchParams(location.search)
    const order = (params.get('order') || '').toLowerCase().split(' ')
    const by = rows.find((r) => r.id === order[0])
    this.orderBy = by ? by.id : ''
    this.order =
      order[1] && ['asc', 'desc'].includes(order[1]) ? order[1] : 'desc'
  }

  public handleRequestSort = ({ id, disableOrder }: any) =>
    action(() => {
      if (disableOrder) return
      this.order = this.order === 'desc' && this.orderBy === id ? 'asc' : 'desc'
      this.orderBy = id
      this.replaceHistory(`${this.orderBy} ${this.order}`)
    })

  public replaceHistory = (order: string) => {
    const { location, onChangeParams } = this.props
    const params: any = new URLSearchParams(location.search)
    params.set('order', order)
    replace(`${location.pathname}?${params.toString()}`)
    setTimeout(() => {
      onChangeParams()
    })
  }

  public render(): ReactNode {
    const { children, rows } = this.props
    return (
      <div className="data-table">
        <Table className="table">
          <TableHead className="thead">
            <TableRow>
              {rows.map((row: any, i: number) => {
                return (
                  <TableCell
                    align={row.align || 'left'}
                    key={i}
                    sortDirection={this.orderBy === row.id ? this.order : false}
                  >
                    <TableSortLabel
                      active={this.order && this.orderBy === row.id}
                      direction={this.order}
                      onClick={this.handleRequestSort(row)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody className="tbody">{children}</TableBody>
        </Table>
      </div>
    )
  }
}

export default withRouter(DataTable)
