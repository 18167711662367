import { Editor } from '@legalplace/slate'
import { useSlate } from '@legalplace/slate-react'
import React, { MouseEvent, useRef, useState } from 'react'
import TableHelpers from '../../helpers/TableHelpers'
import './bordercolor.scss'
import Button from './Button'
import Icon from './Icon'

const isDescendant = (parent: HTMLElement, element: HTMLElement) => {
  let node = element.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

let bodyEventListener:
  | ((bodyEvent: globalThis.MouseEvent) => void)
  | null = null

const TableBorderStyleButton = () => {
  const borderStyleCtaRef = useRef<HTMLElement>(null)
  const [open, setOpen] = useState(false)
  const editor = useSlate()

  const close = () => {
    if (bodyEventListener !== null)
      document.body.removeEventListener('click', bodyEventListener)
    setOpen(false)
  }

  if (bodyEventListener === null)
    bodyEventListener = (bodyEvent: globalThis.MouseEvent) => {
      const target = bodyEvent.target as HTMLElement
      if (target === null || borderStyleCtaRef.current === null) {
        close()
      } else if (
        target === borderStyleCtaRef.current ||
        isDescendant(borderStyleCtaRef.current, target)
      ) {
        bodyEvent.preventDefault()
      } else {
        close()
      }
    }

  const handleMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault()

    if (open === false && bodyEventListener !== null) {
      setOpen(true)
      document.body.addEventListener('click', bodyEventListener)
    } else {
      close()
    }
  }

  const styles = ['solid', 'dotted', 'dashed']

  const setBorderStyle = (event: MouseEvent<HTMLDivElement>, style: string) => {
    event.preventDefault()
    Editor.withoutNormalizing(editor, () => {
      TableHelpers.editCurrentCellBorder(editor, 'top', { style })
      TableHelpers.editCurrentCellBorder(editor, 'bottom', { style })
      TableHelpers.editCurrentCellBorder(editor, 'left', { style })
      TableHelpers.editCurrentCellBorder(editor, 'right', { style })
    })
  }

  return (
    <span
      className={`border-style-cta${open ? ' open' : ''}`}
      ref={borderStyleCtaRef}
    >
      <Button active={open} onMouseDown={handleMouseDown}>
        <Icon>border_style</Icon>
      </Button>
      <div className="dropdown">
        <div className="styles">
          {styles.map((style) => {
            return (
              <div
                key={`border-style-${style}`}
                className={`style ${style}`}
                onMouseDown={(event) => setBorderStyle(event, style)}
              >
                <div className="border" />
              </div>
            )
          })}
        </div>
      </div>
    </span>
  )
}

export default TableBorderStyleButton
