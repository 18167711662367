import { action, observable } from 'mobx'
import { catchAction } from './editor/catchActionsDecorator'

export interface IDialogStore {
  dialogContent: any
  open: boolean
  dialogSettings: any
  closeDialog(): void
  openDialog(component: any, options?: any): void
}

export interface IOptions {
  closeBtn?: boolean
}

const defaultSettings: IOptions = {
  closeBtn: true
}

class DialogStore implements IDialogStore {
  @observable public dialogContent = null

  @observable public open = false

  public dialogSettings = defaultSettings

  @catchAction
  @action
  public closeDialog = () => {
    this.open = false
    this.dialogContent = null
  }

  @catchAction
  @action
  public openDialog = (component: any, options: IOptions = {}) => {
    this.dialogSettings = { ...defaultSettings, ...options }
    this.dialogContent = component
    this.open = true
  }
}

const dialogStore = new DialogStore()
export default dialogStore
