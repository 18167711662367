import React, { useState } from 'react'
import { I18n } from 'aws-amplify'
import OptionContext from '../editors/OptionContext'
import { Style } from './components/Style'
import { ReactComponent as ChevronDown } from '../chevron-down.svg'

const dictionary = {
  fr: { style_category: 'Style' },
  en: { style_category: 'Style' }
}
I18n.putVocabularies(dictionary)
/**
 * Style option params
 */
const StyleOptionParams: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <OptionContext.Consumer>
      {(state) => {
        const toggleCollapsed = () => setCollapsed(!collapsed)

        const { type } = state.option.meta

        if (type !== 'static') return null

        return (
          <div
            className={`state-drawer-category${
              collapsed ? ' collapsed' : ' open'
            }`}
          >
            <div className="category-title" onClick={toggleCollapsed}>
              {I18n.get('style_category')}
              <div className="icon">
                <ChevronDown />
              </div>
            </div>
            {!collapsed && (
              <>
                <Style state={state} />
              </>
            )}
          </div>
        )
      }}
    </OptionContext.Consumer>
  )
}

export default StyleOptionParams
