import React from 'react'
import { I18n } from 'aws-amplify'
import createStore from '../../../store/editor/createStore'
import Input from '../../Editor/InputField'
import CheckboxField from '../../Editor/CheckboxField'

const dictionary = {
  fr: {
    disable_preview_head: 'Afficher le questionnaire sur toute la largeur',
    disable_preview: "Désactiver l'aperçu",
    preview_title: "Titre de l'aperçu"
  },
  en: {
    disable_preview_head: 'Display wizard on full width',
    disable_preview: 'Disable preview',
    preview_title: 'Preview title'
  }
}

I18n.putVocabularies(dictionary)

type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

type PreviewParamsModalStateType = {
  [key: string]: any
}

class PreviewParamsModal extends React.Component<
  ParamsModalTabPropsType,
  PreviewParamsModalStateType
> {
  constructor(props: ParamsModalTabPropsType) {
    super(props)

    const { getParams } = props

    this.state = {
      'meta.previewTitle':
        createStore.document.customization?.meta?.previewTitle || '',
      'meta.isFullWidth':
        createStore.document.customization?.meta?.isFullWidth || false,
      ...getParams()
    }
  }

  updateParams = (newParams: Record<string, any>) => {
    const { setParams } = this.props

    this.setState({ ...this.state, ...newParams })
    setParams(newParams)
  }

  setFieldValue = (name: string, value: string | number | boolean) => {
    const newParams = {
      ...this.state,
      [name]: value
    }
    this.updateParams({
      ...newParams
    })
  }

  render() {
    return (
      <div>
        <div className="pm-block">
          <h3>{I18n.get('disable_preview_head')}</h3>
          <CheckboxField
            name="meta.isFullWidth"
            setFieldValue={this.setFieldValue}
            checked={this.state['meta.isFullWidth'] || false}
            label={I18n.get('disable_preview')}
          />
        </div>
        <div className="pm-block">
          <Input
            name="meta.previewTitle"
            setFieldValue={this.setFieldValue}
            value={this.state['meta.previewTitle']}
            label={I18n.get('preview_title')}
          />
        </div>
      </div>
    )
  }
}

export default PreviewParamsModal
