import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { match } from 'react-router'

import { IContractsStore } from '../../../store/editor/contractsStore'
import { IModelsStore } from '../../../store/editor/modelsStore'
import Model from './Model/Model'
import Title from '../../../components/Editor/Title/Title'
import './style.scss'

const columns = {
  fr: {
    Models: 'Modèles',
    'New model': 'Nouveau modèle',
    Contracts: 'Contrats',
    Version: 'Version',
    State: 'État',
    Modified: 'Modifié le',
    'Published on': 'Publié le',
    Management: 'Gestion'
  },
  en: {
    Models: 'Models',
    'New model': 'New model',
    Contracts: 'Contracts ',
    Version: 'Version',
    State: 'State',
    Modified: 'Modified',
    'Published on': 'Published on',
    Management: 'Management'
  }
}

I18n.putVocabularies(columns)

interface IRouteParams {
  contractId: string
}

interface IModelsPageProps {
  modelsStore?: IModelsStore | any
  contractsStore?: IContractsStore | any
  match: match<IRouteParams>
}

@inject('modelsStore', 'contractsStore')
@observer
class ModelsPage extends Component<IModelsPageProps> {
  componentDidMount() {
    const {
      match: {
        params: { contractId }
      }
    } = this.props
    this.props.modelsStore
      .fetchModels(contractId)
      .then(() => {
        const { contract, fetchContractMeta } = this.props.contractsStore
        if (!contract.name) {
          fetchContractMeta(contractId)
        }
      })
      .catch((err: Error) => {
        console.log('Get models failed: ', err)
      })
  }

  public render(): ReactNode {
    const { models } = this.props.modelsStore
    const {
      contract: { name: contractName },
      contractMeta: { name }
    } = this.props.contractsStore
    const title = contractName || name
    return (
      <div className="dashboard models">
        <div className="breadcrumbs">
          <Link to="/contracts">{I18n.get('Contracts')}</Link>
          <span> &gt; </span>
          <span>{title || 'loading...'}</span>
        </div>
        <Title
          title={`${title || 'loading...'} - ${I18n.get('Models')}`}
          link="/"
          buttonTitle={I18n.get('New model')}
        />
        <ul className="list">
          <li className="head">
            <div className="version">{I18n.get('Version')}</div>
            <div className="state">{I18n.get('State')}</div>
            <div className="last_edit">{I18n.get('Modified')}</div>
            <div className="publication_time">{I18n.get('Published on')}</div>
            <div className="buttons-block">{I18n.get('Management')}</div>
          </li>
          {models &&
            models.map((el: any) => {
              return <Model key={el.id} item={el} />
            })}
        </ul>
      </div>
    )
  }
}

export default ModelsPage
