import React, { CSSProperties } from 'react'

const Leaf: React.FC<Record<string, any>> = (props: Record<string, any>) => {
  const style: CSSProperties = {
    fontWeight: props.leaf.bold ? 'bold' : undefined,
    fontStyle: props.leaf.italic ? 'italic' : undefined,
    textDecoration: props.leaf.underlined ? 'underline' : undefined
  }

  return (
    <span {...props.attributes} style={style}>
      {props.children}
    </span>
  )
}

export default Leaf
