import React, { Component, ReactNode } from 'react'
import { inject, observer } from 'mobx-react'
import { ErrorMessage } from 'formik'
import { I18n } from 'aws-amplify'
import { IDataStore } from '../../../store/dataStore'
import SelectField, {
  IOption
} from '../../../components/SelectField/SelectField'
import { IContractsStore } from '../../../store/editor/contractsStore'
import Input from '../../../components/Editor/InputField'
import './filterStyle.scss'

interface ITemplateDetailSettingsProps {
  dataStore?: IDataStore | any
  contractsStore?: IContractsStore | any
  name: string
  permalink: string
  version_in_production?: number
  template: any
  category: any
  modelId: number
  publishedOptions: any[]
  categoriesOptions: any[]
  type: string
  do_not_send_docs: boolean | 1 | 0
  handleModelChange(id: number): void
}

export interface ITemplateSettingsValues {
  id?: string
  name?: string
  permalink?: string
  category?: string
  model?: string
}

const columns = {
  en: {
    name: 'Name',
    permalink: 'Permalink',
    category_dtls: 'Category',
    model_dtls: 'Published model',
    submit_settings_btn: 'Save settings',

    category_1: 'Staff Management',
    category_2: 'HR Management',
    category_3: 'MP Management',
    category_4: 'RP Management',

    model_1: 'Staff Model',
    model_2: 'HR Model',
    model_3: 'MP Model',
    model_4: 'RP Model',

    Type: 'Type',
    Procedure: 'Procedure',
    Contract: 'Contract',
    'social-contrat': 'Social-contract',
    'social-demarche': 'Social-demarche',
    shine: 'Shine',
    'blank-societe': 'Blank-societe',
    'blank-ae': 'Blank-AE',
    other: 'Other',

    'Send documents': 'Send documents',
    Disabled: 'Disabled',
    Enabled: 'Enabled'
  },
  fr: {
    name: 'Nom',
    permalink: 'Permalink',
    category_dtls: 'Catégorie',
    model_dtls: 'Modèle publié',
    submit_settings_btn: 'Enregistrer',

    category_1: 'Staff Management',
    category_2: 'HR Management',
    category_3: 'MP Management',
    category_4: 'RP Management',

    model_1: 'Staff Model',
    model_2: 'HR Model',
    model_3: 'MP Model',
    model_4: 'RP Model',

    Type: 'Type',
    Procedure: 'Démarches',
    Contract: 'Contrat',
    'social-contrat': 'Social-contrat',
    'social-demarche': 'Social-demarche',
    shine: 'Shine',
    'blank-societe': 'Blank-societe',
    'blank-ae': 'Blank-AE',
    other: 'Autre',
    'Send documents': 'Envoi de documents',
    Disabled: 'Désactivé',
    Enabled: 'Activé'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'contractsStore')
@observer
class TemplateDetailSettings extends Component<ITemplateDetailSettingsProps> {
  private categories: IOption[] = [
    { value: 1, label: I18n.get('category_1') },
    { value: 2, label: I18n.get('category_2') },
    { value: 3, label: I18n.get('category_3') },
    { value: 4, label: I18n.get('category_4') }
  ]

  private models: IOption[] = [
    { value: 1, label: I18n.get('model_1') },
    { value: 2, label: I18n.get('model_2') },
    { value: 3, label: I18n.get('model_3') },
    { value: 4, label: I18n.get('model_4') }
  ]

  private typeOptions: IOption[] = [
    { value: 'demarche', label: I18n.get('Procedure') },
    { value: 'contrat', label: I18n.get('Contract') },
    { value: 'social-contrat', label: I18n.get('social-contrat') },
    { value: 'social-demarche', label: I18n.get('social-demarche') },
    { value: 'shine', label: I18n.get('shine') },
    { value: 'blank-societe', label: I18n.get('blank-societe') },
    { value: 'blank-ae', label: I18n.get('blank-ae') },
    { value: 'other', label: I18n.get('other') }
  ]

  private sendDocsOptions: IOption[] = [
    { value: 1, label: I18n.get('Disabled') },
    { value: 0, label: I18n.get('Enabled') }
  ]

  public handleChangeMeta = (name: string, value: any) => {
    const { updateContractMetaInfo } = this.props.contractsStore
    updateContractMetaInfo(name, value)
    if (name === 'model') {
      this.props.handleModelChange(value)
    }
  }

  public setModelId = (name: string, value: any) => {
    this.props.handleModelChange(value)
  }

  public render(): ReactNode {
    const { updateContractMeta, fetching } = this.props.contractsStore
    const {
      name,
      permalink,
      version_in_production,
      type,
      do_not_send_docs,
      modelId,
      publishedOptions,
      categoriesOptions,
      category
    } = this.props
    const { typeOptions, sendDocsOptions } = this

    return (
      <div className="detail-settings">
        <form noValidate onSubmit={(e) => e.preventDefault()}>
          <div className="inner-container">
            <Input
              fieldClass="fieldName temp-details-input"
              label={I18n.get('name')}
              type="text"
              name="name"
              value={name}
              setFieldValue={this.handleChangeMeta}
            />
            <Input
              fieldClass="fieldName temp-details-input"
              label="Permalink"
              type="text"
              name="permalink"
              value={permalink}
              setFieldValue={this.handleChangeMeta}
            />
            <SelectField
              fieldClass="fieldCategory"
              label={I18n.get('category_dtls')}
              options={categoriesOptions}
              setFieldValue={this.handleChangeMeta}
              name="category"
              value={category && category.id}
            />
            <SelectField
              fieldClass="fieldType"
              label={I18n.get('Type')}
              options={typeOptions}
              setFieldValue={this.handleChangeMeta}
              name="type"
              value={type}
            />
            <SelectField
              fieldClass="fieldDocs"
              label={I18n.get('Send documents')}
              options={sendDocsOptions}
              setFieldValue={this.handleChangeMeta}
              name="do_not_send_docs"
              value={do_not_send_docs ? 1 : 0}
            />
            <SelectField
              fieldClass="fieldModel"
              label={I18n.get('model_dtls')}
              options={publishedOptions}
              setFieldValue={this.handleChangeMeta}
              name="model_published"
              value={version_in_production && version_in_production}
            />
            <ErrorMessage name="server_error" />
          </div>
          <div className="btn-wrapper">
            <button
              type="button"
              className="btn primary"
              onClick={() => updateContractMeta(modelId)}
              disabled={fetching}
            >
              {I18n.get('submit_settings_btn')}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default TemplateDetailSettings
