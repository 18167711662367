import React from 'react'
import { OptionV3 } from '@legalplace/models-v3-types'
import { I18n } from 'aws-amplify'
import { OptionEditorStateType } from '../../editors/OptionEditor'
import SelectField from '../../../../../../../components/SelectField/SelectField'
import createStore from '../../../../../../../store/editor/createStore'

const dictionary = {
  fr: {
    Type: 'Type'
  },
  en: {
    Type: 'Type'
  }
}
I18n.putVocabularies(dictionary)

/**
 * Edits option type
 */
export const Type: React.FC<{ state: OptionEditorStateType }> = ({ state }) => {
  const { getOptionParent, rootOptTypes, optTypes } = createStore
  const { id, type } = state.option.meta

  const parentId = getOptionParent(id)
  const isRoot = parentId !== id

  const types = isRoot ? rootOptTypes : optTypes

  return (
    <SelectField
      isSearchable={false}
      fieldClass="type-element-select"
      setFieldValue={(name, value: OptionV3['meta']['type']) => {
        state.updateType(value)
      }}
      meta
      value={type}
      label={I18n.get('Type')}
      name="type"
      options={types}
    />
  )
}
