const material_icons = {
  action: {
    '3d_rotation': ['e84d', false],
    accessibility: ['e84e', false],
    accessible: ['e914', false],
    account_balance: ['e84f', false],
    account_balance_wallet: ['e850', false],
    account_box: ['e851', false],
    account_circle: ['e853', false],
    add_shopping_cart: ['e854', false],
    alarm: ['e855', false],
    alarm_add: ['e856', false],
    alarm_off: ['e857', false],
    alarm_on: ['e858', false],
    all_out: ['e90b', false],
    android: ['e859', false],
    announcement: ['e85a', false],
    aspect_ratio: ['e85b', false],
    assessment: ['e85c', false],
    assignment: ['e85d', false],
    assignment_ind: ['e85e', false],
    assignment_late: ['e85f', false],
    assignment_return: ['e860', false],
    assignment_returned: ['e861', false],
    assignment_turned_in: ['e862', false],
    autorenew: ['e863', false],
    backup: ['e864', false],
    book: ['e865', false],
    bookmark: ['e866', false],
    bookmark_border: ['e867', false],
    bug_report: ['e868', false],
    build: ['e869', false],
    cached: ['e86a', false],
    camera_enhance: ['e8fc', false],
    card_giftcard: ['e8f6', false],
    card_membership: ['e8f7', false],
    card_travel: ['e8f8', false],
    change_history: ['e86b', false],
    check_circle: ['e86c', false],
    chrome_reader_mode: ['e86d', false],
    class: ['e86e', false],
    code: ['e86f', false],
    compare_arrows: ['e915', false],
    copyright: ['e90c', false],
    credit_card: ['e870', false],
    dashboard: ['e871', false],
    date_range: ['e916', false],
    delete: ['e872', false],
    delete_forever: ['e92b', true],
    description: ['e873', false],
    dns: ['e875', false],
    done: ['e876', false],
    done_all: ['e877', false],
    donut_large: ['e917', false],
    donut_small: ['e918', false],
    eject: ['e8fb', false],
    euro_symbol: ['e926', true],
    event: ['e878', false],
    event_seat: ['e903', false],
    exit_to_app: ['e879', false],
    explore: ['e87a', false],
    extension: ['e87b', false],
    face: ['e87c', false],
    favorite: ['e87d', false],
    favorite_border: ['e87e', false],
    feedback: ['e87f', false],
    find_in_page: ['e880', false],
    find_replace: ['e881', false],
    fingerprint: ['e90d', false],
    flight_land: ['e904', false],
    flight_takeoff: ['e905', false],
    flip_to_back: ['e882', false],
    flip_to_front: ['e883', false],
    g_translate: ['e927', true],
    gavel: ['e90e', false],
    get_app: ['e884', false],
    gif: ['e908', false],
    grade: ['e885', false],
    group_work: ['e886', false],
    help: ['e887', false],
    help_outline: ['e8fd', false],
    highlight_off: ['e888', false],
    history: ['e889', false],
    home: ['e88a', false],
    hourglass_empty: ['e88b', false],
    hourglass_full: ['e88c', false],
    http: ['e902', false],
    https: ['e88d', false],
    important_devices: ['e912', false],
    info: ['e88e', false],
    info_outline: ['e88f', false],
    input: ['e890', false],
    invert_colors: ['e891', false],
    label: ['e892', false],
    label_outline: ['e893', false],
    language: ['e894', false],
    launch: ['e895', false],
    lightbulb_outline: ['e90f', false],
    line_style: ['e919', false],
    line_weight: ['e91a', false],
    list: ['e896', false],
    lock: ['e897', false],
    lock_open: ['e898', false],
    lock_outline: ['e899', false],
    loyalty: ['e89a', false],
    markunread_mailbox: ['e89b', false],
    motorcycle: ['e91b', false],
    note_add: ['e89c', false],
    offline_pin: ['e90a', false],
    opacity: ['e91c', false],
    open_in_browser: ['e89d', false],
    open_in_new: ['e89e', false],
    open_with: ['e89f', false],
    pageview: ['e8a0', false],
    pan_tool: ['e925', false],
    payment: ['e8a1', false],
    perm_camera_mic: ['e8a2', false],
    perm_contact_calendar: ['e8a3', false],
    perm_data_setting: ['e8a4', false],
    perm_device_information: ['e8a5', false],
    perm_identity: ['e8a6', false],
    perm_media: ['e8a7', false],
    perm_phone_msg: ['e8a8', false],
    perm_scan_wifi: ['e8a9', false],
    pets: ['e91d', false],
    picture_in_picture: ['e8aa', false],
    picture_in_picture_alt: ['e911', false],
    play_for_work: ['e906', false],
    polymer: ['e8ab', false],
    power_settings_new: ['e8ac', false],
    pregnant_woman: ['e91e', false],
    print: ['e8ad', false],
    query_builder: ['e8ae', false],
    question_answer: ['e8af', false],
    receipt: ['e8b0', false],
    record_voice_over: ['e91f', false],
    redeem: ['e8b1', false],
    remove_shopping_cart: ['e928', true],
    reorder: ['e8fe', false],
    report_problem: ['e8b2', false],
    restore: ['e8b3', false],
    restore_page: ['e929', true],
    room: ['e8b4', false],
    rounded_corner: ['e920', false],
    rowing: ['e921', false],
    schedule: ['e8b5', false],
    search: ['e8b6', false],
    settings: ['e8b8', false],
    settings_applications: ['e8b9', false],
    settings_backup_restore: ['e8ba', false],
    settings_bluetooth: ['e8bb', false],
    settings_brightness: ['e8bd', false],
    settings_cell: ['e8bc', false],
    settings_ethernet: ['e8be', false],
    settings_input_antenna: ['e8bf', false],
    settings_input_component: ['e8c0', false],
    settings_input_composite: ['e8c1', false],
    settings_input_hdmi: ['e8c2', false],
    settings_input_svideo: ['e8c3', false],
    settings_overscan: ['e8c4', false],
    settings_phone: ['e8c5', false],
    settings_power: ['e8c6', false],
    settings_remote: ['e8c7', false],
    settings_voice: ['e8c8', false],
    shop: ['e8c9', false],
    shop_two: ['e8ca', false],
    shopping_basket: ['e8cb', false],
    shopping_cart: ['e8cc', false],
    speaker_notes: ['e8cd', false],
    speaker_notes_off: ['e92a', true],
    spellcheck: ['e8ce', false],
    stars: ['e8d0', false],
    store: ['e8d1', false],
    subject: ['e8d2', false],
    supervisor_account: ['e8d3', false],
    swap_horiz: ['e8d4', false],
    swap_vert: ['e8d5', false],
    swap_vertical_circle: ['e8d6', false],
    system_update_alt: ['e8d7', false],
    tab: ['e8d8', false],
    tab_unselected: ['e8d9', false],
    theaters: ['e8da', false],
    thumb_down: ['e8db', false],
    thumb_up: ['e8dc', false],
    thumbs_up_down: ['e8dd', false],
    timeline: ['e922', false],
    toc: ['e8de', false],
    today: ['e8df', false],
    toll: ['e8e0', false],
    touch_app: ['e913', false],
    track_changes: ['e8e1', false],
    translate: ['e8e2', false],
    trending_down: ['e8e3', false],
    trending_flat: ['e8e4', false],
    trending_up: ['e8e5', false],
    turned_in: ['e8e6', false],
    turned_in_not: ['e8e7', false],
    update: ['e923', false],
    verified_user: ['e8e8', false],
    view_agenda: ['e8e9', false],
    view_array: ['e8ea', false],
    view_carousel: ['e8eb', false],
    view_column: ['e8ec', false],
    view_day: ['e8ed', false],
    view_headline: ['e8ee', false],
    view_list: ['e8ef', false],
    view_module: ['e8f0', false],
    view_quilt: ['e8f1', false],
    view_stream: ['e8f2', false],
    view_week: ['e8f3', false],
    visibility: ['e8f4', false],
    visibility_off: ['e8f5', false],
    watch_later: ['e924', false],
    work: ['e8f9', false],
    youtube_searched_for: ['e8fa', false],
    zoom_in: ['e8ff', false],
    zoom_out: ['e900', false]
  },
  alert: {
    add_alert: ['e003', false],
    error: ['e000', false],
    error_outline: ['e001', false],
    warning: ['e002', false]
  },
  av: {
    add_to_queue: ['e05c', false],
    airplay: ['e055', false],
    album: ['e019', false],
    art_track: ['e060', false],
    av_timer: ['e01b', false],
    branding_watermark: ['e06b', true],
    call_to_action: ['e06c', true],
    closed_caption: ['e01c', false],
    equalizer: ['e01d', false],
    explicit: ['e01e', false],
    fast_forward: ['e01f', false],
    fast_rewind: ['e020', false],
    featured_play_list: ['e06d', true],
    featured_video: ['e06e', true],
    fiber_dvr: ['e05d', false],
    fiber_manual_record: ['e061', false],
    fiber_new: ['e05e', false],
    fiber_pin: ['e06a', false],
    fiber_smart_record: ['e062', false],
    forward_10: ['e056', false],
    forward_30: ['e057', false],
    forward_5: ['e058', false],
    games: ['e021', false],
    hd: ['e052', false],
    hearing: ['e023', false],
    high_quality: ['e024', false],
    library_add: ['e02e', false],
    library_books: ['e02f', false],
    library_music: ['e030', false],
    loop: ['e028', false],
    mic: ['e029', false],
    mic_none: ['e02a', false],
    mic_off: ['e02b', false],
    movie: ['e02c', false],
    music_video: ['e063', false],
    new_releases: ['e031', false],
    not_interested: ['e033', false],
    note: ['e06f', true],
    pause: ['e034', false],
    pause_circle_filled: ['e035', false],
    pause_circle_outline: ['e036', false],
    play_arrow: ['e037', false],
    play_circle_filled: ['e038', false],
    play_circle_outline: ['e039', false],
    playlist_add: ['e03b', false],
    playlist_add_check: ['e065', false],
    playlist_play: ['e05f', false],
    queue: ['e03c', false],
    queue_music: ['e03d', false],
    queue_play_next: ['e066', false],
    radio: ['e03e', false],
    recent_actors: ['e03f', false],
    remove_from_queue: ['e067', false],
    repeat: ['e040', false],
    repeat_one: ['e041', false],
    replay_10: ['e059', false],
    replay: ['e042', false],
    replay_30: ['e05a', false],
    replay_5: ['e05b', false],
    shuffle: ['e043', false],
    skip_next: ['e044', false],
    skip_previous: ['e045', false],
    slow_motion_video: ['e068', false],
    snooze: ['e046', false],
    sort_by_alpha: ['e053', false],
    stop: ['e047', false],
    subscriptions: ['e064', false],
    subtitles: ['e048', false],
    surround_sound: ['e049', false],
    video_call: ['e070', true],
    video_label: ['e071', true],
    video_library: ['e04a', false],
    videocam: ['e04b', false],
    videocam_off: ['e04c', false],
    volume_down: ['e04d', false],
    volume_mute: ['e04e', false],
    volume_off: ['e04f', false],
    volume_up: ['e050', false],
    web: ['e051', false],
    web_asset: ['e069', false]
  },
  communication: {
    business: ['e0af', false],
    call: ['e0b0', false],
    call_end: ['e0b1', false],
    call_made: ['e0b2', false],
    call_merge: ['e0b3', false],
    call_missed: ['e0b4', false],
    call_missed_outgoing: ['e0e4', false],
    call_received: ['e0b5', false],
    call_split: ['e0b6', false],
    chat: ['e0b7', false],
    chat_bubble: ['e0ca', false],
    chat_bubble_outline: ['e0cb', false],
    clear_all: ['e0b8', false],
    comment: ['e0b9', false],
    contact_mail: ['e0d0', false],
    contact_phone: ['e0cf', false],
    contacts: ['e0ba', false],
    dialer_sip: ['e0bb', false],
    dialpad: ['e0bc', false],
    email: ['e0be', false],
    forum: ['e0bf', false],
    import_contacts: ['e0e0', false],
    import_export: ['e0c3', false],
    invert_colors_off: ['e0c4', false],
    live_help: ['e0c6', false],
    location_off: ['e0c7', false],
    location_on: ['e0c8', false],
    mail_outline: ['e0e1', false],
    message: ['e0c9', false],
    no_sim: ['e0cc', false],
    phone: ['e0cd', false],
    phonelink_erase: ['e0db', false],
    phonelink_lock: ['e0dc', false],
    phonelink_ring: ['e0dd', false],
    phonelink_setup: ['e0de', false],
    portable_wifi_off: ['e0ce', false],
    present_to_all: ['e0df', false],
    ring_volume: ['e0d1', false],
    rss_feed: ['e0e5', true],
    screen_share: ['e0e2', false],
    speaker_phone: ['e0d2', false],
    stay_current_landscape: ['e0d3', false],
    stay_current_portrait: ['e0d4', false],
    stay_primary_landscape: ['e0d5', false],
    stay_primary_portrait: ['e0d6', false],
    stop_screen_share: ['e0e3', false],
    swap_calls: ['e0d7', false],
    textsms: ['e0d8', false],
    voicemail: ['e0d9', false],
    vpn_key: ['e0da', false]
  },
  content: {
    add: ['e145', false],
    add_box: ['e146', false],
    add_circle: ['e147', false],
    add_circle_outline: ['e148', false],
    archive: ['e149', false],
    backspace: ['e14a', false],
    block: ['e14b', false],
    clear: ['e14c', false],
    content_copy: ['e14d', false],
    content_cut: ['e14e', false],
    content_paste: ['e14f', false],
    create: ['e150', false],
    delete_sweep: ['e16c', true],
    drafts: ['e151', false],
    filter_list: ['e152', false],
    flag: ['e153', false],
    font_download: ['e167', false],
    forward: ['e154', false],
    gesture: ['e155', false],
    inbox: ['e156', false],
    link: ['e157', false],
    low_priority: ['e16d', true],
    mail: ['e158', false],
    markunread: ['e159', false],
    move_to_inbox: ['e168', false],
    next_week: ['e16a', false],
    redo: ['e15a', false],
    remove: ['e15b', false],
    remove_circle: ['e15c', false],
    remove_circle_outline: ['e15d', false],
    reply: ['e15e', false],
    reply_all: ['e15f', false],
    report: ['e160', false],
    save: ['e161', false],
    select_all: ['e162', false],
    send: ['e163', false],
    sort: ['e164', false],
    text_format: ['e165', false],
    unarchive: ['e169', false],
    undo: ['e166', false],
    weekend: ['e16b', false]
  },
  device: {
    access_alarm: ['e190', false],
    access_alarms: ['e191', false],
    access_time: ['e192', false],
    add_alarm: ['e193', false],
    airplanemode_active: ['e195', false],
    airplanemode_inactive: ['e194', false],
    battery_alert: ['e19c', false],
    battery_charging_full: ['e1a3', false],
    battery_full: ['e1a4', false],
    battery_std: ['e1a5', false],
    battery_unknown: ['e1a6', false],
    bluetooth: ['e1a7', false],
    bluetooth_connected: ['e1a8', false],
    bluetooth_disabled: ['e1a9', false],
    bluetooth_searching: ['e1aa', false],
    brightness_auto: ['e1ab', false],
    brightness_high: ['e1ac', false],
    brightness_low: ['e1ad', false],
    brightness_medium: ['e1ae', false],
    data_usage: ['e1af', false],
    developer_mode: ['e1b0', false],
    devices: ['e1b1', false],
    dvr: ['e1b2', false],
    gps_fixed: ['e1b3', false],
    gps_not_fixed: ['e1b4', false],
    gps_off: ['e1b5', false],
    graphic_eq: ['e1b8', false],
    location_disabled: ['e1b6', false],
    location_searching: ['e1b7', false],
    network_cell: ['e1b9', false],
    network_wifi: ['e1ba', false],
    nfc: ['e1bb', false],
    screen_lock_landscape: ['e1be', false],
    screen_lock_portrait: ['e1bf', false],
    screen_lock_rotation: ['e1c0', false],
    screen_rotation: ['e1c1', false],
    sd_storage: ['e1c2', false],
    settings_system_daydream: ['e1c3', false],
    signal_cellular_4_bar: ['e1c8', false],
    signal_cellular_connected_no_internet_4_bar: ['e1cd', false],
    signal_cellular_no_sim: ['e1ce', false],
    signal_cellular_null: ['e1cf', false],
    signal_cellular_off: ['e1d0', false],
    signal_wifi_4_bar: ['e1d8', false],
    signal_wifi_4_bar_lock: ['e1d9', false],
    signal_wifi_off: ['e1da', false],
    storage: ['e1db', false],
    usb: ['e1e0', false],
    wallpaper: ['e1bc', false],
    widgets: ['e1bd', false],
    wifi_lock: ['e1e1', false],
    wifi_tethering: ['e1e2', false]
  },
  editor: {
    attach_file: ['e226', false],
    attach_money: ['e227', false],
    border_all: ['e228', false],
    border_bottom: ['e229', false],
    border_clear: ['e22a', false],
    border_color: ['e22b', false],
    border_horizontal: ['e22c', false],
    border_inner: ['e22d', false],
    border_left: ['e22e', false],
    border_outer: ['e22f', false],
    border_right: ['e230', false],
    border_style: ['e231', false],
    border_top: ['e232', false],
    border_vertical: ['e233', false],
    bubble_chart: ['e6dd', true],
    drag_handle: ['e25d', false],
    format_align_center: ['e234', false],
    format_align_justify: ['e235', false],
    format_align_left: ['e236', false],
    format_align_right: ['e237', false],
    format_bold: ['e238', false],
    format_clear: ['e239', false],
    format_color_fill: ['e23a', false],
    format_color_reset: ['e23b', false],
    format_color_text: ['e23c', false],
    format_indent_decrease: ['e23d', false],
    format_indent_increase: ['e23e', false],
    format_italic: ['e23f', false],
    format_line_spacing: ['e240', false],
    format_list_bulleted: ['e241', false],
    format_list_numbered: ['e242', false],
    format_paint: ['e243', false],
    format_quote: ['e244', false],
    format_shapes: ['e25e', false],
    format_size: ['e245', false],
    format_strikethrough: ['e246', false],
    format_textdirection_l_to_r: ['e247', false],
    format_textdirection_r_to_l: ['e248', false],
    format_underlined: ['e249', false],
    functions: ['e24a', false],
    highlight: ['e25f', false],
    insert_chart: ['e24b', false],
    insert_comment: ['e24c', false],
    insert_drive_file: ['e24d', false],
    insert_emoticon: ['e24e', false],
    insert_invitation: ['e24f', false],
    insert_link: ['e250', false],
    insert_photo: ['e251', false],
    linear_scale: ['e260', false],
    merge_type: ['e252', false],
    mode_comment: ['e253', false],
    mode_edit: ['e254', false],
    monetization_on: ['e263', true],
    money_off: ['e25c', false],
    multiline_chart: ['e6df', true],
    pie_chart: ['e6c4', true],
    pie_chart_outlined: ['e6c5', true],
    publish: ['e255', false],
    short_text: ['e261', false],
    show_chart: ['e6e1', true],
    space_bar: ['e256', false],
    strikethrough_s: ['e257', false],
    text_fields: ['e262', false],
    title: ['e264', true],
    vertical_align_bottom: ['e258', false],
    vertical_align_center: ['e259', false],
    vertical_align_top: ['e25a', false],
    wrap_text: ['e25b', false]
  },
  file: {
    attachment: ['e2bc', false],
    cloud: ['e2bd', false],
    cloud_circle: ['e2be', false],
    cloud_done: ['e2bf', false],
    cloud_download: ['e2c0', false],
    cloud_off: ['e2c1', false],
    cloud_queue: ['e2c2', false],
    cloud_upload: ['e2c3', false],
    create_new_folder: ['e2cc', false],
    file_download: ['e2c4', false],
    file_upload: ['e2c6', false],
    folder: ['e2c7', false],
    folder_open: ['e2c8', false],
    folder_shared: ['e2c9', false]
  },
  hardware: {
    cast: ['e307', false],
    cast_connected: ['e308', false],
    computer: ['e30a', false],
    desktop_mac: ['e30b', false],
    desktop_windows: ['e30c', false],
    developer_board: ['e30d', false],
    device_hub: ['e335', false],
    devices_other: ['e337', false],
    dock: ['e30e', false],
    gamepad: ['e30f', false],
    headset: ['e310', false],
    headset_mic: ['e311', false],
    keyboard: ['e312', false],
    keyboard_arrow_down: ['e313', false],
    keyboard_arrow_left: ['e314', false],
    keyboard_arrow_right: ['e315', false],
    keyboard_arrow_up: ['e316', false],
    keyboard_backspace: ['e317', false],
    keyboard_capslock: ['e318', false],
    keyboard_hide: ['e31a', false],
    keyboard_return: ['e31b', false],
    keyboard_tab: ['e31c', false],
    keyboard_voice: ['e31d', false],
    laptop: ['e31e', false],
    laptop_chromebook: ['e31f', false],
    laptop_mac: ['e320', false],
    laptop_windows: ['e321', false],
    memory: ['e322', false],
    mouse: ['e323', false],
    phone_android: ['e324', false],
    phone_iphone: ['e325', false],
    phonelink: ['e326', false],
    phonelink_off: ['e327', false],
    power_input: ['e336', false],
    router: ['e328', false],
    scanner: ['e329', false],
    security: ['e32a', false],
    sim_card: ['e32b', false],
    smartphone: ['e32c', false],
    speaker: ['e32d', false],
    speaker_group: ['e32e', false],
    tablet: ['e32f', false],
    tablet_android: ['e330', false],
    tablet_mac: ['e331', false],
    toys: ['e332', false],
    tv: ['e333', false],
    videogame_asset: ['e338', false],
    watch: ['e334', false]
  },
  image: {
    add_a_photo: ['e439', false],
    add_to_photos: ['e39d', false],
    adjust: ['e39e', false],
    assistant: ['e39f', false],
    assistant_photo: ['e3a0', false],
    audiotrack: ['e3a1', false],
    blur_circular: ['e3a2', false],
    blur_linear: ['e3a3', false],
    blur_off: ['e3a4', false],
    blur_on: ['e3a5', false],
    brightness_1: ['e3a6', false],
    brightness_2: ['e3a7', false],
    brightness_3: ['e3a8', false],
    brightness_4: ['e3a9', false],
    brightness_5: ['e3aa', false],
    brightness_6: ['e3ab', false],
    brightness_7: ['e3ac', false],
    broken_image: ['e3ad', false],
    brush: ['e3ae', false],
    burst_mode: ['e43c', true],
    camera: ['e3af', false],
    camera_alt: ['e3b0', false],
    camera_front: ['e3b1', false],
    camera_rear: ['e3b2', false],
    camera_roll: ['e3b3', false],
    center_focus_strong: ['e3b4', false],
    center_focus_weak: ['e3b5', false],
    collections: ['e3b6', false],
    collections_bookmark: ['e431', false],
    color_lens: ['e3b7', false],
    colorize: ['e3b8', false],
    compare: ['e3b9', false],
    control_point: ['e3ba', false],
    control_point_duplicate: ['e3bb', false],
    crop_16_9: ['e3bc', false],
    crop: ['e3be', false],
    crop_3_2: ['e3bd', false],
    crop_5_4: ['e3bf', false],
    crop_7_5: ['e3c0', false],
    crop_din: ['e3c1', false],
    crop_free: ['e3c2', false],
    crop_landscape: ['e3c3', false],
    crop_original: ['e3c4', false],
    crop_portrait: ['e3c5', false],
    crop_rotate: ['e437', false],
    crop_square: ['e3c6', false],
    dehaze: ['e3c7', false],
    details: ['e3c8', false],
    edit: ['e3c9', false],
    exposure: ['e3ca', false],
    exposure_neg_1: ['e3cb', false],
    exposure_neg_2: ['e3cc', false],
    exposure_plus_1: ['e3cd', false],
    exposure_plus_2: ['e3ce', false],
    exposure_zero: ['e3cf', false],
    filter_1: ['e3d0', false],
    filter: ['e3d3', false],
    filter_2: ['e3d1', false],
    filter_3: ['e3d2', false],
    filter_4: ['e3d4', false],
    filter_5: ['e3d5', false],
    filter_6: ['e3d6', false],
    filter_7: ['e3d7', false],
    filter_8: ['e3d8', false],
    filter_9: ['e3d9', false],
    filter_9_plus: ['e3da', false],
    filter_b_and_w: ['e3db', false],
    filter_center_focus: ['e3dc', false],
    filter_drama: ['e3dd', false],
    filter_frames: ['e3de', false],
    filter_hdr: ['e3df', false],
    filter_none: ['e3e0', false],
    filter_tilt_shift: ['e3e2', false],
    filter_vintage: ['e3e3', false],
    flare: ['e3e4', false],
    flash_auto: ['e3e5', false],
    flash_off: ['e3e6', false],
    flash_on: ['e3e7', false],
    flip: ['e3e8', false],
    gradient: ['e3e9', false],
    grain: ['e3ea', false],
    grid_off: ['e3eb', false],
    grid_on: ['e3ec', false],
    hdr_off: ['e3ed', false],
    hdr_on: ['e3ee', false],
    hdr_strong: ['e3f1', false],
    hdr_weak: ['e3f2', false],
    healing: ['e3f3', false],
    image: ['e3f4', false],
    image_aspect_ratio: ['e3f5', false],
    iso: ['e3f6', false],
    landscape: ['e3f7', false],
    leak_add: ['e3f8', false],
    leak_remove: ['e3f9', false],
    lens: ['e3fa', false],
    linked_camera: ['e438', false],
    looks: ['e3fc', false],
    looks_3: ['e3fb', false],
    looks_4: ['e3fd', false],
    looks_5: ['e3fe', false],
    looks_6: ['e3ff', false],
    looks_one: ['e400', false],
    looks_two: ['e401', false],
    loupe: ['e402', false],
    monochrome_photos: ['e403', false],
    movie_creation: ['e404', false],
    movie_filter: ['e43a', false],
    music_note: ['e405', false],
    nature: ['e406', false],
    nature_people: ['e407', false],
    navigate_before: ['e408', false],
    navigate_next: ['e409', false],
    palette: ['e40a', false],
    panorama: ['e40b', false],
    panorama_fish_eye: ['e40c', false],
    panorama_horizontal: ['e40d', false],
    panorama_vertical: ['e40e', false],
    panorama_wide_angle: ['e40f', false],
    photo: ['e410', false],
    photo_album: ['e411', false],
    photo_camera: ['e412', false],
    photo_filter: ['e43b', false],
    photo_library: ['e413', false],
    photo_size_select_actual: ['e432', false],
    photo_size_select_large: ['e433', false],
    photo_size_select_small: ['e434', false],
    picture_as_pdf: ['e415', false],
    portrait: ['e416', false],
    remove_red_eye: ['e417', false],
    rotate_90_degrees_ccw: ['e418', false],
    rotate_left: ['e419', false],
    rotate_right: ['e41a', false],
    slideshow: ['e41b', false],
    straighten: ['e41c', false],
    style: ['e41d', false],
    switch_camera: ['e41e', false],
    switch_video: ['e41f', false],
    tag_faces: ['e420', false],
    texture: ['e421', false],
    timelapse: ['e422', false],
    timer_10: ['e423', false],
    timer: ['e425', false],
    timer_3: ['e424', false],
    timer_off: ['e426', false],
    tonality: ['e427', false],
    transform: ['e428', false],
    tune: ['e429', false],
    view_comfy: ['e42a', false],
    view_compact: ['e42b', false],
    vignette: ['e435', false],
    wb_auto: ['e42c', false],
    wb_cloudy: ['e42d', false],
    wb_incandescent: ['e42e', false],
    wb_iridescent: ['e436', false],
    wb_sunny: ['e430', false]
  },
  maps: {
    add_location: ['e567', false],
    beenhere: ['e52d', false],
    directions: ['e52e', false],
    directions_bike: ['e52f', false],
    directions_boat: ['e532', false],
    directions_bus: ['e530', false],
    directions_car: ['e531', false],
    directions_railway: ['e534', false],
    directions_run: ['e566', false],
    directions_subway: ['e533', false],
    directions_transit: ['e535', false],
    directions_walk: ['e536', false],
    edit_location: ['e568', false],
    ev_station: ['e56d', true],
    flight: ['e539', false],
    hotel: ['e53a', false],
    layers: ['e53b', false],
    layers_clear: ['e53c', false],
    local_activity: ['e53f', false],
    local_airport: ['e53d', false],
    local_atm: ['e53e', false],
    local_bar: ['e540', false],
    local_cafe: ['e541', false],
    local_car_wash: ['e542', false],
    local_convenience_store: ['e543', false],
    local_dining: ['e556', false],
    local_drink: ['e544', false],
    local_florist: ['e545', false],
    local_gas_station: ['e546', false],
    local_grocery_store: ['e547', false],
    local_hospital: ['e548', false],
    local_hotel: ['e549', false],
    local_laundry_service: ['e54a', false],
    local_library: ['e54b', false],
    local_mall: ['e54c', false],
    local_movies: ['e54d', false],
    local_offer: ['e54e', false],
    local_parking: ['e54f', false],
    local_pharmacy: ['e550', false],
    local_phone: ['e551', false],
    local_pizza: ['e552', false],
    local_play: ['e553', false],
    local_post_office: ['e554', false],
    local_printshop: ['e555', false],
    local_see: ['e557', false],
    local_shipping: ['e558', false],
    local_taxi: ['e559', false],
    map: ['e55b', false],
    my_location: ['e55c', false],
    navigation: ['e55d', false],
    near_me: ['e569', false],
    person_pin: ['e55a', false],
    person_pin_circle: ['e56a', false],
    pin_drop: ['e55e', false],
    place: ['e55f', false],
    rate_review: ['e560', false],
    restaurant: ['e56c', true],
    restaurant_menu: ['e561', false],
    satellite: ['e562', false],
    store_mall_directory: ['e563', false],
    streetview: ['e56e', true],
    subway: ['e56f', true],
    terrain: ['e564', false],
    traffic: ['e565', false],
    train: ['e570', true],
    tram: ['e571', true],
    transfer_within_a_station: ['e572', true],
    zoom_out_map: ['e56b', false]
  },
  navigation: {
    apps: ['e5c3', false],
    arrow_back: ['e5c4', false],
    arrow_downward: ['e5db', false],
    arrow_drop_down: ['e5c5', false],
    arrow_drop_down_circle: ['e5c6', false],
    arrow_drop_up: ['e5c7', false],
    arrow_forward: ['e5c8', false],
    arrow_upward: ['e5d8', false],
    cancel: ['e5c9', false],
    check: ['e5ca', false],
    chevron_left: ['e5cb', false],
    chevron_right: ['e5cc', false],
    close: ['e5cd', false],
    expand_less: ['e5ce', false],
    expand_more: ['e5cf', false],
    first_page: ['e5dc', true],
    fullscreen: ['e5d0', false],
    fullscreen_exit: ['e5d1', false],
    last_page: ['e5dd', true],
    menu: ['e5d2', false],
    more_horiz: ['e5d3', false],
    more_vert: ['e5d4', false],
    refresh: ['e5d5', false],
    subdirectory_arrow_left: ['e5d9', false],
    subdirectory_arrow_right: ['e5da', false],
    unfold_less: ['e5d6', false],
    unfold_more: ['e5d7', false]
  },
  notification: {
    adb: ['e60e', false],
    airline_seat_flat: ['e630', false],
    airline_seat_flat_angled: ['e631', false],
    airline_seat_individual_suite: ['e632', false],
    airline_seat_legroom_extra: ['e633', false],
    airline_seat_legroom_normal: ['e634', false],
    airline_seat_legroom_reduced: ['e635', false],
    airline_seat_recline_extra: ['e636', false],
    airline_seat_recline_normal: ['e637', false],
    bluetooth_audio: ['e60f', false],
    confirmation_number: ['e638', false],
    disc_full: ['e610', false],
    do_not_disturb: ['e612', false],
    do_not_disturb_alt: ['e611', false],
    do_not_disturb_off: ['e643', true],
    do_not_disturb_on: ['e644', true],
    drive_eta: ['e613', false],
    enhanced_encryption: ['e63f', false],
    event_available: ['e614', false],
    event_busy: ['e615', false],
    event_note: ['e616', false],
    folder_special: ['e617', false],
    live_tv: ['e639', false],
    mms: ['e618', false],
    more: ['e619', false],
    network_check: ['e640', false],
    network_locked: ['e61a', false],
    no_encryption: ['e641', false],
    ondemand_video: ['e63a', false],
    personal_video: ['e63b', false],
    phone_bluetooth_speaker: ['e61b', false],
    phone_forwarded: ['e61c', false],
    phone_in_talk: ['e61d', false],
    phone_locked: ['e61e', false],
    phone_missed: ['e61f', false],
    phone_paused: ['e620', false],
    power: ['e63c', false],
    priority_high: ['e645', true],
    rv_hookup: ['e642', false],
    sd_card: ['e623', false],
    sim_card_alert: ['e624', false],
    sms: ['e625', false],
    sms_failed: ['e626', false],
    sync: ['e627', false],
    sync_disabled: ['e628', false],
    sync_problem: ['e629', false],
    system_update: ['e62a', false],
    tap_and_play: ['e62b', false],
    time_to_leave: ['e62c', false],
    vibration: ['e62d', false],
    voice_chat: ['e62e', false],
    vpn_lock: ['e62f', false],
    wc: ['e63d', false],
    wifi: ['e63e', false]
  },
  places: {
    ac_unit: ['eb3b', false],
    airport_shuttle: ['eb3c', false],
    all_inclusive: ['eb3d', false],
    beach_access: ['eb3e', false],
    business_center: ['eb3f', false],
    casino: ['eb40', false],
    child_care: ['eb41', false],
    child_friendly: ['eb42', false],
    fitness_center: ['eb43', false],
    free_breakfast: ['eb44', false],
    golf_course: ['eb45', false],
    hot_tub: ['eb46', false],
    kitchen: ['eb47', false],
    pool: ['eb48', false],
    room_service: ['eb49', false],
    rv_hookup: ['e642', false],
    smoke_free: ['eb4a', false],
    smoking_rooms: ['eb4b', false],
    spa: ['eb4c', false]
  },
  social: {
    cake: ['e7e9', false],
    domain: ['e7ee', false],
    group: ['e7ef', false],
    group_add: ['e7f0', false],
    location_city: ['e7f1', false],
    mood: ['e7f2', false],
    mood_bad: ['e7f3', false],
    notifications: ['e7f4', false],
    notifications_active: ['e7f7', false],
    notifications_none: ['e7f5', false],
    notifications_off: ['e7f6', false],
    notifications_paused: ['e7f8', false],
    pages: ['e7f9', false],
    party_mode: ['e7fa', false],
    people: ['e7fb', false],
    people_outline: ['e7fc', false],
    person: ['e7fd', false],
    person_add: ['e7fe', false],
    person_outline: ['e7ff', false],
    plus_one: ['e800', false],
    poll: ['e801', false],
    public: ['e80b', false],
    school: ['e80c', false],
    sentiment_dissatisfied: ['e811', true],
    sentiment_neutral: ['e812', true],
    sentiment_satisfied: ['e813', true],
    sentiment_very_dissatisfied: ['e814', true],
    sentiment_very_satisfied: ['e815', true],
    share: ['e80d', false],
    whatshot: ['e80e', false]
  },
  toggle: {
    check_box: ['e834', false],
    check_box_outline_blank: ['e835', false],
    indeterminate_check_box: ['e909', false],
    radio_button_checked: ['e837', false],
    radio_button_unchecked: ['e836', false],
    star: ['e838', false],
    star_border: ['e83a', false],
    star_half: ['e839', false]
  }
}

export default material_icons
