import React from 'react'
import Leaf from './Leaf'

const EmphasisElement: React.FC<Record<string, any>> = (
  props: Record<string, any>
) => {
  return (
    <Leaf leaf={{ italic: true }} {...props.attributes}>
      {props.children}
    </Leaf>
  )
}

export default EmphasisElement
