import { I18n } from 'aws-amplify'

let language = ''
// @ts-ignore
const navLang = navigator.language || navigator.userLanguage

if (/en-en/gi.test(document.cookie)) {
  language = 'en-En'
} else if (/fr-fr/gi.test(document.cookie)) {
  language = 'fr-FR'
} else if (/en-en/gi.test(navLang) || /fr-fr/gi.test(navLang)) {
  language = navLang
} else {
  language = 'en-En'
}

export const setLanguage = (lng = language) => {
  I18n.setLanguage(lng)
}

export const getLocale = () => {
  return language.replace('-', '_')
}
