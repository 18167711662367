import {
  ConditionV3,
  ModelV3,
  OptionV3,
  SectionV3,
  VariableV3
} from '@legalplace/models-v3-types'
import { Editor } from '@legalplace/slate'
import { HistoryEditor } from '@legalplace/slate-history'
import { ReactEditor } from '@legalplace/slate-react'
import { I18n } from 'aws-amplify'
import { cloneDeep } from 'lodash'
import { action, computed, observable, runInAction, toJS } from 'mobx'
import { toast, cssTransition } from 'react-toastify'
import slugify from 'slugify'
import { go } from '../../utils/history'
import {
  CHECKICON,
  FIELDICON,
  LISTICON,
  RADIOICON
} from '../../components/Icons/icons'
import api from '../../utils/api'
import {
  checkbox,
  dropdownMenu,
  field,
  question,
  radioBox,
  text
} from '../../utils/elements'
import { runHealthCheck } from './healthCheck'
import { catchAction, toastError } from './catchActionsDecorator'
import { traceHistory } from './traceHistoryDecorator'
import deepObjectSetValue from '../../components/ParamsModal/helpers/deepObjectSetValue'
import SafeDelete from './safeDelete'
import CopySection from './copySection'
import UnlinkDelete from './unlinkDelete'
import SlateEditor from '../../pages/Editor/CreateContractPage/slate-editor'
import promptModal from '../../components/PromptPortal/PromptPortal'
import { PromptPublishedContent } from './promptPublished'
import authStore from '../authStore'

const dictionary = {
  fr: {
    'section title': 'Titre de la section',
    'Title of the section': 'Titre de la section',
    None: 'Aucun',

    'box list': 'Boite info',
    'static list': 'Question',
    'checkbox list': 'Case à cocher',
    'radio list': 'Radio',
    'list list': 'Liste déroulante',
    'field list': 'Champ',

    'none list': 'Par défaut',
    full: 'En-dessous',
    half: 'A droite',
    'full opt': 'Large',
    'half opt': 'Moitié',
    'calculator list': 'Calculatrice',
    'text 2': 'Texte',
    'number list': 'Nombre',
    'date list': 'Date',
    'hour list': 'Heure',
    'email list': 'Email',
    'multi-line field list': 'Champ multiligne',
    'advanced mask list': 'Masque avancé',

    'Is checked': 'Est sélectionné',
    'Is not checked': 'Non sélectionné mais affiché',
    'Is not checked (even hidden)': "N'est pas sélectionné",
    'Is checked at least once': 'Au moins une sélection',
    Is: 'Est',
    'Is not': "N'est pas",
    'Is empty': 'Est vide',
    'Is not empty': "N'est pas vide",
    'Is not like': 'Ne contient pas',
    'Is like': 'Contient',
    'Has one value': 'A une seule occurence',
    'Has more than one value': 'A plusieurs occurences',
    Equals: '=',
    'Does not equals': '<>',
    'Strictly inferior': '<',
    'Strictly superior': '>',
    'Superior or equal': '>=',
    'Inferior of equal': '=<',
    success_autosaved_data: 'Le modèle a été sauvegardé automatiquement',
    success_saved_data: "Modèle sauvegardé à l'instant !",
    failed_autosaved_data: 'La sauvegarde automatique a échoue',
    failed_saved_data: 'La sauvegarde a échoué',
    'This element is a condition of ': 'Cet élément conditionne la',
    'field alt text': 'Cet élément conditionne le',
    'This element is a condition of output starting with':
      'Cet élément conditionne le contenu commençant par',
    model_creation_success: 'Le nouveau modèle a été créé avec succès',
    model_creation_failed: 'La création du nouveau modèle a échoué',
    new_secondary_document: 'Un nouveau document secondaire a été ajouté',
    delete_secondary_document: 'Le document secondaire a été supprimé',
    success_copy_element: "L'élément a été duppliqué",
    EDIT: 'MODIFIER',
    'Warning !!!': 'ATTENTION !!!'
  },
  en: {
    'section title': 'Section title',
    'Title of the section': 'Title of the section',
    None: 'None',
    'none list': 'None',
    full: 'Full',
    half: 'Half',
    'full opt': 'Full',
    'half opt': 'Half',

    'calculator list': 'Calculator',
    'number list': 'Number',
    'date list': 'Date',
    'hour list': 'Hour',
    'email list': 'Email',
    'multi-line field list': 'Multi-line field',
    'advanced mask list': 'Advanced mask',

    'text 2': 'Text',
    'box list': 'Text',
    'static list': 'Question',
    'checkbox list': 'Checkbox',
    'radio list': 'Radio',
    'list list': 'DropDown',
    'field list': 'Field',

    'Is checked': 'Is checked',
    'Is not checked': 'Not checked but displayed',
    'Is not checked (even hidden)': 'Is not checked',
    'Is checked at least once': 'Is checked at least once',
    Is: 'Is',
    'Is not': 'Is not',
    'Has one value': 'Has one value',
    'Has more than one value': 'Has more than one value',
    Equals: 'Equals',
    'Does not equals': 'Does not equals',
    'Strictly inferior': 'Strictly inferior',
    'Strictly superior': 'Strictly superior',
    'Superior or equal': 'Superior or equal',
    'Inferior of equal': 'Inferior of equal',
    success_autosaved_data: 'Model has been autosaved successfully !',
    success_saved_data: 'Model has been saved successfully !',
    failed_autosaved_data: 'Model autosave has failed',
    failed_saved_data: 'Model save as failed',

    'This element is a condition of ': 'This element is a condition of',
    'This element is a condition of output starting with':
      'This element is a condition of output starting with',
    'field alt text': 'This element is a condition of output starting with',
    model_creation_success: 'Model creation succeeded',
    model_creation_failed: 'Model creation failed',
    new_secondary_document: 'New secondary document created',
    delete_secondary_document: 'Secondary document deleted',
    success_copy_element: 'Element is duplicated'
  }
}

I18n.putVocabularies(dictionary)

type ConditionsPartsType =
  | ConditionV3
  | ConditionV3[]
  | [
      {
        var: string
      },
      (string | number | boolean)?,
      {
        var: string
      }[]?
    ]
  | {
      var: string
    }
  | string

interface IParent {
  variables?: any[]
  children?: any[]
  [k: string]: any
}

export interface INote {
  content: string
  author: string
  timestamp: number
}

export interface IFormElement {
  type: string
  id: number
  parentId: null | number
  // groupId: string;
  content: string
  order: number | any
  helper: boolean | any
  label: string | any
  conditional: number | any
  secondStepOnly: boolean | any
  mandatory: boolean | any
  format: string | any
  placeholder: string | any
  mask: string | any
  decimals: string | any
  CTA: boolean | any
  autocomplete: string | any
  advanced_mask: string | any
  formula: string | any
  num_decimal: number | any
  round: string | any
  condition_of: any
  dropdown_values: any[] | any
  is_Multiple: boolean | any
  multiple_button_label: string | any
  multiple_label: string | any
  multiple_first_num: number | any
  multiple_min_occurences: number | any
  multiple_max_occurences: number | any
  helper_Content: string | any
  warning: any
  secondStep: any
  color: string
  placed: boolean
}

interface IMetaDataElem {
  placed: boolean
  id: number
  color: string
  openedInnerElements: boolean
  parentId: number
  [k: string]: any
}

declare type IMetaData = Record<number, IMetaDataElem>

type IMoveElement = {
  isMoving: boolean
  type: 'option' | 'variable'
  id: number
  currentParentType: 'option' | 'section'
  currentParent: number
  toggleDrawer?: (v: boolean) => void
}

export interface IForm {
  id: string
  color: string
  label: string
  selected: boolean
  formElements: any
  type: string
}

export interface ISection {
  sectionId: number
  sectionTitle: string
  form: IForm[]
}

export interface IDocSection {
  id: number
  label: string
  options: number[]
}

type IHistoryState = {
  prevState: ModelV3
  sectionIndex: number
  prevSectionIndex: number
  state: ModelV3
  action: string
  currentDocument: string
  prevCurrentDocument: string
}
type IHistorySlate = {
  slateHistory: number
}
export type IHistory = IHistoryState | IHistorySlate

interface IParagraph {
  paragraph: string
  start: number
  length: number
}

class CreateStore implements ICreateStore {
  @observable lastSavedModelTime: number = 0

  @observable autosaveModelDataInterval: ReturnType<
    typeof setInterval
  > | null = null

  @observable enableAutosaveModelDataInterval: boolean = false

  @observable pauseAutosaveModelDataInterval: boolean = false

  @observable slateEditor: (Editor & ReactEditor & HistoryEditor) | null = null

  @observable slateEditorPushHistory: boolean = true

  @observable slateEditorVariablePopover: boolean = false

  @observable contractTemplate = ''

  @observable form: IForm[] = []

  @observable idSectionCounter: number = 1

  @observable sections: ISection[] = [
    {
      sectionId: this.idSectionCounter,
      sectionTitle: 'Section Title',
      form: []
    }
  ]

  @observable currentDocumentOutputs: {
    option: OptionV3
    children: OptionV3[]
  }[] = [
    {
      option: {
        meta: { id: 1, type: 'hidden', label: '', output: '', step: '*' },
        options: [],
        variables: []
      },
      children: []
    }
  ]

  @observable history: IHistory[] = []

  @observable modelNotes: INote[] = []

  @observable currentUserEmail: string = 'unkown@unkown'

  @observable currentSectionId: number = 0

  @observable currentDocument: string = 'main'

  @observable currentState: number = -1

  @observable idCounter: number = 2

  @observable selectedElement: any = {}

  @observable selectedElementType: string = ''

  @observable conditionedElType: string = ''

  @observable prefillIndex: number = -1

  @observable variableId: number = -1

  @observable selectedElementIds: any[] = []

  @observable selectedElementPrefillings: any[] = []

  @observable extractionIsOpen: boolean = false

  @observable deleteConditionIsOpen: boolean = false

  @observable deletingElement: { id: number; type: 'o' | 'v' } | false = false

  @observable canUnlink: boolean = false

  @observable slateEditorRef: React.RefObject<SlateEditor> | null = null

  @observable selectOutputModalIsOpen: boolean = false

  @observable deleteConditionMessage: string[] = []

  @observable pdfEditorIsOpen: boolean = false

  @observable publishIsOpen: boolean = false

  @observable moveIsOpen: boolean = false

  @observable copySectionIsOpen: boolean = false

  @observable newModelIsOpen: boolean = false

  @observable isModelLoading: boolean = false

  @observable selectMetaModalIsOpen: boolean = false

  @observable copySecondaryDocumentIsOpen: boolean = false

  @observable prefillIsOpen: boolean = false

  @observable modelOptionsIsOpen: boolean = false

  @observable documentParamsIsOpen: boolean = false

  @observable multiplesIsOpen: boolean = false

  @observable hasCondition: boolean = false

  @observable slateSelection: any = {}

  @observable slateStartBlock: any = {}

  @observable slatePrevBlock: any = {}

  @observable showPlusIcon: boolean = false

  @observable showHeading: boolean = false

  @observable paramsWasShown: boolean = false

  @observable docParamsWasShown: boolean = false

  @observable modelParamsWasShown: boolean = false

  @observable modelParamsTab: string = 'default'

  @observable prefillWasShown: boolean = false

  @observable editingValidators: boolean = false

  @observable deleteValidationIsOpen: boolean = false

  @observable deleteValidationData: any = {}

  @observable deleteValidationCallback: (() => void) | undefined = undefined

  @observable conditionIndexes: any[] = []

  @observable anchorBlock: any = {}

  @observable modelId: number = -1

  @observable editorIsLoading: boolean = false

  @observable duplicateModel: boolean = false

  @observable slateTemplate: any = null

  @observable meta_permalink: any = null

  @observable version: number = -1

  @observable templateName: string = ''

  @observable templateId: number = -1

  @observable modelState: number = -1

  @observable selectedBlocks: number[] = []

  @observable conditionsBuffer: any = {}

  @observable documentToCopySection: any = {}

  @observable conditionedEl: any = {
    destinationEl: {
      meta: {
        conditions: {
          and: []
        }
      }
    },
    sectionIndex: -1
  }

  @observable selectedParagraph: IParagraph = {
    paragraph: '',
    start: -1,
    length: -1
  }

  @observable metaData: IMetaData = {}

  @observable moveElement: IMoveElement = {
    isMoving: false,
    id: 0,
    currentParent: 0,
    type: 'option',
    currentParentType: 'option'
  }

  // Will store last saved model
  // in order to check whether
  // changes were made and whether
  // we need to save model again
  @observable lastSavedDocument: string = '{}'

  // new structure impl
  @observable document: ModelV3 = {
    documents: {
      main: {
        name: 'main',
        sections: [
          {
            id: 1,
            label: I18n.get('Title of the section'),
            options: [1]
          }
        ]
      }
    },
    options: {
      1: {
        meta: {
          type: 'hidden',
          label: 'Text de la question',
          output: '<p></p>',
          // default: "0",
          step: '*',
          id: 1
        },
        variables: [],
        options: []
      }
    },
    variables: {},
    customization: {}
  }

  // new structure impl
  quill: any = null

  slate: any = null

  constructor() {
    this.editSection = this.editSection.bind(this)
    this.duplicateSection = this.duplicateSection.bind(this)
    this.addSection = this.addSection.bind(this)
    this.createFormElement = this.createFormElement.bind(this)
    this.deleteElement = this.deleteElement.bind(this)
    this.updateElement = this.updateElement.bind(this)
    this.deleteDocument = this.deleteDocument.bind(this)
    this.updateDocument = this.updateDocument.bind(this)
    this.setNewSectionIndex = this.setNewSectionIndex.bind(this)
    this.setNewOptionIndex = this.setNewOptionIndex.bind(this)
    this.setNewOptionInSectionIndex = this.setNewOptionInSectionIndex.bind(this)
  }

  setRef = (ref: any) => {
    this.slate = ref
  }
  // setRef = (ref: any) => this.quill = ref ? ref.editor : null;

  @computed get formElements() {
    // return all documents variables
    return this.document.variables
  }

  @computed get currentSection() {
    // return current section
    return this.document.documents.main.sections[this.currentSectionId]
  }

  @computed get sectionIndex(): any {
    // return current index
    return this.document.documents.main.sections.indexOf(this.currentSection)
  }

  @computed get modelCustomization(): ModelV3['customization'] {
    return this.document.customization
  }

  @computed get multiplesOptions() {
    // get all main doc options
    const allDocumentOptions = this.document.documents.main.sections.reduce(
      (acc: any, curEl: any) => {
        return acc.concat(curEl.options)
      },
      []
    )
    // collect all options with childs
    const collectedOptions = this.documentOptionsCollector(allDocumentOptions)

    const multiplesParents: any[] | { value: number; label: any }[] = [
      { value: 'none', label: I18n.get('None') }
    ]
    // eslint-disable-next-line
    collectedOptions.map((optId: number) => {
      if (
        this.document.options[optId] &&
        this.document.options[optId].meta &&
        this.document.options[optId].meta.multiple &&
        this.document.options[optId].meta.multiple?.enabled === true
      ) {
        multiplesParents.push({
          value: optId,
          label: this.document.options[optId].meta.label
        })
      }
    })
    // return all multiples parents
    return multiplesParents
  }

  @computed get varTypes() {
    return [
      {
        icon: {
          path: LISTICON,
          color: '#fff'
        },
        className: 'dropdown',
        value: 'list',
        label: I18n.get('list list')
      },
      {
        icon: {
          path: FIELDICON,
          color: '#fff'
        },
        className: 'field',
        value: 'text',
        label: I18n.get('field list')
      }
    ]
  }

  @computed get optTypes() {
    return [
      {
        icon: {
          color: '#fff'
        },
        className: 'text-item',
        value: 'box',
        label: I18n.get('box list')
      },
      {
        icon: {
          color: '#fff'
        },
        className: 'question',
        value: 'static',
        label: 'Question'
      },
      {
        icon: {
          path: CHECKICON,
          color: '#fff'
        },
        className: 'check',
        value: 'checkbox',
        label: I18n.get('checkbox list')
      }
    ]
  }

  @computed get rootOptTypes() {
    return [
      {
        icon: {
          color: '#fff'
        },
        className: 'text-item',
        value: 'box',
        label: I18n.get('box list')
      },
      {
        icon: {
          color: '#fff'
        },
        className: 'question',
        value: 'static',
        label: 'Question'
      },
      {
        icon: {
          path: CHECKICON,
          color: '#fff'
        },
        className: 'check',
        value: 'checkbox',
        label: I18n.get('checkbox list')
      },
      {
        icon: {
          path: RADIOICON,
          color: '#fff'
        },
        className: 'radio',
        value: 'radio',
        label: I18n.get('radio list')
      }
    ]
  }

  @computed get formatTextOptions() {
    return [
      {
        value: 's',
        label: I18n.get('small')
      },
      {
        value: 'm',
        label: I18n.get('medium')
      },
      {
        value: 'l',
        label: I18n.get('large')
      }
    ]
  }

  @computed get maskOptions() {
    return [
      {
        value: 'eval',
        label: I18n.get('calculator list')
      },
      {
        value: 'text',
        label: I18n.get('text 2')
      },
      {
        value: 'number',
        label: I18n.get('number list')
      },
      {
        value: 'date',
        label: I18n.get('date list')
      },
      {
        value: 'textarea',
        label: I18n.get('multi-line field list')
      },
      {
        value: 'mask',
        label: I18n.get('advanced mask list')
      },
      {
        value: 'hour',
        label: I18n.get('hour list')
      },
      {
        value: 'email',
        label: I18n.get('email list')
      }
    ]
  }

  @computed get boxTypes() {
    return [
      {
        value: 'light',
        label: 'Information'
      },
      {
        value: 'warning',
        label: 'Warning'
      },
      {
        value: 'error',
        label: 'Error'
      },
      {
        value: 'info',
        label: 'Guide'
      }
    ]
  }

  @computed get roundOptions() {
    return [
      {
        value: 'round',
        label: I18n.get('Default')
      },
      {
        value: 'ceil',
        label: I18n.get('Up')
      },
      {
        value: 'floor',
        label: I18n.get('Down')
      }
    ]
  }

  @computed get autocompleteOptions() {
    return [
      {
        value: 'First_name',
        label: 'First name'
      },
      {
        value: 'Last_name',
        label: 'Last name'
      },
      {
        value: 'Address',
        label: 'Address'
      },
      {
        value: 'phone number',
        label: 'phone_number'
      },
      {
        value: 'email',
        label: 'email'
      }
    ]
  }

  @computed get inputStyleOptions() {
    return [
      {
        value: '',
        label: I18n.get('none list')
      },
      {
        value: 'full',
        label: I18n.get('full opt')
      },
      {
        value: 'half',
        label: I18n.get('half opt')
      }
    ]
  }

  @computed get optionStyleOptions() {
    return [
      {
        value: '',
        label: I18n.get('none list')
      },
      {
        value: 'full',
        label: I18n.get('full')
      },
      {
        value: 'half',
        label: I18n.get('half')
      }
    ]
  }

  @computed get conditionOptions() {
    return [
      {
        value: 'selected',
        label: I18n.get('Is checked')
      },
      {
        value: 'not-selected',
        label: I18n.get('Is not checked')
      },
      {
        value: 'not-selected-ip',
        label: I18n.get('Is not checked (even hidden)')
      }
    ]
  }

  @computed get conditionOptionsExtended() {
    return [
      {
        value: 'selected',
        label: I18n.get('Is checked')
      },
      {
        value: 'not-selected',
        label: I18n.get('Is not checked')
      },
      {
        value: 'not-selected-ip',
        label: I18n.get('Is not checked (even hidden)')
      },
      {
        value: 'at-least-one',
        label: I18n.get('Is checked at least once')
      }
    ]
  }

  @computed get listConditionOptions() {
    return [
      {
        value: 'is',
        label: I18n.get('Is')
      },
      {
        value: 'not',
        label: I18n.get('Is not')
      },
      {
        value: 'like',
        label: I18n.get('Is like')
      },
      {
        value: 'not-like',
        label: I18n.get('Is not like')
      },
      {
        value: 'empty',
        label: I18n.get('Is empty')
      },
      {
        value: 'not-empty',
        label: I18n.get('Is not empty')
      }
    ]
  }

  @computed get variableConditionOptions() {
    return [
      {
        value: '=',
        label: I18n.get('Equals')
      },
      {
        value: '<>',
        label: I18n.get('Does not equals')
      },
      {
        value: '>',
        label: I18n.get('Strictly superior')
      },
      {
        value: '<',
        label: I18n.get('Strictly inferior')
      },
      {
        value: '>=',
        label: I18n.get('Superior or equal')
      },
      {
        value: '<=',
        label: I18n.get('Inferior of equal')
      }
    ]
  }

  @computed get multipleConditionOptions() {
    return [
      {
        value: 'has-one',
        label: I18n.get('Has one value')
      },
      {
        value: 'has-many',
        label: I18n.get('Has more than one value')
      }
    ]
  }

  @computed get autoComplete() {
    return Object.values(this.formElements)
      .filter((el) => this.createRegExp(el.id).test(this.contractTemplate))
      .map((el) => toJS(el))
  }

  @catchAction
  @action
  parentDropdownOptions = () => {
    let parentOptions: any[] = []
    if (!this.selectedElement.meta && !this.selectedElement.type) {
      // if selected element is section
      parentOptions = this.document.documents.main.sections.map(
        (section: IDocSection, i: number) => {
          return {
            value: i,
            label: section.label
          }
        }
      )
      return parentOptions
    }
    // get element id
    const selectedId =
      this.selectedElement && this.selectedElement.meta
        ? this.selectedElement.meta.id
        : this.selectedElement.id
    // check for option parent
    const parent = this.optionParent()
    let opts = []
    if (parent) {
      if (this.selectedElementType === 'option') {
        opts = [...parent.options]
        if (opts) {
          opts = opts.filter((opt: number) => opt !== selectedId)
          // get dropdown options for element with type "option"
          parentOptions = opts.map((opt: number, i: number) => {
            return {
              value: i,
              label: this.document.options[opt].meta.label
            }
          })
        }
      } else if (this.selectedElementType === 'variable') {
        opts = parent && [...parent.variables]
        if (opts) {
          opts = opts.filter((opt: number) => opt !== selectedId)
          // get dropdown options for element with type "variable"
          parentOptions = opts.map((opt: number, i: number) => {
            return {
              value: i,
              label: this.document.variables[opt].label
            }
          })
        }
      }
    } else {
      // if selected element is root element without parent
      const allSectionsOptions = this.document.documents.main.sections.reduce(
        (acc: any, curEl: any) => {
          return acc.concat(curEl.options)
        },
        []
      )

      parentOptions = []
      allSectionsOptions.forEach((opt: number) => {
        if (this.document.options[opt]) {
          const { id, type, box } = this.document.options[opt].meta
          let { label } = this.document.options[opt].meta
          if (['hidden', 'repeated'].includes(type)) return
          if (type === 'box' && box && box.content) {
            label =
              box.content.length > 45
                ? `${box.content.slice(0, 45)}...`
                : box.content
          }
          parentOptions.push({
            value: id,
            label
          })
        }
      })
    }
    return parentOptions
  }

  @catchAction
  @action
  optionParent = () => {
    // get selected element parent
    const selectedId =
      this.selectedElement && this.selectedElement.meta
        ? this.selectedElement.meta.id
        : this.selectedElement.id
    const type = this.selectedElement.meta ? 'option' : 'variable'
    let parent
    if (type === 'option') {
      parent = Object.values(this.document.options).find((opt: any) => {
        // set parent if option contains option as child
        return opt.options.find((child: number) => {
          return child === selectedId
        })
      })
      this.selectedElementType = 'option'
    } else {
      parent = Object.values(this.document.options).find((opt: any) => {
        // set parent if option contains variable as child
        return opt.variables.find((child: number) => {
          return child === selectedId
        })
      })
      this.selectedElementType = 'variable'
    }
    return parent
  }

  @catchAction
  @action
  onTemplateChange = (val: string) => {
    // need for quill editor
    this.contractTemplate = val
  }

  @catchAction
  @action
  setEditorIsLoading = (val: boolean) => {
    // editor loader state
    this.editorIsLoading = val
  }

  @traceHistory
  @catchAction
  @action
  setNewOptionIndex(index: number) {
    // move option/variable to another index
    // get current option parent
    const parent: IParent = this.optionParent() || {}
    const id = this.selectedElement.meta
      ? this.selectedElement.meta.id
      : this.selectedElement.id
    let elemIndex = -1
    if (this.selectedElementType === 'option' && parent.options) {
      // find element in parent children array
      parent.options.map((child: number, i: number) => {
        if (child === id) {
          elemIndex = i
        }
        return null
      })
      // remove element
      parent.options.splice(elemIndex, 1)
      // put element to the new index
      parent.options.splice(index + 1, 0, id)
    } else if (this.selectedElementType === 'variable' && parent.variables) {
      // find element in parent variables array
      parent.variables.map((child: number, i: number) => {
        if (child === id) {
          elemIndex = i
        }
        return null
      })
      // remove variable
      parent.variables.splice(elemIndex, 1)
      // put variable to the new index
      parent.variables.splice(index + 1, 0, id)
    }
  }

  @traceHistory
  @catchAction
  @action
  setNewOptionInSectionIndex(index: number) {
    // move element from one section to another

    // get element id
    const id = this.selectedElement.meta
      ? this.selectedElement.meta.id
      : this.selectedElement.id
    // get firs section which contain selected element
    const sectionOne = this.document.documents.main.sections.find(
      (section: any) => {
        return section.options.find((option: any) => option === id)
      }
    )
    // get second section by new index
    const sectionTwo = this.document.documents.main.sections.find(
      (section: any) => {
        return section.options.find((option: any) => option === index)
      }
    )
    // remove element from 1st section
    if (sectionOne) {
      sectionOne.options = sectionOne.options.filter((opt: any) => opt !== id)
    }

    if (sectionTwo) {
      const optIndex = sectionTwo.options.findIndex(
        (opt: number) => opt === index
      )
      // put element to second section on new index
      sectionTwo.options.splice(optIndex + 1, 0, id)
    }
  }

  @traceHistory
  @catchAction
  @action
  setNewSectionIndex(index: number) {
    // move section to another index
    const sectionId = this.selectedElement.id
    // get current section index
    const sectionIndex = this.document.documents.main.sections.findIndex(
      (sect: IDocSection) => {
        return sect.id === sectionId
      }
    )
    // clone current section
    const deletedSection = cloneDeep(
      this.document.documents.main.sections[sectionIndex]
    )
    // remove current section from sections array
    this.document.documents.main.sections.splice(sectionIndex, 1)

    // add removed section to the new index
    if (sectionIndex < index + 1) {
      this.document.documents.main.sections.splice(index, 0, deletedSection)
    } else {
      this.document.documents.main.sections.splice(index + 1, 0, deletedSection)
    }

    // Reordering sections ids
    this.document.documents.main.sections.forEach((section, currentIndex) => {
      section.id = currentIndex + 1
    })
  }

  @traceHistory
  @catchAction
  @action
  duplicateSection(sectionId: number) {
    // copy section
    // clone current section
    const currentSection = cloneDeep(
      this.document.documents.main.sections.find(
        (section: IDocSection) => section.id === sectionId
      )
    )
    // get current section index
    const currentSectionIndex = this.document.documents.main.sections.findIndex(
      (section: IDocSection) => section.id === sectionId
    )

    if (currentSection) {
      this.idSectionCounter += 1
      currentSection.id = this.idSectionCounter
      const newOptionsArray: number[] = []

      // Initiating ids trace
      const idsTrace: {
        options: Record<string, number>
        variables: Record<string, number>
      } = {
        options: {},
        variables: {}
      }

      // clone all section options
      currentSection.options.forEach((optId: number) => {
        const newOpt = cloneDeep(this.document.options[optId])
        if (['hidden', 'repeated'].includes(newOpt.meta.type)) return
        newOpt.meta.id = this.getNewOptionId()
        newOptionsArray.push(newOpt.meta.id)

        // Adding id to idsTrace
        idsTrace.options[optId] = newOpt.meta.id

        this.document.options[newOpt.meta.id] = newOpt
        // clone options and set new IDs
        this.newChildCreator(this.document.options[newOpt.meta.id], idsTrace)
        // clone variables and set new IDs
        this.newVariableCreator(this.document.options[newOpt.meta.id], idsTrace)
      })
      // set cloned options to new section
      currentSection.options = newOptionsArray
      this.document.documents.main.sections.splice(
        currentSectionIndex + 1,
        0,
        currentSection
      )

      // Resetting sections ids
      this.document.documents.main.sections = this.document.documents.main.sections.map(
        (section: any, index: number) => {
          section.id = index + 1
          return section
        }
      )

      // Updating internal conditions
      Object.values(idsTrace.options).forEach((optionId) => {
        const conditions = this.document.options[optionId]?.meta.conditions
        if (typeof conditions === 'object')
          this.updateInternalConditions(conditions, idsTrace)
      })

      Object.values(idsTrace.variables).forEach((variableId) => {
        const { conditions } = this.document.variables[variableId]
        if (typeof conditions === 'object')
          this.updateInternalConditions(conditions, idsTrace)
      })

      // set focus on new section
      this.setSection(currentSectionIndex + 1)
    }
  }

  private updateInternalConditions(
    conditions: ConditionsPartsType,
    idsTrace: {
      options: Record<string, number>
      variables: Record<string, number>
    }
  ) {
    const isConditionV3 = (c: ConditionsPartsType): c is ConditionV3 =>
      typeof c === 'object' && !Array.isArray(c)
    const isConditionV3Array = (c: ConditionsPartsType): c is ConditionV3[] =>
      !isConditionV3(c) && Array.isArray(c)
    const isDataMap = (c: ConditionsPartsType): c is { var: string } =>
      typeof c === 'object' &&
      Object.keys(c).length === 1 &&
      Object.keys(c)[0] === 'var'

    if (isConditionV3(conditions)) {
      if (isDataMap(conditions)) {
        if (/(o|v|c\.s|c\.o|c\.v)\.([0-9]+)/.test(conditions.var)) {
          const lastPart = conditions.var.split('.').pop()
          if (lastPart === undefined)
            throw new Error(`Unkown var ${conditions.var}`)

          const id = parseInt(lastPart, 10)
          const extractedType = conditions.var.slice(0, -lastPart.length - 1)

          switch (extractedType) {
            case 'o':
              if (idsTrace.options[id] !== undefined)
                conditions.var = `o.${idsTrace.options[id]}`
              break
            case 'v':
              if (idsTrace.variables[id] !== undefined)
                conditions.var = `v.${idsTrace.variables[id]}`
              break
            default:
              throw new Error(`Unkown type "${extractedType} recieved`)
          }
        }
      } else {
        Object.keys(conditions).forEach((key) => {
          this.updateInternalConditions(
            conditions[key as keyof ConditionV3] || {},
            idsTrace
          )
        })
      }
    } else if (isConditionV3Array(conditions)) {
      conditions.forEach((_conditions) => {
        this.updateInternalConditions(_conditions, idsTrace)
      })
    }
  }

  @catchAction
  @action
  copySecondaryDocumentFromAnotherDoc = (documentToPaste: string) => {
    // Cloning document
    const document: ModelV3['documents']['any'] = cloneDeep(
      this.documentToCopySection.documents[documentToPaste]
    )

    // Removing all non output options
    const options = document.sections[0].options.filter((optionId) =>
      ['repeated', 'hidden'].includes(
        this.documentToCopySection.options[optionId].meta.type
      )
    )

    const copyOptions = (originalOptions: number[]): number[] => {
      const copy = originalOptions.map((originalOptionId) => {
        const orginalOption = this.documentToCopySection.options[
          originalOptionId
        ]

        // If option is a repeated, we return its children
        if (orginalOption.meta.type === 'repeated')
          return copyOptions(orginalOption.options)

        // Getting new ID
        const newOptionId = this.getNewOptionId()

        const newOption = {
          ...orginalOption,
          meta: {
            ...orginalOption.meta,
            conditions: {},
            validator: {},
            id: newOptionId
          },
          options: copyOptions(orginalOption.options)
        }

        // Copying option and removing their conditions/validators
        this.document.options[newOptionId] = cloneDeep(newOption)

        return newOptionId
      })

      let newOptions: number[] = []
      copy.forEach((a) => {
        if (typeof a === 'number') newOptions = [...newOptions, a]
        else newOptions = [...newOptions, ...a]
      })

      return newOptions
    }

    // Copying options
    const newOptions = copyOptions(options)

    // Copying document
    const [slug] = this.slugifyDocumentName(document.name)
    this.document.documents[slug] = {
      ...document,
      sections: [
        {
          ...document.sections[0],
          options: newOptions
        }
      ],
      params: {
        ...document.params,
        conditions: {}
      }
    }

    this.setCurrentDocument(slug)
  }

  @catchAction
  @action
  copySectionFromAnotherDoc = (id: number, pasteAfterSectionId: number) => {
    // Getting destination index
    const destinationIndex =
      this.document.documents.main.sections.findIndex(
        (section: IDocSection) => section.id === pasteAfterSectionId
      ) + 1

    const {
      getNewOptionId,
      getNewVariableId,
      documentToCopySection: originalModel,
      document: destinationModel
    } = this

    // Copying section
    const copySection = new CopySection({
      id,
      destinationModel,
      originalModel,
      destinationIndex,
      getNewOptionId,
      getNewVariableId
    })

    if (copySection.report.length > 0) {
      this.deleteConditionMessage.push(
        ...copySection.report.map(
          (m) =>
            `\u2022 ${m.message.charAt(0).toUpperCase()}${m.message.slice(1)}`
        )
      )

      this.deleteConditionIsOpen = true
      this.showHeading = false
    }

    // Setting section to new copied section
    this.setSection(destinationIndex)
  }

  @catchAction
  @action
  toggleSlateEditorVariablePopover = (val: boolean) => {
    // params modal state, need to open modal again after closing element state drawer
    this.slateEditorVariablePopover = val
  }

  @catchAction
  @action
  toggleParamsWasShown = (val: boolean) => {
    // params modal state, need to open modal again after closing element state drawer
    this.paramsWasShown = val
  }

  @catchAction
  @action
  toggleDocParamsWasShown = (val: boolean) => {
    // document params modal state, need to open modal again after closing element state drawer
    this.docParamsWasShown = val
  }

  @catchAction
  @action
  toggleModelParamsWasShown = (val: boolean) => {
    // model params modal state, need to open modal again after closing element state drawer
    this.modelParamsWasShown = val
  }

  @catchAction
  @action
  toggleModelParamsTab = (val: string) => {
    this.modelParamsTab = val
  }

  @catchAction
  @action
  togglePrefillWasShown = (val: boolean) => {
    // params modal state, need to open modal again after closing element state drawer
    this.prefillWasShown = val
  }

  @catchAction
  @action
  toggleEditingValidators = (val: boolean) => {
    // params modal state, need to open modal again after closing element state drawer
    this.editingValidators = val
  }

  @catchAction
  @action
  setSlateAnchorBlock = (val: any) => {
    // set current slate anchor block
    this.anchorBlock = val
  }

  @traceHistory
  @catchAction
  @action
  addSection() {
    // adding section
    this.idSectionCounter += 1
    // get current section index
    const currentSectionIndex = this.document.documents.main.sections.findIndex(
      (section: any) => section.id === this.currentSection.id
    )
    // put new section right after current section
    this.document.documents.main.sections.splice(currentSectionIndex + 1, 0, {
      id: this.idSectionCounter,
      label: I18n.get('section title'),
      options: []
    })
    const sectionIndex = this.document.documents.main.sections.indexOf(
      this.currentSection
    )
    // Reorder sections ids
    this.document.documents.main.sections.forEach((section, index) => {
      section.id = index + 1
    })
    // set focus on new section
    this.setSection(sectionIndex + 1)
  }

  @catchAction
  @action
  fixIds = () => {
    // Fixing options
    Object.keys(this.document.options).forEach((optionId) => {
      if (this.document.options[optionId].meta.id !== parseInt(optionId, 10)) {
        this.document.options[optionId].meta.id = parseInt(optionId, 10)
      }
    })

    // Fixing variables
    Object.keys(this.document.variables).forEach((variableId) => {
      if (this.document.variables[variableId].id !== parseInt(variableId, 10)) {
        this.document.variables[variableId].id = parseInt(variableId, 10)
      }
    })

    // Fixing sections
    Object.keys(this.document.documents).forEach((documentName) => {
      this.document.documents[documentName].sections.forEach(
        (section, index) => {
          const sectionId = index + 1
          if (section.id !== sectionId) {
            section.id = sectionId
          }
        }
      )
    })
  }

  @catchAction
  @action
  setModelNotes = (modelNotes: INote[]) => {
    this.modelNotes = modelNotes
  }

  @catchAction
  @action
  addModelNote = (content: string) => {
    const note = {
      author: this.currentUserEmail,
      timestamp: new Date().getTime(),
      content
    }
    runInAction(() => {
      this.modelNotes = [note, ...(this.modelNotes || [])]
    })
  }

  @catchAction
  @action
  setDocument = (doc?: ModelV3) => {
    // @ts-ignore
    window.hhHisto = this.history
    // set document
    if (doc) {
      this.document = doc

      // Moving all outputs to first section
      this.moveAllOutputsToFirstSection()
    } else {
      this.document = {
        documents: {
          main: {
            name: 'main',
            sections: [
              {
                id: 1,
                label: I18n.get('Title of the section'),
                options: [1]
              }
            ]
          }
        },
        options: {
          1: {
            meta: {
              type: 'hidden',
              label: 'Text de la question',
              output: '<p></p>',
              step: '*',
              id: 1
            },
            variables: [],
            options: []
          }
        },
        variables: {},
        customization: {}
      }
    }

    // Getting current document output
    this.setCurrentDocument('main')

    // @ts-ignore
    window.model = this.document

    // Fixing documents ids
    this.fixIds()

    // get all section options
    const allSectionsOptions = this.document.documents[
      this.currentDocument
    ].sections.reduce((acc: any, curEl: any) => {
      return acc.concat(curEl.options)
    }, [])
    // get all section options with descendants
    const allDocumentOptions = this.idsCollector(allSectionsOptions)
    // check all collected options for hidden opts
    const hasHidden = allDocumentOptions.find((option: any) => {
      return (
        this.document.options[option] &&
        this.document.options[option].meta.type === 'hidden'
      )
    })

    // if document has no any output - add one
    if (!hasHidden || hasHidden < 0) {
      const id = this.getNewOptionId()
      this.document.documents.main.sections[0].options.push(id)
      this.document.options[id] = {
        meta: {
          type: 'hidden',
          label: 'Text de la question',
          output: '<p>  </p>',
          step: '*',
          // default: "0",
          id
        },
        variables: [],
        options: []
      }
    }

    // Forcing update update
    if (this.slateEditorRef?.current) {
      this.slateEditorRef.current.updateOutputs()
    }
  }

  getOptionParent = (id: number): number => {
    // returns provided option parent
    const parent = Object.values(this.document.options).find((opt: any) => {
      return opt.options.find((optId: number) => optId === +id)
    })
    if (!parent) {
      return id
    }
    return this.getOptionParent(parent.meta.id)
  }

  getVariableParent = (id: number): number => {
    const options = Object.values(this.document.options)
    for (let i = 0; i < options.length; i += 1) {
      const currentOption = options[i]
      if (currentOption.variables.includes(id)) return currentOption.meta.id
    }
    return 0
  }

  @catchAction
  @action
  updateCustomizationParams = (params: Record<string, any>) => {
    const { customization } = this.document

    Object.keys(params).forEach((currentParam) => {
      deepObjectSetValue(customization, currentParam, params[currentParam])
    })

    this.document.customization = customization
  }

  @catchAction
  @action
  addOption = (
    id: number,
    opt: OptionV3,
    hidden?: boolean,
    dontPutToSection?: boolean,
    prevId?: number
  ) => {
    // add new option to the options array
    this.document.options[+id] = opt
    if (!dontPutToSection) {
      if (prevId) {
        // get option parent
        const parentId = this.getOptionParent(prevId)
        // find section which contains provided option
        // @ts-ignore
        const section = this.document.documents[
          this.currentDocument
        ].sections.findIndex((currentSection: SectionV3) => {
          return currentSection.options.find(
            (optId: number) => +optId === +parentId
          )
        })
        // find index of option which contains provided option
        const prevIdIndex = this.document.documents[
          this.currentDocument
        ].sections[section].options
          // @ts-ignore
          .findIndex((optId: number) => +optId === +parentId)
        // const prevIdIndex = this.document.documents[this.currentDocument].sections[0].options
        //   .findIndex((optId: number) => +optId === +parentId);
        // if parent option was found
        if (prevIdIndex < 0) {
          // find repeated option which contain provided option
          const repeatedParent = Object.values(this.document.options).find(
            (currentOption: OptionV3) => {
              return (
                currentOption.meta.type === 'repeated' &&
                currentOption.options.includes(+prevId)
              )
            }
          )
          // find repeated option index
          const repeatedParentIndex = this.document.documents[
            this.currentDocument
          ].sections[section].options
            // @ts-ignore
            .findIndex((optId: number) => optId === +repeatedParent.meta.id)
          // replace repeated option with provided
          this.document.documents[this.currentDocument].sections[
            section
          ].options.splice(repeatedParentIndex + 1, 0, id)
        } else {
          // replace option with provided
          this.document.documents[this.currentDocument].sections[
            section
          ].options.splice(prevIdIndex + 1, 0, id)
        }
      } else {
        // add option to section
        this.document.documents[this.currentDocument].sections[0].options.push(
          id
        )
      }
    }
  }

  @catchAction
  @action
  deleteHiddenOption = (id: number) => {
    // try to find repeated option
    const repeated = Object.values(this.document.options).find(
      (option: any) => {
        // eslint-disable-next-line
      if (option.meta && option.meta.repeatOption) return option.options.find((opt: any) => opt == id);
        return null
      }
    )

    // if repeated was found and repeated has many options
    if (repeated && repeated.options.length < 2) {
      // delete provided element
      delete this.document.options[id]
      this.document.documents[this.currentDocument].sections.map(
        (section: any) => {
          const optionIndex = section.options.findIndex(
            (opt: number) => +opt === +repeated.meta.id
          )
          if (optionIndex >= 0) {
            section.options.splice(optionIndex, 1)
          }
          return null
        }
      )
    } else {
      // if repeated was found and repeated has not many options
      if (repeated) {
        // remove provided element from repeated opt
        const filtered = this.document.options[repeated.meta.id].options.filter(
          (elId: number) => +elId !== +id
        )
        this.document.options[repeated.meta.id].options = filtered
      }

      // delete hidden option

      delete this.document.options[id]
      // eslint-disable-next-line
      this.document.documents[this.currentDocument].sections.map((section: any) => {
          const optionIndex = section.options.findIndex(
            (opt: number) => +opt === +id
          )
          if (optionIndex >= 0) {
            section.options.splice(optionIndex, 1)
          }
        }
      )
    }
    // if last hidden opt was deleted - add new
    this.addDefaultHiddenOption()
  }

  @catchAction
  @action
  updateCustomization = (customization: ModelV3['customization']) => {
    this.document.customization = {
      ...this.document.customization,
      ...customization
    }
  }

  @catchAction
  @action
  deleteHiddenOptionsArr = (arr: number[]) => {
    const repeatedOpt: any[] = []
    const repeatedArr: any[] = []

    // check for any repeated opts in the arr
    Object.values(this.document.options).map((option: any) => {
      // eslint-disable-next-line
      if (option.meta && option.meta.repeatOption) {
        const optOne = option.options.find((opt: any) => {
          return arr.find((el: number) => {
            // compare all opts from document with elements to delete from arr
            if (+opt === +el) repeatedOpt.push(opt)
            return +opt === +el
          })
        })
        const optTwo = option.options.find((opt: any) => +opt === +arr[1])
        if (optOne || optTwo) repeatedArr.push(option)
      }
      return null
    })

    if (repeatedArr.length) {
      // if any repeated was found - delete it from current section and from document.options
      repeatedArr.map((repeated: any) => {
        delete this.document.options[repeated.meta.id]
        // @ts-ignore
        repeatedOpt.map((el: any) => {
          // remove repeated element from arr to proceed deletion below
          if (arr.indexOf(el) >= 0) arr.splice(arr.indexOf(el), 1)
          return null
        })
        this.document.documents[this.currentDocument].sections.map(
          (section: any) => {
            const optionIndex = section.options.findIndex(
              (opt: number) => +opt === +repeated.meta.id
            )
            // delete element from section
            if (optionIndex >= 0) section.options.splice(optionIndex, 1)
            return null
          }
        )
        return null
      })
    }
    // delete not repeated elements
    arr.map((id: number) => {
      // delete hidden option
      delete this.document.options[id]
      // eslint-disable-next-line
      this.document.documents[this.currentDocument].sections.map((section: any, i: number) => {
          const optionIndex = section.options.findIndex(
            (opt: number) => +opt === +id
          )
          // delete option from curren section
          if (optionIndex >= 0) section.options.splice(optionIndex, 1)
        }
      )
      return null
    })
    // if all of the hidden options was deleted - add empty one
    this.addDefaultHiddenOption()
  }

  @catchAction
  @action
  addDefaultHiddenOption = () => {
    // get all section options
    const allSectionsOptions = this.document.documents[
      this.currentDocument
    ].sections.reduce((acc: any, curEl: any) => {
      return acc.concat(curEl.options)
    }, [])
    // get all section options with descendants
    const allDocumentOptions = this.idsCollector(allSectionsOptions)
    // check section options for hidden opts
    const hasHidden = allDocumentOptions.find((option: any) => {
      return (
        this.document.options[option] &&
        this.document.options[option].meta.type === 'hidden'
      )
    })
    // if document has no any output - add one
    if (!hasHidden) {
      const id = this.getNewOptionId()
      this.document.documents[this.currentDocument].sections[0].options.push(id)
      this.document.options[id] = {
        meta: {
          type: 'hidden',
          label: 'Text de la question',
          output: '<p>Enter some text</p>',
          step: '*',
          id
        },
        variables: [],
        options: []
      }
    }
  }

  @catchAction
  @action
  updateHiddenOption = (id: number, output: string) => {
    // set new output to new option
    if (this.document.options[id]) {
      this.document.options[id].meta.output = output
    }
  }

  @catchAction
  @action
  setSlateSelection = (selection: any) => {
    // setting slate selection
    if (selection) this.slateSelection = selection
  }

  @catchAction
  @action
  setSlateStartBlock = (startBlock: any) => {
    // setting slate start block
    if (startBlock) this.slateStartBlock = startBlock
  }

  @catchAction
  @action
  setSlatePrevBlock = (slatePrevBlock: any) => {
    // setting slate prev block
    if (slatePrevBlock) this.slatePrevBlock = slatePrevBlock
  }

  @catchAction
  @action
  setCurrentDocument = (name: string, noUpdate?: boolean) => {
    // set focus on another document
    this.currentDocument = name

    // Updating current document outputs
    if (noUpdate !== true) this.updateCurrentDocumentOutputs()
  }

  @traceHistory
  @catchAction
  @action
  addDocument = (params: any) => {
    const { title } = params

    // Getting list of titles
    const [slug] = this.slugifyDocumentName(title)

    // Default option id
    const defaultHiddenId = this.getNewOptionId()

    // add new document
    this.document.documents[slug] = {
      name: title,
      sections: [
        {
          id: 1,
          label: '',
          options: [defaultHiddenId]
        }
      ],
      params: {
        formats: {
          pdf: params.pdf,
          docx: params.word
        },
        conditions: params.conditions
      }
    }
    // set default hidden option into new document
    this.document.options[defaultHiddenId] = {
      meta: {
        type: 'hidden',
        label: 'Text',
        output: '<p></p>',
        step: '*',
        id: defaultHiddenId
      },
      variables: [],
      options: []
    }
    this.idSectionCounter += 1
    toast.success(I18n.get('new_secondary_document'), {
      position: toast.POSITION.TOP_RIGHT
    })

    // set focus on new document
    this.setCurrentDocument(slug)
  }

  @catchAction
  @action
  duplicateDocument = (sourceSlug: string) => {
    // Getting document
    const source = this.document.documents[sourceSlug]

    // Getting list of titles
    const [destinationSlug, documentNumber] = this.slugifyDocumentName(
      source.name
    )

    // Destination
    const destination: ModelV3['documents']['document'] = {
      name: `${source.name} (${documentNumber})`,
      sections: [
        {
          id: 1,
          label: '',
          options: []
        }
      ],
      params: {
        ...source.params,
        formats: {
          ...source.params?.formats
        },
        conditions: {
          ...source.params?.conditions
        }
      }
    }

    // Duplicating documents options
    const sourceOptions = source.sections[0].options
    const destinationOptions: number[] = []

    sourceOptions.forEach((id) => {
      // Ignoring option if it's not an output or a repeated
      const { type } = this.document.options[id].meta
      if (!['repeated', 'hidden'].includes(type)) {
        return
      }

      // Duplicating option's children
      const { options } = this.document.options[id]
      const destinationChildren: number[] = []
      options.forEach((childId) => {
        const optionsIds = Object.keys(this.document.options).map((i) =>
          parseInt(i, 10)
        )
        const newChildId = Math.max(0, ...optionsIds) + 1
        // Duplicating option
        const destinationChild: OptionV3 = {
          ...this.document.options[childId],
          meta: {
            ...this.document.options[childId].meta,
            id: newChildId
          }
        }

        // Adding new child to model
        this.document.options[newChildId] = destinationChild

        destinationChildren.push(newChildId)
      })

      const optionsIds = Object.keys(this.document.options).map((i) =>
        parseInt(i, 10)
      )
      const newId = Math.max(0, ...optionsIds) + 1
      // Duplicating option
      const option: OptionV3 = {
        ...this.document.options[id],
        meta: {
          ...this.document.options[id].meta,
          id: newId
        },
        options: destinationChildren
      }

      this.document.options[newId] = option
      destinationOptions.push(newId)
    })

    // Adding duplicated options to destination doc
    destination.sections[0].options = destinationOptions

    // Adding duplicated document to model
    this.document.documents[destinationSlug] = destination

    // set focus on new document
    this.setCurrentDocument(destinationSlug)
  }

  /**
   * Generates proper slug for a given
   * document name and adding suffix
   * if needed in case another document
   * has the same slug
   * @param name Document's name
   */
  @catchAction
  @action
  private slugifyDocumentName(name: string): [string, number] {
    // Slugifying
    let slug = slugify(name, {
      lower: true,
      strict: true
    })

    // Getting list of titles
    const existingSlugs = Object.keys(this.document.documents)

    // Making sure title doesn't exist, otherwise we add a number to it
    let documentNumber = -1
    existingSlugs.forEach((currentSlug) => {
      const regex = new RegExp(`^${slug}(-([0-9]+))?$`, 'gi')
      const test = regex.exec(currentSlug)
      if (test !== null) {
        let number: string | number = test[2]
        if (number !== undefined) {
          number = parseInt(number, 10) + 1
          documentNumber = number > documentNumber ? number : documentNumber
        } else if (documentNumber === -1) {
          documentNumber = 1
        }
      }
    })
    if (documentNumber > 0) slug += `-${documentNumber}`

    return [slug, documentNumber]
  }

  /**
   * Updates document name & params
   *
   * @param slug Document's SLUG
   * @param name  Document's Name
   * @param params Document's Params (check Model definition ModelV3['documents']['params']))
   */
  @traceHistory
  @catchAction
  @action
  updateDocument(
    slug: string,
    name: string,
    params: ModelV3['documents']['document']['params']
  ) {
    let document = slug

    // Saving document's index
    const documentIndex = Object.keys(this.document.documents).indexOf(slug)

    // If document isn't main, we must slugify its name if it changed
    // and move it the its new slug
    if (
      document !== 'main' &&
      name.toLowerCase() !== this.document.documents[slug].name.toLowerCase()
    ) {
      ;[document] = this.slugifyDocumentName(name)
      this.document.documents[document] = {
        ...this.document.documents[slug]
      }
      delete this.document.documents[slug]
    }

    this.document.documents[document] = {
      ...this.document.documents[document],
      name,
      params: {
        ...this.document.documents[document].params,
        ...params
      }
    }

    // Moving document back to its previous index
    this.moveDocumentWithoutHistory(document, documentIndex)

    if (document !== slug) {
      this.setCurrentDocument(document)
    }
  }

  /**
   * Moves document up or down in documents tree
   *
   * @param slug Document's SLUG
   * @param upOrDown Move document up or down by one case
   */
  @traceHistory
  @catchAction
  @action
  moveDocumentUpDown = (slug: string, upOrDown: 'up' | 'down') => {
    // Getting list of documents slugs
    const slugs = Object.keys(this.document.documents)

    // Getting current slug's index
    const documentIndex = slugs.indexOf(slug)

    // Making sure document exists
    if (documentIndex === -1) throw new Error(`Unable to find document ${slug}`)

    // If document is first and user tries to go UP let's stop here
    if (documentIndex === 0 && upOrDown === 'up') return

    // If document is last and user tries to go DOWN let's stop here
    if (documentIndex === slugs.length - 1 && upOrDown === 'down') return

    // Calculating new document's index
    const newDocumentIndex = documentIndex + (upOrDown === 'up' ? -1 : 1)

    this.moveDocumentWithoutHistory(slug, newDocumentIndex)
  }

  @traceHistory
  moveDocument = (slug: string, newDocumentIndex: number) => {
    this.moveDocumentWithoutHistory(slug, newDocumentIndex)
  }

  /**
   * Moves document to set index
   *
   * @param slug Document's SLUG
   * @param upOrDown Move document up or down by one case
   */
  @catchAction
  @action
  private moveDocumentWithoutHistory = (
    slug: string,
    newDocumentIndex: number
  ) => {
    // Getting list of documents slugs
    const slugs = Object.keys(this.document.documents)

    // Getting current slug's index
    const documentIndex = slugs.indexOf(slug)

    // Making sure document exists
    if (documentIndex === -1) throw new Error(`Unable to find document ${slug}`)

    // If document is first and user tries to go UP let's stop here
    if (documentIndex === 0 && newDocumentIndex <= 0) return

    // If document is last and user tries to go DOWN let's stop here
    if (
      documentIndex === slugs.length - 1 &&
      newDocumentIndex >= slugs.length - 1
    )
      return

    // Deleting current slug from index
    slugs.splice(documentIndex, 1)

    // Moving document in new slugs tree
    const reorderedSlugs = [
      ...slugs.slice(0, newDocumentIndex),
      slug,
      ...slugs.slice(newDocumentIndex)
    ]

    // Reordring documents
    reorderedSlugs.forEach((currentSlug) => {
      const copy = { ...this.document.documents[currentSlug] }
      delete this.document.documents[currentSlug]
      this.document.documents[currentSlug] = copy
    })
  }

  /**
   * Deletes current document
   */
  @traceHistory
  @catchAction
  @action
  deleteDocument() {
    // delete document
    delete this.document.documents[this.currentDocument]
    toast.success(I18n.get('delete_secondary_document'), {
      position: toast.POSITION.TOP_RIGHT
    })
    this.setCurrentDocument('main')
  }

  @catchAction
  @action
  setSection = (id: number) => {
    // set focus on section
    this.currentSectionId = id
  }

  /**
   * Edits Section property
   *
   * @param id Section's ID (not index)
   * @param prop Section's property to edit
   * @param value Properties new value
   */
  @traceHistory
  @catchAction
  @action
  editSection<T extends keyof SectionV3>(
    id: number,
    prop: T,
    value: SectionV3[T]
  ) {
    // edit current section
    const sectionIndex = this.document.documents.main.sections.findIndex(
      (s) => s.id === id
    )

    if (sectionIndex === -1)
      throw new Error(`Unable to determine section index when editing`)

    this.document.documents.main.sections[sectionIndex][prop] = value
  }

  /**
   * Deleting current section
   */
  @catchAction
  @action
  deleteSection = () => {
    const mainSections = this.document.documents.main.sections
    // Getting current section's index
    const sectionIndex = mainSections.indexOf(this.currentSection)

    // Checking if section options are used in conditions
    const { options } = this.currentSection
    options.forEach((id) => {
      this.checkForConditions(this.document.options[id], [], false, false, true)
    })

    // In case there are errors thrown by checkForConditions
    // let's stop right here
    if (this.deleteConditionMessage.length > 0) return

    // In case we can't find current section's index
    // let's stop right here
    if (sectionIndex === -1) return

    // Deleting section's options
    const outputs: number[] = []
    mainSections[sectionIndex].options.forEach((id) => {
      // If we're deleting first section, let's make sure not to delete outputs
      if (
        sectionIndex === 0 &&
        ['repeated', 'hidden'].includes(this.document.options[id].meta.type)
      ) {
        outputs.push(id)
      } else {
        delete this.document.options[id]
      }
    })

    // Deleting section
    mainSections.splice(sectionIndex, 1)

    // Creating new empty section if we deleted the only one
    if (mainSections.length === 0) {
      mainSections.push({
        id: 1,
        label: I18n.get('section title'),
        options: []
      })
    }

    // Moving outputs to new first section if we have any
    if (outputs.length > 0) {
      mainSections[0].options.push(...outputs)
    }

    // Setting current section
    const currentSectionIndex = sectionIndex - 1 > 0 ? sectionIndex - 1 : 0
    this.setSection(currentSectionIndex)
  }

  @catchAction
  @action
  updateFormInfo = () => {
    // need for quill
    this.form = this.form.map((group) => ({
      ...group,
      selected: this.contractTemplate.includes(`data-group="${group.id}"`),
      formElements: Object.values(group.formElements).map((el: any) => ({
        ...el,
        placed: this.contractTemplate.includes(`data-id="${el.id}"`)
      }))
    }))
  }

  @catchAction
  @action
  addGroup = (type: string) => {
    // add root element
    this.addFormElement(type, false, -1, [])
  }

  @catchAction
  @action
  addFormElement = (
    type: string,
    inner: boolean,
    elId: number,
    childrenIds: any[] = [],
    isDuplicate?: boolean,
    duplicateId?: number
  ) => {
    // creating new element
    this.updateSelectedElement({}, [])
    // childrenIds - chain children ids
    const create = this.createFormElement(
      elId,
      childrenIds,
      isDuplicate,
      duplicateId
    )
    switch (type) {
      case 'text':
      case 'date':
      case 'textarea':
      case 'number':
      case 'eval':
      case 'mask':
        return create(field, true)
      case 'box':
        return create(text)
      case 'static':
        return create(question)
      case 'checkbox':
        return create(checkbox)
      case 'radioBox':
      case 'radio':
        return create(radioBox)
      case 'dropdownMenu':
      case 'list':
        return create(dropdownMenu, true)
      default:
        return null
    }
  }

  @observable variableIdTracker = 0

  getNewVariableId = () => {
    // Getting last registred id from model
    const modelsLastId =
      Object.keys(this.document.variables)
        .map((id) => parseInt(id, 10))
        .sort((a, b) => a - b)
        .pop() || 0

    // Making sure we're not overriding any
    // reserved id
    const lastId =
      this.variableIdTracker > modelsLastId
        ? this.variableIdTracker
        : modelsLastId

    // Calculating new id
    const newId = typeof lastId === 'number' ? lastId + 1 : 1

    // Reserving new id
    this.variableIdTracker = newId

    return newId
  }

  @observable optionIdTracker = 0

  getNewOptionId = () => {
    // Getting last registred id from model
    const modelsLastId =
      Object.keys(this.document.options)
        .map((id) => parseInt(id, 10))
        .sort((a, b) => a - b)
        .pop() || 0

    // Making sure we're not overriding any
    // reserved id
    const lastId =
      this.optionIdTracker > modelsLastId ? this.optionIdTracker : modelsLastId

    // Calculating new id
    const newId = typeof lastId === 'number' ? lastId + 1 : 1

    // Reserving new id
    this.optionIdTracker = newId

    return newId
  }

  @traceHistory
  createFormElement(
    elId: number,
    childrenIds: any[],
    isDuplicate?: boolean,
    duplicateId?: number
  ) {
    return (elem: any, variable = false) => {
      const item = cloneDeep(elem)

      // set id to new elem
      if (variable) {
        item.id = this.getNewVariableId()
      } else {
        item.meta.id = this.getNewOptionId()
      }
      let destinationEl: any
      this.resetHistoryIfNeeded()
      if (childrenIds.length) {
        // if nested element
        childrenIds.shift()
        destinationEl = this.document.options[elId]
        if (variable) {
          this.document.variables[item.id] = item
          const parentIndex = destinationEl.variables.indexOf(duplicateId)
          if (parentIndex > -1)
            destinationEl.variables.splice(parentIndex + 1, 0, item.id)
          else destinationEl.variables.push(item.id)
        } else {
          this.document.options[item.meta.id] = item

          const parentIndex = destinationEl.options.indexOf(duplicateId)
          if (parentIndex > -1)
            destinationEl.options.splice(parentIndex + 1, 0, item.meta.id)
          else destinationEl.options.push(item.meta.id)
        }
      } else {
        this.document.options[item.meta.id] = item
        if (isDuplicate) {
          // if duplicating element
          // find element parent
          const parent = Object.values(this.document.options).find(
            (opt: any) => {
              return opt.options.find((child: number) => {
                return child === item.meta.id
              })
            }
          )
          if (!parent) {
            // find parent index
            const parentIndex = this.document.documents.main.sections[
              this.currentSectionId
            ].options.findIndex((opt: any) => opt === duplicateId)
            // find section which contains duplicateId
            const sectionOne = this.document.documents.main.sections.find(
              (section: any) => {
                return section.options.find(
                  (option: any) => option === duplicateId
                )
              }
            )
            if (sectionOne) {
              // put new element right after current
              sectionOne.options.splice(parentIndex + 1, 0, item.meta.id)
            }
          } else {
            this.currentSection.options.push(item.meta.id)
          }
        } else {
          // if root element - push into options array
          this.currentSection.options.push(item.meta.id)
        }
      }
      // fill metadata
      this.metaData[this.idCounter + 1] = {
        placed: false,
        openedInnerElements: false,
        id: this.idCounter + 1,
        color: 'rgba(133, 81, 181, 0.3)',
        parentId: elId
      }

      this.idCounter += 1
      return item
    }
  }

  @traceHistory
  @catchAction
  @action
  updateElement(el: any, ids: any[], variable: boolean, id: number) {
    // edit element
    let destinationEl: any
    this.resetHistoryIfNeeded()
    if (el.meta && el.meta.multiple && el.meta.multiple.enabled) {
      // if element becomes multiple - set new status
      // eslint-disable-next-line
      // el.options.map((elId: number) => {
      //   this.document.options[elId].meta.parentMultiple = true;
      // })
    }
    // if (ids.length) {
    if (variable) {
      // eslint-disable-next-line
      this.document.variables[el.id] = el
      if (el.type !== 'eval') {
        // set new prefillings
        this.document.variables[
          el.id
        ].prefillings = this.selectedElement.prefillings
      }
    } else {
      ids.shift()
      // find edited element
      destinationEl = this.document.options[id]
      // set new values
      // eslint-disable-next-line
      Object.keys(destinationEl).map(key => {
        destinationEl[key] = el[key]
      })
    }
  }

  @catchAction
  @action
  toggleDeleteValidationModal = (
    open: boolean,
    data: any,
    callback?: () => void
  ) => {
    this.deleteValidationIsOpen = open
    this.deleteValidationData = data
    this.deleteValidationCallback = callback
  }

  deleteCurrentCondition = (indexes: any[], conditions: ConditionV3) => {
    // get first parent
    const rootElId = indexes[0]
    // copy parent IDs array
    const idxs = [...indexes]
    // get current index
    const index = this.getIndex(indexes)
    if (index) {
      idxs.splice(index - 1)
    }
    idxs.shift()
    // get current condition
    // @ts-ignore
    const destinationEl = idxs.reduce(
      (parent, id) => parent[id],
      // TODO: Remove TS IGNORE
      // @ts-ignore
      conditions[rootElId]
    )

    // @ts-ignore
    this.deleteCondition(destinationEl, indexes[index - 1])
  }

  getIndex = (indexes: any[]) => {
    let index
    if (indexes.indexOf('selected') !== -1) {
      index = indexes.lastIndexOf('selected')
    } else if (indexes.indexOf('not-selected') !== -1) {
      index = indexes.lastIndexOf('not-selected')
    } else if (indexes.indexOf('not-selected-ip') !== -1) {
      index = indexes.lastIndexOf('not-selected-ip')
    } else if (indexes.indexOf('at-least-one') !== -1) {
      index = indexes.lastIndexOf('at-least-one')
    } else if (indexes.indexOf('contains') !== -1) {
      index = indexes.lastIndexOf('contains')
    } else if (indexes.indexOf('does-not-contain') !== -1) {
      index = indexes.lastIndexOf('does-not-contain')
    } else if (indexes.indexOf('has-one') !== -1) {
      index = indexes.lastIndexOf('has-one')
    } else if (indexes.indexOf('=') !== -1) {
      index = indexes.lastIndexOf('=')
    } else if (indexes.indexOf('<>') !== -1) {
      index = indexes.lastIndexOf('<>')
    } else if (indexes.indexOf('>') !== -1) {
      index = indexes.lastIndexOf('>')
    } else if (indexes.indexOf('<') !== -1) {
      index = indexes.lastIndexOf('<')
    } else if (indexes.indexOf('>=') !== -1) {
      index = indexes.lastIndexOf('>=')
    } else if (indexes.indexOf('<=') !== -1) {
      index = indexes.lastIndexOf('<=')
    }
    return index
  }

  conditionRemover = (
    elemId: number,
    elemType: 'o' | 'v',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteRequest = true,
    showModal = true
  ) => {
    // Checking whether we can delete this element
    const safeDelete = new SafeDelete(elemId, elemType)

    if (safeDelete.errorMessages.length > 0) {
      this.deleteConditionMessage.push(
        ...safeDelete.errorMessages.map(
          (m) => `${m.message.charAt(0).toUpperCase()}${m.message.slice(1)}`
        )
      )

      this.hasCondition = true
      if (showModal) {
        this.deletingElement = {
          id: elemId,
          type: elemType
        }
        this.canUnlink = safeDelete.canUnlink
        this.deleteConditionIsOpen = true
        this.showHeading = true
      }
    }
  }

  @traceHistory
  @catchAction
  @action
  unlinkDeleteElement = (elemId: number, elemType: 'o' | 'v') => {
    const unlinkDelete = new UnlinkDelete(elemId, elemType)
    unlinkDelete.unlink()
  }

  @catchAction
  @action
  toggleDeleteConditionModal = (open: boolean, showHeading = true) => {
    this.deleteConditionIsOpen = open
    this.showHeading = showHeading
  }

  @catchAction
  @action
  toggleSelectOutputModal = (open: boolean) => {
    this.selectOutputModalIsOpen = open
  }

  @catchAction
  @action
  checkForConditions = (
    el: any,
    ids: number[],
    variable: boolean,
    deleteRequest = true,
    showModal = true,
    deleteCallback?: Function
  ) => {
    const id = el.meta ? el.meta.id : el.id
    const type = typeof el.meta === 'object' ? 'o' : 'v'

    // Setting deleting element to false
    this.deletingElement = false
    this.canUnlink = false
    this.clearConditionMessage()
    this.conditionRemover(id, type, deleteRequest, showModal)

    if (el.options) {
      const varIds = this.variablesIdsCollector(el.options)
      // get inner child IDs
      const innerChildsIds = this.idsCollector(el.options)

      // eslint-disable-next-line
      Object.keys(this.document.options).map((optKey: any) => {
        // delete inner options
        if (innerChildsIds.includes(this.document.options[optKey].meta.id)) {
          this.conditionRemover(optKey, 'o', deleteRequest, showModal)
          // delete this.document.options[optKey]
        }
      })

      Object.keys(this.document.variables).map((varKey: any) => {
        if (
          el.meta &&
          this.document.options[el.meta.id].variables.includes(+varKey)
        ) {
          this.conditionRemover(varKey, 'v', deleteRequest, showModal)
          // delete this.document.variables[varKey]
        }
        return null
      })

      Object.keys(this.document.variables).map((variableKey: any) => {
        // delete variable
        if (varIds.includes(this.document.variables[variableKey].id)) {
          this.conditionRemover(variableKey, 'v', deleteRequest, showModal)
          // delete this.document.variables[variableKey];
        }
        return null
      })
    }

    if (!this.deleteConditionMessage.length && deleteRequest) {
      this.toggleDeleteValidationModal(true, { el, ids, variable })
      // Close drawer if opened
      if (typeof deleteCallback === 'function') {
        deleteCallback()
      }

      // this.deleteElement(el, ids, variable)
    }
  }

  @catchAction
  @action
  clearConditionMessage = () => {
    this.deleteConditionMessage = []
  }

  @catchAction
  @action
  clearHasCondition = () => {
    this.hasCondition = false
  }

  @traceHistory
  @catchAction
  @action
  deleteElement(el: any, ids: number[], variable: boolean) {
    // delete element
    this.resetHistoryIfNeeded()
    if (variable) {
      // if variable - find element and delete it from option and document.variables
      const id = ids[ids.length - 1]
      const destinationEl = this.document.options[id]
      this.conditionRemover(el.id, 'v', true)
      delete this.document.variables[el.id]
      const vars = destinationEl.variables.filter(
        (currentVariable: any) => currentVariable !== el.id
      )
      destinationEl.variables = vars
      return
    }

    ids.pop()
    const id = ids[ids.length - 1]

    if (ids.length && id > 0) {
      ids.shift()
      // find element
      const destinationEl = this.document.options[id]
      // get all child options variables IDs
      const varIds = this.variablesIdsCollector(el.options)
      // get inner child IDs
      const innerChildsIds = this.idsCollector(el.options)

      // eslint-disable-next-line
      Object.keys(this.document.options).map((optKey: any) => {
        // delete inner options
        if (innerChildsIds.includes(this.document.options[optKey].meta.id)) {
          this.conditionRemover(optKey, 'o', true)
          delete this.document.options[optKey]
        }
      })

      Object.keys(this.document.variables).map((varKey: any) => {
        if (this.document.options[el.meta.id].variables.includes(+varKey)) {
          this.conditionRemover(varKey, 'v', true)
          delete this.document.variables[varKey]
        }
        return null
      })

      Object.keys(this.document.variables).map((variableKey: any) => {
        // delete variable
        if (varIds.includes(this.document.variables[variableKey].id)) {
          this.conditionRemover(variableKey, 'v', true)
          delete this.document.variables[variableKey]
        }
        return null
      })

      this.currentSection.options = this.currentSection.options.filter(
        (opt: number) => !innerChildsIds.includes(opt)
      )
      this.conditionRemover(el.meta.id, 'o', true)
      delete this.document.options[el.meta.id]
      const childs = destinationEl.options.filter(
        (child: any) => child !== el.meta.id
      )
      destinationEl.options = childs
    } else {
      // del nested option
      // get child IDs
      const innerChildsIds = this.idsCollector(
        this.document.options[el.meta.id].options
      )
      let varIds = this.variablesIdsCollector(
        this.document.options[el.meta.id].options
      )

      // filter element from options
      this.currentSection.options = this.currentSection.options.filter(
        (opt: number) => opt !== el.meta.id
      )
      // eslint-disable-next-line
      Object.keys(this.document.options).map((optKey: any) => {
        // delete inner options
        if (innerChildsIds.includes(this.document.options[optKey].meta.id)) {
          this.conditionRemover(optKey, 'o', true)

          delete this.document.options[optKey]
        }
      })
      varIds = varIds.concat(this.document.options[el.meta.id].variables)
      Object.keys(this.document.variables).map((variableKey: any) => {
        // delete variables
        if (varIds.includes(this.document.variables[variableKey].id)) {
          this.conditionRemover(variableKey, 'v', true)
          delete this.document.variables[variableKey]
        }
        return null
      })
      this.conditionRemover(el.meta.id, 'o', true)
      delete this.document.options[el.meta.id]
    }
    // prepare options without el
    const filteredOptions = this.currentSection.options.filter(
      (opt: number) => opt !== el.id
    )
    this.currentSection.options = filteredOptions
  }

  @catchAction
  @action
  copyElement = (el: any, ids: any[], variable: boolean) => {
    // copy element
    const initialIds = [...ids]
    if (!variable) ids.pop()
    // get type and id of element
    const type = variable ? el.type : el.meta.type
    const id = variable ? el.id : el.meta.id
    // create new element
    let newElem = this.addFormElement(
      type,
      true,
      ids[ids.length - 1],
      ids,
      true,
      id
    )
    const elem = cloneDeep(el)
    const newId = newElem.meta ? newElem.meta.id : newElem.id
    newElem = elem

    // Initiating ids trace
    const idsTrace: {
      options: Record<string, number>
      variables: Record<string, number>
    } = {
      options: {},
      variables: {}
    }

    if (newElem.meta) {
      newElem.meta.id = newId

      // Adding id to ids trace
      idsTrace.options[id] = newId
    } else {
      newElem.id = newId

      // Adding id to ids trace
      idsTrace.variables[id] = newId
    }
    if (variable) {
      initialIds[initialIds.length - 1] = newElem.id
    } else {
      // copy childs and variables
      newElem.options = el.options
      newElem.variables = el.variables
      if (el.meta.default) {
        newElem.meta.default = el.meta.default
      }
      if (el.meta.multiple && el.meta.multiple.enabled) {
        newElem.meta.multiple.enabled = el.meta.multiple.enabled
        newElem.meta.multiple.incrementationStart =
          el.meta.multiple.incrementationStart
        newElem.meta.multiple.cta = el.meta.multiple.cta
        newElem.meta.multiple.label = el.meta.multiple.label
      }
      // set new IDs for childs and variables
      this.newChildCreator(newElem, idsTrace)
      this.newVariableCreator(newElem, idsTrace)
      initialIds[initialIds.length - 1] = newElem.meta.id
      newElem.meta.label = el.meta.label
    }
    if (!variable) {
      this.document.options[newElem.meta.id] = newElem
    } else {
      this.document.variables[newElem.id] = newElem
    }

    toast.success(I18n.get('success_copy_element'), {
      position: toast.POSITION.TOP_RIGHT
    })

    // Updating internal conditions
    Object.values(idsTrace.options).forEach((optionId) => {
      const conditions = this.document.options[optionId]?.meta.conditions
      if (typeof conditions === 'object')
        this.updateInternalConditions(conditions, idsTrace)
    })

    Object.values(idsTrace.variables).forEach((variableId) => {
      const { conditions } = this.document.variables[variableId]
      if (typeof conditions === 'object')
        this.updateInternalConditions(conditions, idsTrace)
    })

    this.updateSelectedElement(newElem, initialIds)
  }

  @catchAction
  @action
  newChildCreator = (
    el: any,
    idsTrace: {
      options: Record<string, number>
      variables: Record<string, number>
    }
  ) => {
    // recursively goes through childs, clone and update childs
    const newChildArray: number[] = []
    el.options.map((childId: number) => {
      const newOpt = cloneDeep(this.document.options[childId])

      newOpt.meta.id = this.getNewOptionId()
      newChildArray.push(newOpt.meta.id)

      // Adding new id to ids Trace
      idsTrace.options[childId] = newOpt.meta.id

      this.newVariableCreator(newOpt, idsTrace)
      this.document.options[newOpt.meta.id] = newOpt
      this.newChildCreator(this.document.options[newOpt.meta.id], idsTrace)
      return null
    })
    el.options = newChildArray
  }

  @catchAction
  @action
  newChildForCopyCreator = (el: any) => {
    // recursively goes through childs, clone and update childs
    const newChildArray: number[] = []
    el.options.map((childId: number) => {
      const newOpt = cloneDeep(this.documentToCopySection.options[childId])

      newOpt.meta.id = this.getNewOptionId()
      delete newOpt.meta.conditions
      newChildArray.push(newOpt.meta.id)
      this.newVariableToCopyCreator(newOpt)
      this.document.options[newOpt.meta.id] = newOpt

      this.newChildForCopyCreator(this.document.options[newOpt.meta.id])
      return null
    })
    el.options = newChildArray
  }

  @catchAction
  @action
  newVariableCreator = (
    el: any,
    idsTrace: {
      options: Record<string, number>
      variables: Record<string, number>
    }
  ) => {
    // goes through variables, clone and set new IDs
    const newVariableArray: number[] = []
    el.variables.map((varId: number) => {
      const newVar = cloneDeep(this.document.variables[varId])
      newVar.id = this.getNewVariableId()
      newVariableArray.push(newVar.id)

      // Adding id to ids trace
      idsTrace.variables[varId] = newVar.id

      this.document.variables[newVar.id] = newVar
      return null
    })
    el.variables = newVariableArray
  }

  @catchAction
  @action
  newVariableToCopyCreator = (el: any) => {
    // goes through variables, clone and set new IDs
    const newVariableArray: number[] = []
    el.variables.map((varId: number) => {
      const newVar: VariableV3 = cloneDeep(
        this.documentToCopySection.variables[varId]
      )
      newVar.id = this.getNewVariableId()
      delete newVar.conditions

      // Removing conditions from prefillings
      if (Array.isArray(newVar.prefillings)) {
        newVar.prefillings.forEach((prefill) => {
          delete prefill.conditions
        })
      }
      newVariableArray.push(newVar.id)
      this.document.variables[newVar.id] = newVar
      return null
    })
    el.variables = newVariableArray
  }

  @catchAction
  @action
  setConditions = (reset = true) => {
    if (this.conditionedElType === 'prefill') {
      // add conditions to prefilling
      const data = this.conditionedEl.destinationEl.meta
        ? this.conditionedEl.destinationEl.meta.conditions
        : this.conditionedEl.destinationEl.conditions
      this.selectedElementPrefillings[this.prefillIndex].conditions = data
      if (reset) this.resetConditionedEl()
      return
    }
    if (this.conditionedElType === 'document') {
      // add conditions to document
      const data = this.conditionedEl.destinationEl.meta
        ? this.conditionedEl.destinationEl.meta.conditions
        : this.conditionedEl.destinationEl.conditions
      // @ts-ignore
      this.document.documents[this.currentDocument].params.conditions = data
      if (reset) this.resetConditionedEl()
      return
    }
    if (this.conditionedElType === 'client_type') {
      // add conditions to document
      const data = this.conditionedEl.destinationEl.meta
        ? this.conditionedEl.destinationEl.meta.conditions
        : this.conditionedEl.destinationEl.conditions
      // @ts-ignore
      this.document.customization.meta.clientType.conditions = data
      if (reset) this.resetConditionedEl()
      return
    }
    if (this.conditionedElType === 'variable') {
      // add conditions to variable
      const data = this.conditionedEl.destinationEl.meta
        ? this.conditionedEl.destinationEl.meta.conditions
        : this.conditionedEl.destinationEl.conditions
      this.selectedElement.conditions = data
      this.document.variables[this.variableId].conditions = data
      if (reset) this.resetConditionedEl()
      return
    }
    if (!this.conditionedEl.destinationEl.meta) {
      // add conditions to section
      const currentSectionIndex = this.document.documents.main.sections.findIndex(
        (section: any) => section.id === this.conditionedEl.destinationEl.id
      )

      this.document.documents.main.sections[
        currentSectionIndex
      ].conditions = this.conditionedEl.destinationEl.conditions
      this.selectedElement.conditions = this.conditionedEl.destinationEl.conditions
    } else {
      if (Object.keys(this.selectedElement).length)
        this.selectedElement.meta.conditions = this.conditionedEl.destinationEl.meta.conditions

      this.document.options[
        this.conditionedEl.destinationEl.meta.id
      ].meta.conditions = this.conditionedEl.destinationEl.meta.conditions
    }
    // set conditioned element to default
    if (reset) this.resetConditionedEl()
  }

  @catchAction
  @action
  setValidators = (reset = true) => {
    if (this.conditionedElType === 'variable') {
      // add conditions to variable
      const data = this.conditionedEl.destinationEl.validator.conditions
      this.selectedElement.validator = {
        ...this.selectedElement.validator,
        conditions: data
      }
      this.document.variables[this.variableId].validator = {
        ...this.document.variables[this.variableId].validator,
        conditions: data
      }
      if (reset) this.resetConditionedEl()
      return
    }
    if (Object.keys(this.selectedElement).length)
      this.selectedElement.meta.validator = {
        ...this.selectedElement.meta.validator,
        conditions: this.conditionedEl.destinationEl.meta.validator.conditions
      }

    const { id } = this.conditionedEl.destinationEl.meta
    this.document.options[id].meta.validator = {
      ...this.document.options[id].meta.validator,
      conditions: this.conditionedEl.destinationEl.meta.validator.conditions
    }

    // set conditioned element to default
    if (reset) this.resetConditionedEl()
  }

  @catchAction
  @action
  setPrefillings = () => {
    // copy prefillings
    this.selectedElement.prefillings = this.selectedElementPrefillings
  }

  @catchAction
  @action
  resetConditionedEl = () => {
    // set conditioned element to default
    this.conditionedEl = {
      destinationEl: {
        meta: {
          conditions: {
            and: []
          }
        }
      },
      sectionIndex: -1
    }
  }

  @catchAction
  @action
  setConditionedEl = (type: string, i?: number, id?: number) => {
    // set conditioned element
    this.conditionedElType = type
    let destinationEl
    if (type === 'hidden' && typeof id === 'number') {
      // set hidden option as conditioned element
      destinationEl = cloneDeep(this.document.options[id])
    } else if (type === 'other') {
      // set selected element as conditioned element
      destinationEl = cloneDeep(this.selectedElement)
    } else if (type === 'prefill' && typeof i === 'number') {
      // set prefilling as conditioned element
      this.prefillIndex = i
      destinationEl = cloneDeep(this.selectedElementPrefillings[i])
    } else if (type === 'variable' && typeof i === 'number') {
      // set prefilling as conditioned element
      this.variableId = i
      destinationEl = cloneDeep(this.document.variables[i])
    } else if (type === 'document') {
      // set document as conditioned element
      if (!this.document.documents[this.currentDocument].params) {
        this.document.documents[this.currentDocument].params = {}
      }
      destinationEl = cloneDeep(
        this.document.documents[this.currentDocument].params
      )
    } else if (type === 'client_type') {
      // set customization as conditioned element
      if (!this.document.customization.meta?.clientType?.conditions) {
        if (!this.document.customization.meta?.clientType) {
          if (!this.document.customization.meta) {
            this.document.customization.meta = {}
          }
          this.document.customization.meta.clientType = {
            type: 'conditionnal',
            conditions: {}
          }
        }
        this.document.customization.meta.clientType.conditions = {}
      }
      destinationEl = cloneDeep(this.document.customization.meta.clientType)
    }
    const containsMeta = !!destinationEl.meta

    if (!this.editingValidators) {
      // if element is variable
      if (!containsMeta) {
        // if there is no conditions in element
        if (
          !destinationEl.conditions ||
          !Object.keys(destinationEl.conditions).length
        ) {
          // add default and/or node
          destinationEl.conditions = {
            and: []
          }
        } else if (
          destinationEl.conditions.and === undefined &&
          destinationEl.conditions.or === undefined
        ) {
          // if element has only conditions without root and/or node
          destinationEl.conditions = {
            and: [destinationEl.conditions]
          }
        }
      } else if (
        // if element is option
        !destinationEl.meta.conditions ||
        !Object.keys(destinationEl.meta.conditions).length
      ) {
        // add default and/or node
        destinationEl.meta.conditions = {
          and: []
        }
      } else if (
        destinationEl.meta.conditions.and === undefined &&
        destinationEl.meta.conditions.or === undefined
      ) {
        // if element has only conditions without root and/or node
        destinationEl.meta.conditions = {
          and: [destinationEl.meta.conditions]
        }
      }
    } else if (!containsMeta) {
      if (!destinationEl.validator) {
        destinationEl.validator = {}
      }
      if (
        !destinationEl.validator.conditions ||
        !Object.keys(destinationEl.validator.conditions).length
      ) {
        destinationEl.validator.conditions = {
          and: []
        }
      }
    } else {
      if (!destinationEl.meta.validator) {
        destinationEl.meta.validator = {}
      }
      if (
        !destinationEl.meta.validator.conditions ||
        !Object.keys(destinationEl.meta.validator.conditions).length
      ) {
        destinationEl.meta.validator.conditions = {
          and: []
        }
      }
    }

    const { sectionIndex } = this
    let keysLenght

    if (!this.editingValidators) {
      keysLenght = destinationEl.meta
        ? Object.keys(destinationEl.meta.conditions).length
        : Object.keys(destinationEl.conditions).length
    } else {
      keysLenght = destinationEl.meta
        ? Object.keys(destinationEl.meta.validator.conditions).length
        : Object.keys(destinationEl.validator.conditions).length
    }
    if (keysLenght) {
      this.conditionedEl = {
        destinationEl,
        sectionIndex
      }
    }
  }

  @catchAction
  @action
  deleteCondition = (elem: any, index: any) => {
    // delete condition
    elem.splice(index, 1)
  }

  @catchAction
  @action
  deleteConditionsObject = (elem: any, isValidator: boolean = false) => {
    if (typeof elem.meta === 'object') {
      if (isValidator === true && typeof elem.meta.validator === 'object') {
        elem.meta.validator.conditions = {}
      } else {
        elem.meta.conditions = {}
      }
    } else if (isValidator === true && typeof elem.validator === 'object') {
      elem.validator.conditions = {}
    } else {
      elem.conditions = {}
    }
  }

  @catchAction
  @action
  toggleShowPlusIcon = (value: boolean) => {
    // + icon state
    this.showPlusIcon = value
  }

  @catchAction
  @action
  setConditionIndexes = (value: number[]) => {
    // set condition indexes
    this.conditionIndexes = value
  }

  @catchAction
  // eslint-disable-next-line
  @action
  addCondition = (el: any) => {
    // Getting Conditions object
    let conditions
    if (this.editingValidators === true) {
      conditions = this.conditionedEl.destinationEl.meta
        ? this.conditionedEl.destinationEl.meta.validator.conditions
        : this.conditionedEl.destinationEl.validator.conditions
    } else {
      conditions = this.conditionedEl.destinationEl.meta
        ? this.conditionedEl.destinationEl.meta.conditions
        : this.conditionedEl.destinationEl.conditions
    }

    // adding condition
    const rootElId = this.conditionIndexes[0]
    const idxs = [...this.conditionIndexes]
    const id = el.meta ? el.meta.id : el.id
    const type = el.meta ? el.meta.type : el.type
    // set type of node
    let keyName
    if (type === 'radio' || type === 'checkbox') {
      keyName = 'selected'
    } else if (
      [
        'text',
        'date',
        'eval',
        'mask',
        'textarea',
        'number',
        'hour',
        'email'
      ].includes(type)
    ) {
      keyName = '='
    } else if (type === 'list') {
      keyName = 'contains'
    } else if (type === 'static' || type === 'box') {
      keyName = 'has-one'
    }

    // set type of element
    const optType = [
      'list',
      'text',
      'date',
      'eval',
      'mask',
      'textarea',
      'number',
      'hour',
      'email'
    ].includes(type)
      ? 'v'
      : 'o'
    idxs.shift()
    // find conditioned element and push conditions
    let destinationEl
    if (!idxs.length) {
      destinationEl = conditions.and || conditions.or
      destinationEl.push({
        // @ts-ignore
        [keyName]: [{ var: `${optType}.${id}` }]
      })
    } else {
      destinationEl = idxs.reduce(
        (parent, currentId) => parent[currentId],
        conditions[rootElId]
      )
      const key = Object.keys(destinationEl)[0]
      destinationEl[key].push({
        // @ts-ignore
        [keyName]: [{ var: `${optType}.${id}` }]
      })
    }

    this.conditionIndexes = []
    this.showPlusIcon = false
  }

  @catchAction
  @action
  updateSelectedElement = (el: any, ids: any[]) => {
    // edit selected element
    this.selectedElement = el
    this.selectedElementIds = ids
  }

  @catchAction
  @action
  updateSelectedElementPrefillings = (arr: any[]) => {
    // edit prefillings
    this.selectedElementPrefillings = arr
  }

  @catchAction
  @action
  pushHistory = (props: IHistory) => {
    if (this.isSlateHistory(props)) {
      const lastHistory = this.history[this.history.length - 1]
      if (!this.slateEditorPushHistory) {
        this.toggleSlateEditorPushHistory()
        return
      }
      if (
        this.isSlateHistory(lastHistory) &&
        lastHistory.slateHistory === props.slateHistory
      ) {
        return
      }
    }
    // Deleting all history after currentState
    this.history.splice(this.currentState + 1, this.history.length)
    // add new point to history
    this.history.push(props)
    this.currentState += 1
  }

  isSlateHistory = (
    props: IHistorySlate | IHistoryState
  ): props is IHistorySlate => {
    return (
      typeof props === 'object' &&
      Object.prototype.hasOwnProperty.call(props, 'slateHistory') &&
      Object.keys(props).length === 1
    )
  }

  @catchAction
  @action
  undo = () => {
    const currentHistory = this.history[this.currentState]
    if (currentHistory === undefined) return

    if (this.isSlateHistory(currentHistory)) {
      if (this.slateEditor !== null) {
        this.toggleSlateEditorPushHistory()
        this.slateEditor.undo()
      }
      this.currentState -= 1
      return
    }

    // go backward in history
    const { prevState, prevSectionIndex, prevCurrentDocument } = currentHistory
    this.document = prevState
    this.setSection(prevSectionIndex)

    const currentDocument = Object.hasOwnProperty.call(
      this.document.documents,
      prevCurrentDocument
    )
      ? prevCurrentDocument
      : 'main'
    this.setCurrentDocument(currentDocument)
    this.currentState -= 1
  }

  @catchAction
  @action
  redo = () => {
    const currentHistory = this.history[this.currentState + 1]
    if (currentHistory === undefined) return
    // go forward in history
    this.currentState += 1

    if (this.isSlateHistory(currentHistory)) {
      if (this.slateEditor !== null) {
        this.toggleSlateEditorPushHistory()
        this.slateEditor.redo()
      }
      return
    }

    const { state, sectionIndex, currentDocument } = currentHistory
    this.setSection(sectionIndex)
    this.document = state
    this.setCurrentDocument(currentDocument)
  }

  @catchAction
  @action
  toggleModal = (modalName: string, value: boolean) => {
    // all modals state handler
    // @ts-ignore
    this[modalName] = value
  }

  @catchAction
  @action
  setQuillSelection = (start: number, length: number, paragraph: string) => {
    // need for quill
    this.quill.setSelection(start, length)
    this.selectedParagraph = {
      paragraph,
      start,
      length
    }
  }

  @catchAction
  @action
  resetHistoryIfNeeded = () => {
    // if history was updated, but element was deleted/edited/added
    if (this.currentState !== this.history.length - 1) {
      this.history = []
      this.currentState = -1
    }
  }

  @catchAction
  @action
  conditionsCollector = (list: any, source: any) => {
    // recursively goes and collect all conditions
    const result: any[] = []

    const getConditions = (currentList: any, currentSource: any) => {
      if (typeof currentSource === 'string') {
        if (/\w\.\d+/.test(currentSource)) result.push(currentSource)
        return
      }

      currentList.map((condition: any) => {
        // Stopping here if element has no conditions
        if (currentSource[condition] === undefined) return []
        return Array.isArray(currentSource[condition])
          ? ['and', 'or'].includes(condition)
            ? currentSource[condition].map((item: any) =>
                getConditions(Object.keys(item), item)
              )
            : getConditions(
                Object.keys(currentSource[condition][0]),
                currentSource[condition][0]
              )
          : getConditions(
              typeof currentSource[condition] === 'string'
                ? currentSource[condition]
                : Object.keys(currentSource[condition]),
              currentSource[condition]
            )
      })
    }
    getConditions(list, source)
    return result
  }

  @catchAction
  @action
  fillMetaData = () => {
    // need to operate with sidebar
    // eslint-disable-next-line
    Object.values(this.document.options).map((opt: any) => {
      this.metaData[opt.meta.id] = {
        placed: false,
        openedInnerElements: false,
        id: opt.meta.id,
        color: 'rgba(133, 81, 181, 0.3)',
        parentId: -1
      }
    })
  }

  @catchAction
  @action
  public getTemplateData = async (permalink: string, id: number) => {
    try {
      const params = {
        where: { permalink }
      }
      // getting template data from API
      const resp: any = await api.post(`/contract/meta/search`, params)
      const row = resp.data.data[0].models.find(
        (model: any) => +model.id === +id
      )
      // If model is already published redirect to template page
      if (row.draft === 0) {
        promptModal(PromptPublishedContent, {
          title: I18n.get('Warning !!!'),
          expectedValue: I18n.get('EDIT')
        }).catch(() => {
          go(`/dashboard/all-templates/${resp.data.data[0].id}`)
        })
      }
      this.setEditorIsLoading(true)
      // getting model data
      await this.getModelData(
        id,
        permalink,
        row,
        resp.data.data[0].name,
        resp.data.data[0].id,
        false
      )
      this.fillMetaData()
    } catch (err) {
      console.log('Get template data failed: ', err)
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
    }
  }

  @catchAction
  @action
  public getModelData = async (
    id: number,
    permalink: string,
    row: any,
    name: string,
    templateId: number,
    duplicate?: boolean,
    openEditor?: boolean
  ) => {
    try {
      // getting model data from API
      const resp: any = await api.get(`editor/model/${id}`)
      // Extracting model & metadata
      const { metadata, model } = resp.data as {
        model: ModelV3
        metadata: Record<string, any>
      }

      // Getting user profile
      const userProfile = await authStore.getUserProfile()

      /**
       * When Duplicating
       */
      if (duplicate === true) {
        // Saving new duplicate model
        this.meta_permalink = permalink
        const queryParams = { params: { duplicatedModelId: id } }
        const duplicateModel = await this.saveModel(
          model,
          true,
          queryParams,
          metadata
        )
        if (duplicateModel) {
          runInAction(() => {
            // set resp as current document
            this.setDocument(model)

            // Setting notes
            const notes =
              typeof metadata === 'object' &&
              row.metadata !== null &&
              Array.isArray(metadata.notes)
                ? metadata.notes
                : []
            this.setModelNotes(notes)

            // Setting user email
            if (userProfile) this.currentUserEmail = userProfile.email

            // Setting last saved
            this.lastSavedDocument = JSON.stringify({ model, metadata })

            // save model ID
            this.modelId = duplicateModel.data.id
            // save premalink
            this.meta_permalink = permalink
            this.version = row.version
            this.templateName = name
            this.templateId = templateId
            this.modelState = row.draft
            // redirect to editor
            if (openEditor === true)
              window.location.href = `/contracts/create/${permalink}/${duplicateModel.data.id}`
          })
        }
      } else {
        /**
         * When opening
         */
        runInAction(() => {
          // set resp as current document
          this.setDocument(model)

          // Setting notes
          const notes =
            typeof metadata === 'object' &&
            row.metadata !== null &&
            Array.isArray(metadata.notes)
              ? metadata.notes
              : []
          this.setModelNotes(notes)

          // Setting user email
          if (userProfile) this.currentUserEmail = userProfile.email

          // Setting last saved
          this.lastSavedDocument = JSON.stringify({ model, metadata })

          // save model ID
          this.modelId = id
          // save premalink
          this.meta_permalink = permalink
          this.version = row.version
          this.templateName = name
          this.templateId = templateId
          this.modelState = row.draft
          // redirect to editor
          if (openEditor === true)
            window.location.href = `/contracts/create/${permalink}/${row.id}`
        })
      }
    } catch (err) {
      console.log('Get model data failed: ', err)
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
    }
  }

  @catchAction
  @action
  getDocumentToCopySection = async (id: number) => {
    const resp: any = await api.get(`editor/model/${id}`)
    runInAction(() => {
      this.documentToCopySection = resp.data
    })
  }

  @catchAction
  @action
  public setMetaPermalink = async (permalink: string) => {
    this.meta_permalink = permalink
  }

  @catchAction
  @action
  public setModelId = async (id: number) => {
    this.modelId = id
  }

  @catchAction
  @action
  public setAutosaveModelData = (enable: boolean) => {
    // Autosave each X minutes
    const autosaveTime = 1
    this.enableAutosaveModelDataInterval = enable

    if (
      this.enableAutosaveModelDataInterval === true &&
      this.autosaveModelDataInterval === null
    ) {
      this.autosaveModelDataInterval = setInterval(
        () => this.saveModelData(true),
        autosaveTime * 60000
      )
    } else if (
      this.enableAutosaveModelDataInterval === false &&
      this.autosaveModelDataInterval !== null
    ) {
      clearInterval(this.autosaveModelDataInterval)
      this.autosaveModelDataInterval = null
    }
  }

  @catchAction
  @action
  public saveModelData = async (autosave = false) => {
    try {
      if (autosave === true && this.pauseAutosaveModelDataInterval === true)
        return

      this.pauseAutosaveModelDataInterval = true
      const save = async () => {
        this.pauseAutosaveModelDataInterval = false

        // if modelId === -1 - means it's a new document
        if (this.modelId < 0 || this.duplicateModel) {
          // saving new model
          const resp = await this.saveModel(this.document, true)
          runInAction(() => {
            // set new model ID into store so the next time this model will be saved - it will be updated as existing
            this.modelId = resp && resp.data.id
            this.duplicateModel = false
          })
        } else {
          // updating existing model
          await this.saveModel(this.document, false)
        }
        toast.dismiss('modelSavedToast')
        toast.success(I18n.get('success_saved_data'), {
          containerId: 'header',
          hideProgressBar: true,
          draggable: false,
          autoClose: 5000,
          className: 'modelSavedToast',
          closeButton: false,
          closeOnClick: false,
          toastId: 'modelSavedToast',
          transition: cssTransition({
            enter: 'none',
            exit: 'leaving',
            duration: [0, 0]
          })
        })
      }
      runHealthCheck(this.document)
        .then(() => {
          save()
        })
        .catch((e) => {
          if (!e) {
            this.pauseAutosaveModelDataInterval = false
          } else {
            console.error(e)
            save()
            toastError()
          }
        })
    } catch (err) {
      console.log(
        I18n.get(autosave ? 'failed_autosaved_data' : 'failed_saved_data'),
        err
      )
      this.duplicateModel = false
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
    }
  }

  @catchAction
  @action
  saveModel = async (
    model: ModelV3,
    save: boolean,
    queryParams = {},
    metadata: Record<string, any> = {
      notes: this.modelNotes
    }
  ) => {
    try {
      // Checking whether model changed or not
      if (JSON.stringify({ model, metadata }) === this.lastSavedDocument) {
        return false
      }

      if (save) {
        const resp = await api.post(
          `/editor/model/${this.meta_permalink}/create`,
          model,
          queryParams
        )
        toast.success(I18n.get('model_creation_success'), {
          position: toast.POSITION.TOP_RIGHT
        })
        // save model ID
        this.modelId = resp.data.id

        // Saving model copy to store
        this.lastSavedDocument = JSON.stringify({ model, metadata })

        return resp
      }
      const resp = await api.put(
        `editor/model/${this.meta_permalink}/${this.modelId}`,
        { model, metadata }
      )

      // Saving model copy to store
      this.lastSavedDocument = JSON.stringify({ model, metadata })

      return resp
    } catch (error) {
      console.error(error)
      toast.error(I18n.get('model_creation_failed'), {
        position: toast.POSITION.TOP_LEFT
      })
      return false
    }
  }

  @catchAction
  @action
  public publishModelData = async (
    model = this.document,
    metadata = {
      notes: this.modelNotes
    }
  ) => {
    try {
      // publishing model
      if (this.modelId < 0 || this.duplicateModel) {
        const resp = await api.post(
          `/editor/model/${this.meta_permalink}/create?publish=true`,
          model
        )
        runInAction(() => {
          // set new model ID into store so the next time this model will be saved - it will be updated as existing
          this.modelId = resp.data.id
          this.duplicateModel = false
        })
      } else {
        await api.put(
          `editor/model/${this.meta_permalink}/${this.modelId}?publish=true`,
          { model, metadata }
        )
      }
      toast.success('Model has been published successfully !', {
        position: toast.POSITION.TOP_RIGHT
      })
    } catch (err) {
      console.log('saving model data failed: ', err)
      toast.error(err.message, {
        position: toast.POSITION.TOP_LEFT
      })
    }
  }

  onSelectAutoComplete = (el: OptionV3 & VariableV3) =>
    action(() => {
      // add variable anchor in slate
      const r = this.createRegExp(el.id)
      // get selected block ID
      const anchorBlockId = this.slate.value.anchorBlock.data.get('dataId')

      // put variable anchor in editor
      // @ts-ignore
      this.document.options[anchorBlockId].meta.output = this.document.options[
        anchorBlockId
      ].meta.output.replace(
        r,
        `<abbr data-id="${el.id}">[var:${el.id}] </abbr> $3`
      )
    })

  // // @ts-ignore
  // collector (el: any) {
  //   return Object.values(el).reduce((acc: any, curEl: any) => {
  //     if(Object.keys(curEl.innerElements).length) {
  //       const curElReduced = {...curEl};
  //       curElReduced.placed = this.contractTemplate.includes(`data-id="${curElReduced.id}"`);
  //       delete curElReduced.innerElements;
  //       return [ ...acc, curElReduced, ...this.collector(curEl.innerElements)]
  //     } else {
  //       return [...acc, curEl]
  //     }
  //   }, [])
  // }

  idsCollector(arr: any) {
    // recursively collects elem IDs
    return arr.reduce((acc: any, curEl: any) => {
      if (this.document.options[curEl] === undefined) {
        throw new Error(`${curEl} option not found`)
      }
      if (this.document.options[curEl].options.length) {
        return [
          ...acc,
          this.document.options[curEl].meta.id,
          ...this.idsCollector(this.document.options[curEl].options)
        ]
      }
      return [...acc, this.document.options[curEl].meta.id]
    }, [])
  }

  variablesIdsCollector(arr: any) {
    // recursively collects variables IDs
    return arr.reduce((acc: any, curEl: any) => {
      if (this.document.options[curEl] === undefined) {
        throw new Error(`${curEl} option not found`)
      }
      if (this.document.options[curEl].variables.length) {
        const varIds = this.document.options[curEl].variables.map(
          (variable: any) => {
            return variable
          }
        )
        return [
          ...acc,
          ...varIds,
          ...this.variablesIdsCollector(this.document.options[curEl].options)
        ]
      }
      return [
        ...acc,
        ...this.variablesIdsCollector(this.document.options[curEl].options)
      ]
    }, [])
  }

  documentOptionsCollector(arr: any) {
    // recursively collects document options
    return arr.reduce((acc: any, curEl: any) => {
      if (
        this.document.options[curEl] &&
        this.document.options[curEl].options.length
      ) {
        const childIds = this.document.options[curEl].options.map(
          (child: any) => {
            return +child
          }
        )
        return [
          ...acc,
          curEl,
          ...childIds,
          ...this.documentOptionsCollector(this.document.options[curEl].options)
        ]
      }
      return [...acc, curEl]
    }, [])
  }

  createRegExp = (regexText: number) => {
    return new RegExp(`(#)(${regexText})(\\s|\\<|$|[^d"]$)`, 'mg')
    // return new RegExp(`(^|\\s|\\>|.)(${text})(\\s|\\<|$|[^d"]$)`, 'mg');
  }

  encodeTemplateText = () => {
    // eslint-disable-next-line
    const encoded = Object.values(this.formElements).reduce((res, el) => {
      return res.replace(
        `<span style="background-color: ${this.metaData[el.id].color};">[${
          el.label
        }]</span>`,
        `[${el.id}]`
      )
    }, this.contractTemplate)
    console.log(encoded)
  }

  decodeTemplateText = () => {
    // eslint-disable-next-line
    this.contractTemplate = Object.values(this.formElements).reduce((res, el) => {
        return res.replace(
          `[${el.id}]`,
          `<abbr data-id="${el.id}" style="background-color: ${
            this.metaData[el.id].color
          };">[${el.label}]</abbr>`
        )
      },
      this.contractTemplate
    )
  }

  @catchAction
  @action
  cleaner = (obj: any) => {
    // clean up empty fields
    const mapAndClean = (_obj: any) => {
      const currObj = _obj.meta ? _obj.meta : _obj
      // eslint-disable-next-line
      Object.keys(currObj).map((key: string) => {
        if (
          currObj[key] !==
            (Array.isArray(currObj[key]) && currObj[key] === null) ||
          currObj[key] === ''
        )
          delete currObj[key]
      })
    }
    // eslint-disable-next-line
    Object.values(obj).map((opt: any) => {
      mapAndClean(opt)
      if (opt.options && opt.options.length) {
        // eslint-disable-next-line
        opt.options.map((childId: number) => {
          this.cleaner(this.document.options[childId])
        })
      }
    })
  }

  @catchAction
  @action
  setSelectedBlocks = (arr: any[]) => {
    this.selectedBlocks = arr.reverse()
  }

  @catchAction
  @action
  copyConditions = () => {
    const hasMeta = !!this.conditionedEl.destinationEl.meta
    const source = hasMeta
      ? this.conditionedEl.destinationEl.meta
      : this.conditionedEl.destinationEl
    const temporaryBuffer =
      this.editingValidators === true
        ? source.validator.conditions
        : source.conditions
    this.conditionsBuffer = cloneDeep(temporaryBuffer)
  }

  @catchAction
  @action
  pasteConditions = () => {
    const hasMeta = !!this.conditionedEl.destinationEl.meta
    const destination = hasMeta
      ? this.conditionedEl.destinationEl.meta
      : this.conditionedEl.destinationEl
    if (this.editingValidators === true) {
      destination.validator = {
        ...destination.validator,
        conditions: cloneDeep(this.conditionsBuffer)
      }
    } else {
      destination.conditions = cloneDeep(this.conditionsBuffer)
    }
  }

  clearAndStringify = (document: ModelV3) => {
    // prepares output model
    const doc = cloneDeep(document)
    // this.cleaner(doc.options);
    // this.cleaner(doc.variables);

    return JSON.stringify(doc)
  }

  @catchAction
  @action
  cleanOrphelins = (orphelins: { options: number[]; variables: number[] }) => {
    orphelins.options.forEach((id) => {
      delete this.document.options[id]
    })
    orphelins.variables.forEach((id) => {
      delete this.document.variables[id]
    })
  }

  @catchAction
  @action
  setSlateEditor = (editor: (Editor & ReactEditor & HistoryEditor) | null) => {
    this.slateEditor = editor
  }

  @catchAction
  @action
  toggleSlateEditorPushHistory = () => {
    this.slateEditorPushHistory = !this.slateEditorPushHistory
  }

  @catchAction
  @action
  deleteOutputOption = (id: number) => {
    const outputSectionIndex = this.document.documents[
      this.currentDocument
    ].sections[0].options.indexOf(id)

    if (outputSectionIndex > -1) {
      delete this.document.options[id]
      const outputIndex = this.document.documents[
        this.currentDocument
      ].sections[0].options.indexOf(id)
      this.document.documents[this.currentDocument].sections[0].options.splice(
        outputIndex,
        1
      )
    } else {
      const parentOptionsList = this.document.documents[
        this.currentDocument
      ].sections[0].options
        .map((optionId) => {
          const option = this.document.options[optionId]
          if (option.options.includes(id)) return optionId
          return false
        })
        .filter((a: number | false): a is number => {
          return a !== false
        })

      if (parentOptionsList.length !== 1) {
        throw new Error(
          `Found ${parentOptionsList.length} parents for option ${id}`
        )
      }

      delete this.document.options[id]
      const [parentId] = parentOptionsList
      const parentOption = this.document.options[parentId]
      const outputIndex = parentOption.options.indexOf(id)
      parentOption.options.splice(outputIndex, 1)

      if (parentOption.options.length === 0) {
        this.deleteOutputOption(parentId)
      }
    }
  }

  @catchAction
  @action
  updateOutput = (id: number, output: string): void => {
    this.document.options[id].meta.output = output
  }

  @catchAction
  @action
  updateConditions = (id: number, option: OptionV3): void => {
    this.document.options[id].meta.conditions = option.meta.conditions
  }

  @catchAction
  @action
  updateCurrentDocumentOutputs = (): void => {
    this.setEditorIsLoading(true)
    const section = this.document.documents[this.currentDocument].sections[0]
    const outputs = section.options
      .map((optionId) => {
        const option = this.document.options[optionId]

        if (option.meta.type === 'hidden') return { option, children: [] }

        if (option.meta.type === 'repeated')
          return {
            option,
            children: option.options.map(
              (childId) => this.document.options[childId]
            )
          }
        return null
      })
      .filter(
        (v): v is { option: OptionV3; children: OptionV3[] } => v !== null
      )
    // Creating an empty output if current document doesn't have any
    if (outputs.length === 0)
      outputs.push({ option: this.addOutput(0, 'before'), children: [] })

    this.currentDocumentOutputs = outputs
    this.setEditorIsLoading(false)
  }

  @catchAction
  @action
  recreateOutput = (params: {
    parentId?: number
    parentMeta?: OptionV3['meta']
    precedentOption: number
    originalOption: OptionV3
  }): void => {
    const { parentId, parentMeta, precedentOption, originalOption } = params

    // Checking if it's a repeated and if parent still exists, if not, recreating it
    if (
      typeof parentId === 'number' &&
      !Object.prototype.hasOwnProperty.call(this.document.options, parentId)
    ) {
      if (parentMeta === undefined)
        throw new Error(
          `Unable to find parentMeta when trying to recreate repeated option ${parentId}`
        )

      this.recreateOutput({
        precedentOption,
        originalOption: {
          meta: parentMeta,
          options: [],
          variables: []
        }
      })
    }

    // Getting pushing index
    const parent =
      typeof parentId === 'number'
        ? this.document.options[parentId]
        : this.document.documents[this.currentDocument].sections[0]
    const pushIndex =
      precedentOption > 0 ? parent.options.indexOf(precedentOption) + 1 : 0

    // Creating option
    const originalId = originalOption.meta.id
    this.document.options[originalId] = originalOption

    // Adding Option to id to parent
    parent.options.splice(pushIndex, 0, originalId)
  }

  @catchAction
  @action
  addOutput = (id: number, position: 'after' | 'before'): OptionV3 => {
    const newOptionId = this.getNewOptionId()
    const documentSection = this.document.documents[this.currentDocument]
      .sections[0]

    // Getting option index
    const optionIndex =
      typeof id === 'number' && id > 0
        ? documentSection.options.indexOf(id)
        : documentSection.options.length - 1
    if (optionIndex === -1)
      throw new Error(`Unable to determine option ${id} position`)

    const newOption: OptionV3 = {
      meta: {
        id: newOptionId,
        type: 'hidden',
        output: '<p></p>',
        step: '*',
        label: ''
      },
      options: [],
      variables: []
    }

    this.document.options[newOptionId] = newOption

    // Determining newOption's index
    const newOptionIndex = optionIndex + (position === 'before' ? 0 : 1)

    // Adding our new option
    documentSection.options.splice(newOptionIndex, 0, newOptionId)

    // Updating outputs
    this.updateCurrentDocumentOutputs()

    return newOption
  }

  @catchAction
  @action
  linkOutputsToMultiple = (multipleId: number, outputs: number[]) => {
    const sectionOptions = this.document.documents[this.currentDocument]
      .sections[0].options

    if (outputs.length === 0) return false

    // Unlinking all outputs that already belong to a multiple
    outputs.forEach((id) => {
      const parentId = this.getOutputParent(id)
      if (parentId > 0) this.unlinkOutputFromMultiple(id, parentId)
    })

    if (multipleId === 0) return false

    // Getting index of first output to use it next as repeat index
    const repeatIndex = sectionOptions.indexOf(outputs[0])

    // Deleting them from section
    outputs.forEach((id) => {
      sectionOptions.splice(sectionOptions.indexOf(id), 1)
    })

    // Creating repeat
    const optionsIds = Object.keys(this.document.options).map((i) =>
      parseInt(i, 10)
    )
    const repeatId =
      optionsIds.length > 0 ? optionsIds[optionsIds.length - 1] + 1 : 1

    const repeat: OptionV3 = {
      meta: {
        id: repeatId,
        type: 'repeated',
        step: '*',
        label: '',
        repeatOption: multipleId
      },
      options: outputs,
      variables: []
    }
    this.document.options[repeatId] = repeat

    // Adding repeat to section
    sectionOptions.splice(repeatIndex, 0, repeatId)

    return repeat
  }

  @catchAction
  @action
  unlinkOutputFromMultiple = (id: number, multipleId: number) => {
    // Getting output's index
    const sectionOptions = this.document.documents[this.currentDocument]
      .sections[0].options
    const multipleIndex = sectionOptions.indexOf(multipleId)
    const siblings = this.document.options[multipleId].options
    const index = siblings.indexOf(id)
    if (index === -1)
      throw new Error(`Output ${id} is not child of ${multipleId}`)

    // If current output is first and has siblings
    // we must move it right before it's old parent
    if (index === 0) {
      // Removing it from it siblings
      siblings.splice(index, 1)

      // Moving it into section right before its parent
      sectionOptions.splice(multipleIndex, 0, id)
    } else {
      // If outputs is in the middle of its siblings
      // we must move all of them to section's root and
      // create a new repeat of the next siblings
      const ids = siblings.splice(index, siblings.length - 1)

      // Moving everything to section
      sectionOptions.splice(multipleIndex + 1, 0, ...ids)

      // Linking next siblings to a new repeat if any
      const nextSiblings = ids.slice(0, ids.length - 1)
      if (nextSiblings.length > 0)
        this.linkOutputsToMultiple(multipleId, nextSiblings)
    }

    // If repeat is empty we delete it
    if (siblings.length === 0) {
      delete this.document.options[multipleId]
      sectionOptions.splice(sectionOptions.indexOf(multipleId), 1)
    }
  }

  getOutputParent = (id: number) => {
    const outputSectionIndex = this.document.documents[
      this.currentDocument
    ].sections[0].options.indexOf(id)
    if (outputSectionIndex > -1) return 0
    const parentOptionsList = this.document.documents[
      this.currentDocument
    ].sections[0].options
      .map((optionId) => {
        const option = this.document.options[optionId]
        if (option.options.includes(id)) return optionId
        return false
      })
      .filter((a: number | false): a is number => {
        return a !== false
      })

    if (parentOptionsList.length !== 1) {
      throw new Error(
        `Found ${parentOptionsList.length} parents for option ${id}`
      )
    }

    return parentOptionsList[0]
  }

  @catchAction
  @action
  moveAllOutputsToFirstSection() {
    Object.keys(this.document.documents).forEach((currentDocument) => {
      const outputs: number[] = []
      this.document.documents[currentDocument].sections.forEach(
        (section, index) => {
          if (index > 0) {
            section.options.forEach((optionId, optionIndex) => {
              if (
                ['hidden', 'repeated'].includes(
                  this.document.options[optionId].meta.type
                )
              ) {
                outputs.push(optionId)
                section.options[optionIndex] = 0
              }
            })
            section.options = section.options.filter((id) => id > 0)
          }
        }
      )

      this.document.documents[currentDocument].sections[0].options.push(
        ...outputs
      )
    })
  }

  @catchAction
  @action
  initiateMoveElement = (
    type: 'option' | 'variable',
    id: number,
    toggleDrawer: (v: boolean) => void
  ) => {
    // Getting current parent
    let currentParentType: 'option' | 'section' | undefined
    let currentParent: number | undefined
    if (type === 'option') {
      // Looking for option in sections
      this.document.documents.main.sections.forEach((section, index) => {
        if (section.options.includes(id)) {
          currentParentType = 'section'
          currentParent = index
        }
      })
    }

    if (currentParentType === undefined || currentParent === undefined) {
      const optionsIds = Object.keys(this.document.options)
      for (let i = 0; i < optionsIds.length; i += 1) {
        const currentOptionId = optionsIds[i]
        const currentOption = this.document.options[currentOptionId]

        if (type === 'option' && currentOption.options.includes(id)) {
          currentParentType = 'option'
          currentParent = parseInt(currentOptionId, 10)
          break
        } else if (
          type === 'variable' &&
          currentOption.variables.includes(id)
        ) {
          currentParentType = 'option'
          currentParent = parseInt(currentOptionId, 10)
          break
        }
      }
    }

    if (currentParentType === undefined || currentParent === undefined) {
      throw new Error(
        `Unable to find parent when trying to initiate moving element type: ${type} id: ${id}`
      )
    }

    // Toggle params was shown
    this.toggleParamsWasShown(true)

    // Setting moveElement object
    this.moveElement = {
      isMoving: true,
      type,
      id,
      currentParentType,
      currentParent,
      toggleDrawer
    }
  }

  @catchAction
  @action
  cancelMoveElement = () => {
    this.toggleParamsWasShown(false)
    if (typeof this.moveElement.toggleDrawer === 'function')
      this.moveElement.toggleDrawer(true)

    this.moveElement = {
      isMoving: false,
      id: 0,
      type: 'option',
      currentParent: 0,
      currentParentType: 'option'
    }
  }

  @traceHistory
  @catchAction
  @action
  handleMoveElement = (
    destIndex: number,
    destParent: number,
    destParentType: 'option' | 'section' | 'variable'
  ) => {
    if (this.moveElement.type === 'option')
      this.handleMoveOption(destIndex, destParent, destParentType)
    else this.handleMoveVariable(destIndex, destParent, destParentType)

    this.toggleParamsWasShown(false)
    if (typeof this.moveElement.toggleDrawer === 'function')
      this.moveElement.toggleDrawer(true)

    // Resetting moveElement to default
    this.moveElement = {
      isMoving: false,
      id: 0,
      type: 'option',
      currentParent: 0,
      currentParentType: 'option'
    }
  }

  private handleMoveOption = (
    destIndex: number,
    destParent: number,
    destParentType: 'option' | 'section' | 'variable'
  ) => {
    // Getting element informations
    const { id, currentParent, currentParentType } = this.moveElement

    let currentIndex: number = 0

    /**
     * Deleting option from current parent
     */
    if (currentParentType === 'section') {
      /**
       * In case current parent is a section
       */
      const currentParentSection = this.document.documents.main.sections[
        currentParent
      ]
      currentIndex = currentParentSection.options.indexOf(id)

      // Deleting option id
      currentParentSection.options.splice(currentIndex, 1)
    } else if (currentParentType === 'option') {
      /**
       * In case current parent is an option
       */
      const currentParentOption = this.document.options[currentParent]

      currentIndex = currentParentOption.options.indexOf(id)

      // Deleting option id
      currentParentOption.options.splice(currentIndex, 1)
    }

    /**
     * Adding option to new parent
     */

    // Calculating new index (we remove 1 if we're moving in the same parent after previous position since we deleted the old one)
    const newIndex =
      destIndex -
      (currentParentType === destParentType &&
      currentParent === destParent &&
      currentIndex < destIndex
        ? 1
        : 0)

    if (destParentType === 'section') {
      /**
       * In case destination parent is a section
       */
      const destinationParentSection = this.document.documents.main.sections[
        destParent
      ]

      destinationParentSection.options = [
        ...destinationParentSection.options.slice(0, newIndex),
        id,
        ...destinationParentSection.options.slice(newIndex)
      ]
    } else if (destParentType === 'option') {
      /**
       * In case destination parent is an option
       */
      const destinationParentOption = this.document.options[destParent]

      destinationParentOption.options = [
        ...destinationParentOption.options.slice(0, newIndex),
        id,
        ...destinationParentOption.options.slice(newIndex)
      ]
    }
  }

  private handleMoveVariable = (
    destIndex: number,
    destParent: number,
    destParentType: 'option' | 'section' | 'variable'
  ) => {
    // Getting element informations
    const { id, currentParent, currentParentType } = this.moveElement

    let currentIndex: number = 0

    /**
     * Deleting variable from current parent
     */
    const currentParentOption = this.document.options[currentParent]

    currentIndex = currentParentOption.variables.indexOf(id)

    // Deleting option id
    currentParentOption.variables.splice(currentIndex, 1)

    /**
     * Adding variable to new parent
     */

    // Calculating new index (we remove 1 if we're moving in the same parent after previous position since we deleted the old one)
    const newIndex =
      destIndex -
      (currentParentType === destParentType &&
      currentParent === destParent &&
      currentIndex < destIndex
        ? 1
        : 0)

    const destinationParentOption = this.document.options[destParent]

    destinationParentOption.variables = [
      ...destinationParentOption.variables.slice(0, newIndex),
      id,
      ...destinationParentOption.variables.slice(newIndex)
    ]
  }

  @traceHistory
  @catchAction
  @action
  initCurrentDocumentPdf(
    pdf: Exclude<ModelV3['documents']['']['pdf'], undefined>
  ) {
    this.document.documents[this.currentDocument].pdf = pdf
  }

  @traceHistory
  @catchAction
  @action
  deleteCurrentDocumentPdf() {
    this.document.documents[this.currentDocument].pdf = undefined
  }

  getMultipleRepeateds(id: number) {
    const repeateds = Object.values(this.document.options).filter(
      (option) => option.meta.repeatOption === id
    )

    const outputs: OptionV3[] = []
    repeateds.forEach((option) => {
      option.options.forEach((outputId) => {
        outputs.push(this.document.options[outputId])
      })
    })

    return outputs
  }

  @catchAction
  @action
  setSlateEditorRef(ref: React.RefObject<SlateEditor>) {
    this.slateEditorRef = ref
  }

  getOptionParentSection(id: number) {
    const rootParent = this.getOptionParent(id)

    // Looking for option in sections
    const { sections } = this.document.documents.main
    for (let i = 0; i < sections.length; i += 1) {
      const currentSection = sections[i]
      if (currentSection.options.includes(rootParent)) return currentSection.id
    }

    return 0
  }

  getVariableParentSection(id: number) {
    const variableParent = this.getVariableParent(id)
    return this.getOptionParentSection(variableParent)
  }
}

export type ICreateStore = CreateStore

const createStore = new CreateStore()
export default createStore
