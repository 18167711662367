import React from 'react'
import { I18n } from 'aws-amplify'
import createStore from '../../../store/editor/createStore'
import Input from '../../Editor/InputField'
import Select from '../../../pages/Editor/CreateContractPage/slate-editor/components/select/Select'

const dictionary = {
  fr: {
    email_section_head: 'Section email',
    email_section_title: 'Titre de la section',
    email_input_head: 'Champ email',
    email_input_label: 'Label du champ',
    email_cta_head: 'CTA',
    email_cta_label: 'Label du CTA',
    email_cta_icon: 'Icône du CTA',
    download: 'Téléchargement',
    chevron: 'Chevron'
  },
  en: {
    email_section_head: '',
    email_section_title: '',
    email_input_head: '',
    email_input_label: '',
    email_cta_head: '',
    email_cta_label: '',
    email_cta_icon: ''
  }
}

I18n.putVocabularies(dictionary)

const ctaIconOptions = {
  download: I18n.get('download'),
  chevron: I18n.get('chevron')
}

type ParamsModalTabPropsType = {
  setParams: (params: Record<string, any>) => void
  getParams: () => Record<string, any>
  toggleConditionsDrawer: (
    open: boolean,
    type: string,
    i?: number | undefined
  ) => void
  closeModal: (keepCurrentTab?: boolean) => void
}

type EmailParamsModalStateType = {
  [key: string]: any
}

class EmailParamsModal extends React.Component<
  ParamsModalTabPropsType,
  EmailParamsModalStateType
> {
  constructor(props: ParamsModalTabPropsType) {
    super(props)

    const { getParams } = props

    this.state = {
      'meta.emailSection.sectionTitle':
        createStore.document.customization?.meta?.emailSection?.sectionTitle ||
        '',
      'meta.emailSection.inputLabel':
        createStore.document.customization?.meta?.emailSection?.inputLabel ||
        '',
      'meta.emailSection.ctaLabel':
        createStore.document.customization?.meta?.emailSection?.ctaLabel || '',
      'meta.emailSection.ctaIcon':
        createStore.document.customization?.meta?.emailSection?.ctaIcon || '',
      ...getParams()
    }
  }

  updateParams = (newParams: Record<string, any>) => {
    const { setParams } = this.props

    this.setState({ ...this.state, ...newParams })
    setParams(newParams)
  }

  setFieldValue = (name: string, value: string | number | boolean) => {
    const newParams = {
      ...this.state,
      [name]: value
    }
    this.updateParams({
      ...newParams
    })
  }

  render() {
    return (
      <div>
        <div className="pm-block">
          <h3>{I18n.get('email_section_head')}</h3>
          <Input
            name="meta.emailSection.sectionTitle"
            setFieldValue={this.setFieldValue}
            value={this.state['meta.emailSection.sectionTitle']}
            label={I18n.get('email_section_title')}
          />
        </div>
        <div className="pm-block">
          <h3>{I18n.get('email_input_head')}</h3>
          <Input
            name="meta.emailSection.inputLabel"
            setFieldValue={this.setFieldValue}
            value={this.state['meta.emailSection.inputLabel']}
            label={I18n.get('email_input_label')}
          />
        </div>
        <div className="pm-block">
          <h3>{I18n.get('email_cta_head')}</h3>
          <Input
            name="meta.emailSection.ctaLabel"
            setFieldValue={this.setFieldValue}
            value={this.state['meta.emailSection.ctaLabel']}
            label={I18n.get('email_cta_label')}
          />
          <h5 className="text" style={{ margin: 0 }}>
            {I18n.get('email_cta_icon')}
          </h5>
          <Select
            options={ctaIconOptions}
            onChange={(v) => this.setFieldValue('meta.emailSection.ctaIcon', v)}
            value={this.state['meta.emailSection.ctaIcon']}
          />
        </div>
      </div>
    )
  }
}

export default EmailParamsModal
