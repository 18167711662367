import React from 'react'
import ReactQuill from 'react-quill'
import { OptionEditorStateType } from '../../editors/OptionEditor'

const QuillModules = {
  toolbar: [['bold', 'italic', 'underline', 'link']]
}
/**
 * Edits box content
 */
export const BoxContent: React.FC<{ state: OptionEditorStateType }> = ({
  state
}) => {
  const content =
    (state.option.meta.box && state.option.meta.box.content) ||
    state.option.meta.label

  return (
    <div className="label-holder">
      <ReactQuill
        className="editor"
        modules={QuillModules}
        value={content}
        onChange={(val: string) => {
          state.updateBoxContent(val)
        }}
      />
    </div>
  )
}
