import React, { Component, ReactNode } from 'react'
import { FadeLoader } from 'react-spinners'
import { inject, observer } from 'mobx-react'
import { I18n } from 'aws-amplify'
import { RouteComponentProps } from 'react-router'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from '@material-ui/core'
import { observable, action, runInAction } from 'mobx'
import { IDataStore } from '../../../store/dataStore'
import DataSearch from '../../../components/DataSearch/DataSearch'
import DataTable from '../../../components/DataTable/DataTable'
import DataPagination from '../../../components/DataPagination/DataPagination'
import NewFileForm from '../MyFilesPage/NewFileForm'
import Icon from '../../../components/Icons/Icon'
import { PLUS } from '../../../components/Icons/icons'
import { IDialogStore } from '../../../store/dialogStore'
import TemplatesFilter from './TemplatesFilter'
import { primary } from '../../../constants/scheme'
import { ICreateStore } from '../../../store/editor/createStore'
import { go } from '../../../utils/history'
import SelectMetaModal from '../../../components/SelectMetaModal'
import './style.scss'
import { IContractsStore } from '../../../store/editor/contractsStore'
import { catchAction } from '../../../store/editor/catchActionsDecorator'

interface IAllTemplatesPageProps extends RouteComponentProps {
  dataStore?: IDataStore | any
  dialogStore?: IDialogStore | any
  createStore?: ICreateStore | any
  contractsStore?: IContractsStore | any
}

const columns = {
  en: {
    templates_title: 'All Templates',
    templates_new: 'Create new template',
    templates_search: 'Search by email, type, ID or template name ...',
    templates_items_name: 'Templates',
    templates_settings: 'Settings',
    templates_id: 'ID',
    templates_name: 'Template Name',
    templates_category: 'Category',
    templates_status: 'Status',
    // templates_model: 'Model Published',
    templates_model: 'model',
    templates_action: 'Action',
    templates_refresh: 'Refresh',
    templates_draft: 'Draft',
    templates_published: 'Published'
  },
  fr: {
    templates_title: 'Templates',
    templates_new: 'Nouveau template',
    templates_search: 'Chercher par nom de template',
    templates_items_name: 'Templates',
    templates_settings: 'Paramètres',
    templates_id: 'ID',
    templates_name: 'Nom du template',
    templates_category: 'Categorie',
    templates_status: 'Statut',
    // templates_model: 'Model Published',
    templates_model: 'Modèles',
    templates_action: 'Action',
    templates_refresh: 'Actualiser',
    templates_draft: 'Brouillon',
    templates_published: 'Publié'
  }
}

I18n.putVocabularies(columns)

@inject('dataStore', 'dialogStore', 'createStore', 'contractsStore')
@observer
class AllTemplatesPage extends Component<IAllTemplatesPageProps> {
  private rows: any[] = [
    // {disableOrder: false, id: 'id', label: I18n.get('templates_id')},
    { disableOrder: false, id: 'filename', label: I18n.get('templates_name') },
    {
      disableOrder: false,
      id: 'category_name',
      label: I18n.get('templates_category')
    },
    { disableOrder: false, id: 'state', label: I18n.get('templates_status') },
    {
      disableOrder: false,
      id: 'model_published',
      label: I18n.get('templates_model')
    },
    { disableOrder: true, id: 'action', label: I18n.get('templates_action') }
  ]

  @observable loadingModel: boolean = false

  UNSAFE_componentWillMount() {
    const { fetchCategories } = this.props.contractsStore
    this.onChangeParams()
    fetchCategories()
  }

  public newFile = () => {
    this.props.dialogStore.openDialog(<NewFileForm />)
  }

  public createEmptyDoc = async (params: any) => {
    const {
      setDocument,
      setMetaPermalink,
      setModelId,
      saveModel
    } = this.props.createStore
    const { createNewMeta } = this.props.dataStore
    await createNewMeta(params)
    // if new doc - set initial state of document
    await setDocument()
    // save meta_permalink
    await setMetaPermalink(params.permalink)
    // reset model id
    await setModelId(-1)
    // create new model
    await saveModel(this.props.createStore.document, true)
    const { permalink, modelId, getTemplateData } = this.props.createStore
    // get model data
    await getTemplateData(permalink, modelId)
    // redirect to editor
    await go(`/contracts/create/${params.permalink}/${modelId}`)
    this.toggleSelectMetaModal(false)
  }

  public toggleSelectMetaModal = (val: boolean) => {
    const { toggleModal } = this.props.createStore
    toggleModal('selectMetaModalIsOpen', val)
  }

  @catchAction
  @action
  public goToDoc = async (id: number, permalink: string) => {
    const { getModelData } = this.props.createStore
    // turn on loader
    this.loadingModel = true
    // get model from API
    await getModelData(id, permalink)
    runInAction(() => {
      // turn off loader
      this.loadingModel = false
    })
    // redirect to editor
    go(`/contracts/create/${permalink}`)
    this.toggleSelectMetaModal(false)
  }

  goToSettings = (id: number, draft: number) => {
    this.props.contractsStore.setDraftState(draft)
    go(`/dashboard/all-templates/${id}`)
  }

  public onChangeParams = () => {
    const { location, dataStore } = this.props
    const params: any = new URLSearchParams(location.search)
    dataStore.fetchProducts(params)
  }

  public render(): ReactNode {
    const { allProductsData, fetching, error } = this.props.dataStore
    const { toggleModal, selectMetaModalIsOpen } = this.props.createStore
    const { data, total } = allProductsData

    const { categories } = this.props.contractsStore

    let categoriesOptions = categories.map((category: any) => {
      return {
        label: category.name,
        value: category.id
      }
    })
    // @ts-ignore
    window.categoriesOptions = categoriesOptions
    categoriesOptions = categoriesOptions.sort((a: any, b: any) =>
      a.label.localeCompare(b.label)
    )

    return (
      <div className="content">
        <div className="padding-container ttl-padding-container">
          <div className="ttl-container">
            <h3 className="ttl">{I18n.get('templates_title')}</h3>
            <button
              type="button"
              className="primary"
              onClick={() => this.toggleSelectMetaModal(true)}
            >
              <Icon iconClass="iconPlus" icon={PLUS} color="#fff" />
              {I18n.get('templates_new')}
            </button>
          </div>
          <div className="flex-row flex-center-sb filter-bar">
            <div className="flex-row">
              <DataSearch
                cypressId="search-templates"
                onChangeParams={this.onChangeParams}
                placeholder={I18n.get('templates_search')}
              />
              <button
                type="button"
                className="secondary"
                onClick={this.onChangeParams}
              >
                {I18n.get('templates_refresh')}
              </button>
            </div>
            <TemplatesFilter onChangeParams={this.onChangeParams} />
          </div>
        </div>

        <FadeLoader color={primary} loading={fetching} />
        <FadeLoader color={primary} loading={this.loadingModel} />
        {error && <div>Error - {error}</div>}

        <DataTable rows={this.rows} onChangeParams={this.onChangeParams}>
          {data &&
            data.map((row: any, i: number) => (
              <TableRow key={i}>
                {/* <TableCell className="big-text" align="right"> */}
                {/* <Link to={`/all-templates/${row.id}`}>{row.id}</Link> */}
                {/* </TableCell> */}
                <TableCell className="big-text" align="right">
                  {row.name}
                </TableCell>
                <TableCell className="big-text" align="right">
                  {row.category_name}
                </TableCell>
                <TableCell
                  className="big-text has-text-weight-medium"
                  align="right"
                >
                  {row.state === 0
                    ? I18n.get('templates_draft')
                    : I18n.get('templates_published')}
                </TableCell>
                <TableCell className="big-text" align="right">
                  {row.models_count}
                </TableCell>
                <TableCell align="right">
                  <div className="btn-wrapper ff-nowrap">
                    {/* <button type="button" onClick={() => this.goToDoc(row.id, row.meta_permalink)} className="btn btn-dark btn-icon-text nomb"> */}
                    <button
                      type="button"
                      data-cypressId="templates-settings"
                      onClick={() => {
                        this.goToSettings(row.id, row.draft)
                      }}
                      className="btn secondary"
                    >
                      {I18n.get('templates_settings')}
                    </button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </DataTable>

        <div className="padding-container pagination-padding-container">
          <DataPagination
            onChangeParams={this.onChangeParams}
            total={total}
            itemsName={I18n.get('templates_items_name')}
          />
        </div>
        <SelectMetaModal
          toggleModal={toggleModal}
          options={categoriesOptions}
          open={selectMetaModalIsOpen}
          createEmptyDoc={this.createEmptyDoc}
        />
      </div>
    )
  }
}

export default AllTemplatesPage
